

import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import "./tableContent.scss"
import { useDispatch, useSelector } from "react-redux";
import ACTION_MENU_CLICKED_RESET from "../../../../actions/OptionsMenu/ACTION_MENU_CLICKED_RESET";
import { DataSpinner as RenderSitesLoader } from "../../spinner/dataSpinner";
 

const TableContent = (props) => {

  const menu_clicked = useSelector((state) => state.menuClicked.menu_clicked);
  
    const {
        accountType,
        accountTabs,
        accountColumns,
        accountData,
        loading,
        conditionalRowStyles,
        defaultSortFieldId,
        setShowPopup,
    } = props
    const dispatch = useDispatch()
    const location = useLocation()
  
    return (
        <div className="contentContainer">
             <DataTable
             highlightOnHover={true}
                onRowMouseEnter={(row) => setShowPopup && setShowPopup(row)}
                columns={accountColumns}
                data={accountData ? accountData : []}
                progressPending={accountData == null || loading}
                progressComponent={RenderSitesLoader({small:"l"})}
                onRowClicked={(row) => props.subScreen && props.subScreen(row)}
                conditionalRowStyles={conditionalRowStyles ? conditionalRowStyles : []}
        />
        </div>
    )  
}

export default TableContent
