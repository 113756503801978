const MOBILE_DRAG = "MOBILE_DRAG";
const MOBILE_DRAG_RESET = "MOBILE_DRAG_RESET";

const mobileDragReducer = (state = { 
          payload: null,
          }, action) => {
  switch (action.type) {
    case MOBILE_DRAG:
      return { 
        ...state, 
        payload: action.payload
      };
    case MOBILE_DRAG_RESET:
      return { ...state, 
              payload: null
              };
    default:
      return { ...state };
  }
};

export default mobileDragReducer;
