import React, { useEffect } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./templateHistoryModal.scss"
import moment from 'moment';
import { Style } from 'react-style-tag';
import parse from 'html-react-parser';

const TemplateHistoryModal = (props) => {

    const {
        templateHistoryModal,
        setTemplateHistoryModal,
        selectedHistory,
        setSelectedHistory,
        loadHistory,
    } = props


    useEffect(() => {
      if(templateHistoryModal) {
        setSelectedHistory(templateHistoryModal[0])
      }
    },[templateHistoryModal])
  return(
<Modal
        isOpen={templateHistoryModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
             
              
              {templateHistoryModal && (
               <div className="modal task-modal" style={{width:"90vw", height:"100%"}}>
 <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setTemplateHistoryModal(null)
                }}
              />
          <h1 className="header" style={{width:"100%"}}>Load previous version</h1>
          <div className="form-group-container task-form" style={{flexDirection:"row", justifyContent:"flex-start", height:"100%"}}>
              <div className='modalSideBar'>
                  {
                      templateHistoryModal && (
                          templateHistoryModal.sort((a,b) => b.createdAt - a.createdAt).map((history, i) => (
                            <div key={i} className="versionCardContainer">
                            <div className={`versionCard templatecontainer ${ selectedHistory && selectedHistory._id == history._id && "selectedHistory"}`} onClick={() => setSelectedHistory(history)}>
                                <div className='userSquareHeader'>{moment.unix(history.createdAt/1000).format("MM/DD/YYYY hh:mm a")?.toUpperCase()}</div>
                                <div className='squareContent'>created by: {history.by}</div>
                                {i == 0 && <div className="squareContent" style={{color:"#6DB240"}}> default version </div>}
                            </div>
                            </div>
                          ))
                      )
                  } 
          </div>
          {
                                selectedHistory && (
                                    <div className='historyViewerContainer'>
                                        {parse(`${selectedHistory.html} <style>${selectedHistory.css}</style>`)}
                                    </div>
                                    
                                )
                            }
          </div>
          <div>
            <div
              className={`${selectedHistory ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={selectedHistory ? loadHistory : null }
            >
              Load
            </div>
          </div>
        </div>)}
      </Modal>
  )}

  export default TemplateHistoryModal
       