import React, { useState } from 'react'
import Modal from "react-modal"
import "../../../../assests/styles/Modal.scss"

const UpdateSurfaceModal = (props) => {
    const {
        active,
        setActive,
        serial,
        setSerial,
        wetValue,
        setWetValue,
        dryValue,
        setDryValue,
        updateSurfaceFunc,       
    } = props

    let prefix = "0."
    const serialTyping = (e) => {
      if (
        (e.keyCode >= 8 && e.keyCode < 32) ||
        (e.keyCode >= 37 && e.keyCode <= 40) ||
        (e.keyCode >= 65 && e.keyCode <= 105) ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode == 17) || 
        (e.keyCode == 86)

      ) {
        return e.keyCode;
      } else {
       e && e.preventDefault();
      }
    };

    const serialValueHandle = (e) => {
      let currentTyping = e.currentTarget.value;
      if(currentTyping.length <= 6) {
          setSerial(currentTyping)
      }
    };

    const valueTyping = (e) => {
      if (
        (e.keyCode >= 8 && e.keyCode < 32) ||
        (e.keyCode >= 37 && e.keyCode <= 40) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode == 17) || 
        (e.keyCode == 86) ||
        (e.keyCode == 190) ||
        (e.keyCode == 46)

      ) {
        return e.keyCode;
      } else {
       e && e.preventDefault();
      }
    };

    const wetValueHanle = (e) => {
      let currentTyping = e.currentTarget.value;
      if(currentTyping.length <= 4) {
        if(currentTyping == 0) {
          currentTyping = prefix
          setWetValue(currentTyping)
        }
        if(currentTyping.length <= 1) {
            currentTyping = prefix + e.currentTarget.value
            setWetValue(currentTyping) 
        } else {
          setWetValue(currentTyping)
        } 
          
      }
    };
    const dryValueHanle = (e) => {
      let currentTyping = e.currentTarget.value;
      if(currentTyping.length <= 4) {
        if(currentTyping == 0) {
          currentTyping = prefix
          setDryValue(currentTyping) 
        }
        if(currentTyping.length <= 1) {
            currentTyping = prefix + e.currentTarget.value
            setDryValue(currentTyping) 
        } else {
          setDryValue(currentTyping)
        } 
          
      }
    };

  return(
<Modal
        isOpen={active ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { active && <div className="modal task-modal" style={{height: "fit-content", width:"50vw", justifyContent:"space-between"}}>
               

          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>ANSI A326.3 Validation Surface</h1>
          <div className="form-group-container task-form"style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}}> 
          <div style={{color:"gray"}}>Please enter the Serial # found on the back of your ANSI A326.3 validation surface</div>
          <div className="form-row" style={{marginBottom:"2rem"}}>
                <div className="form-collection textarea" style={{width:"100%"}}>
                <div className="form-label"><span>Validation Surface Serial<span style={{color:"red"}}> *</span></span></div>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <div style={{width:"2%"}}>#</div>
                <div style={{width:"98%"}}>
                <input
                        placeholder='XXXXXX'
                          onBlur={(e) => e.target.value && setSerial(e.target.value)}
                          onKeyDown={serialTyping}
                          onChange={serialValueHandle}
                          value={serial}
                          type="text"
                          className="form-input"
                          style={{marginBottom:"0"}}
                        />
                </div>
                </div>
                </div>
              </div>
              <div style={{color:"gray"}}>Please enter the Wet and/or Dry Values found on the back of your ANSI A326.3 validation surface</div>
              <div className="form-row">
                <div className="form-collection textarea" style={{width:"100%"}}>
                <div className="form-label"><span>Wet Value<span style={{color:"red"}}> *</span></span></div>
                <input
                        placeholder='0.00'
                          onBlur={(e) => e.target.value && setWetValue(Number(e.target.value))}
                          onKeyDown={valueTyping}
                          onChange={wetValueHanle}
                          value={wetValue}
                          step=".11"
                          min="0"
                          max="0"
                          type="number"
                          className="form-input"
                        />
                </div>
              </div>
              <div className="form-row">
                <div className="form-collection textarea" style={{width:"100%"}}>
                <div className="form-label"><span>Dry Value<span style={{color:"red"}}> *</span></span></div>
                <input
                        placeholder='0.00'
                          onBlur={(e) => e.target.value && setDryValue(Number(e.target.value))}
                          onKeyDown={valueTyping}
                          onChange={dryValueHanle}
                          value={dryValue}
                          step=".11" 
                          min="0"
                          max="0"
                          type="number"
                          className="form-input"
                        />

                </div>
              </div>
          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {                  
                    setSerial("")
                    setWetValue("")
                    setDryValue("")
                    setActive(null)
                }}
            >

            Cancel

            </div>
            <div
            style={{margin:".5rem"}}
              className={`${serial && (wetValue || dryValue) ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={serial && (wetValue || dryValue) ?  updateSurfaceFunc : null}
            >
              
              Update

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default UpdateSurfaceModal
       