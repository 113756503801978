import React,{useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom"
import { DataSpinner } from "../../spinner/dataSpinner";

import { Style } from "react-style-tag";
import parse from 'html-react-parser';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faPen } from "@fortawesome/free-solid-svg-icons";
import edit from "../../../../assests/icons/edit.svg"
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'
import addReportMutation from "../../../../graphql/mutations/report/addReportMutation"
import { useMutation } from "@apollo/client";
import updateAreaMutation from "../../../../graphql/mutations/area/updateAreaMutation";
import LZString from "lz-string";

import AWS from 'aws-sdk'
import ACTION_LOADING_SPINNER_ACTIVE from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import { useDispatch } from "react-redux";
import updateReportMutation from "../../../../graphql/mutations/report/updateReportMutation";
import { v4 as uuidv4 } from 'uuid';

const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
}) 
const TemplateViewer = ({reportsRefetch, user, userRefetch, usersRefetch, licensesRefetch, socket}) => {

    const dispatch = useDispatch()
    const [
        addReport,
        { data: addReportData },
      ] = useMutation(addReportMutation)
  
 
      const [
        updateReport,
        { data: updateReportData },
      ] = useMutation(updateReportMutation)
  

    const [
      updateArea,
      { data: updateAreaData },
    ] = useMutation(updateAreaMutation)

    const history = useHistory()
    const templateContainer = useRef()
    const location = useLocation()
    const [template, setTemplate] = useState(null)
    const [ defaultDCOF, setDefaultDCOF] = useState(null)
    const [ variables, setVariables] = useState(null)
    const [ allVariables, setAllVariables] = useState(null)
    const [ finalReport, setFinalReport] = useState(null)
    const [files, setFiles] = useState(null)
    const[allPdf, setAllPdf] = useState(0)
    const [imagesCount, setImagesCount] = useState([])
    const [extraCount, setExtraCount] = useState([])
    const [generateReport, setGenerateReport] = useState(0)
    const [reset, setReset] = useState(false)
    const [resetOnly, setResetOnly] = useState(false)
    const [resetTime, setResetTime] = useState(false)

   // attach prf helper functions
   
 
    useEffect(() => {
        if(location.state) {
            setDefaultDCOF(location.state.defaultDCOF)
            setVariables(location.state.variables) 
            setAllVariables(location.state.allVariables)
            setFinalReport(location.state.finalReport)
            setAllPdf(location.state.finalReport.pdfFiles?.filter(x => x.pdf)?.length)
            setGenerateReport(location.state.generate)
            setReset(location.state.reset)
            setResetOnly(location.state.resetedOnly)
            setResetTime(location.state.resetTime)
        }
    },[location] )
                
  useEffect(() => {
    if((finalReport?.tests?.length || finalReport?.resetedAreas?.length) && !generateReport) {
            if(resetOnly) {
              finalReport.resetedAreas.map(test => {           
                updateArea({ 
                   variables:{
                    _id: test.id,
                    results: null
                   }
                }).then(res => {
                    !generateReport && history.push("/admin/audits")
                }).catch(err => console.log(err))
            }) 
              } else { 
          finalReport.areas.map(test => {    
            updateArea({ 
               variables:{
                _id: test.id,
                reset: reset,
                resetTime: resetTime,
                results: JSON.stringify(finalReport?.tests.map(test => {
                    //getPdfText(test.pdf)
                    let newTest = {}
                    newTest.extra = test?.extra
                    newTest.mistake = test?.mistake
                    newTest.result = test?.result
                    newTest.results = test?.results
                    newTest.areaCode = test?.areaCode 
                    newTest.areaId = test?.areaId
                    newTest.locationCode = test?.locationCode
                    newTest.locationId = test?.locationId
                    newTest.areaCondition = test?.areaCondition
                    newTest.resultCategory = test?.resultCategory
                    newTest.pdfUrl = test?.pdfUrl
                    newTest.passed = test?.passed
                    return newTest 
                }).filter(result => result.areaId == test.id)) 
               }
            }).then(res => {
              if(finalReport.resetedAreas.length) {
                 finalReport.resetedAreas.map(test => {           
                updateArea({ 
                   variables:{
                    _id: test.id,
                    results: null
                   }
                }).then(res => {
                    !generateReport && history.push("/admin/audits")
                }).catch(err => console.log(err))
            }) 
              } else {
                !generateReport && history.push("/admin/audits")
              }
             
            }).catch(err => console.log(err))
        }) }
        
    }
  },[finalReport, generateReport])


// save report


// attach pdf helper functions

  const convertFiletoDataUrl = async (file) => new Promise(resolve => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
   reader.onload = () => {
    resolve(reader.result.normalize())
  }
})

  const timer = ms => new Promise(res => setTimeout(res, ms))
  const convertPdfPageToImage = async (pdf, pageNumber, uniqe, area, fileName, i, extra) => {
    return new Promise(resolve => {
    pdf.getPage(pageNumber).then(async (page) => {
    const viewport = page.getViewport({ scale: 1 });
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d',{ willReadFrequently: true });
    const renderContext = { canvasContext: ctx, viewport: viewport };
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    page.render(renderContext).promise.then(async () => {
    const dataUrl   = canvas.toDataURL();
    let fileName = `pdf-${new Date().toISOString()}`
    const base64Data = new Buffer.from(dataUrl.replace(/^data:image\/\w+;base64,/, ""), 'base64')
    const type = dataUrl.split(';')[0].split('/')[1];

    const params = {
        ACL: 'public-read',
        Body: base64Data,
        Bucket: S3_BUCKET,
        Key: `${fileName.replace(/\s/g, '')}.png`,
        ContentEncoding: 'base64', // required
        ContentType: `image/${type}` // required. Notice the back ticks
    };  

        myBucket.putObject(params)
        .on("complete", (evt) => {
        //   let attachments = document.querySelector(`[variable=attachments]`)
        //   var DOM_img = document.createElement("img");
        //   DOM_img.src = `https://step-us-east-prod.s3.amazonaws.com/${fileName.replace(/\s/g, '')}.png`;
        //   DOM_img.countImage = "photoDomCount"
        //   DOM_img.style.margin= "20px 20px"
        //   attachments?.appendChild(DOM_img)
          resolve()
          false ? setExtraCount([...extraCount, {index: uniqe, testNumber: pageNumber, url: `https://step-us-east-prod.s3.amazonaws.com/${fileName.replace(/\s/g, '')}.png`}]) : setImagesCount([...imagesCount, {index: uniqe, extra, testNumber: pageNumber, area: area, url: `https://step-us-east-prod.s3.amazonaws.com/${fileName.replace(/\s/g, '')}.png`}])
        })
        .send((err) => {
            if (err) console.log(err)
        })
    }) 
  });
  })}

            const convertPdfFilesToImages = async (files) => {
            pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
            const worker = new pdfjsLib.PDFWorker();
            let allPdfs = files.map(async (pdf, i, a) => {
                let file = pdf.pdf
                let area = pdf.test
                if (file?.type === 'octet/stream' || file?.type === "application/pdf") {
                    let dataUrl = await convertFiletoDataUrl(file)
                    pdfjsLib.getDocument({ url: dataUrl, worker }).promise.then(async pdf => {
                    let allPages = Array.from(Array(pdf.numPages)).map(async (_, index) => {
                    let pageNumber = index + 1
                    let uniqe = area.test.extra ? `${Number(`${area.test.areaCode}${area.test.locationCode}${area.test.fileName.slice(0,area.test.fileName.indexOf("."))}${index+1}`)}` : `${Number(area.test.areaCode)}${Number(area.test.locationCode)}${area.test.areaCondition.includes("Prevailing") ? 1 : 2}${index + 1}`
                    await convertPdfPageToImage(pdf, pageNumber,uniqe, area, file.name, index, area.test.extra)
                    await timer(2000)

            })
            })}})} 

  let ignored = ["AuditMistakes", "AddendumImages","AddendumNotes","AddendumTable","certificateImages","OneAuditorName", "OneAuditorCompany", "OneCertificateDate","AuditorCertificate", "certificate", "AuditPhotos", "MainAreaPhotos", "AuditorNotes", "areaRow" , "areaBody", "areaTable", "attachments", "appendix", "locationFailRow", "locationFailBody", "locationFailTable","locationPassRow", "locationPassBody", "locationPassTable","AreaName","FloorType","FloorTexture","FloorFinish","FloorFinish","ConditionOfFloorFinish","SlopeOfFloorSurface", "TestingConditions", "TestingCategory", "FailingLocationNumber", "PassingLocationNumber"]

  useEffect(() => { 
      if(finalReport && generateReport) {

          let areaRowRef = document.querySelector(`[variable=areaRow]`)
          let areaBodyRef = document.querySelector(`[variable=areaBody]`)
          let areaTableRef = document.querySelector(`[variable=areaTable]`)
          let locationFailRowRef = document.querySelector(`[variable=locationFailRow]`)
          let locationFailBodyRef = document.querySelector(`[variable=locationFailBody]`)
          let locationFailTableRef = document.querySelector(`[variable=locationFailTable]`)
          let locationPassRowRef = document.querySelector(`[variable=locationPassRow]`)
          let locationPassBodyRef = document.querySelector(`[variable=locationPassBody]`)
          let locationPassTableRef = document.querySelector(`[variable=locationPassTable]`)
          let appendixRef = document.querySelector(`[variable=appendix]`)
          let PrevailingCondition = document.querySelectorAll(`[condition=PrevailingCondition]`)
          let CleanCondition = document.querySelectorAll(`[condition=CleanCondition]`)
          let AllCondition = document.querySelectorAll(`[condition=AllCondition]`)

          if(finalReport.tests) {
            let somePrevailing = finalReport.tests.some(x=> x.areaCondition.toLowerCase().includes("prevailing"))
            let someClean = finalReport.tests.some(x=> x.areaCondition.toLowerCase().includes("clean"))
            
            if(somePrevailing && someClean) {
                let removeClean = [...CleanCondition].map(condition => {
                    condition.remove()
                })
            } 

            if(somePrevailing && !someClean) {
                let removeClean = [...CleanCondition].map(condition => {
                    condition.remove()
                })
                let removeCleanAll = [...AllCondition].map(condition => {
                    condition.remove()
                })
            } 

            if(!somePrevailing && someClean) {
                let removePrevailing = [...PrevailingCondition].map(condition => {
                    condition.remove()
                })
                let removeCleanAll = [...AllCondition].map(condition => {
                    condition.remove()
                })
            } 
          }

          if(areaRowRef && areaBodyRef) {
             let rowHtml = areaRowRef.innerHTML
              areaBodyRef.removeChild(areaBodyRef.childNodes[0])
              finalReport.areas.map(area => {
                  areaBodyRef.insertRow().innerHTML = rowHtml.replace("[AreaName]", area.name ? area.name : "no name").replace("[FloorType]", area.floorType ? area.floorType : "not specified").replace("[FloorTexture]", area.floorTexture ? area.floorTexture : "not specified").replace("[FloorFinish]", area.floorFinish ? area.floorFinish : "not specified").replace("[FloorFinish]", area.floorFinish ? area.floorFinish : "not specified").replace("[ConditionOfFloorFinish]", area.conditionofFloorFinish ? area.conditionofFloorFinish : "not specified").replace("[SlopeOfFloorSurface]", area.slopeofFloorsSurface ? area.slopeofFloorsSurface : "not specified")
              }) 
          }

          if(locationFailRowRef && locationFailBodyRef) {
              let locationFailRowHtml = locationFailRowRef.innerHTML
              locationFailBodyRef.removeChild(locationFailBodyRef.childNodes[0])
              let failLocations = finalReport.tests.filter(test => !test.passed)?.map((x,i) => {
                let uniqe = `${Number(x.areaCode)}${Number(x.locationCode)}${x.areaCondition.includes("Prevailing") ? 1 : 2}${i + 1}`
                    x.uniqe = uniqe
                    return x
              })?.sort((a,b) => (Number(a.uniqe) > Number(b.uniqe)) ? 1 : ((Number(b.uniqe) > Number(a.uniqe)) ? -1 : 0))
              if(failLocations && failLocations.length) {
                failLocations.map(test => {
                      locationFailBodyRef.insertRow().innerHTML = locationFailRowHtml.replace("[FailingLocationNumber]", `<div style="text-align: center;">L${test.locationCode}</div>`).replace("[AreaName]", test.areaId ? finalReport.areas.filter(x => x.id == test.areaId) ? finalReport.areas.filter(x => x.id == test.areaId)[0].name ? `<div style="text-align: center;">A${test.areaCode} -  ${finalReport.areas.filter(x => x.id == test.areaId)[0].name}</div>` : test.areaId : test.areaId : "not specified").replace("[TestingConditions]", test.areaCondition ? test.areaCondition.replace("wet", "Wet").replace("dry", "Dry") : "not specified").replace("[TestingCategory]", finalReport?.areas?.filter(x => x.id == test?.areaId)[0]?.testingCategory?.slice(0, finalReport?.areas?.filter(x => x.id == test?.areaId)[0]?.testingCategory.indexOf(":")))
                  })
              } 
          } 

          if(locationPassRowRef && locationPassBodyRef) {
              let locationPassRowHtml = locationPassRowRef.innerHTML
              locationPassBodyRef.removeChild(locationPassBodyRef.childNodes[0])
              let passLocations = finalReport.tests.filter(test => test.passed)?.map((x,i) => {
                let uniqe = `${Number(x.areaCode)}${Number(x.locationCode)}${x.areaCondition.includes("Prevailing") ? 1 : 2}${i + 1}`
                    x.uniqe = uniqe
                    return x
              })?.sort((a,b) => (Number(a.uniqe) > Number(b.uniqe)) ? 1 : ((Number(b.uniqe) > Number(a.uniqe)) ? -1 : 0))
              if(passLocations && passLocations.length) {
                passLocations.map(test => {
                      locationPassBodyRef.insertRow().innerHTML = locationPassRowHtml.replace("[PassingLocationNumber]", `<div style="text-align: center;">L${test.locationCode}</div>`).replace("[AreaName]", test.areaId ? finalReport.areas.filter(x => x.id == test.areaId) ? finalReport.areas.filter(x => x.id == test.areaId)[0].name ? `<div style="text-align: center;">A${test.areaCode} -  ${finalReport.areas.filter(x => x.id == test.areaId)[0].name}</div>` : test.areaId : test.areaId : "not specified").replace("[TestingConditions]", test.areaCondition ? test.areaCondition.replace("wet", "Wet").replace("dry", "Dry") : "not specified").replace("[TestingCategory]", finalReport?.areas?.filter(x => x.id == test?.areaId)[0]?.testingCategory?.slice(0, finalReport?.areas?.filter(x => x.id == test?.areaId)[0]?.testingCategory.indexOf(":")))
                  })
              } 
          }

          if(appendixRef) {
              finalReport.areas.map((area, i, a) => {
                  let dots = area.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).map((x, i) => {
                    // let uniqe = `${Number(x.areaCode)}${Number(x.locationCode)}${x.areaCondition.includes("Prevailing") ? 1 : 2}${index + 1}`
                    return x
                  })
                  appendixRef.classList.remove("cell")
 
                  let node = document.createElement("div")

                  node.setAttribute("cardattr", "areaCard")
                  // node.classList.add("avoid") 

                  node.style.height = "fit-content"
                  node.style.width = "fit-content"
                  node.style.margin = "10px auto"

                  let nodeEl = document.createElement("div")
                  nodeEl.setAttribute("break", "pagebreak" )

                  node.innerHTML =`
                  <div cardattr="areaCardType avoid">
                      <div cardattr="typeHeader testingDiagramHeader" style="justify-content:space-between; display:flex;">
                      <span>${area.name} ${area.areaNumber}</span> 
                      </div>
                  <div cardattr="typeContent" id="imgContainer-${i}" style="position:relative; width:350px; height:350px; margin: 0 auto;">
                  <img width="350px" height="350px" src=${area.diagramImage} />
                  </div> </div> 
                              <div cardattr="diagramDetails" style="align-items:flex-start;">
                              <div cardattr="diagramDetailsContainer"> 
                                  <div cardattr="areaCardType">
                                      <div cardattr="typeHeader">Date: ${area.createdAt ? area.createdAt : "unknown"}</div>
                                  </div>
                                  <div cardattr="areaCardType">
                                      <div cardattr="typeHeader">Created by: ${area.createdBy ? area.createdBy : "unknown"}</div>
                                </div>
                          </div>
                          </div>
                          <div id="resultTable-${i}">
                  </div>`

              appendixRef.before(node, "")
              i !== a.length - 1 ? appendixRef.before(nodeEl, "") : null

                  dots.map(shape => {
                      let top = shape[Object.keys(shape)].y - shape[Object.keys(shape)].radius
                      let left = shape[Object.keys(shape)].x - shape[Object.keys(shape)].radius
                      let dot = document.createElement("div")
                          dot.style.position = "absolute"
                          dot.style.width = `${shape[Object.keys(shape)].radius * 2.5+5}px`
                          dot.style.height = `${shape[Object.keys(shape)].radius * 2.5+5}px`
                          dot.style.top = `${top - 3}px`
                          dot.style.left = `${left - 3}px`
                          dot.style.fontWeight = "bold"
                          dot.style.borderRadius = "50%"
                      
                        let tests = finalReport.tests.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id)
                        dot.style.background =  finalReport.tests.some(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id)
                        ? tests.length == 2
                        ?  ((!tests[0]?.passed && tests[1]?.passed) || (tests[0]?.passed && !tests[1]?.passed)) 
                        ? "linear-gradient(90deg, #6DB240 50%, #EF1111 50%)"
                        : (!tests[0]?.passed && !tests[1]?.passed) 
                        ? "#EF1111" : 
                        (tests[0]?.passed && tests[1]?.passed) ?
                        "#6DB240" : "notAny" 
                        : !tests[0]?.passed
                        ? "#EF1111" 
                        : tests[0]?.passed
                        ? "#6DB240"
                        : "#ffffff" : "#ffffff" 
                                        
                      dot.innerHTML = `<span class='locationTestId' style="right:-27px; position:absolute;">${ finalReport.tests.some(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id) ? finalReport.tests.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id)[0].result ? !finalReport.tests.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id)[0].passed ? Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) : Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) : "" : ""}</span>`
                      document.getElementById(`imgContainer-${i}`).appendChild(dot)
                  }) 
                      let myTableDiv = document.getElementById(`resultTable-${i}`);
                      myTableDiv.style.height = "100%"

                      let mappedArea = finalReport.tests.filter(x => x.areaId == area.id).map((x,i) => {
                        let uniqe = `${Number(x.areaCode)}${Number(x.locationCode)}${x.areaCondition.includes("Prevailing") ? 1 : 2}${i + 1}`
                            x.uniqe = uniqe
                            return x
                      }).sort((a,b) => (Number(a.uniqe) > Number(b.uniqe)) ? 1 : ((Number(b.uniqe) > Number(a.uniqe)) ? -1 : 0))
                    
                          mappedArea.map((y, i) => {
                          let areaTest = mappedArea[i].results?.reverse().map(x => {
                            x.condition = y.areaCondition.replace("wet", "Wet").replace("dry", "Dry")
                            return x
                          })
                          let table = document.createElement('TABLE');
                          table.classList.add("avoid")
                          table.border = '1';
                          table.classList.add("i4njnc")
                          table.style.width= "100%"
                          table.style.border = "1px solid rgb(223, 224, 235)"
                          table.style.borderRadius = "8px"


                          // let tableHeader = document.createElement("THEAD")
                          let tableBody = document.createElement('TBODY');

                          // table.appendChild(tableHeader)

                          let trh = document.createElement('TR');
                          // tableHeader.appendChild(trh)

                          tableBody.appendChild(trh)
                          let header = [
                              {value:"date",name: "Date"},
                              {value:"time",name: "Time"},
                              {value:"area",name: "Area"},
                              {value:"location",name: "Location"},
                              {value:"condition",name: "Condition"},
                              {value:"testNumber",name: "Sample"},
                              {value:"result",name: "Average DCOF"},
                          ]
                          
                          for (let j = 0; j < 7; j++) {
                              // let th = document.createElement('TH');
                              let th = document.createElement('TD');
                              th.style.padding = '5px';
                              th.style.fontWeight = 'bold';
                              th.innerHTML = `${header[j].name}`
                              trh.appendChild(th);
                            }

                          table.appendChild(tableBody);

                          
                        
                          for (let n = 0; n < 3; n++) {
                            let row = areaTest[n]
                            let tr = document.createElement('TR');
                            tableBody.appendChild(tr);
                        
                            for (let j = 0; j < 7; j++) {
                              let td = document.createElement('TD');
                              td.style.padding = '5px';
                              td.innerHTML = `${row[Object.keys(row)[Object.keys(row).indexOf(header[j].value)]]}`
                              tr.appendChild(td);
                            }
                          }
                          myTableDiv.appendChild(table);
                      })
                      
              })
          }

          convertPdfFilesToImages(finalReport.pdfFiles?.filter(x => x))
      }
  },[finalReport, files, generateReport])

  // replace variables
  useEffect(() => {
      if(allVariables && generateReport) {
          allVariables.map(variable => {
              if(variable) {
              let allNodes = document.querySelectorAll(`[variable=${variable}]`)
                 if(allNodes) {
                    [...allNodes].map(node => {
                      if(!ignored.some(x=> x == variable)) {
                      if (variable == "redLine") {
                          node.innerHTML = ""
                      } else {
                      if(variables[variable]) {
                      node.innerHTML = `${variables[variable]}`
                      } else {
                      node.innerHTML = ` blank `
                      }
                  }
                  }
                  }) 
                 } 
              }
          })
      }
  },[allVariables, generateReport])

  // replace tables
  useEffect(() => {
      if(variables && generateReport) {
        let conditionsAllPass = document.querySelectorAll(`[condition=allPass]`)
        let conditionsFail = document.querySelectorAll(`[condition=fail]`)
        let conditionsAllFail = document.querySelectorAll(`[condition=allFail]`)
        let conditionsPass = document.querySelectorAll(`[condition=pass]`)
        let certificates = document.querySelectorAll(`[condition=certificate]`)
        let addendum = document.querySelectorAll(`[condition=addendum]`)
        
        let mistakes = document.querySelectorAll(`[condition=mistakes]`)
        let notMistakes = document.querySelectorAll(`[condition=notMistakes]`)

        let finishDescription = document.querySelectorAll(`[condition=finishDescription]`)
        let notFinishDescription = document.querySelectorAll(`[condition=notFinishDescription]`)


        let typeDescription = document.querySelectorAll(`[condition=typeDescription]`)
        let notTypeDescription = document.querySelectorAll(`[condition=notTypeDescription]`)

        let certificateImage = document.querySelector(`[variable=AuditorCertificate]`)
        let certificateImages = document.querySelector(`[variable=certificateImages]`)
        let auditPhotos = document.querySelector(`[variable=AuditPhotos]`)
        let areaMainPhotos = document.querySelector(`[variable=MainAreaPhotos]`)
        let auditorNotes = document.querySelector(`[variable=AuditorNotes]`)
        let auditMistakes = document.querySelector(`[variable=AuditMistakes]`)
        let addendumNotes = document.querySelector(`[variable=AddendumNotes]`)
        let addendumTable = document.querySelector(`[variable=AddendumTable]`)

        let validationSurfaceDry = document.querySelectorAll(`[condition=ValidationSurfaceDry]`)
        let validationSurfaceWet = document.querySelectorAll(`[condition=ValidationSurfaceWet]`)

        if(variables.finishDescription) {
          let remove = [...notFinishDescription].map(condition => {
              condition.remove()
          })

      } else {
        let remove = [...finishDescription].map(condition => {
          condition.remove()
      })
      }

      if(variables.typeDescription) {
        let remove = [...notTypeDescription].map(condition => {
            condition.remove()
        })

    } else {
      let remove = [...typeDescription].map(condition => {
        condition.remove()
    })
    }

        
        if(variables.AuditMistakes?.length) {
          let remove = [...notMistakes].map(condition => {
              condition.remove()
          })

      } else {
        let remove = [...mistakes].map(condition => {
          condition.remove()
      })
      }

                        if(!variables.addendum) {
                        let remove = [...addendum].map(extra => {
                            extra.remove()
                        })
                        } else {
                          variables.extraTests?.filter(x=>x)?.map((x,i) => {
                            let uniqe = `${Number(x?.areaCode)}${Number(x?.locationCode)}${x?.areaCondition?.includes("Prevailing") ? 1 : 2}${i + 1}`
                                x.uniqe = uniqe
                                return x
                          }).sort((a,b) => (Number(a?.uniqe) > Number(b?.uniqe)) ? 1 : ((Number(b?.uniqe) > Number(a?.uniqe)) ? -1 : 0)).map(x => {
                          let results =  x?.results.sort((a,b) => a?.testNumber - b?.testNumber)
                          let table = document.createElement('table');
                          table.classList.add("avoid")
                          // table.classList.add("boxContainer")
                          table.border = '1';
                          table.classList.add("i4njnc")
                          table.style.width= "100%" 
                          table.style.border = "1px solid rgb(223, 224, 235)"
                          table.style.borderRadius = "8px"

                          // let tableHeader = document.createElement("THEAD")
                          let tableBody = document.createElement('TBODY');

                          // table.appendChild(tableHeader)

                          let trh = document.createElement('TR');
                          // tableHeader.appendChild(trh)
                          tableBody.appendChild(trh)
                          let ignoredT = ["avg1",	"avg2",	"avg3",	"avg4"]
                          let ignoredO = ["type",	"condition",	"testNumber"]

                          let header = Object.keys(results[0]).filter(x => !ignoredT.some(y => y == x)).filter(x => {
                            if(results.length == 1) {
                              return !ignoredO.some( y => y == x)
                            } else {
                              return  x
                            }
                          })
                          
                          for (let j = 0; j < header.length; j++) {
                              // let th = document.createElement('TH');
                              let th = document.createElement('TD');
                              th.style.padding = '15px';
                              th.style.fontWeight = 'bold';
                              th.style.textTransform="capitalize"
                              th.innerHTML = `${header[j]}`
                              trh.appendChild(th);
                            }

                          table.appendChild(tableBody);

                          for (let n = 0; n < results.length; n++) {
                            let row = results[n]
                            let tr = document.createElement('TR');
                            tableBody.appendChild(tr);
                        
                            for (let j = 0; j < header.length; j++) {
                              let td = document.createElement('TD');
                              td.style.padding = '15px';
                              td.innerHTML = `${row[Object.keys(row)[Object.keys(row).indexOf(header[j])]]}`
                             
                              tr.appendChild(td);
                            
                          }
                        }
                          addendumTable.appendChild(table);
                        })
          }

        
        if(!variables.ValidationSurfaceWet) {
          let remove = [...validationSurfaceWet].map(condition => {
              condition.remove()
          })
      }

      if(!variables.ValidationSurfaceDry) {
        let remove = [...validationSurfaceDry].map(condition => {
            condition.remove()
        })
     }

        let haveTile = document.querySelectorAll(`[condition=tile]`)
        let notHaveTile = document.querySelectorAll(`[condition=notTile]`)


        if(!variables.certificate || variables.certificate.length == 0) {
            let remove = [...certificates].map(condition => {
                condition.remove()
            })
        } else { 
            if(certificateImage) {
               let newCertificate = certificateImage.innerHTML

            if(certificateImages) {
              certificateImage.classList.remove("cell")
            }
            variables.certificate.map((x,i) => {
                certificateImages.innerHTML = i == 0 ? `<div class="avoid"><img width="100%" height="100%" src=${x.certificate} /></div> ${x.letter ? `<div class="avoid"><img width="100%" height="100%" src=${x.letter} /></div>` : ""}`: certificateImages.innerHTML + `<div class="avoid"><img width="350px" height="350px" src=${x.certificate} /></div> ${x.letter ? <div class="avoid"><img width="350px" height="350px" src={x.letter} /></div> : ""}`
                certificateImage.innerHTML = i == 0 ? newCertificate.replace("[OneAuditorName]", x.name).replace("[OneAuditorCompany]", x.company).replace("[OneCertificateDate]", x.certificateDate): certificateImage.innerHTML + newCertificate.replace("[OneAuditorName]", x.name).replace("[OneAuditorCompany]", x.company).replace("[OneCertificateDate]", x.certificateDate)

            } )   
            }
                      
        }  

        if(variables.AuditPhotos?.length) {
            variables.AuditPhotos.map((x,i) => {
              if(auditPhotos) {
                auditPhotos.innerHTML = auditPhotos.innerHTML + `<img key={${i}} width="250px" height="250px" style="margin: 10px;" src=${x} />`
              }
            })
        }

        if(variables.AreaMainPhotos?.length) {
            variables.AreaMainPhotos?.sort((a,b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0)).map((x,i, a) => {
              let node = document.createElement("div")
              node.setAttribute("cardattr", "cardAreaContainer")
              node.classList.add("avoid")
              node.style.minHeight = "auto"
              node.style.margin = "10px auto"
              node.style.padding = "10px"
              node.innerHTML = `
              <div cardattr="upperCardArea" style="min-height:auto;">
                              <div cardattr="areaImageText">${x.name}</div>
                              <div cardattr="areaImageText"><span style="color: gray; font-size:18px">Area:</span> ${x.number}</div>
                              <div cardattr="areaImageText"><span style="color: gray; font-size:18px">Locations:</span> ${x.locations}</div>
                              <div cardattr="areaImageText"><span style="color: gray; font-size:18px">Testing Category:</span> ${x.testingCategory}</div>
              </div>
              <div cardattr="areaImageLower"> 
                              <img cardattr="areaMainImage" key={${i}}  src=${x.image} />
              </div>
              `
              let nodeEl = document.createElement("div")
              nodeEl.setAttribute("break", "pagebreak" )

                areaMainPhotos?.before(node, "")
                i !== a.length - 1 ? areaMainPhotos?.before(nodeEl, "") : null
            }) 
        }

        if(variables.tile) {
            let removeNote = [...notHaveTile].map(condition => { 
                condition.remove()
            })
        } else {
            let removeTile = [...haveTile].map(condition => { 
                condition.remove()
            }) 
        } 
        
        if(variables.AuditorNotes) {
          variables.AuditorNotes.map((x,i) => {
              auditorNotes.innerHTML = auditorNotes.innerHTML + " <br/> " + " <div> " + x.replaceAll("*", "<br/> -").replace("Floor Type Description", "<div style='font-weight:bold; display:inline-block;'>Floor Type Decription</div>").replace("Floor Finish Description", "<div style='font-weight:bold; display:inline-block;'>Floor Finish Decription</div>") + " </div> " || "No Comment"
          }) 
      } 

        if(variables.AuditMistakes) {
            variables.AuditMistakes.map((x,i) => {
                auditMistakes.innerHTML = auditMistakes.innerHTML + " <br/> "  + `<div style="font-weight:bold">${x.content}</div>`
            }) 
        }

        if(variables.allFail) {
            let removePass = [...conditionsPass].map(condition => {
                condition.remove() 
            })

            let removeAllPass = [...conditionsAllPass].map(condition => {
                condition.remove()
            })
        }
        if(variables.allPass) {
            let removeFail = [...conditionsFail].map(condition => {
                condition.remove()
            })

            let removeAllFails = [...conditionsAllFail].map(condition => {
                condition.remove()
            })
        }
        if(!variables.allPass && !variables.allFail) {
            let removeAllPass = [...conditionsAllPass].map(condition => {
                condition.remove()
            })
            let removeAllFails = [...conditionsAllFail].map(condition => {
                condition.remove()
            })
        }
      
      }
  },[variables , generateReport])

// save report
  const addReportFunc = () => {  
    addReport({
      variables:{
        finalReport: JSON.stringify(finalReport),
        user: user?.user?._id,   
        wmg: variables.isWmg, 
        dateOfAudit:  variables.AuditDate,
        dateOfReportGeneration: variables.DateReportGenerated,
        view: variables.view,
        questionnaire: variables.questionnaire,
        note: variables.beforeAndAfter ? "Validation Surface failed" : "",
        type: "DCOF", 
        status: "pending",
        html: "testss",
        css: "testff",
        ...variables.clientObj,
        ...variables.siteObj 
      } 
    }).then((res) => {
      if(res?.data?.addReport?.notification) {
       socket?.emit("new message", res?.data?.addReport?.notification)
      }
      const _id = res?.data?.addReport?._id
      if(_id) {
      let html = `${_id}-html.html`
      let css = `${_id}-css.css` 
      const params = {
        ACL: 'public-read',
          Body: templateContainer.current.innerHTML,
          Bucket: S3_BUCKET,
          Key: html,
      };
  
      myBucket.putObject(params)
          .on("complete", (evt) => {
            const params = {
              ACL: 'public-read',
                Body: defaultDCOF.css,
                Bucket: S3_BUCKET,
                Key: css,
            }; 
        
            myBucket.putObject(params)
                .on("complete", (evt) => {
                  updateReport({
                    variables:{
                      _id: _id,
                      html: html,
                      css: css,
                    }
                  }).then(() => {
                    usersRefetch()
                    licensesRefetch()
                    userRefetch()
                    reportsRefetch().then((res) => {
                      history.push("/admin/reports")
                    })
                  })
                })
                .send((err) => {
                    if (err) console.log(err)
                }) 
          })
          .send((err) => {
              if (err) console.log(err)
          }) 
  } else {
    history.push("/admin/audits")
  }
})
.catch(err => console.log(err))

    }

    const [newImagesCount, setNewImagesCount] = useState([])
    const [newExtraCount, setNewExtraCount] = useState([])

    useEffect(() => {
        if(imagesCount) {
            setNewImagesCount([...imagesCount, ...newImagesCount])
        } 
    },[imagesCount])

    useEffect(() => {
      if(extraCount) {
          setNewExtraCount([...extraCount, ...newExtraCount])
      } 
  },[extraCount])

    const getConst = (coName, con) => {
        let co = !con ? coName.slice(0, coName.indexOf(":")) : coName
        if(co == "Interior, Dry") {
          return 0.42
        } else if(co == "Interior, Wet") { 
          return 0.42
        } else if(co == "Interior, Wet Plus") {
          return 0.50
        } else if(co == "Exterior, Wet") {
          return 0.55
        } else if(co == "Oils/Greases") {
          return 0.55
        }
      }

      // pdf to images to the report
  useEffect(() => { 
    if(newImagesCount) {
      let newImagesArrCount = newImagesCount ? newImagesCount?.length : 0
      if(generateReport && generateReport == newImagesArrCount && !addReportData) {
        let newOrderedArr = newImagesCount.sort((a,b) => a.index - b.index)
        let attachments = document.querySelector(`[variable=attachments]`)
        let addendumImages = document.querySelector(`[variable=AddendumImages]`)
          newOrderedArr.map(async (x, i, a) => {
          let area = finalReport.areas.find(y => y?.id == x?.area?.test?.areaId)
          let test = x?.area?.test?.results.find(y => y?.testNumber == x?.testNumber)

          let IMG_container = document.createElement("div")
         
          IMG_container.style.position= "relative"
          IMG_container.classList.add("avoid")


          let IMG_info_container = document.createElement("div")
          IMG_info_container.style.width = "100%"
          IMG_info_container.style.height = "100%"
          IMG_info_container.style.margin= "0 auto"
          // IMG_info_container.style.position = "absolute"
          // IMG_info_container.style.top = "0"
          // IMG_info_container.style.right = "0"
          // IMG_info_container.style.left = "0"
          IMG_info_container.style.display = "flex"
          IMG_info_container.style.flexDirection = "column"
          IMG_info_container.style.justifyContent = "space-between"
          IMG_info_container.style.marginTop = "0px"
          IMG_info_container.style.marginBottom = i == 0 ? "0" : "10px"

          let IMG_info_left = document.createElement("div")
          IMG_info_left.style.width = "65%"
          IMG_info_left.style.height = "120px"
          IMG_info_left.style.border = "2px solid gray"
          IMG_info_left.style.display = "flex"
          IMG_info_left.style.justifyContent = "space-evenly"
          IMG_info_left.style.flexDirection = "column" 
          IMG_info_left.style.padding = "0 10px"

          let areaName = document.createElement("div")
          areaName.innerHTML = `Area Name : ${area?.name || "UNKNOWN"}`
          
          let areaCategory = document.createElement("div")
          areaCategory.innerHTML = `Use Category : ${area?.testingCategory || "UNKNOWN"}`

          IMG_info_left.append(areaName, areaCategory)
          IMG_info_container.appendChild(IMG_info_left)
          let IMG_info_right = document.createElement("div")
          IMG_info_right.style.width = "30%"
          IMG_info_right.style.height = "120px"
          IMG_info_right.style.border = "2px solid gray"
          IMG_info_right.style.display = "flex"
          IMG_info_right.style.justifyContent = "space-evenly"
          IMG_info_right.style.alignItems = "center"
          
          let testSample = document.createElement("div")
          testSample.innerHTML = test?.testNumber ? `Sample ${test.testNumber}` : null
          testSample.style.transform = `rotate(270deg)`
          
          let testResult = document.createElement("div")
          testResult.innerHTML = `${test?.result}`
          testResult.style.padding = `15px`
          testResult.style.width = "60px"
          testResult.style.height = "80px"
          x.extra ? null : area ? testResult.style.background = test.result < getConst(area.testingCategory) ? "#EF1111" :  "#6DB240" : null
          testResult.style.color = "white"
          testResult.style.fontWeight = "bold"
          testResult.style.display = "flex"
          testResult.style.justifyContent = "center"
          testResult.style.alignItems = "center"

          
          IMG_info_right.append(testSample, testResult)


          let appendixB = document.createElement("div")
          appendixB.innerHTML = i == 0 ? "Appendix B" : " "
          appendixB.style.width = "100%"
          appendixB.style.textAlign = "center"
          appendixB.style.fontSize = "30px"
          appendixB.style.fontWeight = "bold"
          appendixB.style.height = i == 0 ? "fit-content" : "0px"


          let info_container = document.createElement("div")
          info_container.style.width = "100%"
          info_container.style.display = "flex"
          info_container.style.justifyContent = "space-between"
          // info_container.style.position = i == 0 ? "static" : "absolute"
          // info_container.style.top = i == 0 ? "auto" : "0"
          info_container.style.height = "120px"
          info_container.style.justifyContent = "space-between"
          
          info_container.appendChild(IMG_info_left)
          info_container.appendChild(IMG_info_right)

         if(!x.extra) { 
            if(i == 0) {
              IMG_info_container.appendChild(appendixB)
              IMG_info_container.appendChild(info_container)
            } else {
              IMG_info_container.appendChild(appendixB)
              IMG_info_container.appendChild(info_container)
            }
            }
          
            x.extra ? null : IMG_container.appendChild(IMG_info_container)

          var DOM_img = document.createElement("img");
          DOM_img.src = x.url;
          DOM_img.countImage = "photoDomCount"
          DOM_img.style.border= "2px solid gray"
          DOM_img.style.width= "100%"

          IMG_container.appendChild(DOM_img) 
          let nodeEl = document.createElement("div")
          nodeEl.setAttribute("break", "pagebreak" )

          x.extra ? addendumImages.appendChild(nodeEl):attachments?.appendChild(nodeEl)
          x.extra ? addendumImages.appendChild(IMG_container) :attachments?.appendChild(IMG_container)
          if(i == a.length - 1) {
          attachments.setAttribute("class", "row")
          attachments.setAttribute("variable", "testt")
          attachments.setAttribute("id", "miwndiqnwf")
          
          addReportFunc()
          }
        })  
      } 
    }
  },[newImagesCount , generateReport]) 

  useEffect(() => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  },[])

    return(
        <div className="adminContent">
          <DataSpinner 
          all={true} 
          over={true}
          />
            { generateReport ? !defaultDCOF ? <DataSpinner small="l" /> : (
                <div ref={templateContainer} style={{width:"100%", height:"100%"}}>
                <Style>{defaultDCOF.css}</Style>
                {parse(`${defaultDCOF.html}`)}
               </div>
            ) : null}
        </div>
    ) 
}
 
export default TemplateViewer 