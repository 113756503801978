import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BeforeLoadModal = (props) => {
    const {
        exist,
        draft,
        beforeLoadModal,
        setBeforeLoadModal,
        beforeLoadAreaFunc,
        noHandleClick
        } = props

 
  return(
<Modal
        isOpen={beforeLoadModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal">
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => setBeforeLoadModal(null)}
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Update to continue</h1>

          {/* {exist ? <h1 className="header" style={{width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>{ draft ? "Continue editing!" :"Do you want to edit the area before load it"}</h1> : null } */}

          <div className="form-row" style={{flexDirection:"column", marginBottom:"20px"}}>
            <div className="form-collection" style={{width:"100%", fontWeight:"bold"}}>
               You will be asked to edit the following fields
            </div>
            <div className="form-collection" style={{width:"100%"}}>
            - You will give the Area a name <span style={{fontStyle:"italic"}}>(to differentiate it from other Areas in the Audit)</span>.
            </div> <div className="form-collection" style={{width:"100%"}}>
            - You will assign a number called an Area Identification number of AID, which is unique to this Area and is the same as the Area number chosen on the BOT-3000E.
            </div> <div className="form-collection" style={{width:"100%"}}>
            - You will be asked to add a photo of each Area.
            </div>
            </div>            
            {exist || draft ? (
                <div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>
                <div
                
                    className="auth-button addUser confirmDelete"
                    onClick={() => {
                        beforeLoadAreaFunc()
                        setBeforeLoadModal(null)
                    
                    }}
                >

                Continue

                </div>

            </div>
            ) : (<div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>

                <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {                 
                noHandleClick()
                setBeforeLoadModal(null)
                }} 
                >
                No
                </div> 

                <div 
                
                    className="auth-button addUser confirmDelete"
                    onClick={() => {
                        beforeLoadAreaFunc()
                        setBeforeLoadModal(null)
                    
                    }}
                >

                Yes edit

                </div>

            </div>)}
        </div>
      </Modal>
  )}

  export default BeforeLoadModal
       