import { gql } from "apollo-boost";

const addTemplateMutation = gql`
  mutation(
    $type: String,
    $name: String,
    $default: Boolean,
    $html: String,
    $css: String,
    $thumbnail: String,
    $by: String,
  ) {
    addTemplate(
    type:$type
    name:$name
    default:$default
    html:$html
    css:$css
    thumbnail: $thumbnail
    by: $by
    ) {
    _id
    type
    name
    default
    html
    css  
    thumbnail
    by
    }
  }
`;

export default addTemplateMutation;
