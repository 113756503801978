import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConditionModal = ( props) => {
    const {
        open,
        setOpen,
        selectCondition
        } = props

        const [condition, setCondition] = useState(null)

        useEffect(() => {
            if(open) {
                if(open.condition) {
                    setCondition(open.condition)
                }
            }
        },open)

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"999999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal task-modal" style={{height:"100%", width:"44vw", justifyContent:"space-between"}}>
              <FontAwesomeIcon 
                className="modal_x"
                icon={faTimes}
                onClick={() => setOpen(null)}
              /> 
            <h1 className="header" style={{width:"100%", textTransform:"capitalize" , fontWeight:"bolder", fontSize:"17px"}}>Select Condition for this area</h1>
            <div style={{alignItems:"center", height:"80%", overflow:"auto", flexDirection:"column", width:"100%"}} className="form-group-container task-form"> 
                <div style={{width:"100%", height:"50%", display:"flex",}}>
                    <div className='optionCard'>
                        <div className={`optionName pw ${condition == "Prevailing/wet" && "activeCard"} `} onClick={() => setCondition("Prevailing/wet")}>Prevailing/Wet</div>
                    </div>
                    <div className='optionCard'>
                        <div className={`optionName pd ${condition == "Prevailing/dry" && "activeCard"} `} onClick={() => setCondition("Prevailing/dry")}>Prevailing/Dry</div>
                    </div>
                </div>
                <div style={{width:"100%", height:"50%", display:"flex"}}>
                    <div className='optionCard'> 
                        <div className={`optionName cw ${condition == "Clean/wet" && "activeCard"} `} onClick={() => setCondition("Clean/wet")}>Clean/Wet</div>
                    </div>
                    <div className='optionCard'>
                        <div className={`optionName cd ${condition == "Clean/dry" && "activeCard"} `} onClick={() => setCondition("Clean/dry")}>Clean/Dry</div>
                    </div>
                </div>
             
            </div>
                <div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>
                    <div></div>
                <div
                
                    className="auth-button addUser confirmDelete"
                    onClick={() => {
                         selectCondition(open, condition)
                         setCondition(null)
                    }}
                >

                proceed

                </div>
            </div>
        </div>
      </Modal>
  )}

  export default ConditionModal
       