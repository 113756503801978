import { faEllipsisV, faEnvelope, faInfoCircle, faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { AccountTable as ClientsTable } from "../../resuable/accountTable";
import addClientMutation from "../../../graphql/mutations/client/addClientMutation"
import updateClientStatusMutation from "../../../graphql/mutations/client/updateClientStatusMutation"
import updateClientMutation from "../../../graphql/mutations/client/updateClientMutation"
import deleteClientMutation from "../../../graphql/mutations/client/deleteClientMutation"
import blank from "../../../assests/images/blank.png"
import { useMutation } from "@apollo/react-hooks";
import {useDispatch, useSelector} from "react-redux"
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import AccountHeader from "../../resuable/accountHeader"
import "./adminClients.scss"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import ACTION_BACK_ROUTE from "../../../actions/route/ACTION_BACK_ROUTE"
import ACTION_BACK_ROUTE_RESET from "../../../actions/route/ACTION_BACK_ROUTE_RESET"
import { useLazyQuery } from "@apollo/client";

import imageCompression from "browser-image-compression";
import LZString from "lz-string";
import ClientSites from "./clientSites";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import allSitesQuery from "../../../graphql/queries/site/allSitesQuery";
import AddClientModal from "../../resuable/modals/addClientModal";
import ACTION_MENU_CLICKED from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED"
import ACTION_MENU_CLICKED_RESET from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED_RESET"
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const AdminClients = (props) => {
    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const history = useHistory()

    const {
        user,
        clients,
        clientsRefetch,
        allSitesRefetchDashboard
    } = props
    const menu_clicked = useSelector((state) => state.menuClicked.menu_clicked);
    const [sitesData, setSitesData] = useState(null)
    const dispatch = useDispatch()
    const [toggleChecked, setToggleChecked] = useState(false)

    const[accountData, setAccountData] = useState(null)
        
    const backRoute = useSelector((state) => state.backRoute.backRoute);

    const subScreen = (row) => {
        allSites({
          variables: {
            client: row.id
          }
        })
        dispatch(ACTION_BACK_ROUTE({backRoute: "clients", routeName: "Client Details"}))
        setSelectedClient(row)
      } 
      
      const [siteModal, setSiteModal] = useState(null)

      const subScreenById = (id, back) => {
        allSites({
          variables: {
            client: id
          }
        })
        // dispatch(ACTION_BACK_ROUTE({backRoute: "clients", routeName: "Client Details"}))
        setSelectedClient(accountData?.find(x => x.id == id))
        !back ? setSiteModal("Site") : null
      } 
      const menuToggle = (row) => {
         menu_clicked ? dispatch(ACTION_MENU_CLICKED_RESET()) : dispatch(ACTION_MENU_CLICKED(row))
        }
      const accountColumns = [
        // {
        //     cell: (row) => <div className="dotsVContainer">
        //     <FontAwesomeIcon style={{cursor:"pointer"}} onClick={() => { 
        //       menuToggle(row)
        //     }} icon={faEllipsisV} /> 
        //     {menu_clicked && menu_clicked.id == row.id && ( 
        //       <div className="optionMenuContainer">
        //           <div className="option">Edit</div>
        //           <div className="option">Add Site</div>
        //           <div className="option archive">Archive</div>
        //       </div>
        //     )}
        //     </div>,
        //     ignoreRowClick: true,
        //     allowOverflow: false,
        //     button: true,
        //     style:{
        //         fontSize:"19px",
        //         color:"#505463"
        //     }
        // },
        {
            name: 'logo',
            cell: row => (
              <img
              onClick={() => subScreen(row)}
                    className="adminAvatar"
                    src={row.image ? row.image : blank}
                    alt={
                      row.name
                    }
                  />
            ),
            width: "50px"

            
        },
        {
            name: 'company',
            selector: row => row.company,

        },
        {
            name: 'contact person',
            selector: row => row.contact,

        },
        {
            name: 'email',
            width:"200px",
            cell: row => (
              <div onClick={() => subScreen(row)}>{row.email}</div>
            ),
        },
        {
            name: 'Mobile phone number',
            width:"200px",
            cell: row => (
              <div onClick={() => subScreen(row)}> {row.phone ? row.phone : "not provided"} </div>
            ),

        },
        {
          name: 'Work phone number',
          width:"200px",
          cell: row => (
            <div onClick={() => subScreen(row)}> {row.phoneWork ? row.phoneWork : "not provided"} </div>
          ),

      },
        {
            name: 'address',
            selector: row => row.address,

        },
        {
          name: 'zip code',
          selector: row => row.zip,

      },
        {
          name: 'status',
          cell: (row) => (
              <label style={{cursor:"pointer"}} onClick={() => updateClientStatusFunc(row)} className="toggle-control">
                  <span className={`${row.status ? "checked" : "notChecked"}`}></span>
                  <span className="control"></span>
              </label>
              ),
          ignoreRowClick: true,
          allowOverflow: false,
          button: true,
          style:{
              fontSize:"19px"
          }
          
      },
    ];
    
    const [
        addClient,
        { data: addClientData },
      ] = useMutation(addClientMutation)
  
        
  const [
    allSites,
    {
      data: allSitesData,
      refetch: allSitesRefetch,
      loading: allSitesLoading
      
    },
  ] = useLazyQuery(allSitesQuery, {
    fetchPolicy: "no-cache",
  });

  const [sites, setSites] = useState(null)
  useEffect(() => {
    if(allSitesData) {
      setSites(allSitesData.allSites)
    }
  },[allSitesData, backRoute])

  useEffect(() => {
    if(user && accountData) {
      if(user?.user?.client) {
        subScreenById(user.user.client, true)
      }
    }
  },[user, accountData])
       
    const [clientModal, setClientModal] = useState(null)
    const [editClientModal, setEditClientModal] = useState(null)
    const [companyName, setCompanyName] = useState("")
    const [companyEmail, setCompanyEmail] = useState("")
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState("")
    const [companyWorkPhoneNumber, setCompanyWorkPhoneNumber] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [contactPersonName, setContactPersonName] = useState("")
    const [contactPersonFirstName, setContactPersonFirstName] = useState("")
    const [contactPersonLastName, setContactPersonLastName] = useState("")


    const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [selectedClient, setSelectedClient] = useState(null)


    const inviteUser = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setClientModal(null)
      addClient({
        variables:{
            companyName: companyName,
            companyAddress: `${companyAddress}, ${city}, ${state}, ${country}`,
            contactPersonName: contactPersonFirstName + " " + contactPersonLastName,
            contactPersonEmail: companyEmail,
            contactPersonPhoneNumber : companyPhoneNumber,
            contactPersonWorkPhoneNumber : companyWorkPhoneNumber,
            image: images?.map(x => x.url)[0],
            zip: zip,
            user: user?.user?.role == "user" ? user?.user?._id : user?.user?.role == "sub-user" ? user?.user?.owner : null
        }
      })
      .then((res) => {
        changeImageUploaded("");
        setAddProfileClicked(false)
        changeImagePreviewAvailable(false);
        changeTakeAPhotoSelected(false);
        changeWebcamURI("");
        setCompanyName("")
        setCompanyEmail("")
        setCompanyPhoneNumber("")
        setCompanyWorkPhoneNumber("")
        setCompanyAddress("")
        setCity("")
        setCountry("")
        setState("")
        setZip && setZip("")
        setContactPersonName("")
        clientsRefetch()
        if(backToAudit) {
          //setBackToAudit(false)
          history.push({
            pathname:"/admin/clients",
            state:{
              clientId: res.data.addClient._id,
              addNewSite: true
            }
          })
          //history.push("/admin/audits")
        }
      })
      

    }
 
    
    useEffect(() => {
      if(!backRoute) {
        setSelectedClient(null)
        setSitesData(null)
        setSites(null) 
      }
    },[backRoute])
 

    // logo props

        
    const [takeAPhotoSelected, changeTakeAPhotoSelected] = useState(false);
    const [webcamURI, changeWebcamURI] = useState("");
    const [imageUploaded, changeImageUploaded] = useState("");
    const [imagePreviewAvailable, changeImagePreviewAvailable] = useState(false);
    const [addProfilePhotoClicked, setAddProfileClicked] = useState(false)

    
    const handleDeletedPreviewImage = () => {
        const deleteImageClass = document.getElementsByClassName("deleteImage");
        const uploadPictureClass = document.getElementsByClassName("uploadPicture");
    
        if (deleteImageClass) {
          if (deleteImageClass[0]) {
            deleteImageClass[0].style.display = "none";
          }
        }
        if (uploadPictureClass) {
          if (uploadPictureClass[0]) {
            uploadPictureClass[0].style.display = "none";
          }
        }
      };
    
  const handleImageUploaded = async (picture) => {
    // dispatch(ACTION_IMAGE_LOADING());
    if (picture[0] || typeof picture === "string") {
      const reader = new FileReader();
      changeImagePreviewAvailable(true);
      try {
        let compressedImage;

        if (typeof picture === "object") {
          compressedImage = await imageCompression(picture[0], {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 300,
          });
        } else if (typeof picture === "string") {
          await fetch(picture)
            .then((res) => {
              return res.blob();
            })
            .then(async (blob) => {
              compressedImage = await imageCompression(blob, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 300,
              });
            });
        }

        reader.readAsDataURL(compressedImage);

        reader.onloadend = async () => {
          const base64data = reader.result;

          const compressedBase64data = LZString.compressToEncodedURIComponent(
            base64data
          );
        //   dispatch(ACTION_IMAGE_LOADING_RESET());
          changeImageUploaded(compressedBase64data);
        };
      } catch (error) {
        // dispatch(ACTION_IMAGE_LOADING_RESET());
        console.log(error);
      }
    } else {
    //   dispatch(ACTION_IMAGE_LOADING_RESET());
      changeImageUploaded("");
      changeImagePreviewAvailable(false);
      handleDeletedPreviewImage();
    }
  };


  
  // toggle client status

  
  const [
    updateClientStatus,
    { data: updateClientStatusData },
  ] = useMutation(updateClientStatusMutation)

  

  const updateClientStatusFunc = (row) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    const newRow = row
    newRow.status = !newRow.status
    setAccountData(accountData.map(t => t.id === newRow.id ? newRow : t))
    updateClientStatus({
        variables:{
          _id: newRow.id,
          status: newRow.status
        }
      }).then(() => {
        dispatch(ACTION_LOADING_SPINNER_RESET())
        clientsRefetch()
      })
  }
    

  // delete client


  const [
    deleteClient,
    { data: deleteClientData },
  ] = useMutation(deleteClientMutation)

  

  const deleteClientFunc = (row) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())

      deleteClient({
        variables:{
          _id: row.id,
        }
      }).then(() => {
        setSelectedClient(null)
        dispatch(ACTION_BACK_ROUTE_RESET())
        clientsRefetch()
      })
  }
    

  

  // edit client func.

  const editClient = (client) => {    
    setCompanyPhoneNumber(client.phone)
    setCompanyWorkPhoneNumber(client.phoneWork)
    setCompanyName(client.name)
    setContactPersonFirstName(client.contact.split(" ")?.filter((x,i) => i == 0)?.join(" "))
    setContactPersonLastName(client.contact.split(" ")?.filter((x,i) => i > 0)?.join(" "))
    setCompanyAddress(client.address.split(', ')[0])
    setCity(client.address.split(', ')[1])
    setState(client.address.split(', ')[2])
    setCountry(client.address.split(', ')[3])
    setCompanyEmail(client.email)
    setZip(client.zip)
    client?.image && setImages([{url: client?.image}])
    setEditClientModal("Edit Client")
     
}

const [
  updateClient,
  { data: updateClientData },
] = useMutation(updateClientMutation)

const updateClientFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setEditClientModal(null)

  const newClient = selectedClient
  newClient.image = imageUploaded
  newClient.name = companyName
  newClient.company = companyName
  newClient.contact = `${contactPersonFirstName} ${contactPersonLastName}`
  newClient.email = companyEmail
  newClient.phone = companyPhoneNumber
  newClient.phoneWork = companyWorkPhoneNumber
  newClient.address = `${companyAddress}, ${city}, ${state}, ${country}`
  newClient.zip = zip


  setSelectedClient(newClient)
  setAccountData(accountData.map(t => t.id === newClient.id ? newClient : t))

  updateClient({
    variables:{
      _id: selectedClient.id,
      companyName: companyName,
      companyAddress: `${companyAddress}, ${city}, ${state}, ${country}`,
      contactPersonName: `${contactPersonFirstName} ${contactPersonLastName}`,
      contactPersonEmail: companyEmail,
      contactPersonPhoneNumber : companyPhoneNumber,
      contactPersonWorkPhoneNumber : companyWorkPhoneNumber,
      image: imageUploaded,
      zip: zip
    }
  })
  .then(() => {
    changeImageUploaded("");
    setAddProfileClicked(false)
    changeImagePreviewAvailable(false);
    changeTakeAPhotoSelected(false);
    changeWebcamURI("");
    setCompanyName("")
    setCompanyEmail("")
    setCompanyPhoneNumber("")
    setCompanyWorkPhoneNumber("")
    setCompanyAddress("")
    setCity("")
    setCountry("")
    setState("")
    setZip && setZip("")
    setContactPersonName("")
    dispatch(ACTION_LOADING_SPINNER_RESET())
    clientsRefetch()
  })

}


    // client search 

    const [searchFilter, setSearchFilter] = useState("")
    const [clientsFilter, setClientsFilter] = useState(null)
    useEffect(() => {
      if (clients) {
        if (clients.length > 0) {
          if (searchFilter) {
            setClientsFilter(
            [...clients].filter((x) => {
                return (
                  new RegExp(searchFilter, "gi").test(x.companyName) ||
                  new RegExp(searchFilter, "gi").test(x.contactPersonName) ||
                  new RegExp(searchFilter, "gi").test(x.contactPersonEmail) ||
                  new RegExp(searchFilter, "gi").test(x.contactPersonPhoneNumber) ||
                  new RegExp(searchFilter, "gi").test(x.contactPersonWorkPhoneNumber) ||
                  new RegExp(searchFilter, "gi").test(x.companyAddress)
                );
              })
            )
          } else {
            setClientsFilter(null)
          }
        }
      }
    }, [searchFilter, clients]);


    
    useEffect(() => {
     if(clientsFilter) {

      setAccountData(
        clientsFilter.map((client) => {
          const mappingUser = {
              id: client._id,
              status: client.status,
              image: client.image?.replace("#", "").replace("_", "").replace("/", ""),
              name: client.companyName,
              company: client.companyName,
              contact: client.contactPersonName,
              email: client.contactPersonEmail,
              phone: client.contactPersonPhoneNumber,
              phoneWork: client.contactPersonWorkPhoneNumber,
              address: client.companyAddress,
              zip: client.zip
      
          }                                                                                   
          
          return mappingUser
      })  
      )
      dispatch(ACTION_LOADING_SPINNER_RESET())

     } else if(clients) {
          setAccountData(
            clients.map((client) => {
              const mappingUser = {
                  id: client._id,
                  status: client.status,
                  image: client.image?.replace("#", "").replace("_", "").replace("/", ""),
                  name: client.companyName,
                  company: client.companyName,
                  contact: client.contactPersonName,
                  email: client.contactPersonEmail,
                  phone: client.contactPersonPhoneNumber,
                  phoneWork: client.contactPersonWorkPhoneNumber,
                  address: client.companyAddress,
                  zip: client.zip
          
              }                                                                                   
              
              return mappingUser
          })  
          )
          dispatch(ACTION_LOADING_SPINNER_RESET())
      }
  },[clients, clientsFilter])
  

  
const[city, setCity]  = useState("")
const [state, setState] = useState("")
const [country, setCountry] = useState("")
const [zip, setZip] = useState("")
const [images, setImages] = useState([])
const [backToAudit, setBackToAudit] = useState(false)
const location = useLocation()

useEffect(() => {
  if(location) {
    if(location.state) {
      if(location.state.addNewClient) {
        setClientModal("Client")
        setBackToAudit(true)
      }
      if(location.state.addNewSite) {
        setBackToAudit(true)
        subScreenById(location.state.clientId)
      }
    }
  }
  return () => window.history.replaceState({}, document.title)
},[location, accountData])


 return (
        <div className="adminContent">
            {returnToHomeScreen()}
            {editClientModal && (
              
            <AddClientModal 
            images={images}
            setImages={setImages}
            clientModal={editClientModal}
            setClientModal={setEditClientModal}
            setCompanyName={setCompanyName}
            companyName={companyName}
            setCompanyEmail={setCompanyEmail}
            companyEmail={companyEmail}
            updateClientFunc={updateClientFunc}
            emailAlreadyRegistered={emailAlreadyRegistered}
            invalidEmail={invalidEmail}
            setEmailAlreadyRegistered={setEmailAlreadyRegistered}
            setCompanyPhoneNumber={setCompanyPhoneNumber}
            companyPhoneNumber={companyPhoneNumber}
            setCompanyWorkPhoneNumber={setCompanyWorkPhoneNumber}
            companyWorkPhoneNumber={companyWorkPhoneNumber}
            setCompanyAddress={setCompanyAddress}
            companyAddress={companyAddress}
            contactPersonName={contactPersonName}
            setContactPersonName={setContactPersonName}
            city={city}
            setCity={setCity}
            zip={zip}
            setZip={setZip}
            state={state}
            setState={setState}
            country={country}
            setCountry={setCountry}
            // avatar props
            takeAPhotoSelected={takeAPhotoSelected}
            changeTakeAPhotoSelected={changeTakeAPhotoSelected}
            webcamURI={webcamURI}
            changeWebcamURI={changeWebcamURI}
            imageUploaded={imageUploaded}
            changeImageUploaded={changeImageUploaded}
            imagePreviewAvailable={imagePreviewAvailable}
            changeImagePreviewAvailable={changeImagePreviewAvailable}
            handleDeletedPreviewImage={handleDeletedPreviewImage}
            handleImageUploaded={handleImageUploaded}
            addProfilePhotoClicked={addProfilePhotoClicked}
            setAddProfileClicked={setAddProfileClicked}
            setContactPersonFirstName={setContactPersonFirstName}
            setContactPersonLastName={setContactPersonLastName}
            contactPersonFirstName={contactPersonFirstName}
            contactPersonLastName={contactPersonLastName}
            
            />

            )}
            {!selectedClient ? <ClientsTable
            user={user}
            backToAudit={backToAudit}
            setBackToAudit={setBackToAudit}
            images={images}
            setImages={setImages}
            zip={zip}
            setZip={setZip}
            setContactPersonFirstName={setContactPersonFirstName}
            setContactPersonLastName={setContactPersonLastName}
            contactPersonFirstName={contactPersonFirstName}
            contactPersonLastName={contactPersonLastName}
             subScreen={subScreen}
             accountColumns={accountColumns}
             accountData={accountData}
             accountTabs={false} 
             accountType="Client"
             // search client
             searchFilter={searchFilter}
             setSearchFilter={setSearchFilter}
             // add client modal

            clientModal={clientModal}
            setClientModal={setClientModal}
            setCompanyName={setCompanyName}
            companyName={companyName}
            setCompanyEmail={setCompanyEmail}
            companyEmail={companyEmail}
            inviteUser={inviteUser}
            emailAlreadyRegistered={emailAlreadyRegistered}
            invalidEmail={invalidEmail}
            setEmailAlreadyRegistered={setEmailAlreadyRegistered}
            setCompanyPhoneNumber={setCompanyPhoneNumber}
            companyPhoneNumber={companyPhoneNumber}
            setCompanyWorkPhoneNumber={setCompanyWorkPhoneNumber}
            companyWorkPhoneNumber={companyWorkPhoneNumber}
            setCompanyAddress={setCompanyAddress}
            companyAddress={companyAddress}
            city={city}
            setCity={setCity}
            country={country}
            setCountry={setCountry}
            state={state}
            setState={setState}
            contactPersonName={contactPersonName}
            setContactPersonName={setContactPersonName}

                      // photo props
            takeAPhotoSelected={takeAPhotoSelected}
            changeTakeAPhotoSelected={changeTakeAPhotoSelected}
            webcamURI={webcamURI}
            changeWebcamURI={changeWebcamURI}
            imageUploaded={imageUploaded}
            changeImageUploaded={changeImageUploaded}
            imagePreviewAvailable={imagePreviewAvailable}
            changeImagePreviewAvailable={changeImagePreviewAvailable}
            handleDeletedPreviewImage={handleDeletedPreviewImage}
            handleImageUploaded={handleImageUploaded}
            addProfilePhotoClicked={addProfilePhotoClicked}
            setAddProfileClicked={setAddProfileClicked}

             /> : (
               <>
                <div className="adminDetailsContainer" style={{padding:"0px"}}>
                <AccountHeader deleteType={"Client"} editUser={editClient} deleteClientFunc={deleteClientFunc} updateClient={updateClientStatusFunc} selectedAccount={selectedClient} type="client" />
                <ClientSites
                backToAudit={backToAudit}
                setBackToAudit={setBackToAudit}
                siteModal={siteModal}
                setSiteModal={setSiteModal}
                allSitesLoading={allSitesLoading}
                sitesData={sitesData}
                setSitesData={setSitesData}
                  user={user}
                  selectedClient={selectedClient}
                  sites = {sites}
                  sitesRefetch = {allSitesRefetch}
                  allSitesRefetch={allSitesRefetchDashboard}
                />
                </div>
                </>
             )}

        </div>
    )
}

export default AdminClients