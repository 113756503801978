import React, { useEffect } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Typical from 'react-typical'


const ConfirmDefaultModal = (props) => {
    const {
        confirmDefaultModal,
        setConfirmDefaultModal,
        updateDefaultTemplate,
    } = props

  
    
  return(
<Modal
        isOpen={confirmDefaultModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
              {confirmDefaultModal && (
               <div 
               className="modal task-modal confirmModal"
               >
               
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setConfirmDefaultModal(null)
                }}
              />

          <div style={{alignItems:"center", height:"100%"}} className="form-group-container task-form">
            <h2 className='header' style={{width:"100%", textAlign:"center", display:"flex", justifyContent:"center",alignItems:"center"}}>
            {/* <Typical
                    steps={[`Are you sure to set "${confirmDefaultModal.name}" as the default template`, 1000]}
            /> */}
            Are you sure to set "{confirmDefaultModal.name}" as the default template
            </h2>

            </div>
          <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
            <div
            
              className={"auth-button addUser"}
              style={{background:"#EF1111", width:"40%"}}
              onClick={() => setConfirmDefaultModal(null)}
            >
              No
            </div>
            <div
            
            className={"auth-button addUser"}
            style={{width:"40%"}}
            onClick={() => updateDefaultTemplate(confirmDefaultModal)}
          >
            Yes
          </div>
          </div>
        </div>)}
      </Modal>
  )}

  export default ConfirmDefaultModal
       