import { gql } from "apollo-boost";
gql`
  type ClientType {
    _id: ID
    companyName: String
  }
`;
gql`
  type SiteType {
    _id: ID
    name: String
  }
`;
gql`
  input ClientInput {
    client: ClientType
  }
`;
gql`
  input SiteInput {
    site: SiteType
  }
`;
const addReportMutation = gql`
  mutation(
    $site: [SiteInput],
    $client: [ClientInput],
    $dateOfAudit: String,
    $dateOfReportGeneration: String,
    $dateOfApproval: String,
    $type: String,
    $expDate: String,
    $status: String,
    $note: String,
    $reportTemplate: String
    $html: String
    $css: String
    $user: ID
    $wmg: ID
    $view: ID
    $questionnaire: String
    $finalReport: String
    

  ) {
    addReport(
      finalReport: $finalReport
    site:$site
    client:$client
    dateOfAudit:$dateOfAudit
    dateOfReportGeneration:$dateOfReportGeneration
    dateOfApproval:$dateOfApproval
    type:$type
    expDate:$expDate
    status:$status
    note:$note
    reportTemplate:$reportTemplate
    user: $user
    wmg: $wmg
    html: $html
    css: $css
    view: $view
    questionnaire: $questionnaire
    

    ) {
    _id
    site {
      _id
      name
    }
    client {
      _id
      companyName
    }
    dateOfAudit
    finalReport
    dateOfReportGeneration
    dateOfApproval
    type
    expDate
    wmg
    notification {
        _id
        new
        message
        users
        createdAt
      }
    status
    questionnaire
    note
    reportTemplate
    user
    html
    css
        view

  }
  }
`;

export default addReportMutation;
