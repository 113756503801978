import { gql } from "apollo-boost";

const checkUserQuery = gql`
  query($email: String) {
    checkUserRole(email: $email) {
      role
      active
      _id
      companyName
      companyAddress
      contactPersonName
      contactPersonEmail
      contactPersonWorkPhoneNumber
      status
      image
      user
      zip
    }
  }
`;

export default checkUserQuery;
