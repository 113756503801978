import React, { useEffect, useState } from "react";
import {  Link, useLocation, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";

import Cookies from "js-cookie";

import loginQuery from "../../../graphql/queries/user/loginQuery";
import { saveAs } from 'file-saver';

import ACTION_LOGIN_IS_NOT_ACTIVE from "../../../actions/user/Login/ACTION_LOGIN_IS_NOT_ACTIVE";
import ACTION_LOGIN_IS_ACTIVE from "../../../actions/user/Login/ACTION_LOGIN_IS_ACTIVE";
import ACTION_LOGIN_EMAIL_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_TEMPO_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_INVALID";
import ACTION_LOGIN_PASSWORD_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_INVALID";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import ACTION_LOGIN_TEMPO_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_TEMPORARY_DUMMY_TOKEN_RESET from "../../../actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN_RESET";


import LoginEmail from "./LoginEmail/LoginEmail";
import LoginPassword from "./LoginPassword/LoginPassword";
import UserNewPassword from "./NewPassword/UserNewPassword";
import UserConfirmNewPassword from "./NewPassword/UserConfirmNewPassword";
import updateUserPasswordMutation from "../../../graphql/mutations/user/updateUserPasswordMutation"

import "./Auth.scss"
import logo from "../../../assests/images/logo.png"
import ForgetPassword from "./ForgetPassword"; 
import resetPasswordMutation from "../../../graphql/mutations/user/resetPasswordMutation";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
 import slip from "../../../assests/images/slip.png"
 import ansi from "../../../assests/images/ansi.png"
 import bot from "../../../assests/images/bot.png"
import DoneModal from "../../resuable/modals/doneModal";
import checkUserQuery from "../../../graphql/queries/user/checkUserQuery";
import addUserMutation from "../../../graphql/mutations/user/addUserMutation";

const Login = ({socket}) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  const loginEmail = useSelector((state) => state.loginEmail.login_email);
  const loginEmailInvalid = useSelector( 
    (state) => state.loginEmailInvalid.login_email_invalid
  );
  const loginTempoInvalid = useSelector(
    state => state.loginTempoInvalid.login_tempo_invalid
  );
  const loginPassword = useSelector(
    (state) => state.loginPassword.login_password
  );
  const loginPasswordInvalid = useSelector(
    (state) => state.loginPasswordInvalid.login_password_invalid
  );
  const registeredUserFound = useSelector(
    (state) => state.registeredUserFound.registered_user_found
  );

  const confirmNewPassword = useSelector(
    (state) => state.confirmNewPassword.confirm_new_password
  );
  const newPasswordValid = useSelector(
    (state) => state.newPasswordValid.new_password_valid
  );
  const confirmNewPasswordValid = useSelector(
    (state) =>
      state.confirmNewPasswordValid.confirm_new_password_valid
  );
  const userAuthenticated = useSelector(
    (state) => state.userAuthenticated.user_authenticated
  );

  const temporaryDummyToken = useSelector((state) => state.temporaryDummyToken.temporary_dummy_token);

  const resetUser = () => { 
    Cookies.remove("temporary-dummy-token")
    dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET())

  }
  const handleUserLogin = () => {

    if (registeredUserFound) {
      if (loginEmailInvalid) {
        dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
      }

      if (loginPasswordInvalid) {
        dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
      } 
      if(loginTempoInvalid) {
        dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
      }
    }

  };

  const handleUserCheck = (data) => {

    dispatch(ACTION_LOADING_SPINNER_RESET())
    if(!data?.checkUserRole?.active) {
      setFirstLogin(data?.checkUserRole)
      setEmailDone(true)
    } else {
      setEmailDone(true)

    }

  };


  const handleUserLoginError = () => {
    let error = error || checkError
    if (error || checkError) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
      if (error.message) {
        if (error.message.includes("email")) {
          dispatch(ACTION_LOGIN_EMAIL_INVALID());
          dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
          dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID());
        } else if (error.message.includes("temporarily")) {
          dispatch(ACTION_LOGIN_TEMPO_INVALID());
          dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
          dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
        } else {
          dispatch(ACTION_LOGIN_PASSWORD_INVALID());
          dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
          dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID());

        }
      }
    }
  };

  const [loginUser, { error, loading }] = useLazyQuery(loginQuery, {
    fetchPolicy: "no-cache",
    onCompleted: handleUserLogin,
    onError: handleUserLoginError,
  });

  const [checkUserRole, {error: checkError}] = useLazyQuery(checkUserQuery, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => handleUserCheck(data),
    onError: handleUserLoginError
  });


  const handleLoginClick = (e) => {
    e && e.preventDefault()
    loginUser({
      variables: {
        email: loginEmail,
        password: loginPassword,
      },
    });
  };

  const [
    addUser,
    { data: addUserData },
  ] = useMutation(addUserMutation)

  const handleClientSignup = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())

    let {
      _id,
      companyName,
      contactPersonName,
      contactPersonEmail,
      contactPersonWorkPhoneNumber,
      companyAddress,
      zip,
    } = firstLogin

    addUser({
      variables:{
        client: _id,
        companyName: companyName,
        name: contactPersonName,
        email: contactPersonEmail,
        phoneNumber: contactPersonWorkPhoneNumber,
        companyAddress: companyAddress,
        zip: zip,
        role:"client"
      }
    }).then(res => {
      console.log(res)
      setFirstLogin(null)
      setEmailDone(false)
      setSignupDone(true)
      dispatch(ACTION_LOADING_SPINNER_RESET())
    })
  }

  const checkEmailRole = (e) => {
    e && e.preventDefault()
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    checkUserRole({
      variables: {
        email: loginEmail
      }
    })
  }

useEffect(() => {
  console.log(error, "error")
},[error])


  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);


  useEffect(() => {
    dispatch(ACTION_LOGIN_IS_ACTIVE());
    return () => {
      dispatch(ACTION_LOGIN_IS_NOT_ACTIVE());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
      dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
      dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
    };
  }, [dispatch]);
 

const [
  updateUserPassword,
  { loading: updateUserPasswordLoading, data: updateUserPasswordData },
] = useMutation(updateUserPasswordMutation)


const handleChangePasswordClick = (e) => {
  e && e.preventDefault()

  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  updateUserPassword({
    variables: { password: confirmNewPassword },
  })
};

useEffect(() => {
  if (!updateUserPasswordLoading) {
    if (updateUserPasswordData) {
      dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET())
      loginUser({
        variables: {
          email: loginEmail,
          password: confirmNewPassword,
        },
      });
    }
  }
}, [
  updateUserPasswordLoading,
  confirmNewPassword,
  loginUser,
  updateUserPasswordData,
  dispatch
]);


 
// reset password

const [
  resetPassword,
  { data: resetPasswordData },
] = useMutation(resetPasswordMutation)
 

const [resetPasswordModal, setResetPasswordModal] = useState(false)
const [userEmail, setUserEmail] = useState("")
const [emailError, setEmailError] = useState("")
const [ doneModal, setDoneModal] = useState(null)

const forgetPassword = () => {
  setResetPasswordModal(false)
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  resetPassword({
    variables:{
      email: userEmail.toLocaleLowerCase()
    }
  }).then(res => {
    if(res) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
      setDoneModal("Password Reset Request Sent")

    }
  }).catch(err => {
    if(err) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
      setResetPasswordModal(true)
      setEmailError(err.message)
    }
  })
}

useEffect(() => {
  if(resetPasswordData) {
    setUserEmail("")
    dispatch(ACTION_LOADING_SPINNER_RESET())
  }
},[resetPasswordData, dispatch])

useEffect(() => {
if(userAuthenticated) {
  history.push("/admin/dashboard")
  dispatch(ACTION_LOADING_SPINNER_RESET())
} else {
  dispatch(ACTION_LOADING_SPINNER_RESET())
} 
},[userAuthenticated])

const [adminVisitor, setAdminVisitor] = useState(false)
const [emailDone, setEmailDone] = useState(false)
const [signupDone, setSignupDone] = useState(false)
const [firstLogin, setFirstLogin] = useState(null) 

if(adminVisitor) {
  return (
    <div className="signinPageContainer">
                    <img src={logo} className="logoScreen" style={{position:"fixed", top:"20px", left:"20px"}} />
    <div className="notAdminVisitor signinPageContainer singinPageContent">
      <div className="notAdminChild">
      <div className="white heading" onClick={(e) => {
        if(e.detail == 5) {
          setAdminVisitor(false)
        }
      }}>
        <div style={{height:"3vh"}}></div>
        Production has been <br/>moved to  <br/><span className="stepWording">stepreporting.com</span>. <br/> Please click the link <br/>below to be rerouted.<br/>Thank you!

        <div style={{display:"flex", height:"30%", justifyContent:"center", alignItems:"flex-end"}}>
            <div
              className="auth-button addUser bigButton"
              onClick={() => {window.location.href = "https://stepreporting.com"}}
              >
              Reroute Now
            </div>
        </div>
      </div>
      {/* <h1 className="black heading">stepreporting.com</h1> */}
      {/* <div className="white heading">Click below to go to the new domain</div> */}
     
      </div>
    </div>
    </div>
  )
}
  return (
    <div className="signinPageContainer">
      <div className=" signinPageContainer singinPageContent">
        <DoneModal 
        open={doneModal}
        setOpen={setDoneModal}
        />
      <ForgetPassword 
        resetPasswordModal={resetPasswordModal}
        setResetPasswordModal={setResetPasswordModal}
        setUserEmail={setUserEmail}
        userEmail={userEmail}
        setEmailError={setEmailError}
        emailError={emailError}
        forgetPassword={forgetPassword}
      />
      <div className="rightSignInContainer">
        <div className="loginRightContainer">
      <div className="sidebarLogo" style={{background:"transparent", height:"150px", padding:"0px"}}>
        <div className="logoContentContainer">
                    <img height={"150%"} src={logo} />
                    </div>
      </div>
      <div className="loginHeader">
            The world's first multi-platform application that revolutionizes slip resistance testing
          </div>
          </div>
      </div>
      <div className="signinContainer">
      <div className="siginContent">
        <div className="signinBoxContainer">

        <div className="auth-header">
        {signupDone ? " We have sent an email for signup" :firstLogin ? "Hello, Signup to use the stepApp" : temporaryDummyToken ? "Change the password" : "Login to your account"}
        </div>
        {!signupDone ? <div>
          <div className="auth-group">
          { temporaryDummyToken ? (
            <UserNewPassword
            handleChangePasswordClick={handleChangePasswordClick}
          /> ) : (
          <LoginEmail disabled={firstLogin || emailDone} /> ) }
          </div>

          <div className="auth-group">
          { temporaryDummyToken ? (
            <UserConfirmNewPassword
            handleChangePasswordClick={handleChangePasswordClick}
          /> 
          ) : emailDone && !firstLogin ? (
              <LoginPassword handleLoginClick={handleLoginClick } />
          ) : null}
          
          </div>
        { temporaryDummyToken || firstLogin ? ( 
              <div className="bottom-group">
              <div onClick={firstLogin ? ()=> {
setFirstLogin(null)
setEmailDone(null)
              } : resetUser} className="link">
              Back
              </div>
              </div>
           ) : emailDone ? (
             <div className="bottom-group" style={{justifyContent:"space-between"}}>
             <div className="link" onClick={() => setEmailDone(false)}>Back</div>

             <div className="link" onClick={() => setResetPasswordModal(true)}>Forgot your password?</div>
           </div>
           
          ) : null }

          <div>
            <div
              onClick = { firstLogin ? handleClientSignup :
                  temporaryDummyToken
                  ? handleChangePasswordClick : 
                  emailDone ?
                  handleLoginClick :
                  checkEmailRole
              }
              style = {{
              color: temporaryDummyToken
              ? newPasswordValid && confirmNewPasswordValid
                ? "rgb(255, 255, 255)"
                : "grey"
              : (emailDone && loginPassword ) || (loginEmail && !emailDone) || firstLogin
                ? "rgb(255, 255, 255)"
                : "grey",
              pointerEvents: temporaryDummyToken
                ? newPasswordValid && confirmNewPasswordValid
                  ? "auto"
                  : "none"
                : (emailDone && loginPassword ) || (loginEmail && !emailDone) || firstLogin
                ? "auto"
                : "none",
              background: temporaryDummyToken
                ? newPasswordValid && confirmNewPasswordValid
                  ? "#5C92BF"
                  : "#f0f0f0"
                : (emailDone && loginPassword ) || (loginEmail && !emailDone) || firstLogin
                ? "#5C92BF"
                : "#f0f0f0",
              width:"20%",
              minWidth:"140px"
          }} 
          className="auth-button">
          {firstLogin ? "Signup" : temporaryDummyToken ? "Change Password" : emailDone ? "Login" : "Next"}          
          </div>
          </div>

        </div> : null}
        </div> 
        </div>
        <div className="footerLoginPage" style={{height:"25%", width:"100%", padding:"0 10px", flexDirection:"row", display:"flex", justifyContent:"flex-start"}}>
          <div onClick={() => {
           history.push({
            pathname: "/contact-us",
            state: {
              user: "home"
            }
          })
          }} className="footerCard" style={{display:"flex", alignItems:"flex-start",height:"100%", width:"33.333333333%"}}>
            <img  src={slip} height={100} style={{margin:"0 1rem"}}/>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"flex-start"}}>
              <div style={{color:"#3D7DBD", fontSize:"14px", fontWeight:"bold", textTransform:"capitalize"}}>get a slip test quote</div>
              <div style={{textTransform:"none", fontSize:"12px"}}>STEP has auditors that can provide coefficient of friction (COF) testing on all hard surface flooring <br/> STEP can also provide Lab testing</div>
            </div>
          </div>
          <div className="footerCard" onClick={() => {
            window.open("https://cdn.shopify.com/s/files/1/0019/0123/2185/files/ANSI_A326.3_2021_February_2022_Locked.pdf?v=1644874339", "_blank")
          }} style={{display:"flex", alignItems:"flex-start",height:"100%", width:"33.333333333%"}}>
            <img  src={ansi} height={100} style={{margin:"0 1rem"}}/>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"flex-start"}}>
              <div style={{color:"#3D7DBD", fontSize:"14px", fontWeight:"bold", textTransform:"capitalize"}}>ANSI A326.3 - 2021</div>
              <div style={{textTransform:"none" , fontSize:"12px"}}>2021 updates to National Consensus Standard, ANSI A326.3 now help facilities in the USA protect themselves from slip and fall hazards & liabilities in interior and exterior areas </div>
            </div>
          </div>
          <div onClick={() => {
            history.push({
              pathname: "/bot-3000e",
              state: {
                user: "home",
                route: location.pathname
              }
            })
          }} className="footerCard" style={{display:"flex", alignItems:"flex-start",height:"100%", width:"33.333333333%"}}>
            <img  src={bot} height={100} style={{margin:"0 1rem"}}/>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"flex-start"}}>
              <div style={{color:"#3D7DBD", fontSize:"14px", fontWeight:"bold", textTransform:"capitalize"}}>BOT-3000E</div>
              <div style={{textTransform:"none" , fontSize:"12px"}}>Purchase the industry's leading tribometer to measure the Static or Dynamic coefficient of friction in the wet or dry conditions</div>
            </div>
          </div>
        </div>
        </div>
        </div>
  </div>
  );
};

export default Login;
