import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";

export function Store(props) {


const location = useLocation();
const history = useHistory()
   
    const [user, setUser] = React.useState(null)
    const [route, setRoute] = React.useState(null)
    const [license, setLicense] = React.useState(null)

    React.useEffect(() => {
      if(location) {
        if(location.state) {
          if(location?.state?.user) {
          setUser(location.state.user)
        }
        if(location?.state?.route) {
          setRoute(location.state.route)
        }

        if(location?.state?.license?.length) {
          setLicense(location.state.license[0])
        }

        } else [
          history.push("/admin")
        ]
      } 
      return () => window.history.replaceState({}, document.title)
    },[location])


    const purchasePackage = (type) => {
      if(type == "go") {
        window.location.replace(`${process.env.REACT_APP_PAY_AS_YOU_GO}?client_reference_id=${user._id}`);
      } else if(type == "professional") {
        window.location.replace(`${process.env.REACT_APP_PROFESSIONAL}?client_reference_id=${user._id}`)
      } else if(type == "extra") {
        window.location.replace(`${process.env.REACT_APP_EXTRA}?client_reference_id=${user._id}`)
      } else if(type == "enterprise") {
        window.location.replace(`${process.env.REACT_APP_ENTERPRISE}?client_reference_id=${user._id}`)
      }
    }

  return (
    <div>

<div className="headerTitle" style={{position:"absolute", top:0, left:0, padding:"0 50px", zIndex:"99", width:"100vw", color:"#1467b3", display:"flex", justifyContent:"space-between"}}>
            <div style={{cursor:"pointer", display:"flex", alignItems:"center", width:"fit-content"}} onClick= { () => {                      
                    history.push(`${route || "/admin"}`)    
                    }}> 
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{marginRight: "1rem", fontSize:"1.8rem"}}
                    />
                    <span>{route ? "Back" : "Home"}</span>   
            </div> 
            <div>
            Plans & Pricing
            </div>
            </div>

      <div className="cdiv">
        <div style={{overflow:"auto", background:`url("https://cdn.builder.io/api/v1/image/assets/TEMP/a78a1b04fd897a838aab272ac7996cf63ef763200f148043be6b03e92c600406?apiKey=091b6fddccc34042917fcceffd640004&")`}} className="cdiv-2">
        
          <span className="span">
            <div className="cdiv-3" style={{display:"flex", justifyContent:"space-between"}}>
              <div>{license?.licenseType} Tier</div>
              <div>
                 {Number(license?.purchasedReports) + Number(license?.freeReports)} reports
              </div>
              </div>
            <div className="cdiv-4">
              <div className="cdiv-5">
               {license?.licenseType !== "Enterprise" ?  <div className="column" style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                  <div className="cdiv-6" style={{width:"130%"}}>
                    <div className="cdiv-7" style={{width:"100%"}}>
                      <div className="cdiv-8">
                       
              {license?.licenseType == "Pay As You Go" ? <div className="column-4">
                  <span className="span-15">
                    <span className="span-17">
                      <div className="cdiv-25">$175</div>
                      <div className="cdiv-26">/ Report</div>
                    </span>
                    <div className="cdiv-27">Pay As You Go</div>
                    <div className="cdiv-28">

                      
                    </div>
                    <span className="span-18">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-29">Detailed Summary Report</div>
                    </span>
                    <span className="span-19">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-30">Post Audit Evaluation</div>
                    </span>
                    <span className="span-20">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-31">Secure, Scientific Data</div>
                    </span>
                    <span className="span-21">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-32">ASTM F2048-00 and ASTM F1694-21 Compliant</div>
                    </span>
                  
                    <span className="span-23"
                    onClick={() => purchasePackage("go")}
                    >Choose plan</span>
                  </span>
                </div> : null}
                        
                     
{license?.licenseType == "Pay As You Go" || license?.licenseType == "Professional" ? <div className="column-4">
                  <span className="span-151">
                    <span className="span-16" style={{color:license?.licenseType == "Professional" ? "#6DB240" :"#6DB240",fontSize:license?.licenseType == "Professional" ? "24px" :"18px"}}>{ license?.licenseType == "Professional" ? "Your Tier!" : "MOST POPULAR"}</span>
                    <span className="span-17">
                      <div className="cdiv-25">$900</div>
                      <div className="cdiv-26">/ Year</div>
                    </span>
                    <div className="cdiv-27">Professional </div>
                    <div className="cdiv-28">

                      
                    </div>
                    <span className="span-18">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb5d516dd09eb0f3a9792d7c113bfcf4641a879155b1c96248e43f987000438?apiKey=091b6fddccc34042917fcceffd640004&"
                        className="img-9"
                      />
                      <div className="cdiv-29">{license?.licenseType == "Professional" ? `${Number(license?.purchasedReports) + Number(license?.freeReports)} reports remains` : "This tier includes 5 free Summary Reports"}</div>
                    </span>
                    {license?.licenseType !== "Professional" ? <><span className="span-19">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb5d516dd09eb0f3a9792d7c113bfcf4641a879155b1c96248e43f987000438?apiKey=091b6fddccc34042917fcceffd640004&"
                        className="img-10"
                      />
                      <div className="cdiv-30">Post Audit Evaluation</div>
                    </span>
                    <span className="span-20">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb5d516dd09eb0f3a9792d7c113bfcf4641a879155b1c96248e43f987000438?apiKey=091b6fddccc34042917fcceffd640004&"
                        className="img-11"
                      />
                      <div className="cdiv-31">Secure, Scientific Data</div>
                    </span>
                    <span className="span-21">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb5d516dd09eb0f3a9792d7c113bfcf4641a879155b1c96248e43f987000438?apiKey=091b6fddccc34042917fcceffd640004&"
                        className="img-12"
                      />
                      <div className="cdiv-32">ASTM F2048-00 and ASTM F1694-21 Compliant</div>
                    </span>

                    
                            </> : null
}
<span className="span-6">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb5d516dd09eb0f3a9792d7c113bfcf4641a879155b1c96248e43f987000438?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-8"
                              />
                              <div className="cdiv-23">Subsequent Summary Reports are $90/each</div>
                            </span>
                    <span className="span-23"
                    onClick={() => purchasePackage(license?.licenseType == "Professional" ? "extra" : "professional")}
                    >{license?.licenseType == "Professional" ? "add extra report" : "Choose plan"}</span>
                  </span>
                </div> : null}

                       
{license?.licenseType == "Pay As You Go" || license?.licenseType == "Professional" ? <div className="column-4">
                  <span className="span-15">
                    <span className="span-17">
                      <div className="cdiv-25">$2600</div>
                      <div className="cdiv-26">/ Year</div>
                    </span>
                    <div className="cdiv-27">Enterprise</div>
                    <div className="cdiv-28">

                      
                    </div>
                    <span className="span-18">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-29">Unlimited Reports Summary</div>
                    </span>
                    <span className="span-19">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-30">Post Audit Evaluation</div>
                    </span>
                    <span className="span-20">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-31">Secure, Scientific Data</div>
                    </span>
                    <span className="span-21">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-32">ASTM F2048-00 and ASTM F1694-21 Compliant</div>
                    </span>
                  
                    <span className="span-23"
                    onClick={() => purchasePackage("enterprise")}
                    >
                      
                      {license?.licenseType == "Professional" ? "Upgrade!" : "Choose plan" }
                     
                    
                    </span>
                  </span>
                </div> : null}
                
                      </div>
                    </div>
                  </div> 
                </div> : null}
               

                { license?.licenseType == "Enterprise" ? <div style={{width:"100%"}} className="column-4">
                  <span className="span-15">
                    <span className="span-16" style={{color:"#6DB240", fontSize:"24px"}}>Your Tier!</span>
                    <span className="span-17">
                      <div className="cdiv-25">$2600</div>
                      <div className="cdiv-26">/ Year</div>
                    </span>
                    <div className="cdiv-27">Enterprise </div>
                    <div className="cdiv-28">
                    
                    </div>
                    <span className="span-18">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-29">Unlimited Reports Summary</div>
                    </span>
                    <span className="span-19">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-30">Post Audit Evaluation</div>
                    </span>
                    <span className="span-20">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-31">Secure, Scientific Data</div>
                    </span>
                    <span className="span-21">
                    <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9883d970b4872b1461b4f8c97581374b94efb609b96685034e369eb7c83146dd?apiKey=091b6fddccc34042917fcceffd640004&"
                                className="img-5"
                              />
                      <div className="cdiv-32">ASTM F2048-00 and ASTM F1694-21 Compliant</div>
                    </span>


                  
                    <span style={{cursor:"auto", background:"#6DB240"}} className="span-23"
                    // onClick={() => purchasePackage("enterprise")}
                    >Subscribed!</span>
                  </span>
                </div> : null}

              </div>
            </div>
          </span>
        </div>
      </div>
      <style jsx>{`
        .cdiv {
          width: 100vw;
          height:100vh;
          border-radius: 20px;
          box-shadow: 0px 1.993px 2.093px 0px rgba(85, 100, 87, 0.02),
            0px 4.529px 4.755px 0px rgba(85, 100, 87, 0.02),
            0px 7.882px 8.276px 0px rgba(85, 100, 87, 0.03),
            0px 12.522px 13.148px 0px rgba(85, 100, 87, 0.04),
            0px 19.32px 20.286px 0px rgba(85, 100, 87, 0.04),
            0px 30.147px 31.654px 0px rgba(85, 100, 87, 0.05),
            0px 50.049px 52.551px 0px rgba(85, 100, 87, 0.05),
            0px 100px 105px 0px rgba(85, 100, 87, 0.07);
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .cdiv-2 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          position: relative;
          display: flex;
          min-height: 900px;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          padding: 50px 60px;
          height:100%;
        }
        @media (max-width: 991px) {
          .cdiv-2 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .img {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          filter: hue-rotate(180deg);
        }
        .span {
          position: relative;
          display: flex;
          width: 100%;
          max-width: 952px;
          flex-direction: column;
          margin: 85px 0 87px;
        }
        @media (max-width: 991px) {
          .span {
            max-width: 100%;
            margin: 40px 0;
          }
        }
        .cdiv-3 {
          color: #231d4f;
          font: 400 40px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-3 {
            max-width: 100%;
          }
        }
        .cdiv-4 {
          border-radius: 26px;
          backdrop-filter: blur(17.5px);
          background-color: rgba(255, 255, 255, 0.5);
          margin-top: 67px;
          padding: 0 46px 20px 46px;
        }
        @media (max-width: 991px) {
          .cdiv-4 {
            max-width: 100%;
            margin-top: 40px;
            padding: 0 20px;
          }
        }
        .cdiv-5 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .cdiv-5 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 63%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .cdiv-6 {
          z-index: 1;
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .cdiv-6 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .cdiv-7 {
          
        }
        @media (max-width: 991px) {
          .cdiv-7 {
            max-width: 100%;
          }
        }
        .cdiv-8 {
          width:100%;
          gap: 20px;
          display: flex;
          display:flex;
          justify-content:center;
          align-items:center;
        }
        @media (max-width: 991px) {
          .cdiv-8 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .span-2 {
          display: flex;
          flex-direction: column;
          padding: 2px 25px 2px 0;
        }
        @media (max-width: 991px) {
          .span-2 {
            margin-top: 34px;
            padding-right: 20px;
          }
        }
        .span-3 {
          display: flex;
          justify-content: space-between;
          margin-top:20px;
          
        }
        .cdiv-9 {
          margin-right:10px;
          color: #231d4f;
          
          font: 700 36px/128% Poppins, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-9 {
            white-space: initial;
          }
        }
        .cdiv-10 {
          color: #848199;
          align-self: center;
          flex-grow: 1;
          
          margin: auto 0;
          font: 500 30px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-10 {
            white-space: initial;
          }
        }
        .cdiv-11 {
          color: #231d4f;
          margin-top: 31px;
          font: 500 28px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-11 {
            margin-top: 40px;
          }
        }
        .cdiv-12 {
          color: #848199;
          margin-top: 10px;
          font: 500 15px Poppins, sans-serif;
        }
        .span-4 {
          align-self: start;
          display: flex;
          margin-top: 11px;
          gap: 10px;
        }
        .img-2 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-13 {
          color: #848199;
          margin-top: 4px;
          font: 500 15px Poppins, sans-serif;
        }
        .span-5 {
          align-self: start;
          display: flex;
          margin-top: 11px;
          gap: 10px;
        }
        .img-3 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-14 {
          color: #848199;
          margin-top: 4px;
          font: 500 15px Poppins, sans-serif;
        }
        .span-6 {
          align-self: start;
          display: flex;
          margin-top: 11px;
          gap: 10px;
        }
        .img-4 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-15 {
          color: #848199;
          margin: auto 0;
          font: 500 15px Poppins, sans-serif;
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .span-7 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          padding: 1px 0;
        }
        @media (max-width: 991px) {
          .span-7 {
            margin-top: 34px;
          }
        }
        .span-8 {
          display: flex;
          justify-content: space-between;
          gap: 17px;
          margin-top:20px;
        }
        .cdiv-16 {
          color: #231d4f;
          
          font: 700 36px/128% Poppins, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-16 {
            white-space: initial;
          }
        }
        .cdiv-17 {
          color: #848199;
          align-self: center;
          flex-grow: 1;
          
          margin: auto 0;
          font: 500 30px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-17 {
            white-space: initial;
          }
        }
        .cdiv-18 {
          color: #231d4f;
          margin-top: 31px;
          font: 500 28px Poppins, sans-serif;
        }
        .cdiv-19 {
          color: #848199;
          margin-top: 10px;
          font: 500 15px Poppins, sans-serif;
        }
        .span-9 {
          align-self: start;
          display: flex;
          margin-top: 21px;
          gap: 10px;
        }
        .img-5 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-20 {
          color: #848199;
          align-self: start;
          margin-top: 4px;
          flex-grow: 1;
          
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-20 {
            white-space: initial;
          }
        }
        .span-10 {
          align-self: start;
          display: flex;
          margin-top: 11px;
          gap: 10px;
        }
        .img-6 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-21 {
          color: #848199;
          align-self: start;
          margin-top: 4px;
          flex-grow: 1;
          
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-21 {
            white-space: initial;
          }
        }
        .span-11 {
          align-self: start;
          display: flex;
          margin-top: 11px;
          gap: 10px;
        }
        .img-7 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-22 {
          color: #848199;
          align-self: center;
          flex-grow: 1;
          
          margin: auto 0;
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-22 {
            white-space: initial;
          }
        }
        .span-12 {
          align-self: start;
          display: flex;
          margin-top: 11px;
          gap: 10px;
        }
        .img-8 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-23 {
          color: white;
          align-self: start;
          margin-top: 5px;
          flex-grow: 1;
          
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-23 {
            white-space: initial;
          }
        }
        .cdiv-24 {
          display: flex;
          margin-top: 64px;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .cdiv-24 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .span-13 {
          color: var(--White, #fff);
          text-align: center;
          
          border-radius: 24px;
          background-color: #231d4f;
          flex-grow: 1;
          justify-content: center;
          padding: 15px 57px;
          font: 500 15px Poppins, sans-serif;
          cursor:pointer;
        }
        @media (max-width: 991px) {
          .span-13 {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .span-14 {
          color: var(--White, #fff);
          text-align: center;
          
          border-radius: 24px;
          background-color: #231d4f;
          flex-grow: 1;
          justify-content: center;
          padding: 15px 57px;
          font: 500 15px Poppins, sans-serif;
          cursor:pointer;
        }
        @media (max-width: 991px) {
          .span-14 {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .column-4 {
        margin-bottom:20px;
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 37%;
          margin-left: 0px;
          height:100%;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }

        .span-151 {
          align-items: flex-end;
          border-radius: 26px;
          box-shadow: 0px 42px 34px 0px rgba(82, 67, 194, 0.3);
          background-color: #231d4f;
          display: flex;
          width: 100%;
          flex-grow: 1;
          flex-direction: column;
          margin: -26px auto 0;
          padding: 20px 31px;
          height:fit-content;
          div {
            color:white;
          }
        }
        @media (max-width: 991px) {
          .span-151 {
            margin-top: 8px;
            padding: 20px;
          }
        }

        .span-15 {
          align-items: flex-end;
          border-radius: 26px;
          box-shadow: 0px 42px 34px 0px rgba(82, 67, 194, 0.3);
          background-color: rgba(255, 255, 255, .7);
          display: flex;
          width: 100%;
          flex-grow: 1;
          flex-direction: column;
          margin: -26px auto 0;
          padding: 20px 31px;
          height:fit-content;
          div {
            color:gray;
          }
        }
        @media (max-width: 991px) {
          .span-15 {
            margin-top: 8px;
            padding: 20px;
          }
        }
        .span-16 {
          color: #5B92BF;
          text-align: center;
          letter-spacing: 0.83px;
          
          border-radius: 13.5px;
          background-color: #403879;
          align-self: end;
          justify-content: center;
          padding: 10px 18px;
          font: 800 10px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .span-16 {
            white-space: initial;
          }
        }
        .span-17 {
          align-self: stretch;
          display: flex;
          margin-top: 27px;
          justify-content: space-between;
          gap: 20px;
        }
        .cdiv-25 {
          color: #fff;
          font: 700 36px/128% Poppins, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .cdiv-26 {
          color: #fff;
          align-self: start;
          margin-top: 9px;
          flex-grow: 1;
          
          font: 500 30px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-26 {
            white-space: initial;
          }
        }
        .cdiv-27 {
          color: #5B92BF !important;
          align-self: stretch;
          margin-top: 36px;
          font: 500 28px Poppins, sans-serif;
        }
        .cdiv-28 {
          color: #fff;
          align-self: stretch;
          margin-top: 15px;
          font: 500 15px Poppins, sans-serif;
        }
        .span-18 {
          align-self: stretch;
          display: flex;
          margin-top: 21px;
          justify-content: space-between;
          gap: 10px;
        }
        .img-9 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-29 {
          color: #fff;
          align-self: start;
          margin-top: 4px;
          flex-grow: 1;
          
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-29 {
            white-space: initial;
          }
        }
        .span-19 {
          align-self: stretch;
          display: flex;
          margin-top: 11px;
          justify-content: space-between;
          gap: 10px;
        }
        .img-10 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-30 {
          color: #fff;
          align-self: start;
          margin-top: 4px;
          flex-grow: 1;
          
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-30 {
            white-space: initial;
          }
        }
        .span-20 {
          align-self: stretch;
          display: flex;
          margin-top: 11px;
          justify-content: space-between;
          gap: 10px;
        }
        .img-11 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-31 {
          color: #fff;
          align-self: center;
          flex-grow: 1;
          
          margin: auto 0;
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-31 {
            white-space: initial;
          }
        }
        .span-21 {
          align-self: stretch;
          display: flex;
          margin-top: 11px;
          justify-content: space-between;
          gap: 10px;
        }
        .img-12 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-32 {
          color: #fff;
          align-self: center;
          flex-grow: 1;
          
          margin: auto 0;
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-32 {
            white-space: initial;
          }
        }
        .span-22 {
          align-self: stretch;
          display: flex;
          margin-top: 11px;
          justify-content: space-between;
          gap: 10px;
        }
        .img-13 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          min-width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .cdiv-33 {
          color: #fff;
          align-self: center;
          flex-grow: 1;
          
          margin: auto 0;
          font: 500 15px Poppins, sans-serif;
        }
        @media (max-width: 991px) {
          .cdiv-33 {
            white-space: initial;
          }
        }
        .span-23 {
        min-height:40px;
          color: var(--White, #fff);
          text-align: center;
          display:flex;
          justify-content:center;
          alignItems: center;
          border-radius: 24px;
          background-color: #5B92BF;
          align-self: stretch;
          margin-top: 23px;
          justify-content: center;
          align-items: center;
          padding: 5px 60px;
          font: 500 15px Poppins, sans-serif;
          cursor:pointer;
        }
        @media (max-width: 991px) {
          .span-23 {
            white-space: initial;
            padding: 0 20px;
          }
        }
      `}</style>
    </div>
  );
}


