import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";
import "./avatarModal.scss"
import archive from "../../../../../assests/icons/archive.svg"
import LZString from "lz-string";
import AWS from 'aws-sdk'
import { DataSpinner } from "../../../spinner/dataSpinner";

const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
}) 
function AvatarModal({images, setImages, progress, setProgress}) {
  const [selectedFile, setSelectedFile] = useState(null);

  const uploadFile = (file) => {

      const params = {
        ACL: 'public-read',
          Body: file.file,
          Bucket: S3_BUCKET,
          Key: file.file.name.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "")
      };

      myBucket.putObject(params)
          .on('httpUploadProgress', (evt) => {
              setProgress(Math.round((evt.loaded / evt.total) * 100))
          })
          .on("complete", (evt) => {
            file.url = `https://step-us-east-prod.s3.amazonaws.com/${file.file.name.replace(/\s/g, '')}`
            setImages([file])
          })
          .send((err) => {
              if (err) console.log(err)
          })
  }
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    uploadFile(imageList[0])
    setImages(imageList)
  };

  return (
    <div>
      <ImageUploading        
        value={images}
        onChange={onChange} 
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div
              style={{ cursor:"pointer", color: isDragging ?"#EF1111": null, border:"1px solid #e0e0e0", padding:"7px", borderRadius:"50%",width:"80px", height:"80px", display:"flex", justifyContent:"center", alignItems:"center" }}
              onClick={onImageUpload}
              {...dragProps}
            >
              {images?.length == 0 && <span style={{color:"#e0e0e0", fontWeight:"bold"}}>Add</span>}
           
            {/* <div onClick={(e) => {
              e.stopPropagation() 
              onImageRemoveAll()}}>Remove all images</div> */}
            {imageList?.length > 0 && <div style={{ display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                {imageList?.map((image, index) => (
                  <div style={{ width:"80px", height:"80px", borderRadius:"50%",overflow:"hidden", display:"flex", justifyContent:"center", alignItems:"flex-start", padding:"5px"}} key={index} className="image-item">
                     {image.url ? <> <img onClick={(e) =>{
                        e.stopPropagation()
                        onImageUpdate(index)}} src={image?.url} alt="" height={"100"} width={"100"} style={{objectFit:"cover"}} /> </> : <DataSpinner />}
                  </div>
                ))} 
            </div>}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
 export default AvatarModal
