import React, { useEffect, useState } from "react";
import "./adminSideBar.scss"

import { faBriefcase, faChartLine, faFile, faProjectDiagram, faShieldAlt, faUserFriends, faCertificate, faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
useHistory, useLocation
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../assests/images/logo.png"

import ACTION_BACK_ROUTE_RESET from "../../../actions/route/ACTION_BACK_ROUTE_RESET";
import ACTION_MENU_CLICKED_RESET from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED_RESET";
import ACTION_RESET_SIDE_BAR from "../../../actions/ActiveSideBar/ACTION_RESET_SIDE_BAR";


import audits from "../../../assests/icons/audits.svg"
import pendulum from "../../../assests/icons/pendulum.svg"
import reports from "../../../assests/icons/reports.svg"
import users from "../../../assests/icons/users.svg"
import clients from "../../../assests/icons/clients.svg"
import dashboard from "../../../assests/icons/dashboard.svg"
import license from "../../../assests/icons/license.svg"
import admins from "../../../assests/icons/admins.svg"


const AdminSideBar = (props) => {

    const {
        path,
        user,
        setOnFlyAudit,
        setSelectedAudit
    } = props

    const dispatch = useDispatch()
    const location = useLocation()
    const activeSideBar = useSelector((state) => state.activeSideBar.active);


    const history = useHistory()
    const [activeRoute, setActiveRoute] = useState("dashboard")
    const sidebarList = [
        {
            icon: dashboard,
            name: "Dashboard",
            path: "dashboard",
            role: "both"
        },
        {
            icon: admins,
            name: "Admins",
            path: "admins",
            role: "admin"

        },
        {
            icon: users,
            name: "Users",
            path: "users",
            role: "both"

        },
        {
            icon:clients,
            name: "Clients",
            path: "clients",
            role: "both"

        },        
        {
            icon: audits,
            name: "Audits",
            path: "audits",
            role: "both"

        },
        // {
        //     icon: pendulum,
        //     name: "Pendulum",
        //     path: "pendulum",
        //     role: "both"

        // },
        // {
        //     icon: audits,
        //     name: "Insights",
        //     path: "insights",
        //     role: "both"

        // },
        {
            icon: license,
            name: "Licenses",
            path:"licenses",
            role: "admin"
        },                
        {
            icon:reports,
            name: "Reports",
            path: "reports",
            role: "both"
        },
        {
            icon:reports,
            name: "Dashboard",
            path: "dashboard",
            role: "client"
        },
        {
            icon:reports,
            name: "Reports",
            path: "reports",
            role: "client"
        },
        {
            icon:reports,
            name: "Sites",
            path: "clients",
            role: "client"
        },
        {
            icon:reports,
            name: "Auditors",
            path: "users",
            role: "client"
        },
        {
            icon:reports,
            name: "Maintenance",
            path: "maintenance",
            role: "client"
        },
        {
            icon:reports,
            name: "Calendar",
            path: "calendar",
            role: "client"
        }
    ]

    useEffect(() => {
      setActiveRoute(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    }, [location]);

    return (
        <div className={`${ activeSideBar ? "mobileSideBar sidebarContainer" : "sidebarContainer"}`}>
            <FontAwesomeIcon
                className="activeSideBarX"
                icon={faBars}
                style={{color:"#5C92BF"}}
                onClick={() => dispatch(ACTION_RESET_SIDE_BAR())}
              />

            <div className="sidebarChildren">
                <div className="sidebarLogo">
                    <img width="60" src={logo} />
                </div>
                <div className="sidebarContent">
                    {sidebarList.filter(x => user && user?.user?.role == "admin" ? x.role !== "client" : user?.user?.role == "client" ?x.role == "client" : x.role == "both").map(route => (
                        <div key={route.name} className={`routeContainer ${activeRoute == route.path && "activeRoute"}`}
                        onClick={() => {
                            setOnFlyAudit(null)
                            setSelectedAudit(null)
                            setActiveRoute(route.path)
                            if(route.path !== "reports" || route.path !== "licenses") {
                            dispatch(ACTION_BACK_ROUTE_RESET())
                            dispatch(ACTION_MENU_CLICKED_RESET()) 
                            }
                            
                            history.push(`${path}/${route.path}`)
                            dispatch(ACTION_RESET_SIDE_BAR())
                        }}>
                            <img src={route.icon} className="routeIcon" />
                            <div className="routeName">
                                {route.name}
                            </div>

                    </div>
                    ))}
                </div>
                <div className="comicsans" style={{fontSize:"15px",position:"absolute", width:"100%", textAlign:"center", bottom:0, padding:"10px 0px", right:0}}>
                    <div style={{display:"inline-block", fontSize:"12px"}}>by</div> Safe Space Ingenuity
                </div>              
            </div>
        </div>
    )
}

export default AdminSideBar