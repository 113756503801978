import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CommentsModal = ( props) => {
    const {
      setAddAreaDropDown,
        open,
        setOpen,
        comments,
        areasComments,
        addComment,
        } = props

        const [comment, setComment] = useState("")
        const [allComments, setAllComments] = useState([])
        const [addCommentActive, setAddCommentActive] = useState(false)
        useEffect(() => {
            let allCommentsArr = comments?.length && areasComments?.length ?  [...comments, ...areasComments.filter(x => x.comment)] : []
            setAllComments(allCommentsArr)
        },[comments, areasComments])
  return(
<Modal
        isOpen={open}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999", 
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal task-modal" style={{height:"100%", width:"44vw", justifyContent:"space-between"}}>
              <FontAwesomeIcon 
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setAddAreaDropDown(true)
                  setOpen(false)}}
              /> 
            <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>Audit Comments</h1>
            <div style={{height:"100%", margin:"50px 0", justifyContent:"center", alignItems:"center", overflowY:"auto"}} className="form-group-container task-form"> 
                {!addCommentActive ? allComments?.length ? allComments.map((x,i) => (
                    <div key={i} className="form-row" style={{border:"1px solid #DFE0EB", margin:".5rem 0",padding:"10px", borderRadius:"10px", display:"flex", flexDirection:"column"}}>
                    <div className='form-label'>{x.name}</div> 
                    <div className="form-collection" style={{ margin:"0"}}>
                        {x.comment}
                    </div>
                    </div>
                )) : (
                    <div style={{fontSize:"3rem", color:"#DFE0EB"}}>Empty</div>
                )  : (
                    <div className="form-row">
                <div className="form-collection textarea" style={{width:"80%"}}>
                <div className="form-label">Auditor's Comments</div>
                <div className='label-statement'>
                Auditor can add any comments or notes that they would like included in the Audit Report. These comments should pertain to environmental conditions such as contaminant type present, traffic conditions, weather conditions (if exterior testing), maintenance chemicals, maintenance equipment, the presence of walk-off mats, or any exclusions (such as blocked off hallways or sections under construction).
                </div>
                  <textarea
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    type="text"
                    placeholder="Auditor's Comments"
                    className="form-input textarea-input"
                  />
              </div>
              </div>
                )}
            </div>
            {addCommentActive ? (          <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>


                <div
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {                 
                        setAddCommentActive(false)
                }}
                >
                Back
                </div> 

                <div
    style={{margin:".5rem"}}
                    className={`auth-button addUser confirmDelete ${!comment && "disable-button"}`}
                    onClick={
                        comment ? () => {
                            addComment(comment)
                            setAddCommentActive(false)
                         } : null
                    }
                >

                Save

                </div>

            </div> ) : (
                <div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>
                    <div></div>
                <div
                
                    className="auth-button addUser confirmDelete"
                    onClick={() => {
                         setAddCommentActive(true)
                    }}
                >

                Add Comment

                </div>
            </div>
            )}
        </div>
      </Modal>
  )}

  export default CommentsModal
       