import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ACTION_SELECTED_TESTS from '../../../../actions/SelectedTests/ACTION_SELECTED_TESTS';
import { useDispatch } from 'react-redux';

const AddViewConfirmModal = (props) => {
    const {
        open,
        setOpen,
        addViewFunc,
        rejectFile,
        withTest,
        setWithTest,
        resetActiveTest
        } = props

 const dispatch = useDispatch()
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal" style={{height:"fit-content"}}>
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setOpen(null)
                  open == "manual" ? rejectFile() : resetActiveTest()
                  setWithTest(false)
                }}
              />

            <h1 className="header" style={{width:"100%", width:"100%", height:"33px", display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"}}>Duplicate Audit
            <div className='label-statement' style={{paddingBottom:"10px", marginTop:0,}}>
            Make a copy
          </div>
            </h1>
            <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
            <span style={{margin:"15px 0", textTransform:"none"}}>Are you sure you want to create a copy of this Audit?</span>
            <span style={{margin:"15px 0", textTransform:"none"}}>The copy will create a new audit for this client which will include all audit information (excluding data).
Information can be edited in the copy.</span>
            </div>

            {(<div style={{display:"flex", flexDirection:"column-reverse", justifyContent: "space-between", width:"100%"}}>

                <div
                
                className="auth-button addUser confirmDelete deleteButton"
                style={{margin:0, marginBottom:"10px"}}
                onClick={() => {
                    addViewFunc(withTest)
                    setWithTest(false)
                }} 
                >
                Continue
                </div> 
              </div>)}
        </div>
      </Modal>
  )}

  export default AddViewConfirmModal
       