import { gql } from "apollo-boost";

const deleteUserMutation = gql`
  mutation($_id: ID) {
    deleteUser(_id: $_id) {
      _id
    }
  }
`;

export default deleteUserMutation;
