import { createStore } from "@halka/state";
import produce from "immer";
import clamp from "clamp";
import { nanoid } from "nanoid";

import { SHAPE_TYPES, DEFAULTS, LIMITS } from "./constants";

const APP_NAMESPACE = "__integrtr_diagrams__";

const baseState = {
  selected: null, 
  shapes: {},
  lines: [],
  history: [],
  deletedShapes: {}
};
 
export const useShapes = createStore(() => {
  const initialState = JSON.parse(localStorage.getItem(APP_NAMESPACE));

  return { ...baseState, shapes: initialState ?? {} };
});
const setState = (fn) => useShapes.set(produce(fn));

export const saveDiagram = () => {
  const state = useShapes.get();
  localStorage.setItem(APP_NAMESPACE, JSON.stringify(state.shapes));
};

export const reset = () => {
  localStorage.removeItem(APP_NAMESPACE);

  useShapes.set(baseState);
};

export const createVector = ({ type, x, y }) => {
  setState((state) => {
    state.shapes[nanoid()] = {
      type: type,
      width: DEFAULTS.VECTOR.WIDTH,
      height: DEFAULTS.VECTOR.HEIGHT,
      rotation: DEFAULTS.VECTOR.ROTATION,
      x,
      y,
    };
  });
};

export const createCircle = ({ x, y }) => {
  setState((state) => {
    state.shapes[nanoid()] = {
      type: SHAPE_TYPES.CIRCLE,
      radius: DEFAULTS.CIRCLE.RADIUS,
      fill: DEFAULTS.CIRCLE.FILL,
      stroke: DEFAULTS.CIRCLE.STROKE,
      x,
      y,
    };
  }); 
};

export const createLocation = ({ x, y }) => {  
  let allLocations = useShapes.get()
  let allLocationsArr = Object.entries(allLocations.shapes)
  let locationsCount = allLocationsArr.filter(([key, shape]) => key.includes("location"))

  if(!locationsCount?.length || locationsCount.length == 0) {

    let name = prompt("Write Location Number as three digits like `001 or 010`", "")
    if(name) {
  
    setState((state) => { 
    state.shapes[`location - ${name}`] = {
      type: SHAPE_TYPES.LOCATION,
      radius: DEFAULTS.LOCATION.RADIUS, 
      fill: DEFAULTS.LOCATION.FILL,
      stroke: DEFAULTS.LOCATION.STROKE, 
      new: true, 
      x,
      y,  
    };
  })
}
  } else {
 

    let name = prompt("Write Location Number as three digits like `001 or 010`", "")

    if(name) {

    let existingIndexes = locationsCount?.map( x => Number(x[0].slice(-3))).sort((a,b) => a-b)
    let orderedArr = Array.from(Array(existingIndexes[existingIndexes.length - 1]), (_, index) => index + 1)
    let indexesArr = orderedArr.map( (x, i, a) => existingIndexes.some(y => y == x) ? null : i + 1 ).filter(x=>x)
    let locationNumber = Number(existingIndexes[existingIndexes.length - 1]) == locationsCount.length ? `location - ${locationsCount.length + 1 >= 10 ? `0${locationsCount.length + 1}` : `00${locationsCount.length+1}`}` : `location - ${indexesArr[0] >= 10 ? `0${indexesArr[0]}` : `00${indexesArr[0]}`}`
    setState((state) => { 
      state.shapes[`location - ${name}`] = {
      type: SHAPE_TYPES.LOCATION, 
      radius: DEFAULTS.LOCATION.RADIUS,
      fill: DEFAULTS.LOCATION.FILL,
      stroke: DEFAULTS.LOCATION.STROKE,  
      new: true, 
      x,
      y,  
    };
  });
  } 
  }
  }

export const createLine = (lines) => {
  setState((state) => {
    state.lines = lines
  });
};

export const selectShape = (id) => {
  setState((state) => {
    state.selected = id;
  });
};

export const clearSelection = () => {
  setState((state) => {
    state.selected = null;
  });
};

export const deleteShape = (id, event) => {
  setState((state) => {
    delete state.shapes[id]
  });
  clearSelection()
};

export const moveShape = (id, event) => {
  
  setState((state) => {  
    const shape = state.shapes[id];

    if (shape) {
      let x = event.target.x()
      let y = event.target.y()

      shape.x = x > 330 ? shape.x == 330 ? 329 :330 : x < 0 ? shape.x == 10 ? 11 :10 : x;
      shape.y = y > 330 ? shape.y == 330 ? 329 :330 : y < 0 ? shape.y == 10 ? 11 :10 : y;
      
      if(!shape.new) {
        shape.update = true
      }
    }
  });  
  clearSelection()
};

export const updateAttribute = (attr, value) => {
  setState((state) => {
    const shape = state.shapes[state.selected];

    if (shape) {
      shape[attr] = value;
    }
  });
};

export const transformVectorShape = (node, id, event) => {
  const scaleX = node.scaleX();
  const scaleY = node.scaleY();

  node.scaleX(1);
  node.scaleY(1);

  setState((state) => {
    const shape = state.shapes[id];

    if (shape) {
      shape.x = node.x();
      shape.y = node.y();

      shape.rotation = node.rotation();

      shape.width = clamp(
        node.width() * scaleX,
        LIMITS.VECTOR.MIN,
        LIMITS.VECTOR.MAX
      );
      shape.height = clamp(
        node.height() * scaleY,
        LIMITS.VECTOR.MIN,
        LIMITS.VECTOR.MAX
      );
    }
  });
  clearSelection()
};

export const transformCircleShape = (node, id, event) => {
  const scaleX = node.scaleX();

  node.scaleX(1);
  node.scaleY(1);

  setState((state) => {
    const shape = state.shapes[id];

    if (shape) {
      shape.x = node.x();
      shape.y = node.y();

      shape.radius = clamp(
        (node.width() * scaleX) / 2,
        LIMITS.CIRCLE.MIN,
        LIMITS.CIRCLE.MAX
      );
    }
  });
  clearSelection()
};
