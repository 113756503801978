const MENU_CLICKED = "MENU_CLICKED";

const ACTION_MENU_CLICKED = (row) => {
  return {
    type: MENU_CLICKED,
    row
  };
};

export default ACTION_MENU_CLICKED;
