import React from "react";

import { DRAG_DATA_KEY, SHAPE_TYPES } from "./constants";
import {useDispatch} from "react-redux"
import ACTION_MOBILE_DRAG from "../../../../../actions/MobileDrag/ACTION_MOBILE_DRAG"

import useImage from 'use-image';

import areaRugImg from "../../../../../assests/layoutkeys/areaRug.svg"
import arrowOnImg from "../../../../../assests/layoutkeys/arrow-on.svg"
import barrierImg from "../../../../../assests/layoutkeys/barrier.svg"
import changeFloorTypeImg from "../../../../../assests/layoutkeys/changeFloortype.svg"
import columnImg from "../../../../../assests/layoutkeys/column.svg"
import dcofTestSampleImg from "../../../../../assests/layoutkeys/dcofTestSample.svg"
import deskImg from "../../../../../assests/layoutkeys/desk.svg"
import doubleDoorImg from "../../../../../assests/layoutkeys/doubleDoor.svg"
import elavatorImg from "../../../../../assests/layoutkeys/elevator.svg"
import halfCircleImg from "../../../../../assests/layoutkeys/half-circle.svg"
import otherImg from "../../../../../assests/layoutkeys/other.svg"
import rampImg from "../../../../../assests/layoutkeys/ramp.svg"
import revolvingDoorImg from "../../../../../assests/layoutkeys/revolvingDoor.svg"
import staircaseEscalatorImg from "../../../../../assests/layoutkeys/staircaseEscalator.svg"
import swingDoorImg from "../../../../../assests/layoutkeys/swingDoor.svg"
import walkoffMatImg from "../../../../../assests/layoutkeys/walkoffMat.svg"
import wallImg from "../../../../../assests/layoutkeys/wall.svg"
import waterFeatureImg from "../../../../../assests/layoutkeys/waterFeature.svg"
import {
  useShapes,
} from "./state";

export function Palette({locationMode, shapeToDelete, selectShapeToDelete}) {

  // const [areaRugImg] = useImage(areaRug);
  // const [arrowOnImg] = useImage(arrowOn);
  // const [barrierImg] = useImage(barrier);
  // const [changeFloorTypeImg] = useImage(changeFloorType);
  // const [columnImg] = useImage(column);
  // const [dcofTestSampleImg] = useImage(dcofTestSample);
  // const [deskImg] = useImage(desk);
  // const [doubleDoorImg] = useImage(doubleDoor);
  // const [elavatorImg] = useImage(elavator);
  // const [halfCircleImg] = useImage(halfCircle);
  // const [otherImg] = useImage(other);
  // const [rampImg] = useImage(ramp);
  // const [revolvingDoorImg] = useImage(revolvingDoor);
  // const [staircaseEscalatorImg] = useImage(staircaseEscalator);
  // const [swingDoorImg] = useImage(swingDoor);
  // const [walkoffMatImg] = useImage(walkoffMat);
  // const [wallImg] = useImage(wall);
  // const [waterFeatureImg] = useImage(waterFeature);

  let vectors = [
    {
      title:"area rug",
      img: areaRugImg,
    },
    {
      title:"Enter",
      img: arrowOnImg,
    },
    {
      title:"barrier",
      img: barrierImg,
    },
    {
      title:"Floor",
      img: changeFloorTypeImg,
    },
    {
      title:"column",
      img: columnImg,
    },
    // {
    //   title:"Location",
    //   img: dcofTestSampleImg,
    // },
    {
      title:"desk",
      img: deskImg,
    },
    {
      title:"double",
      img: doubleDoorImg,
    },
    {
      title:"elavator",
      img: elavatorImg,
    },
    {
      title:"half circle",
      img: halfCircleImg,
    },
    {
      title:"other",
      img: otherImg,
    },
    {
      title:"ramp",
      img: rampImg,
    },
    {
      title:"revolving",
      img: revolvingDoorImg,
    },
    {
      title:"stairs",
      img: staircaseEscalatorImg,
    },
    {
      title:"swing",
      img: swingDoorImg,
    },
    {
      title:"walk off",
      img: walkoffMatImg,
    },
    {
      title:"wall",
      img: wallImg,
    },
    {
      title:"water",
      img: waterFeatureImg,
    },
    
  ]

  const dispatch = useDispatch()
  const handleDragStart = (event) => {
    const type = event.target.dataset.shape;
  
    if (type) {
      const offsetX = event.nativeEvent.offsetX;
      const offsetY = event.nativeEvent.offsetY;
  
      const clientWidth = event.target.clientWidth;
      const clientHeight = event.target.clientHeight;
      const dragPayload = JSON.stringify({
        type,
        offsetX,
        offsetY,
        clientWidth,
        clientHeight,
      });
  
      event.nativeEvent.dataTransfer.setData(DRAG_DATA_KEY, dragPayload);
    }
  };
  
  const handleDragStartMobile = (event) => {
    const type = event.target.dataset.shape;
    if (type) {
      let touches = event.targetTouches[0]
      const offsetX = touches.pageX
      const offsetY = touches.pageY;  
      const clientWidth = event.target.clientWidth;
      const clientHeight = event.target.clientHeight;
      let payload = {
        type,
        offsetX,
        offsetY,
        clientWidth,
        clientHeight
      }
      dispatch(ACTION_MOBILE_DRAG(payload))
    }
  };
  const shapes = useShapes((state) => state.shapes);

  return (
    <aside className="palette">
      {/* <h2>Shapes</h2> */}
      {!locationMode ? (
        vectors.map((vector, i) => (
      <div key={i} className="shapeR">
        <img
          src={vector.img}
          data-shape={vector.title}
          draggable
          onDragStart={handleDragStart}
          onTouchMove={handleDragStartMobile}
        />
        <span className="shapeTitle" style={{textTransform:"capitalize"}}>{vector.title}</span>
      </div>
        )) 
      ) : (
        <div style={{display:"flex", justifyContent:"space-between", width:"100%", alignItems:"center"}}>
        <div className="shapeR">
        <div
          className="shape circle locationcircle"
          data-shape={SHAPE_TYPES.LOCATION}
          draggable
          onDragStart={handleDragStart}
          onTouchMove={handleDragStartMobile}
        />
        <span className="shapeTitle">Location</span>
        </div>
        {false ? (
        <div  
          className={`auth-button addUser confirmDelete deleteButton ${!shapeToDelete && "noDelete"}`}
          onClick={() => {
            if(shapeToDelete) {
            let newShapes = {...shapes}
            delete newShapes[shapeToDelete]
            const mappingNewShapes = Object.entries(newShapes)
            const oldShapes = mappingNewShapes.filter(([key, shape]) => !key.includes("location"))
            let newObjectArr = mappingNewShapes.filter(([key, shape]) => key.includes("location")).map(([key, shape], i) => {
            key = `location - ${i + 1 >= 10 ? `0${i + 1}` : `00${i+1}`}`
            return [key, shape]           
            })

          let finalNewShapes = Object.fromEntries([...oldShapes, ...newObjectArr])

          useShapes.set({
            shapes: finalNewShapes,
          })
          selectShapeToDelete("")
        }
        }}
    > 

    {shapeToDelete ?  `Delete ${shapeToDelete}` : "Delete location"}

    </div>
      ) : null}
        </div>
      )}
      
      

    </aside>
  );
}
