import { combineReducers } from "redux";
import loadingSpinnerReducer from "./reducers/LoadingSpinner/loadingSpinnerReducer";
import createAccountEmailReducer from "./reducers/user/CreateAccount/CreateAccountEmail/createAccountEmailReducer";
import createAccountFirstNameReducer from "./reducers/user/CreateAccount/CreateAccountFirstName/createAccountFirstNameReducer";
import createAccountLastNameReducer from "./reducers/user/CreateAccount/CreateAccountLastName/createAccountLastNameReducer";
import createAccountPasswordReducer from "./reducers/user/CreateAccount/CreateAccountPassword/createAccountPasswordReducer";
import createAccountConfirmPasswordReducer from "./reducers/user/CreateAccount/CreateAccountConfirmPassword/createAccountConfirmPasswordReducer";
import createAccountEmailValidReducer from "./reducers/user/CreateAccount/CreateAccountEmail/Validation/Valid/createAccountEmailValidReducer";
import createAccountEmailInvalidReducer from "./reducers/user/CreateAccount/CreateAccountEmail/Validation/Invalid/createAccountEmailInvalidReducer";
import createAccountPasswordValidReducer from "./reducers/user/CreateAccount/CreateAccountPassword/Validation/Valid/createAccountPasswordValidReducer";
import createAccountPasswordInvalidReducer from "./reducers/user/CreateAccount/CreateAccountPassword/Validation/Invalid/createAccountPasswordInvalidReducer";
import createAccountConfirmPasswordValidReducer from "./reducers/user/CreateAccount/CreateAccountConfirmPassword/Validation/Valid/createAccountConfirmPasswordValidReducer";
import createAccountConfirmPasswordInvalidReducer from "./reducers/user/CreateAccount/CreateAccountConfirmPassword/Validation/Invalid/createAccountConfirmPasswordInvalidReducer";
import loginEmailReducer from "./reducers/user/Login/LoginEmail/loginEmailReducer";
import loginPasswordReducer from "./reducers/user/Login/LoginPassword/loginPasswordReducer";
import loginEmailInvalidReducer from "./reducers/user/Login/LoginEmail/Invalid/loginEmailInvalidReducer";
import loginTempoInvalidReducer from "./reducers/user/Login/LoginEmail/Tempo/loginEmailInvalidReducer";
import loginPasswordInvalidReducer from "./reducers/user/Login/LoginPassword/Invalid/loginPasswordInvalidReducer";
import registeredUserFoundReducer from "./reducers/user/Login/RegisteredUserFound/registeredUserFoundReducer";
import userAuthenticatedReducer from "./reducers/user/Authenticated/userAuthenticatedReducer";
import logoutClickedReducer from "./reducers/user/LogOut/logoutClickedReducer";
import dummyTokenReducer from "./reducers/user/Login/DummyToken/dummyTokenReducer";
import temporaryDummyTokenReducer from "./reducers/user/Login/TemporaryDummyToken/temporaryDummyTokenReducer"
import newPasswordReducer from "./reducers/user/Login/NewPassword/newPasswordReducer"
import newPasswordInvalidReducer from "./reducers/user/Login/NewPassword/Invalid/newPasswordInvalidReducer"
import newPasswordValidReducer from "./reducers/user/Login/NewPassword/Valid/newPasswordValidReducer"
import confirmNewPasswordReducer from "./reducers/user/Login/ConfirmNewPassword/confirmNewPasswordReducer"
import confirmNewPasswordInvalidReducer from "./reducers/user/Login/ConfirmNewPassword/Invalid/confirmNewPasswordInvalidReducer"
import confirmNewPasswordValidReducer from "./reducers/user/Login/ConfirmNewPassword/Valid/confirmNewPasswordValidReducer"
import infoActiveReducer from "./reducers/InfoActive/inforActiveReducers";

// report template

import anyProblemsNoReducer from "./reducers/ReportTemplate/AnyProblems/No/anyProblemsNoReducer";
import anyProblemsYesReducer from "./reducers/ReportTemplate/AnyProblems/Yes/anyProblemsYesReducer";
import anyProblemsNotesReducer from "./reducers/ReportTemplate/AnyProblems/Yes/Notes/anyProblemsNotesReducer";
import reportTemplateLastPageOpenedReducer from "./reducers/ReportTemplate/LastPageOpened/reportTemplateLastPageOpenedReducer";
import reportTemplatePDFReducer from "./reducers/ReportTemplate/ReportTemplatePDF/reportTemplatePDFReducer";
import pdfLoadingReducer from "./reducers/PDFLoading/pdfLoadingReducer";


// header reducers

import backRouteReducer from "./reducers/route/backRouteReducer";

// menu clicked reducers

import menuClickedReducer from "./reducers/OptionsMenu/menuClickedReducer";


// sidebar
import activeSideBarReducer from "./reducers/ActiveSideBar/activeSideBarReducer";
// drag event

import mobileDragReducer from "./reducers/MobileDrag/mobileDragReducer"

import selectedTestsReducer from "./reducers/SelectedTests/selectedTestsReducer"
import selectedAuditReducer from "./reducers/SelectedTests/selectedAuditReducer"


const RootReducer = combineReducers({

  infoActive: infoActiveReducer,
  // mobile drag event

  mobileDrag : mobileDragReducer,
  //sidebar

  activeSideBar : activeSideBarReducer,
  // menu clicked reducers
  menuClicked: menuClickedReducer,
  // spinner Reducers
  loadingSpinnerActive: loadingSpinnerReducer,

  selectedTests: selectedTestsReducer,
  selectedAudit: selectedAuditReducer,

  // Create Account Reducers
  createAccountEmail: createAccountEmailReducer,
  createAccountEmailValid: createAccountEmailValidReducer,
  createAccountEmailInvalid: createAccountEmailInvalidReducer,
  createAccountFirstName: createAccountFirstNameReducer,
  createAccountLastName: createAccountLastNameReducer,
  createAccountPassword: createAccountPasswordReducer,
  createAccountPasswordValid: createAccountPasswordValidReducer,
  createAccountPasswordInvalid: createAccountPasswordInvalidReducer,
  createAccountConfirmPassword: createAccountConfirmPasswordReducer,
  createAccountConfirmPasswordValid: createAccountConfirmPasswordValidReducer,
  createAccountConfirmPasswordInvalid: createAccountConfirmPasswordInvalidReducer,

  // Login Form Reducers
  loginEmail: loginEmailReducer,
  loginEmailInvalid: loginEmailInvalidReducer,
  loginTempoInvalid: loginTempoInvalidReducer,
  loginPassword: loginPasswordReducer,
  loginPasswordInvalid: loginPasswordInvalidReducer,
  registeredUserFound: registeredUserFoundReducer,
  userAuthenticated: userAuthenticatedReducer,
  logoutClicked: logoutClickedReducer,
  dummyToken: dummyTokenReducer,

  // New Password Reducers

  temporaryDummyToken: temporaryDummyTokenReducer,
  newPassword: newPasswordReducer,
  newPasswordInvalid: newPasswordInvalidReducer,
  newPasswordValid: newPasswordValidReducer,
  confirmNewPassword: confirmNewPasswordReducer,
  confirmNewPasswordInvalid: confirmNewPasswordInvalidReducer,
  confirmNewPasswordValid: confirmNewPasswordValidReducer,

  // report template Reducers

  
  // report template Reducers

  anyProblemsNo: anyProblemsNoReducer,
  anyProblemsYes: anyProblemsYesReducer,
  anyProblemsNotes: anyProblemsNotesReducer,
  reportTemplateLastPageOpened: reportTemplateLastPageOpenedReducer,
  reportTemplatePDF: reportTemplatePDFReducer,
  pdfLoading: pdfLoadingReducer,

  // back route reducers

  backRoute: backRouteReducer

    
});

export default RootReducer;
