import { useMutation } from "@apollo/client";
import { faEnvelope, faMapMarkerAlt, faPen, faPhoneAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react"
import ACTION_LOADING_SPINNER_ACTIVE from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import addSiteMutation from "../../../../graphql/mutations/site/addSiteMutation";
import updateSiteStatusMutation from "../../../../graphql/mutations/site/updateSiteStatusMutation";
import updateSiteMutation from "../../../../graphql/mutations/site/updateSiteMutation";
import {useHistory} from "react-router-dom"
import { AccountTable as SitesTable } from "../../../resuable/accountTable"
import { useDispatch, useSelector} from "react-redux";
import ACTION_LOADING_SPINNER_RESET from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import AddSiteModal from "../../../resuable/modals/addSiteModal";
import edit from "../../../../assests/icons/edit.svg"
import archive from "../../../../assests/icons/archive.svg"
import ConfirmDeleteModal from "../../../resuable/modals/confirmDeleteModal";
import deleteSiteMutation from "../../../../graphql/mutations/site/deleteSiteMutation";


const ClientSites = (props) => {
  const backRoute = useSelector((state) => state.backRoute.backRoute);

    const {
        selectedClient,
        sites,
        sitesRefetch,
        user,
        allSitesRefetch,
        sitesData,
        setSitesData,
        allSitesLoading,
        siteModal, 
        setSiteModal,
        backToAudit,
setBackToAudit
    } = props

    const dispatch = useDispatch()

    const [toggleChecked, setToggleChecked] = useState(false)
    const sitesColumns = [
      {
        name:"edit",
        cell: (row) => <img src={edit} style={{cursor:"pointer"}} onClick={() => editSite(row)} />,
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        style:{
            fontSize:"19px",
            color:"#5C92BF"
            
        }
      },
      {
            name:"Delete",
            cell: (row) => <img style={{cursor:"pointer"}} onClick={() => {
              setSelectedSite(row)
              setDeleteSite(true)
            }} src={archive} />,
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
            style:{
                fontSize:"19px",
            } 
        },
        {
            name: 'status',
            cell: (row) => (
                <label style={{cursor:"pointer"}} onClick={() => updateSiteStatusFunc(row)} className="toggle-control">
                    <span className={`${row.status ? "checked" : "notChecked"}`}></span>
                    <span className="control"></span>
                </label>
                ),
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
            style:{
                fontSize:"19px"
            }
            
        },
        {
            name: 'site name',
            selector: row => row.name,

        },
        {
            name: 'contact person',
            selector: row => row.contact,

        },
        {
            name: 'email',
            width:"200px",
            cell: row => (
              <div>{row.email}</div>
            ),
        },
        {
            name: 'phone number',
            width:"200px",
            cell: row => (
              <div> {row.phoneNumber ? row.phoneNumber : "not provided"} </div>
            ),

        },
        {
            name: 'site address',
            selector: row => row.address,

        },
        {
          name: 'zip code',
          selector: row => row.zip,

      }
    ];

      let history = useHistory()

    const [
        addSite,
        { data: addSiteData },
      ] = useMutation(addSiteMutation)

      const [
        deleteSiteF,
        { data: deleteSiteData },
      ] = useMutation(deleteSiteMutation)


      const subScreen = (row) => {
          history.push({
            pathname:"/admin/audits",
            state:{
            clientFilter: selectedClient.id,
            clientName: selectedClient.company,
            siteName: row.name,
             siteFilter:row.id
            }
          })
      }     
      
    const [siteName, setSiteName] = useState("")
    const [siteEmail, setSiteEmail] = useState("")
    const [siteAddress, setSiteAddress] = useState("")
    const [siteContactPersonName, setSiteContactPersonName] = useState("")
    const [siteContactPersonPhoneNumber, setSiteContactPersonPhoneNumber] = useState("")
    const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [selectedSite, setSelectedSite] = useState(null)
    const [zip, setZip] = useState("")
    const [country, setCountry] = useState("")

    const addSiteFunc = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setSiteModal(null)
      addSite({
        variables:{
            name: siteName,
            address: `${siteAddress}, ${city}, ${state}, ${country}`,
            contactPersonName: siteContactPersonName,
            contactPersonPhoneNumber: siteContactPersonPhoneNumber,
            contactPersonEmail: siteEmail,
            client: selectedClient.id, 
            zip: zip,
            user: user?.user?._id
        }
      })
      .then((res) => {
        setSiteName("")
        setSiteEmail("")
        setSiteAddress("")
        setCountry("")
        setCity("")
        setState("")
        setZip && setZip("")
        setSiteContactPersonName("")
        setSiteContactPersonPhoneNumber("")
        sitesRefetch()
        allSitesRefetch()
        if(backToAudit) {
          setBackToAudit(false)
          history.push({
            pathname: "/admin/audits",
            state: {
              clientId: selectedClient?.id,
              siteId: res?.data?.addSite?._id
            }
          })
        }
      })
      

    }

    
  
  // toggle Site status

  
  const [
    updateSiteStatus,
    { data: updateSiteStatusData },
  ] = useMutation(updateSiteStatusMutation)

  

  const updateSiteStatusFunc = (row) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    const newRow = row
    newRow.status = !newRow.status
    setSitesData(sitesData.map(t => t.id === newRow.id ? newRow : t))
    updateSiteStatus({
        variables:{
          _id: newRow.id,
          status: newRow.status
        }
      }).then(() => {
        dispatch(ACTION_LOADING_SPINNER_RESET())
        sitesRefetch()
      })
  }
    

  // edit site func

  const editSite = (site) => {
    setSelectedSite(site)
    setSiteName(site.name)
    setSiteEmail(site.email)
    setSiteAddress(site.address.split(', ')[0])
    setCity(site.address.split(', ')[1])
    setState(site.address.split(', ')[2])
    setCountry(site.address.split(', ')[3])
    setSiteContactPersonName(site.contact)
    setSiteContactPersonPhoneNumber(site.phoneNumber)
    setZip(site.zip)
    setSiteModal("Edit Site")
}
  
// update user func.
const [editSiteModal, setEditSiteModal] = useState(null)
const [
  updateSite,
  { data: updateSiteData },
] = useMutation(updateSiteMutation)

const updateSiteFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setSiteModal(null)
  const newSite = selectedSite
  newSite.name = siteName,
  newSite.email = siteEmail,
  newSite.address = `${siteAddress}, ${city}, ${state}, ${country}`,
  newSite.contact = siteContactPersonName,
  newSite.zip = zip

  setSelectedSite(newSite)
  setSitesData(sitesData.map(t => t.id === newSite.id ? newSite : t))

  updateSite({
    variables:{
      _id: selectedSite.id,
      name: siteName,
      address: `${siteAddress}, ${city}, ${state}, ${country}`,
      contactPersonName: siteContactPersonName,
      contactPersonPhoneNumber: siteContactPersonPhoneNumber,
      contactPersonEmail: siteEmail,
      zip: zip
    }
  }) 
  .then(() => {
    setSiteName("") 
    setSiteEmail("")
    setSiteAddress("")
    setCountry("")
    setCity("")
    setState("")
    setZip && setZip("")
    setSiteContactPersonName("")
    setSiteContactPersonPhoneNumber("")
    dispatch(ACTION_LOADING_SPINNER_RESET())    
    sitesRefetch()
  })

}


    // client search 

    const [searchFilter, setSearchFilter] = useState("")
    const [sitesFilter, setSitesFilter] = useState(null)
    useEffect(() => {
      if (sites) {
        if (sites.length > 0) {
          if (searchFilter) {
            setSitesFilter(
            [...sites].filter((x) => {
                return (
                  new RegExp(searchFilter, "gi").test(x.name) ||
                  new RegExp(searchFilter, "gi").test(x.address) ||
                  new RegExp(searchFilter, "gi").test(x.contactPersonName) ||
                  new RegExp(searchFilter, "gi").test(x.address)
                );
              })
            )
          } else {
            setSitesFilter(null)
          }
        }
      }
    }, [searchFilter, sites]);


        
    useEffect(() => {
      if(sitesFilter) {
        setSitesData(
          sitesFilter.map((site) => {
            const mappingUser = {
                id: site._id,
                status: site.status,
                name: site.name,
                address: site.address,
                contact: site.contactPersonName,
                phoneNumber: site.contactPersonPhoneNumber,
                email: site.contactPersonEmail,
                zip: site.zip
        
            }                                                                                   

            return mappingUser
        })  
        )
        dispatch(ACTION_LOADING_SPINNER_RESET())

      } else if (sites) {
          setSitesData(
            sites.map((site) => {
              const mappingUser = {
                  id: site._id,
                  status: site.status,
                  name: site.name,
                  address: site.address,
                  contact: site.contactPersonName, 
                  phoneNumber: site.contactPersonPhoneNumber,
                  email: site.contactPersonEmail,
                  zip: site.zip
          
              }                                                                                   

              return mappingUser
          })  
          )
          dispatch(ACTION_LOADING_SPINNER_RESET())
      }
  },[sites, sitesFilter, backRoute])
  

const [city, setCity]  = useState("")
const [state, setState] = useState("")

const [deleteSite,setDeleteSite] = useState(false)

const deleteSiteFunc = () => {
   dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setDeleteSite(null)
  deleteSiteF({
    variables: {
      _id: selectedSite.id
    }
  }).then(x => {
    sitesRefetch()    
  }
  )
}

    
    return(
      <>
      {editSiteModal && (
        <AddSiteModal
        country={country}
        setCountry={setCountry}
        zip={zip}
        setZip={setZip} 
        siteModal={editSiteModal}
        setSiteModal={setEditSiteModal}
        setSiteName={setSiteName}
        siteName={siteName}
        setSiteEmail={setSiteEmail}
        siteEmail={siteEmail}
        updateSiteFunc={updateSiteFunc}
        emailAlreadyRegistered={emailAlreadyRegistered}
        invalidEmail={invalidEmail}
        setEmailAlreadyRegistered={setEmailAlreadyRegistered}
        setSiteAddress={setSiteAddress}
        siteAddress={siteAddress}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        siteContactPersonName={siteContactPersonName}
        setSiteContactPersonName={setSiteContactPersonName}
        siteContactPersonPhoneNumber={siteContactPersonPhoneNumber}
        setSiteContactPersonPhoneNumber={setSiteContactPersonPhoneNumber}

        />
      )}
      <ConfirmDeleteModal
                deleteType={"Site"}
                deleteModal={deleteSite}
                setDeleteModal={setDeleteSite}
                userType={"Admin"}
                deleteUserFunc={deleteSiteFunc}
                />

            <SitesTable
            loading={allSitesLoading}
              zip={zip}
              setZip={setZip}
              country={country}
              setCountry={setCountry}
              selectedClient={selectedClient}
              accountColumns={sitesColumns}
              accountData={sitesData}
              accountTabs={false}
              accountType="Site"
              // search sites
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
             // add site modal
              siteModal={siteModal}
              setSiteModal={setSiteModal}
              setSiteName={setSiteName}
              siteName={siteName}
              setSiteEmail={setSiteEmail}
              siteEmail={siteEmail}
              addSiteFunc={addSiteFunc}
              updateSiteFunc={updateSiteFunc}
              emailAlreadyRegistered={emailAlreadyRegistered}
              invalidEmail={invalidEmail}
              setEmailAlreadyRegistered={setEmailAlreadyRegistered}
              setSiteAddress={setSiteAddress}
              siteAddress={siteAddress}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              siteContactPersonName={siteContactPersonName}
              setSiteContactPersonName={setSiteContactPersonName}              
              siteContactPersonPhoneNumber={siteContactPersonPhoneNumber}
              setSiteContactPersonPhoneNumber={setSiteContactPersonPhoneNumber}
              subScreen={subScreen}
            />
    </>
    )
}

export default ClientSites