import { gql } from "apollo-boost";

const addViewMutation = gql`
  mutation(
    $project: ID,
    $data: String,
  ) {
    addView(
    project: $project,
    data: $data
    ) { 
    _id
    project
    default
    firstTime
    areas
    locations
    passed
    failed
    reports
    createdAt
  }
  }
`;

export default addViewMutation;
