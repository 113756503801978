import React, { useEffect } from 'react'
import Modal from "react-modal"
import "../../../../assests/styles/Modal.scss"
import Multiselect from 'multiselect-react-dropdown';
const AddTerritoryModal = (props) => {
    const {
        addTerritoryModal,
        setAddTerritoryModal,
        addTerritoryFunc,
        territoryToAdd,
        setTerritoryToAdd,
        territories,
        updateTerritoryFunc,
    } = props

    const onSelectFunc = (selectedList, selectedItem) => {

            setTerritoryToAdd(selectedList)
         }
         
         const onRemoveFunc = (selectedList, removedItem) => {

          setTerritoryToAdd(selectedList)
         }

    
  return(
<Modal
        isOpen={addTerritoryModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0", 
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >  
              {addTerritoryModal && (
          <div className="modal task-modal" style={{overflow:"visible", height:"100vh", width:"60vw"}}>
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>{addTerritoryModal.includes("Edit") ? addTerritoryModal : addTerritoryModal.includes("Edit") ? "Edit territory" : `Add ${addTerritoryModal}`}</h1>
          <div style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}} className="form-group-container task-form">        
          <>
            {<div className="form-collection">
              <div className="form-label"><span>Add Territory<span style={{color:"red"}}> *</span></span></div>            
                    <Multiselect 
                            selectionLimit={100}
                            options={territories} // Options to display in the dropdown
                            selectedValues={territoryToAdd} // Preselected value to persist in dropdown
                            onSelect={onSelectFunc} // Function will trigger on select event
                            onRemove={onRemoveFunc} // Function will trigger on remove event
                            displayValue="label" // Property name to display in the dropdown options
                    />

            </div>}
            </>
          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {
                  setAddTerritoryModal(null)
                  setTerritoryToAdd("")
                }}
            >

            Cancel

            </div>            <div
            style={{margin:".5rem"}}
              className={`${territoryToAdd?.length > 0 ? "auth-button addUser" :"auth-button addUser disable-button" }`}
              onClick={territoryToAdd?.length > 0 ? addTerritoryFunc : null}
            >
              {addTerritoryModal.includes("Edit") ? "Update" : "Add"}
            </div>
          </div>
        </div>)}
      </Modal>
  )}

  export default AddTerritoryModal
       