import { gql } from "apollo-boost";

const addDiagramMutation = gql`
  mutation(
    $name: String,
    $description: String,
    $drawing: String,
    $area: ID
  ) {
    addDiagram(
    name: $name
    description: $description
    drawing: $drawing
    area: $area

    ) {
    _id
    name
    description
    drawing
    area
  }
  }
`;

export default addDiagramMutation;
