const LOGIN_TEMPO_INVALID = "LOGIN_TEMPO_INVALID";
const LOGIN_TEMPO_NOT_INVALID = "LOGIN_TEMPO_NOT_INVALID";

const loginTempoInvalidReducer = (
  state = { login_tempo_invalid: false },
  action
) => {
  switch (action.type) {
    case LOGIN_TEMPO_INVALID:
      return { ...state, login_tempo_invalid: true };
    case LOGIN_TEMPO_NOT_INVALID:
      return { ...state, login_tempo_invalid: false };
    default:
      return { ...state };
  }
};

export default loginTempoInvalidReducer;
