import React, { Component, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

export const MyCalendar = ({audits}) =>  {

  const [events, setEvents] = useState([{
    start: moment().toDate(),
    end: moment()
      .toDate(),
    title: "Audit Test"
  }])

  useEffect(() => {
    if(audits) {
        console.log(audits, 109)
    }
  },[audits])

    return (
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={events}
          style={{ height: "100%", width:"100%" }}
        />
    );
}

