import { gql } from "apollo-boost";

const allTemplatesQuery = gql`
  
    query(
    $type: String
  ) { 
    allTemplates (
      type: $type
    ) {
    _id
    type
    name
    default
    html
    css
    by
    thumbnail
    history {
      _id
      template
      html
      css
      by
      createdAt
    }
    }
  }
  
`;

export default allTemplatesQuery;
