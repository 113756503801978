import { gql } from "apollo-boost";

const addClientMutation = gql`
  mutation(
    $companyName: String,
    $companyAddress: String,
    $contactPersonName: String,
    $contactPersonEmail: String,
    $contactPersonPhoneNumber: String,
    $contactPersonWorkPhoneNumber: String,
    $status: Boolean,
    $image: String 
    $user: ID,
    $zip: String,
    $siteID: ID
  ) {
    addClient(
    companyName:$companyName,
    image:$image
    companyAddress:$companyAddress,
    contactPersonName:$contactPersonName,
    contactPersonEmail:$contactPersonEmail,
    contactPersonPhoneNumber:$contactPersonPhoneNumber,
    contactPersonWorkPhoneNumber:$contactPersonWorkPhoneNumber,
    status:$status,
    user: $user,
    zip: $zip
    siteID: $siteID
    ) {
    _id
    image
    companyName,
    companyAddress,
    contactPersonName,
    contactPersonEmail,
    contactPersonPhoneNumber,
    contactPersonWorkPhoneNumber,
    status,
    user,
    zip,
    siteID
    }
  }
`;

export default addClientMutation;
