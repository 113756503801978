const ACTIVE_SIDE_BAR = "ACTIVE_SIDE_BAR";
const RESET_SIDE_BAR = "RESET_SIDE_BAR";

const activeSideBarReducer = (
  state = { active: false },
  action
) => {
  switch (action.type) {
    case ACTIVE_SIDE_BAR:
      return { ...state, active: true };
    case RESET_SIDE_BAR:
      return { ...state, active: false };
    default:
      return { ...state };
  }
};

export default activeSideBarReducer;
