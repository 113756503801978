import { gql } from "apollo-boost";

const allSitesQuery = gql`
  
    query(
    $client: ID
    $user: ID
    $status: Boolean
  ) { 
    allSites (
      client: $client
      user: $user
      status: $status
    ) {
    _id
    name
    address
    client
    contactPersonName,
    contactPersonEmail,
    contactPersonPhoneNumber
    status
    notes
    zip
    }
  }
  
`;

export default allSitesQuery;
