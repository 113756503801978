import React from 'react'
import Modal from "react-modal"
import "../../../../assests/styles/Modal.scss"
import DatePicker from "react-datepicker"
import addDays from "date-fns/addDays"
import Dropdown from "react-dropdown"
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const AddReportsModal = (props) => {
    const {
        user,
        reportsModal,
        setReportsModal,
        freeReports,
        setFreeReports,
        purchasedReports,
        setPurchasedReports,
        expirationDate,
        setExpirationDate,
        reportsBundle,
        setReportsBundle,
        addReportsFunc,
    } = props


    const handleChangeRaw = (value) => {
      if (value === "day") {
        setExpirationDate(addDays(new Date(), 1));
      }
      if(value === "week") {
        setExpirationDate(addDays(new Date(), 365))
      }
      if(value === "month") {
        setExpirationDate(addDays(new Date(), 30))
      }
      if(value === "year") {
        setExpirationDate(addDays(new Date(), 365))
      }
    };


    const location = useLocation();  
    const history = useHistory();  


    const goToStoreFunc = () => {

      history.push({
        pathname: "/store",
        state: {
          user: user?.user,
          route: location.pathname,
          license: user?.user?.licenseNumber ?  JSON.parse(user?.user?.licenseNumber) : null

        }
      })

        // window.open("https://www.walkwaymg.com/collections/services/products/step-audit-reports")
        setReportsModal(null)
    }

  return(
<Modal
        isOpen={reportsModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { reportsModal && <div className="modal task-modal" style={{height: "fit-content"}}>
               

          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>{`Add New Reports`}</h1>
          <div className="form-group-container task-form" style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}}>  

           {reportsModal.includes("Reports") && <><div className="form-row">
            <div className="form-collection">
              <div className="form-label">Free Reports</div>
              <input
                disabled
                style={{opacity:".5"}}
                onKeyDown={(e) => {
                  if (
                    (e.keyCode >= 8 && e.keyCode < 32) ||
                    (e.keyCode >= 37 && e.keyCode <= 40) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode == 17) || 
                    (e.keyCode == 86)      
                  ) {
                    return e.keyCode;
                  } else {
                   e && e.preventDefault();
                  }
                }}
                onChange={(e) => setFreeReports(e.target.value)}
                value={freeReports}
                type="text"
                className="form-input"
              />
            </div>
            </div>
            <div className="form-row">
            <div className="form-collection">
              <div className="form-label">Purchased Reports</div>
              <input
              style={{opacity:".5"}}
                disabled
                onKeyDown={(e) => {
                  if (
                    (e.keyCode >= 8 && e.keyCode < 32) ||
                    (e.keyCode >= 37 && e.keyCode <= 40) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode == 17) || 
                    (e.keyCode == 86)      
                  ) {
                    return e.keyCode;
                  } else {
                   e && e.preventDefault();
                  }
                }}
                onChange={(e) => setPurchasedReports(e.target.value)}
                value={purchasedReports}
                type="text"
                className="form-input"
              />
            </div>
            </div>
            <div className="form-row">
                <div className="form-collection textarea" style={{width:"100%"}}>
                <div className="form-label">Expiration Date</div>
                <DatePicker
                style={{opacity:".5"}}
                    disabled
                    className='form-input Dropdown-disabled'
                    selected={expirationDate}
                    onChange={(date) => setExpirationDate(date)}
                    placeholderText='Enter a date'
                    onChangeRaw={(event) => handleChangeRaw(event.target.value)}
                  />
              </div>
            </div>
  <div className="form-row">
            <div className="form-collection">
              <div className="form-label">Add Reports Bundles</div>
            <Dropdown
                  options={["5", "10", "15", "20", "25"]}
                  onChange={(choice) => {
                   
                    setReportsBundle(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="Select Reports Bundle"
                  value={reportsBundle}
                  menuClassName='underMenu'
                />  
                </div>
            </div>
        </>}

          {!reportsModal.includes("Reports") && <>
          <div className='label-statement' style={{padding:"0 10px", margin:"10px"}}>
          {`To purchase new reports click the link
below.
`}</div>    
          <div className="form-row">
                <div className="form-collection" style={{ display:"flex", justifyContent:"center", width:"100%", flexDirection:"column", alignItems:"center"}}>
                <div className="form-label"  style={{ display:"flex", justifyContent:"center", fontWeight:"600", textTransform:"none"}}>Your tier is</div>
                  <div style={{border:"2px solid grey", padding:"5px 30px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                      {reportsModal}
                  </div>
              </div>
              </div></>}
          </div>
          <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {                  
                    setFreeReports && setFreeReports("")
                    setPurchasedReports && setPurchasedReports("")  
                    setExpirationDate && setExpirationDate("")
                    setReportsBundle && setReportsBundle("")
                    setReportsModal(null)
                }}
            >

            Cancel

            </div>
            <div
            style={{margin:".5rem"}}
              className={`${reportsModal.includes("Reports") ? reportsBundle ? "auth-button addUser" : "auth-button addUser disable-button" : "auth-button addUser"}`}
              onClick={ reportsModal.includes("Reports") ? reportsBundle ? addReportsFunc : null : goToStoreFunc }
            >
              
              {reportsModal.includes("Reports") ? "Add" : "Go to store"}

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default AddReportsModal
       