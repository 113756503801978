import React, { useRef, useEffect, useCallback } from "react";
import { Circle, Image, Rect as KonvaRectangle, Transformer } from "react-konva";

import { LIMITS } from "./constants";
import { selectShape, transformVectorShape, moveShape, deleteShape } from "./state";
import useImage from "use-image";
import areaRug from "../../../../../assests/layoutkeys/areaRug.svg"
import arrowOn from "../../../../../assests/layoutkeys/arrow-on.svg"
import barrier from "../../../../../assests/layoutkeys/barrier.svg"
import changeFloorType from "../../../../../assests/layoutkeys/changeFloortype.svg"
import column from "../../../../../assests/layoutkeys/column.svg"
import dcofTestSample from "../../../../../assests/layoutkeys/dcofTestSample.svg"
import desk from "../../../../../assests/layoutkeys/desk.svg"
import doubleDoor from "../../../../../assests/layoutkeys/doubleDoor.svg"
import elavator from "../../../../../assests/layoutkeys/elevator.svg"
import halfCircle from "../../../../../assests/layoutkeys/half-circle.svg"
import other from "../../../../../assests/layoutkeys/other.svg"
import ramp from "../../../../../assests/layoutkeys/ramp.svg"
import revolvingDoor from "../../../../../assests/layoutkeys/revolvingDoor.svg"
import staircaseEscalator from "../../../../../assests/layoutkeys/staircaseEscalator.svg"
import swingDoor from "../../../../../assests/layoutkeys/swingDoor.svg"
import walkoffMat from "../../../../../assests/layoutkeys/walkoffMat.svg"
import wall from "../../../../../assests/layoutkeys/wall.svg"
import waterFeature from "../../../../../assests/layoutkeys/waterFeature.svg"
import archive from "../../../../../assests/icons/archive.svg"


const boundBoxCallbackForVector = (oldBox, newBox) => {
  // limit resize
  if (
    newBox.width < LIMITS.VECTOR.MIN ||
    newBox.height < LIMITS.VECTOR.MIN ||
    newBox.width > LIMITS.VECTOR.MAX ||
    newBox.height > LIMITS.VECTOR.MAX
  ) {
    return oldBox;
  }
  return newBox;
};

export function Vector({ locationMode, id, passed,
  loadingCanvas, setHandleDeleteModal, isSelected, type, ...shapeProps }) {

  const [areaRugImg] = useImage(areaRug);
  const [arrowOnImg] = useImage(arrowOn);
  const [barrierImg] = useImage(barrier);
  const [changeFloorTypeImg] = useImage(changeFloorType);
  const [columnImg] = useImage(column);
  const [dcofTestSampleImg] = useImage(dcofTestSample);
  const [deskImg] = useImage(desk);
  const [doubleDoorImg] = useImage(doubleDoor);
  const [elavatorImg] = useImage(elavator);
  const [halfCircleImg] = useImage(halfCircle);
  const [otherImg] = useImage(other);
  const [rampImg] = useImage(ramp);
  const [revolvingDoorImg] = useImage(revolvingDoor);
  const [staircaseEscalatorImg] = useImage(staircaseEscalator);
  const [swingDoorImg] = useImage(swingDoor);
  const [walkoffMatImg] = useImage(walkoffMat);
  const [wallImg] = useImage(wall);
  const [waterFeatureImg] = useImage(waterFeature);

  let vectors = [
    {
      title:"area rug",
      img: areaRugImg,
    },
    {
      title:"Enter",
      img: arrowOnImg,
    },
    {
      title:"barrier",
      img: barrierImg,
    },
    {
      title:"Floor",
      img: changeFloorTypeImg,
    },
    {
      title:"column",
      img: columnImg,
    },
    {
      title:"Location",
      img: dcofTestSampleImg,
    },
    {
      title:"desk",
      img: deskImg,
    },
    {
      title:"double",
      img: doubleDoorImg,
    },
    {
      title:"elavator",
      img: elavatorImg,
    },
    {
      title:"half circle",
      img: halfCircleImg,
    },
    {
      title:"other",
      img: otherImg,
    },
    {
      title:"ramp",
      img: rampImg,
    },
    {
      title:"revolving",
      img: revolvingDoorImg,
    },
    {
      title:"stairs",
      img: staircaseEscalatorImg,
    },
    {
      title:"swing",
      img: swingDoorImg,
    },
    {
      title:"walk off",
      img: walkoffMatImg,
    },
    {
      title:"wall",
      img: wallImg,
    },
    {
      title:"water",
      img: waterFeatureImg,
    },
    
  ]
  const shapeRef = useRef();
  const transformerRef = useRef();

  const ICON =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDI2LjY2NyA0MjYuNjY3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MjYuNjY3IDQyNi42Njc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBkPSJNMjEzLjMzMyw4NS4zMzNWMEwxMDYuNjY3LDEwNi42NjdsMTA2LjY2NywxMDYuNjY3VjEyOGM3MC43MiwwLDEyOCw1Ny4yOCwxMjgsMTI4cy01Ny4yOCwxMjgtMTI4LDEyOHMtMTI4LTU3LjI4LTEyOC0xMjgNCgkJCUg0Mi42NjdjMCw5NC4yOTMsNzYuMzczLDE3MC42NjcsMTcwLjY2NywxNzAuNjY3UzM4NCwzNTAuMjkzLDM4NCwyNTZTMzA3LjYyNyw4NS4zMzMsMjEzLjMzMyw4NS4zMzN6Ii8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=";
  const [icon] = useImage(ICON);

  useEffect(() => {
    if (isSelected) {
      transformerRef.current && transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleSelect = useCallback(
    (event) => {
      event.cancelBubble = true;

      selectShape(id);
    },
    [id]
  );

  const handleDrag = useCallback(
    (event) => {
          moveShape(id, event);
    },
    [id]
  );

  const handleDelete = (event) => {
    setHandleDeleteModal({id, event})
    }

  const handleTransform = useCallback(
    (event) => {
      transformVectorShape(shapeRef.current, id, event);
    },
    [id]
  );
  
  const [trash] = useImage(archive, 'Anonymous');

  return (
    <>
    {!locationMode ? (
      <>
      <Image
        image={vectors.filter(x => x.title == type)[0].img}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onMouseDown={handleSelect}
        onTouchStart={handleSelect}
        onDblClick={handleSelect}
        onTap={handleSelect}
        onDragStart={handleSelect}
        onDragEnd={handleDrag}
        onTransformEnd={handleTransform}
      />
      {isSelected && passed == null && !loadingCanvas ?  (
        <Transformer
        anchorSize={5}
        borderDash={[6, 2]}
        ref={transformerRef}
        boundBoxFunc={boundBoxCallbackForVector}
        >
           {trash && <Image  
            width={15}
            height={15}
            image={trash}
            // ref={deleteButton}
            onClick={handleDelete}
            x={shapeRef?.current?.width()}
            y={-10}
          />}
        </Transformer>
      ) : null}
      </>
    ) : (
      <>
      <Image
        image={vectors.filter(x => x.title == type)[0].img}
        ref={shapeRef}
        {...shapeProps}
      /> 
          </>
    )}
     
     
    </>
  );
}
