import { gql } from "apollo-boost";

const allReportsQuery = gql`
  
query(
$user: ID
$wmg: ID
$view: ID
$limit: Int,
$status: String,
$skip: Int
$role: String
) { 
allReports ( 
  user: $user
  status: $status
  view: $view
  limit: $limit
  skip: $skip
  wmg: $wmg
  role: $role
) {
    _id
    dateOfAudit
    updatedAt
    view
    dateOfReportGeneration
    dateOfApproval
    type
    finalReport
    count
    user
    updatedBy
    expDate
    status
    archive
    wmg
    questionnaire
    note
    site {
      _id
      name
    }
    client {
      _id
      companyName
    }
    reportTemplate
    html
    css
    newUpdate
    }
  }
`;

export default allReportsQuery;