import React, { useCallback, useEffect } from "react";

import { SHAPE_TYPES } from "./constants";
import { useShapes } from "./state";
import { Circle } from "./Circle";
import { Vector } from "./Vector";
import { Location } from "./Location";
import { Label, Tag, Text } from "react-konva";

export function Shape({fromArea,setHandleDeleteModal,  loadingCanvas, passed, addLocationToDeleted, locationId, shape,loadMode, isSelectedFunc, locationMode, isNew, shapeToDelete, selectShapeToDelete }) {
  const isSelectedSelector = useCallback(
    (state) => state.selected === shape.id,
    [shape]
  );
  const isSelected = useShapes(isSelectedSelector);

    useEffect(() => {
      isSelectedFunc(isSelected)
    },[isSelected])
  if (shape.type === SHAPE_TYPES.LOCATION) {
    return (
      <>
      <Location setHandleDeleteModal={setHandleDeleteModal} loadingCanvas={loadingCanvas} passed={passed} addLocationToDeleted={addLocationToDeleted} fromArea={fromArea} locationId={locationId} loadMode={loadMode} shapeToDelete={shapeToDelete} selectShapeToDelete={selectShapeToDelete} isNew={isNew} locationMode={locationMode} {...shape} isSelected={isSelected} />
      </>
    );
  } else {
    return <Vector loadingCanvas={loadingCanvas} passed={passed} setHandleDeleteModal={setHandleDeleteModal} locationMode={locationMode} {...shape} isSelected={isSelected} />;
  }

  return null;
}
