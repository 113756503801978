import { gql } from "apollo-boost";

const allDiagramsQuery = gql`
   query(
    $area: ID
  ) { 
    allDiagrams (
      area: $area
    ) {
    _id
    name
    description
    drawing
    area
    }
  }
`;

export default allDiagramsQuery;