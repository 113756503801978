import { gql } from "apollo-boost";


gql`
  type TileResultType {
    first: String
    firstAfter: String
    Second: String
    SecondTile: String
    exist: Boolean
    pass: Boolean
    passAfter: Boolean
    result: String
    dcof: String
  }
`;
gql`
  input TileInput {
    tileResult: TileResultType
  }
`;

const updateViewTileMutation = gql`
  mutation(
      $_id: ID 
      $tileResult: TileInput,
      ) {
    updateViewTile(
    _id: $_id
    tileResult: $tileResult
    ) {
      _id  
      project
      default
      firstTime
      areas
      locations
      passed
      failed
      reports
      createdAt
 tileResult {
      first
      firstAfter
      second
      secondAfter
      exist
      result
      pass
      passAfter
      dcof
    }
    }
  }
`;

export default updateViewTileMutation;
