import React, { useEffect, useRef, useState } from 'react'
import Modal from "react-modal"

import { faEraser, faPaintBrush, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import CanvasDraw from 'react-canvas-draw';
import Dropdown from "react-dropdown";
import archive from "../../../../assests/icons/archive.svg"
import Planner from '../addAreaModal/diagramWrapper';
import { reset, useShapes } from '../addAreaModal/diagramWrapper/state';
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE';
import produce from "immer";
import { useDispatch } from 'react-redux';
import ConfirmDeleteModal from '../confirmDeleteModal';
import { DataSpinner } from '../../spinner/dataSpinner';
import html2canvas from "html2canvas"

const AdLocationModal = (props) => {

    const {
      addLocationToDeleted,
        setNewDiagramImage,
        locationModal,
        setLocationModal,
        locationCondition,
        setLocationCondition,
        locationReadings,
        setLocationReadings,
        locationDetails,
        setLocationDetails,
        setLocationModalPage,
        locationModalPage,
        addLocationFunc,
        areaLines,
        setAreaLines,
        areaDiagram,
        locationCoordinates,
        setLocationCoordinates,
        setAreaDiagram,
        updateLocationFunc,
        locationModalLabel,
        setLocationModalLabel,
        shapeToDelete,
        selectShapeToDelete,
        deleteLocationFunc,
        photo,
        setPhoto,
    } = props
    const [LoadingShape, setLoadingShape] = useState(false)

  const canvasRef = useRef()
  const shapes = useShapes((state) => state.shapes);
  const setState = (fn) => useShapes.set(produce(fn));

    // canvas diagram
    const [canvasEraser, setCanvasEraser] = useState(false)

    const dispatch = useDispatch()

    const [deleteLocationModal, setDeleteLocationModal] = useState(null)
    const deleteLocationFuncHelper = (newD) => {
      deleteLocationFunc(locationModal, newD)
    }

    const [loadingCanvas, setLoadingCanvas] = useState(false)

  return(
<Modal
        isOpen={locationModal ? true : false}
        style={{ 
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
            
              {locationModal && (
               <div className="modal task-modal" style={{width: locationModal.includes("Locations") ? "60vw" : "fit-content", height:"fit-content"}}>
                    {loadingCanvas ? <DataSpinner over /> : null}

                <ConfirmDeleteModal
                loadingCanvas={loadingCanvas}
                setLoadingCanvas={setLoadingCanvas}
                deleteModal={deleteLocationModal}
                setDeleteModal={setDeleteLocationModal}
                deleteType={"location"}
                setPhoto={setPhoto}
                deleteLocationFunc={deleteLocationFuncHelper} 
                setLocationModal={setLocationModal}               
                />
                <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  reset()
                  setPhoto(null)
                  setLocationModal(null)
                  setLocationDetails("")
                  setLocationReadings("")
                  setLocationCondition("")
                  setLocationCoordinates(null)
                  setLocationModalLabel("")
                  setLocationModalPage(0)
                }}
              /> 

          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px", display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"}}>{locationModal.includes("Edit") ? locationModal : locationModal.includes("Locations") ? `Edit ${locationModal}` : "Delete Location"}
          {locationModal.includes("Locations") && <div className='label-statement' style={{paddingBottom:"10px", marginTop:0,}}>
            Auditors can Add Location
          </div>}
          </h1>
                               

          {locationModalPage == 0 ? (
         <div className="form-group-container task-form" style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", flexDirection:"row", overflowY:"auto"}}> 
         {locationModal.includes("Locations") && <div style={{display:"flex", alignItems:"center", flexDirection:"column", justifyContent:"center", height:"100%"}}> 
          <div className='label-statement' style={{padding:"0 10px", margin:0}}>
          The following are typical locations that should be considered for testing:<br/>
          - Locations that are representative of both high and low traffic conditions.<br/>
          - Locations that evaluate varying material sizes or textures.<br/>
          - Locations commonly exposed to contaminants or other risks.<br/>
          </div>                
          <div className='label-statement' style={{padding:"0 10px"}}>
          For each test location, choose a minimum of three test samples. Each sample must be of size
          sufficient to accommodate the test travel distance of the BOT
          </div> 
          </div>}
          <div>
            <div className="form-row" style={{alignItems:"center"}}>
            <div className="form-collection relative" style={{width:"fit-content", minWidth:"300px", minHeight:"300px"}}>
            {locationModal.includes("Locations") && <div className="form-label" style={{fontWeight:"bold", display:"flex", justifyContent:"center", alignItems:"center"}}>Place Locations within that coordinates to Area</div>}
              <Planner setLoadingCanvas={setLoadingCanvas} loadingCanvas={loadingCanvas}  addLocationToDeleted={addLocationToDeleted} setLoadingShape={setLoadingShape} photo={photo} shapeToDelete={shapeToDelete} selectShapeToDelete={selectShapeToDelete} locationId={locationModal.includes("Locations") ? null : locationModal} setNewDiagramImage={setNewDiagramImage} areaLines={areaLines.lines} areaShapes={areaLines.shapes} locationMode={true} />
            </div>
            </div>
            </div>
            </div>
          ) : locationModalPage == 1 ?    
          <div className='form-row'>        
            <div className="form-collection">
              <div className="form-label">Testing Condition</div>
              <Dropdown
                  options={["option1", "option2", "option3", "option4"]}
                  onChange={(choice) => { 
                     
                    setLocationCondition(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="Testing condition"
                  value={locationCondition}
                />
            </div>
          </div> : null } 
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>


          { locationModalPage > 0 ? <div
            style={{margin:".5rem"}}
            className="auth-button addUser"
            onClick={() => {              
              if(locationModalPage == 1) {
                setLocationModalPage(locationModalPage - 1)
              }
            }}
          >
            Back
          </div> : <div></div> }

            {locationModal.includes("Locations") ? <div
              style={{margin:".5rem"}}
              className={!LoadingShape ? "auth-button addUser" : "auth-button addUser disable-button"}
              onClick={!LoadingShape ? locationModalPage !== 0 ? () => setLocationModalPage(locationModalPage + 1) : locationModal.includes("Edit") ? null : () => {
                setLoadingCanvas(true)
                
                setTimeout(() => {

                html2canvas(document.getElementById("stageImage"), {useCORS:true,}).then(canvas => {
                  let dataURL = canvas.toDataURL()
                  setNewDiagramImage && setNewDiagramImage(dataURL)
                  setLoadingCanvas(false)   
                  addLocationFunc(dataURL)
                }, 1)
              
              })} : null }
            >

              { !LoadingShape ? "Save" : <DataSpinner small="s"/>}

            </div> : (
              <div  
              className={`auth-button addUser confirmDelete deleteButton`}
              onClick={() => {
                //dispatch(ACTION_LOADING_SPINNER_ACTIVE())
                let newShapes = {...shapes}
                delete newShapes[shapeToDelete]
                setState((state) => { 
                  state.shapes = newShapes
                })
                setDeleteLocationModal(locationModal)
                
            }}
        > 
    
          Delete
    
        </div>
            )}

          </div>
        </div>)}
      </Modal>
  )}

  export default AdLocationModal
       