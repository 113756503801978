import { gql } from "apollo-boost";


gql`
  type ClientType {
    _id: ID
    companyName: String
  }
`;
gql`
  type UserType {
    _id: ID
    name: String
    adminType: String 
  }
`;
gql`
  type SiteType {
    _id: ID
    name: String
  }
`;

gql`
  type TileResultType {
    Exist: Boolean
  }
`;

gql`
  input ClientInput {
    client: ClientType
  }
`;

gql`
input UserInput {
  user: UserType
}
`;

gql`
  input SiteInput {
    site: SiteType
  }
`;

gql`
  input TileInput {
    tileResult: TileResultType
  }
`;

const addProjectMutation = gql`
  mutation(
    $details: String,
    $site: [SiteInput],
    $client: [ClientInput],
    $status: Boolean,
    $dateOfAudit: String,
    $dateOfTestingDiagram: String,
    $creatorOfTestingDiagram: ID,
    $auditTrailOfTestingDiagram: String,
    $auditTrailOnChanges: String,
    $creator: [UserInput],
    $assignee: [UserInput],
    $tileResult: TileInput,
  ) {
    addProject(
    details:$details
    site:$site
    client:$client
    status:$status
    dateOfAudit:$dateOfAudit
    dateOfTestingDiagram:$dateOfTestingDiagram
    creatorOfTestingDiagram:$creatorOfTestingDiagram
    auditTrailOfTestingDiagram:$auditTrailOfTestingDiagram
    auditTrailOnChanges:$auditTrailOnChanges
    creator:$creator
    assignee:$assignee
    tileResult: $tileResult
    ) {
    _id
    details
    site {
      _id
      name
    }
    client {
      _id
      companyName
    }
    status
    dateOfAudit
    dateOfTestingDiagram
    creatorOfTestingDiagram
    auditTrailOfTestingDiagram
    auditTrailOnChanges
    creator {
      _id
      name
      adminType
    }
    assignee {
      _id
      name
    }
    tileResult {
      first
      second
      exist
      result
    }
  }
  }
`;

export default addProjectMutation;
