import { faArrowLeft, faBars, faChevronDown, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./header.scss"
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import LZString from "lz-string"
import ACTION_BACK_ROUTE_RESET from "../../actions/route/ACTION_BACK_ROUTE_RESET";
import blank from "../../assests/images/blank.png"
import bell from "../../assests/icons/bell.svg"
import ACTION_BACK_ROUTE from "../../actions/route/ACTION_BACK_ROUTE";
import LogoutModal from "../resuable/modals/logoutModal";
const Header = (props) => {
    const {
        setActiveView,
        activeView,
        messages,
        user,
        selectedUser,
        setSelectedUser,
        handleLogout,
        setLogoutMenu,
        logoutModal,
        setLogoutModal,
        notificationMenu,
        setNotificationMenu,
        projectsRefetch,
        reportsRefetch
    } = props

    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
     

    const backRoute = useSelector((state) => state.backRoute.backRoute);
    const routeName = useSelector((state) => state.backRoute.routeName);
    const subUser = useSelector((state) => state.backRoute.subUser);
 

    const allReports = () => {
        setActiveView(null)
        reportsRefetch({
            view: null
        })
    }
    return (
        <div className="header">
            <LogoutModal  
                open={logoutModal}
                setOpen={setLogoutModal}
                handleLogout={handleLogout}
            />
            <div className="headerTitle">
                {
                    backRoute && routeName ? (<div style={{cursor:"pointer", display:"flex", alignItems:"center"}} onClick= { () => {
                        if(subUser) {
                            setSelectedUser(subUser)
                            dispatch(ACTION_BACK_ROUTE({backRoute: backRoute, routeName: routeName, subUser: null}))
                        } else {
                            dispatch(ACTION_BACK_ROUTE_RESET())
                            history.push(`/admin/${backRoute}`)
                        }
                        
                    }}> 
                    <FontAwesomeIcon 
                        icon={faArrowLeft}
                        style={{marginRight: "1rem", fontSize:"1.8rem"}}
                    />
                    <span>{routeName}</span>
                    </div> ) : location ? <div style={{cursor:"pointer", display:"flex", alignItems:"center"}} onClick= { () => {
                      activeView ? allReports() : location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1)?.toLowerCase() == "download" ?  history.push(`/admin/reports`) : null
                    }}> 
                    {activeView || location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1)?.toLowerCase() == "download" ? <FontAwesomeIcon 
                        icon={faArrowLeft}
                        style={{marginRight: "1rem", fontSize:"1.8rem"}}
                    /> : null}
                    <span>{activeView ? "All Reports" :location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1)?.toLowerCase() == "download" ? "Back" : location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1)?.toLowerCase() }</span>
                    </div> 
                    : null
                }
            </div>
            <div className="headerUser">
                {/* <div className="headerNotification">
                    <img 
                    src={bell}
                    />
                </div> */}
                <div className="headerUserMenu">    
                <div className="userArrow" style={{margin:"0 .5rem",  cursor:"pointer"}}>
                    <div>
                 {messages?.some(x => x.new ) ? <div className="newupdate"></div> : null}

                        <FontAwesomeIcon
                        icon={faBellRegular}
                        style={{color:"#5C92BF",fontSize:"30px", margin:"1px 10px"}}
                        onClick={() => setNotificationMenu(true)}
                    /> 
                    </div>
                            {/* <FontAwesomeIcon
                            onClick={() => {
                                setLogoutModal(true)                                
                            }}
                            icon={faPowerOff}
                            style={{color:"#EF1111"}}
                            /> */}
                    </div>                
                    <div className="userName headerTitle">
                        {user && user?.user?.name}
                    </div>
                    <div className="userArrow" style={{margin:"0 .5rem",  cursor:"pointer"}}>
                    <FontAwesomeIcon
                        className="activeSideBarX"
                        icon={faBars}
                        style={{color:"#5C92BF"}}
                        onClick={() => setLogoutMenu(true)}
                    />
                            {/* <FontAwesomeIcon
                            onClick={() => {
                                setLogoutModal(true)                                
                            }}
                            icon={faPowerOff}
                            style={{color:"#EF1111"}}
                            /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header