import { gql } from "apollo-boost";

const deleteViewMutation = gql`
  mutation($_id: ID) {
    deleteView(_id: $_id) {
      _id
    }
  }
`;

export default deleteViewMutation;
 