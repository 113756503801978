import { gql } from "apollo-boost";

const updateClientMutation = gql`
  mutation(
    $_id: ID
    $companyName: String,
    $companyAddress: String,
    $contactPersonName: String,
    $contactPersonEmail: String,
    $contactPersonPhoneNumber: String,
    $contactPersonWorkPhoneNumber: String,
    $status: Boolean,
    $image: String,
    $zip: String,

  ) {
    updateClient(
    _id:$_id
    companyName:$companyName,
    image:$image
    companyAddress:$companyAddress,
    contactPersonName:$contactPersonName,
    contactPersonEmail:$contactPersonEmail,
    contactPersonPhoneNumber:$contactPersonPhoneNumber,
    contactPersonWorkPhoneNumber:$contactPersonWorkPhoneNumber,
    status:$status,
    zip: $zip

    ) {
    _id
    image
    companyName,
    companyAddress,
    contactPersonName,
    contactPersonEmail,
    contactPersonPhoneNumber,
    contactPersonWorkPhoneNumber,
    status,
        zip

    }
  }
`;

export default updateClientMutation;
