import { gql } from "apollo-boost";

const updateReportMutation = gql`
  mutation(
    $_id: ID
    $dateOfAudit: String,
    $dateOfReportGeneration: String,
    $dateOfApproval: String,
    $type: String,
    $updatedBy: String,
    $expDate: String,
    $status: String,
    $note: String,
    $reportTemplate: String
    $html: String
    $css: String
    $newUpdate: Boolean
    $archive: Boolean
  ) {
    updateReport(
    _id:$_id
     dateOfAudit:$dateOfAudit
    dateOfReportGeneration:$dateOfReportGeneration
    dateOfApproval:$dateOfApproval
    type:$type
    updatedBy:$updatedBy
    expDate:$expDate
    status:$status
    note:$note  
    reportTemplate:$reportTemplate
    html:$html
    css:$css
    newUpdate:$newUpdate
    archive:$archive
    ) {
    _id
    dateOfAudit
    dateOfReportGeneration
    dateOfApproval
    type
    updatedBy
    expDate
    archive
    notification {
        _id
        new
        message
        users
        createdAt
      }
    status
    note
    reportTemplate
    css
    newUpdate
    }
  }
`;

export default updateReportMutation;
