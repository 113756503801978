import { gql } from "apollo-boost";

const updateSiteMutation = gql`
  mutation(
    $_id: ID
    $name:String,
    $address:String,
    $client:ID,
    $contactPersonName: String,
    $contactPersonEmail: String,
    $contactPersonPhoneNumber: String,
    $status:Boolean,
    $notes:String,
        $zip: String,

  ) {
    updateSite(
    _id: $_id
    name:$name
    address:$address
    client:$client
    contactPersonName:$contactPersonName,
    contactPersonEmail:$contactPersonEmail,
    contactPersonPhoneNumber: $contactPersonPhoneNumber
    status:$status
    notes:$notes    
    zip: $zip

    ) {
    _id
    name
    address
    client
    contactPersonName,
    contactPersonEmail,
    contactPersonPhoneNumber
    status
    notes    
    zip

  }
  }
`;

export default updateSiteMutation;
