import { ApolloClient, InMemoryCache, split, HttpLink } from "@apollo/client";


const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri:  process.env.REACT_APP_ENV === "production"
  ? `/graphql`
  : "/graphql",
  credentials: "include",
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => console.log(message));
    }
  },
});

export default apolloClient; 
