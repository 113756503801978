import React from "react"
import infoI from "../../../assests/icons/info.svg"
import "./index.scss"
import ReactTooltip from 'react-tooltip';
import { UncontrolledTooltip } from "reactstrap";

const InfoItem = ({info}) => {
    return (
        <><a data-for="main" data-tip={` `} className="infoContainer"><img id="UncontrolledTooltipExample_10" width={15} style={{cursor:"pointer",
                color:"#5C92BF", margin:"0 10px"}} src={infoI} /><UncontrolledTooltip
    placement="top"
    target="UncontrolledTooltipExample_10"
  >
    Need Help!
  </UncontrolledTooltip></a>
                </>
    )
}

export default InfoItem