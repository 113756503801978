const SELECTED_AUDIT = "SELECTED_AUDIT";

const ACTION_SELECTED_AUDIT = (audit) => {
  return {
    type: SELECTED_AUDIT,
    audit
  };
};

export default ACTION_SELECTED_AUDIT;
