import React from 'react'
import Modal from "react-modal"

import { faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DoneModal = (props) => {
    const {
        open,
        setOpen
        } = props


  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal">
              <FontAwesomeIcon
                className="modal_x" 
                icon={faTimes}
                onClick={() => {
                  setOpen(null)
                }}
              />
          
          <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
            <FontAwesomeIcon
            icon={faCheckCircle}
            style={{fontSize:"150px"}}
            color={"#6DB240"}
            />
                <h1 style={{fontSize:"24px", marginTop:"30px", textAlign:"center", fontWeight:"bold", color:"#5C92BF"}}>{open}</h1>
          </div>
        </div>
      </Modal>
  )}

  export default DoneModal
       