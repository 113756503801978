import React, { useEffect, useRef, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from "react-dropdown";
import AvatarModal from '../addAccountModal/avatarModal';
import { DataSpinner } from '../../spinner/dataSpinner';
import Select from 'react-select'

const AddClientModal = (props) => {  
  const [progress , setProgress] = useState(0);

    const {
        setNewClientDiscuss,
        images,
        setImages,
        clientModal,
        setClientModal,
        setCompanyName,
        companyName,
        setCompanyEmail,
        companyEmail,
        inviteUser,
        emailAlreadyRegistered,
        invalidEmail,
        setEmailAlreadyRegistered,
        setCompanyPhoneNumber,
        companyPhoneNumber,
        setCompanyWorkPhoneNumber,
        companyWorkPhoneNumber,
        setCompanyAddress,
        companyAddress,
        city,
        setCity,
        country,
        setCountry,
        addClientToReport,
        state,
        setZip,
        zip,
        setState,
        setContactPersonName,
        contactPersonName,
        // AvatarModal props
        takeAPhotoSelected,
        changeTakeAPhotoSelected,
        webcamURI,
        changeWebcamURI,
        imageUploaded,
        changeImageUploaded,
        imagePreviewAvailable,
        changeImagePreviewAvailable,
        handleDeletedPreviewImage,
        handleImageUploaded,
        addProfilePhotoClicked,
        setAddProfileClicked,
        updateClientFunc,
        contactPersonFirstName,
        contactPersonLastName,
        setContactPersonFirstName,
        setContactPersonLastName,
        setNewClient,
        addClientLoading,
        addClientData
    } = props




const [stateTyping, setStateTyping] = useState("")
const [countryTyping, setCountryTyping] = useState("")
const [temporaryState, setTemporaryState] = useState("")
const formatNumber = (num) => {
  let newNum = num.split("")?.filter((x,i) => i < 10)
  return newNum.length == 1 ? `(${newNum[0]}` : newNum.length == 2 ? `(${newNum[0]}${newNum[1]}` : newNum.length == 3 ? `(${newNum[0]}${newNum[1]}${newNum[2]}` : newNum.length == 4 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}` : newNum.length == 5 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}` : newNum.length == 6 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]}` : newNum.length == 7 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}` : newNum.length == 8 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}${newNum[7]}` : newNum.length == 9 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}${newNum[7]}${newNum[8]}` : newNum.length == 10 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}${newNum[7]}${newNum[8]}${newNum[9]}` : ""

}
useEffect(() => {
if(state) {
 setStateTyping(state)
} else {
setStateTyping("")
}
},[state]) 

useEffect(() => {
if(country) {
if(country !== "United States" && country !== "Canada" && country !== "Mexico") {
setTemporaryState("Other")
}
 setCountryTyping(country)
} else {
setCountryTyping("")
}
},[country]) 


   

  const phoneNumberTyping = (e) => {
    let currentTyping = e.currentTarget.value;

    let string = currentTyping.replace(/\D/g, "")
    currentTyping = formatNumber(string)
    setCompanyPhoneNumber(currentTyping)
  };
  const workPhoneNumberTyping = (e) => {
    let currentTyping = e.currentTarget.value; 
    let string = currentTyping.replace(/\D/g, "")
    currentTyping = formatNumber(string)
    setCompanyWorkPhoneNumber(currentTyping)
  };

 
    const [zipError, setZipError] = useState(true)

    const isUSAZipCode = (str) => {
      return /^\d{5}(-\d{4})?$/.test(str);
    }

    const isCanadaZipCode = (str) => {
    return  /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/.test(str);
    }
    
    const validateInput = (e) => {
      if(countryTyping !== "Canada") {
        if (isUSAZipCode(e.target.value)) {
        setZipError(false)
      } else {
       setZipError(true)
      }  
      } else {
        if (isCanadaZipCode(e.target.value)) {
          setZipError(false)
        } else {
         setZipError(true)
        } 
      }
      
    }
    

useEffect(() => {
  if(clientModal && clientModal.includes("Edit")) {
    setZipError(false)
  }
},[clientModal])

const [addWorkPhone, setAddWorkPhone] = useState(false)

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    position:"aboslute",
    top:-110
  }),

  control: (provided, { selectProps: { width }}) => ({
    ...provided,
    margin:"10px 0"
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

  const lastNameRef = useRef()
  const firstNameRef = useRef()
  const countryRef = useRef()
  const otherCountryRef = useRef()
  const stateRef = useRef()
  const stateMRef = useRef()
  const cityRef = useRef()
  const addressRef = useRef()
  const zipRef = useRef()

  return(
<Modal
        isOpen={clientModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { clientModal && <div className="modal task-modal" style={{height: "fit-content", width:"50vw"}}>
               

          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>{clientModal.includes("Edit") ? clientModal : clientModal.includes("Addressed") ? `Addressed to` : `Add Client`}</h1>
          <div className="form-group-container task-form" style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}}>
          {!clientModal.includes("Addressed") && <div className="form-row"> 
            <AvatarModal 
            progress={progress}
              setProgress={setProgress}
            images={images}
            setImages={setImages}
            />
            </div>}
            {!clientModal.includes("Addressed") && <div className="form-row">
              <div className="form-collection">
                <div className="form-label"><span>Company Name<span style={{color:"red"}}> *</span></span></div>
                <input
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  type="text"
                  className="form-input"
                  placeholder='Client Company Name'
                />
              </div>
            </div>}
            <div className='form-row'>
            <div className="form-collection">
              <div className="form-label"><span>First Name<span style={{color:"red"}}> *</span></span></div>
              <input
              ref={firstNameRef}
                name='firstNameClient'
                onKeyDown={(e) => e.key == "Tab" ? lastNameRef.current.focus() : null}
                onChange={(e) => setContactPersonFirstName(e.target.value)}
                value={contactPersonFirstName}
                type="text"
                className="form-input"
                placeholder='Contact Person First Name'
              />
            </div>
            </div>
            <div className='form-row'>
            <div className="form-collection">
              <div className="form-label"><span>Last Name<span style={{color:"red"}}> *</span></span></div>
              <input
                ref={lastNameRef}
                onKeyDown={(e) => e.key == "Tab" && clientModal.includes("Addressed") ? countryRef.current.focus() : null}
                name='lastNameClient'
                onChange={(e) => setContactPersonLastName(e.target.value)}
                value={contactPersonLastName}
                type="text"
                className="form-input"
                placeholder='Contact Person Last Name'
              />
            </div>
            </div>
            {!clientModal.includes("Addressed") && <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Email<span style={{color:"red"}}> *</span></span></div>
              <input
                placeholder='Client Contact Email'
                onChange={(e) => {
                  setCompanyEmail(e.target.value.toLowerCase())
                  setEmailAlreadyRegistered(false)

                }}
                value={companyEmail}
                type="text"
                className="form-input"
              />
              {emailAlreadyRegistered ? (
        <div className="auth-error" style={{textTransform:"none"}}>
          This email has already been used.
        </div>
      ) : invalidEmail ? (
        <div className="auth-error" style={{textTransform:"none"}}>
          Please enter a valid email address.
        </div>
      ) : null }
            </div>
            </div>}
            {!clientModal.includes("Addressed") && <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Mobile Phone Number<span style={{color:"red"}}> *</span></span> {!addWorkPhone && <span style={{color: !addWorkPhone ? "#6DB240" : "#EF1111" , cursor:"pointer"}} onClick={() => setAddWorkPhone(!addWorkPhone)}> {!addWorkPhone ? "Add" : "Remove"} Work Phone Number</span>}</div>
              <input
              placeholder='(XXX) XXX - XXXX'
                onBlur={(e) => setCompanyPhoneNumber(e.target.value)}
                onChange={phoneNumberTyping}
                value={companyPhoneNumber}
                maxLength="16"
                type="text"
                className="form-input"
              />
            </div>
            </div>}
            {addWorkPhone && <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><div>Work Phone Number</div><span style={{color: !addWorkPhone ? "#6DB240" : "#EF1111" , cursor:"pointer"}} onClick={() => setAddWorkPhone(!addWorkPhone)}> {!addWorkPhone ? "Add" : "Remove"}</span></div>
              <input
              onKeyDown={(e) => e.key == "Tab" && clientModal.includes("Addressed") ? countryRef.current.focus() : null}
              placeholder='(XXX) XXX - XXXX'
                onBlur={(e) => setCompanyWorkPhoneNumber(e.target.value)}
                onChange={workPhoneNumberTyping}
                value={companyWorkPhoneNumber}
                maxLength="16"
                type="text"
                className="form-input"
              />
            </div>
            </div>}

            <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Country<span style={{color:"red"}}> *</span></span></div>
              <Select 
              ref={countryRef}
              onKeyDown={(e) => e.key == "Tab" && clientModal.includes("Addressed") ? temporaryState ? otherCountryRef.current.focus() : !country ? cityRef.current.focus() : stateRef.current.focus() : null}
              options={[
                {label:"United States", value:"United States"},
                {label:"Canada", value:"Canada"},
                {label:"Mexico", value:"Mexico"},
                {label:"Other", value:"Other"},

              ]}
              isSearchable={true}
              styles={customStyles}

              value={temporaryState ? {label: temporaryState , valye: temporaryState} : country ? {label: country, value: country} : ""}
              onChange={(choice) =>{
                setZip && setZip("")
                setZipError(false)
                if(choice.value == "Other") {
                  setCountry("")
                  setCountryTyping("")
                  setTemporaryState("Other")
                  setStateTyping("")
                setState("")
               } else {
                setTemporaryState("")
                setStateTyping("")
                setState("")
                setCountry(choice.value)
               }
              }
              }
              placeholder={"Client Contact Country"}
              />  
          </div>
            
        </div>  

        {temporaryState && <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Other Country<span style={{color:"red"}}> *</span></span></div>
              <input
              ref={otherCountryRef}
              onKeyDown={(e) => e.key == "Tab" && clientModal.includes("Addressed") ? stateMRef.current.focus() : null}
              placeholder='Client Contact Country'
              onChange={(e) => {
                setCountryTyping(e.target.value)}}
              onBlur={() => setCountry(countryTyping)}
              value={countryTyping}
              type="text"
              className="form-input"
            />  
          </div>
            
        </div> }

        <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>State<span style={{color:"red"}}> *</span></span></div>
              {!temporaryState ? <Select
                ref={stateRef}
                onKeyDown={(e) => e.key == "Tab" && clientModal.includes("Addressed") ? cityRef.current.focus() : null}
                isDisabled={!country}
                options={country == "United States" ? [
                {label: "Alabama" , value: "Alabama"},
                {label: "Alaska" , value: "Alaska"},
                {label: "Arizona" , value: "Arizona"},
                {label: "Arkansas" , value: "Arkansas"},
                {label: "California" , value: "California"},
                {label: "Colorado" , value: "Colorado"},
                {label: "Connecticut" , value: "Connecticut"},
                {label: "Delaware" , value: "Delaware"},
                {label: "District of Columbia", value: "District of Columbia"},
                {label: "Florida" , value: "Florida"},
                {label: "Georgia" , value: "Georgia"},
                {label: "Hawaii" , value: "Hawaii"},
                {label: "Idaho" , value: "Idaho"},
                {label: "Illinois" , value: "Illinois"},
                {label: "Indiana" , value: "Indiana"},
                {label: "Iowa" , value: "Iowa"},
                {label: "Kansas" , value: "Kansas"},
                {label: "Kentucky" , value: "Kentucky"},
                {label: "Louisiana" , value: "Louisiana"},
                {label: "Maine" , value: "Maine"},
                {label: "Maryland" , value: "Maryland"},
                {label: "Massachusetts" , value: "Massachusetts"},
                {label: "Michigan" , value: "Michigan"},
                {label: "Minnesota" , value: "Minnesota"},
                {label: "Mississippi" , value: "Mississippi"},
                {label: "Missouri" , value: "Missouri"},
                {label: "Montana" , value: "Montana"},
                {label: "Nebraska" , value: "Nebraska"},
                {label: "Nevada" , value: "Nevada"},
                {label: "New Hampshire" , value: "New Hampshire"},
                {label: "New Jersey" , value: "New Jersey"},
                {label: "New Mexico" , value: "New Mexico"},
                {label: "New York" , value: "New York"},
                {label: "North Carolina" , value: "North Carolina"},
                {label: "North Dakota" , value: "North Dakota"},
                {label: "Ohio" , value: "Ohio"},
                {label: "Oklahoma" , value: "Oklahoma"},
                {label: "Oregon" , value: "Oregon"},
                {label: "Pennsylvania" , value: "Pennsylvania"},
                {label: "Rhode Island" , value: "Rhode Island"},
                {label: "South Carolina" , value: "South Carolina"},
                {label: "South Dakota" , value: "South Dakota"},
                {label: "Tennessee" , value: "Tennessee"},
                {label: "Texas" , value: "Texas"},
                {label: "Utah" , value: "Utah"},
                {label: "Vermont" , value: "Vermont"},
                {label: "Virginia" , value: "Virginia"},
                {label: "Washington" , value: "Washington"},
                {label: "West Virginia" , value: "West Virginia"},
                {label: "Wisconsin" , value: "Wisconsin"},
                {label: "Wyoming" , value: "Wyoming"},
              ].sort((a, b) => a.label.localeCompare(b.label)) : country == "Mexico" ? [
                {label : "Aguascalientes", value: "Aguascalientes"},
                {label : "Baja California", value: "Baja California"},
                {label : "Baja California Sur", value: "Baja California Sur"},
                {label : "Campeche", value: "Campeche"},
                {label : "Coahuila", value: "Coahuila"},
                {label : "Colima", value: "Colima"},
                {label : "Chiapas", value: "Chiapas"},
                {label : "Chihuahua", value: "Chihuahua"},
                {label : "Durango", value: "Durango"},
                {label : "Mexico City", value: "Mexico City"},
                {label : "Guanajuato", value: "Guanajuato"},
                {label : "Guerrero", value: "Guerrero"},
                {label : "Hidalgo", value: "Hidalgo"},
                {label : "Jalisco", value: "Jalisco"},
                {label : "Michoacan", value: "Michoacan"},
                {label : "Morelos", value: "Morelos"},
                {label : "Nayarit", value: "Nayarit"},
                {label : "Nuevo León", value: "Nuevo León"},
                {label : "Oaxaca", value: "Oaxaca"},
                {label : "Puebla", value: "Puebla"},
                {label : "Queretaro", value: "Queretaro"},
                {label : "Quintana Roo", value: "Quintana Roo"},
                {label : "San Luis Potosi", value: "San Luis Potosi"},
                {label : "Sinaloa", value: "Sinaloa"},
                {label : "Sonora", value: "Sonora"},
                {label : "Tabasco", value: "Tabasco"},
                {label : "Tamaulipas", value: "Tamaulipas"},
                {label : "Tlaxcala", value: "Tlaxcala"},
                {label : "Veracruz", value: "Veracruz"},
                {label : "Yucatán", value: "Yucatán"},
                {label : "Zacatecas", value: "Zacatecas"},
            ].sort((a, b) => a.label.localeCompare(b.label)) : country == "Canada" ? [
              {label: "Alberta" , value: "Alberta"},
              {label: "British Columbia" , value: "British Columbia"},
              {label: "Manitoba" , value: "Manitoba"},
              {label: "New Brunswick" , value: "New Brunswick"},
              {label: "Newfoundland and Labrador" , value: "Newfoundland and Labrador"},
              {label: "Northwest Territories" , value: "Northwest Territories"},
              {label: "Nova Scotia" , value: "Nova Scotia"},
              {label: "Nunavut" , value: "Nunavut"},
              {label: "Ontario" , value: "Ontario"},
              {label: "Prince Edward Island" , value: "Prince Edward Island"},
              {label: "Quebec" , value: "Quebec"},
              {label: "Saskatchewan" , value: "Saskatchewan"},
              {label: "Yukon" , value: "Yukon"},
          ].sort((a, b) => a.label.localeCompare(b.label)) : []}
              isSearchable={true}
              styles={customStyles}
              value={state ? {label: state, value: state} : ""}
              onChange={(choice) =>{
              setState(choice.value)
              }
              }
              placeholder={"Client Contact State"}
              />  :  <input
              ref={stateMRef}
              onKeyDown={(e) => e.key == "Tab" && clientModal.includes("Addressed") ? cityRef.current.focus() : null}
              placeholder='Client Contact State'
              onChange={(e) => setStateTyping(e.target.value)}
              onBlur={() => setState(stateTyping)}
              value={stateTyping}
              type="text"
              className="form-input"
            /> }
          </div>
            
        </div> 
        <div className="form-row">
          <div className="form-collection">
              <div className="form-label"><span>City<span style={{color:"red"}}> *</span></span></div>
              <input
              ref={cityRef}
              onKeyDown={(e) => e.key == "Tab" && clientModal.includes("Addressed") ? addressRef.current.focus() : null}
                placeholder='Client Contact City'
                onChange={(e) => setCity(e.target.value)}
                value={city}
                type="text"
                className="form-input"
              />
            </div>
            </div>

            <div className="form-row">
            <div className="form-collection">
            <div className="form-label"><span>Address<span style={{color:"red"}}> *</span></span></div>
              <input
                ref={addressRef}
                placeholder='Client Contact Address'
                onKeyDown={(e) => {
                  if(e.key == "Tab" && clientModal.includes("Addressed")) {
                    zipRef.current.focus()
                  } else if(e.keyCode !== 188) {
                  return e.keyCode
                  } else {
                    e && e.preventDefault()
                  }
              }}
              onChange={(e) => setCompanyAddress(e.target.value)}
              value={companyAddress}
              type="text"
              className="form-input"
              />
          </div>
          </div>

              <div className="form-row">
              <div className="form-collection">
               <div className="form-label"><span>Zip code<span style={{color:"red"}}> *</span></span>
               {zipError && zip?.length ? (
                  <div className="auth-error" style={{textTransform:"none"}}>
                    Pleae enter a valid ZIP code
                  </div>
                ) : null }
               </div>
              <input
                ref={zipRef}
              onKeyDown={(e) => e.key == "Tab" && clientModal.includes("Addressed") ? firstNameRef.current.focus() : null}
                name="zipCodeClient"
                placeholder='Client Zip Code'
                onKeyUp={(e) => validateInput(e)}
                onChange={(e) => setZip(e.target.value)}
                value={zip}
                type= { country == "Canada" ? "text" : "number" }
                maxLength={ country == "Canada" ? "7" : "6" }
                minLength={ country == "Canada" ? "7" : "6" }
                className="form-input"
              />
          </div>
          </div>

          <div className="form-row">
              <div className="form-collection">
              <input
                style={{opacity:"0", cursor:"default"}}
                className="form-input"
              />
          </div>
          </div>
            
          </div>
          <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {
                     
                    setImages([])
                    setClientModal(null)
                    setCompanyName("")
                    setCompanyEmail("")
                    setEmailAlreadyRegistered("")
                    setCompanyPhoneNumber("")
                    setCompanyWorkPhoneNumber("")
                    setCompanyAddress("")
                    setCity("")
                    setState("")
                    setContactPersonName("")
                    setContactPersonFirstName("")
                    setContactPersonLastName("")
                    setZip && setZip("")
                    setZipError(true)
                    setNewClient && setNewClient(null)
                    setNewClientDiscuss && setNewClientDiscuss(null)
                    setTemporaryState("")

                }}
            > 

            Cancel

            </div>
            <div
            style={{margin:"0.5rem"}}
              className={`${progress && progress !== 100 ? "auth-button addUser disable-button": clientModal.includes("Addressed") ? zip ? !zipError ? "auth-button addUser" : "auth-button addUser disable-button" : "auth-button addUser" : companyName && companyEmail && !zipError && !emailAlreadyRegistered && companyPhoneNumber && companyAddress && city && state ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={progress && progress !== 100 ? null :clientModal.includes("Addressed")? zip ? !zipError ? () => {
                setTemporaryState("")
                addClientToReport()} : null : () => {
                setTemporaryState("")
                addClientToReport()} : companyName && companyEmail && !zipError && !emailAlreadyRegistered && companyPhoneNumber && companyAddress && city && state ?  clientModal.includes("Edit") ? () => {
                  setTemporaryState("")
                  updateClientFunc() } : () => {
                    setTemporaryState("")
                    inviteUser(clientModal.includes("discuss") ? true : false)} : null }
            >
              
              {progress && progress !== 100 ? <DataSpinner small="s" /> :clientModal.includes("Edit") ? "Update" : clientModal.includes("Addressed") ? "Save" : "Add"}

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default AddClientModal
       