import React, { useEffect } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from "react-dropdown";
import { DataSpinner } from '../../spinner/dataSpinner';
import InfoItem from '../../info';
    import Multiselect from 'multiselect-react-dropdown';
    import infoI from "../../../../assests/icons/info.svg"
    import ReactTooltip from 'react-tooltip';
    import { useDispatch } from 'react-redux';
import ACTION_INFO_ACTIVE from '../../../../actions/InfoActive/ACTION_INFO_ACTIVE';
import { useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
const AddAuditModal = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
    const {
        addAuditModal,
        setAddAuditModal,
        auditClientsOptions,
        auditClient,
        setAuditClient,
        auditSitesOptions,
        auditSite,
        setAuditSite,
        auditAssignOptions,
        auditAssignTo,
        setAuditAssignTo,
        addAuditFunc,
        updateAuditFunc
    } = props

    const onSelectFunc = (selectedList, selectedItem) => {

      setAuditAssignTo(selectedList)
         }
         
         const onRemoveFunc = (selectedList, removedItem) => {

          setAuditAssignTo(selectedList)
         }

      useEffect(() => {
        if(auditClient?.value == "Add New Client") {
          history.push({
            pathname:"/admin/clients",
            state:{
              addNewClient: true
            }
          })
        }
      },[auditClient]) 
      
      useEffect(() => {
        if(auditSite?.value == "Add New Site") {
          history.push({
            pathname:"/admin/clients",
            state:{
              clientId: auditClient.value,
              addNewSite: true
            }
          })
        }
      },[auditSite]) 
    
  return(
<Modal
        isOpen={addAuditModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }} 
      >  
              {addAuditModal && (
               <div className="modal task-modal" style={{height:"fit-content", width:"40vw", overflow:"visible"}}>
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, height:"33px"}}>{addAuditModal.includes("Edit") ? addAuditModal : addAuditModal.includes("Assign") ? "Assign Audit" : `Add ${addAuditModal}`}</h1>
          <div style={{height:"100%", width:"100%", margin:"50px 0", justifyContent:"center", overflow:"visible"}} className="form-group-container task-form">        
          <div className='label-statement' style={{padding:"0 1rem"}}>
                    Once you have created an audit, you can Assign it to someone in your company. This person will have access to this audit file and will be the person performing the audit.
                    </div> 
                    <>
            {!addAuditModal.includes("Assign") && <div className="form-collection">
              <div className="form-label" style={{display:"flex", alignItems:"center", paddingRight:"0px"}}><span>Client<span style={{color:"red"}}> *</span></span> <div data-for={"main"}><img id="UncontrolledTooltipExample_8" onClick={() => dispatch(ACTION_INFO_ACTIVE(`The client is the company or person who has hired you (the STEP User/Auditor) to perform testing. The report will be addressed to this client unless otherwise specified.`) )} width={15} style={{cursor:"pointer",
         color:"#5C92BF", margin:"0 10px"}} src={infoI} /><UncontrolledTooltip
    placement="top"
    target="UncontrolledTooltipExample_8"
  >
    Need Help!
  </UncontrolledTooltip></div></div>
              <Dropdown
                  options={[...auditClientsOptions.map(client => {
                      client.label = client.companyName
                      client.value = client._id
                      return client
                  }), {
                    label: <div style={{color:"#6DB240"}}>Add New Client</div>,
                    value: "Add New Client"
                  }]}
                  onChange={(choice) =>{
                    setAuditClient(choice)
                  }
                  }
                  controlClassName="form-dropdown" 
                  value={auditClient}
                />

            </div>}
            {auditClient && !addAuditModal.includes("Assign") && <div className="form-collection">
            <div className="form-label" style={{display:"flex", alignItems:"center", paddingRight:"0px"}}><span>Site<span style={{color:"red"}}> *</span></span> <div data-for={"main"} ><img id="UncontrolledTooltipExample_9" onClick={() => dispatch(ACTION_INFO_ACTIVE(<div>The Site is the exact address where the slip testing will take place. This can be an address or name<span style={{fontStyle:"italic"}}>(for example CBS, Dom's Restaurant or 375 Broadway).</span></div>) )} width={15} style={{cursor:"pointer",
         color:"#5C92BF", margin:"0 10px"}} src={infoI} /><UncontrolledTooltip
    placement="top"
    target="UncontrolledTooltipExample_9"
  >
    Need Help!
  </UncontrolledTooltip></div></div>
              <Dropdown
                  options={
                      auditSitesOptions ? (
                        [...auditSitesOptions.map(site => {
                    site.label = site.name
                    site.value = site._id
                    return site
                }), {
                  label: <div style={{color:"#6DB240"}}>Add New Site</div>,
                  value: "Add New Site"
                }]
                      ) : (
                          [{
                            label: "Add New Site",
                            value: "Add New Site"
                          }]
                      )
                  } 
                  onChange={(choice) =>
                    setAuditSite(choice)
                  }
                  controlClassName="form-dropdown" 
                  value={auditSite}
                />

            </div>}
            {addAuditModal.includes("Edit") || addAuditModal.includes("Assign") && <div className="form-collection">
              <div className="form-label">Assign to</div>            
                    <Multiselect
                            options={auditAssignOptions?.map(user => {
                            user.label = user.name
                            user.value = user._id
                            return user

                          })} // Options to display in the dropdown
                            selectedValues={auditAssignTo} // Preselected value to persist in dropdown
                            onSelect={onSelectFunc} // Function will trigger on select event
                            onRemove={onRemoveFunc} // Function will trigger on remove event
                            displayValue="label" // Property name to display in the dropdown options
                    />

            </div>}
            </>
          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {
                  setAddAuditModal(null)
                  setAuditClient("")
                  setAuditSite("")
                  setAuditAssignTo("")
                }}
            >

            Cancel

            </div>            <div
              style={{margin:".5rem"}}
              className={`${ addAuditModal.includes("Assign") ? auditAssignTo?.length ? "auth-button addUser" : "auth-button addUser disable-button" : addAuditModal.includes("Edit") ? auditClient && auditSite ? "auth-button addUser" :"auth-button addUser disable-button" :  auditClient && auditSite ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={addAuditModal.includes("Assign") ? () => auditAssignTo?.length && updateAuditFunc("assign") : addAuditModal.includes("Edit") ? auditClient && auditSite ? updateAuditFunc : null: auditClient && auditSite ? addAuditFunc : null }
            >
              {addAuditModal.includes("Edit") || addAuditModal.includes("Assign") ? "Update" : "Add"}
            </div>
          </div>
        </div>)}
      </Modal>
  )}

  export default AddAuditModal
       