import { gql } from "apollo-boost";

const addAreaMutation = gql`
  mutation(
    $_id: ID
    $testingDiagrams:ID,
    $view:ID,
    $diagramPhoto: String,
    $site:ID,
    $createdBy:String,
    $pass:Boolean,
    $floorType:String,
    $name:String,
    $floorTypeDescription:String,
    $floorTexture:String,
    $floorTextureDescription:String,
    $floorFinish:String,
    $floorFinishDescription:String,
    $conditionofFloorFinish:String,
    $slopeofFloorsSurface:String,
    $cleaningChemicalsUsed:String,
    $slsWaterConcentration:String,
    $dcof:String,
    $type:String,
    $testingDiagram:String,
    $testingCondition:String,
    $testingCategory:String,
    $diagramImage:String,
    $auditorsNotes:String,
    $areaNumber:String,
    $load:Boolean
    $images: [String]
  ) {
    addArea(
    _id: $_id
    load: $load
    testingDiagrams:$testingDiagrams
    diagramImage:$diagramImage
    view:$view
    diagramPhoto: $diagramPhoto
    site:$site
    createdBy:$createdBy
    pass:$pass
    floorType:$floorType
    name:$name
    floorTypeDescription:$floorTypeDescription
    floorTexture:$floorTexture
    floorTextureDescription:$floorTextureDescription
    floorFinish:$floorFinish
    floorFinishDescription:$floorFinishDescription
    conditionofFloorFinish:$conditionofFloorFinish
    slopeofFloorsSurface:$slopeofFloorsSurface
    cleaningChemicalsUsed:$cleaningChemicalsUsed
    slsWaterConcentration:$slsWaterConcentration
    dcof:$dcof
    type:$type
    testingDiagram:$testingDiagram
    testingCondition:$testingCondition
    testingCategory:$testingCategory
    auditorsNotes:$auditorsNotes
    areaNumber:$areaNumber
    images: $images
    ) {
    _id
    load
    diagramPhoto
    testingDiagrams
    createdBy
    site
    testingDiagram
    testingCondition
    testingCategory
    diagramImage
    view
    pass
    floorType
    name
    floorTypeDescription
    floorTexture
    floorTextureDescription
    floorFinish
    floorFinishDescription
    conditionofFloorFinish
    slopeofFloorsSurface
    cleaningChemicalsUsed
    slsWaterConcentration
    dcof
    type
    auditorsNotes
    images
    areaNumber
    }
  }
`;

export default addAreaMutation;
