import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_SELECTED_TESTS from '../../../../actions/SelectedTests/ACTION_SELECTED_TESTS';

const WrongDataModal = (props) => {
    const {
        open,
        setOpen,
        fetchPdf,
        resetActiveTest,
        dataOnFly,
        currentTest,
        setCurrentTest,
        areas,
        selectedAudit
        } = props

        const selectedTests = useSelector((state) => state.selectedTests.selectedTests)

        const dispatch = useDispatch()
  const [areaOnFly, setAreaOnFly] = useState(null)
  const [locationOnFly, setLocaitonOnFly] = useState(null)
 useEffect(() => {
  if(areas && dataOnFly) {
  let botArea = areas.find(x => x.areaNumber == dataOnFly.area)
  if(botArea) {
  setAreaOnFly(botArea)
  let botAreaLocations = botArea?.locationsCoordinates
  let areaLocation = botAreaLocations.find(x => Object.keys(x)[0]?.includes(dataOnFly?.location))
  if(areaLocation) {
    let objectKeyLocation = Object.keys(areaLocation)
    if(objectKeyLocation?.length) {
      setLocaitonOnFly(areaLocation[objectKeyLocation[0]])
    }
  }
  } 
  
  }
 }, [areas, dataOnFly])
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal" style={{height:"fit-content"}}>
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  resetActiveTest()
                }}
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Data Do Not Match</h1>
          <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"16px"}}>
          <span style={{margin:"15px 0"}}>The data for Area and Location <span style={{color:"rgb(77, 76, 76)"}}>{`A${dataOnFly?.area}L${dataOnFly?.location}`}</span> does not matched the data you selecting from the app <span style={{color:"rgb(77, 76, 76)"}}>{`A${currentTest?.areaCode}L${currentTest?.locationCode}`}</span></span>
          <span style={{margin:"15px 0"}}>Press ADD and the data will be placed in <span style={{color:"rgb(77, 76, 76)"}}>{`A${dataOnFly?.area}L${dataOnFly?.location}`}</span> as selected</span>
          <div
                
                className="auth-button addUser confirmDelete"
                style={{margin:0, marginBottom:"10px"}}
                onClick={() => {
                  let newTest = {...currentTest}               
                  newTest.areaCode = dataOnFly.area
                  newTest.locationCode = dataOnFly.location
                  newTest.areaId = areaOnFly?.id
                  newTest.locationId = locationOnFly?.id
                  newTest.replaced = true
                  setCurrentTest(newTest)
                  dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView]: [...selectedTests[selectedAudit?.defaultView].filter(x => x.locationId!==currentTest.locationId) || [], newTest]}))
                  fetchPdf(open)  
                  setOpen(null)
                }} 
                >
                ADD
                </div> 
                
                <span style={{margin:"15px 0"}}>Press CANCEL if you do not want this data (<span style={{color:"rgb(77, 76, 76)"}}>{`A${currentTest?.areaCode}L${currentTest?.locationCode}`}</span>) to be placed in <span style={{color:"rgb(77, 76, 76)"}}>{`A${dataOnFly?.area}L${dataOnFly?.location}`}</span></span>

                <div 
                    className="auth-button addUser confirmDelete deleteButton"
                    style={{margin:0, marginBottom:"10px"}}
                    onClick={() => {
                      resetActiveTest()                   
                    }}
                >
                Cancel

                </div>
          </div>          
        </div>
      </Modal>
  )}

  export default WrongDataModal
       