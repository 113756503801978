import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QuestionnaireModal = (props) => {
    const {
        open,
        setOpen,
        } = props


        // const questions = [
        //   {q: "The Report Will Be Addressed To", ifNo: "Who Should the Report be addressed to ?"},
        //   {q: }
        // ]
        
        const [vars, setVars] = useState(null)
        
        useEffect(() => {
          if(open) {
            setVars(JSON.parse(open))
          }
        },[open])

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"9999999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal task-modal" style={{minHeight:"60vh",maxHeight:"90vh", height:"fit-content", width:"60vw", alignItems:"center", justifyContent:"flex-start"}}>
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setOpen(null)
                }}
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px", display:"flex", justifyContent:"center", alignItems:"center"}}>Step Report Questionnaire</h1>
          <div style={{width:"100%", overflowY:"auto", height:"82%", display:"flex", justifyContent:"center", alignItems:"center"}}><div className="form-group-container task-form" style={{height:"100%", padding:"50px 0", justifyContent:"flex-start", alignItems:"center", width:"50%"}}>
          <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <div className="form-collection">
              <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>The report will be addressed to Original Client</div>
              {vars?.one_yes?.question == false && <div className="form-collection">
              <div className="form-label" style={{justifyContent:"flex-end"}}><span style={{textTransform:"none"}}>{vars?.one_yes?.answer || "N/A"}</span></div>
              </div>} 
              <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
              <div style={{cursor:"pointer"}} className={`${vars?.one_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                    <span className="control">No</span>
              </div>
              <div style={{cursor:"pointer"}} className={`${vars?.one_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                    <span className="control">Yes</span>
              </div>
              </div>
              </div>
              
            </div>
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Auditor performed the Audit and was the only person(s) to operate the BOT-3000E.</div>
                {vars?.two_yes?.question == false && <div className="form-collection">
              <div className="form-label" style={{justifyContent:"flex-end"}}><span style={{textTransform:"none"}}>{vars?.two_yes?.answer || "N/A"}</span></div>
              </div>} 
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} className={`${vars?.two_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} className={`${vars?.two_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
                {/* {two_yes?.question == false && <div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>Who performed this audit?</span></div>
                <input
                  placeholder='Auditor Name'
                  onChange={(e) => setNewAuditor(e.target.value)}
                  value={newAuditor}
                  type="text"
                  className="form-input"
                />
                </div>}  */}
              </div>
           
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Did you verify the BOT-3000E onsite?</div>
                
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} className={`${vars?.three_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} className={`${vars?.three_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
              </div>
            
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Was 0.05% Sodium Laurel Sulfate (SLS) used when testing was performed under “WET”
                conditions?</div>
                {vars?.four_yes?.question == false && <div className="form-collection">
              <div className="form-label" style={{justifyContent:"flex-end"}}><span style={{textTransform:"none"}}>{vars?.four_yes?.answer || "N/A"}</span></div>
              </div>}
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} className={`${vars?.four_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} className={`${vars?.four_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
                {/* {four_yes?.question == false && <div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>explain why!</span></div>
                <textarea
                    onChange={(e) => setExplainWhy(e.target.value)}
                    value={explainWhy}
                    type="text"
                    placeholder="Explain why!"
                    className="form-input textarea-input"
                  />
                </div>}  */}
              </div>
           
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Were the procedures for testing according to ANSI A326.3 Standard-2021 under Testing Conditions used discussed with Client?</div>
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} className={`${vars?.five_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} className={`${vars?.five_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
                {/* {vars?.five_yes?.question == false && <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Did you discuss the procedures for testing according to ANSI A326.3 Standard-2021 under Testing Conditions used with anybody?</div>
                {vars?.five_sub_yes?.question == true && <div className="form-collection">
              <div className="form-label" style={{justifyContent:"flex-end"}}><span style={{textTransform:"none"}}>{vars?.five_sub_yes?.answer || "N/A"}</span></div>
              </div>}
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} className={`${vars?.five_sub_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                      <span className="control">No</span> 
                </div>
                <div style={{cursor:"pointer"}} className={`${vars?.five_sub_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>} */}
                {/* {five_sub_yes?.question == true && <><div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>What is the client name?</span></div>
                <input
                  placeholder='Client Name'
                  onChange={(e) => setNewClientName(e.target.value)}
                  value={newClientName}
                  type="text"
                  className="form-input"
                />
                </div>
                <div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>what is the client company?</span></div>
                <input
                  placeholder='Client Company'
                  onChange={(e) => setNewClientCompany(e.target.value)}
                  value={newClientCompany}
                  type="text"
                  className="form-input"
                />
                </div></>}  */}
              </div>
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Did Auditors categorize areas into the Product Use Category
</div>
                
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}}  className={`${vars?.six_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} className={`${vars?.six_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
              </div>
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
              <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Did Auditors yse the ANSI A326.3 Validation Surface to perform the Testfoot Validation Procedure?
              </div>
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}}  className={`${vars?.seven_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} className={`${vars?.seven_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
              </div>
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Are you aware that some locations do not show any data inputted</div>
                {vars?.eight_yes?.question == true && <div className="form-collection">
              <div className="form-label" style={{justifyContent:"flex-end"}}><span style={{textTransform:"none"}}>{vars?.eight_yes?.answer || "N/A"}</span></div>
              </div>}
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} className={`${vars?.eight_yes?.question == false ? "cursorDisable checkedNo notCheckedNo" : "noHoverButton notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} className={`${vars?.eight_yes?.question == true ? "cursorDisable checkedYes notCheckedYes" : "noHoverButton notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
                {/* {eight_yes?.question == true && <div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>why you skipped it!</span></div>
                <textarea
                    onChange={(e) => setSkippedWhy(e.target.value)}
                    value={skippedWhy}
                    type="text"
                    placeholder="why you skipped it!"
                    className="form-input textarea-input"
                  />
                </div>}  */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
  )}

  export default QuestionnaireModal
       