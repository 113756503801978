import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataSpinner } from '../../spinner/dataSpinner';
import { useHistory } from 'react-router-dom';

const VariablesModal = (props) => {
    const {
        variablesModal,
        setVariablesModal,
        defaultDCOF
        
    } = props

    const history = useHistory()


        const [state, setState] = useState({})
        const [pass, setPass] = useState(false)

      
        const onChange = event => {
          const {
            target: { name, value }
          } = event;
          setState({...state, [name]: value });
        };


        useEffect(() => {
            setState({...state, pass: pass})
        },[pass])


  return(
<Modal
        isOpen={variablesModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
            
        { variablesModal ? <div className="modal task-modal" style={{padding:"3rem .5rem"}}>
               
<FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                setVariablesModal(null)
                }}
              />
          <h1 className="header" style={{width:"100%"}}> Test Variables</h1>
          <div className="form-group-container task-form" style={{ display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
            {variablesModal.filter(x => x.length > 0 && x!=="redLine").map(variable => (
                  <div key={variable} className="form-row" style={{width:"50%"}}>
                  <div className="form-collection" style={{width:"100%"}}>
                    <div className="form-label" style={{paddingRight:"0px"}}>{variable.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")}</div>
                    <input
                      type="text"
                      onChange={onChange}
                      name={variable}
                      placeholder={variable.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")}
                      className="form-input"
                    />
                  </div>
                  </div>
            ))}
            <div className="form-row">
                  <div className="form-collection">
                  <div className="form-label">Pass</div>

                  <label style={{cursor:"pointer"}} onClick={() => {
                  setPass(!pass)
                }}
                 className="toggle-control">
                    <span className={`${pass ? "checked" : "notChecked"}`}></span>
                    <span className="control"></span>
                </label>
                  </div>
            
          </div>
          </div>
          <div>
            <div
              className={`${true ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={() => {
                history.push({
                    pathname: `/admin/reports/template/viewer`,
                    state: {
                    defaultDCOF: defaultDCOF,
                    variables: state,
                    allVariables: variablesModal
                    }
                })
              }}
            >
            Go Ahead
            </div>
          </div>
        </div>: (
                <DataSpinner small="l" />
        )}
      </Modal>
  )}

  export default VariablesModal
       