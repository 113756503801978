import React, { useEffect, useState } from "react"
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import addTemplateMutation from "../../../graphql/mutations/template/addTemplateMutation"
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import AddTemplateModal from "../modals/addTemplateModal";

import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import gsWebpage from 'grapesjs-preset-webpage'
import typed from 'grapesjs-typed';
import table from 'grapesjs-table';
import breakPage from 'grapesjs-page-break';

import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import LZString from "lz-string";
import updateTemplateMutation from "../../../graphql/mutations/template/updateTemplateMutation";
import parse from 'html-react-parser';
import TemplateHistoryModal from "../modals/templateHistoryModal";
import updateReportMutation from "../../../graphql/mutations/report/updateReportMutation";
import { DataSpinner } from "../spinner/dataSpinner";
import AWS from 'aws-sdk'

const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
}) 
 

const ReportTemplate = (props) => {    

  const {
    templates,
    templatesRefetch,
    reportsRefetch,
    user
  } = props
  const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
  }


    let history = useHistory();
    let dispatch = useDispatch()
    let location = useLocation()
    const returnToTemplates = () => {
      if(!location.state) {
        return <Redirect to="/admin/reports/templates" />
      }
    }
    const [
        addTemplate,
        { data: addTemplateData },
      ] = useMutation(addTemplateMutation)
      const [
        updateTemplate,
        { data: updateTemplateData },
      ] = useMutation(updateTemplateMutation)
  
      
    const [
      updateReport,
      { data: updateReportData, loading:updateReportLoading },
    ] = useMutation(updateReportMutation)

       // grapesjs
       const [pluginLoaded, setPluginLoaded] = useState(false);
       const [editor, setEditor] = useState(null);
      
       
    function downloadInnerHtml(filename, elId, mimeType) {
      var elHtml = elId;
      var link = document.createElement('a');
      mimeType = mimeType || 'text/plain';
  
      link.setAttribute('download', filename);
      link.setAttribute('href', 'data:' + mimeType  +  ';charset=utf-8,' + encodeURIComponent(elHtml));
      link.click(); 
      dispatch(ACTION_LOADING_SPINNER_RESET())
  }

  const [loadingContent, setLoadingContent] = useState(true)
  const updateReportFunc = (wrapper) => {
    updateReport({
      variables:{
        _id: location.state.reportId,
        reportTemplate: wrapper
      }
    }).then(() => {
      reportsRefetch()
      history.push("/admin/reports")
    }) 
  }
 
   
       useEffect(() => {
           if (!pluginLoaded) {
              
               setPluginLoaded(true);
           } else if (!editor) {
            setLoadingContent(true)
            const e = grapesjs.init({
                   color:'white',
                   height: '100vh',
                   width: 'auto',
                   container: "#g",
                   plugins: [gsWebpage, typed, table, breakPage],
                   storageManager: { type: null },
                   noticeOnUnload: 0,
                   dragMode:'translate',
                   })

                  e.DomComponents.getTypes().map(type => {

                    e.DomComponents.addType(type.id, {
                
                      model: {
                            defaults: {
                              traits: [
                                {
                                  type: 'text', 
                                  name: 'variable',
                                  label: 'variable',
                                },
                                {
                                  type: 'text',
                                  name: 'condition',
                                  label: 'condition',
                                },
                              ]
                            }
                          }
                
                    })
                  });
                
            if(
              location.state &&
              location.state.html
              ) { 
                if(location.state.default) {
                  location.state.css && e.setStyle(location.state.css)
                  location.state.html && e.setComponents(location.state.html)
                  setLoadingContent(false)
                } else {
                const paramsCss = {
                  Bucket: S3_BUCKET,
                  Key: location.state.css,
                }
                myBucket.getObject(paramsCss)
                .on("complete", (evtCss) => {
                  if(evtCss?.data?.Body) {
                    if(Buffer.from(evtCss?.data?.Body)) {
                      let cssParsed = Buffer.from(evtCss?.data?.Body).toString('utf8')
                    e.setStyle(cssParsed)
                    }
                    
                  } 
                  const params = {
                    Bucket: S3_BUCKET,
                    Key: location.state.html,
                  }
          
                  myBucket.getObject(params)
                  .on("complete", (evt) => {
                    if(evt?.data?.Body) { 
                      let htmlParsed = Buffer.from(evt?.data?.Body).toString('utf8')
                      e.setComponents(htmlParsed)
                      setLoadingContent(false)
                    }
                  })
                  .send((err) => {
                      if (err) console.log(err)
                  }) 
                })
                .send((err) => {
                    if (err) console.log(err)
                })       
              }} else {
                setLoadingContent(false)
              }
              
                   setEditor(e)
                   
   // save database
                   e.Panels.addButton
         ('options',
           [{
             id: 'save-db',
             className: 'fa fa-floppy-o',
             command: 'save-db',
             attributes: {title: 'Save DB'}
           }]
         );
         e.Commands.add
       ('save-db',
       {
           run: function(editor, sender) 
           {
            dispatch(ACTION_LOADING_SPINNER_ACTIVE())  
             sender && sender.set('active', 0); // turn off the button
             editor.store();
             
             if(location.state.type == "edit") {               
              //  let wrapper = `<html> <head><meta charset="utf-8" /></head> <body><style>${editor.getCss()}</style> ${editor.getHtml()}</body></html>`
              // dispatch(ACTION_LOADING_SPINNER_ACTIVE())
              //   updateReportFunc(wrapper)
              //   editor.DomComponents.clear(); // Clear components
              //   editor.CssComposer.clear();  // Clear styles
              //   editor.UndoManager.clear();
              // //downloadInnerHtml("Report.html", wrapper,'text/html');

              setTemplateHtml(editor.getHtml())
              setTemplateCss(editor.getCss())
             if(location.state._id) {
                updateTemplateFunc(
                  editor.getHtml(),
                  editor.getCss())  
              } else { 
                setAddTemplateModal("Template")
              }
             } else {
              setTemplateHtml(editor.getHtml())
              setTemplateCss(editor.getCss())
             if(location.state._id) {
                updateTemplateFunc(
                  editor.getHtml(),
                  editor.getCss())  
              } else { 
                setAddTemplateModal("Template")
              }
            }
          }
       });

   // load history

// if(location.state._id) {
// e.Panels.addButton
//   ('options',
//     [{
//       id: 'history',
//       className: 'fa fa-history',
//       command: 'history',
//       attributes: {title: 'History'}
//     }]
//   );
// e.Commands.add
// ('history',
//   {
//       run: function(editor, sender) 
//       {
//         sender && sender.set('active', 0); // turn off the button
//         editor.store();
//         setTemplateHistoryModal(location.state.history.filter(x => x !== null))
//       }
//   }
//   );
// }
    
   // close modal
       e.Panels.addButton
         ('options',
           [{
             id: 'closeModal',
             className: 'fa fa-times',
             command: 'closeModal',
             attributes: {title: 'Back to reports'}
           }]
         );
   
         e.Commands.add
       ('closeModal',
       {
           run: function(editor, sender)
           {
            dispatch(ACTION_LOADING_SPINNER_ACTIVE())  
             sender && sender.set('active', 0); // turn off the button
             editor.DomComponents.clear(); // Clear components
             editor.CssComposer.clear();  // Clear styles
             editor.UndoManager.clear();
             dispatch(ACTION_LOADING_SPINNER_RESET())
             location.state.type == "edit" ? history.push("/admin/reports") : history.push("/admin/reports/templates")              
           }
           });
        }
   
       });

       // load history modal

       const [templateHistoryModal, setTemplateHistoryModal] = useState(null)

const [selectedHistory, setSelectedHistory] = useState(null)
const [loadingHistory, setLoadingHistory] = useState(null)

const loadHistory = () => {  
  // dispatch(ACTION_LOADING_SPINNER_ACTIVE())  
  setTemplateHistoryModal(null) 
  //setLoadingHistory(selectedHistory)
}

useEffect(() => {
  if(loadingHistory) {
    setTimeout(() => {
      editor.setComponents(loadingHistory.html)
      editor.setStyle(loadingHistory.css)
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }, 1)
  }
},[loadingHistory])
useEffect(() => {
dispatch(ACTION_LOADING_SPINNER_ACTIVE())
},[])
useEffect(() => {
  if(editor) {
    dispatch(ACTION_LOADING_SPINNER_RESET())
  }
},[editor])
    // add Template modal

    const [addTemplateModal,setAddTemplateModal] = useState(null)
    const [templateName,setTemplateName] = useState("")
    const [templateHtml, setTemplateHtml] = useState("")
    const [templateThumbnail, setTemplateThumbnail] = useState("")
    const [templateCss, setTemplateCss] = useState("")   

    const addTemplateFunc = () => {

    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setAddTemplateModal(null)
    addTemplate({
      variables:{
        name: templateName,
        html:"test",
        css:"test",
        type: location.state.type.type,
        by: user?.user?._id
      } 
    }).then((res) => {
      const {_id} = res.data.addTemplate
      let html = `${_id}-html.html`
      let css = `${_id}-css.css` 
      const params = {
        ACL: 'public-read',
          Body: templateHtml,
          Bucket: S3_BUCKET,
          Key: html,
      };
  
      myBucket.putObject(params)
          .on("complete", (evt) => {
            const params = {
              ACL: 'public-read',
                Body: templateCss,
                Bucket: S3_BUCKET,
                Key: css,
            }; 
        
            myBucket.putObject(params)
                .on("complete", (evt) => {
                  updateTemplate({
                    variables:{
                      _id: _id,
                      html: html,
                      css: css,
                      by: user?.user?._id,
                    }
                  }).then(() => {
                    setTemplateName("")
                    setTemplateHtml("")
                    setTemplateCss("")
                    setTemplateThumbnail("")
                    templatesRefetch().then((res) => {
                      history.push("/admin/reports/templates")
                    })
                  })
                })
                .send((err) => {
                    if (err) console.log(err)
                }) 
          })
          .send((err) => {
              if (err) console.log(err)
          }) 
    })

    }

    const updateTemplateFunc = (html, css, thumbnail) => {

      let htmlKey = `${location.state._id}-html.html`
      let cssKey = `${location.state._id}-css.css`

      const params = {
        ACL: 'public-read',
          Body: html,
          Bucket: S3_BUCKET,
          Key: htmlKey,
      };
  
      myBucket.putObject(params)
            .on("complete", (evt) => {
              const params = {
                ACL: 'public-read',
                  Body: css,
                  Bucket: S3_BUCKET,
                  Key: cssKey,
              }
        
            myBucket.putObject(params)
                .on("complete", (evt) => {
                  setTemplateName("")
                  setTemplateHtml("")
                  setTemplateCss("")
                  setTemplateThumbnail("")
                  templatesRefetch().then((res) => {
                    location.state.reportId ? updateReport({
                      variables:{
                        _id: location.state.reportId,
                        newUpdate: true
                      }
                    }).then(() => {
                      reportsRefetch().then((res) => {
                       history.push("/admin/reports")
                      })
                    }) : history.push("/admin/reports/templates")
                })
                 
                })
                .send((err) => {
                    if (err) console.log(err)
                }) 
          })
          .send((err) => {
              if (err) console.log(err)
          })
    }


    // templates refetch

    useEffect(() => {
      if(addTemplateModal) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
    },[addTemplateModal])

    const loadingSpinnerActive = useSelector(
      (state) => state.loadingSpinnerActive.loading_spinner
    );

    return(
        <div className="grapesJsContainer">
          {returnToHomeScreen()}
          {returnToTemplates()}
          {loadingContent && <div style={{position:"fixed", zIndex:"9999999", width:"100%", height:"100%", background:"#ffffffa8"}}><DataSpinner /></div>}
          <AddTemplateModal 
          addTemplateModal={addTemplateModal}
          setAddTemplateModal={setAddTemplateModal}
          templateName={templateName}
          setTemplateName={setTemplateName}
          addTemplateFunc={addTemplateFunc}
          updateTemplateFunc={updateTemplateFunc}
          />
          <TemplateHistoryModal 
            templateHistoryModal={templateHistoryModal}
            setTemplateHistoryModal={setTemplateHistoryModal}
            selectedHistory={selectedHistory}
            setSelectedHistory={setSelectedHistory}
            loadHistory={loadHistory}
          />
           <div id="g" className="grapesInnerContainer test">
           </div>
        </div>
    )
}

export default ReportTemplate