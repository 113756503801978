import React, { useEffect } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmModal = (props) => {
    const {
        open,
        setOpen,
        handleIt,
        setHandleIt,
        } = props

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal">
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => setOpen(false)}
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Confirm</h1>
          <h1 className="header" style={{width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>Are you sure you want to {open}?</h1>
            
            <div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>

                <div
                
                className={`auth-button addUser confirmDelete ${open? open.includes("approve") ? "deleteButton": "":""}`}
                onClick={() => {              
                setOpen(null)
                }}
                >
                Cancel
                </div> 

                <div
                    // style={{background:"transparent", color:"#6DB240", borderColor:"#6DB240"}}
                    className={`auth-button addUser userActionsButtons ${open? open.includes("approve") ? "hoverGreen": "deleteButton confirmDelete": ""}`}
                    onClick={() => { 
                      handleIt()
                      setOpen(false)
                    }
                    }
                >

                Continue 

                </div>

            </div>
        </div>
      </Modal>
  )}

  export default ConfirmModal
       