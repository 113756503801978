const SELECTED_TESTS = "SELECTED_TESTS";
const SELECTED_TESTS_RESET = "SELECTED_TESTS_RESET";

const selectedTestsReducer = (state = { selectedTests: {} }, action) => {
  let oKey = action.test ? Object.keys(action.test)[0] : null
  switch (action.type) {
    case SELECTED_TESTS:
      return { ...state, selectedTests: oKey == "all" ? {...action.test[oKey]} : {...state.selectedTests, [oKey]: action.test[oKey] ? [...action.test[oKey]] : null} };
    case SELECTED_TESTS_RESET:
      return { ...state, selectedTests: {} };
    default:
      return { ...state };
  } 
};

export default selectedTestsReducer;