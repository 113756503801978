const SELECTED_TESTS = "SELECTED_TESTS";

const ACTION_SELECTED_TESTS = (test) => {
  return {
    type: SELECTED_TESTS,
    test
  };
};

export default ACTION_SELECTED_TESTS;
