import { gql } from "apollo-boost";

const sendEmailMutation = gql`
  mutation(
    $firstName: String
    $lastName: String
    $email: String
    $purpose: String
    $floorType: String
    $message: String
  ) {
    sendEmail(
    firstName: $firstName
    lastName: $lastName
    email: $email
    purpose: $purpose
    floorType: $floorType 
    message: $message

    ) {
    _id
    firstName
    lastName
    email
    purpose
    floorType
    message
  }
  }
`;

export default sendEmailMutation;
