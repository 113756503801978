import { faChevronDown, faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons';
import { useLocation } from "react-router-dom";
import add from "../../../../assests/icons/add.svg"
import "react-datepicker/dist/react-datepicker.css";
import { 
    FormGroup,
    Label,
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
 } from "reactstrap";
import "./tableHeader.scss"
import {AddAccountModal} from "../../modals/addAccountModal";
import AddClientModal from "../../modals/addClientModal";
import AvatarModal from "../../modals/addAccountModal/avatarModal";
import AddSiteModal from "../../modals/addSiteModal";
import search from "../../../../assests/icons/search.svg"

const TableHeader = (props) => {
    const {
        user,
        siteContactPersonPhoneNumber,
        setSiteContactPersonPhoneNumber,
        selectedClient,
        userCompanyCity,
        setUserCompanyCity,
        userCompanyState,
        setUserCompanyState,
        userCompanyCountry,
        setUserCompanyCountry,
        images,
        updateSiteFunc,
        setImages,
        emailError,
        setEmailError,
        subUser,
        setLicenseNumber,
        licenseNumber,
        currentUser,
        accountType,
        invitationModal,
        setInvitationModal,
        setUserFirstName,
        userFirstName,
        setUserLastName,
        userLastName,
        setUserEmail,
        userEmail,
        inviteUser,
        emailAlreadyRegistered,
        invalidEmail,
        setEmailAlreadyRegistered,
        setInvalidEmail,
        setCompanyName,
        companyName,
        setCompanyEmail,
        companyEmail,
        setCompanyPhoneNumber,
        companyPhoneNumber,
        setCompanyWorkPhoneNumber,
        companyWorkPhoneNumber,
        setCompanyAddress,
        companyAddress,
        city,
        setCity,
        state,
        setState,
        adminType,
        setAdminType,
        clientModal,
        setClientModal,
        setContactPersonName,
        contactPersonName,
        setUserPhoneNumber,
        userPhoneNumber,
        takeAPhotoSelected,
        changeTakeAPhotoSelected,
        webcamURI,
        changeWebcamURI,
        imageUploaded,
        changeImageUploaded,
        imagePreviewAvailable,
        changeImagePreviewAvailable,
        handleDeletedPreviewImage,
        handleImageUploaded,
        handleConfirmPhotoSubmit,
        addProfilePhotoClicked,
        setAddProfileClicked,
        setUserFranchisee,
        userFranchisee,
        setUserRole,
        userRole,
        setUserCompanyName,
        userCompanyName,
        setUserCompanyAddress,
        userCompanyAddress,
// site props
        siteModal,
        setSiteModal,
        setSiteName,
        siteName,
        setSiteEmail,
        siteEmail,
        addSiteFunc,
        setSiteAddress,
        siteAddress,
        siteContactPersonName,
        setSiteContactPersonName,
        updateUserFunc,
        setSearchFilter,
        setLicenseModal,
        setContactPersonFirstName,
        setContactPersonLastName,
        contactPersonFirstName,
        contactPersonLastName,
        selectedUser,
        zip,
        setZip,
        country,
        setCountry,
    } = props

    const location = useLocation()

    const [searchInputs, setSearchInputs] = useState("")

    const searchAccount = (e) => {
        setSearchFilter && setSearchFilter(e.target.value)
    }
    const preventKeys = (e) => {
        if (
          e.key === ")" ||
          e.key === "(" ||
          e.key === "[" ||
          e.key === "]" ||
          e.key === "\\" ||
          e.key === "/"
        ) {
          e.preventDefault();
        }
      };
    
    return (
        <div className="tableHeader">
            
            <AddClientModal
                images={images}
                setImages={setImages}
                zip={zip}
                setZip={setZip}
                country={country}
                setCountry={setCountry}
                clientModal={clientModal}
                setClientModal={setClientModal}
                setCompanyName={setCompanyName}
                companyName={companyName}
                setCompanyEmail={setCompanyEmail}
                companyEmail={companyEmail}
                inviteUser={inviteUser}
                emailAlreadyRegistered={emailAlreadyRegistered}
                invalidEmail={invalidEmail}
                setEmailAlreadyRegistered={setEmailAlreadyRegistered}
                setCompanyPhoneNumber={setCompanyPhoneNumber}
                companyPhoneNumber={companyPhoneNumber}
                setCompanyWorkPhoneNumber={setCompanyWorkPhoneNumber}
                companyWorkPhoneNumber={companyWorkPhoneNumber}
                setCompanyAddress={setCompanyAddress}
                companyAddress={companyAddress}
                contactPersonName={contactPersonName}
                setContactPersonName={setContactPersonName}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}
                // avatar props
                takeAPhotoSelected={takeAPhotoSelected}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                handleImageUploaded={handleImageUploaded}
                handleConfirmPhotoSubmit={handleConfirmPhotoSubmit}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
                setContactPersonFirstName={setContactPersonFirstName}
                setContactPersonLastName={setContactPersonLastName}
                contactPersonFirstName={contactPersonFirstName}
                contactPersonLastName={contactPersonLastName}
                
                />

            <AddAccountModal 
            userCompanyCity={userCompanyCity}
            setUserCompanyCity={setUserCompanyCity}
            userCompanyState={userCompanyState}
            setUserCompanyState={setUserCompanyState}

            userCompanyCountry={userCompanyCountry}
            setUserCompanyCountry={setUserCompanyCountry}
            zip={zip}
            setZip={setZip}
            images={images}
            setImages={setImages}
            emailError={emailError}
            setEmailError={setEmailError}
                setLicenseNumber={setLicenseNumber}
                licenseNumber={licenseNumber}
                selectedUser={selectedUser}
                currentUser={currentUser}
                invitationModal={invitationModal}
                setInvitationModal={setInvitationModal}
                setUserFirstName={setUserFirstName}
                userFirstName={userFirstName}
                setUserLastName={setUserLastName}
                userLastName={userLastName}
                setUserEmail={setUserEmail}
                userEmail={userEmail}
                inviteUser={inviteUser}
                emailAlreadyRegistered={emailAlreadyRegistered}
                invalidEmail={invalidEmail}
                setEmailAlreadyRegistered={setEmailAlreadyRegistered}
                setInvalidEmail={setInvalidEmail}
                setUserPhoneNumber={setUserPhoneNumber}
                userPhoneNumber={userPhoneNumber}
                adminType={adminType}
                setAdminType={setAdminType}
                // avatar props
                takeAPhotoSelected={takeAPhotoSelected}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                handleImageUploaded={handleImageUploaded}
                handleConfirmPhotoSubmit={handleConfirmPhotoSubmit}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
                setUserFranchisee={setUserFranchisee}
                userFranchisee={userFranchisee}
                setUserRole={setUserRole}
                userRole={userRole}
                setUserCompanyName={setUserCompanyName}
                userCompanyName={userCompanyName}
                setUserCompanyAddress={setUserCompanyAddress}
                userCompanyAddress={userCompanyAddress}
                updateUserFunc={updateUserFunc}
            />
            <AddSiteModal
            zip={zip}
            setZip={setZip}
            country={country}
            setCountry={setCountry}
            updateSiteFunc={updateSiteFunc}
            selectedClient={selectedClient}
            siteModal={siteModal}
            setSiteModal={setSiteModal}
            setSiteName={setSiteName}
            siteName={siteName}
            setSiteEmail={setSiteEmail}
            siteEmail={siteEmail}
            addSiteFunc={addSiteFunc}
            emailAlreadyRegistered={emailAlreadyRegistered}
            invalidEmail={invalidEmail}
            setEmailAlreadyRegistered={setEmailAlreadyRegistered}
            setSiteAddress={setSiteAddress}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            siteAddress={siteAddress}
            siteContactPersonName={siteContactPersonName}
            setSiteContactPersonName={setSiteContactPersonName}
            
        siteContactPersonPhoneNumber={siteContactPersonPhoneNumber}
        setSiteContactPersonPhoneNumber={setSiteContactPersonPhoneNumber}
            />
            <div className="tableHeaderSearch">
            {!subUser && <FormGroup className="auth-input searchUser">
                
                        <Input
                        type="text"
                        name="searchUser"
                        defaultValue={searchInputs}
                        maxLength={128}
                        className="auth-input-bar"
                        placeholder={`Search ${accountType}s`}
                        onChange={(e) => searchAccount(e)}
                        onKeyDown={preventKeys}
                        />
                        <img style={{position:"absolute"}} src={search} />
            </FormGroup>}
            </div>
            {accountType !== "Report" ? accountType == "User" ? currentUser !== "sub-user" ? <div className="tableHeaderAdd">
            <div 
            style={{
              color: "rgb(255, 255, 255)",
              pointerEvents:  "auto",
              background: "#5C92BF",
              
          }} 
          className="auth-button addUser floatButton"
          onClick={accountType == "License" ? () => setLicenseModal("License") : accountType == "Site" ? () => setSiteModal("site") : accountType !== "Client" ? () => setInvitationModal(accountType) : () => {
            
            setCountry(user?.user?.companyAddress.split(',')[3])
            setClientModal("client")}}
          >
                <span style={{ marginRight:".5rem"}} >Add New {accountType} </span> <img src={add} />
            </div>
            </div> : null : <div className="tableHeaderAdd">
            <div 
            style={{
              color: "rgb(255, 255, 255)",
              pointerEvents:  "auto",
              background: "#5C92BF",  
              
          }} 
          className="auth-button addUser floatButton"
          onClick={accountType == "License" ? () => setLicenseModal("License") : accountType == "Site" ? () => setSiteModal("site") : accountType !== "Client" ? () => setInvitationModal(accountType) : () => {
            setCountry(user?.user?.companyAddress?.split(',')[3])
            setClientModal("client")}}
          >
                <span  style={{ marginRight:".5rem"}}  >Add New {accountType} </span> <img src={add} />
            </div>
            </div> : null}
        </div>
    )
}  
 
export default TableHeader     