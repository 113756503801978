const CONFIRM_NEW_PASSWORD_NOT_VALID =
  "CONFIRM_NEW_PASSWORD_NOT_VALID";

const ACTION_CONFIRM_NEW_PASSWORD_NOT_VALID = () => {
  return {
    type: CONFIRM_NEW_PASSWORD_NOT_VALID,
  };
};

export default ACTION_CONFIRM_NEW_PASSWORD_NOT_VALID;
