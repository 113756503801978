import React, { useEffect, useState } from 'react'
import Modal from "react-modal"
import "../../../../assests/styles/Modal.scss"
import DatePicker from "react-datepicker"
import addDays from "date-fns/addDays"
import Dropdown from "react-dropdown"
const AddLicenseModal = (props) => {
    const {
        licenseModal,
        setLicenseModal,
        freeReports,
        setFreeReports,
        setLicenseNotes,
        licenseNotes,
        expirationDate,
        setExpirationDate,
        addLicenseFunc,
        updateLicenseFunc,
        assignLicenseFunc,
        setLicenseUser,
        licenseUser,
        auditAssignOptions,
        setSelectedLicense
        
    } = props


    const [licenseNotesTyping, setLicenseNotesTyping] = useState(null)
    useEffect(() => {
      if(licenseNotes) {
        setLicenseNotesTyping(licenseNotes)
      } else {
        setLicenseNotesTyping("")
      }
    },[licenseNotes])

    const handleChangeRaw = (value) => {
      if (value === "day") {
        setExpirationDate(addDays(new Date(), 1));
      }
      if(value === "week") {
        setExpirationDate(addDays(new Date(), 365))
      }
      if(value === "month") {
        setExpirationDate(addDays(new Date(), 30))
      }
      if(value === "year") {
        setExpirationDate(addDays(new Date(), 365))
      }
    };

  return(
<Modal
        isOpen={licenseModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { licenseModal && <div className="modal task-modal" style={{height: "fit-content"}}>
               

          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>{licenseModal.includes("Edit") || licenseModal.includes("Assign") ? licenseModal : `Add ${licenseModal}`}</h1>
          <div className="form-group-container task-form" style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}}>  

           {licenseModal.includes("Edit") && <><div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Free Reports<span style={{color:"red"}}> *</span></span></div>
              <input
                onKeyDown={(e) => {
                  if (
                    (e.keyCode >= 8 && e.keyCode < 32) ||
                    (e.keyCode >= 37 && e.keyCode <= 40) ||
                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode == 17) || 
                    (e.keyCode == 86)      
                  ) {
                    return e.keyCode;
                  } else {
                   e && e.preventDefault();
                  }
                }}
                onChange={(e) => setFreeReports(e.target.value)}
                value={freeReports}
                type="text"
                className="form-input"
              />
            </div>
            </div>
            <div className="form-row">
                <div className="form-collection textarea" style={{width:"100%"}}>
                <div className="form-label"><span>Expiration Date<span style={{color:"red"}}> *</span></span></div>
                <DatePicker
                    className='form-input'
                    selected={expirationDate}
                    onChange={(date) => setExpirationDate(date)}
                    placeholderText='Enter a date'
                    onChangeRaw={(event) => handleChangeRaw(event.target.value)}
                  />
              </div>
              </div>
        </>}

          {!licenseModal.includes("Assign") && <div className="form-row">
                <div className="form-collection textarea" style={{width:"100%"}}>
                <div className="form-label">notes</div>
                  <textarea
                    onChange={(e) => setLicenseNotesTyping(e.target.value)}
                    onBlur={(e) => setLicenseNotes(licenseNotesTyping)}
                    value={licenseNotesTyping}
                    type="text"
                    placeholder="Notes"
                    className="form-input textarea-input"
                  />
              </div>
              </div>}
          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {                  
                    setFreeReports("")
                    setLicenseNotes("")
                    setExpirationDate("")
                    setSelectedLicense(null)
                    setLicenseModal(null)
                }}
            >

            Cancel

            </div>
            <div
            style={{margin:".5rem"}}
              className={`${licenseModal.includes("Assign") && licenseUser ? "auth-button addUser" : true ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={ licenseModal.includes("Assign") && licenseUser ? assignLicenseFunc : true ?  licenseModal.includes("Edit") ? updateLicenseFunc  : addLicenseFunc : null }
            >
              
              {licenseModal.includes("Edit") ? "Update" : licenseModal.includes("Assign") ? "Assign" : "Add"}

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default AddLicenseModal
       