const MOBILE_DRAG = "MOBILE_DRAG";

const ACTION_MOBILE_DRAG = (payload) => {
  return {
    type: MOBILE_DRAG,
    payload
  };
};

export default ACTION_MOBILE_DRAG;
