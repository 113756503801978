import { gql } from "apollo-boost";

const allClientsQuery = gql`
   query(
    $user: ID
    $_id: ID
  ) {
    allClients(
      user: $user
      _id: $_id
     
    ) {
      _id
    companyName
    companyAddress
    contactPersonName
    contactPersonEmail
    contactPersonPhoneNumber
    contactPersonWorkPhoneNumber
    status
    image
    user
    zip
    }
  }
`;

export default allClientsQuery;