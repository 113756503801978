const REPORT_TEMPLATE_PDF_READY = "REPORT_TEMPLATE_PDF_READY";
const REPORT_TEMPLATE_PDF_READY_RESET = "REPORT_TEMPLATE_PDF_READY_RESET";

const reportTemplatePDFReducer = (
  state = { report_template_pdf_ready: false },
  action
) => {
  switch (action.type) {
    case REPORT_TEMPLATE_PDF_READY:
      return { ...state, report_template_pdf_ready: true };
    case REPORT_TEMPLATE_PDF_READY_RESET:
      return { ...state, report_template_pdf_ready: false };
    default:
      return { ...state };
  }
};

export default reportTemplatePDFReducer;
