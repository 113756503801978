import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataSpinner } from '../../spinner/dataSpinner';

const WarningModal = (props) => {
    const {   
        open,
        setOpen,
        button,
        done,
        resetModalFunc,
        setOnFlyTest,  
        unsaved,
        dismissChanges,
        warning
        } = props

        const [loadingClick, setLoadingClick] = useState(null)
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0", 
            right: "0",
            bottom: "0", 
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
                
               {loadingClick ? <DataSpinner /> : <div className="modal password-modal">
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setOnFlyTest && setOnFlyTest(null)
                  setOpen(false)}
                }
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px", marginBottom:"70px", color:"#EF1111"}}>{"Warning"}</h1>
          {/* <h1 className="header" style={{width:"100%", fontSize:"24px", marginBottom:"70px", display:"flex", justifyContent:"center", alignItems:"center", textDecoration:"underline"}}>{warning}</h1> */}
          
          <div style={{width:"100%", textTransform:"none" , color:"#EF1111", fontWeight:"bolder", fontSize:"18px", display:"flex", width:"100%", justifyContent:"center", alignItems:"center", color: "#505463"}}>{open?.open ? open.open : open}</div>
            
            {button && <div style={{display:"flex" , justifyContent: "space-between", width:"100%", marginTop:"50px"}}>
            
              {resetModalFunc && <div
                className={`auth-button addUser confirmDelete deleteButton`}
                onClick={() => {
                resetModalFunc()
                }}
                >
                Dismiss
                </div> }
                <div
                className={`auth-button addUser confirmDelete deleteButton`}
                onClick={() => {
                  setLoadingClick(true)
                if(open?.unsaved) {
                  done()
                } else {
                  button()  
                  setOpen(null) 
                }
                
                }}
                >
                Confirm
                </div> 
                {open?.unsaved && <div
                className={`auth-button addUser confirmDelete`}
                onClick={() => {
                dismissChanges()
                }}
                >
                Dismiss
                </div> }
            </div>}
        </div>}
      </Modal>
  )}

  export default WarningModal
       