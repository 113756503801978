import React, { useEffect, useState, useRef } from 'react'
import Modal from "react-modal"
import "./index.scss"
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import LZString from 'lz-string';
import BeforeLoadModal from '../beforeLoadModal';
import { DataSpinner } from '../../spinner/dataSpinner';
import logo from "../../../../assests/images/logo.png"

const PrintModal = (props) => { 

    const {
      setAddAreaDropDown,
        selectedAudit,
        printModal,
        setPrintModal,
        allAreasToPrint,
        printFunc,
    } = props

   const print = useRef()

   
  return( 
<Modal
        isOpen={printModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
             
              
              {printModal ? (
               <div className="modal task-modal" style={{width:"90vw", height:"100vh"}}>
               <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setAddAreaDropDown(true)
                  setPrintModal(null)
                }}
              />
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>Audit Print</h1>
          <div ref={print} className="form-group-container task-form printedContainer" style={{height:"100vh", flexDirection:"column", margin:"50px 0", width:"100%", justifyContent:"flex-start", alignItems:"center", overflowX:"auto"}}>
          {/* <img alt={""} src={logo} className={"watermark"}/> */}
            <table className="report-container">
                <thead className="report-header">
                <tr>
                    <th className="report-header-cell">
                        <div className="header-info">
                            
                        </div>
                    </th>
                </tr>
                </thead>
                <tfoot className="report-footer">
                <tr>
                    <td className="report-footer-cell">
                        <div className="footer-info">
                            <div className={"page-footer"}>
                            
                            </div>
                        </div>
                    </td>
                </tr>
                </tfoot>
                <tbody className="report-content">
                <tr>
                    <td className="report-content-cell">
                        <div className="main">
                        <div style={{display:"flex", flexDirection:"column", width:"90%", justifyContent:"flex-start", alignItems:"center", padding:"70px 0", borderRadius:"8px"}}>
            <div style={{width:"90%", display:"flex"}}>
                <div style={{width:"20%", display:"flex", display:"flex",flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                <div style={{margin: "0 0 10px 0"}}>
                  <div className='userSquareHeader'>Site Locations</div>
                  <div>
                    <div style={{color:"#898989", fontWeight:"500", fontSize:"17px"}}>{selectedAudit?.site?.name}</div>
                    <div style={{color:"#898989", fontWeight:"500", fontSize:"17px"}}>{selectedAudit?.site?.address}</div>
                  </div> 
                </div>
                <div style={{margin: "0 0 10px 0"}}>
                  <div className='userSquareHeader'>Job Prep</div>
                  <ul>
                    <li>Recondition all SBR rubber sensors</li>
                    <li>Charge all batteries</li>
                  </ul>
                </div>
                <div>
                  <div className='userSquareHeader'>Equipment Needed</div>
                  <ul>
                    <li>BOT-3000E Kit</li>
                    <li>Bring extra SBR rubber sensors</li>
                  </ul>
                </div>
                </div>
                <div style={{width:"80%", display:"flex", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                <div style={{margin: "0 0 30px 0"}}>
                  <div className='userSquareHeader'>Site Contact</div>
                  <div>
                    <div style={{color:"#898989", fontWeight:"500", fontSize:"17px"}}>{selectedAudit?.site?.contactPersonName}</div>
                    <div style={{color:"#898989", fontWeight:"500", fontSize:"17px"}}>{selectedAudit?.site?.contactPersonPhoneNumber?.replace(/\s/g, '').replace(")", ") ")}</div>
                  </div>
                </div>
                <div style={{margin: "0 0 10px 0"}}>
                  <div className='userSquareHeader'>Site Prep</div>
                  <ol>
                    <li>Start with a full battery and perform System verification using the verification mass</li>
                    <li>Clear the area of known debris</li>
                    <li>Confirm with the site contact which areas you will be auditing

                      <ol style={{listStyleType:"lower-alpha"}}>
                        <li style={{fontWeight:"600"}}>Please draw out the floor plan and identify area names, diagrams, etc.
                        and note the locations</li>
                                                <li style={{fontWeight:"600"}}>Please complete the audit report form and take pictures of the areas/
                        locations</li>
                      </ol>
                    </li>
                    </ol>
                </div>
                </div>
            </div>
                {allAreasToPrint.map((selectedAreaToPrint, i, a) =>(
              <>

              <div key={i} className='historyViewerContainer' style={{border:"0px",height:"fit-content", width:"90%", overflow:"visible", margin:"20px 0"}}>
                <div style={{display:"flex", justifyContent:"space-between", cursor:"pointer", padding:"9px 20px",cursor:"auto", borderRadius:"8px"}}
                className="userSquareHeader" >
                <div style={{
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center" 
                }}
                >
                <span>
                {selectedAreaToPrint.name} (Area #{selectedAreaToPrint.areaNumber})</span>
                {selectedAreaToPrint.pass !== null && <span style={{width:"auto", margin:"0 1rem", backgroundColor: selectedAreaToPrint.pass == "pass" ? "#6DB240" : "#EF1111"}} className="audit-status" >{selectedAreaToPrint.pass}</span>}
                </div> 
                </div>

                    <div  className="reportsContainer areaContainer">
                    <div style={{marginBottom:"2.5rem"}} className="areaUpperContainer responsiveArea">
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <div className="areaCard testingDiagramCard" style={{width:"fit-content"}}>
                              <div className="areaCardType">
                                  <div className="typeHeader testingDiagramHeader" style={{justifyContent:"space-between", display:"flex"}}><span>testing diagram</span></div>
                                  <div className="typeContent" style={{position:"relative", width:"350px", height:"350px"}}>
                                      <> 
                                      <img width={"350px"} height={"350px"} src={selectedAreaToPrint.diagramImage}/>
                                      {selectedAreaToPrint.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).map((shape, i) => (
                                      
                                      <div key={i}>
                                        <div 
                                        className={`${
                                          selectedAreaToPrint.results?.some(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)
                                          ?selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id).length == 2
                                          ?  ((selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)?.some(x=>x.passed) &&selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)?.some(x => !x.passed))) 
                                          ? "differentLocations clickable" 
                                          : (selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)?.every(x => !x.passed))
                                          ? "faildLocation clickable" : 
                                          (selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)?.every(x=> x.passed)) ?
                                          "passLocation clickable" : "notAny"
                                          :selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)?.every(x=>!x.passed)
                                          ? "faildLocation clickable" 
                                          :selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)?.every(x=>x.passed)
                                          ? "passLocation clickable"
                                          : "" : ""                                 
                                        }`} 
                                        style={{ position:"absolute", width:`${shape[Object.keys(shape)].radius * 2.5+5}px`, borderRadius:"50%", height:`${shape[Object.keys(shape)].radius * 2.5+5}px`,  top:shape[Object.keys(shape)].y - shape[Object.keys(shape)].radius - 3, left:shape[Object.keys(shape)].x - shape[Object.keys(shape)].radius - 3}}>
                                                                                  {selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id).length == 2 ? selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)?.every(x=>!x.passed) || selectedAreaToPrint.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == selectedAreaToPrint.id)?.every(x=>x.passed) ? <div style={{justifyContent:"center", display:"flex", alignItems:"center", color:"white", fontWeight:"bold"}}>2</div> : null : null}

                                      </div>
                                      <span className='locationTestId' style={{ top:shape[Object.keys(shape)].y + shape[Object.keys(shape)].radius +5, left:shape[Object.keys(shape)].x, position:"absolute", fontWeight:"bold", color:"black", textShadow:"1px 1px 2px #fff"}}>{Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length)}</span>
                                      </div>
                                      
                                      ))}
                                      </>
                                  </div>
                              </div>
                              <div className="diagramDetails">
                                  <div className="diagramDetailsContainer"> 
                              <div className="areaCardType">
                                  <div className="typeHeader">date</div>
                                  <div className="typeContent">{selectedAreaToPrint.createdAt ? selectedAreaToPrint.createdAt : "unknown"}</div>
                              </div>
                              <div className="areaCardType">
                                  <div className="typeHeader">created by</div>
                                  <div className="typeContent">{selectedAreaToPrint.createdBy ? selectedAreaToPrint.createdBy : "unknown"}</div>
                              </div>
                              </div>
                              </div>
                      </div> 
                      <div className="areaCard testingDiagramCard" style={{width:"400px"}}>
                          <div className="areaCardType areaNotes">
                          <div className="typeHeader">notes</div>
                          <div className="typeContent">{selectedAreaToPrint.auditorsNotes ? selectedAreaToPrint.auditorsNotes : "No notes"}</div>
                      </div>

                    
                      <div className="areaCardType areaImages">
                          <div className="typeHeader">photos</div>
                          {selectedAreaToPrint.images && selectedAreaToPrint.images.length > 0 && <div className="typeContent">{selectedAreaToPrint.images.map((image, i) => (
                              <div  key={i} style={{margin:"5px"}}>
                              <img
                              className="areaImage"
                              src={image}
                              alt="areaImage" 
                            />
                            </div>
                          ))}
                          </div>}
                          </div>
                      </div>
                      <div className="areaCard testingDiagramCard" style={{width:"400px"}}>
                            <div className="areaCardType">
                                <div className="typeHeader"> 
                                <label class="plus-minus bounce">
                          <input checked={false} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                                Floor Type                                <div className="typeContent" style={{display:"inline-flex"}}>{selectedAreaToPrint.floorType}</div>
</div>
                            </div>

                            <div className="areaCardType">
                                <div className="typeHeader"> 
                                <label class="plus-minus bounce">
                          <input checked={false} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                                Testing Category
</div>
<div className="typeContent" style={{display:"inline-flex"}}>{selectedAreaToPrint.testingCategory ? selectedAreaToPrint.testingCategory : "any"}</div>
                            </div>
                            { selectedAreaToPrint.floorTypeDescription && <div className="areaCardType">
                                <div className="typeHeader"> 
                                <label class="plus-minus bounce">
                          <input checked={false} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                                Floor Type Description                                <div className="typeContent" style={{display:"inline-flex"}}>{selectedAreaToPrint.floorTypeDescription}</div>
</div>
                        </div> }
                <div className="areaCardType">
                            <div className="typeHeader"> 
                            <label class="plus-minus bounce">
                          <input checked={false} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                            floor finish                            <div className="typeContent" style={{display:"inline-flex"}}>{selectedAreaToPrint.floorFinish}</div>
</div>
                        </div>
                        { selectedAreaToPrint.floorFinishDescription &&  <div className="areaCardType">
                            <div className="typeHeader"> 
                            <label class="plus-minus bounce">
                          <input checked={false} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                            floor finish Description                            <div className="typeContent" style={{display:"inline-flex"}}>{selectedAreaToPrint.floorFinishDescription}</div>
</div>
                        </div> }
                        {selectedAreaToPrint?.floorTexture && <div className="areaCardType">
                    <div className="typeHeader"> 
                    <label class="plus-minus bounce">
                          <input checked={false} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                    Floor Texture                    <div className="typeContent" style={{display:"inline-flex"}}>{selectedAreaToPrint.floorTexture}</div>
</div>
                </div>}
                        <div className="areaCardType">
                    <div className="typeHeader"> 
                    <label class="plus-minus bounce">
                          <input checked={false} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                    Floor Condition                    <div className="typeContent" style={{display:"inline-flex"}}>{selectedAreaToPrint.conditionofFloorFinish ? selectedAreaToPrint.conditionofFloorFinish : "N/A"}</div>
</div>
                </div>
                <div className="areaCardType">
                    <div className="typeHeader"> 
                    <label class="plus-minus bounce">
                          <input checked={false} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                    Slope Surface <div className="typeContent" style={{display:"inline-flex"}}>{selectedAreaToPrint.slopeofFloorsSurface}</div></div> 
                   
                </div>
                    </div>
                    </div>
                    <div className="rightCards" >                      
                    <div className="rightLower">
                    <div className="areaUpperContainer" style={{height:"fit-content"}}>
                    
                    </div>
                    </div>
                    <div className='areaCard' style={{width:"98%", fontSize:"24px"}}>
                    <div style={{margin: "0 0 10px 0"}}>
                  <div className='userSquareHeader'>Wet DCOF</div>
                  <ol>
                    <li style={{margin:"20px 0"}}>Perform System Verification (Pass)
                    <ol style={{listStyleType:"lower-alpha"}}>
                        <li>Take the BOT-3000E out of the case and with a charged battery begin the verification process. On a stable level surface insert the steel verification mass and complete the verification process until PASSED.</li>
                      </ol>
                    </li>
                    <li style={{margin:"20px 0"}}>Recondition SBR Sensor</li>
                    <li style={{margin:"20px 0"}}>Press DCOF Button</li>
                    <li style={{margin:"20px 0"}}>Press A326.3</li>
                    <li style={{margin:"20px 0"}}>Set testing conditions:

                      <ol style={{listStyleType:"lower-alpha"}}>
                        <li>Field, Wet</li>
                        <li>Prevailing or Clean</li>
                      </ol>
                    </li>
                    <li style={{margin:"20px 0"}}>Set area and location:

                      <ol style={{listStyleType:"lower-alpha"}}>
                        <li>Test #1: Area: {selectedAreaToPrint?.areaNumber}; Location: {Object.keys(selectedAreaToPrint.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).sort((a,b) => Object.keys(a)[0].substring(11, Object.keys(a)[0].length) - Object.keys(b)[0].substring(11, Object.keys(b)[0].length))[0])[0].substring(11, Object.keys(selectedAreaToPrint.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).sort((a,b) => Object.keys(a)[0].substring(11, Object.keys(a)[0].length) - Object.keys(b)[0].substring(11, Object.keys(b)[0].length))[0])[0].length)}
                        </li>
                        <li>Test Distance: 8 Inches</li>
                      </ol>
                    </li>

                    <li style={{margin:"20px 0"}}>Sample 1
                      <ol style={{listStyleType:"lower-alpha"}}>
                        <li>Using the SLS Solution, create a thin film down the test path</li>
                        <li>Test Sample 1 in the four cardinal directions</li>
                        <li>Recondition SBR Sensor</li>
                      </ol>
                    </li>

                    <li style={{margin:"20px 0"}}>Sample 2
                      <ol style={{listStyleType:"lower-alpha"}}>
                        <li>Using the SLS Solution, create a thin film down the test path</li>
                        <li>Test Sample 2 in the four cardinal directions</li>
                        <li>Recondition SBR Sensor</li>
                      </ol>
                    </li>

                    <li style={{margin:"20px 0"}}>Sample 3
                      <ol style={{listStyleType:"lower-alpha"}}>
                        <li>Using the SLS Solution, create a thin film down the test path</li>
                        <li>Test Sample 3 in the four cardinal directions</li>
                        <li>Recondition SBR Sensor</li>
                      </ol>
                    </li>

                    <li style={{margin:"20px 0"}}>Press DONE to create ANSI A326.3 Report</li>

                    <li style={{margin:"20px 0"}}>Repeat steps 4-9
                      <ol style={{listStyleType:"lower-alpha"}}>
                        {selectedAreaToPrint.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).sort((a,b) => Object.keys(a)[0].substring(11, Object.keys(a)[0].length) - Object.keys(b)[0].substring(11, Object.keys(b)[0].length)).map((shape, i) => (
                         i !== 0 ? <>
                           <li>Set area and location; Test #{i+1}: <br/>Area: {selectedAreaToPrint.areaNumber}; Location: {Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length)}</li>
                           <li>Press DONE to create ANSI A326.3 Report</li>
                          </> : null
                        ))}
                      </ol>
                    </li>
 
                    </ol>
                </div>
                    </div>
                    </div>
                    </div>
                </div>
                { i !== a.length-1 ?<div className="pagebreak"></div>:null}  
                </div>
                </>))} 
          </div> 
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
           
          
          </div>
          
          <div style={{position:"fixed",height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
            <div></div>
          
             <ReactToPrint
              content={() => print?.current}
              trigger={() => (
                <div
                style={{margin:".5rem"}}
                  className={`${true ? "auth-button addUser": "auth-button addUser disable-button"}`}
                >Print</div>
          )}
        />
          </div>
        </div>) : null  }
      </Modal>
  )}

  export default PrintModal
       