import { gql } from "apollo-boost";

const allLicensesQuery = gql`
  
    query(
    $user: ID
  ) { 
    allLicenses (
      user: $user
    ) {
    _id
    user
    createdBy
    pass
    endDate
    dateIssued
    freeReports
    purchasedReports
    users
    status
    notes
    number
    licenseType
    }
  }
  
`;

export default allLicensesQuery;
