const REGISTERED_USER_FOUND = "REGISTERED_USER_FOUND";
const REGISTERED_USER_FOUND_RESET = "REGISTERED_USER_FOUND_RESET";

const registeredUserFoundReducer = (
  state = { registered_user_found: false },
  action
) => {
  switch (action.type) {
    case REGISTERED_USER_FOUND:
      return { ...state, registered_user_found: true };
    case REGISTERED_USER_FOUND_RESET:
      return { ...state, registered_user_found: false };
    default:
      return { ...state };
  }
};

export default registeredUserFoundReducer;
