import React from "react";
import "./home.scss"

const Home = () => {
    return (
        <div>
            home
        </div>
    )
}

export default Home