import React, { useEffect, useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import Cookies, { set } from "js-cookie";
import "./adminDashboard.scss"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { AccountTable as DashboardTable }from "../../resuable/accountTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Dropdown from "react-dropdown";
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
import reportBlue from "../../../assests/icons/reportBlue.svg"
import moment from "moment";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import { useMutation } from "@apollo/client";
import addLicenseMutation from "../../../graphql/mutations/license/addLicenseMutation";
import updateLicenseMutation from "../../../graphql/mutations/license/updateLicenseMutation";
import AddLicenseModal from "../../resuable/modals/addLicenseModal";
import { useDispatch } from "react-redux";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import { MyCalendar } from "../../client/calendar";

const AdminDashboard = ({
    user,
    reports,
    audits,
    users,
    licenses,
    licensesRefetch,
    defaultDCOF,
    auditAssignOptions,
    usersRefetch,
    clients,
    sites
}) => {
    const dispatch = useDispatch()
    const [usersCount, setUsersCount] = useState()
    const [subUsersCount, setSubUsersCount] = useState()
    
    const history = useHistory()

    const reportTemplateType = [
        { name: 'single run', value: 45 },
        { name: 'SCOF', value: 37 },
        { name: 'DCOF', value: 18 }

      ]; 

      const reportClass = [
        { name: 'WMG', value: 22 },
        { name: 'Franchisee', value: 30 },
        { name: 'Non-Franchisee', value: 30 }

      ];
    

      const handleClick = useCallback(() => {
        // handle the click event
      }, []);
      
 // expired licenses 

  
  const licensesColumns = [
        
    {
        name: 'name',
        filter:["none"],
        button: true,
        selector: row => row.name,
        
    },
    {
        name: 'expiration',
        filter:["none"],
        // button: true,
        width:"250px",
        cell: row => <div style={{color: moment().add("2", "week").isAfter(moment(row.endDate)) ? "#EF1111" : "black"}}>{row.expiration}</div>,
        
    }
    ,{
        name: "update",
        filter:["none"],
        cell: (row) => <div style={{width:"fit-content"}} className="tableHeaderAdd">
        <div 
        style={{
          color: "rgb(255, 255, 255)",
          pointerEvents:  "auto", 
          background: "#5C92BF",
          marginRight: "1rem",
          fontSize:"14",
          fontWeight:"bold",
      }} 
      className="auth-button addUser"
      onClick={(e) => {
          e.stopPropagation()
          setSelectedLicense(row)
          setFreeReports(row.count)
          setExpirationDate(new Date(row.endDate))
          setLicenseNotes(row.notes)          
          setLicenseModal("Edit License")
      }}
      >
            update
        </div>
        </div>,
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        style:{
            fontSize:"16px"
        }
    }
];


  useEffect(() => {
    if(licenses) {
        setLicensesExpired(licenses.map(x => {
            return {
                id: x._id,
                name: x.number,
                endDate: x.endDate,
                count: x.freeReports,
                notes: x.notes,
                expiration: moment(x.endDate).format("MM/DD/YYYY")
            }
        }).filter((y) => {
            return moment(y.endDate).isBefore(moment().add(1, "month"))
        }).sort((a,b) => new Date(a.endDate) - new Date(b.endDate))
        )
    }
  },[licenses])
  const [licensesExpired, setLicensesExpired] = useState(null)
  
// pending reports
const reportsColumns = [
        
    {
        name: "report",
        filter:["none"],
        cell: (row) => <img src={reportBlue} style={{cursor:"pointer"}} onClick={(e) => {
            e.stopPropagation()
            history.push({ 
                pathname: `/admin/reports/template`,
                state: {
                type: "edit",
                reportId: row.id,
                html: row.template,
                css: defaultDCOF.css,
                }
            })
        }}/>,
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        style:{
            fontSize:"16px",
            color:"#5C92BF"
        }
    },
    {
        name: 'Auditor',
        filter:["none"],
        selector: row => row.userName,
    },
    {
        name: 'company',
        filter:["none"],
        selector: row => row.company,
    },
    {
        name: 'date created',
        filter:["none"],
        selector: row => row.date,

    },
    {
        name: 'audit date',
        filter:["none"],
        selector: row => row.dateOfAudit,
        
    },
];

useEffect(() => {
    if(reports && users?.length) {
        setpendingReports(reports.filter(y => y.status == "pending").map(x => {
            let activeUser = users.find(y => y._id == x.user)
            return{
                id: x.id,
                dateOfAudit: x.dateOfAudit,
                date: x.dateOfReportGeneration,
                company:activeUser?.companyName,
                userName:activeUser?.name,
                template: x.reportTemplate

            }
        }))
    }
},[reports, users])
 const [pendingReports, setpendingReports] = useState(null)
const usersLowCountColumns = [
    {
        name: 'name',
        filter:["none"],
        selector: row => row.name,
        
    },
    {
        name: 'free',
        filter:["none"],
        cell: row => <div style={{color: row.count <= 1 ? "#EF1111" : "black"}}>{row.count}</div>,
        
    },
    {
        name: 'purchased',
        filter:["none"],
        cell: row => <div style={{color: row.purchased <= 1 ? "#EF1111" : "black"}}>{row.purchased}</div>,
    }
];

useEffect(() => {
    if(users) {
setUsersCount(users.filter(x => x.role == "user")?.length)
setSubUsersCount(users.filter(x => x.role == "sub-user")?.length)
        setUsersLowCount(users.filter(x => x.role == "user").map(x => {
            let license = x.licenseNumber ? JSON.parse(x.licenseNumber) : {}
            if(license) {  
            return{
                id: x.id,
                name: x.name,
                count: license[0]?.freeReports || "0",
                purchased: license[0]?.purchasedReports || "0"
                }  
                }
           
        }).filter((y) => Number(y.count) <= 3 && Number(y.purchased) <= 3)
        )
    }
},[users])

const [usersLowCount, setUsersLowCount] = useState(null)
const upcomingAuditsColumns = [
        
    {
        name: 'client',
        filter:["none"],
        selector: row => row.client,

        
    },
    {
        name: 'site',
        filter:["none"],
        selector: row => row.site,
        
    },
    {
        name: 'created on',
        filter:["none"],
        selector: row => row.createdOn,
        
    },
    {
        name: 'assigned to',
        filter:["none"],
        cell: row => <div style={{color: row.assignTo == "not assigned" ? "#EF1111" : "black"}}>{row.assignTo ? `${row.assignTo} ${row.assignToId ? row.assignToId == user?.user?._id ? `(you)` : `(user)` : ""}` : "not assigned"} </div>,
        width:"100px"
        
    }
];

  
const newClientsColumns = [        
    {
        name: 'Client Name',
        filter:["none"],
        selector: row => row.name,
        
    },
    {
        name: 'Contact person',
        filter:["none"],
        selector: row => row.contatName,
        
    },
    {
        name: 'Client Address',
        filter:["none"],
        selector: row => row.site,
        
    }
];

useEffect(() => {
    if(audits) {
        setUpcomingAudits(audits.map(x => {
            return {
                id: x.id,
                createdOn: moment.unix(x.createdAt/1000).format("MM/DD/YYYY"),
                client: x.client.companyName,
                site: x.site.name,
                assignTo: x.assignee[0]?.name ? x.assignee[0]?.name : "not assigned",
                assignToId: x.assignee[0]?._id,
                }
        }))
    }
},[audits])

const [upcomingAudits, setUpcomingAudits] = useState(null)

const [newClientsData, setNewClientsData] = useState(null)

useEffect(() => {
    if(clients) {
        setNewClientsData(clients.map(client => {
            return {
                  id: client._id,
                  logo: client.image,
                  name: client.companyName,
                  contatName: client.contactPersonName,
                  site: client.companyAddress
            }
        }))
    }
},[clients])


  
    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    
const COLORS = ['#F12B72', '#55DD9C', "#92E52A"];
const COLORSCLASS = ['#FCB936', '#F12BC5', "#92E52A"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
      <>
    <text x={x} y={y} fill="white" style={{textShadow: "#000000 1px 0px 2px"}} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      <tspan x={x} y={y-10}>{`${(percent * 100).toFixed(0)}%`}</tspan>
      <tspan x={name == "Franchisee" ? x+40 : name == "Non-Franchisee" ? x-60 : x} y={y+10}>{name}</tspan>
    </text> 
    </>
  );
};


// update license

   
const [licenseModal, setLicenseModal] = useState(null)
const [freeReports, setFreeReports] = useState("")
const [licenseUser, setLicenseUser] = useState("")
const [licenseNotes, setLicenseNotes] = useState("")
const [expirationDate, setExpirationDate] = useState(null);
const [selectedLicense, setSelectedLicense] = useState(null)
const assignLicenseFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setLicenseModal(null)  
  updateLicense({
      variables:{
          _id: selectedLicense.id,
          user: licenseUser?.value
      }
  }).then(() => {
      setSelectedLicense(null)
      setLicenseUser("") 
      usersRefetch()
      licensesRefetch()
  
  })
  
}
const assignLicense = (row) => {
        setSelectedLicense(row)
        setLicenseModal("Assign License")
    }
const [
  addLicense,
  { data: addLicenseData },
] = useMutation(addLicenseMutation)

const [
  updateLicense,
  { data: updateLicenseData },
] = useMutation(updateLicenseMutation)

const updateLicenseFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setLicenseModal(null)  
  updateLicense({
      variables:{
          _id: selectedLicense.id,
          freeReports: freeReports,
          endDate: expirationDate,
          notes: licenseNotes,
          expired:false
      }
  }).then((res) => {
      setSelectedLicense(null)
      setLicenseUser("")
      usersRefetch()
      licensesRefetch()
  })
  }

  useEffect(() => {
    if(licenses) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
  },[licenses])
  
    return (
        <div className="adminContent">
            {returnToHomeScreen()}
            <div className="dashboardContainer">
            <AddLicenseModal 
            licenseModal={licenseModal}
            setLicenseModal={setLicenseModal}
            freeReports={freeReports}
            setFreeReports={setFreeReports}
            licenseNotes={licenseNotes}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate }
            setLicenseNotes={setLicenseNotes}
            licenseUser={licenseUser}
            setLicenseUser={setLicenseUser}
            updateLicenseFunc={updateLicenseFunc}
            setSelectedLicense={setSelectedLicense}
            assignLicenseFunc={assignLicenseFunc}
            auditAssignOptions={auditAssignOptions}
        />
                <div className="dashboardParent">
                    {user ? user?.user?.role == "admin" ? (
                        <>
                        <div className="tinnyContainer">
                <div className="dashboardCard" onClick={() => history.push("/admin/reports")}>
                    <div className="dashboardCardHeader">
                    reports Pending Review 
                    </div>
                    <div className="dashboardCardContent">
                    <DashboardTable
                        notTabs={true}
                        notTemplate={true}
                        notSearch={true}
                        loading={!reportsColumns}
                        accountColumns={reportsColumns}
                        accountData={pendingReports?.filter((x,i) => i < 5)}
                        accountType="Report"
                        />
                    </div>
                    { pendingReports?.length > 5 && <div className="dashboardCardFooter">
                    {pendingReports?.length - 5} more reports
                    </div>}
                    </div>
                    <div className="dashboardCard" onClick={() => history.push("/admin/users")}>
                    <div className="dashboardCardHeader">
                    low reports count
                    </div>
                    <div className="dashboardCardContent">
                    <DashboardTable
                        notTabs={true}
                        notTemplate={true}
                        notSearch={true}
                        loading={!usersLowCountColumns}
                        accountColumns={usersLowCountColumns}
                        accountData={usersLowCount?.filter((x,i) => i < 5)}
                        accountType="Count"
                    />
                    </div>
                    { usersLowCount?.length > 5 && <div className="dashboardCardFooter">
                    {usersLowCount?.length - 5} more users
                    </div>}
                    </div>
                    <div className="dashboardCard" onClick={() => history.push("/admin/licenses")}>
                    <div className="dashboardCardHeader">
                    licenses about to expire
                    </div>
                    <div className="dashboardCardContent">
                    <DashboardTable
                        notTabs={true}
                        notTemplate={true}
                        notSearch={true}
                        loading={!licensesExpired}
                        accountColumns={licensesColumns}
                        accountData={licensesExpired?.filter((x,i) => i < 5)}
                        accountType="Licenses"
                    />
                    </div>
                    { licensesExpired?.length > 5 && <div className="dashboardCardFooter">
                    {licensesExpired?.length - 5} more licenses
                    </div>}
                    </div>
                    </div>
                    <div className="tinnyContainer">            
                    <div className="dashboardCard downCard" style={{width:"40%"}} onClick={() => history.push("/admin/audits")}>
                    <div className="dashboardCardHeader">
                    upcoming audits
                    </div>
                    <div className="dashboardCardContent">
                    <DashboardTable
                        notTabs={true}
                        notTemplate={true}
                        notSearch={true}
                        loading={!upcomingAudits}
                        accountColumns={upcomingAuditsColumns}
                        accountData={upcomingAudits?.filter((x,i) => i<5)}
                        accountType="Upcoming"
                    />
                    </div> 
                    { upcomingAudits?.length > 5 && <div className="dashboardCardFooter">
                    {upcomingAudits?.length - 5} more audits
                    </div>}
                    </div>
                    <div className="dashboardCard downCard" style={{width:"20.7%"}} onClick={() => history.push("/admin/reports")}>
                    <div className="dashboardCardHeader">
                        report template type
                    </div>
                    <div className="dashboardCardContent">
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width="100%" height="100%">
                            <Pie
                                width="100%"
                                height="100%"
                                data={reportTemplateType}
                                isAnimationActive={false}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={135}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {reportTemplateType.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                </ResponsiveContainer>
                    </div>
                    </div>
                    { user?.user?.role !== "sub-user" || user?.user?.role !== "client" && <div className="dashboardCard downCard" style={{width:"20.0%"}} onClick={() => history.push("/admin/reports")}>
                    <div className="dashboardCardHeader">
                    users
                    </div>
                    <div className="dashboardCardContent">
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={"100%"} height={"100%"}>
                            <Pie
                                data={reportClass}
                                isAnimationActive={false}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={135}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {reportClass.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORSCLASS[index % COLORSCLASS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                </ResponsiveContainer>
                    </div>
                    </div>}
                        </div>
                        </>
                    ) : user?.user?.role !== "client" ? (
            <>
            <div className="tinnyContainer">            
                    <div onClick={() => history.push("/admin/reports")} className="dashboardCard todayNumber todayNumberContainer">
                    <div className="dashboardCardHeader">
                    Generated Reports
                    </div>
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {reports ? reports.length ? reports[reports.length - 1].count || reports.length : 0 : 0}
                    </div>
                    </div>
                    <div onClick={() => history.push("/admin/clients")} className="dashboardCard todayNumber todayNumberContainer">
                    <div className="dashboardCardHeader">
                    Clients
                    </div>
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {clients?.length || 0}
                    </div>
                    </div>
                    <div onClick={() => history.push("/admin/clients")} className="dashboardCard todayNumber todayNumberContainer">
                    <div  className="dashboardCardHeader">
                    Sites
                    </div> 
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {sites?.length || 0}
                    </div>
                    </div>
                    { user?.user?.licenseNumber?.length ? JSON.parse(user?.user?.licenseNumber)[0]?.endDate ? <div onClick={() => {
                        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
                        history.push({
                            pathname:"/admin/users",
                            state: {
                                license: true
                            }
                        })
                    }} className="dashboardCard todayNumber todayNumberContainer">
                    <div className="dashboardCardHeader">
                    License Expiration Date
                    </div>
                    <div className="todayNumber" style={{color:"#F12B2C", fontSize:"30px"}}>
                    {JSON.parse(user?.user?.licenseNumber)[0]?.endDate? moment(JSON.parse(user?.user?.licenseNumber)[0]?.endDate).format("MM/DD/YYYY") : "Not visible"}
                    </div>
                    </div> : null : null }                   
            </div>

            <div className="tinnyContainer">            
                    <div onClick={() => history.push("/admin/audits")} className="dashboardCard downCard" style={{width:"40%"}}>
                    <div className="dashboardCardHeader"> 
                    upcoming audits
                    </div>
                    <div className="dashboardCardContent">
                    <DashboardTable
                        notTabs={true}
                        notTemplate={true}
                        notSearch={true}
                        loading={!upcomingAudits}
                        accountColumns={upcomingAuditsColumns}
                        accountData={upcomingAudits?.filter((x,i) => i<5)}
                        accountType="Upcoming"
                    />
                    </div> 
                    { upcomingAudits?.length > 5 && <div className="dashboardCardFooter">
                    {upcomingAudits?.length - 5} more audits
                    </div>} 
                    </div>
                    <div onClick={() => history.push("/admin/clients")} className="dashboardCard downCard" style={{width:"20%"}}>
                    <div className="dashboardCardHeader">
                    recently added clients
                    </div>
                    <div className="dashboardCardContent">
                    <DashboardTable
                        notTabs={true}
                        notTemplate={true}
                        notSearch={true}
                        accountColumns={newClientsColumns}
                        accountData={newClientsData}
                        accountType="Upcoming"
                    />
                    </div>  
                    </div>
                    { user?.user?.role !== "sub-user" &&<div onClick={() => history.push("/admin/users")} className="dashboardCard downCard" style={{width:"20%"}}>
                    <div className="dashboardCardHeader">
                        users
                    </div>
                    <div className="dashboardCardContent">
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width="100%" height="100%">
                            <Pie
                                width="100%"
                                height="100%"
                                data={ [
        { name: 'You', value: usersCount || 0 },
        { name: 'Sub-User', value: subUsersCount || 0 }
      ]}
                                isAnimationActive={false}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={135}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                { [     
        { name: 'You', value: usersCount || 0 },
        { name: 'Sub-User', value: subUsersCount || 0}
      ].map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                </ResponsiveContainer>
                    </div>
                    </div>}
            </div>
            </>            
                    ) : user?.user?.role == "client" ? (

<>
            <div className="tinnyContainer">            
                    <div onClick={() => history.push("/admin/reports")} className="dashboardCard todayNumber todayNumberContainer">
                    <div className="dashboardCardHeader">
                    Generated Reports
                    </div>
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {reports ? reports.length ? reports[reports.length - 1].count || reports.length : 0 : 0}
                    </div>
                    </div>
                    <div onClick={() => history.push("/admin/clients")} className="dashboardCard todayNumber todayNumberContainer">
                    <div className="dashboardCardHeader">
                    Auditors
                    </div>
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {clients?.length || 0}
                    </div>
                    </div>
                    <div onClick={() => history.push("/admin/clients")} className="dashboardCard todayNumber todayNumberContainer">
                    <div  className="dashboardCardHeader">
                    Sites
                    </div> 
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {sites?.length || 0}
                    </div>
                    </div>
                    {<div className="dashboardCard todayNumber todayNumberContainer">
                    <div className="dashboardCardHeader">
                    Next Maintenance
                    </div>
                    <div className="todayNumber" style={{color:"#F12B2C", fontSize:"30px"}}>
                    {/* {JSON.parse(user?.user?.licenseNumber)[0]?.endDate? moment(JSON.parse(user?.user?.licenseNumber)[0]?.endDate).format("MM/DD/YYYY") : "Not visible"} */}
                    </div>
                    </div>} 
                                     
            </div>

            <div className="dashboardCard" style={{height:"700px", padding:"20px 30px", width:"100%"}}>            
            <div className="dashboardCardHeader" style={{marginBottom:"20px"}}>
                    Audits
                    </div>
                <MyCalendar audits={audits} />
            </div>
            </> ) : null : <DataSpinner small="l" />}
                    
                </div>

            </div>
        </div>
    )
}

export default AdminDashboard