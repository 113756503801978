import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import "./index.scss"
import { useDispatch } from "react-redux"
import { DataSpinner } from "../resuable/spinner/dataSpinner"
import { useLocation } from "react-router-dom"
import Faq from 'react-faq-component';

const Bot = (props) => {
 

const location = useLocation();
    const history = useHistory()
   
    const [user, setUser] = useState(null)
    const [route, setRoute] = useState(null)
    useEffect(() => {
      if(location) {
        if(location.state) {
          if(location?.state?.user) {
          setUser(location.state.user) 
        }
        if(location?.state?.route) {
          setRoute(location.state.route)
        } 
        } else {
        history.push("/admin") 
      }
      } 
      return () => window.history.replaceState({}, document.title)
    },[location]) 

    if(!user) {
      return <DataSpinner small="l" />
    }
    
    return (
        <div className="contactUs" style={{background:"white"}}>
            <div className="headerTitle" style={{color:"#1467b3"}}>
            <div style={{cursor:"pointer", display:"flex", alignItems:"center", width:"fit-content"}} onClick= { () => {                      
                    history.push(`${route || "/admin"}`)    
                    }}> 
                    <FontAwesomeIcon  
                        icon={faArrowLeft}
                        style={{marginRight: "1rem", fontSize:"1.8rem"}}
                    />
                    <span>{route ? "Back" : "Home"}</span>   
            </div> 
            </div>
            <div  
            className="contactUsContent"
            style={{display:"flex", height:"fit-content", justifyContent:"center"}}
            > 
            <div className="form-row" style={{flexDirection:"column", color:"black",  marginBottom:"50px"}}>
            <div className="form-collection" style={{display:"flex",justifyContent:"center",  width:"100%", fontWeight:"700", color:"rgb(35,61,108)", fontSize:"32px"}}>
            {/* style={{transform: `rotate(${180 - ((180)/a.length*(i + 1))}deg) translateY(30px)`, background: el.passed ? "#6DB240" : "#EF1111", borderRadius:"50%", width:"10px", height:"10px", position:"absolute"}} */}
              <div style={{height:"700px", display:"flex", justifyContent:"center", alignItems:"center", position:"relative"}}>
                <div><img className="bigSizwHover" onClick={() => window.open("https://www.walkwaymg.com/pages/step?ref=5e31ccf09bf71", "_blank")} src="https://i.ibb.co/Tv81NPP/STEP-app-Logo.png" style={{height:"300px"}} /></div>

                <div onClick={() => window.open("https://www.walkwaymg.com/pages/unt?ref=5e31ccf09bf71", "_blank")} style={{ position:"absolute", transform: `translateX(0px) translateY(-280px)`}}><img className="bigSizwHover" src="https://i.ibb.co/PDGPR5k/UNT-Logo-in-Web.png" style={{height:"150px"}} /></div>
                <div onClick={() => window.open("https://www.walkwaymg.com/pages/boost?ref=5e31ccf09bf71", "_blank")} style={{ position:"absolute", transform: `translateX(-290px) translateY(-170px)`}}><img className="bigSizwHover" src="https://i.ibb.co/X8XQDC3/Boost-Solutions-Logo.png" style={{height:"90px"}} /></div>
                <div onClick={() => window.open("https://www.walkwaymg.com/pages/catl?ref=5e31ccf09bf71", "_blank")} style={{ position:"absolute", transform: `translateX(-290px) translateY(120px)`}}><img className="bigSizwHover" src="https://i.ibb.co/WBxmPYX/CATL-Logo.png" style={{height:"170px"}} /></div>
                <div onClick={() => window.open("https://www.walkwaymg.com/pages/bot3000?ref=5e31ccf09bf71", "_blank")} style={{ position:"absolute", transform: `translateX(290px) translateY(120px)`}}><img className="bigSizwHover" src="https://i.ibb.co/1qJ9Mfr/BOT-3000-E-Logo1-1.png" style={{height:"150px"}} /></div>
                <div onClick={() => window.open("https://www.safespaceingenuity.com/", "_blank")} style={{ position:"absolute", transform: `translateX(320px) translateY(-170px)`}}><img className="bigSizwHover" src="https://i.ibb.co/FsZwXwQ/Safe-Space-logo.png" style={{height:"120px"}} /></div>
                <div onClick={() => window.open("https://www.walkwaymg.com/pages/aia-continuing-education-course", "_blank")} style={{ position:"absolute", transform: `translateX(0px) translateY(260px)`}}><img className="bigSizwHover" src="https://i.ibb.co/6Rfhnqt/AIA-COURSE-Page-Header-600x600.png" style={{height:"120px"}} /></div>



              </div> 

            </div> 
            </div>
            
               <div className="form-row" style={{flexDirection:"column", color:"black",  marginBottom:"50px"}}>
            <div className="form-collection" style={{width:"100%", fontWeight:"700", color:"rgb(35,61,108)", fontSize:"32px"}}>
              Statistics regarding Slip and Falls
            </div>
            <ul style={{listStylePosition:"outside"}}>
            {[
              "Slip and Falls account for 50% of all accidental deaths at home.",
              "Over 9 million adults and children visited the emergency room in 2019 for fall related injuries.",
              "According to a CDC Report in 2020, the costs for fall related injuries totaled over 361 BILLION DOLLARS! This includes medical costs, work loss costs and quality of life lost costs.",
              "Over 35,000 people died in a slip and fall accident in the United States in 2019, according to the National Safety Council.",
              "Slip and Fall incidents account for more severe injuries in the United States than motor vehicle accidents.",
              "Slip and Fall injuries remain the leading cause of non-fatal medical treatment in the United States.",
              "Slip and Fall remain the leading cause of injury-related deaths in seniors over sixty-four years of age.",
              "More traumatic brain injuries are caused by falls than any other contributing factor.",
              "The National Safety Council ranks falling with the highest odds of dying (1 in 127), ranking sixth in leading cause of death in the United States 60% of all fall-related deaths occur to individuals seventy-five years and older.",
            ].map(x => (
              <div className="form-collection" style={{width:"100%", fontWeight:"600", fontSize:"16px"}}>
              <li>{x}</li>
              </div>
            ))
            } 
            </ul>
            </div>

            <div className="form-row" style={{flexDirection:"column", color:"black"}}>
            <div className="form-collection" style={{width:"100%", fontWeight:"700", color:"rgb(35,61,108)", fontSize:"32px"}}>
              Slip and Falls in Workplace
            </div>
                        <ul style={{listStylePosition:"outside"}}>

            {[
              "OSHA revealed nearly 230,000 reports of non-fatal accidents involving a slip and fall in 2019.",
              "Nearly 900 fatal work-related slips, trips and falls were reported in all occupational sectors.",
              "More than 60% of all slip and fall accidents occurred in the retail trade, wholesale, and services industries.",
              "Slip and Fall accidents accounted for more than 25% of reported work injuries in 2019.",
              "Nearly 25% of all employee slip and fall injuries on the job required over 31 days of recovery before returning to work.",
              "The Industrial Safety & Occupational Health Markets revealed that over 85% of workers' compensation claims involved employees falling on slippery surfaces.",
              "Insurance statistics show that 16% of all workers' compensation benefits are associated with slip and fall accidents on the job and represent over 25% of costs.",
              "American Trucking Association statistics show that slip and falls are the leading cause of compensable injuries throughout the trucking industry.",
              "Injuries from slip and fall accidents are the primary cause of missing work.",
              "Approximately 48,000 individuals required days off from work in 2019 due to severe injuries from falling.",
            ].map(x => (
              <div className="form-collection" style={{width:"100%", fontWeight:"600", fontSize:"16px"}}>
              <li>{x}</li>
              </div>
            ))
            }             </ul>

            </div>

            <div className="form-row" style={{flexDirection:"column", color:"black"}}>
            <div className="form-collection" style={{width:"100%", fontWeight:"700", color:"rgb(35,61,108)", fontSize:"32px"}}>
            <div className='label-statement' style={{paddingBottom:"10px", marginTop:"50px"}}>
            Sources for Slip and Fail Statistics
            </div>
            </div>
           
            <div className="form-row" style={{flexDirection:"row", color:"black", padding:"0 100px"}}>

              <div className="form-collection" style={{width:"100%", fontWeight:"600", fontSize:"16px"}}>
              <li className="asLink" onClick={() => window.open("https://www.cdc.gov/injury/wisqars/index.html", "_blank")}>Centers of Disease Control and Prevention (CDC)</li>
              <li className="asLink" onClick={() => window.open("https://injuryfacts.nsc.org/all-injuries/overview/", "_blank")}>National Safety Council</li>
              <li className="asLink" onClick={() => window.open("https://www.cpsc.gov/", "_blank")}>Consumer Product Safety Commission</li>
              <li className="asLink" onClick={() => window.open("https://www.nia.nih.gov/", "_blank")}>National Institute of Aging</li>
              </div>
              <div className="form-collection" style={{width:"100%", fontWeight:"600", fontSize:"16px"}}>
              <li className="asLink" onClick={() => window.open("https://www.osha.gov/stop-falls", "_blank")}>Occupational Safety and Health Administration (OSHA)</li>
              <li className="asLink" onClick={() => window.open("https://www.bls.gov/charts/census-of-fatal-occupational-injuries/fatal-occupational-injuries-by-event-drilldown.html", "_blank")}>US Bureau of Labor Statistics</li>
              <li className="asLink" onClick={() => window.open("https://www.trucking.org/", "_blank")}>American Trucking Association</li>
              <li className="asLink" onClick={() => window.open("https://nfsi.org/nfsi-research/quick-facts/", "_blank")}>National Floor Safety Institute (NFSI)</li>
              </div>
          
            </div>
            </div>

            </div>
        </div>
    )    
}

export default Bot