import { faTrashAlt, faPen, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { FormGroup, Label, Input } from "reactstrap";
import AddAuditModal from "../../../resuable/modals/addAuditModal";
import "./auditHeader.scss"
import Dropdown from "react-dropdown";
import add from "../../../../assests/icons/add.svg"
import arrowUp from "../../../../assests/icons/arrowUp.svg"
import Select from 'react-select'

const AuditHeader = (props) => {

  const {
    addAuditModal,
    setAddAuditModal,
    auditClientsOptions,
    auditClient,
    setAuditClient,
    auditSitesOptions,
    auditSite,
    setAuditSite,
    auditAssignOptions,
    auditAssignTo,
    setAuditAssignTo,
    addAuditFunc,
    updateAuditFunc,
    allAdminsfilter,
    setAllAdmins,
    allCompaniesFilter,
    setAllCompanies, 
    allSitesFilter,
    setAllSites,
    companyFilter,
    setCompanyFilter,
    siteFilter,
    setSiteFilter,
    createdByFilter,
    setCreatedByFilter,
  } = props

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,  
    }),
  
    control: (provided, { selectProps: { width }}) => ({
      ...provided,
      width: "216px",
      height: "36px",
      margin: "0 .5rem",
    }),
  
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }
  
    return (
        <div className="detailsContent auditHeaderDetailsContent">

                  <AddAuditModal 
                    addAuditModal={addAuditModal}
                    setAddAuditModal={setAddAuditModal}
                    auditClientsOptions={auditClientsOptions}
                    auditClient={auditClient}
                    setAuditClient={setAuditClient}
                    auditSitesOptions={auditSitesOptions}
                    auditSite={auditSite}
                    setAuditSite={setAuditSite}
                    auditAssignOptions={auditAssignOptions}
                    auditAssignTo={auditAssignTo}
                    setAuditAssignTo={setAuditAssignTo}
                    addAuditFunc={addAuditFunc}
                    updateAuditFunc={updateAuditFunc}
                  />
                <div className="contentLeft auditFiltersContainer">
                <Select
                  options={allCompaniesFilter?.filter((c, index, chars) => {
                    return c && chars.indexOf(c) == index;
                  }).map(x=> { return {label:x, value:x}})}
                  isSearchable={true}
                  // controlClassName="form-dropdown filterAuditInput" 
                  placeholder={"Company"}
                  styles={customStyles}
                  // arrowClosed={<span className="arrow-closed arrowI" ><img
                  // style={{height:"8px"}}
                  // className="routeIcon forceRight"
                  // src={arrowUp}
                  // /></span>}
                  // arrowOpen={<span className="arrow-open arrowI" ><img
                  // style={{height:"8px"}}
                  // className="routeIcon forceRight"
                  // src={arrowUp}
                  // /></span>}
                  value={companyFilter}
                  onChange={(e) => {
                   setSiteFilter("")
                   setCreatedByFilter("")
                    setCompanyFilter(e)
                  }}
                  
                />
                <Select
                  options={allSitesFilter?.filter((c, index, chars) => {
                    return c && chars.indexOf(c) == index;
                  }).map(x=> { return {label:x, value:x}})}
                  isSearchable={true}
                  styles={customStyles}
                  placeholder={"Site"}
                  value={siteFilter}
                  // arrowClosed={<span className="arrow-closed arrowI" ><img
                  // style={{height:"8px"}}
                  // className="routeIcon forceRight"
                  // src={arrowUp}
                  // /></span>}
                  // arrowOpen={<span className="arrow-open arrowI" ><img
                  // style={{height:"8px"}}
                  // className="routeIcon forceRight"
                  // src={arrowUp}
                  // /></span>}
                  onChange={(e) => {
                    setCreatedByFilter("")
                    setSiteFilter(e)
                  }}

                />
                <Select
                  options={allAdminsfilter?.filter((c, index, chars) => {
                    return c && chars.indexOf(c) == index;
                  }).map(x=> { return {label:x, value:x}})}
                  isSearchable={true}
                  styles={customStyles}
                  placeholder={"Created by"}
                  // arrowClosed={<span className="arrow-closed arrowI" ><img
                  // style={{height:"8px"}}
                  // className="routeIcon forceRight"
                  // src={arrowUp}
                  // /></span>}
                  // arrowOpen={<span className="arrow-open arrowI" ><img
                  // style={{height:"8px"}}
                  // className="routeIcon forceRight"
                  // src={arrowUp}
                  // /></span>}
                  value={createdByFilter}
                  onChange={(e) => {
                    setCreatedByFilter(e)

                  }}
                />
               {createdByFilter || siteFilter || companyFilter ? <div style={{color:"#EF1111", cursor:"pointer", margin:"0 5px", height:"100%", padding:"3px"}} onClick={() => {
                  setCreatedByFilter("")
                  setSiteFilter("")
                  setCompanyFilter("")
                }}>reset</div> : null}
                </div>
                <div className="contentRight">
                        <div className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          marginRight: "1rem",
                          fontSize:"14",
                          fontWeight:"bold"
                      }} 
                      className="auth-button addUser floatAddUser  floatButton"
                      onClick={() => setAddAuditModal("Audit")}
                      >
                            <span style={{ marginRight:".5rem"}}>Add New Audit </span> <img style={{ marginLeft:".5rem"}} src={add} />
                        </div>
                        </div>
                </div>
              </div>
    )
}

export default AuditHeader