import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";
import "./avatarModal.scss"

import { DataSpinner } from "../../../spinner/dataSpinner";
import useAwsCredentials from "../../../../hooks/useAws";

function AvatarModal({images, setImages, progress, setProgress}) {

  const { credentials, loading, error, fetchCredentials } = useAwsCredentials();  // Use the custom hook to fetch credentials


  const [selectedFile, setSelectedFile] = useState(null);

  const uploadFile = async (file) => {

    let myBucket = await fetchCredentials()


    if(myBucket) {
      const params = {
        ACL: 'public-read',
          Body: file.file,
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: file.file.name.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "")
      };

      myBucket.putObject(params) 
          .on('httpUploadProgress', (evt) => {
              setProgress(Math.round((evt.loaded / evt.total) * 100))
          })
          .on("complete", (evt) => {
            file.url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${file.file.name.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "")}`
            setImages([file])
          })
          .send((err) => {
              if (err) console.log(err)
          })
  } else {
    alert("Loading.")
    
  }
  }
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    uploadFile(imageList[0])
    setImages(imageList)
  };

  return (
    <div>
      <ImageUploading        
        value={images}
        onChange={onChange} 
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "jpeg", "png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div
              style={{ cursor:"pointer", color: isDragging ?"#EF1111": null, border:"1px solid #e0e0e0", padding:"7px", borderRadius:"50%",width:"80px", height:"80px", display:"flex", justifyContent:"center", alignItems:"center" }}
              onClick={onImageUpload}
              {...dragProps}
            >
              {images?.length == 0 && <span style={{color:"#e0e0e0", fontWeight:"bold"}}>Add</span>}
           
            {/* <div onClick={(e) => {
              e.stopPropagation() 
              onImageRemoveAll()}}>Remove all images</div> */}
            {imageList?.length > 0 && <div style={{ display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                {imageList?.map((image, index) => (
                  <div style={{ width:"80px", height:"80px", borderRadius:"50%",overflow:"hidden", display:"flex", justifyContent:"center", alignItems:"flex-start", padding:"5px"}} key={index} className="image-item">
                     {image.url ? <> <img onClick={(e) =>{
                        e.stopPropagation()
                        onImageUpdate(index)}} src={image?.url?.replace("#", "").replace("_", "").replace("/", "")} alt="" height={"100"} width={"100"} style={{objectFit:"cover"}} /> </> : <DataSpinner />}
                  </div>
                ))} 
            </div>}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
 export default AvatarModal
