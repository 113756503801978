import React, { useState, useEffect } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleButton from 'react-toggle-button'
import {DataSpinner} from "../../spinner/dataSpinner"
import Dropdown from "react-dropdown";
import Select from 'react-select'

const AddSiteModal = (props) => {
    const {
        selectedClient,
        siteModal,
        setSiteModal,
        setSiteName,
        siteName,
        setSiteEmail, 
        siteEmail,
        addSiteFunc,
        emailAlreadyRegistered,
        invalidEmail,
        setEmailAlreadyRegistered,
        setSiteContactPersonPhoneNumber,
        siteContactPersonPhoneNumber,
        setSiteAddress,
        siteAddress,
        city,
        setCity,
        country,
        setCountry,
        state,
        zip, 
        setZip,
        setState,
        siteContactPersonName,
        setSiteContactPersonName,
        updateSiteFunc

    } = props




const [stateTyping, setStateTyping] = useState("")
const [countryTyping, setCountryTyping] = useState("")
const [temporaryState, setTemporaryState] = useState("")

useEffect(() => {
if(state) {
 setStateTyping(state)
} else {
setStateTyping("")
}
},[state]) 

useEffect(() => {
if(country) {
if(country !== "United States" && country !== "Canada" && country !== "Mexico") {
setTemporaryState("Other")
}
 setCountryTyping(country)
} else {
setCountryTyping("")
}
},[country]) 

 

    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        position:"aboslute",
        top:-110
      }),
    
      control: (provided, { selectProps: { width }}) => ({
        ...provided,
        margin:"10px 0"
      }),
    
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      }
    }
  
  
    const formatNumber = (num) => {
      let newNum = num.split("")?.filter((x,i) => i < 10)
      return newNum.length == 1 ? `(${newNum[0]}` : newNum.length == 2 ? `(${newNum[0]}${newNum[1]}` : newNum.length == 3 ? `(${newNum[0]}${newNum[1]}${newNum[2]}` : newNum.length == 4 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}` : newNum.length == 5 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}` : newNum.length == 6 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]}` : newNum.length == 7 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}` : newNum.length == 8 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}${newNum[7]}` : newNum.length == 9 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}${newNum[7]}${newNum[8]}` : newNum.length == 10 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}${newNum[7]}${newNum[8]}${newNum[9]}` : ""
    
    }
    
  const phoneNumberTyping = (e) => {
    let currentTyping = e.currentTarget.value;
    let string = currentTyping.replace(/\D/g, "")
    currentTyping = formatNumber(string)
    setSiteContactPersonPhoneNumber(currentTyping)
  };

    const [sameLike,setSameLike] = useState(false)
    const [loadingClient,setLoadingClient] = useState(false)

    const loadClient = () => {
      setSiteName(selectedClient.company)
      setSiteContactPersonName(selectedClient.contact)
      setSiteContactPersonPhoneNumber(selectedClient.phone)
      setSiteEmail(selectedClient.email)
      setSiteAddress(selectedClient.address.split(", ")[0])
      setCity(selectedClient.address.split(", ")[1])
      setState(selectedClient.address.split(", ")[2])
      setCountry(selectedClient.address.split(", ")[3])
      setZip(selectedClient.zip)
      setZipError(false)
    }
    const resetClient = () => {
setSiteName && setSiteName("")
setSiteContactPersonName && setSiteContactPersonName("")
setSiteContactPersonPhoneNumber && setSiteContactPersonPhoneNumber("")
setSiteEmail && setSiteEmail("")
setSiteAddress && setSiteAddress("")
setCity && setCity("")
setState && setState("")
setCountry && setCountry("")
setZip&& setZip("")
setZipError(true)
setTemporaryState("")
    }

    useEffect(() => { 
      if(sameLike) {
        loadClient()
      } else {
        resetClient()
      }
    },[sameLike]) 

    
    const [zipError, setZipError] = useState(true)
 
    const isUSAZipCode = (str) => {
      return /^\d{5}(-\d{4})?$/.test(str);
    }

    const isCanadaZipCode = (str) => {
    return  /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/.test(str);
    }
    
    const validateInput = (e) => {
      if(countryTyping !== "Canada") {
        if (isUSAZipCode(e.target.value)) {
        setZipError(false)
      } else { 
       setZipError(true)
      }  
      } else {
        if (isCanadaZipCode(e.target.value)) {
          setZipError(false)
        } else {
         setZipError(true)
        } 
      }
      
    }
    


useEffect(() => {
  if(siteModal && siteModal.includes("Edit")) {
    setZipError(false)
  }
},[siteModal])

  return(
<Modal
        isOpen={siteModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >       
              {siteModal && (
               <div className="modal task-modal" style={{height:"fit-content", width:"40vw"}}>
               
          {!loadingClient ?
                <>
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>{siteModal.includes("Edit") ? siteModal : `Add ${siteModal}`}</h1>
         
                                    
          <div className="form-group-container task-form" style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}}>
          <div className='label-statement' style={{padding:"0 1rem"}}>
          A Site is the exact address where the slip testing will take place. This can be an address or a name.<br/>For example CBS, Dom's Restaurant 375 Broadway
                    </div>
          {!siteModal.includes("Edit") && <div className="form-row">
            <div className="form-collection" style={{flexDirection:"row", display: "flex", justifyContent:"space-between"}}>
              <div className="form-label" style={{width:"fit-content", color:"#404040"}}>Information same as client info</div>
              <ToggleButton
                inactiveLabel={"No"}
                activeLabel={"Yes"}
                value={sameLike}
                onToggle={(value) => {
                  setSameLike(!value)
                }} />
            </div>
            </div> }   
            <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Site Name<span style={{color:"red"}}> *</span></span></div>
              <input
              placeholder='Site Name'
                onChange={(e) => setSiteName(e.target.value)}
                value={siteName}
                type="text" 
                className="form-input"
              />
            </div>
            </div>
            <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Site Contact Person<span style={{color:"red"}}> *</span></span></div>
              <input
              placeholder='Site Contact Person'
                onChange={(e) => setSiteContactPersonName(e.target.value)}
                value={siteContactPersonName}
                type="text"
                className="form-input"
              />
            </div>
            </div>
            <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Site Contact Phone Number<span style={{color:"red"}}> *</span></span></div>
              <input 
                placeholder='(XXX) XXX - XXXX'
                onChange={phoneNumberTyping}
                onBlur={(e) => setSiteContactPersonPhoneNumber(e.target.value)}
                value={siteContactPersonPhoneNumber}
                maxLength="16"
                type="text"
                className="form-input"
              />
            </div>
            </div>
            <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Site Contact Email<span style={{color:"red"}}> *</span></span></div>
              <input
              placeholder='Site Contact Email'
                onChange={(e) => {
                  
                  setSiteEmail(e.target.value.toLowerCase())
                  setEmailAlreadyRegistered(false)

                }}
                value={siteEmail}
                type="text"
                className="form-input"
              />
              {emailAlreadyRegistered ? (
        <div className="auth-error" style={{textTransform:"none"}}>
          This email has already been used.
        </div>
      ) : invalidEmail ? (
        <div className="auth-error" style={{textTransform:"none"}}>
          Please enter a valid email address.
        </div>
      ) : null }
            </div>
            </div>           
              
            
            <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Country<span style={{color:"red"}}> *</span></span></div>
              <Select 
              options={[
                {label:"United States", value:"United States"},
                {label:"Canada", value:"Canada"},
                {label:"Mexico", value:"Mexico"},
                {label:"Other", value:"Other"},

              ]}
              isSearchable={true}
              styles={customStyles}

              value={temporaryState ? {label: temporaryState , valye: temporaryState} : country ? {label: country, value: country} : ""}
              onChange={(choice) =>{
                setZip && setZip("")
                setZipError(false)
                if(choice.value == "Other") {
                  setCountry("")
                  setCountryTyping("")
                  setTemporaryState("Other")
                  setStateTyping("")
                setState("")
               } else {
                setTemporaryState("")
                setStateTyping("")
                setState("")
                setCountry(choice.value)
               }
              }
              }
              placeholder={"User Contact Country"}
              />  
          </div>
            
        </div>  

        {temporaryState && <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Other Country<span style={{color:"red"}}> *</span></span></div>
              <input
              placeholder='User Contact Country'
              onChange={(e) => {
                setCountryTyping(e.target.value)}}
              onBlur={() => setCountry(countryTyping)} 
              value={countryTyping}
              type="text"
              className="form-input"
            />  
          </div>
            
        </div> }

        <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>State<span style={{color:"red"}}> *</span></span></div>
              {!temporaryState ? <Select
              isDisabled={!country} 
              options={country == "United States" ? [
                {label: "Alabama" , value: "Alabama"},
                {label: "Alaska" , value: "Alaska"},
                {label: "Arizona" , value: "Arizona"},
                {label: "Arkansas" , value: "Arkansas"},
                {label: "California" , value: "California"},
                {label: "Colorado" , value: "Colorado"},
                {label: "Connecticut" , value: "Connecticut"},
                {label: "Delaware" , value: "Delaware"},
{label: "District of Columbia", value: "District of Columbia"},
                {label: "Florida" , value: "Florida"},
                {label: "Georgia" , value: "Georgia"},
                {label: "Hawaii" , value: "Hawaii"},
                {label: "Idaho" , value: "Idaho"},
                {label: "Illinois" , value: "Illinois"},
                {label: "Indiana" , value: "Indiana"},
                {label: "Iowa" , value: "Iowa"},
                {label: "Kansas" , value: "Kansas"},
                {label: "Kentucky" , value: "Kentucky"},
                {label: "Louisiana" , value: "Louisiana"},
                {label: "Maine" , value: "Maine"},
                {label: "Maryland" , value: "Maryland"},
                {label: "Massachusetts" , value: "Massachusetts"},
                {label: "Michigan" , value: "Michigan"},
                {label: "Minnesota" , value: "Minnesota"},
                {label: "Mississippi" , value: "Mississippi"},
                {label: "Missouri" , value: "Missouri"},
                {label: "Montana" , value: "Montana"},
                {label: "Nebraska" , value: "Nebraska"},
                {label: "Nevada" , value: "Nevada"},
                {label: "New Hampshire" , value: "New Hampshire"},
                {label: "New Jersey" , value: "New Jersey"},
                {label: "New Mexico" , value: "New Mexico"},
                {label: "New York" , value: "New York"},
                {label: "North Carolina" , value: "North Carolina"},
                {label: "North Dakota" , value: "North Dakota"},
                {label: "Ohio" , value: "Ohio"},
                {label: "Oklahoma" , value: "Oklahoma"},
                {label: "Oregon" , value: "Oregon"},
                {label: "Pennsylvania" , value: "Pennsylvania"},
                {label: "Rhode Island" , value: "Rhode Island"},
                {label: "South Carolina" , value: "South Carolina"},
                {label: "South Dakota" , value: "South Dakota"},
                {label: "Tennessee" , value: "Tennessee"},
                {label: "Texas" , value: "Texas"},
                {label: "Utah" , value: "Utah"},
                {label: "Vermont" , value: "Vermont"},
                {label: "Virginia" , value: "Virginia"},
                {label: "Washington" , value: "Washington"},
                {label: "West Virginia" , value: "West Virginia"},
                {label: "Wisconsin" , value: "Wisconsin"},
                {label: "Wyoming" , value: "Wyoming"},
              ].sort((a, b) => a.label.localeCompare(b.label)) : country == "Mexico" ? [
                {label : "Aguascalientes", value: "Aguascalientes"},
                {label : "Baja California", value: "Baja California"},
                {label : "Baja California Sur", value: "Baja California Sur"},
                {label : "Campeche", value: "Campeche"},
                {label : "Coahuila", value: "Coahuila"},
                {label : "Colima", value: "Colima"},
                {label : "Chiapas", value: "Chiapas"},
                {label : "Chihuahua", value: "Chihuahua"},
                {label : "Durango", value: "Durango"},
                {label : "Mexico City", value: "Mexico City"},
                {label : "Guanajuato", value: "Guanajuato"},
                {label : "Guerrero", value: "Guerrero"},
                {label : "Hidalgo", value: "Hidalgo"},
                {label : "Jalisco", value: "Jalisco"},
                {label : "Michoacan", value: "Michoacan"},
                {label : "Morelos", value: "Morelos"},
                {label : "Nayarit", value: "Nayarit"},
                {label : "Nuevo León", value: "Nuevo León"},
                {label : "Oaxaca", value: "Oaxaca"},
                {label : "Puebla", value: "Puebla"},
                {label : "Queretaro", value: "Queretaro"},
                {label : "Quintana Roo", value: "Quintana Roo"},
                {label : "San Luis Potosi", value: "San Luis Potosi"},
                {label : "Sinaloa", value: "Sinaloa"},
                {label : "Sonora", value: "Sonora"},
                {label : "Tabasco", value: "Tabasco"},
                {label : "Tamaulipas", value: "Tamaulipas"},
                {label : "Tlaxcala", value: "Tlaxcala"},
                {label : "Veracruz", value: "Veracruz"},
                {label : "Yucatán", value: "Yucatán"},
                {label : "Zacatecas", value: "Zacatecas"},
            ].sort((a, b) => a.label.localeCompare(b.label)) : country == "Canada" ? [
              {label: "Alberta" , value: "Alberta"},
              {label: "British Columbia" , value: "British Columbia"},
              {label: "Manitoba" , value: "Manitoba"},
              {label: "New Brunswick" , value: "New Brunswick"},
              {label: "Newfoundland and Labrador" , value: "Newfoundland and Labrador"},
              {label: "Northwest Territories" , value: "Northwest Territories"},
              {label: "Nova Scotia" , value: "Nova Scotia"},
              {label: "Nunavut" , value: "Nunavut"},
              {label: "Ontario" , value: "Ontario"},
              {label: "Prince Edward Island" , value: "Prince Edward Island"},
              {label: "Quebec" , value: "Quebec"},
              {label: "Saskatchewan" , value: "Saskatchewan"},
              {label: "Yukon" , value: "Yukon"},
          ].sort((a, b) => a.label.localeCompare(b.label)) : []}
              isSearchable={true}
              styles={customStyles}
              value={state ? {label: state, value: state} : ""}
              onChange={(choice) =>{
              setState(choice.value)
              }
              }
              placeholder={"User Contact State"}
              />  :  <input
              placeholder='User Contact State'
              onChange={(e) => setStateTyping(e.target.value)}
              onBlur={() => setState(stateTyping)}
              value={stateTyping}
              type="text"
              className="form-input"
            /> }
          </div>
            
        </div> 

        <div className="form-row">
            <div className="form-collection">
            <div className="form-label"><span>Site Address<span style={{color:"red"}}> *</span></span></div>
              <input
              placeholder='Site Address'
              onKeyDown={(e) => {
                if(e.keyCode !== 188) {
                  return e.keyCode
                } else {
                  e && e.preventDefault()
                }
              }}
                onChange={(e) => setSiteAddress(e.target.value)}
                value={siteAddress}
                type="text"
                className="form-input "
              />
          </div>
          </div>
          <div className="form-row">
          <div className="form-collection">
              <div className="form-label"><span>City<span style={{color:"red"}}> *</span></span></div>
              <input
              placeholder='City'
                onChange={(e) => setCity(e.target.value)}
                value={city}
                type="text"
                className="form-input"
              />
              </div>
              </div>
              <div className="form-row">
          <div className="form-collection">
               <div className="form-label"><span>Zip code<span style={{color:"red"}}> *</span></span>
               {zipError && zip?.length ? (
        <div className="auth-error" style={{textTransform:"none"}}>
          Pleae enter a valid ZIP code
        </div>
      ) : null }
               </div>
              <input
                placeholder='Site Zip Code'
                onKeyUp={(e) => validateInput(e)}
                onChange={(e) => setZip(e.target.value)}
                value={zip}
                type= { country == "Canada" ? "text" : "number" }
                maxLength={ country == "Canada" ? "7" : "6" }
                minLength={ country == "Canada" ? "7" : "6" }
                className="form-input"
              />
          </div>
          </div>
            
          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {
                  setSiteModal(null)
                  setSiteName("")
                  setSiteEmail("")
                  setEmailAlreadyRegistered("")
                  setSiteAddress("")
                  setSiteContactPersonName("")
                  setSiteContactPersonPhoneNumber("")
                  setCity("")
                  setState("")
                  setZip && setZip("")
                  setSameLike(false)
                  setLoadingClient(false)
                  setTemporaryState("")
                }}
            >

            Cancel

            </div>
            <div
            style={{margin:".5rem"}}
              className={`${siteName && siteEmail && !zipError && !emailAlreadyRegistered && siteContactPersonName && siteContactPersonPhoneNumber && siteAddress && city && state ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={siteName && siteEmail && !zipError && siteAddress && siteContactPersonName && siteContactPersonPhoneNumber && city && state ? siteModal.includes("Edit") ? () => {
                setTemporaryState("")
                updateSiteFunc()
              } : () => {
                setSameLike(false)
                setLoadingClient(false)
                addSiteFunc()
                setTemporaryState("")
               } : null }
            >
              {siteModal.includes("Edit") ? "Update" : "Add"}
            </div>
          </div> </> : (
            <DataSpinner />
          )}
        </div>)}
      </Modal>
  )}

  export default AddSiteModal
       