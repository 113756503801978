import { gql } from "apollo-boost";

const getUserQuery = gql`
  query(
    $_id: ID
    $name: String
    $email: String
    $phoneNumber: String
    $owner: ID
  ) {
    user(
    _id: $_id
    name: $name
    email: $email
    phoneNumber: $phoneNumber
    owner: $owner
     
    ) {
      _id
      zip
      name
      email
      letter
      wmg
      phoneNumber
      certificateExpiry
      status
      active
      client
      role
      image
      certificate
      companyName
      adminType
      franchiseStatus
      franchiseTerritory
      licenseNumber
      owner
      userRole
      surface
      companyAddress
      notification {
        _id
        new
        message
        users
        createdAt
      }
      notifications {
        _id
        new
        message
        users
        createdAt
      }

    }
  }
`;

export default getUserQuery;
