const SELECTED_AUDIT = "SELECTED_AUDIT";
const SELECTED_AUDIT_RESET = "SELECTED_AUDIT_RESET";

const selectedAuditReducer = (state = { selectedAudit: null }, action) => {
  switch (action.type) {
    case SELECTED_AUDIT:
      return { ...state, selectedAudit: action.audit };
    case SELECTED_AUDIT_RESET:
      return { ...state, selectedAudit: null };
    default:
      return { ...state };
  }
};

export default selectedAuditReducer;