import React, { useEffect, useRef, useState } from 'react'
import Modal from "react-modal"
import "../../../../../assests/styles/Modal.scss"
import "./index.scss"
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ACTION_SELECTED_TESTS from '../../../../../actions/SelectedTests/ACTION_SELECTED_TESTS';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'
import { DataSpinner } from '../../../spinner/dataSpinner';
import WarningModal from '../../warningModal';
import moment from 'moment';
import download from "../../../../../assests/icons/download.svg"
import archive from "../../../../../assests/icons/archive.svg"
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE';
import { convertArrayToCSV } from'convert-array-to-csv';
import AWS from 'aws-sdk'
import ACTION_LOADING_SPINNER_RESET from '../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET';
import ConfirmDeleteModal from '../../confirmDeleteModal';
import Dropdown from "react-dropdown";


const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
}) 

const FileUploaderModal = (props) => {
  const dispatch = useDispatch()
  const loadingSpinnerActive = useSelector(
    (state) => state.loadingSpinnerActive.loading_spinner
  );
  
  const {
    pendulum,
    addNewTest,
    setAddNewTest,
    addExtraData,
    setFirstTimeOnFly,
    resetActiveTest,
    areas,
    mode,
    setMode,
    extraDataArr,
    oneLocation,
    firstTimeOnFly,
    active,
    fullCategory,
    setFullCategory,
    setActive,
    contextArea,
    contextLocation,
    isDisconnected,
    connectToDeviceAndSubscribeToUpdates,
    getPdfFromBot,
    pullingDataActive,
    setMyState,
    setFinalData,
    setPullingDataActive,
    setCurrentTest,
    pdfFile,
    setPdfFile,
    matchedTest,
    convertuploadedPdf, 
    setFile,
    setFileError,   
    pdfFileNotFound,
    setPdfFileNotFound,
    contextCategory,
    cleanCondition,
    setCleanCondition,
    file,
    fileError,
    selectedAudit,
    setWarningModal,
    warningModal,
    pdfImages,
setPdfImages,
newPdfImages,
setNewPdfImages,
exactData,
setExactData,
matchedData, 
setMatchedData,
singleRuns,
setSingleRuns

} = props

const [mistake , setMistake] = useState(false)
const [onFlyTest, setOnFlyTest] = useState(null)
const [allData, setAllData] = useState(null)
const convertFiletoDataUrl = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.normalize());
  reader.onerror = error => reject(error);
});

const convertPdfPageToImage = (pdf, pageNumber, fileName, i) => new Promise(resolve => {
    pdf.getPage(pageNumber).then((page) => {
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        const renderContext = { canvasContext: ctx, viewport: viewport };

        canvas.height = viewport.height;
        canvas.width = viewport.width;
            page.render(renderContext).promise.then(async () => {
            const dataUrl = canvas.toDataURL();
            let data = {}
            data.url = dataUrl
            data.index = pageNumber
            setPdfImages([...pdfImages, data])

        })
    });
})

const convertPdfFilesToImages = (files) => new Promise(resolve => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
    const worker = new pdfjsLib.PDFWorker();

    const promises = Array.from(Array(files.length), (_, index) => new Promise(resolve => {
        const file = files[index];

        if (file?.type === 'octet/stream') {
            convertFiletoDataUrl(file).then(dataUrl =>
                pdfjsLib.getDocument({ url: dataUrl, worker }).promise.then(pdf => {
                    const promises = Array.from(Array(pdf.numPages), (_, index) => new Promise(resolve => {
                        const pageNumber = index + 1;
                        convertPdfPageToImage(pdf, pageNumber, file.name, index)
                    }));
                    Promise.all(promises)
                })
            );
        } else {
            resolve(file);
        }
    }).then(() => console.log("allDoneee")));

    Promise.all(promises).then(files => resolve(files.flat()));
});
    
    const [dataLogFile, setDataLogFile] = useState(null);  
   
    const selectedTests = useSelector((state) => state.selectedTests.selectedTests)

    useEffect(() => {
      if(dataLogFile) {
        let reader = new FileReader()
        reader.addEventListener("load", () => {
          let arr = reader?.result.split('\n'); 
          arr?.unshift("date,time,area,location,testNumber,id,type,condition,result,avg1,avg2,avg3,avg4,fileName")
          var jsonObj = [];
          var allObj = [];
          var headers = arr[0].split(',');
          for(var i = 1; i < arr.length; i++) {
          var data = arr[i].split(','); 
          var obj = {};
          for(var j = 0; j < data.length; j++){ 
            obj[headers[j].trim()] = data[j].trim();
          }

          // test
          // jsonObj.push(obj)

          obj.area == contextArea && obj.location == contextLocation ? jsonObj.push(obj) : mode ? jsonObj.push(obj) : null

          // here to filter by area and location
          }   
          JSON.stringify(jsonObj);
          var groupedData = groupBy(jsonObj, 'fileName');
          setMatchedData(Object.entries(groupedData))
          setAllData(arr)

        }, false);

        reader.readAsText(dataLogFile)

      }
    },[dataLogFile])

    useEffect(() => {
      if(pdfFile) {
        convertPdfFilesToImages([pdfFile])
      }
    },[pdfFile])

    const groupBy = (objectArray, property) => {
      return objectArray.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        let objDate = obj.date
        let monthAndYear = moment(objDate, "MM/DD/YY")
        acc[key].every(x => {
          let objDateX = x.date
          let monthAndYearX = moment(objDateX, "MM/DD/YY")
          let month = monthAndYear.format('M');
          let year  = monthAndYear.format('YY');

          let monthX = monthAndYearX.format('M');
          let yearX  = monthAndYearX.format('YY');

          if(month == monthX && yearX == year) {
            return true
          } else {
            return false
          }

        }) ? acc[key].push(obj) : null
        return acc;
      }, {});
    }

    // useEffect(() => {
    //   if(fileError) {
    //     let attachments = document.querySelector(`[id=imagesContainer]`)
    //         attachments ? attachments.innerHTML = ("") : null
    //   }   
    // },[fileError])

    useEffect(() => {
        if(pdfImages) {
            setNewPdfImages([...pdfImages, ...newPdfImages])
        } 
    },[pdfImages])

    useEffect(() => {
      if(!singleRuns && newPdfImages.length == 3) {

        let newPdfImagesOrdered = newPdfImages.sort((a,b) => a.index - b.index)
          newPdfImagesOrdered.map(x => {
          let attachments = document.querySelector(`[id=imagesContainer]`)
          var DOM_img = document.createElement("img");
          DOM_img.src = x.url;
          DOM_img.className="domImg"
          attachments?.appendChild(DOM_img)
        })
          
      } else if(singleRuns && newPdfImages.length == 1) {

          let newPdfImagesOrdered = newPdfImages.sort((a,b) => a.index - b.index)
          newPdfImagesOrdered.map(x => {
          let attachments = document.querySelector(`[id=imagesContainer]`)
          var DOM_img = document.createElement("img");
          DOM_img.src = x.url;
          DOM_img.className="domImg"
          attachments?.appendChild(DOM_img)

        })
      }
    },[newPdfImages, singleRuns])

    let validateDate = (date) => {
      if(date) {
      let rightDate = date.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g)
      if(rightDate?.length) {
        return rightDate[0]
      } else {
        return null
      }
    } else {
      return null
    }
    }
    let ignoredT = ["avg1",	"avg2",	"avg3",	"avg4"]
    let ignoredO = ["type",	"condition",	"testNumber"]
    const [confirmDelete, setConfirmDelete] = useState(null)

    const deleteTestFunc = (test) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      addExtraData(test, "delete")
      setActive(null)
    }
    const downloadData = (x) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        let pdf = x.pdfUrl
        let testsData = x.results
        const csvFromArrayOfArrays = convertArrayToCSV(testsData, {
          header:[],        
          separator: ','
        });
        const csvBlob = new Blob([csvFromArrayOfArrays], {type: "text/plain;charset=utf-8"});

        let pdfFilename = x.results[0].fileName
        const params = {
          Bucket: S3_BUCKET,  
          Key: pdf.slice(pdf.indexOf("amazonaws.com/") + 14 , pdf.length)
        }
        myBucket.getObject(params) 
        .on("complete", (evt) => {
          if(evt?.data?.Body) {
            let pdfArr = evt.data.Body
            let blobPdf = new Blob([pdfArr], {type: 'application/pdf'})
            saveAs(blobPdf, pdfFilename)
            saveAs(csvBlob, "DATA.LOG")
            dispatch(ACTION_LOADING_SPINNER_RESET())
          }   
        }) 
        .send((err) => {
          if (err) console.log(err) 
      }) 
  
    }


    const [mistakeArea ,setMistakeArea] = useState("")
    const [mistakeLocation ,setMistakeLocation] = useState("")
    const [mistakeFilter ,setMistakeFilter] = useState(null)
    const lookFunc = () => {
            setMistake(false)
            let arr = allData 
            arr?.unshift("date,time,area,location,testNumber,id,type,condition,result,avg1,avg2,avg3,avg4,fileName")
            var jsonObj = [];
            var allObj = [];
            var headers = arr[0].split(',');
            for(var i = 1; i < arr.length; i++) {
            var data = arr[i].split(','); 
            var obj = {};
            for(var j = 0; j < data.length; j++){ 
              obj[headers[j].trim()] = data[j].trim();
            }
            obj.area == mistakeArea?.value && obj.location == mistakeLocation?.value ? jsonObj.push(obj) : null
            }   
            JSON.stringify(jsonObj);
            var groupedData = groupBy(jsonObj, 'fileName');
            setMatchedData(Object.entries(groupedData))
            setMistakeFilter({area: mistakeArea?.value, location: mistakeLocation?.value, correctArea: matchedTest.areaCode, correctLocation: matchedTest.locationCode})  
            setMistakeArea(null)     
            setMistakeLocation(null)
          }

          const order = ["original", "45", "90"];


          const [segments, setSegments] = React.useState({original:["","","","",""]})
        
          const addNewTestType = (type) => {
            let newSegments = {...segments, [type]: ["","","","",""]}
            console.log(newSegments, 10)
            setSegments(newSegments)
          }


          const deleteType = (type) => {
            let newSegments = {...segments}
            delete newSegments[type];

            setSegments(newSegments)
          }


         const handleChange = (e) => {
            const { maxLength, value, name } = e.target;
            const [fieldName, fieldIndex] = name.split("-");
     
            let fieldIntIndex = parseInt(fieldIndex, 10);
     
            // Check if no of char in field == maxlength
            if (value.length >= maxLength) {
     
                // It should not be last input field
                if (fieldIntIndex < 3) {
     
                    // Get the next input field using it's name
                    const nextfield = document.querySelector(
                        `input[name=field-${fieldIntIndex + 1}]`
                    );
     
                    // If found, focus the next field
                    if (nextfield !== null) {
                        nextfield.focus();
                    }
                }
            }
        };

const runone = useRef()
const runtwo = useRef()
const runthree = useRef()
const runfour = useRef()
const runfive = useRef()

          const update = (type, index, inputValue) => { 
          
            setSegments({
                ...segments,
                [type]: [
                ...segments[type].slice(0, index),
                inputValue,
                ...segments[type].slice(index + 1)
                ]
          })
                    
          }


          const valueTyping = (e) => {
            if (
              (e.keyCode >= 8 && e.keyCode < 32) ||
              (e.keyCode >= 37 && e.keyCode <= 40) ||
              (e.keyCode >= 96 && e.keyCode <= 105) ||
              (e.keyCode >= 48 && e.keyCode <= 57) ||
              (e.keyCode == 17) || 
              (e.keyCode == 86) ||
              (e.keyCode == 190) ||
              (e.keyCode == 46)
      
            ) {
              return e.keyCode;
            } else {
             e && e.preventDefault();
            }
          };
      
          let prefix = "0."


          const numberHandle = (e, key, index) => {
            console.log(e.target.value, key, index)
            let currentTyping = e.currentTarget.value;
            if(currentTyping.length <= 4) {
              if(currentTyping == 0) {
                currentTyping = prefix
                update(key, index, currentTyping)
              }
              if(currentTyping.length <= 1) {
                  currentTyping = prefix + e.currentTarget.value
                  update(key, index, currentTyping) 
              } else {
                update(key, index, currentTyping)
              } 
                
            }
          };
          return(
<Modal
        isOpen={active ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(1px)",
            WebkitBackdropFilter: "blur(1px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <ConfirmDeleteModal 
          deleteModal={confirmDelete}
          setDeleteModal={setConfirmDelete}
          deleteType={"test"}
          deleteTestFunc={deleteTestFunc}
        />
        { active ?
        pendulum ? 
        (
          <div className="modal task-modal" style={{height:"fit-content", width:"40vw", overflow:"visible"}}>
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, height:"33px"}}>Pendulum Test</h1>
          <div style={{height:"100%", width:"100%", margin:"50px 0", justifyContent:"center", overflow:"visible"}} className="form-group-container task-form">        
          <div className='label-statement' style={{padding:"0 1rem"}}>
            The Pendulum Test measures the slip resistance of a floor surface by replicating the interaction between a moving pedestrian heel and static surface. The Pendulum has a swinging arm with a rubber slider attached to the heel of an imitation foot, which sits at the bottom of the arm.
                    </div> 
                    <>
                    {
                      order.map((key, i) => {
                        const segment = segments[key];

                        if(!segment) {
                          return null
                        }
                        return (
                          <div style={{opacity: key == "original" ? 1 : segments["original"].every(x=>x!=="") ? 1 : .3}} className="form-collection">
              <div key={i} className="form-label" style={{display:"flex", alignItems:"center", justifyContent:"space-between", paddingRight:"0px"}}>
              <div>{key} test</div>
      {key!=="original" ?  <div onClick={() => deleteType(key)} style={{cursor:"pointer", color:"#EF1111"}}>delete</div>
      : null}              
      </div> 
                    <div>

            <div className='keyStyle'>
              
              
            <input 
            ref={runone}
            onKeyUp={(e) => e.key == "Tab" ? runtwo.current.focus() : null}
            onBlur={(e) => e.target.value && update(key, 0, Number(e.target.value))}
            onKeyDown={valueTyping}
            step=".11"
            min="0"
            max="0"
            type="number"
            onChange={(e) => numberHandle(e, key, 0)}
            name={`run 1`} 
            placeholder={`0.00 run 1`}  value={segment[0]}
            style={{padding:"3px", borderRadius:"10px", border: Number(segment[0]) >= 0.45 ? "2px solid green" : Number(segment[0]) < 0.45 ? "2px solid #EF1111" : "2px solid lightgray"}}
            />

            <input
            ref={runtwo}
            onKeyUp={(e) => e.key == "Tab" ? runthree.current.focus() : null}
            onBlur={(e) => e.target.value && update(key, 1, Number(e.target.value))}
            onKeyDown={valueTyping}
            step=".11"
            min="0"
            max="0"
            type="number"
            onChange={(e) => numberHandle(e, key, 1)}
            name={`run 2`} 
            placeholder={`0.00 run 2`}  value={segment[1]}
            style={{padding:"3px", borderRadius:"10px", border: Number(segment[1]) >= 0.45 ? "2px solid green" : Number(segment[1]) < 0.45 ? "2px solid #EF1111" : "2px solid lightgray"}}
            />

            <input 
            ref={runthree}
            onKeyUp={(e) => e.key == "Tab" ? runfour.current.focus() : null}
            onBlur={(e) => e.target.value && update(key, 2, Number(e.target.value))}
            onKeyDown={valueTyping}
            step=".11"
            min="0"
            max="0"
            type="number"
            onChange={(e) => numberHandle(e, key, 2)}
            name={`run 3`} 
            placeholder={`0.00 run 3`}  value={segment[2]}
            style={{padding:"3px", borderRadius:"10px", border: Number(segment[2]) >= 0.45 ? "2px solid green" : Number(segment[2]) < 0.45 ? "2px solid #EF1111" : "2px solid lightgray"}}
            />

            <input 
            ref={runfour}
            onKeyUp={(e) => e.key == "Tab" ? runfive.current.focus() : null}
            onBlur={(e) => e.target.value && update(key, 3, Number(e.target.value))}
            onKeyDown={valueTyping}
            step=".11"
            min="0"
            max="0"
            type="number"
            onChange={(e) => numberHandle(e, key, 3)}
            name={`run 4`} 
            placeholder={`0.00 run 4`}  value={segment[3]}
            style={{padding:"3px", borderRadius:"10px", border: Number(segment[3]) >= 0.45 ? "2px solid green" : Number(segment[3]) < 0.45 ? "2px solid #EF1111" : "2px solid lightgray"}}
            />

            <input 
            ref={runfive}
            onKeyUp={(e) => e.key == "Tab" ? runone.current.focus() : null}
            onBlur={(e) => e.target.value && update(key, 4, Number(e.target.value))}
            onKeyDown={valueTyping}
            step=".11"
            min="0"
            max="0"
            type="number"
            onChange={(e) => numberHandle(e, key, 4)}
            name={`run 5`} 
            placeholder={`0.00 run 5`}  value={segment[4]}
            style={{padding:"3px", borderRadius:"10px", border: Number(segment[4]) >= 0.45 ? "2px solid green" : Number(segment[4]) < 0.45 ? "2px solid #EF1111" : "2px solid lightgray"}}
            />
            </div>
              </div>
            </div>
                        )
                      })
                    }
            </>
            <div className='addDiv'>
            {!segments["45"] && <div className={segments["original"]?.every(element => element !== "") ?  "auth-button addUser": "auth-button addUser disable-button"} onClick={() => {
            segments["original"]?.every(element => element !== "") ? addNewTestType("45") : null
            }}>add 45 angle test</div>}

            {!segments["90"] && <div className={segments["original"]?.every(element => element !== "") ?  "auth-button addUser": "auth-button addUser disable-button"} onClick={() => {
            segments["original"]?.every(element => element !== "") ? addNewTestType("90") : null
            }}>add 90 angle test</div>}
</div>

          </div>
          
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {
                  setMistake(false)
                  setMistakeArea(null)
                  setMistakeLocation(null)
                  pendulum ? setActive(null) : null
                }}
            >

            Cancel

            </div>            
            <div
              style={{margin:".5rem"}}
              className={`${ mistakeArea && mistakeLocation ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={ mistakeArea && mistakeLocation ? () => lookFunc() : null }
            >
              Submit
            </div>
          </div>
        </div>
        )
        : mistake ? (
          <div className="modal task-modal" style={{height:"fit-content", width:"40vw", overflow:"visible"}}>
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, height:"33px"}}>Lets clear this up...</h1>
          <div style={{height:"100%", width:"100%", margin:"50px 0", justifyContent:"center", overflow:"visible"}} className="form-group-container task-form">        
          <div className='label-statement' style={{padding:"0 1rem"}}>
          It looks like the auditor did not enter the correct Area # and/or Location # on the BOT. Please indicate below the Area # and Location # that was entered on the BOT. This data will be included in the Audit Report as Area <span style={{color:"#6db240"}}>#{contextArea}</span> and Location <span style={{color:"#6db240"}}>#{contextLocation}</span> with a NOTE stating that the auditor mistakenly chose the wrong Area # and Location # on the BOT.
                    </div> 
                    <>
            <div className="form-collection">
              <div className="form-label" style={{display:"flex", alignItems:"center", paddingRight:"0px"}}>
              Area Identification
              </div> 
  <Dropdown 
              options={["000","001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012", "013", "014", "015", "016", "017", "018", "019", "020", "021", "022", "023", "024", "025", "026", "027", "028", "029", "030", "031", "032", "033", "034", "035", "036", "037", "038", "039", "040", "041", "042", "043", "044", "045", "046", "047", "048", "049", "050", "051", "052", "053", "054", "055", "056", "057", "058", "059", "060", "061", "062", "063", "064", "065", "066", "067", "068", "069", "070", "071", "072", "073", "074", "075", "076", "077", "078", "079", "080", "081", "082", "083", "084", "085", "086", "087", "088", "089", "090", "091", "092", "093", "094", "095", "096", "097", "098", "099", "100"]}
                            controlClassName="form-dropdown" 
                  value={mistakeArea}
                  onChange={(choice) =>
                   setMistakeArea(choice)
                  } 
              placeholder={"Choose Area Number"}
              /> 

            </div>
            <div className="form-collection">
              <div className="form-label" style={{display:"flex", alignItems:"center", paddingRight:"0px"}}>
                Location Identification
              </div>
  <Dropdown 
              options={["000","001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012", "013", "014", "015", "016", "017", "018", "019", "020", "021", "022", "023", "024", "025", "026", "027", "028", "029", "030", "031", "032", "033", "034", "035", "036", "037", "038", "039", "040", "041", "042", "043", "044", "045", "046", "047", "048", "049", "050", "051", "052", "053", "054", "055", "056", "057", "058", "059", "060", "061", "062", "063", "064", "065", "066", "067", "068", "069", "070", "071", "072", "073", "074", "075", "076", "077", "078", "079", "080", "081", "082", "083", "084", "085", "086", "087", "088", "089", "090", "091", "092", "093", "094", "095", "096", "097", "098", "099", "100"]}
                            controlClassName="form-dropdown" 
                  value={mistakeLocation}
                  onChange={(choice) =>
                   setMistakeLocation(choice)
                  }
              placeholder={"Choose Location Number"}
              /> 

            </div>
        
            </>
          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {
                  setMistake(false)
                  setMistakeArea(null)
                  setMistakeLocation(null)
                }}
            >

            Cancel

            </div>            
            <div
              style={{margin:".5rem"}}
              className={`${ mistakeArea && mistakeLocation ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={ mistakeArea && mistakeLocation ? () => lookFunc() : null }
            >
              Look
            </div>
          </div>
        </div>
        ) :
        mode == "extra" && !addNewTest ? <div className="modal task-modal" style={{height: "fit-content", minHeight:"100%", width:"90vw", justifyContent:"space-between"}}>

<div><h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, height:"10%" , display:"flex", justifyContent:"space-between"}}>
          <div>Add Extra data</div>
          {/* audit date */}
          {/* <div style={{fontSize:"16px", fontWeight:"normal"}}>
            {true ? firstTimeOnFly ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span>  {moment(validateDate(firstTimeOnFly)).format("MM/DD/YYYY")} </div> : selectedAudit.firstTime ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span>  {moment(validateDate(selectedAudit.firstTime)).format("MM/DD/YYYY")} </div> : null : null}
          </div>  */}
          </h1>
          </div>
          <div className="form-group-container task-form uploaderContainer" style={{height:"100%", margin:"70px 0", alignItems:"center", overflowY:"auto", lexWrap:"wrap"}}>    
            <div className='dataUpload' style={{border: extraDataArr && "0px", width:"100%", minWidth:"300px"}}>
          {
            extraDataArr?.filter(x=>x)?.length ? extraDataArr.filter(x=>x).map((extra, key) => {
             return( 
              <div key={key} style={{cursor:"auto"}} className={`boxContainer`}>
                <div style={{borderBottom:"2px solid gray", display:"flex", justifyContent:"space-between", padding:"10px 5px"}}>
                <img 
                    onClick={() => {
                      setConfirmDelete(extra)}}
                    className="testDelete"
                    style={{cursor:"pointer", marginLeft:"10px"}}
                    src={archive} />
                <img 
                onClick={() => downloadData(extra)}
                style={{cursor:"pointer", marginLeft:"10px"}} 
                src={download} />
                </div>
              <div>
                <div className="tbl-content">
                  <table cellPadding="0" cellSpacing="0" border="0">
                    <thead className='tbl-header'>
                      <tr>
                        {Object.keys(extra?.results[0]).filter(x => !ignoredT.some(y => y == x)).filter(x => {
                          if(extra.results.length == 1) {
                            return !ignoredO.some( y => y == x)
                          } else {
                            return  x
                          }
                        }).map(x=> (
                        <th>{x}</th>                    
                        ))}                
                     </tr>
                    </thead>
                    <tbody>
              {extra?.results.sort((a,b) => a.testNumber - b.testNumber).map((test, i) => (
                <tr key={i}>
                        {Object.keys(extra?.results[0]).filter(x => !ignoredT.some(y => y == x)).filter(x => {
                          if(extra.results.length == 1) {
                            return !ignoredO.some( y => y == x)
                          } else {
                            return  x
                          }
                        }).map(y => (
                          <td>{test[y]}</td>
                        ))}
                  </tr> 
                  
              ))}
                    </tbody>
                  </table>
                </div>
              </div>
              </div>          
            )}
              ) : <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
              You haven‘t add any extra data
            </div>
          }
          </div>
          </div>
          <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {      
                    resetActiveTest()
                    setActive(null)
                    setDataLogFile(null)
                    setPdfFile(null)
                    setMatchedData(null)
                    setExactData(null)
                    setCurrentTest(null)
                    setFinalData([])
                    setPdfFile(null)
                    setMyState("data")
                    setPullingDataActive(0)
                    setPdfFileNotFound(false)
                    setPdfImages([])
                    setNewPdfImages([])
                    setFileError(null)
                    setAddNewTest(false)
                    setMode && setMode(null)
                }}
            >

            Cancel

          </div>
          <div
            style={{margin:".5rem"}}
              className={`${"auth-button addUser"}`}
              onClick={ () => {
                      setAddNewTest(true)
                      }}
                    >
              Add new test

            </div>
            </div>
        </div>: <div className="modal task-modal" style={{height: "fit-content", minHeight:"100%", width:"90vw", justifyContent:"space-between"}}>
               
          {warningModal && (
            <WarningModal
            setOnFlyTest={setOnFlyTest}
            open={warningModal}
            setOpen={setWarningModal} 
            />
          )}
          <div><h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, height:"10%" , display:"flex", justifyContent:"space-between"}}>
          <div style={{color:mistakeFilter ? "#EF1111" : "#5C92BF"}}>{mistakeFilter ? "Mistake Mode" : mode ? "Extra Mode" : "Normal Mode"}</div>
          {fullCategory && !mode ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}></span>{fullCategory} </div> : null }
          <div style={{fontSize:"16px", fontWeight:"normal"}}>
            {!mode && firstTimeOnFly !== "reset" ? firstTimeOnFly ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span>  {moment(validateDate(firstTimeOnFly)).format("MM/DD/YYYY")} </div> : selectedAudit.firstTime ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span>  {moment(validateDate(selectedAudit.firstTime)).format("MM/DD/YYYY")} </div> : null : null}
          </div>     
        { matchedData || dataLogFile || exactData || pdfFile ? <div onClick={() => {
            let attachments = document.querySelector(`[id=imagesContainer]`)
            attachments ? attachments.innerHTML = ("") : null
            setDataLogFile(null) 
            setPdfFile(null)
            setExactData(null)
            setMatchedData(null)
            setFinalData([])
            setPdfFile(null)
            setPullingDataActive(0)
            setPdfFileNotFound(false)
            setPdfImages([])
            setNewPdfImages([])
            setFile(null)
            setFileError(null)
            setMistakeArea(null)
            setMistakeLocation(null)
            setMistakeFilter(null)
            resetActiveTest()
          }} style={{cursor:"pointer", color:"#EF1111", fontSize:"16px", fontWeight:"normal"}}>reset</div> : null }
          </h1>
          </div>
          <div className="form-group-container task-form uploaderContainer" style={{height:"100%", margin:"70px 0", alignItems:"center", overflowY:"auto", flexWrap:"wrap"}}>    
            <div className='dataUpload' style={{border: matchedData && "0px", position:"relative", minWidth:"300px", paddingTop:"30px"}}>
            {!matchedData ? <FileUploader
          multiple={false}
          handleChange={(file) =>{
          setDataLogFile(file)
          }}
        name="file"
        types={["LOG"]} 
        label=" First you upload 'DATA.LOG' file"
      /> : (
        <>
              <div style={{position:"sticky", top:-30, right:22,padding:"10px", width:"100%", backgroundColor:"white", zIndex:"10", display:"flex", justifyContent:"space-between"}}>
              {true ? <div onClick={() => {
                setMistake(true)
              }} style={{textDecoration:"underline",  color:"#EF1111", width:"fit-content", cursor:"pointer"}}>Can't find what you're looking for?</div> : <div></div>}
              <label onClick={() => setSingleRuns(!singleRuns)} className="toggle-control lookingfor" style={{cursor:"pointer",justifyContent:"flex-end", marginBottom:"20px", width:"fit-content"}}>
                  <span className={`${singleRuns ? "checked" : "notChecked"}`}></span>
                  <span className="control"></span>
                  <div style={{marginLeft:"10px"}}>Single runs</div>
              </label>
              </div>
        {true ? matchedData.map(([key, tests], i) => {
            return(
          <div key={key} className={`boxContainer ${key == exactData && "activeBox"}`} style={{position:"relative",paddingTop:i == 0 ? "20px" : "0px", opacity: pullingDataActive ? ".2" : 1, cursor: pullingDataActive ? "auto" : "pointer"}} onClick={() => {
              if(!pullingDataActive) {
                let cloneMode = mode
                if(onFlyTest) {
                  cloneMode = null  
                  setCleanCondition("prevailing")
                  setFinalData(null)
                  setExactData(null)
                  setMode(null)
                  setAddNewTest(false)
                  setOnFlyTest(null)
                }
                if(cloneMode !== "extra" && tests.every(x => x?.type?.toLowerCase().includes("lab"))) {
                  setOnFlyTest(tests)
                  setWarningModal(<div>
                    <div className='squareContent' style={{fontSize:"18px", margin:"0 5px", textTransform:"none"}}>Lab tests are not able to be uploaded</div>
                    <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
                    <span style={{margin:"15px 0", textTransform:"none"}}>You still can add selected test to extra data</span>
                    <div
                    className="auth-button addUser confirmDelete" 
                    style={{margin:0, marginBottom:"10px"}}
                    onClick={() => {                 
                    setMode("extra")
                    setAddNewTest(true)
                    let attachments = document.querySelector(`[id=imagesContainer]`)
                    attachments ? attachments.innerHTML = ("") : null
                    setFile(null)
                    setFileError(null)
                    let onFlyCategory = tests[0]?.type?.slice(tests[0]?.type?.indexOf("/")+1, tests[0]?.type?.length)
                    
                    matchedTest ? matchedTest.areaCondition = `${tests[0]?.condition}/${onFlyCategory?.toLowerCase() || contextCategory}` : null
                    matchedTest ? matchedTest.areaCode = tests[0].area : null
                    matchedTest ? matchedTest.fileName = tests[0].fileName : null
                    matchedTest ? matchedTest.locationCode = tests[0].location : null
                    matchedTest ? matchedTest.extra = true : null
                                            


                          
                          setCleanCondition(tests[0]?.condition?.toLowerCase())
                          setPdfFileNotFound(false)
                          let newTests = Object.assign([], tests)
                          newTests.reverse()
                          setFinalData(newTests)
                          setPdfFile(null)
                          setExactData(key)
                          setPdfImages([]) 
                          setNewPdfImages([])
                // setOnFlyTest(null) 
                         setWarningModal(null)
                      }} 
                      >
                      Open extra mode
                      </div> 
                    </div>
                </div>)
                } else if(cloneMode !== "extra" && tests.length == 1) {
                  setOnFlyTest(tests)
                  setWarningModal(<div>
                    <div className='squareContent' style={{fontSize:"18px", margin:"0 5px", textTransform:"none"}}>Signle runs are not able to be uploaded</div>
                    <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
                    <span style={{margin:"15px 0", textTransform:"none"}}>You still can add selected test to extra data</span>
                      <div
                      className="auth-button addUser confirmDelete"
                      style={{margin:0, marginBottom:"10px"}}
                      onClick={() => {                 
                        setMode("extra")
                        setAddNewTest(true)
                        let attachments = document.querySelector(`[id=imagesContainer]`)
                        attachments ? attachments.innerHTML = ("") : null
                        setFile(null)
                        setFileError(null)
                        let onFlyCategory = tests[0]?.type?.slice(tests[0]?.type?.indexOf("/")+1, tests[0]?.type?.length)
                        
                        matchedTest ? matchedTest.areaCondition = `${tests[0]?.condition}/${onFlyCategory?.toLowerCase() || contextCategory}` : null
                        matchedTest ? matchedTest.areaCode = tests[0].area : null
                        matchedTest ? matchedTest.fileName = tests[0].fileName : null
                        matchedTest ? matchedTest.locationCode = tests[0].location : null
                        matchedTest ? matchedTest.extra = true : null
                                                


                        
                        setCleanCondition(tests[0]?.condition?.toLowerCase())
                        setPdfFileNotFound(false)
                        let newTests = Object.assign([], tests)
                        newTests.reverse()
                        setFinalData(newTests)
                        setPdfFile(null)
                        setExactData(key)
                        setPdfImages([]) 
                        setNewPdfImages([])
                        setWarningModal(null) 
                      }} 
                      >
                      Open extra mode
                      </div> 
                    </div>
                    </div>)

                    } else if((cloneMode) || (tests.every(x => x?.type?.toLowerCase().includes(contextCategory?.toLowerCase()) && x?.condition))) {
                    setOnFlyTest(null)
                    let attachments = document.querySelector(`[id=imagesContainer]`)
                    attachments ? attachments.innerHTML = ("") : null
                    setFile(null)
                    setFileError(null)
                    let onFlyCategory = tests[0]?.type?.slice(tests[0]?.type?.indexOf("/")+1, tests[0]?.type?.length)
                    
                    matchedTest ? matchedTest.areaCondition = `${tests[0]?.condition}/${onFlyCategory?.toLowerCase() || contextCategory}` : null
                    matchedTest ? matchedTest.areaCode = tests[0].area : null
                    matchedTest ? matchedTest.fileName = tests[0].fileName : null
                    matchedTest ? matchedTest.locationCode = tests[0].location : null
                    matchedTest ? matchedTest.extra = cloneMode ? true : false : null
                                            


                    
                    setCleanCondition(tests[0]?.condition?.toLowerCase())
                    setPdfFileNotFound(false)
                    let newTests = Object.assign([], tests)
                    newTests.reverse()
                    setFinalData(newTests)
                    setPdfFile(null)
                    setExactData(key)
                    setPdfImages([]) 
                    setNewPdfImages([])
                    } else {  
                    let onFlyCategory = tests[0]?.type?.slice(tests[0]?.type?.indexOf("/")+1, tests[0]?.type?.length )
                    setOnFlyTest(tests)
                    setWarningModal(<div>
                    <div className='squareContent' style={{fontSize:"18px", margin:"0 5px", textTransform:"none"}}>Data.log file chosen is for testing condition ({onFlyCategory}), which does not match the Product Use Category {fullCategory}.</div>
                    <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
                    <span style={{margin:"15px 0", textTransform:"none"}}>- Please select a Data.log file with the correct testing condition.</span>
                    <span style={{margin:"15px 0", textTransform:"none"}}>- To change Product Use Category, go to "Run Test" and edit the Product Use Category.</span>            
                    <span style={{margin:"15px 0", textTransform:"none"}}>You still can add selected test to extra data</span>
                      <div
                      className="auth-button addUser confirmDelete"
                      style={{margin:0, marginBottom:"10px"}}
                      onClick={() => {                 
                        setMode("extra")
                        setAddNewTest(true)
                        let attachments = document.querySelector(`[id=imagesContainer]`)
                        attachments ? attachments.innerHTML = ("") : null
                        setFile(null)
                        setFileError(null)
                        let onFlyCategory = tests[0]?.type?.slice(tests[0]?.type?.indexOf("/")+1, tests[0]?.type?.length)
                        
                        matchedTest ? matchedTest.areaCondition = `${tests[0]?.condition}/${onFlyCategory?.toLowerCase() || contextCategory}` : null
                        matchedTest ? matchedTest.areaCode = tests[0].area : null
                        matchedTest ? matchedTest.fileName = tests[0].fileName : null
                        matchedTest ? matchedTest.locationCode = tests[0].location : null
                        matchedTest ? matchedTest.extra = true : null
                                                

                        setCleanCondition(tests[0]?.condition?.toLowerCase())
                        setPdfFileNotFound(false)
                        let newTests = Object.assign([], tests)
                        newTests.reverse()
                        setFinalData(newTests)
                        setPdfFile(null)
                        setExactData(key)
                        setPdfImages([]) 
                        setNewPdfImages([])
                        setWarningModal(null) 
                        }} 
                      >
                        Open extra mode
                      </div> 
                    </div>
                </div>)
              }
              } 
              
            
            }}>
          
          <section>
            <div className="tbl-content">
              <table cellPadding="0" cellSpacing="0" border="0">
                <thead className='tbl-header'>
                  <tr>
                    <th>file name</th>                    
                    {tests.length == 3 ? <th>type</th> : null}
                    {tests.length == 3 ? <th>condition</th> : null}
                    <th>date</th>
                    <th>time</th>
                    <th>location</th>
                    <th>area</th>
                    {tests.length == 3 ? <th>test number </th> : null}
                    <th>result</th>
                    {tests.length == 3 ? <th>avg1</th> : null}
                    {tests.length == 3 ? <th>avg2</th> : null}
                    {tests.length == 3 ? <th>avg3</th> : null}
                    {tests.length == 3 ? <th>avg4</th> : null}
                 </tr>
                </thead>
                <tbody>
                {tests.sort((a,b) => a.testNumber - b.testNumber).map((test, i) => (
                  <tr key={i}>
                          <td>{test.fileName}</td>
                          {tests.length == 3 ? <td>{test.type}</td> : null }
                          {tests.length == 3 ? <td>{test.condition}</td> : null }
                          <td>{test.date}</td>
                          <td>{test.time}</td>
                          <td>{test.location}</td>
                          <td>{test.area}</td>
                          {tests.length == 3 ? <td>{test.testNumber}</td> : null }
                          <td>{test.result}</td>
                          {tests.length == 3 ? <td>{test.avg1}</td> : null }
                          {tests.length == 3 ? <td>{test.avg2}</td> : null }
                          {tests.length == 3 ? <td>{test.avg3}</td> : null }
                          {tests.length == 3 ? <td>{test.avg4}</td> : null }
                    </tr> 
                ))}
                </tbody>
              </table>
            </div>
          </section>
       
          </div>          
        )
          }) : (
            <div style={{width:"100%", height:"80%", display:"flex", justifyContent:"center", alignItems:"center"}}>
              No matched data available in this file
            </div>
          )
          } </>)}

             </div>         

            { !pdfFile  ? <div className='pdfUpload' style={{opacity: !exactData ? .2 : 1, border:"0",  minWidth:"300px"}}>
         {!pendulum ?  <div style={{width:"100%", height: pendulum ? "100%":"50%", border:fileError ? "2px solid red":"2px dashed gray", borderRadius:"8px", opacity: pullingDataActive ? .2 : 1}}>
            {!fileError ? <FileUploader
              multiple={false}
              handleChange={(file) => convertuploadedPdf(file, exactData, mode, mistakeFilter)}
              name="file"
              fileOrFiles={null}
              types={["PDF"]}
              children={<div style={{display:"flex", cursor:"pointer", flexDirection:"column", height:"100%", justifyContent:"center", alignItems:"center"}}>
                <svg width="100" height="100" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
                <p style={{fontSize:"24px"}}>
                  {!exactData ? "No data selected" : pullingDataActive ? "loading" :<div>Please upload file: <div style={{display:"inline-block", fontWeight:"bold"}}>{exactData}</div></div>}
                  
                  </p>
                </div>}
              // label={fileError ? ` ${fileError}, try again` :` Please upload file: ${exactData}`}
              disabled={!exactData || pullingDataActive}
            /> : (
              <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", fontSize:20, fontWeight:"bold", textTransform:"capitalize", flexDirection:"column"}}> <div>{fileError}</div> <div style={{color:"#89CFF0",cursor:"pointer"}} onClick={() => setFileError(null)}> try again</div></div>
            )}
        </div> : null}
        {!pendulum ? <div className='horizontalSeparator'>or</div> : null }
          {!pendulum ? <div className='bluetoothPdf' style={{position:"relative"}}>
            {!pullingDataActive ? (
              pdfFileNotFound ? (
                <>
                  <div>
                    {exactData} not found in BOT-3000E storage , you have to upload it above
                  </div>
                  </>
              ) :             
              <>
              <div
              className={`${ true ? "auth-button addUser": "auth-button addUser disable-button"}`}
              style={{width:"fit-content", padding:"0 10px", cursor: !exactData && "auto"}}
              onClick={
                exactData ?
                !isDisconnected ?
                () => {
                  setMyState("pdf")
                  getPdfFromBot(exactData) 
                } :
                connectToDeviceAndSubscribeToUpdates:
                null
              }
            >
              
              fetch PDF from the bot

            </div>
            <div style={{position:"absolute", bottom:"10px", left:"10px", fontSize:"14px", color:"gray"}}>*Need to be connected to the BOT-3000E via bluetooth</div>
            <div style={{position:"absolute", bottom:"10px", right:"10px", fontSize:"14px"}}>{isDisconnected ? <div style={{color:"#EF1111"}}>Not Connected</div> : <div style={{color:"#6DB240"}}>connected</div>}</div>
              </>
            ) : (
              <div style={{margin:"2rem 1rem", width:"100%"}}>
            <div className="progress-bar" style={{width: pullingDataActive && `${(pullingDataActive/1800*100).toFixed(2)}%`}}></div>
            <h1 className="count">{pullingDataActive < 1800 ? (pullingDataActive/1800*100).toFixed(2) : (1800/1800*100).toFixed(2)}%</h1>
            </div>
            )}
          </div> : null}
            </div> : pdfImages.length > 0 ? (
              <div className='pdfUpload' id="imagesContainer" style={{overflowX:"auto"}}>
              </div>
            ) : ( 
              <div className='pdfUpload'>
              <DataSpinner />
              </div>
            )}
           </div>
          <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
          <div
          className="auth-button addUser confirmDelete deleteButton"
          onClick={() => {  
            if(onFlyTest) {
              setCleanCondition("prevailing")
              setFinalData(null)
              setExactData(null)
              setMode(null)
              setAddNewTest(false)
              setOnFlyTest(null)
              let attachments = document.querySelector(`[id=imagesContainer]`)
              attachments ? attachments.innerHTML = ("") : null
              setFile(null)
              setPdfFile(null)
              setMatchedData(null)
              setExactData(null)
              setDataLogFile(null)
            } else {   
            setAddNewTest(false)
            setMode && setMode(null)                
            !mode ? setActive(null) : setAddNewTest(false)
            setDataLogFile(null)
            setPdfFile(null)
            setMatchedData(null)
            setExactData(null)
            setCurrentTest(null)
            setFinalData([])
            setMyState("data")
            setPullingDataActive(0)
            setPdfFileNotFound(false)
            setPdfImages([])
            setNewPdfImages([])
            setFileError(null)
            setMistakeArea(null)
            setMistakeLocation(null)
            setMistakeFilter(null)
            let attachments = document.querySelector(`[id=imagesContainer]`)
            attachments ? attachments.innerHTML = ("") : null
            setFile(null)
            resetActiveTest()
            }
          }}
            >

            Cancel {onFlyTest ? "Extra mode" : ""}

          </div>
            <div
            style={{margin:".5rem"}}
              className={`${ matchedData && (file || pdfFile) ? !loadingSpinnerActive ? "auth-button addUser" : "auth-button addUser disable-button" : "auth-button addUser disable-button"}`}
              onClick={ matchedData && (file || pdfFile) ? !loadingSpinnerActive ? () => {
                      if(mistakeFilter) {
                        matchedTest.mistake = mistakeFilter
                        matchedTest.areaCode = mistakeFilter.correctArea
                        matchedTest.locationCode = mistakeFilter.correctLocation
                        
                      }
                      if(mode) {
                        addExtraData(matchedTest)
                      } else {
                        dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView]: [...selectedTests[selectedAudit?.defaultView]?.filter(x => {
                          if(matchedTest.locationId == x.locationId) {
                          if(x.areaCondition !== matchedTest.areaCondition) {
                            return x
                          } else {
                            return x
                          }
                        } else {
                          return x
                        }
                        }), matchedTest]}))
                      }
                      setDataLogFile(null)
                      setPdfFile(null)
                      setMatchedData(null)
                      setExactData(null)
                      setCurrentTest(null)
                      setFinalData([])
                      setPdfFile(null)
                      setMyState("data")
                      setPullingDataActive(0)
                      setActive(null)
                      setPdfFileNotFound(false)
                      setPdfImages([])
                      setNewPdfImages([])
                      setAddNewTest(false)
                      resetActiveTest()
                      } : null : null
                      }
                    >
              {!loadingSpinnerActive ? active.includes("Edit") ? "Update" : "Add" : <DataSpinner small="s" />}

            </div>
          </div>
        </div> : null}
      </Modal>
  )}

  export default FileUploaderModal
       