import { gql } from "apollo-boost";

const addSiteMutation = gql`
  mutation(
    $name:String,
    $address:String,
    $client:ID,
    $user:ID,
    $contactPersonName: String,
    $contactPersonEmail: String,
    $contactPersonPhoneNumber: String
    $status:Boolean,
    $notes:String,
    $zip: String,

  ) {
    addSite(
    name:$name
    address:$address
    client:$client
    user:$user
    contactPersonName:$contactPersonName,
    contactPersonEmail:$contactPersonEmail,
    contactPersonPhoneNumber:$contactPersonPhoneNumber,
    status:$status
    notes:$notes
    zip: $zip

    ) {
    _id
    name
    address
    client
    user
    contactPersonName,
    contactPersonEmail,
    contactPersonPhoneNumber
    status
    notes
    zip
  }
  }
`;

export default addSiteMutation;
