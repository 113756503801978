import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import LZString from 'lz-string';
import BeforeLoadModal from '../beforeLoadModal';
import { DataSpinner } from '../../spinner/dataSpinner';

const LoadAreaModal = (props) => { 

    const {
      setAddAreaDropDown,
        areas,
        selectedAudit,
        loadAreaModal,
        setLoadAreaModal,
        selectedAreaToLoad,
        setSelectedAreaToLoad,
        allAreasToLoad,
        draftsAreasToLoad,
        loadAreaFunc,
        editArea
    } = props

    // useEffect(() => {
    //   if(allAreasToLoad) {
    //     setSelectedAreaToLoad(allAreasToLoad[0])
    //   }
    // },[allAreasToLoad])

    const [more, setMore] = useState(false)

    const [beforeLoadModal, setBeforeLoadModal] = useState(null)

    const beforeLoadAreaFunc = () => {
      editArea(selectedAreaToLoad, "load")
      setLoadAreaModal(null)
    }   
  return( 
<Modal
        isOpen={loadAreaModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
             
              
              {loadAreaModal ? allAreasToLoad ? allAreasToLoad?.length > 0 ?  (
               <div className="modal task-modal" style={{width:"90vw", height:"100vh", overflow:'hidden'}}>
                 <BeforeLoadModal
                 exist={areas?.some(x => x.name == selectedAreaToLoad?.name || x.areaNumber == selectedAreaToLoad?.areaNumber)}
                 draft={selectedAreaToLoad?.type == "draft" ? true : false}
                 beforeLoadModal={beforeLoadModal}
                 setBeforeLoadModal={setBeforeLoadModal}
                 beforeLoadAreaFunc={beforeLoadAreaFunc}
                 noHandleClick={loadAreaFunc}
                 />
               <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setAddAreaDropDown(true)
                  setLoadAreaModal(null)
                  setSelectedAreaToLoad(null)
                }}
              />
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>Load Existing Area</h1>
          <div className="form-row" style={{flexDirection:"column", marginTop:"40px"}}>
            <div className="form-collection" style={{width:"100%", fontWeight:"bold"}}>
              Choose previously created area from list
            </div>
            </div>
          <div className="form-group-container task-form" style={{height:"100vh", flexDirection:"row", margin:"10px 0", justifyContent:"flex-start", }}>
              <div className='modalSideBar' style={{overflowY:"auto", height:"70%"}}>
                  {
                      allAreasToLoad && (
                          allAreasToLoad.filter(x => x?.site == selectedAudit?.site?._id).map((area, i) => (
                            <div key={i} className="versionCardContainer">
                            <div className={`versionCard templatecontainer ${ area && area.id == selectedAreaToLoad?.id && "selectedHistory"}`} onClick={() => setSelectedAreaToLoad(area)}>
                                <div className='userSquareHeader'>{area?.name}</div>
                                <div className='squareContent' style={{color: area.type && "#f0ad4e"}}>{area.type ? area.type : "existing"}</div>
                            </div>
                            </div>
                          )) 
                      ) 
                  } 
          </div>
          {
                                selectedAreaToLoad ? (
                                    <div className='historyViewerContainer'>
                                        <div  className="reportsContainer areaContainer">
            <div style={{marginBottom:"2.5rem"}} className="areaUpperContainer responsiveArea">
              <div className="areaCard testingDiagramCard" style={{height:"100%", width:"fit-content"}}>
                            <div className="areaCardType">
                                <div className="typeHeader testingDiagramHeader" style={{justifyContent:"space-between", display:"flex"}}><span>testing diagram</span></div>
                                <div className="typeContent" style={{position:"relative", width:"250px", height:"250px"}}>
                                    
                                    <> <img width={"250px"} height={"250px"} src={selectedAreaToLoad.diagramImage}/>
                                    </>
                                </div>
                            </div>
                            <div className="diagramDetails">
                                <div className="diagramDetailsContainer"> 
                            <div className="areaCardType">
                                <div className="typeHeader">date</div>
                                <div className="typeContent">{selectedAreaToLoad.createdAt ? selectedAreaToLoad.createdAt : "unknown"}</div>
                            </div>
                            <div className="areaCardType">
                                <div className="typeHeader">created by</div>
                                <div className="typeContent">{selectedAreaToLoad.createdBy ? selectedAreaToLoad.createdBy : "unknown"}</div>
                            </div>
                            </div>
                            </div>
                    </div> 
                    <div className="rightCards" >                      
                    <div className="rightLower">
                        <div className="areaUpperContainer" style={{height:"fit-content"}}>
                            <div className="areaCard">
                                <div className="areaCardType">
                                    <div className="typeHeader">Floor Type</div>
                                    <div className="typeContent">{selectedAreaToLoad.floorType}</div>
                                </div>
                                {/* <div className="areaCardType">
                                    <div className="typeHeader">Testing Condition</div>
                                    <div className="typeContent">{selectedAreaToLoad.testingCondition ? selectedAreaToLoad.testingCondition : "any"}</div>
                                </div> */}
                                <div className="areaCardType">
                                    <div className="typeHeader">Testing Category</div>
                                    <div className="typeContent">{selectedAreaToLoad.testingCategory ? selectedAreaToLoad.testingCategory : "any"}</div>
                                </div>
                                { selectedAreaToLoad.floorTypeDescription && <div className="areaCardType">
                                    <div className="typeHeader">Floor Type Description</div>
                                    <div className="typeContent">{selectedAreaToLoad.floorTypeDescription}</div>
                            </div> }
                    <div className="areaCardType">
                                <div className="typeHeader">floor finish</div>
                                <div className="typeContent">{selectedAreaToLoad.floorFinish}</div>
                            </div>
                           { selectedAreaToLoad.floorFinishDescription &&  <div className="areaCardType">
                                <div className="typeHeader">floor finish Description</div>
                                <div className="typeContent">{selectedAreaToLoad.floorFinishDescription}</div>
                            </div> }
                            {selectedAreaToLoad?.floorTexture && <div className="areaCardType">
                        <div className="typeHeader">Floor Texture</div>
                        <div className="typeContent">{selectedAreaToLoad.floorTexture}</div>
                    </div>}
                            <div className="areaCardType">
                        <div className="typeHeader">Floor Condition</div>
                        <div className="typeContent">{selectedAreaToLoad.conditionofFloorFinish ? selectedAreaToLoad.conditionofFloorFinish : "N/A"}</div>
                    </div>
                    <div className="areaCardType">
                        <div className="typeHeader">Slope Surface</div> 
                        <div className="typeContent">{selectedAreaToLoad.slopeofFloorsSurface}</div>
                    </div>
                        </div> 
                        <div className="areaCard">
                        <div className="areaCardType areaNotes">
                        <div className="typeHeader">notes</div>
                        <div className="typeContent">{selectedAreaToLoad.auditorsNotes ? selectedAreaToLoad.auditorsNotes : "No notes"}</div>
                    </div>

                   
                    <div className="areaCardType areaImages">
                        <div className="typeHeader">photos</div>
                        {selectedAreaToLoad.images && selectedAreaToLoad.images.length > 0 && <div className="typeContent">{selectedAreaToLoad.images.map((image, i) => (
                            <div  key={i} style={{margin:"5px"}}>
                            <img
                            className="areaImage"
                            src={image}
                            alt="areaImage" 
                          />
                          </div>
                        ))}
                        </div>}
                    </div>
                    
                        </div>
                        </div>

                    </div>
                {/* <div className="areaMiddleContainer" style={{margin:"0"}}>
                    <div className={`${more ? "auditExpanded auditReports": "auditReports"}`} style={{padding:"16px 5px"}}>
                <div style={{display:"flex", justifyContent:"space-between", cursor:"pointer", padding:"5px 20px", borderRadius:"8px"}}
                onClick={() => {
                  setMore(!more)                       
                }}  className="userSquareHeader hover">
                    <div style={{
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center"
                    }}
                    className="areaBar"
                    >
                    <span className="typeHeader">More information</span>
                    </div> 
                    <FontAwesomeIcon className={`${more ? "collapseArrow collapseActive" : "collapseArrow"}`} onClick={() => {
                        setMore(!more)                       
                      }}  icon={faChevronDown}/> 
                </div>
                <div className={`${more ? "areaContainerOn" : "areaContainerOff"}`}>
                    <div className="rightUpper">
                            <div style={{justifyContent:"flex-start"}} className="areaCard">
                                    <div className="userSquareHeader">audit trail of testing diagram</div>
                                    <div className="areaCardType">
                                        <div className="typeHeader">title</div>
                                        <div className="typeContent">text</div>
                                    </div>                            

                            </div>
                            <div style={{justifyContent:"flex-start"}} className="areaCard">
                                    <div className="userSquareHeader">audit trail on changes</div>
                                    <div className="areaCardType">
                                        <div className="typeHeader">title</div>
                                        <div className="typeContent">text</div>
                                    </div>                            

                            </div>
                        </div>
                </div>
            </div>
                    </div>             */}
                    </div>
                    </div>        
                </div>
                </div>
                                    
                                ) : 
                                    (
                                    <div style={{height:"70%", display:"flex", justifyContent:"center", alignItems:"center", overflow:"auto"}} className='historyViewerContainer'>
                                        <div>
                                          Select area to review  
                                        </div>                                    
                                    </div>
                                    ) 
                              }
          </div> 
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

            <div
            style={{margin:".5rem"}}
              className={`${selectedAreaToLoad ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={selectedAreaToLoad ? () => setBeforeLoadModal("Area") : null }
            >
              Load
            </div>
          </div>
        </div>) : (
             <div className="modal task-modal" style={{width:"fit-content", height:"fit-content", display:"flex", alignItems:"flex-end"}}>
                <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                style={{position:"static"}}
                onClick={() => {
                  setLoadAreaModal(null)
                }}
              />
               <div style={{fontSize:"19px", textTransform:"capitalize", fontWeight:"bold", color:"gray"}}>seems like this site have no area to load</div>
             </div>
        ) : <DataSpinner /> : null  }
      </Modal>
  )}

  export default LoadAreaModal
       