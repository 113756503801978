import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserNewPassword from '../../../admin/adminSignin/NewPassword/UserNewPassword';
import UserConfirmNewPassword from '../../../admin/adminSignin/NewPassword/UserConfirmNewPassword';
import { useSelector } from 'react-redux';

const ChangePasswordModal = (props) => {
    const {
        open,
        setOpen,
        changePassword,
        setOldPassword,
        oldPassword,
        passwordError, 
        setPasswordError
        } = props


        const confirmNewPassword = useSelector(
          (state) => state.confirmNewPassword.confirm_new_password
        );
        const newPasswordValid = useSelector(
          (state) => state.newPasswordValid.new_password_valid
        );
        const confirmNewPasswordValid = useSelector(
          (state) =>
            state.confirmNewPasswordValid.confirm_new_password_valid
        );

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal">
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setPasswordError(null)
                  setOldPassword("")
                  setOpen(false)}}
              />

          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Change Password</h1>
          <h1 className="header" style={{width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>Are you sure you want to change your password</h1>

          {/* <span style={{margin:"15px 0"}}>Press change and you will recieve an email to change your password</span> */}
          <div className="form-collection">
              <div className="form-label"><span>Old Password<span style={{color:"red"}}> *</span></span></div>
              <input
                onChange={(e) => {
                  setPasswordError(null)
                  setOldPassword(e.target.value)
                }}
                value={oldPassword}
                type="text"
                name='oldPassword'
                autoComplete="new-password"
                placeholder="Your Old Password"
                className="form-input"
              />
              {passwordError && (
                <div className="auth-error" style={{textTransform:"none"}}>
                  {passwordError}
                </div>
              )}
            </div>
            <div className="form-collection">
              <UserNewPassword
              />
            </div>

            <div className="form-collection" style={{marginBottom:"30px"}}>
              <UserConfirmNewPassword
              />
            </div>

            <div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>

                <div
                
                    className={`auth-button addUser confirmDelete deleteButton ${!oldPassword || passwordError || !newPasswordValid || !confirmNewPasswordValid || !confirmNewPassword ? "noDelete" : "non"}`}
                    onClick={oldPassword && !passwordError && newPasswordValid && confirmNewPasswordValid && confirmNewPassword ? () => {
                      changePassword()
                    } : null
                    }
                >
                Change
                </div>

            </div>
        </div>
      </Modal>
  )}

  export default ChangePasswordModal
       