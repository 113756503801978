import React, { useEffect, useState } from "react";
import "./adminLicenses.scss"
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import { AccountTable as LicensesTable }from "../../resuable/accountTable";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import AddLicenseModal from "../../resuable/modals/addLicenseModal";
import addLicenseMutation from "../../../graphql/mutations/license/addLicenseMutation";
import moment from "moment";
import updateLicenseMutation from "../../../graphql/mutations/license/updateLicenseMutation";

const AdminLicenses = ({licensesRefetch, users, licenses, user, auditAssignOptions, usersRefetch}) => {

    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    // licenses 
    const [selectedLicense, setSelectedLicense] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory()
    const assignLicense = (row) => {
        setSelectedLicense(row)
        setLicenseModal("Assign License")
    }

    const licensesColumns = [
        
        {
            name: "assign",
            cell: (row) => row.assignee ? <div style={{color:"#6DB240", fontSize:"14px"}}>Assigned</div> : <div className="auth-button addUser" onClick={() => {
                history.push({
                    pathname:"/admin/users",
                    state:{
                     licenseNumber:row.licenseNumber
                    }
                  })
            }} style={{width:"fit-content", height:"fit-content", padding:".5rem .7rem"}}>Assign</div>,
            ignoreRowClick: true,
            allowOverflow: false,
            button: false,
            style:{
                fontSize:"19px"
            }
        },
        {
            name: 'license number',
            selector: row => row.licenseNumber,
            
        },
        {
            name: 'created by',
            selector: row => row.createdBy,

        },
        {
            name: 'User',
            selector: row => row.userName,

        },
        {
            name: 'creation date',
            selector: row => row.createdDate,

        },
        {
            name: 'expiration date',
            cell: row => <div style={{color: moment().add("2", "week").isAfter(moment(row.endDate)) ? "#EF1111" : "black"}}>{row.expirationDate}</div>,

        },
        {
            name: 'notes',
            cell: (row) => <div title={row.notes} className="preW">{row.notes}</div>,
            width:"300px"

        },
        {
            name: 'users',
            selector: row => row.users,

        },
        {
            name: 'tier',
            selector: row => row.licenseType,

        },
        {
            name: 'free reports',
            selector: row => <div style={{color: row.freeReports <= 1 ? "#EF1111" : "black"}}>{row.freeReports}</div>,

        },
        {
            name: 'purchased reports',
            selector: row => <div style={{color: row.purchasedReports <= 1 ? "#EF1111" : "black"}}>{row.purchasedReports || 0}</div>,

        }
    ];
    
    const [
        addLicense,
        { data: addLicenseData },
      ] = useMutation(addLicenseMutation)
  
      const [
        updateLicense,
        { data: updateLicenseData },
      ] = useMutation(updateLicenseMutation)
  
      
    const [licenseModal, setLicenseModal] = useState(null)
    const [freeReports, setFreeReports] = useState("")
    const [licenseUser, setLicenseUser] = useState("")
    const [licenseNotes, setLicenseNotes] = useState("")
    const [expirationDate, setExpirationDate] = useState(null);

    const addLicenseFunc = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setLicenseModal(null)
      addLicense({
        variables:{
           user: licenseUser?.value,
           createdBy: user?.user?.name,
           notes: licenseNotes
        }
      })
      .then(() => {
        setFreeReports("")
        setExpirationDate("")
        setLicenseNotes("")
        usersRefetch()
        licensesRefetch()
      })
      

    }
    
    const updateLicenseFunc = () => {
        console.log("d")
  
      }

      const assignLicenseFunc = () => {
            dispatch(ACTION_LOADING_SPINNER_ACTIVE())
            setLicenseModal(null)  
            updateLicense({
                variables:{
                    _id: selectedLicense.id,
                    user: licenseUser?.value
                }
            }).then(() => {
                setSelectedLicense(null)
                setLicenseUser("") 
                usersRefetch()
                licensesRefetch()
            
            })
            
      }
 

    const [licensesData, setLicensesData] = useState(null)

    useEffect(() => {
        if(licenses && users?.length) { 
            setLicensesData(
              licenses.map((license) => {
                let activeUser = users.find(x => x._id == license.user)
 
                const mappingUser = {
                    id: license._id, 
                    assignee: license.user,
                    createdBy: license.createdBy,
                    licenseNumber: license.number,
                    createdDate: license.dateIssued,
                    endDate: license.endDate,
                    expirationDate: moment(new Date(license.endDate)).format("MM/DD/YYYY"),
                    notes: license.notes,
                    users: license.users,
                    freeReports: license.freeReports, 
                    purchasedReports: license.purchasedReports,
                    licenseType: license.licenseType,
                    userName: activeUser?.name,
                }                                                                            
                
                return mappingUser
            })
            )
            dispatch(ACTION_LOADING_SPINNER_RESET())
        }
    },[licenses, users])

    const subScreen = (id) => {
        console.log(id)
    }
    
    return (
        <div className="adminContent">
                        {returnToHomeScreen()}
        <AddLicenseModal 
            licenseModal={licenseModal}
            setLicenseModal={setLicenseModal}
            freeReports={freeReports}
            setFreeReports={setFreeReports}
            licenseNotes={licenseNotes}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate }
            setLicenseNotes={setLicenseNotes}
            licenseUser={licenseUser}
            setLicenseUser={setLicenseUser}
            addLicenseFunc={addLicenseFunc}
            updateLicenseFunc={updateLicenseFunc}
            setSelectedLicense={setSelectedLicense}
            assignLicenseFunc={assignLicenseFunc}
            auditAssignOptions={auditAssignOptions}
        />
        <LicensesTable
             accountColumns={licensesColumns}
             accountData={licensesData || []}
             loading={!licenses}
             accountTabs={false}
             setLicenseModal={setLicenseModal}
             accountType="License" 
             subScreen={subScreen}
            />

        </div>
    )
}

export default AdminLicenses