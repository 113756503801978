import React, { useEffect, useRef, useState } from "react";
import "./adminProjects.scss"
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import AuditHeader from "./auditHeader"
import { AccountTable as AuditsTable } from "../../resuable/accountTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown, faChevronUp, faEllipsisV, faInfoCircle, faPen, faPlusCircle, faTh, faThLarge } from "@fortawesome/free-solid-svg-icons";
import AddAuditModal from "../../resuable/modals/addAuditModal";
import { useDispatch, useSelector, } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import AccountHeader from "../../resuable/accountHeader"
import ACTION_BACK_ROUTE from "../../../actions/route/ACTION_BACK_ROUTE";
import ACTION_MENU_CLICKED_RESET from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED_RESET";
import ACTION_MENU_CLICKED from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED";
import UserInformationCard from "../adminUsers/userInformationCard";
import AuditArea from "./auditArea";
import AuditCard from "./auditCard";
import edit from "../../../assests/icons/edit.svg"
import add from "../../../assests/icons/add.svg"
import allSitesQuery from "../../../graphql/queries/site/allSitesQuery";
import allAreasQuery from "../../../graphql/queries/area/allAreasQuery";
import allViewsQuery from "../../../graphql/queries/view/allViewsQuery";
import allLocationsQuery from "../../../graphql/queries/location/allLocationsQuery";
import allDiagramsQuery from "../../../graphql/queries/diagram/allDiagramsQuery";
import addProjectMutation from "../../../graphql/mutations/project/addProjectMutation";
import addViewMutation from "../../../graphql/mutations/view/addViewMutation";
import updateViewMutation from "../../../graphql/mutations/view/updateViewMutation";
import deleteViewMutation from "../../../graphql/mutations/view/deleteViewMutation";

import addAreaMutation from "../../../graphql/mutations/area/addAreaMutation";
import addDiagramMutation from "../../../graphql/mutations/diagram/addDiagramMutation"

import updateProjectStatusMutation from "../../../graphql/mutations/project/updateProjectStatusMutation"
import { useLazyQuery, useMutation } from "@apollo/client";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import AddAreaModal from "../../resuable/modals/addAreaModal";

import imageCompression from "browser-image-compression";
import LZString from "lz-string";
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
import moment from "moment"
import updateProjectMutation from "../../../graphql/mutations/project/updateProjectMutation";
import updateAreaMutation from "../../../graphql/mutations/area/updateAreaMutation";
import { Style } from "react-style-tag";
import parse from 'html-react-parser';
import RunTestModal from "../../resuable/modals/runTestModal";


import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import { useShapes } from "../../resuable/modals/addAreaModal/diagramWrapper/state";
import ACTION_SELECTED_TESTS from "../../../actions/SelectedTests/ACTION_SELECTED_TESTS";
import ACTION_SELECTED_AUDIT from "../../../actions/SelectedAudit/ACTION_SELECTED_AUDIT";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip } from "reactstrap";
import LoadAreaModal from "../../resuable/modals/LoadAreaModal";
import CommentsModal from "../../resuable/modals/commentsModal";
import TileModal from "../../resuable/modals/tileModal";
import ConditionModal from "../../resuable/modals/runTestModal/ConditionModal";
import addLocationMutation from "../../../graphql/mutations/location/addLocationMutation";
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'
import SuggestModal from "../../resuable/modals/suggestModal";
import AddViewConfirmModal from "../../resuable/modals/addViewConfirmModal";
import AuditHistoryModal from "../../resuable/modals/auditHistoryModal";
import updateViewTileMutation from "../../../graphql/mutations/view/updateViewTileMutation";
import WrongDataModal from "../../resuable/modals/WrongDataModal";
import WrongValidateModal from "../../resuable/modals/WrongValidateModal";
import BeforeGenerateModal from "../../resuable/modals/BeforeGenerateModal";
import allAuditHistoryQuery from "../../../graphql/queries/audiyHistory/allAuditHistoryQuery";
import PrintModal from "../../resuable/modals/printModal";
import updateLocationMutation from "../../../graphql/mutations/location/updateLocationMutation"
import Spinner from "../../resuable/spinner";
import FileUploaderModal from "../../resuable/modals/runTestModal/fileUploader";
import ImagesLaterModal from "../../resuable/modals/imagesLaterModal";
import deleteLocationMutation from "../../../graphql/mutations/location/deleteLocationMutation";
import Pagination from "../../resuable/pagination/Pagination";
import projectQuery from "../../../graphql/queries/project/projectQuery";
import Logo from "../../../assests/images/logo.png";
import viewQuery from "../../../graphql/queries/view/viewQuery";
import useAwsCredentials from "../../hooks/useAws";
import ExtractImages from "../../../fixer";
import { PDFDocument } from 'pdf-lib'; // npm install pdf-lib

 
const AdminProjects = (props) => {
  

  const { credentials, loading, error, fetchCredentials} = useAwsCredentials();  // Use the custom hook to fetch credentials


  const [areasToUpdate, setAreasToUpdate] = useState([])
  const [printMode, setPrintMode] = useState(null)
  const [printedAreas, setPrintedAreas] = useState([])
  const [printModal, setPrintModal] = useState(false)
  const [auditFixer, setAuditFixer] = useState(null)
  const [correctAudit, setCorrectAudit] = useState(null)

  useEffect(() => {
  },[areasToUpdate])
  const printFunc = () => {
    setPrintModal(false)
  }

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
  },[])
  

  const history = useHistory()

  const selectedTests = useSelector((state) => state.selectedTests.selectedTests)
  const selectedAuditId = useSelector((state) => state.selectedAudit.selectedAudit)

  const [images, setImages] = React.useState([])
  const [historyArr, setHistoryArr] = useState([])
  let [c, setC] = useState(null)
  let [char, setChar] = useState(null)
  let [charNotification, setCharNotification] = useState(null)
  let [theEnd, setTheEnd] = useState(null)    
  let [theReEnd, setTheReEnd] = useState(null)    
  let [eachHex, setEachHex] = useState("")
  let [eachPdfHex, setEachPdfHex] = useState("")    
  let [finalData, setFinalData] = useState([])
  let [finalPdf, setFinalPdf] = useState([])
  const [runTestModal, setRunTestModal] = useState(false)
  const [oneLocation, setOneLocation] = useState(false)
  const [cleanCondition, setCleanCondition] = useState("prevailing")
  const [testType, setTestType] = useState("DCOF")
  const [currentTest, setCurrentTest] = useState(null)
  const [contextCategory, setContextCategory] = useState(null)
  const [matchedTest, setMatchedTest] = useState(null)
  const [pdfFile, setPdfFile] = useState(null)
  const [pdfFileNotFound, setPdfFileNotFound] = useState(false)
  const [device, setDevice] = useState(null)
  const [pullingDataActive, setPullingDataActive] = useState(0)

  const [conditionModal, setConditionModal] = useState(null)

  const selectCondition = (area, condition) => {
    let newArea = area
    newArea.condition = condition
    setAreas(areas.map(x => x.id == newArea.id ? newArea : x))
    setConditionModal(null)
  }
  
    const {
      socket,
      auditClientsOptions, 
      auditAssignOptions,
      projects,
      projectsRefetch,
      projectsLoading,
      projectsCalled,
      user,
      defaultDCOF,
      addLocationLoad,
      setAddLocationLoad,
      selectedAudit,
      setSelectedAudit,
      onFlyAudit,
      setOnFlyAudit,
      projectData,
      setProjectData,
      projectsFilter,
      setProjectsFilter,
      allCompaniesFilter,
      setAllCompanies,
      allSitesFilter,
      setAllSites,
      allAdminsfilter,
      setAllAdmins,
      areas,
      setAreas,
      clientsRefetch,
      usersRefetch,
      currentPageAudits,
setCurrentPageAudits,
    } = props

    const [scrollToArea, setScrollToArea] = useState(null)
    const selectRef = useRef()
    const [
      addProject,
      { data: addProjectData }, 
    ] = useMutation(addProjectMutation)


    const [
      addView,
      { data: addViewData }, 
    ] = useMutation(addViewMutation)

    const [
      updateLocation,
      { data: updateLocationData },
    ] = useMutation(updateLocationMutation)

    const [
      deleteLocation, 
      { data: deleteLocationData },
    ] = useMutation(deleteLocationMutation)


    const [
      updateView,
      { data: updateViewData }, 
    ] = useMutation(updateViewMutation)


    const [
      deleteView,
      { data: deleteViewData, loading: deleteViewLoading }, 
    ] = useMutation(deleteViewMutation)



    const [
      allSites,
      {
        data: allSitesData,
        refetch: allSitesRefetch
      },
    ] = useLazyQuery(allSitesQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      allAreas,
      {
        data: allAreasData,
        refetch: allAreasRefetch,
        loading: allAreasLoading
      },
    ] = useLazyQuery(allAreasQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      allAuditHistory,
      {
        data: allAuditHistoryData,
        refetch: allAuditHistoryRefetch,
        loading: auditHistoryLoading
      },
    ] = useLazyQuery(allAuditHistoryQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      allViews,
      {
        data: allViewsData,
        refetch: allViewsRefetch,
        loading: allViewsLoading
      },
    ] = useLazyQuery(allViewsQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      allAreasToLoadFunc,
      {
        data: allAreasDataToLoad,
        refetch: allAreasRefetchToLoad,
        called: allAreasCalledToLoad,
        loading: allAreasLoadingToLoad
      },
    ] = useLazyQuery(allAreasQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      allLocations,
      {
        data: allLocationsData,
        refetch: allLocationsRefetch,
        loading: allLocationsLoading
      },
    ] = useLazyQuery(allLocationsQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      allDiagrams,
      {
        data: allDiagramsData,
        refetch: allDiagramsRefetch
      },
    ] = useLazyQuery(allDiagramsQuery, {
      fetchPolicy: "no-cache",
    });
    
    const dispatch = useDispatch()
    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const [
      addLocation,
      { data: addLocationData },
    ] = useMutation(addLocationMutation)


    const menu_clicked = useSelector((state) => state.menuClicked.menu_clicked);
    const backRoute = useSelector((state) => state.backRoute.backRoute);
    const menuToggle = (row) => {
      menu_clicked ? dispatch(ACTION_MENU_CLICKED_RESET()) : dispatch(ACTION_MENU_CLICKED(row))
     }
    const [uploaderModal, setUploaderModal] = useState(null)
    const [matchedData, setMatchedData] = useState(null);
    const [lastAudit, setLastAudit] = useState(null)
    const [activeView, setActiveView] = useState("grid")
    const [auditHistoryActive, setAuditHistoryActive ] = useState(null)
    const [openSurface, setOpenSurface] = useState(false)


    const [
      getProject,
      {
        data: oneProjectData,
        refetch: oneProjectRefetch,
        called: oneProjectCalled,
        loading: oneProjectLoading
      },
    ] = useLazyQuery(projectQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      getView,
      {
        data: oneViewData,
        refetch: oneViewRefetch,
        called: oneViewCalled,
        loading: oneViewLoading
      },
    ] = useLazyQuery(viewQuery, {
      fetchPolicy: "no-cache",
    });


    const fixAuditFunc = (htmlString) => {
      const container = document.createElement("div");
      container.innerHTML = htmlString;
      
      const extractedData = { areas: {}, images: [] };
  
      // Select all area cards from Appendix A
      container.querySelectorAll('div[cardattr="areaCard"]').forEach(areaDiv => {
          let areaName = "Unknown Area";
          let areaFullName = "Unknown Name";
          
          // Extract area number from the header
          const areaNameElement = areaDiv.querySelector('div[cardattr="typeHeader testingDiagramHeader"] span');
          if (areaNameElement) {
              const matchName = areaNameElement.innerText.match(/^(.*?)(?:\s(\d{3}))?$/);
              if (matchName) {
                  areaFullName = matchName[1].trim();
              }
              const match = areaNameElement.innerText.trim().match(/(\d{3})$/);
              if (match) {
                  areaName = match[1].trim();
              }
          }
  
          if (!extractedData.areas[areaName]) {
              extractedData.areas[areaName] = { areaName: areaFullName, locations: {} };
          }
  
          // Find and process all tables within this area
          areaDiv.querySelectorAll("table.avoid.i4njnc").forEach(table => {
              const rows = table.querySelectorAll("tr");
              rows.forEach((row, index) => {
                  const cells = row.querySelectorAll("td");
                  if (index === 0 || cells.length === 0) return;
                  
                  const location = cells[3].innerText.trim(); // Location column
                  if (!extractedData.areas[areaName].locations[location]) {
                      extractedData.areas[areaName].locations[location] = { images: [], records: [] };
                  }
                  
                  extractedData.areas[areaName].locations[location].records.push({
                      date: cells[0].innerText.trim(),
                      time: cells[1].innerText.trim(),
                      condition: cells[4].innerText.trim(),
                      sample: cells[5].innerText.trim(),
                      averageDCOF: cells[6].innerText.trim(),
                  });
              });
          });
      });
  
      // Extract images from Appendix B and match them to locations in Appendix A
      const sampleImagesMap = {}; // Temporary structure to hold images by sample number
      
      container.querySelectorAll("div.avoid[style*='position: relative;']").forEach(appendixBEntry => {
          const areaTextElement = Array.from(appendixBEntry.querySelectorAll("div div"))
              .find(div => div.innerText.includes("Area Name :") && div.childElementCount === 0);
          if (!areaTextElement) return;
  
          const match = areaTextElement.innerText.trim().match(/Area Name\s*:\s*(.+)/);
          if (!match) return;
  
          const appendixBAreaName = match[1].trim();
          const correspondingAreaKey = Object.keys(extractedData.areas).find(
              key => extractedData.areas[key].areaName === appendixBAreaName
          );
          if (!correspondingAreaKey) return;
  
          // Extract sample number
          const sampleText = appendixBEntry.querySelector("div div[style*='rotate(270deg)']");
          const sampleMatch = sampleText ? sampleText.innerText.trim().match(/Sample (\d+)/) : null;
          const sampleNumber = sampleMatch ? sampleMatch[1] : "Unknown Sample";
  
          // Extract image URL
          const imgElement = appendixBEntry.querySelector("img");
          if (imgElement && imgElement.src.startsWith("https://step-prod-bucket.s3.amazonaws.com")) {
              if (!sampleImagesMap[sampleNumber]) {
                  sampleImagesMap[sampleNumber] = [];
              }
              sampleImagesMap[sampleNumber].push(imgElement.src);
          }
      });
      
      // Distribute images to locations in order
      Object.keys(extractedData.areas).forEach(areaKey => {
          const locations = Object.keys(extractedData.areas[areaKey].locations);
          if (locations.length === 0) return;
  
          ["1", "2", "3"].forEach(sample => {
              if (sampleImagesMap[sample]) {
                  sampleImagesMap[sample].forEach((src, index) => {
                      const targetLocation = locations[index % locations.length];
                      extractedData.areas[areaKey].locations[targetLocation].images.push({ sample, src });
                  });
              }
          });
      });
  
      // Extract audit photos
      const auditPhotoDiv = container.querySelector("div[variable='AuditPhotos']");
      if (auditPhotoDiv) {
          auditPhotoDiv.querySelectorAll("img").forEach(img => {
              if (img.src) {
                  extractedData.images.push(img.src);
              }
          });
      }
      
      return extractedData;
  };
    

    const [fixAudit, setFixAudit] = useState(false)
    const [fixModal, setFixModal] = useState(false)
    const [structuredImages, setStructuredImages] = useState(null)
    const subScreen = async (row, refetch, run, surface, idOfProject, isView, generate, fix, structuredImages) => {
      fix ? setFixAudit(true) : setFixAudit(false)
      fix ? setStructuredImages(structuredImages) :  setStructuredImages(null)
      setAddLocationLoad(true)
      setPrintMode(false)
      if(row) {
      if(!lastAudit || (lastAudit && lastAudit.id !== row.id) || refetch) {
        !refetch && !run ? setAuditHistoryActive(true) : null
        !refetch && setAreas(null)
        setSelectedAudit(row)
        setLastAudit(row)
        !refetch && setWhichRun(null)
        setTileResult("")
        setSurface("")
        
        let views = await allViews({ 
          variables:{
            project: row.id
          } 
        })
        let areas = await allAreas({
          variables: {
            view: row.defaultView   
          }  
        })
        let history = await allAuditHistory({
          variables:{
            view: row.defaultView
          }
        })
 
        let toLoad = !refetch && await allAreasToLoadFunc({
          variables: {
            site: row.site._id
          }
        }) 
        
        let locations = await allLocations({
          variables: {
            view: row.defaultView
          }
        })  
        dispatch(ACTION_BACK_ROUTE({backRoute: "audits", routeName: "Audit Details"}))
        setAddLocationLoad(false)
      } else {
        dispatch(ACTION_BACK_ROUTE({backRoute: "audits", routeName: "Audit Details"}))
        setSelectedAudit(row)
        !refetch && setWhichRun(null) 
        setTileResult("")
        setSurface("") 
        setAddLocationLoad(false)
      }
        setButtonDisabled(true)
        setAddAreaDropDown(true)    
        run && setRunTestModal(true)
        surface && setOpenSurface(true)
    } else { 

      if(isView) {
        getView({
          variables:{
            _id: idOfProject
          }
        }).then(res => {
          let project = res.data.view.project


      getProject({ 
        variables: {
          _id: project
        }
      }).then(res => {
        let project = res.data.project
         const mappingProject = {
              id: project._id,
              count: project.count,
              views: project.views,
              status: project.status,
              client: project.client,
              site: project.site,
              assignee: project.assignee && project.assignee,
              name: project.client.companyName,
              address: project.site.name,
              auditStatus: project.state,
              createdBy: project.creator.name,
              createdByRole: project.creator.role,
              createdById: project.creator._id,
              createdByType: project.creator.adminType,
              assignToRole: project.assignee[0]?.role,
              createdOn: moment.unix(project.viewCreatedOn/1000).format("MM/DD/YYYY"),
              assignTo: project.assignee[0]?.name,
              assignToId: project.assignee[0]?._id,
              areas: project.areas,
              extra: project?.extra ? JSON.parse(project?.extra) : [],
              locations: project.locations,
              photo: Logo,
              state: project.state,
              comments: project.comment,
              tileResult: project.tileResult,
              firstTime: project.firstTime,
              defaultView: project.defaultView,
              hidden: project.hidden,
              viewCreatedOn: project.viewCreatedOn
          }
          subScreen(mappingProject, refetch, run, surface, null, null, true)
      })

        })
      } else {
    
      getProject({ 
        variables: {
          _id: idOfProject
        }
      }).then(res => {
        let project = res.data.project
         const mappingProject = {
              id: project._id,
              count: project.count,
              views: project.views,
              status: project.status,
              client: project.client,
              site: project.site,
              assignee: project.assignee && project.assignee,
              name: project.client.companyName,
              address: project.site.name,
              auditStatus: project.state,
              createdBy: project.creator.name,
              createdByRole: project.creator.role,
              createdById: project.creator._id,
              createdByType: project.creator.adminType,
              assignToRole: project.assignee[0]?.role,
              createdOn: moment.unix(project.viewCreatedOn/1000).format("MM/DD/YYYY"),
              assignTo: project.assignee[0]?.name,
              assignToId: project.assignee[0]?._id,
              areas: project.areas,
              extra: project?.extra ? JSON.parse(project?.extra) : [],
              locations: project.locations,
              photo: Logo,
              state: project.state,
              comments: project.comment,
              tileResult: project.tileResult,
              firstTime: project.firstTime,
              defaultView: project.defaultView,
              hidden: project.hidden,
              viewCreatedOn: project.viewCreatedOn
          }
          subScreen(mappingProject, refetch, run, surface, null, null , generate)
      })

    }

    }
      }   

      const [auditHistory, setAuditHistory] = useState(null)
      useEffect(() => {
        if(allAuditHistoryData) {
          setAuditHistory(allAuditHistoryData.allAuditHistory)
        }
      },[allAuditHistoryData])
    const [views, setViews] = useState(null) 
    const [locations, setLocations] = useState(null)
    const [warningModal, setWarningModal] = useState(null)
 
    const accountColumns = [
        // {
        //     cell: (row) => <div className="dotsVContainer">
        //     <FontAwesomeIcon style={{cursor:"pointer"}} onClick={() => {
        //       menuToggle(row)
        //     }} icon={faEllipsisV} /> 
        //     {menu_clicked && menu_clicked.id == row.id && (
        //       <div className="optionMenuContainer">
        //           <div className="option">Edit</div>
        //           <div className="option archive">Archive</div>
        //       </div>
        //     )}
        //     </div>,
        //     ignoreRowClick: true,
        //     allowOverflow: false,
        //     button: true,
        //     style:{
        //         fontSize:"19px",
        //         color:"#505463"
        //     }
        // },
        {
            name: 'client',
            selector: row => row.name,
            style: {
                fontSize:"13px",
                fontWeight:"bold",
                textTransform:"capitalize"
            }
        },
        {
          name: 'site',
          selector: row => row.address,
        },
        ,
        {
          name: 'status',
          cell: (row) => (
            <div onClick={() => subScreen(row)} style={{backgroundColor: row.auditStatus == "Scheduled" ? "#D89E42" : row.auditStatus == "In progress" ? "#D4D243" : row.auditStatus == "Finished - Approved" ? "#6DB240" : row.auditStatus == "Finished - Rejected" ? "#EF1111" : row.auditStatus == "Pending Approval" ? "#5C92BF" : "", width:"fit-content"}} className="audit-status">
                <span>{row.auditStatus}</span>
            </div>
            ),
        },
        {
          name: 'created by',
          cell: row => (
            <div onClick={() => subScreen(row)}>
              {row.createdBy} ({row.createdByRole})
            </div>
          ),

        },
        {
            name: 'created on',
            selector: row => row.createdOn,

        },
        {
            name: 'assigned to',
            cell: row => (
              <div onClick={() => subScreen(row)}>
                {row.assignTo ? `${row.assignTo} ${row?.assignToId == user?.user?._id ? `(you)` : `(user)`}` : "not assigned"} 
              </div>
            )

        },
        {
          name: 'Views',
          selector: row => row.views,
      },
        {
            name: 'areas',
            selector: row => row.areas,
        },
        {
            name: 'locations',
            selector: row => row.locations,

        },
        {
          name: 'active',
          cell: (row) => (
              <label style={{cursor:"pointer"}} onClick={() => updateProjectStatusFunc(row)} className="toggle-control">
                  <span className={`${row.status ? "checked" : "notChecked"}`}></span>
                  <span className="control"></span>
              </label>
              ),
          ignoreRowClick: true,
          allowOverflow: false,
          button: true,
          style:{
              fontSize:"19px"
          }
          
      },
    ];
    const conditionalRowStyles= [
      {
        when: row => !row.status,
        style: {
          backgroundColor: '#F5f5f5',
          opacity:".5"
        },
      },
    ];


    // filter audit

    const [companyFilter ,setCompanyFilter] = useState("")
    const [siteFilter, setSiteFilter] = useState("")
    const [createdByFilter, setCreatedByFilter] = useState("")
  
    useEffect(() => {
      if(siteFilter) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
    },[siteFilter])
    useEffect(() => {
        if(!backRoute) { 
          setSelectedAudit(null)
        }
      },[backRoute])


      // fetch audit informations

      useEffect(() => {
        if (projects) {
          if (projects.length > 0) {
            if (companyFilter) {
              setProjectsFilter(
              [...projects].filter((x) => {
                  return (
                  x.client.companyName == companyFilter.value
                  );
                })
              )
              setAllAdmins(projects.filter(x => x.client.companyName == companyFilter.value).map(project => (
                  project.creator.name
                ))
              )
              setAllSites(projects.filter(x => x.client.companyName == companyFilter.value).map(project => (
                  project.site.name
                ))
              )
            } 
            if (siteFilter) {
              setProjectsFilter(
              [...projects].filter((x) => {
                if(companyFilter) {
                  return (
                    x.site.name == siteFilter.value && x.client.companyName == companyFilter.value
                  )
                } else {
                  return (
                  x.site.name == siteFilter.value
                  )
                }
                })
              )
              setAllAdmins(
              projects.filter(x => x.site.name == siteFilter.value).map(project => (
                  project.creator.name
                ))
              )
            }
             if (createdByFilter) {
              setProjectsFilter(
              [...projects].filter((x) => {
                if(companyFilter && siteFilter) {
                  return (
                    x.creator.name == createdByFilter.value && x.site.name == siteFilter.value && x.client.companyName == companyFilter.value
                  )

                } else if (siteFilter) {
                  return (
                    x.creator.name == createdByFilter.value && x.site.name == siteFilter.value
                  )
                } else if (companyFilter) {
                  return(
                    x.creator.name == createdByFilter.value && x.client.companyName == companyFilter.value

                  )
                } else {
                  return (
                  x.creator.name == createdByFilter.value
                  )
                }
                })
              )
            }

            if(!createdByFilter && !siteFilter && !companyFilter) {
              setProjectsFilter(null)
            }
            
          }
        }
      }, [companyFilter, siteFilter, createdByFilter, projects]);

      useEffect(() => { 
          if(selectedAudit) {
            setAddLocationLoad(true)
            projectData && setSelectedAudit(projectData?.find(x => x.id == selectedAudit.id ))
            projectData && subScreen(projectData?.find(x => x.id == selectedAudit.id ), true)
          } else {
            setAddLocationLoad(false)
          }
      },[projectData])

      useEffect(() => { 
        if(onFlyAudit) {
          let selectedOnFly = projectData?.find(x => x.defaultView == onFlyAudit.id )
          if(selectedOnFly) {
            setAddLocationLoad(true)
            setSelectedAudit(selectedOnFly)
            subScreen(selectedOnFly, false, true)
            dispatch(ACTION_SELECTED_AUDIT(selectedOnFly?.id))
          }
        }
      },[onFlyAudit])
      let location= useLocation()  
      useEffect(() => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        if(location.state && projects) {
          let siteId
          let siteName
          let clientId
          let clientName

          if(location.state.siteFilter) {
            siteId = location.state.siteFilter
          }
          if(location.state.siteName) {
            siteName = location.state.siteName
          }
          if(location.state.clientFilter) {
            clientId = location.state.clientFilter
          }
          if(location.state.clientName) {
            clientName = location.state.clientName
          }

          if(location.state.siteFilter) {
            let filterSite = projects.filter(project => (
              project.site._id == location.state.siteFilter
            ))
            if(filterSite && filterSite.length) {
               let filterValue = {value: filterSite[0].site.name, label: filterSite[0].site.name}
                setSiteFilter(filterValue)
            } else {
              setSiteFilter({})
            } 
           
            let client = {}
            client.value = clientId
            client.label = clientName
            setAuditClient(client)
            let site = {}
            site.value = siteId
            site.lable = siteName
            // auditSitesOptions?.find(x=>x.value == site.value) ? setAuditSite(auditSitesOptions?.find(x=>x.value == site.value)) : null
            setTempSite(site.value)
            setAddAuditModal("Audit")
 
          }
        } else {
          dispatch(ACTION_LOADING_SPINNER_RESET())
        }
        return () => window.history.replaceState({}, document.title)
      },[location.state, projects])


      // audit history and handlign views

      const [addViewConfirmModal, setAddViewConfirmModal] = useState(false)      
      const addViewFunc = async (withTest) => {

        let myBucket = await fetchCredentials()

        if(myBucket) {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setAddViewConfirmModal(false)
        let auditDefaultView = selectedAudit?.defaultView
        if(withTest) {
          setUploaderModal(null)
          setRunTestModal(false)
          await new Promise((resolve, reject) => {
            const fileName = `${withTest.areaId}-${withTest.locationId}-${withTest.areaCondition}.pdf`
              const params = {
                  ACL: 'public-read',
                  Bucket: process.env.REACT_APP_S3_BUCKET,
                  Key : fileName, 
                  Body : withTest.pdf,
                  ContentType: "application/pdf" 
              }
        
              myBucket.putObject(params)
                  .on("complete", (evt) => {
                    if(evt.data) {
                    withTest.pdfUrl = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`
                        addView({ 
                          variables:{
                            project: selectedAudit.id,
                            data: withTest ? JSON.stringify([withTest]) : null
                          }
                        }).then(x => {
                            if(withTest) {
                              setWithTest(null)
                              setMatchedTest(null)
                              auditDefaultView && dispatch(ACTION_SELECTED_TESTS({[auditDefaultView] : null}))
                              rejectFile()
                            } 
                             projectsRefetch()
                        })
                    resolve()
                    }
                  })
                  .send((err) => {
                      if (err) {
                        rejectFile()
                        setUploaderModal("Location Data")
                        setRunTestModal(true)
                        dispatch(ACTION_LOADING_SPINNER_RESET())
                        reject()
                      }
                  }) 
          })
        } else {
          addView({ 
          variables:{
            project: selectedAudit.id,
          }
        }).then(x => {
            auditDefaultView && dispatch(ACTION_SELECTED_TESTS({[auditDefaultView] : null}))
            projectsRefetch()
        })
        }        
        
      } else {
        alert("Loading.")
        
      }
      }
      const addNewView = () => {
        setAddViewConfirmModal("manual")
      }

      const [auditHistoryModal, setAuditHistoryModal] = useState(false)
 
      const loadViewFunc = (id) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setAuditHistoryModal(false)
        let auditDefaultView = selectedAudit.defaultView
        updateView({
          variables:{
            _id: id,
            project: selectedAudit.id
          }
        }).then(x => {
          dispatch(ACTION_SELECTED_TESTS({[auditDefaultView] : null}))
          
          projectsRefetch()
        })
      }
 
      const deleteViewFunc = (id) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        deleteView({
          variables: {
            _id: id
          }
        }).then(x => {
          allViewsRefetch()
        }) 
      }
      const openHistoryModal = () => {
        allViewsRefetch()
        setAuditHistoryModal(true)
      }

      
      // add audit modal
      const [addAuditModal, setAddAuditModal] = useState(null)
      const [auditClient, setAuditClient] = useState("")
      const [auditSitesOptions, setAuditSitesOptions] = useState(null)
      const [tempSite, setTempSite] = useState(null)
      const [auditSite, setAuditSite] = useState("")
      const [auditAssignTo, setAuditAssignTo] = useState("")

      const addAuditFunc = () => {
        setAddAuditModal(null)
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        const clientObj = { client: {
          _id: auditClient.value,
          companyName: auditClient.label,
        }}

        const siteObj = { site:{
          _id: auditSite.value,
          name: auditSite.label,
        }}

        const creatorObj ={ creator:{
          _id : user?.user?._id,
          name: user?.user?.name,
          // type: user?.user?.type,
        }}

        const assigneeObj ={ assignee:[{
          _id: user?.user?._id,
          name: user?.user?.name
        }]} 
        const adminObj = { assignee:[]} 


        addProject({
          variables:{
            status: true, 
            assignee: user?.user?.role !== "admin" ? assigneeObj.assignee : adminObj.assignee,
            ...clientObj,
            ...siteObj,
            ...creatorObj
          }
        }).then(() => {
          // socket?.emit("new message", {users:[user.user._id], message:"yello there"})
         setAuditClient("")
         setAuditSite("")
         setAuditAssignTo("")
         projectsRefetch() 
        })
      }


      // update project func

      const [
        updateProject,
        { data: updateProjectData },
      ] = useMutation(updateProjectMutation)

      const [
        updateViewTile,
        { data: updateViewTileData },
      ] = useMutation(updateViewTileMutation)

      
      const [
        updateArea,
        { data: updateAreaData },
      ] = useMutation(updateAreaMutation)


      
  const updateAuditFunc = (type) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setEditAuditModal(null)
  
        const assigneeObj = [...auditAssignTo.map(x => {
          return {
           _id: x.value,
           name: x.label, 
         }})]
 
         const clientObj = { client: {
          _id: auditClient.value,
          companyName: auditClient.label,
        }}

        const creatorObj ={ creator:{
          _id : user?.user?._id,
          name: user?.user?.name,
          adminType: user?.user?.adminType,
        }}


        const siteObj = { site:{
          _id: auditSite.value,
          name: auditSite.label,
        }}

        const newAudit = auditUpdate
        if(type !== "assign") {
          newAudit.assignee=assigneeObj
          newAudit.client = clientObj.client
          newAudit.site = siteObj.site
          newAudit.name=auditClient.label
          newAudit.address=auditSite.label
        }
        
        newAudit.assignTo=auditAssignTo[0].label,
        auditUpdate ? setAuditUpdate(null) : setSelectedAudit(newAudit)
        setProjectData(projectData.map(t => t.id === newAudit.id ? newAudit : t))

        updateProject({
          variables: type!=="assign" ? { 
            _id: newAudit.id,
            assignee: assigneeObj,
            ...creatorObj,
            ...clientObj,
            ...siteObj,
          } : { 
            _id: newAudit.id,
            assignee: assigneeObj,
            ...creatorObj
          } 
        }).then((res) => {
          if(res?.data?.updateProject?.notification) {
            socket?.emit("new message", res?.data?.updateProject?.notification)
           }
          setAuditClient("")
          setAuditSite("")
          setAuditAssignTo("")
         projectsRefetch()
         dispatch(ACTION_LOADING_SPINNER_RESET())
        })  


      }

      useEffect(() => {
        if(auditClient) {
          setAuditSite("")
        allSites({
          variables: {
            client: auditClient.value,
            status: true
          }
        })
        }
      },[auditClient])

      useEffect(() => {
        if(allSitesData) {
          setAuditSitesOptions(allSitesData.allSites)
        }
      },[allSitesData])


      useEffect(() => {
          if(auditSitesOptions) {
            if(tempSite) {
              let exactSite = auditSitesOptions.find(x=> x._id == tempSite)
              let newSite = {}
              newSite.label = exactSite?.name
              newSite.value = exactSite?._id 
              setAuditSite(newSite)
            }
          }
      },[auditSitesOptions])

    // edit audit modal 

      const [editAuditModal , setEditAuditModal] = useState(null)
      const [auditUpdate , setAuditUpdate] = useState(null)


      const editAudit = (audit, type) => {
        setAuditUpdate(audit)
        let client = {}
        client.value = audit.client._id
        client.label = audit.client.companyName
        setAuditClient(client)
        let site = {}
        site.value = audit.site._id
        site.lable = audit.site.name
        setAuditSite(site)
        let assignTo = []
        assignTo = [...audit.assignee.filter(y => y?._id).map(x => {
            return {
            value: x?._id, 
            label: x?.name
          }
          
        })]
        setAuditAssignTo(assignTo)
        type ? setEditAuditModal("Assign Audit") : setEditAuditModal("Edit Audit")
      }
 
      
  // toggle audit status

  
  const [
    updateProjectStatus,
    { data: updateProjectStatusData },
  ] = useMutation(updateProjectStatusMutation)


  const updateProjectStatusFunc = (row) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    const newRow = row
    newRow.status = !newRow.status
    newRow.hidden = !newRow.status
    setProjectData(projectData.map(t => t.id === newRow.id ? newRow : t))
      updateProjectStatus({
        variables:{
          _id: newRow.id,
          status: newRow.status,
          hidden: user?.user?.role == "admin" ? newRow.hidden : false
        }
      }).then(() => {
        dispatch(ACTION_LOADING_SPINNER_RESET())
        projectsRefetch()
      })
  }


  // add area modal
        
    // images props

        
    useEffect(() => {
      if(projectsCalled) {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        projectsRefetch().then(x => { 
        dispatch(ACTION_LOADING_SPINNER_RESET()) 
        })
      }
    },[])
    const [takeAPhotoSelected, changeTakeAPhotoSelected] = useState(false);
    const [webcamURI, changeWebcamURI] = useState("");
    const [imageUploaded, changeImageUploaded] = useState("");
    const [imagePreviewAvailable, changeImagePreviewAvailable] = useState(false);
    const [addProfilePhotoClicked, setAddProfileClicked] = useState(false)

    
    const handleDeletedPreviewImage = () => {
        const deleteImageClass = document.getElementsByClassName("deleteImage");
        const uploadPictureClass = document.getElementsByClassName("uploadPicture");
    
        if (deleteImageClass) {
          if (deleteImageClass[0]) {
            deleteImageClass[0].style.display = "none";
          }
        }
        if (uploadPictureClass) {
          if (uploadPictureClass[0]) {
            uploadPictureClass[0].style.display = "none";
          }
        }
      };
    
   
      const handleImageUploaded = async (pictures) => {
        // dispatch(ACTION_IMAGE_LOADING());
        const uploadedImages = []
        if(pictures && pictures.length > 0) {
          pictures.map(async (picture, i) => {
          const reader = new FileReader();
          changeImagePreviewAvailable(true);
          try {
            let compressedImage;
    
            if (typeof picture === "object") {
              compressedImage = await imageCompression(picture, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 300,
              });
            } else if (typeof picture === "string") {
              await fetch(picture)
                .then((res) => {
                  return res.blob();
                })
                .then(async (blob) => {
                  compressedImage = await imageCompression(blob, {
                    maxSizeMB: 0.3,
                    maxWidthOrHeight: 300,
                  });
                });
            }
    
            reader.readAsDataURL(compressedImage);
    
            reader.onloadend = async () => {
              const base64data = reader.result;
    
              const compressedBase64data = LZString.compressToEncodedURIComponent(
                base64data
              );
            //   dispatch(ACTION_IMAGE_LOADING_RESET());
              uploadedImages.push(compressedBase64data)
            };
          } catch (error) {
            // dispatch(ACTION_IMAGE_LOADING_RESET());
            console.log(error);
          }
        changeImageUploaded(uploadedImages)
        })
          } else {
      
          //   dispatch(ACTION_IMAGE_LOADING_RESET());
            changeImageUploaded("");
            changeImagePreviewAvailable(false);
            handleDeletedPreviewImage();
        } 
      } 
       


  const [areaModalPage, setAreaModalPage] = useState(0)
  const [floorType, setFloorType] = useState("")
  const [areaName, setAreaName] = useState("")
  const [testingCondition, setTestingCondition] = useState("")
  const [testingCategory, setTestingCategory] = useState("")
  const [floorTypeDescription, setFloorTypeDescription] = useState("")
  const [TextureType, setTextureType] = useState("")
  const [TextureTypeDescription, setTextureTypeDescription] = useState("")
  const [floorFinish, setFloorFinish] = useState("")
  const [finishCondition, setFinishCondition] = useState("") 
  const [floorFinishDescription, setFloorFinishDescription] = useState("")
  const [slopeSurface, setslopeSurface] = useState("")
  const [cleaningChemicals, setcleaningChemicals] = useState("") 
  const [slsWaterConcentration, setSlsWaterConcentration] = useState("") 
  const [averageDcof, setAverageDcof] = useState("") 
  const [areaNotes, setAreaNotes] = useState("")
  const [testingDiagram, setTestingDiagram] = useState(null)
  const [diagramImage, setDiagramImage] = useState("")
  const [diagramUrl, setDiagramUrl] = useState("")
  const [floorFinishCondition, setFloorFinishCondition] = useState("")
  const [addAreaDropDown, setAddAreaDropDown] = useState(true)
  const [auditViewDropDown, setAuditViewDropDown] = useState(false)

  const [areaNameError, setAreaNameError] = useState(null)
  const [areaNumber, setAreaNumber] = useState("")

const goToReports = (id, state) => {
  history.push({
    pathname:"/admin/reports",
    state:{
      view:{id, state, all:true},
    }

  })
}
  const uploadFile = async (base64, typeF) => {
    let myBucket = await fetchCredentials()


    if(myBucket) {
    const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // Getting the file type, ie: jpeg, png or gif
    const type = base64.split(';')[0].split('/')[1];
    const fileName = `${areaName.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "")}-${selectedAudit.id}-${global.Date.now()}.png`
    const params = {
      ACL: 'public-read',
        Body: base64Data,
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: fileName,
        ContentEncoding: 'base64', // required
        ContentType: `image/${type}` // required. Notice the back ticks
    };
 
    myBucket.putObject(params)
        .on("complete", (evt) => {
          setDiagramUrl(`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`)
          if(typeF == "load")
          addAreaFunc(typeF, `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`, base64)
        })
        .send((err) => {
            if (err) console.log(err)
        }) 
} else {
  alert("Loading.")
  
}
}
  
    const [ 
      addArea,
      { data: addAreaData },
    ] = useMutation(addAreaMutation)
    const [
      addDiagram,
      { data: addDiagramData },
    ] = useMutation(addDiagramMutation)

      const [addAreaModal, setAddAreaModal] = useState(null)
      const [loadAreaModal, setLoadAreaModal] = useState(null)
      const [selectedArea, setSelectedArea] = useState(null)
      const [lines, setLines] = React.useState([]);
     
      const addLocationFunc = async (area, load, diagramFinal) => {
        let myBucket = await fetchCredentials()

        if(myBucket) {
setAddLocationLoad(true)
        let newDiagramImagePhoto = newDiagramImage || diagramFinal
  
    let allLocations = useShapes.get()
    let allLocationsArr = Object.entries(allLocations.shapes)
    let newLocation = allLocationsArr.filter(([key, shape]) => {
      if(load) {
        return key.includes("location")
      } else {
        return key.includes("location") && shape.new
      }
    })
    let updatedLocation = allLocationsArr.filter(([key, shape]) => key.includes("location") && shape.update)
    let finalLocation = Object.fromEntries(newLocation)
    let finalUpdatedLocation = Object.fromEntries(updatedLocation)
    finalLocation[Object.keys(finalLocation)[0]] = {...finalLocation[Object.keys(finalLocation)[0]], new: false}
    finalUpdatedLocation[Object.keys(finalUpdatedLocation)[0]] = {...finalUpdatedLocation[Object.keys(finalUpdatedLocation)[0]], update: false}
    let finalDiagram = {}
    finalDiagram.shapes = allLocations.shapes
    finalDiagram.lines = allLocations.lines
    Object.keys(finalDiagram.shapes).map(x => {
       if(finalDiagram.shapes[x].new == true) {
         let newLocation  = {...finalDiagram.shapes[x], new: false}
        finalDiagram.shapes = {...finalDiagram.shapes, [x]: newLocation}
       } else {
        let oldLocation  = {...finalDiagram.shapes[x], update: false}
        finalDiagram.shapes = {...finalDiagram.shapes, [x]: oldLocation}
       } 
    })  


    if(finalLocation) { 
      let newAreaName = selectedAreaToLoad?.name || areaName
      const base64Data = new Buffer.from(newDiagramImagePhoto.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      const fileName = `${newAreaName?.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "") }-${selectedAudit.id}-${global.Date.now()}.png`
      // Getting the file type, ie: jpeg, png or gif
      const type = newDiagramImagePhoto.split(';')[0].split('/')[1];
    
      const params = { 
        ACL: 'public-read', 
          Body: base64Data,
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: fileName,
          ContentEncoding: 'base64', // required
          ContentType: `image/${type}` // required. Notice the back ticks
      };
  
      myBucket.putObject(params)
          .on("complete", (evt) => {

            let newUrl = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`

            updateArea({
              variables:{
              _id: area._id,
              user: user?.user?._id,
              diagramImage: newUrl,
              testingDiagram: JSON.stringify(finalDiagram)
              }
              
            }).then(async () => {

              let locationsD = await Promise.all(locationToDelete.map( async (y, i, a) => {
                let location = locations.find(x => Object.keys(x.parsedCoordinates)[0] == y && x.area == area._id)
                if(location) {
                   await deleteLocation({
                  variables:{
                    _id: location.id,
                  }
                })
              
              }}))
                          
              let locationsU = await Promise.all(updatedLocation.map(async ([key, x], i, a) => {
                let location = locations.find(x => Object.keys(x.parsedCoordinates)[0] == key && x.area == area._id)
                if(location) {
                   await updateLocation({
                  variables:{
                    _id: location.id,
                    coordinates: JSON.stringify({[key]: {...finalUpdatedLocation[key],id: location.id, new : false, update: false}})
                  }
                })
              }}))

              let locationsN = await Promise.all(newLocation.map(async ([key, x], i) => {     
               await addLocation({
                  variables: {
                      view: area.view,
                      area: area._id,                   
                      coordinates: JSON.stringify({[key]: {...finalLocation[key], new : false}}),
                  }
              })
            }))


                  allAreasRefetch()    
                  allAuditHistoryRefetch()
                  allAreasCalledToLoad && allAreasRefetchToLoad()  
                  
                  useShapes.set({
                    selected: null,
                    shapes: {},
                    lines: []
                  })
                  setHistoryArr([])
                  setLines([])
                  setImages([])
                  setPhoto(null)
                  setNewDiagramImage(null)
                  setSelectedAreaToLoad(null)
                  setButtonDisabled(true)
                  setAddAreaDropDown(true)
                  setLocationToDelete([])
                  allLocationsRefetch().then(x => {
                    setAddLocationLoad(false)
                  })


            })

          })
          .send((err) => {
              if (err) console.log(err)
          }) 
                   
    } else {
                  allAuditHistoryRefetch()
                  allAreasRefetch()
                  allAreasRefetchToLoad()
                  allLocationsRefetch() 
                  useShapes.set({ 
                    selected: null,
                    shapes: {},
                    lines: []
                  })
                  setLines([])
                  setImages([])
                  setNewDiagramImage(null)
                  setButtonDisabled(true)
                  setAddAreaDropDown(true)
    }
      
    } else {
      alert("Loading.")
      
    }
    }

      const [photo, setPhoto] = useState(null)
      const [areaId, setAreaId] = useState(null)
  //     const deleteLocationFunc = (id, newD) => {
  //       let imageD = newDiagramImage || newD
  //       dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  //       let newTestingDiagram = useShapes.get()
  //       let finalDiagram = {}
  //       finalDiagram.shapes = newTestingDiagram.shapes
  //       finalDiagram.lines = newTestingDiagram.lines
  //       let newAreaName = area.name
  //       const base64Data = new Buffer.from(imageD.replace(/^data:image\/\w+;base64,/, ""), 'base64');
  //       const fileName = `${newAreaName.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "")}-${selectedAudit.id}-${global.Date.now()}.png`
  //       // Getting the file type, ie: jpeg, png or gif
  //       const type = imageD.split(';')[0].split('/')[1];
      
  //       const params = { 
  //         ACL: 'public-read', 
  //           Body: base64Data,
  //           Bucket: process.env.REACT_APP_S3_BUCKET,
  //           Key: fileName,
  //           ContentEncoding: 'base64', // required
  //           ContentType: `image/${type}` // required. Notice the back ticks
  //       };
    
  //       myBucket.putObject(params)
  //       .on("complete", (evt) => {
  //       let newUrl = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`
  //       updateArea({
  //         variables:{
  //         _id: area.id,
  //         user: user?.user?._id,
  //         diagramImage: newUrl,
  //         testingDiagram: JSON.stringify(finalDiagram)
  //         }
          
  //       }).then(() => { 
  //         allAreasRefetch()  
  //         allAreasCalledToLoad && allAreasRefetchToLoad()        
  //         deleteLocation({
  //         variables: {
  //             _id: id
  //         }
  //         }).then(() => {
  //             allLocationsRefetch() 
  //             useShapes.set({
  //               selected: null,
  //               shapes: {},
  //               lines: []
  //             })
  //             setLines([])
  //             setPhoto(null)
  //             setNewDiagramImage(null)
  //     })
  //   })
  
  //   }).send((err) => {
  //     if (err) {
  //     console.log(err)
  //     }
  // }) 
  // }
      const [locationToDelete, setLocationToDelete] = useState([])
      const addLocationToDeleted = (x) => {
        setLocationToDelete([...locationToDelete, x]) 
      }

      const addAreaFunc = async (type, diagramFly, base64) => {
        setAddAreaModal(null)
        setHaveData(false)
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        let diagramFinal = diagramUrl || diagramFly
        let diagram = useShapes.get()
        let finalDiagram = {}
        finalDiagram.shapes = diagram.shapes
        finalDiagram.lines = diagram.lines
        if(diagramFinal) {
          addArea({ 
            variables:{ 
              diagramPhoto: photo,
              createdBy: user?.user?.name,
              name: areaName, 
              areaNumber: areaNumber ? areaNumber : null,
              view: selectedAudit.defaultView,  
              site: selectedAudit.site._id,
              floorType: floorType, 
              testingCondition: testingCondition,
              testingCategory: testingCategory,
              floorTypeDescription: floorTypeDescription,
              floorTexture: TextureType,
              floorTextureDescription: TextureTypeDescription,
              floorFinish: floorFinish,
              floorFinishDescription: floorFinishDescription,
              conditionofFloorFinish: finishCondition,
              slopeofFloorsSurface: slopeSurface,
              cleaningChemicalsUsed: cleaningChemicals,
              slsWaterConcentration: slsWaterConcentration,
              dcof: averageDcof,
              auditorsNotes: areaNotes,
              images: images?.map(x => x.url),
              testingDiagram: JSON.stringify(finalDiagram),
              diagramImage: diagramFinal,
              load: type == "load" ? true : false
            }
          }).then((res) => {   
            setAreaId(null) 
              changeWebcamURI("");
              changeImagePreviewAvailable(
                false
              );
              changeImagePreviewAvailable(
                false
              );
              changeTakeAPhotoSelected(false);
              changeImageUploaded("");
              setFloorType("")
              setPhoto(null)
              setTestingCondition("")
              setTestingCategory("")
              setAreaName("")
              setAreaNumber("")
              setFloorTypeDescription("")
              setTextureType("")
              setTextureTypeDescription("")
              setFloorFinish("")
              setFinishCondition("")
              setFloorFinishDescription("")
              setslopeSurface("")
              setcleaningChemicals("")
              setSlsWaterConcentration("")
              setAverageDcof("")
              setAreaNotes("")
              setAreaModalPage(0)
              setTestingDiagram(null)
              setDiagramImage("")
              addLocationFunc(res.data.addArea,  type == "load", base64)
            })
        }
       
        }


        const draftAreaFunc = (id) => {
          setAddAreaModal(null)
          setHaveData(false) 
          dispatch(ACTION_LOADING_SPINNER_ACTIVE())
          let diagram = useShapes.get()
          let finalDiagram = {}
          finalDiagram.shapes = diagram.shapes
          finalDiagram.lines = diagram.lines
          addArea({
            variables:{
              _id: id,
              createdBy: user?.user?.name,
              areaNumber: areaNumber ? areaNumber : null,
              name: areaName,
              diagramPhoto: photo, 
              view: selectedAudit.defaultView,
              site: selectedAudit.site._id,
              floorType: floorType,
              testingCondition: testingCondition,
              testingCategory: testingCategory,
              floorTypeDescription: floorTypeDescription,
              floorTexture: TextureType,
              floorTextureDescription: TextureTypeDescription,
              floorFinish: floorFinish,
              floorFinishDescription: floorFinishDescription,
              conditionofFloorFinish: finishCondition,
              slopeofFloorsSurface: slopeSurface,
              cleaningChemicalsUsed: cleaningChemicals,
              slsWaterConcentration: slsWaterConcentration,
              dcof: averageDcof,
              auditorsNotes: areaNotes,
              images: images?.map(x => x.url),
              testingDiagram: JSON.stringify(finalDiagram),
              diagramImage: diagramImage ,
              type: "draft",
            }
          }).then((res) => {
            useShapes.set({
              selected: null,
              shapes: {},
              lines: []
            })
            setAreaId(null)
            setHistoryArr([])
            setLines([])
            setPhoto(null)
            changeWebcamURI("");
            changeImagePreviewAvailable(
                false
              );
              changeImagePreviewAvailable(
                false
              );
              changeTakeAPhotoSelected(false);
              changeImageUploaded("");
              setFloorType("")
              setTestingCondition("")
              setTestingCategory("")
              setAreaName("")
              setAreaNumber("")
              setFloorTypeDescription("")
              setTextureType("")
              setTextureTypeDescription("")
              setFloorFinish("")
              setFinishCondition("")
              setFloorFinishDescription("")
              setslopeSurface("")
              setcleaningChemicals("")
              setSlsWaterConcentration("")
              setAverageDcof("")
              setAreaNotes("")
              setAreaModalPage(0)
              setTestingDiagram(null)
              setDiagramImage("")
              allAreasRefetchToLoad()
              setImages([])
              allAreasRefetch()
              setButtonDisabled(true)
              setAddAreaDropDown(true)
            })
          }

      // fetch areas 

  const [allAreasToLoad, setAllAreasToLoad] = useState(null)


  useEffect(() => {
    if(allAreasDataToLoad) {
      setAllAreasToLoad(allAreasDataToLoad?.allAreas.map(area => {
        return {
          id: area._id,
          view : area.view,
          site : area.site,
          name: area.name,
          areaNumber: area.areaNumber,
          testingCondition: area.testingCondition,
          testingCategory: area.testingCategory,
          createdBy: area.createdBy,
          createdAt: moment.unix(area.createdAt/1000).format("MM/DD/YYYY"),
          floorType : area.floorType,
          floorTypeDescription : area.floorTypeDescription,
          floorTexture : area.floorTexture,
          floorTextureDescription : area.floorTextureDescription,
          floorFinish : area.floorFinish,
          floorFinishDescription : area.floorFinishDescription,
          conditionofFloorFinish: area.conditionofFloorFinish,
          slopeofFloorsSurface : area.slopeofFloorsSurface,
          cleaningChemicalsUsed : area.cleaningChemicalsUsed,
          slsWaterConcentration : area.slsWaterConcentration,
          testingDiagram: area.testingDiagram, 
          diagramImage: area.diagramImage,
          diagramPhoto: area.diagramPhoto,
          type: area.type,
          dcof: area.dcof,
          auditorsNotes : area.auditorsNotes,
          images: area.images,
          pass: area.results ? JSON.parse(area.results).every(x => x.passed) ? "pass" : "fail" : null,
          results: area.results ? JSON.parse(area.results) : null
        }
      }))
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }
  },[allAreasDataToLoad])
  const bottomRef = useRef(null);

  useEffect(() => {
    if(areas) {
      bottomRef?.current?.scrollIntoView( { behavior: 'smooth', block: 'start' })
    }
  },[bottomRef, areas, selectedAudit])
      useEffect(() => {
        if(allAreasData && allLocationsData) {
          // allDiagrams()
          setAreas(
            allAreasData.allAreas.map((area,i, a) => {
              let locationsShapes = allLocationsData.allLocations.filter(location => location.area == area._id).map(x => JSON.parse(x.coordinates))        
              const mappingArea = {
                  id: area._id,
                  view : area.view, 
                  name: area.name,  
                  areaNumber: area.areaNumber,
                  diagramPhoto: area.diagramPhoto, 
                  testingCondition: area.testingCondition,
                  testingCategory: area.testingCategory,
                  category: area.testingCategory.includes("Interior, Dry") ? "dry" :
                  area.testingCategory.includes("Interior, Wet Plus") ? "wet" :
                  area.testingCategory.includes("Interior, Wet") ? "wet" :
                  area.testingCategory.includes("Exterior, Wet") ? "wet" :
                  area.testingCategory.includes("Oils/Greases") ? "wet" : "none",
                  createdBy: area.createdBy,
                  createdAt: moment.unix(area.createdAt/1000).format("MM/DD/YYYY"),
                  floorType : area.floorType,
                  floorTypeDescription : area.floorTypeDescription,
                  floorTexture : area.floorTexture,
                  floorTextureDescription : area.floorTextureDescription,
                  floorFinish : area.floorFinish,
                  floorFinishDescription : area.floorFinishDescription,
                  conditionofFloorFinish: area.conditionofFloorFinish,
                  slopeofFloorsSurface : area.slopeofFloorsSurface,
                  cleaningChemicalsUsed : area.cleaningChemicalsUsed,
                  slsWaterConcentration : area.slsWaterConcentration,
                  locationsCoordinates: locationsShapes,
                  testingDiagram: area.testingDiagram,
                  diagramImage: area.diagramImage,
                  type: area.type,
                  dcof: area.dcof,
                  auditorsNotes : area.auditorsNotes, 
                  images: area.images,
                  expanded: scrollToArea== area._id ? true : i == a.length - 1 ? true : false,
                  pass: area.results ? JSON.parse(area.results).every(x => x.passed) ? "pass" : "fail" : null,
                  results: area.results ? JSON.parse(area.results)?.filter(x => !x?.extra)?.length ? JSON.parse(area.results)?.filter(x => !x?.extra) : null : null,
                  extra: area.results ? JSON.parse(area.results)?.filter(x => x?.extra)?.length ? JSON.parse(area.results)?.filter(x => x?.extra) : null : null
              } 
              return mappingArea 
          })) 
          dispatch(ACTION_LOADING_SPINNER_RESET())
        }
      },[allAreasData, allLocationsData])

      useEffect(() => {
        if(allViewsData && !fixAudit) {
          setViews(allViewsData.allViews)
          if(auditHistoryActive) {
            if(allViewsData.allViews?.length > 1) {
              setAuditHistoryModal(true)
              setAuditHistoryActive(false)
            } else {
              setAuditHistoryActive(false)
            }
          }  
        }
      },[allViewsData, fixAudit])
      
      // update area
 

      const [areaDiagram, setAreaDiagram] = useState(null)
      const [areaLines, setAreaLines] = useState(null)
      const [newDiagramImage, setNewDiagramImage] = useState(null)
      const editAreaImages = (area) => {
        setAddAreaDropDown(false)
        setSelectedArea(area)
        area?.images?.length ? setImages(area?.images?.map((y, i) => {
          return {
            url: y
          }
        })) : setImages([])
        area?.images?.length ? setImagesLater(true) : setImagesLater(false)

        // setImages(area?.images?.map(y => {
        //   return {
        //     url:  y
        //   } 
        // }))
        setPhoto(null)
        setHaveData(false) 
        setAddAreaModal("photos")
      }

      const updateAreaImages = (area) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setAddAreaModal(null)
        setHaveData(false) 
        let newArea = area
        newArea.images = images?.map(x => x.url)
        setAreas(areas.map(t => t.id === newArea.id ? newArea : t))
        updateArea({
          variables:{ 
            _id: newArea.id,
            images: images?.map(x => x.url),
            user: user?.user?._id,
          }
        }).then(() => {
          allAuditHistoryRefetch()
          setImages([])
          allAreasRefetchToLoad()
          setAddAreaDropDown(true)
          dispatch(ACTION_LOADING_SPINNER_RESET())
        })

      }
      const [haveData, setHaveData] = useState(false)
      const [imagesLater, setImagesLater] = useState(false)
      const editArea = (area, load) => {
        area.results?.length && !load ? setHaveData(true) : null
        setAreaDiagram(area.diagramImage) 
        
        let testingDiagram = area.testingDiagram ? JSON.parse(area.testingDiagram) : {}
        let shapes = testingDiagram.shapes ? Object.entries(testingDiagram.shapes) : null
        let coor = area.locationsCoordinates ? JSON.stringify(area.locationsCoordinates) : null
        let newShapes = coor && shapes ? shapes.filter(([key, x]) => coor.includes(key) || !key.includes("location")) : null
        let lastShapes = newShapes?.map(([key, x, a]) => {
          let selectedLocation = area.results?.filter(y => key.includes(y.locationCode)) 
          x.passed = selectedLocation?.length ? [...selectedLocation?.map(x => x.passed ? "#6DB240" : "#EF1111")] : null
          return [key, x]
        })
        let convertedShapes = lastShapes ? Object.fromEntries(lastShapes) : null
        let newTestingDiagram = convertedShapes ? {...testingDiagram, shapes: convertedShapes} : testingDiagram
        setAreaLines(newTestingDiagram)
        changeImageUploaded(""); 
        !load && setAreaName(area.name)
        !load && setAreaNumber(area.areaNumber)
        load && setAreaId(area.id)
        setPhoto(area.diagramPhoto || null)
        setFloorType(area.floorType)
        setTestingCondition(area.testingCondition)
        setTestingCategory(area.testingCategory)
        setFloorTypeDescription(area.floorTypeDescription)
        setTextureType(area.floorTexture)
        setTextureTypeDescription(area.floorTextureDescription)
        setFloorFinish(area.floorFinish)
        setFinishCondition(area.conditionofFloorFinish)
        setFloorFinishDescription(area.floorFinishDescription)
        setslopeSurface(area.slopeofFloorsSurface)
        setcleaningChemicals(area.cleaningChemicalsUsed)
        setSlsWaterConcentration(area.slsWaterConcentration)
        setAverageDcof(area.dcof)
        setAreaNotes(area.auditorsNotes)
        setAreaModalPage(0)
        setTestingDiagram(JSON.stringify(testingDiagram))
        setDiagramImage(area.diagramImage)
        !load ? area?.images?.length ? setImages(area?.images?.map((y, i) => {
          return {
            url: i == 0 && load ? "replace" : y
          }
        })) : setImages([]) : setImages([])
        area?.images?.length ? setImagesLater(false) : setImagesLater(true)
        setSelectedArea(area)
        setAddAreaDropDown(false)
        load ? setAddAreaModal("Edit Area Before Load") : setAddAreaModal("Edit Area") 
      }

      // update area func.

      const updateAreaFunc = (area) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setAddAreaModal(null)
        setHaveData(false) 
        let diagram = useShapes.get()
        let finalDiagram = {}
        finalDiagram.shapes = diagram.shapes
        finalDiagram.lines = diagram.lines
        const newArea = area
        newArea.floorType = floorType
        newArea.name = areaName
        newArea.floorTypeDescription = floorTypeDescription
        newArea.TextureType = TextureType
        newArea.TextureTypeDescription = TextureTypeDescription
        newArea.floorFinish = floorFinish
        newArea.conditionofFloorFinish = finishCondition
        newArea.floorFinishDescription = floorFinishDescription
        newArea.slopeofFloorsSurface = slopeSurface
        newArea.cleaningChemicalsUsed = cleaningChemicals
        newArea.slsWaterConcentration = slsWaterConcentration
        newArea.dcof = averageDcof
        newArea.diagramPhoto = photo
        newArea.auditorsNotes = areaNotes
        newArea.testingDiagram = JSON.stringify(finalDiagram),
        newArea.diagramImage = diagramUrl || diagramImage
        newArea.images = images?.map(x => x.url)
        setAreas(areas.map(t => t.id === newArea.id ? newArea : t))
        updateArea({
          variables:{
            _id: newArea.id,
            user: user?.user?._id,
            floorType: floorType,
            name: areaName,
            areaNumber: areaNumber,
            floorTypeDescription: floorTypeDescription,
            floorTexture: TextureType,
            floorTextureDescription: TextureTypeDescription,
            floorFinish: floorFinish,
            diagramPhoto: photo,
            floorFinishDescription: floorFinishDescription,
            conditionofFloorFinish: finishCondition,
            slopeofFloorsSurface: slopeSurface,
            cleaningChemicalsUsed: cleaningChemicals,
            slsWaterConcentration: slsWaterConcentration,
            dcof: averageDcof,
            auditorsNotes: areaNotes,
            images: images?.map(x => x.url),
            testingDiagram: JSON.stringify(finalDiagram),
            diagramImage: diagramUrl || diagramImage,
            testingCondition: testingCondition,
            testingCategory: testingCategory,

            
          }
        }).then((res) => {
          setFloorType("")
          setTestingCondition("")
          setTestingCategory("")
          setAreaName("")
          setFloorTypeDescription("")
          setTextureType("")
          setTextureTypeDescription("")
          setFloorFinish("")
          setFinishCondition("")
          setFloorFinishDescription("")
          setslopeSurface("")
          setcleaningChemicals("")
          setSlsWaterConcentration("")
          setAverageDcof("")
          setAreaNotes("")
          setAreaModalPage(0)
          setTestingDiagram(null)
          setSelectedArea(null)
          setDiagramImage("")
          setImages([])
          setPhoto(null)
          setAddAreaDropDown(true)
          addLocationFunc(res.data.updateArea)
          dispatch(ACTION_LOADING_SPINNER_RESET())
        })


      }


      // fecth locations
      
      useEffect(() => {
        if(allLocationsData) {
          
          setLocations(
            allLocationsData.allLocations.map((location,i) => {
              const mappingLocation = {
                  id: location._id,
                  area: location.area,
                  location: location.location,
                  readings: location.readings,
                  samples: "under dev",
                  condition: location.condition,
                  coordinates: location.coordinates,
                  parsedCoordinates: location.coordinates ? JSON.parse(location.coordinates) ? JSON.parse(location.coordinates) : null : null,
                  view: location.view,
                  pass: location.pass === true ? true : location.pass === false ? false : null,
                  label: location.label
              }
              return mappingLocation
          })  
          )
          dispatch(ACTION_LOADING_SPINNER_RESET())
        }
      },[allLocationsData])


      // generate templates

      const [ defaultDcofHtml, setDefaultDcofHtml ] = useState("")
      const [ defaultDcofCss, setDefaultDcofCss ] = useState("")
      const [ templateVariables, setTemplateVariables] = useState(null)

      useEffect(() => {
        if(defaultDCOF) {
            setDefaultDcofHtml(parse(defaultDCOF.html))
            setDefaultDcofCss(defaultDCOF.css)
          }
      },[defaultDCOF])

      const getPropValues = (o, prop) => 
      (res => (JSON.stringify(o, (key, value) => 
        (key === prop && res.push(value), value)), res))([]);

        
      useEffect(() => {
        
        if(defaultDcofHtml) {
   
          let variables = [...new Set(getPropValues(defaultDcofHtml, "variable"))]

          setTemplateVariables(variables)

        }
      },[defaultDcofHtml])

    
      const [warningMessage, setWarningMessage] = useState(null)


        // bluetooth
        
      const arrayBuf8ToString = function(buf) {
        return String.fromCharCode.apply(null, new Uint8Array(buf));
      }
      
      const hexToString = function(hex) {
        var string = '';
        for(var i = 0; i < hex.length; i += 2) {
            string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
        }
        return string;
      }
      
      
      const arrayBufferConcat = function() {
        var length = 0
        var buffer = null
      
        for(var i in arguments) {
            buffer = arguments[i]
            length += buffer.byteLength
        }
      
        var joined = new Uint8Array(length)
        var offset = 0
      
        for(var i in arguments) {
            buffer = arguments[i]
            joined.set(new Uint8Array(buffer), offset)
            offset += buffer.byteLength
        }
      
        return joined.buffer
      }; 
      
      const hexToBase64 = function(hexstring) {
        return btoa(hexstring.match(/\w{2}/g).map(function(a) {
            return String.fromCharCode(parseInt(a, 16));
        }).join(""));
      };
          
        const convertHexToArrayBuffer = function(hex) {
          let array = new Uint8Array((hex.match(/[\da-f]{2}/gi)||[]).map(function(h) {
              return parseInt(h, 16);
          }))
          return array.buffer;
      }
      const getCommand = (cmd) => {
        return convertHexToArrayBuffer(cmd)
      }
      
      const asciiToPureHex = function(str) {
        let hexStr = '';
        for (let n = 0, l = str.length; n < l; n++) {
          hexStr += Number(str.charCodeAt(n)).toString(16);
        }
        return hexStr;
      };
      function ConvertByteStringToCharByteArray(str) {
        var lastErrToken;
        var bytes = [];
        var bytePos = 0;
        var splitStr = str.split(/\s+/);
        for(var i = 0; i < splitStr.length; i++) {
            var byteStr = splitStr[i];
            if(byteStr.substr(0, 2) === "0x") {
                byteStr = byteStr.substr(2, byteStr.length - 2);
            }
      
            if(byteStr === " " || byteStr === "")
                continue;
      
            var b = parseInt(byteStr, 16);
            if(b === NaN || b === undefined) {
                lastErrToken = byteStr;
                return undefined;
            } else {
                if(b < 256) {
                    bytes[bytePos] = b;
                    bytePos++;
                } else {
                    lastErrToken = byteStr;
                    return undefined;
                }
      
            }
        }
        return bytes;
      }
      
      function getInputData(inputText) {
        /* Hex workshop support which copes bytes without spaces */
        var newText = "";
        if(inputText.length % 2 != 0) {
            inputText = '0' + inputText;
        }
        for(var index = 0; index < inputText.length; index += 2) {
            newText += inputText.substr(index, 2);
            newText += ' ';
        }
        newText = newText.substr(0, newText.length - 1);
        return ConvertByteStringToCharByteArray(newText);
      }
      var Crc = function(width, polynomial, initialVal, finalXorVal, inputReflected, resultReflected) {
        /* private variables */
        // crc model variables
        var width;
        var polynomial;
        var initialVal;
        var finalXorVal;
        var inputReflected;
        var resultReflected;
      
        var crcTable; // lookup table
        var castMask = 0xFFFF;
        var msbMask;
      
        /* 'constructor' */
        width = arguments[0];
        polynomial = arguments[1].polynomial;
        initialVal = arguments[1].initial;
        finalXorVal = arguments[1].finalXor;
        inputReflected = arguments[1].inputReflected;
        resultReflected = arguments[1].resultReflected;
      
      
        msbMask = 0x01 << (width - 1)
        /* 'constructor' END */
      
      
        this.calcCrcTable = function() {
            crcTable = new Array(256);
      
            for(var divident = 0; divident < 256; divident++) {
                var currByte = (divident << (width - 8)) & castMask;
                for(var bit = 0; bit < 8; bit++) {
                    if((currByte & msbMask) != 0) {
                        currByte <<= 1;
                        currByte ^= polynomial;
                    } else {
                        currByte <<= 1;
                    }
                }
                crcTable[divident] = (currByte & castMask);
            }
        }
      
        if(!this.crcTable) {
            this.calcCrcTable();
        }
      
        this.compute = function(bytes) {
            var crc = initialVal;
            for(var i = 0; i < bytes.length; i++) {
      
                var curByte = bytes[i] & 0xFF;
      
                /* update the MSB of crc value with next input byte */
                crc = (crc ^ (curByte << (width - 8))) & castMask;
                /* this MSB byte value is the index into the lookup table */
                var pos = (crc >> (width - 8)) & 0xFF;
                /* shift out this index */
                crc = (crc << 8) & castMask;
                /* XOR-in remainder from lookup table using the calculated index */
                crc = (crc ^ crcTable[pos]) & castMask;
            }
      
            return((crc ^ finalXorVal) & castMask);
        }
      };
      
      function computeCRC(width, crcObj, bytes) {
        var crc = new Crc(width, crcObj);
        return crc.compute(bytes).toString(16);
      }
      const calculateCRC = function(inputText) {
        /* character byte array */
        var bytes = getInputData(inputText);
      
        var width = 16;
      
        /* get selected CRC model parameters */
        var crcObj = {
            width: 16,
            name: "CRC16_CCIT_ZERO",
            polynomial: 0x1021,
            initial: 0x0000,
            finalXor: 0x0000,
            inputReflected: false,
            resultReflected: false
        }
      
        return computeCRC(width, crcObj, bytes).padStart(4, '0');
      }
      
      const arrayBufToHex = function(buffer) {
        return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
      }
      
      const pureHexToFancyHex = function(str) {
        let hexStr = '';
        for (let n = 0, l = str.length; n < l; n+=2) {
          hexStr += '0x' + str[n] + str[n+1] + ' ';
        }
        return hexStr.trim();
      }
      
      const concatArrayBuffers = function(buffer1, buffer2) {
        var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
        tmp.set(new Uint8Array(buffer1), 0);
        tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
        return tmp.buffer;
      };
      
      const writeCommand = function(cmdTitle, extras) {
        let cmd = getCommand(cmdTitle)
        if (extras != null && extras !== '') {
          const pureHex = asciiToPureHex(extras);
          const calcCRC = calculateCRC(arrayBufToHex(cmd) + '' + pureHex);
          const rest = pureHexToFancyHex(pureHex + '' + calcCRC);
          cmd = concatArrayBuffers(cmd, convertHexToArrayBuffer(rest));
        }
          return cmd
      }

      const [myState, _setMyState] = React.useState("data");
      const myStateRef = React.useRef(myState);

      const setMyState = data => {
        myStateRef.current = data;
        _setMyState(data);
      };
 
      
      const handleCharacteristicValueChanged = async (event) => {
        let hex = arrayBufToHex(event.target.value.buffer)

        myStateRef.current == "data" ? receiveData(event.target.value.buffer) : recievePdf(event.target.value.buffer)
      }

      const [pdfOn, setPdfOn] = useState(false)
      const [charLoading, setCharLoading] = useState(false)
      const [wrongDataModal, setWrongDataModal] = useState(null)
      const [validateError, setValidateError] = useState(null)
      
      const [dataOnFly, setDataOnFLy] = useState(null)

        const connectToDeviceAndSubscribeToUpdates = async () => {

          setCharLoading(true)
          try {
            const device = await navigator.bluetooth
              .requestDevice({
                optionalServices: ["569a1101-b87f-490c-92cb-11ba5ea5167c"],
                filters :[{namePrefix:"BOT"}],
              });
            
            setDevice(device)
       
            window.addEventListener("load", () => {device.gatt.disconnect()})
            setIsDisconnected(false);
            device.addEventListener("gattserverdisconnected", onDisconnected);
            const server = await device.gatt.connect();
            const service = await server.getPrimaryService("569a1101-b87f-490c-92cb-11ba5ea5167c")
            const characteristic = await service.getCharacteristic("569a2001-b87f-490c-92cb-11ba5ea5167c");
            const characteristicNotification = await service.getCharacteristic("569a2000-b87f-490c-92cb-11ba5ea5167c")
            characteristicNotification.startNotifications();
            characteristicNotification.addEventListener("characteristicvaluechanged", handleCharacteristicValueChanged);
            setChar(characteristic)
            setCharNotification(characteristicNotification)
            setCharLoading(false)            
            } catch(error) {
              console.log(`There was an error: ${error}`);
              setCurrentTest(null)
              setTemporaryTest(null)
              setStartTestModal(null)
              setCharLoading(false)
              setIsDisconnected(true)
            }
        };      
      
        let countOccurences = (string, word) => {
          return string.split(word).length - 1; 
       }


       const [mode, setMode] = useState(null)
      const [singleRuns , setSingleRuns] = useState(false)
       const [addNewTest, setAddNewTest] = useState(false)
       const [pdfImages, setPdfImages] = useState([])
        const [newPdfImages, setNewPdfImages] = useState([])
        const [exactData, setExactData] = useState(null)
        const groupBy = (objectArray, property) => {
          return objectArray.reduce(function (acc, obj) {
            var key = obj[property];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
          }, {});
        }
       const validateTransfer = (data, testCategory) => {
        if(data.length == 1) {
          setValidateError([`Single runs are not able to be uploaded`,  <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
        <span style={{margin:"15px 0", textTransform:"none"}}>You still can add it to extra data</span>
        <div
        className="auth-button addUser confirmDelete"
        style={{margin:0, marginBottom:"10px"}}
        onClick={() => {
          JSON.stringify(data);
          var groupedData = groupBy(data, 'fileName');
          setSingleRuns(true)
          setMatchedData(Object.entries(groupedData))
          setUploaderModal("Location data")
          setValidateError(null)
          setStartTestModal(false)               
          setMode("extra")
          setAddNewTest(true)
          let attachments = document.querySelector(`[id=imagesContainer]`)
          attachments ? attachments.innerHTML = ("") : null
          setFile(null)
          setFileError(null)
          let onFlyCategory = data[0]?.type?.slice(data[0]?.type?.indexOf("/")+1, data[0]?.type?.length)
          let key = data[0]?.fileName
       
          matchedTest ? matchedTest.areaCondition = `${data[0]?.condition}/${onFlyCategory?.toLowerCase() || contextCategory}` : null
          matchedTest ? matchedTest.areaCode = data[0].area : null
          matchedTest ? matchedTest.fileName = data[0].fileName : null
          matchedTest ? matchedTest.locationCode = data[0].location : null
          matchedTest ? matchedTest.extra = true : null

          setCleanCondition(data[0]?.condition?.toLowerCase())
          setPdfFileNotFound(false)
          let newTests = Object.assign([], data)
          newTests.reverse()
          setFinalData(newTests)
          setPdfFile(null)
          setExactData(key)
          setPdfImages([]) 
          setNewPdfImages([])
          // setOnFlyTest(null) 
          setWarningModal(null)
          fetchPdf(data.find(x => x.fileName.includes(".pdf")).fileName, true)

            }} 
          >
          Add to extra data
          </div> 
        </div>])
        } else if(testCategory) {
        let exactData = data[0]
        if(exactData?.type?.toLowerCase()?.includes(testCategory?.toLowerCase())) {
        if(exactData.location !== currentTest.locationCode || exactData.area !== currentTest.areaCode) {
          setDataOnFLy(data[0])
          setWrongDataModal(data.find(x => x.fileName.includes(".pdf")).fileName)
        } else {
          fetchPdf(data.find(x => x.fileName.includes(".pdf")).fileName)
        }} else {
        setValidateError([`BOT-3000E data testing category not matched with the selected area`,  <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
        <span style={{margin:"15px 0", textTransform:"none"}}>You still can add it to extra data</span>
        <div
        className="auth-button addUser confirmDelete"
        style={{margin:0, marginBottom:"10px"}}
        onClick={() => {
        JSON.stringify(data);
        var groupedData = groupBy(data, 'fileName');
        setMatchedData(Object.entries(groupedData))
        setUploaderModal("Location data")
        setValidateError(null)
        setStartTestModal(false)               
        data.length == 1 ? setSingleRuns(true) : setSingleRuns(false)
        setMode("extra")
        setAddNewTest(true)
        let attachments = document.querySelector(`[id=imagesContainer]`)
        attachments ? attachments.innerHTML = ("") : null
        setFile(null)
        setFileError(null)
        let onFlyCategory = data[0]?.type?.slice(data[0]?.type?.indexOf("/")+1, data[0]?.type?.length)
        let key = data[0]?.fileName
        matchedTest ? matchedTest.areaCondition = `${data[0]?.condition}/${onFlyCategory?.toLowerCase() || contextCategory}` : null
        matchedTest ? matchedTest.areaCode = data[0].area : null
        matchedTest ? matchedTest.fileName = data[0].fileName : null
        matchedTest ? matchedTest.locationCode = data[0].location : null
        matchedTest ? matchedTest.extra = true : null

              setCleanCondition(data[0]?.condition?.toLowerCase())
              setPdfFileNotFound(false)
              let newTests = Object.assign([], data)
              newTests.reverse()
              setFinalData(newTests)
              setPdfFile(null)
              setExactData(key)
              setPdfImages([]) 
              setNewPdfImages([])
              // setOnFlyTest(null) 
              setWarningModal(null)
              fetchPdf(data.find(x => x.fileName.includes(".pdf")).fileName, true)

          }} 
          >
          Add to extra data
          </div> 
        </div>])
        }
      } else {
        setValidateError([`The tested area on the BOT-3000E not exist on this audit`,  <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
        <span style={{margin:"15px 0", textTransform:"none"}}>You still can add it to extra data</span>
        <div
        className="auth-button addUser confirmDelete"
        style={{margin:0, marginBottom:"10px"}}
        onClick={() => {
          JSON.stringify(data);
          var groupedData = groupBy(data, 'fileName');
          setMatchedData(Object.entries(groupedData))
          setUploaderModal("Location data")
          setValidateError(null)
          setStartTestModal(false)               
          data.length == 1 ? setSingleRuns(true) : setSingleRuns(false)          
          setMode("extra")
          setAddNewTest(true)
          let attachments = document.querySelector(`[id=imagesContainer]`)
          attachments ? attachments.innerHTML = ("") : null
          setFile(null)
          setFileError(null)
          let onFlyCategory = data[0]?.type?.slice(data[0]?.type?.indexOf("/")+1, data[0]?.type?.length)
          let key = data[0]?.fileName
       
          matchedTest ? matchedTest.areaCondition = `${data[0]?.condition}/${onFlyCategory?.toLowerCase() || contextCategory}` : null
          matchedTest ? matchedTest.areaCode = data[0].area : null
          matchedTest ? matchedTest.fileName = data[0].fileName : null
          matchedTest ? matchedTest.locationCode = data[0].location : null
          matchedTest ? matchedTest.extra = true : null
  
                setCleanCondition(data[0]?.condition?.toLowerCase())
                setPdfFileNotFound(false)
                let newTests = Object.assign([], data)
                newTests.reverse()
                setFinalData(newTests)
                setPdfFile(null)
                setExactData(key)
                setPdfImages([]) 
                setNewPdfImages([])
                // setOnFlyTest(null) 
                setWarningModal(null)
                fetchPdf(data.find(x => x.fileName.includes(".pdf")).fileName, true)
  
            }} 
          >
          Add to extra data
          </div> 
        </div>])
      }
       }
          const formatData = async (data) => {
            let arr = data.split('\n'); 
            arr.unshift("date,time,area,location,testNumber,id,type,condition,result,avg1,avg2,avg3,avg4,fileName")
            var jsonObj = [];
          var headers = arr[0].split(',');
          for(var i = 1; i < arr.length; i++) {
            var data = arr[i].split(','); 
            var obj = {};
            for(var j = 0; j < data.length; j++) { 
               obj[headers[j]?.trim()] = data[j]?.trim();
            }
            jsonObj.push(obj);
          }
          JSON.stringify(jsonObj);
          let areaCategory = areas.find(x => x.areaNumber == jsonObj[0].area)?.category
          setContextCategory(areaCategory)
          if(jsonObj.every((x, i, a) => x.fileName == a[0].fileName)) {
            setFinalData(jsonObj)
            setMyState("pdf")
            validateTransfer(jsonObj, areaCategory)
          } else {
            setFinalData([jsonObj[0]])
            setMyState("pdf")
            validateTransfer([jsonObj[0]], areaCategory)
          }
          
          }
    
          const receiveData = (data) => {
              let hexres = arrayBuf8ToString(data)  
              setEachHex(hexres)            
           }

           const recievePdf = async (data) => {
              let hexres = arrayBufToHex(data)
              setEachPdfHex(hexres)
           }
           
         const [supportsBluetooth, setSupportsBluetooth] = useState(false);
         const [isDisconnected, setIsDisconnected] = useState(true);
         const [pullButtonActive, setPullButtonActive] = useState(false);     
         const [startTestModal, setStartTestModal] = useState(false)

         const onDisconnected = (event) => {
          charNotification && charNotification.removeEventListener("characteristicvaluechanged", handleCharacteristicValueChanged)
          setPullingDataActive(0)
          setIsDisconnected(true);
         }    
          
      const ignoredCmd = ["0200", "08", "41544", "303", "4a"]
        

      useEffect(() => {
        if(currentTest && !currentTest?.replaced) {
          if(!isDisconnected) {
             
              dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView]: [...selectedTests[selectedAudit?.defaultView] || [], currentTest]}))
              char?.writeValueWithResponse(writeCommand("0x02 0x00 0x0C 0xA3", currentTest.areaCode + '' + currentTest.locationCode))
             
          } else {
           
            connectToDeviceAndSubscribeToUpdates()
           
          }
        }
      },[currentTest])

      useEffect(() => {
        if(currentTest) {
           let autoDetect = setTimeout(() => {
              setPullButtonActive(true)
            }, 6000);
            return () => { 
              clearTimeout(autoDetect)
            }
        }
      },[currentTest])

      const pullData = () => {
        if(!isDisconnected) {
           setPullingDataActive(1)
              char?.writeValueWithResponse(writeCommand("0x02 0x00 0x06 0xA0 0xF2 0x24")).then(() => {
                   char?.writeValueWithResponse(writeCommand('0x02 0x00 0x06 0x06 0x27 0x08'))
        })           
        } else {
          setCurrentTest(null)
          connectToDeviceAndSubscribeToUpdates()
        }
       
      }

      useEffect(() => {
        if(eachHex) {
          setFinalData([...finalData , eachHex])
        }
      },[eachHex])
      
      useEffect(() => {
        if(eachPdfHex) {
          setPullingDataActive(pullingDataActive+1)
          setFinalPdf([...finalPdf , eachPdfHex])
        }
      },[eachPdfHex])

      useEffect(() => {
        if(finalData) {
          if(countOccurences(finalData.join(""),".pdf") == 3) {
            let finalDataFilter = finalData.filter((x,i) => i >= 5)
            let finalDataMapping = finalDataFilter.map((y,i) => {
              if (i == 0) {
               return y[y.length - 1]
              } else if(i == finalDataFilter.length - 1) {
                return y.substring(0, y.indexOf('\r'))
      
              } else {
                return y
              }
            })
            setEachHex("")
            setFinalData([])            
            formatData(finalDataMapping.join(""))
          }
        }
      },[finalData])
      
      const fetchPdf = (na, bot) => {
          char?.writeValueWithResponse(writeCommand('0x02 0x00 0x06 0x06 0x27 0x08')).then(() => {
            char?.writeValueWithResponse(writeCommand("0x02 0x00 0x12 0xA2", na)).then(() => {
                (async function() {
                  for (let i = 0; i < 300; i++) {
                    if(!isDisconnected) {
                       const response = await char?.writeValueWithResponse(writeCommand('0x02 0x00 0x06 0x06 0x27 0x08'))
                      if(i == 299) {
                        setPullingDataActive(1800)
                        bot ? setTheReEnd(true) : setTheEnd(true)
                      }
                    } else  {
                      break
                    }
                     
                  }
              })()
          })
        })
      }

      const getPdfFromBot = (na) => {
            char?.writeValueWithResponse(writeCommand("0x02 0x00 0x12 0xA2", na)).then(() => {
                (async function() {
                  for (let i = 0; i < 300; i++) {
                    if(!isDisconnected) {
                       const response = await char?.writeValueWithResponse(writeCommand('0x02 0x00 0x06 0x06 0x27 0x08'))
                      if(i == 299) {
                        setPullingDataActive(1800)
                        setTheReEnd(true)
                      }
                    } else  {
                      break
                    }
                     
                  }
              })()
          })
      }

      // ignore helper functions
      const length = (x) => x.length
      const sum = (a, b) => a+b
      
      const indexesOf = (substr) => ({
        in: (str) => (
          str
          .split(substr)
          .slice(0, -1)
          .map(length)
          .map((_, i, lengths) => (
            lengths
            .slice(0, i+1)
            .reduce(sum, i*substr.length)
          ))
        )  
      });

      let removeFromString = (arr,str) => {
        let regex = new RegExp("\\b"+arr.join('|')+"\\b","gi")
        return str.replace(regex, '')
      }
      const b64toUrl = async (base64Data) => {
        var data = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
        var blob = new Blob([data], {type: "octet/stream"});
        return blob

      }

              const [pdfDone, setPdfDone] = useState(true)

              const uploadPdf = async (test, bot, single) => {

                let myBucket = await fetchCredentials()


                if(myBucket) {
              const fileName = test?.extra ? `${global.Date.now()}${single ? "single" : "extra"}.pdf` :`${test.areaId}-${test.locationId}-${test.areaCondition}-${single ? "single" : "extra"}.pdf`

              const params = {
                  ACL: 'public-read',
                  Bucket: process.env.REACT_APP_S3_BUCKET,
                  Key : fileName, 
                  Body : test.pdf,
                  ContentType: "application/pdf"
              }

              myBucket.putObject(params)
              .on("complete", (evt) => {
              if(evt) {

              test.pdfUrl = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`
              test.pdf = ""

              if(bot) {
              dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [ ...selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationId == test.locationId && ( x.areaCondition == "pending" || x.areaCondition == test.areaCondition) ? false : true) , test]}))
              setMyState("data")
              setMatchedTest(test)
              setFinalData([])
              setFinalPdf([])
              setTheEnd(null)
              setTheReEnd(null)
              setPullingDataActive(0)

              setCurrentTest(null)
              setStartTestModal(null)
              setPullButtonActive(false)
              dispatch(ACTION_LOADING_SPINNER_RESET())
            } else {
              dispatch(ACTION_LOADING_SPINNER_RESET())
              setMatchedTest(test)
              setPullingDataActive(0)
            } 
          }
          })
          .send((err) => {
              if (err) console.log(err)
          })  
    } else {
      alert("Loading.")
      
    }
    }
      

      useEffect(async() => {
          if(finalPdf && theEnd) {
            let hexFinal = finalPdf.join("")
            let ignoredCommand = []
            ignoredCommand.push(hexFinal.slice(0, hexFinal.indexOf("255044462d312e330a25b")))
            ignoredCommand.push(hexFinal.slice(hexFinal.length - 16, hexFinal.length))
            indexesOf('022006a2').in(hexFinal).map(x => {
              ignoredCommand.push(hexFinal.slice(x-4 , x+8))
            })
            let hexBeforeEnd = await removeFromString(ignoredCommand, hexFinal)
            if(hexBeforeEnd) {
            const baseBlob = Buffer.from(hexBeforeEnd, 'hex').toString('base64')
            if(baseBlob) {
            const blobBase = await b64toUrl(baseBlob)
            if(blobBase) {
            //save pdf file comes from the bot
            let pdfText = await getPdfText(blobBase)
            let validation = validatepdf(pdfText, finalData)
            if(validation) { 
            if(validation == "done") {
            saveAs(blobBase, finalData ? finalData.find(x => x.fileName?.includes(".pdf"))?.fileName : "pdfFile.pdf")
            let currentLocation = currentTest
            currentLocation.results = finalData
            currentLocation.pdf = blobBase 
            currentLocation.pending = false
            currentLocation.replaced = false
            let testCondition = finalData[0]?.condition
            let testType = finalData[0]?.type
            let testCategory = testType ? testType.slice(testType.indexOf("/"), testType.length)?.toLowerCase() : ""
            currentLocation.areaCondition = `${testCondition}${testCategory}`
            currentLocation.result = finalData.reduce((a, b) => Number(a) + Number(b["result"] || 0), 0) / finalData.length
            currentLocation.passed = !currentLocation?.extra ? finalData.every(x => Number(x.result) >= getConst(currentLocation.resultCategory)) : null
            
            dispatch(ACTION_LOADING_SPINNER_ACTIVE())
            uploadPdf(currentLocation, true)
              } 
              // else {
              //   setValidateError([validation])
              // }
            }
          } else {
            resetActiveTest()
          }
            }
            }
           
          }
      },[finalPdf, theEnd])  
      
      const [suggestModal, setSuggestModal] = useState(false)
      const [withTest, setWithTest] = useState(null)
      const resetAuditBeforeSave = (manual) => {
        setWithTest(matchedTest)
        setAddViewConfirmModal(manual)
        // generateReport("no", [matchedTest], true)
      }
      const rejectFile = () => {
        //setMatchedTest(null)
        setAreaLocation(null)
        let attachments = document.querySelector(`[id=imagesContainer]`)
            attachments ? attachments.innerHTML = ("") : null
        setPdfFile(null)
        setPullingDataActive(0)

        setFile(null)
        setFileError(true) 
      }

      const [temporaryTest, setTemporaryTest] = useState(null)
      const [areaLocation, setAreaLocation] = useState(null)
      const resetActiveTest = (connect) => {

            setPdfFile(null)
            setMatchedData(null)
            setExactData(null)
            setCurrentTest(null)
            setFinalData([])
            setPdfFile(null)
            setMyState("data")
            setPullingDataActive(0)
            setPdfFileNotFound(false)
            setPdfImages([])
            setNewPdfImages([])
            setFileError(null)
            setAreaLocation(null)

            if(!isDisconnected) {
              char?.writeValueWithResponse(writeCommand('0x02 0x00 0x06 0x06 0x27 0x08'))
            }
            currentTest && dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [ ...selectedTests[selectedAudit?.defaultView]?.filter(x => x?.locationId !== currentTest?.locationId && x?.areaCondition !== "pending") ]}))
            setDataOnFLy(null)
            setFinalPdf([])
            setTheEnd(null)
            setTheReEnd(null)
            setTemporaryTest(null)
            setMatchedTest(null)
            setWrongDataModal(null)
            setValidateError(null)
            setPullButtonActive(false)
            setStartTestModal(false)
            connect && connectToDeviceAndSubscribeToUpdates()

      }

        let validateDate = (date) => {
        if(date) {
        let rightDate = date.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g)
        if(rightDate?.length) {
          return rightDate[0]
        } else {
          return null
        }
      } else {
        return null
      }
      }

      const validatepdf = (pdf , data, manual, extra, single) => {
        let oneData = data.find(x => x.testNumber == "1") || data[0]
        let onePage = pdf[0]
        let dcof = onePage.includes("DCOF")
        let pageDateTime = onePage.slice(onePage.indexOf("Regan Scientific Instruments") + 28, onePage.indexOf("Method:"))
        let pageFourDate = pageDateTime.split(", ")[0]
        let pageDate = pageFourDate.length == 10 ? pageFourDate.slice(0,-4)+pageFourDate.slice(-2) : pageFourDate
        let pageTime = pageDateTime.split(", ")[1]
        let pageVerification = onePage.slice(onePage.indexOf("Verification:") + 14, onePage.indexOf("Meter:"))    
        let pageVerificationFullDate = pageVerification.slice(0, pageVerification.indexOf(", "))
        let pageVerificationDate = pageVerificationFullDate.slice(0,-4)+pageVerificationFullDate.slice(-2)
        let pageVerificationPass = pageVerification.indexOf("PASS") !== "-1" ? true : false 
        // let pageMethod = onePage.slice(onePage.indexOf("Method:") + 8, onePage.indexOf("Area"))
        let pageType = onePage.slice(onePage.indexOf("Type:") + 6, onePage.indexOf("Condition"))
        let pageArea = onePage.slice(pageType.includes("Lab") ? onePage.indexOf("Product") + 8 : onePage.indexOf("Area") + 5 , pageType.includes("Lab") ? onePage.indexOf("Specimen") : onePage.indexOf("Location"))
        let pageLocation = onePage.slice( pageType.includes("Lab") ? onePage.indexOf("Specimen") + 9 : onePage.indexOf("Location") + 9, onePage.indexOf("Result"))
        let pageResult = onePage.slice(onePage.indexOf("Result:") + 8, onePage.indexOf("Distance"))
        let pageCondition = onePage.slice(onePage.indexOf("Condition:") + 11, onePage.indexOf("1. Avg ="))
        // return "done"
        if((!manual && dcof ) || (single && extra && oneData?.date?.toLowerCase() == pageDate?.toLowerCase() &&
          oneData?.time?.toLowerCase() == pageTime?.toLowerCase() &&
          oneData?.result?.toLowerCase() == pageResult?.toLowerCase() && dcof) || 
          ( extra && oneData?.date?.toLowerCase() == pageDate?.toLowerCase() &&
          oneData?.time?.toLowerCase() == pageTime?.toLowerCase() &&
          oneData?.result?.toLowerCase() == pageResult?.toLowerCase() && 
          oneData?.type?.toLowerCase() == pageType?.toLowerCase() 
          && oneData?.condition?.toLowerCase() == pageCondition?.toLowerCase() && dcof) || 
          (oneData?.date?.toLowerCase() == pageDate?.toLowerCase() &&
          oneData?.time?.toLowerCase() == pageTime?.toLowerCase() &&
          oneData?.area?.toLowerCase() == pageArea?.toLowerCase() &&
          oneData?.result?.toLowerCase() == pageResult?.toLowerCase() &&
          oneData?.location?.toLowerCase() == pageLocation?.toLowerCase() &&
          oneData?.type?.toLowerCase() == pageType?.toLowerCase() 
          && oneData?.condition?.toLowerCase() == pageCondition?.toLowerCase() && dcof)
          ) { 
          setAreaLocation(`A${pageArea}, L${pageLocation}`)
          let pageValidDate = moment(pageDate).subtract(1, "day")
          let verificationValidDate = moment(pageVerificationDate) 
          let firstTime = firstTimeOnFly || validateDate(selectedAudit.firstTime)
          let betweenTime = moment(firstTime).add(2, "day")
          
        if(!manual || extra || (pageValidDate.isSameOrBefore(verificationValidDate) && pageVerificationPass)) {
      
          if(firstTime && !extra && firstTimeOnFly !== "reset") {
            if(moment(pageDate).isBetween(moment(firstTime), betweenTime, null, '[]')) {
              return "done"
            } else {
              setSuggestModal(manual ? "manual" : "live")
              return manual ? "done" : false
            }
            } else {   
              return "done"
            }
            
          } else {
            

            manual ? setWarningModal(
              <div>
                    <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
                    <span style={{margin:"15px 0", textTransform:"none"}}>When Verification Mass was placed in the BOT-3000E for Verification of the internal strain gauge system, the Verification FAILED. Hit Re-test and repeat this process on level ground. Testing is not valid if the System Verification does not pass!</span>            
                    </div>
                </div>) : setValidateError([<div>
                    <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
                    <span style={{margin:"15px 0", textTransform:"none"}}>When Verification Mass was placed in the BOT-3000E for Verification of the internal strain gauge system, the Verification FAILED. Hit Re-test and repeat this process on level ground. Testing is not valid if the System Verification does not pass!</span>            
                    </div>
                </div>])
            return "Data not matched"
            //return "done"

          }
        } else {

          let errors = []
          !dcof ? errors.push("SCOF Values are not able to be uploaded") : null
          oneData?.date?.toLowerCase() !== pageDate?.toLowerCase() ? errors.push("Date not matched") : null
          oneData?.time?.toLowerCase() !== pageTime?.toLowerCase()  ? errors.push("Time not matched") : null
          oneData?.result?.toLowerCase() !== pageResult?.toLowerCase()  ? errors.push("Result not matched") : null
          !single && oneData?.type?.toLowerCase() !== pageType?.toLowerCase()  ? errors.push("Type not matched") : null
          !single && oneData?.condition?.toLowerCase() !== pageCondition?.toLowerCase()  ? errors.push("Condition not matched") : null
          !single && !extra && oneData?.area?.toLowerCase() !== pageArea?.toLowerCase()  ? errors.push("Area not matched") : null
          !single && !extra && oneData?.location?.toLowerCase() !== pageLocation?.toLowerCase()  ? errors.push("Location not matched") : null

          manual ? setWarningModal(
            <div>
            <div className='squareContent' style={{fontSize:"18px", margin:"0 5px", textTransform:"none"}}>Verification between data log file and pdf failed</div>
            <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
            {errors.map(x => (
              <span key={x} style={{margin:"15px 0", textTransform:"none"}}>- {x}</span>
            ))}        
            </div>
        </div>) : setValidateError([<div>
            <div className='squareContent' style={{fontSize:"18px", margin:"0 5px", textTransform:"none"}}>Verification between data log file and pdf failed</div>
            <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
            {errors.map(x => (
              <span key={x} style={{margin:"15px 0", textTransform:"none"}}>- {x}</span>
            ))}        
            </div>
        </div>])
          return "Data not matched"
        } 
      } 
      async function getPdfText(data) { 
        let dataBuffer = await data.arrayBuffer()
                        let doc = await pdfjsLib.getDocument({data: dataBuffer}).promise;
                        let pageTexts = Array.from({length: doc.numPages}, async (v,i) => {
                            return (await (await doc.getPage(i+1)).getTextContent()).items.map(token => token.str).join('');
                        });
                        let text  = await Promise.all(pageTexts)
                        return text
                    } 

      
                    const [file, setFile] = useState(null)
                    const [fileError, setFileError] = useState(null)

                    const getConst = (co) => {
                      if(co == "Interior, Dry") {
                        return 0.42
                      } else if(co == "Interior, Wet") {
                        return 0.42
                      } else if(co == "Interior, Wet Plus") {
                        return 0.50
                      } else if(co == "Exterior, Wet") {
                        return 0.55
                      } else if(co == "Oils/Greases") {
                        return 0.55
                      }
                    }
      const convertuploadedPdf = async (pdf, data, mode, mistake) => {
        let blobBase = new Blob([pdf], {
          type: 'octet/stream'
        })
        if(pdf == "pendulum") {
        let currentLocation = matchedTest 
            currentLocation.pendulum = data  
            currentLocation.pending = false
        } else if ( 
          //finalData.every(x => x.name == pdf.name)
          true
          ) {
          let pdfText = await getPdfText(blobBase)
          let validation = validatepdf(pdfText, finalData, true, matchedTest?.extra, finalData.length == 1)
          if(validation == "done") {
            setFile(pdf)
            let currentLocation = matchedTest
            currentLocation.results = finalData  
            // currentLocation.pendulum = finalData  
            currentLocation.pdf = blobBase 
            currentLocation.pending = false
            currentLocation.result = finalData.reduce((a, b) => Number(a) + Number(b["result"] || 0), 0) / finalData.length
            currentLocation.passed = !currentLocation?.extra ? finalData.every(x => Number(x.result) >= getConst(currentLocation.resultCategory)) : null
              
            dispatch(ACTION_LOADING_SPINNER_ACTIVE())
            uploadPdf(currentLocation, false, finalData.length == 1)
            setPdfFile(blobBase)
          } else {
            setFile(null)
            setFileError(validation)
          }
        }
      }
      useEffect(async() => {  
        if(finalPdf && theReEnd) {
          if(finalPdf.length > 10) {
          let hexFinal = finalPdf.join("")
          let ignoredCommand = []
          ignoredCommand.push(hexFinal.slice(0, hexFinal.indexOf("255044462d312e330a25b")))
          ignoredCommand.push(hexFinal.slice(hexFinal.length - 16, hexFinal.length))
          indexesOf('022006a2').in(hexFinal).map(x => {
            ignoredCommand.push(hexFinal.slice(x-4 , x+8))
          })
          let hexBeforeEnd = await removeFromString(ignoredCommand, hexFinal)
          if(hexBeforeEnd) {
          const baseBlob = Buffer.from(hexBeforeEnd, 'hex').toString('base64')
          if(baseBlob) {
          const blobBase = await b64toUrl(baseBlob)
          if(blobBase) {
          let currentLocation = matchedTest
          currentLocation.results = finalData
          currentLocation.pdf = blobBase
          currentLocation.pending = false
          currentLocation.result = finalData.reduce((a, b) => Number(a) + Number(b["result"] || 0), 0) / finalData.length
          currentLocation.passed = !currentLocation?.extra ? finalData.every(x => Number(x.result) >= getConst(currentLocation.resultCategory)): null
          dispatch(ACTION_LOADING_SPINNER_ACTIVE())
          uploadPdf(currentLocation)
          setPdfFile(blobBase)  
          }
          }
          }
          } else {
            setPdfFileNotFound(true)
            setPullingDataActive(0)

          }
        }
    },[finalPdf, theReEnd])  
      
        useEffect(() => {
          if (navigator.bluetooth) {
            setSupportsBluetooth(true);
          }
        }, []);
 
    // save report 
      
    const [beforeGenerateModal, setBeforeGenerateModal] = useState(null)
    const [extraDataModal, setExtraDataModal] = useState(null)
    const addExtraData = (data, del) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      let newProject = selectedAudit
      if(del) {
        newProject.extra = newProject?.extra?.length > 1 ? newProject?.extra.filter(x => JSON.stringify(x) !== JSON.stringify(data)) : []
      } else { 
        newProject.extra = newProject?.extra?.length ? [...newProject?.extra, data] : [data]
      }
      setProjectData(projectData.map(t => t.id === newProject.id ? newProject : t))
      resetActiveTest()
      updateView({
        variables:{
          _id: selectedAudit.defaultView,
          extra: JSON.stringify(data),
          del: del ? true : false
        }
      }).then(x => {
        resetActiveTest()
        projectsRefetch()
      })
      
    }
    const [imagesLaterModal, setImagesLaterModal] = useState(null)
    const updateAreas = (updatedAreas) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setImagesLaterModal(null)
      updatedAreas.map((x, i, a) => {
        updateArea({
          variables:{ 
            _id: x.id,
            images: x.images?.map(x => x.url),
            user: user?.user?._id,
          }
        }).then((res) => {
          if(res) {
            if(i == a.length - 1) { 
              allAuditHistoryRefetch()
              allAreasRefetchToLoad()
              setAddAreaDropDown(true)
              allAreasRefetch().then(res => {
                if(res) {
                  let allAreas = res?.data?.allAreas
                  let newAreas = areas.map(x => {
                    let selected = allAreas.find(y => y._id == x.id)
                    x.images = selected ? selected.images : x.images
                    return x
                  })
                  generateReport("yes", areas?.map(x => x?.results?.map(y => y))?.flat(1)?.filter(y => y), null,areas?.map(x => x?.extra?.map(y => y))?.flat(1)?.filter(y => y), newAreas )
                  dispatch(ACTION_LOADING_SPINNER_RESET())

                }
              })
              
            }
          }
           
        })
      })
    }


    function extractKeyFromUrl(url) {
      return url.slice(url.indexOf("s3.amazonaws.com/") + "s3.amazonaws.com/".length); // Extracts the filename from the URL
  }

  async function uploadImage(base64Image, key) {
    console.log(`Uploading image with key: ${key}`);

    try {
        const myBucket = await fetchCredentials(); // Ensure this returns a valid S3 instance
        if (!myBucket) throw new Error("S3 Bucket instance not found");

        // Convert base64 to Buffer
        const base64Data = Buffer.from(base64Image.replace(/^data:image\/\w+;base64,/, ""), "base64");
        const type = base64Image.match(/^data:image\/(\w+);base64,/)[1]; // Extract image type

        // S3 Upload Parameters
        const params = {
            ACL: "public-read",
            Body: base64Data,
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: key, // Keep the same key structure
            ContentEncoding: "base64",
            ContentType: `image/${type}`,
        };

        // Upload to S3
        await myBucket.putObject(params).promise();

        // Return the new image URL
        return `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${key}.png`;

    } catch (error) {
        console.error("S3 Upload Error:", error);
        return null; // Return null in case of failure
    }
}

async function createPDF(base64Images) {
  console.log("Generating PDF from images...");

  const pdfDoc = await PDFDocument.create();

  for (const base64Image of base64Images) {
      const imageBytes = Buffer.from(base64Image.split(',')[1], 'base64');
      const image = await pdfDoc.embedPng(imageBytes);
      const page = pdfDoc.addPage([image.width, image.height]);
      page.drawImage(image, { x: 0, y: 0, width: image.width, height: image.height });
  }

  return await pdfDoc.save();
}


async function uploadPDF(pdfData, key) {
  console.log(`Uploading PDF with key: ${key}`);

  const params = {
      ACL: 'public-read',
      Body: pdfData,
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: key,
      ContentType: 'application/pdf'
  };

  const myBucket = await fetchCredentials();
  if (myBucket) {
      await myBucket.putObject(params).promise();
      return `https://${process.env.REACT_APP_S3_BUCKET}/${key}`
  }

  console.log("Failed to upload PDF");
  return null;
}



async function fixBrokenPDFs(secondObject, uploadPDF) {
  console.log("Starting PDF replacement process...");

  const pdfs = secondObject.pdfs;
  console.log(pdfs, secondObject, "from pdfssss")
  const structuredData = secondObject.structuredData;

  for (const areaKey in pdfs) {
      console.log(`Processing PDFs for area: ${areaKey}`);

      if (!structuredData[areaKey]) {
          console.log(`No structured data found for area ${areaKey}, skipping...`);
          continue;
      }

      for (const locationKey in pdfs[areaKey]) {
          console.log(`Processing PDF for location: ${locationKey} in area ${areaKey}`);

          const pdfKey = extractKeyFromUrl(pdfs[areaKey][locationKey]);
          console.log(`Found broken PDF with key: ${pdfKey}`);

          const structuredLocation = structuredData[areaKey][locationKey];

          if (!structuredLocation || structuredLocation.length < 3) {
              console.log(`Not enough images to create a PDF for location ${locationKey}, skipping...`);
              continue;
          }

          // Convert base64 images into a PDF
          const newPDF = await createPDF(structuredLocation);

          // Upload new PDF with the same key
          const newPdfUrl = await uploadPDF(newPDF, pdfKey);

          if (newPdfUrl) {
              console.log(`Successfully uploaded fixed PDF: ${newPdfUrl}`);
              pdfs[areaKey][locationKey] = newPdfUrl;
          } else {
              console.log(`Failed to upload fixed PDF for key: ${pdfKey}`);
          }
      }
  }

  console.log("PDF replacement process completed.");
}


    
const [loadingForBroken, setLoadingForBroken] = useState(false)
async function replaceBrokenImages(firstObject, secondObject, uploadImage, uploadPDF) {
  setLoadingForBroken(true)
  console.log("Starting image replacement process...");

  
  const areas = firstObject.areas;

  let testMode = true
    // Replace main object images in order
    if (firstObject.images && Array.isArray(firstObject.images) && 
        secondObject.images && Array.isArray(secondObject.images) && (
        firstObject.images.length === secondObject.images.length  || testMode)) {
          
        
        console.log(`Replacing ${firstObject.images.length} main object images...`);
        
        for (let i = 0; i < firstObject.images.length; i++) {
          
            const originalKey = extractKeyFromUrl(firstObject.images[i]);
            const base64Image = secondObject.images[i];

            console.log(`Replacing main image ${i + 1} with key: ${originalKey}`);

            // Upload new image to S3 with the same key
            const newUrl = await uploadImage(base64Image, originalKey);

            if (newUrl) {
                console.log(`Successfully uploaded new main image. Updated URL: ${newUrl}`);
                firstObject.images[i] = newUrl;
            } else {
                console.log(`Failed to upload main image for key: ${originalKey}`);
            }
        }
    } else {
        console.log("Skipping main object images: Image count mismatch.");
    }

    

  console.log(firstObject, "areas")
  for (const areaKey in areas) {
    
      console.log(`Processing area: ${areaKey}`);

      const area = areas[areaKey];
      console.log(areas)

      console.log( secondObject)

      
      const structuredArea = secondObject.structuredData[areaKey];

      if (!structuredArea) {
          console.log(`No matching structured data found for area ${areaKey}, skipping...`);
          continue;
      }


      for (const locationKey in area.locations) {
        
          console.log(`Processing location: ${locationKey} in area ${areaKey}`);
          const location = area.locations[locationKey];
          const structuredLocation = structuredArea[locationKey];

          if (!structuredLocation) {
              console.log(`No matching structured data found for location ${locationKey}, skipping...`);
              continue;
          }

          // Replace images
          for (const image of location.images) {
            
              const sampleIndex = parseInt(image.sample) - 1;
              console.log(`Checking sample: ${image.sample} (Index: ${sampleIndex}) for location ${locationKey}`);

              if (structuredLocation[sampleIndex]) {
                  const base64Image = structuredLocation[sampleIndex];
                  const originalKey = extractKeyFromUrl(image.src);
                  console.log(`Replacing image with key: ${originalKey}`);

                  // Upload new image to S3 with the same key
                  const newUrl = await uploadImage(base64Image, originalKey);
                  
                  if (newUrl) {
                      console.log(`Successfully uploaded new image. Updated URL: ${newUrl}`);
                      image.src = newUrl;
                  } else {
                      console.log(`Failed to upload image for key: ${originalKey}`);
                  }
              } else {
                  console.log(`No corresponding base64 image found for sample index ${sampleIndex}`);
              }
          }
      }
  }



  await fixBrokenPDFs(secondObject, uploadPDF);


  allAreasRefetchToLoad()
  allAreasRefetch()
  allLocationsRefetch()
  
  setLoadingForBroken(false)
  console.log("Image replacement process completed.");
}

  
  // Helper function to extract S3 key from URL
  
  // Example usage
  
    useEffect(() => {
      if(fixAudit && areas?.length) {
        let areasResults = areas.filter(x=> x.results.length).map(x => x.results)
        console.log(areasResults, "results")


        // here to convert it to object area lcoation urls

        const inputData = [ ...areasResults ];

      const transformedData = {
        pdfs: {}
      }; 

      inputData[0].forEach(area => {
        const areaKey = area.areaCode;
        
        if (!transformedData.pdfs[areaKey]) {
          transformedData.pdfs[areaKey] = {};
        }

        const locationKey = area.locationCode;
        transformedData.pdfs[areaKey][locationKey] = area.pdfUrl;
      });

        areas?.map(x => x?.results?.map(y => y))?.flat(1)?.length ? generateReport(
          "yes", 
          areas?.map(x => x?.results?.map(y => y))?.flat(1)?.filter(y => y),
          null,
          areas?.map(x => x?.extra?.map(y => y))?.flat(1)?.filter(y => y),
          null,
          true, {...structuredImages, pdfs: transformedData.pdfs} ) : null
      }
    },[areas, fixAudit])

    const generateReport = (generate, newTests, reset, extra, newAreas, fix, structuredImages) => {   

      // dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      let allAreas = newAreas?.length ? newAreas : areas
      if(areasToUpdate?.length) {
        areasToUpdate.map((x, i, a)=> {
          updateArea({
          variables: {
            _id: x.id,
            testingCategory: x.testingCategory,
            areaNumber: x.areaNumber,
            resetFirst: firstTimeOnFly == "reset" ? true : false
          }
          }).then(y => {
            if(i == a.length - 1) {
              setAreasToUpdate([])
              if(false) {
                return null
              } else {
                generate == "no" && dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : null}))
                generate == "no" && setOnFlyAudit(null)
                let allTests = newTests
                let extraTests = selectedAudit?.extra
                let variables = {}
                let finalReport = {} 
                let constant = 0.42  
                let tests = allTests
          
            
                const allLocationsArr = allAreas.map(x => x.locationsCoordinates.map(y => {
                  return {
                    location: Object.keys(y)?.length ? Object.keys(y)[0] : "N/A",
                    area: x.areaNumber, 
                    locationId: y[Object.keys(y)[0]]?.id,
                    sortId: `${Number(x.areaNumber)}${Object.keys(y)?.length ? Number(Object.keys(y)[0].replace( /^\D+/g, '')) >= 10 ? Number(Object.keys(y)[0].replace( /^\D+/g, '')) : `0${Number(Object.keys(y)[0].replace( /^\D+/g, ''))}` : 0}`
                  } 
                }).filter(x => !allTests.some(item => item.locationId == x.locationId)))
          
                let testedAreas = allAreas.filter(f => allTests.some(item => item.areaId === f.id))
                let resetedAreas = allAreas.filter(f => allTests.every(item => item.areaId !== f.id) || !allTests.length)
                let failTestedAreas = allAreas.filter(f => allTests.some(item => item.areaId === f.id && !item.passed))
                let passTestedAreas = allAreas.filter(f => allTests.some(item => item.areaId === f.id && item.passed))
                let testedLocations = locations.filter(y => allTests.some(item => item.locationId === y.id))
                let notTestedLocations = locations.filter(y => !allTests.some(item => item.locationId === y.id))
                if(testedAreas.length) {}
                let project = projectData.filter(d =>  testedAreas?.length ? testedAreas[0].view == d.defaultView : false)

                let pdfFiles = []

                let pdfUrls = [...(extraTests?.length ? extraTests : []) , ...(allTests?.length ? allTests : [])].map(x => {
                  if(x?.pdfUrl) {
                    return {  
                      test: x, 
                      pdf: x.pdfUrl
                    }
                  } 
                })?.filter(x => x)
          
          
                // sub project data 
                let site = project[0]?.site
                let client = project[0]?.client
                let auditor = project[0]?.assignee
                let tile = project[0]?.tileResult
                let clientObj = { client: {
                  _id: client?._id,
                  companyName: client?.companyName,
                }}
          
                let siteObj = { site:{ 
                  _id: site?._id,
                  name: site?.name,
                }} 
          
                // new varibales
          
          
                // client vars
                variables.clientObj = clientObj
                variables.ClientZipCode = client?.zip
                variables.ClientZipCode = client?.zip
                variables.ClientCompany = client?.companyName,       
                variables.ClientCompanyStreetAddress = client?.companyAddress?.split(',')[0],  
                variables.ClientCompanyCity = client?.companyAddress?.split(',')[1],  
                variables.ClientCompanyState = client?.companyAddress?.split(',')[2],  
                variables.ClientContactName = client?.contactPersonName, 
          
                // site vars
                variables.SiteZipCode = site?.zip  
                variables.siteObj = siteObj
                variables.SiteName = site?.name,
                variables.SiteStreetAddress = site?.address?.split(',')[0],  
                variables.SiteCity = site?.address?.split(',')[1],  
                variables.SiteState = site?.address?.split(',')[2],  
                variables.SystemVerification = "Pass"
          
                // tile vars
                variables.tile = tile?.exist
                variables.tileResults = tile
                if(tile?.exist) { 
                variables.beforeFirst = tile?.first
                variables.beforeSecond =  tile?.second
                variables.beforeAverage = tile?.pass ? "Yes" : "No"
                variables.afterFirst = tile?.firstAfter
                variables.afterSecond =  tile?.secondAfter
                variables.afterAverage =  tile?.passAfter ? "Yes" : "No"
                }
          
                let isWmg = project[0]?.createdByType?.includes("WMG") ?  project[0]?.createdById : null

                // auditor vars
                variables.isWmg = isWmg
                variables.auditor = auditor
                variables.typeDescription = testedAreas.some(x => x.floorTypeDescription)
                variables.finishDescription = testedAreas.some(x => x.floorFinishDescription)
                let auditNotes = project[0]?.comments?.map(x => `- Audit Notes: ${x}`) || []
                variables.AuditorNotes = [...testedAreas?.map(x => `- ${x.name} - A${x.areaNumber}: ${x.auditorsNotes || "No area comment"}\n
                ${x.floorTypeDescription ? `* Floor Type Description : ${x.floorTypeDescription}\n` : ""}
                ${x.floorFinishDescription ? `* Floor Finish Description : ${x.floorFinishDescription}` : ""}
                `), ...auditNotes]  
                variables.AuditorName = auditor?.map((x, i) => ` ${x.name} (${isWmg ? "WMG" :x.companyName})`).toString() 
                variables.AuditorCompany = auditor?.filter((v,i,a)=>a.findIndex(v2=>(v2.companyName===v.companyName))===i).map((x, i) => i !== 0 ? ` ${isWmg ? "WMG" :x.companyName}` : `${isWmg?"WMG":x.companyName}`).toString() 
                variables.AuditorNameWithPhone = auditor?.map((x, i) => ` ${x.name} (${isWmg?"WMG":x.companyName}) at ${isWmg ? "(940) 464-9103" : x.phoneNumber?.replace(/\s/g, '').replace(")", ") ")}`).toString() 
                variables.certificate = auditor?.filter(x => x.certificate).map(x => {

                  return {
                    certificate: x.certificate.includes("step-us-east-prod") ? "https://step-prod-bucket.s3.amazonaws.com/noImage.png" : x.certificate,
                    letter: x.letter ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/WalkwaySafetyRenewal2.jpg-1680209373907.png` : null,
                    name: x.name,
                    company: isWmg ? "WMG" : x.companyName,
                    certificateDate: moment(x.certificateExpiry).subtract(3,"year").format("MM/DD/YYYY")
                  }
                })
         
                // extra vars
          
                variables.PassOrFail = constant
                variables.extraTests = extraTests
                variables.addendum = extraTests?.filter(x=>x)?.length ? true : false
          
                // tests vars
          
                variables.NumberOfPassingLocations = allTests?.filter(x => x.passed)?.length || "0"
                variables.TotalNumberofFailingLocations = allTests?.filter(x => !x.passed)?.length || "0",  
                variables.NumberOfFailingLocations = allTests?.filter(x => !x.passed)?.length || "0",
                variables.NotTestedLocations = notTestedLocations
                variables.allLocationsArr = allLocationsArr?.flat().sort((a,b) => (Number(a.sortId) > Number(b.sortId)) ? 1 : ((Number(b.sortId) > Number(a.sortId)) ? -1 : 0))
                variables.allPass = allTests?.every(test => test.passed)
                variables.allFail = allTests?.every(test => !test.passed) 
                variables.TotalNumberOfTestLocations = testedLocations?.length
                variables.NumberOfAreasTested = testedAreas.length,  
                variables.NumberOfLocationsTested = testedLocations?.length,
                variables.NumberOfFailingTestAreas = failTestedAreas?.length
                variables.NumberOfPassingTestAreas = passTestedAreas?.length
                variables.AreaMainPhotos = generate == "yes" ? testedAreas.map(x => {
                  return { 
                    image: x.images[0],
                    number: `A${x.results[0].areaCode}`,
                    name: `${x.name}`,
                    locations: x.locationsCoordinates.filter((x) => testedLocations?.some(l => x[Object.keys(x)]?.id == l?.id ))?.map(y => `L${Object.keys(y).map(z => z.slice(z.indexOf("location - ") + 11, z.length))}`)?.flat(1)?.sort().join(', '),
                    //  
                    testingCategory: x.testingCategory
                  }
                })?.sort((a,b) => a-b) : [] 
          
                // user vars
                variables.userId = user?.user?._id
                let surface = user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                  serial: "N/A", 
                  wetValue: "N/A",
                  dryValue: "N/A"
                }
                
                variables.ValidationSurfaceWetValue = surface?.wetValue
                variables.ValidationSurfaceDryValue = surface?.dryValue
                variables.ValidationSurfaceWet = tileResult?.dcof == "wet" ? true : false
                variables.ValidationSurfaceDry = tileResult?.dcof == "dry" ? true : false
          
                // audit vars
                variables.AuditDate = moment(selectedAudit?.firstTime).format("MM/DD/YYYY")
                finalReport.project = project
                variables.DateReportGenerated = moment().format("MM/DD/YYYY"),  
                variables.AuditPhotos = testedAreas.map(x => x.images)?.reduce((a, b) => a.concat(b), []).filter((item, index, array) => array.indexOf(item) === index);
                variables.view = selectedAudit.defaultView
          
          
                // obj vars
                finalReport.tests = tests 
                finalReport.areas = testedAreas
                finalReport.resetedAreas = resetedAreas
                finalReport.locations = testedLocations
                finalReport.variables = variables
                finalReport.pdfFiles = pdfFiles 
          
                // non used vars
                variables.Certificate = "certificate pending",   
                variables.CertificatesAttached = "CertificatesAttached pending", 
                variables.AreaName = null,  
                variables.DateTestingDiagramWasCreated = "DateTestingDiagramWasCreated pending",   
                variables.TestingDiagramCreatorCompanyName = "TestingDiagramCreatorCompanyName pending",  
                variables.DateEditedTestingDiagram = "DateEditedTestingDiagram pending",  
                variables.TestingDiagramEditedBy = "TestingDiagramEditedBy pending",  
                variables.TestingDiagramEditedByCompanyName = "TestingDiagramEditedByCompanyName pending",  
                variables.TestingConditions, // location
                variables.TestingCategory, // location
                variables.FloorType = null,   // area
                variables.FloorTexture = null,   // area
                variables.FloorFinish = null,   // area
                variables.ConditionOfFloorFinish = null,   // area
                variables.SlopeOfFloorSurface = null,   // area
                variables.DateOnCertificate = "DateOnCertificate pending",   
                variables.FailingLocationNumber = null, // location  
                variables.PassingLocationNumber = null // location
                variables.TestingCategory = "pending for more area"
                variables.FlooringTypeNotes = null
                variables.TilePass = null
          
          
          
                if(pdfUrls?.length > 0 && generate == "yes") {

                  if(fix) {
                    generateReportDone({pdfUrls, pdfFiles, variables, finalReport, generate, reset, fix, structuredImages})
                  } else {
                    setBeforeGenerateModal({pdfUrls, pdfFiles, variables, finalReport, generate, reset})

                  }
                } else {
                  setWarningMessage(null)
                  history.push({
                  pathname: `/admin/reports/template/viewer`,
                  state: { 
                  type:"audits",
                  defaultDCOF: defaultDCOF ? defaultDCOF : null,
                  resetedOnly: !testedAreas.length && resetedAreas.length,
                  variables: variables,
                  allVariables: templateVariables,
                  finalReport: finalReport,
                  generate: generate == "yes" ? true : false,
                  fixAudit: fix,
                  reset: reset ? true : false,
                  resetTime: firstTimeOnFly ? true : false,
                  structuredImages: structuredImages
                  }
              })
              setFirstTimeOnFly(null)
              }
            }
            }
          })
        })
        } else {


      generate == "no" && dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : null}))
      generate == "no" && setOnFlyAudit(null)
      let allTests = newTests
      let extraTests = selectedAudit?.extra
      let variables = {}
      let finalReport = {}
      let constant = 0.42 
      let tests = allTests

   
      const allLocationsArr = allAreas.map(x => x.locationsCoordinates.map(y => {

        return {
          location: Object.keys(y)?.length ? Object.keys(y)[0] : "N/A",
          area: x.areaNumber, 
          locationId: y[Object.keys(y)[0]]?.id,
                    sortId: `${Number(x.areaNumber)}${Object.keys(y)?.length ? Number(Object.keys(y)[0].replace( /^\D+/g, '')) >= 10 ? Number(Object.keys(y)[0].replace( /^\D+/g, '')) : `0${Number(Object.keys(y)[0].replace( /^\D+/g, ''))}` : 0}`
        } 
      }).filter(x => !allTests.some(item => item.locationId == x.locationId)))

      let testedAreas = allAreas.filter(f => allTests.some(item => item.areaId === f.id))

      let resetedAreas = allAreas.filter(f => allTests.every(item => item.areaId !== f.id))
      let failTestedAreas = allAreas.filter(f => allTests.some(item => item.areaId === f.id && !item.passed))
      let passTestedAreas = allAreas.filter(f => allTests.some(item => item.areaId === f.id && item.passed))
      let testedLocations = locations.filter(y => allTests.some(item => item.locationId === y.id))
      let notTestedLocations = locations.filter(y => !allTests.some(item => item.locationId === y.id))







      let project = projectData.filter(d =>  testedAreas?.length ? testedAreas[0].view == d.defaultView : false)
      let pdfFiles = []

      let pdfUrls = [...(extraTests?.length ? extraTests : []) , ...(allTests?.length ? allTests : [])].map(x => {
        if(x?.pdfUrl) {
          return { 
            test: x,
            pdf: x.pdfUrl
          }
        }
      })?.filter(x => x)


      // sub project data 
      let site = project[0]?.site
      let client = project[0]?.client
      let auditor = project[0]?.assignee
      let tile = project[0]?.tileResult

      let clientObj = { client: {
        _id: client._id,
        companyName: client.companyName,
      }}

      let siteObj = { site:{ 
        _id: site._id,
        name: site.name,
      }}
      let isWmg = project[0]?.createdByType?.includes("WMG") ?  project[0]?.createdById : null
      // new varibales


      // client vars
      variables.clientObj = clientObj
      variables.ClientZipCode = client?.zip
      variables.ClientZipCode = client?.zip
      variables.ClientCompany = client?.companyName,       
      variables.ClientCompanyStreetAddress = client?.companyAddress?.split(',')[0],  
      variables.ClientCompanyCity = client?.companyAddress?.split(',')[1],  
      variables.ClientCompanyState = client?.companyAddress?.split(',')[2],  
      variables.ClientContactName = client?.contactPersonName, 

      // site vars
      variables.SiteZipCode = site?.zip  
      variables.siteObj = siteObj
      variables.SiteName = site?.name,
      variables.SiteStreetAddress = site?.address?.split(',')[0],  
      variables.SiteCity = site?.address?.split(',')[1],  
      variables.SiteState = site?.address?.split(',')[2],  
      variables.SystemVerification = "Pass"

      // tile vars
      variables.tile = tile.exist
      variables.tileResults = tile
      if(tile.exist) { 
      variables.beforeFirst = tile.first
      variables.beforeAndAfter = tile.pass == "true" || tile.passAfter == "true" ? true : false
      variables.beforeSecond =  tile.second
      variables.beforeAverage = tile.pass ? "Yes" : "No"
      variables.afterFirst = tile.firstAfter
      variables.afterSecond =  tile.secondAfter
      variables.afterAverage =   tile.passAfter ? "Yes" : "No"
      }

      // auditor vars
      variables.isWmg = isWmg
      variables.auditor = auditor
      variables.typeDescription = testedAreas.some(x => x.floorTypeDescription)
      variables.finishDescription = testedAreas.some(x => x.floorFinishDescription)
      let auditNotes = project[0]?.comments?.map(x => `- Audit Notes: ${x}`) || []
      variables.AuditorNotes = [...testedAreas?.map(x => `- ${x.name} - A${x.areaNumber}: ${x.auditorsNotes || "No area comment"}\n
                ${x.floorTypeDescription ? `* Floor Type Description : ${x.floorTypeDescription}\n` : ""}
                ${x.floorFinishDescription ? `* Floor Finish Description : ${x.floorFinishDescription}` : ""}
                `), ...auditNotes]  
      variables.AuditorName = auditor?.map((x, i) => ` ${x.name} (${isWmg ? "WMG" :x.companyName})`).toString() 
      variables.AuditorCompany = auditor?.filter((v,i,a)=>a.findIndex(v2=>(isWmg ? "WMG" : v2.companyName===isWmg ? "WMG":v.companyName))===i).map((x, i) => i !== 0 ? ` ${isWmg ? "WMG" :x.companyName}` : `${isWmg?"WMG":x.companyName}`).toString() 
      variables.AuditorNameWithPhone = auditor?.map((x, i) => ` ${x.name} (${isWmg?"WMG":x.companyName}) at ${isWmg ? "(940) 464-9103" : x.phoneNumber?.replace(/\s/g, '').replace(")", ") ")}`).toString() 
      variables.certificate = auditor.filter(x => x.certificate).map(x => {

        return {
          certificate: x.certificate.includes("step-us-east-prod") ? "https://step-prod-bucket.s3.amazonaws.com/noImage.png" : x.certificate,
          letter: x.letter ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/WalkwaySafetyRenewal2.jpg-1680209373907.png` : null,
          name: x.name,
          company: isWmg ? "WMG" : x.companyName,
          certificateDate: moment(x.certificateExpiry).subtract(3,"year").format("MM/DD/YYYY")
        }
      })

      // extra vars

      variables.PassOrFail = constant
      variables.extraTests = extraTests
      variables.addendum = extraTests?.filter(x=>x)?.length ? true : false

      // tests vars

      variables.NumberOfPassingLocations = allTests.filter(x => x.passed)?.length || "0"
      variables.TotalNumberofFailingLocations = allTests.filter(x => !x.passed)?.length || "0",  
      variables.NumberOfFailingLocations = allTests.filter(x => !x.passed)?.length || "0",
      variables.NotTestedLocations = notTestedLocations
      variables.allLocationsArr = allLocationsArr?.flat().sort((a,b) => (Number(a.sortId) > Number(b.sortId)) ? 1 : ((Number(b.sortId) > Number(a.sortId)) ? -1 : 0))
      variables.allPass = allTests.every(test => test.passed)
      variables.allFail = allTests.every(test => !test.passed) 
      variables.TotalNumberOfTestLocations = testedLocations?.length
      variables.NumberOfAreasTested = testedAreas.length,  
      variables.NumberOfLocationsTested = testedLocations?.length,
      variables.NumberOfFailingTestAreas = failTestedAreas?.length
      variables.NumberOfPassingTestAreas = passTestedAreas?.length
      variables.AreaMainPhotos = generate == "yes" ? testedAreas.map(x => {
        return { 
          image: x.images[0],
          number: `A${x.results[0].areaCode}`,
          name: `${x.name}`,
          locations: x.locationsCoordinates.filter((x) => testedLocations?.some(l => x[Object.keys(x)]?.id == l?.id ))?.map(y => `L${Object.keys(y).map(z => z.slice(z.indexOf("location - ") + 11, z.length))}`)?.flat(1)?.sort().join(', '),
          //  
          testingCategory: x.testingCategory
        }
      })?.sort((a,b) => a-b) : [] 

      // user vars
      variables.userId = user?.user?._id
      let surface = user?.user?.surface ? JSON.parse(user?.user?.surface) : {
        serial: "N/A", 
        wetValue: "N/A",
        dryValue: "N/A" 
      }
      
      variables.ValidationSurfaceWetValue = surface?.wetValue
      variables.ValidationSurfaceDryValue = surface?.dryValue
      variables.ValidationSurfaceWet = tileResult.dcof == "wet" ? true : false
      variables.ValidationSurfaceDry = tileResult.dcof == "dry" ? true : false

      // audit vars
      variables.AuditDate = moment(selectedAudit?.firstTime).format("MM/DD/YYYY")
      finalReport.project = project
      variables.DateReportGenerated = moment().format("MM/DD/YYYY"),  
      variables.AuditPhotos = testedAreas.map(x => x.images)?.reduce((a, b) => a.concat(b), []).filter((item, index, array) => array.indexOf(item) === index);
      variables.AuditMistakes = [...allTests]?.filter(x => x.mistake)?.map(x => {
        let y = {}
        let mistake = x.mistake
        let test = x.results?.find(z => z.testNumber == "1")
        let date = test?.date
        let time = test?.time
        let originalArea = mistake?.correctArea
        let originalLocation = mistake?.correctLocation
        let wrongArea = mistake?.area
        let wrongLocation = mistake?.location
        y.content = `Note: On ${moment(date).format("MM/DD/YYYY")} at ${time}, the auditor mistakenly chose Area ${wrongArea} Location ${wrongLocation} instead Area ${originalArea} Location
        ${originalLocation}. This does not affect the validity of the DCOF measurements collected by the auditor at ${time} on
        ${moment(date).format("MM/DD/YYYY")}. In this report we refer to these DCOF measurements as A${originalArea} L${originalLocation} but in Appendix B, the Report
        Forms will read A${wrongArea} L${wrongLocation}`
        return y
      })
      variables.view = selectedAudit.defaultView


      // obj vars
      finalReport.tests = tests 
      finalReport.areas = testedAreas
      finalReport.resetedAreas = resetedAreas
      finalReport.locations = testedLocations
      finalReport.variables = variables
      finalReport.pdfFiles = pdfFiles 

      // non used vars
      variables.Certificate = "certificate pending",   
      variables.CertificatesAttached = "CertificatesAttached pending", 
      variables.AreaName = null,  
      variables.DateTestingDiagramWasCreated = "DateTestingDiagramWasCreated pending",   
      variables.TestingDiagramCreatorCompanyName = "TestingDiagramCreatorCompanyName pending",  
      variables.DateEditedTestingDiagram = "DateEditedTestingDiagram pending",  
      variables.TestingDiagramEditedBy = "TestingDiagramEditedBy pending",  
      variables.TestingDiagramEditedByCompanyName = "TestingDiagramEditedByCompanyName pending",  
      variables.TestingConditions, // location
      variables.TestingCategory, // location
      variables.FloorType = null,   // area
      variables.FloorTexture = null,   // area
      variables.FloorFinish = null,   // area
      variables.ConditionOfFloorFinish = null,   // area
      variables.SlopeOfFloorSurface = null,   // area
      variables.DateOnCertificate = "DateOnCertificate pending",   
      variables.FailingLocationNumber = null, // location  
      variables.PassingLocationNumber = null // location
      variables.TestingCategory = "pending for more area"
      variables.FlooringTypeNotes = null
      variables.TilePass = null



      if(pdfUrls?.length > 0 && generate == "yes") {

        if(fix) {
          generateReportDone({pdfUrls, pdfFiles, variables, finalReport, generate, reset, fix, structuredImages})
        } else {
          setBeforeGenerateModal({pdfUrls, pdfFiles, variables, finalReport, generate, reset})
        }
      } else {
        setWarningMessage(null)
        history.push({
        pathname: `/admin/reports/template/viewer`,
        state: { 
        type:"audits",
        defaultDCOF: defaultDCOF ? defaultDCOF : null,
        variables: variables,
        allVariables: templateVariables,
        finalReport: finalReport,
        generate: generate == "yes" ? true : false,
        fixAudit: fix,
        reset: reset ? true : false,
        resetTime: firstTimeOnFly ? true : false,
        structuredImages: structuredImages
        }
    })
    setFirstTimeOnFly(null)
    }
      
  
  }
    }

    const generateReportDone = async (vars) => {
      let myBucket = await fetchCredentials()

      if( myBucket) {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setBeforeGenerateModal(null)
      let {pdfUrls, pdfFiles, variables, finalReport, generate, reset, fix, structuredImages} = vars

// for(let i = 0; i < pdfUrls.length; i++) {
//   let pdffile = pdfUrls[i]
//   if(pdffile.pdf?.includes("step-us-east-prod")) {
//     console.log(pdffile.pdf, "pdffile")
//     pdffile.pdf = "https://step-prod-bucket.s3.amazonaws.com/noImage.pdf"
//     pdffile.oldpdf = pdffile.pdf
//   }
// }

let fileChecked = false;
let fileExists = false;

// Step 1: Replace "step-us-east-prod" with "step-prod-bucket" in all files
let hasStepUsEastProd = false; // Flag to check if at least one file contains "step-us-east-prod"
for (let i = 0; i < pdfUrls.length; i++) {
  if (pdfUrls[i].pdf?.includes("step-us-east-prod")) {
    hasStepUsEastProd = true; // Mark as true if at least one file contains the old URL
    pdfUrls[i].pdf = pdfUrls[i].pdf.replace("step-us-east-prod", "step-prod-bucket");
  }
}

// Step 2: Proceed with checking the files if at least one file had "step-us-east-prod"
if (hasStepUsEastProd) {
  for (let i = 0; i < pdfUrls.length; i++) {
    let pdffile = pdfUrls[i];

    console.log(pdffile.pdf, "pdffile"); 
 
    if (!fileChecked) {
      try {
        let response = await fetch(pdffile.pdf, { method: "HEAD" });
        fileExists = response.ok;
      } catch (error) {
        console.error("Error checking file:", error);
        fileExists = false;
      }
      fileChecked = true;
    }

    // If the file does not exist, replace with a fallback URL
    if (!fileExists) {
      pdffile.oldpdf = pdffile.pdf;
      pdffile.pdf = "https://step-prod-bucket.s3.amazonaws.com/noImage.pdf";
    }
  }
}

      if(pdfUrls.some(x => x.pdf.includes("step-us-east-prod"))) {
        history.push({  
          pathname: `/admin/reports/template/viewer`,
          state: { 
          type:"audits",
          defaultDCOF: defaultDCOF ? defaultDCOF : null,
          variables: variables, 
          allVariables: templateVariables,     
          finalReport: finalReport,  
          generate: generate == "yes" ? pdfUrls.map(x => x.pdf.includes("single") ? 1 : 3).reduce((partialSum, a) => partialSum + a, 0) : false,
          fixAudit: fix,
          reset: reset ? true : false,
          structuredImages: structuredImages
          }  
      })  
      } else {
      if(pdfUrls?.length > 0 && generate == "yes") { 
        pdfUrls.map((x,i, a) => {
          let pdf = x.pdf
          const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET,  
            Key: pdf.slice(pdf.indexOf("amazonaws.com/") + 14 , pdf.length)
          }           
          myBucket.getObject(params) 
          .on("complete", async(evt) => {
            console.log("evt", evt)
            if(evt?.data?.Body) {

              //defaultDCOFBefore.html = Buffer.from(evt.data.Body).toString('utf8')
              let pdfArr = evt.data.Body
              let blobPdf = new Blob([pdfArr], {type: 'application/pdf'})
              if(blobPdf) {
             pdfFiles.push({test: x, pdf: blobPdf})
              if(pdfFiles?.length == a?.length) {
              pdfUrls.map(x => x.pdf.includes("single") ? 1 : 3).reduce((partialSum, a) => partialSum + a, 0)
              history.push({  
                      pathname: `/admin/reports/template/viewer`,
                      state: { 
                      type:"audits",
                      defaultDCOF: defaultDCOF ? defaultDCOF : null,
                      variables: variables, 
                      allVariables: templateVariables,     
                      finalReport: finalReport,  
                      generate: generate == "yes" ? pdfUrls.map(x => x.pdf.includes("single") ? 1 : 3).reduce((partialSum, a) => partialSum + a, 0) : false,
                      fixAudit: fix,
                      structuredImages: structuredImages,
                      reset: reset ? true : false
                      } 
                  }) 
              }}
              
            }   
          }) 
          .send((err) => {
            if (err) console.log(err) 
        }) 
        })  
      }
      }
    } else {
      alert("Loading.")
      
    }
    }
    

    // load area

// const [allAreasToLoad, setAllAreasToLoad] = useState(null)
const [draftsAreasToLoad, setDraftsAreasToLoad] = useState(null)
const [selectedAreaToLoad, setSelectedAreaToLoad] = useState(null)
const loadAreaFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setLoadAreaModal(null)
  setAddAreaModal(null)
  setHaveData(false) 
  addArea({
    variables:{
      _id: selectedAreaToLoad.id,
      createdBy: user?.user?.name,  
      name: selectedAreaToLoad.name, 
      diagramPhoto: selectedAreaToLoad.photo,
      view: selectedAudit.defaultView,
      site: selectedAreaToLoad.site,
      floorType: selectedAreaToLoad.floorType,
      areaNumber: selectedAreaToLoad.areaNumber,
      testingCondition: selectedAreaToLoad.testingCondition,
      testingCategory: selectedAreaToLoad.testingCategory,
      floorTypeDescription: selectedAreaToLoad.floorTypeDescription,
      floorTexture: selectedAreaToLoad.floorTexture,
      floorTextureDescription: selectedAreaToLoad.TextureTypeDescription,
      floorFinish: selectedAreaToLoad.floorFinish,
      floorFinishDescription: selectedAreaToLoad.floorFinishDescription,
      conditionofFloorFinish: selectedAreaToLoad.conditionofFloorFinish,
      slopeofFloorsSurface: selectedAreaToLoad.slopeofFloorsSurface,
      cleaningChemicalsUsed: selectedAreaToLoad.cleaningChemicalsUsed,
      slsWaterConcentration: selectedAreaToLoad.slsWaterConcentration,
      dcof: selectedAreaToLoad.dcof,
      load: true,
      auditorsNotes: areaNotes || selectedAreaToLoad.auditorsNotes,
      images: selectedAreaToLoad.images?.map(x => {
        if(x.url) {
          return x.url
        } else { 
          return x
        }
      }),
      testingDiagram: selectedAreaToLoad.testingDiagram,
      diagramImage: selectedAreaToLoad.diagramImage 
    }
  }).then((x) => {
    setAreaId(null)
    setAreaNotes("")
    allAreasRefetchToLoad()
    allAreasRefetch()
    allLocationsRefetch()
    setSelectedAreaToLoad(null)
    setButtonDisabled(true)
    setAddAreaDropDown(true)
  })   
}


// comments modal

    const [commentsModal, setCommentsModal] = useState(false)

    const [comments, setComments] = useState([])
    const [areasComments, setAreasComments] = useState([])
    const addComment = (comment, type) => {    
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setCommentsModal(false)
        updateProject({
          variables:{
            _id: selectedAudit.id,
            comment: [comment],
            delete: type == "delete" ? true : false
          }
        }).then(x => {
          projectsRefetch()
          let newSelectedAudit = {...selectedAudit, comments:[...selectedAudit.comments, comment]}
          setProjectData(projectData.map(x => x.id == selectedAudit.id ? newSelectedAudit : x))
          setSelectedAudit(newSelectedAudit)
          dispatch(ACTION_LOADING_SPINNER_RESET())
        })
     }
    
    useEffect(() => {
    if(areas) {
      let areaComments = areas.map(x => { 
        return {
          name: `#Area - ${x.name}`,
          comment: x.auditorsNotes
        }
      }) 
      setAreasComments(areaComments)  
    }
    },[areas])

    // useEffect(() => {
    //   if(areas?.length && !printMode) {
    //     setAreas(areas.map((ar, i, a) => {
    //       ar.checked = false
    //       // ar.expanded = false                                   
    //       return ar
    //     }))
    //   }
    // },[printMode, areas])
    useEffect(() => {
        if(selectedAudit) {
          setTileResult(selectedAudit.tileResult)
          setComments(selectedAudit?.comments?.map(x => {
            return {
              name: "Audit",
              comment: x
            }
          })) 
        }
    },[selectedAudit])

    // tile modal

    const [tileModal, setTileModal] = useState(null)
    const [tileResult, setTileResult] = useState(null)
    const [surface, setSurface] = useState(null)

    const [whichRun, setWhichRun] = useState(null)
    const [botRun, setBotRun] = useState("")
    const [secondRun,setSecondRun] = useState("")
    const [messyAverage, setMessyAverage] = useState(true)
    const [activeTestCondition, setActiveTestCondition] = useState("wet")
    const [onlyTestCondition, setOnlyTestCondition] = useState("")

    const updateTile = (type, bot, second) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setTileModal(null)
      setSurface(null)
      if(type == "notExist") {
        const newTileResult = { tileResult:{
          exist: false
        }}

        updateViewTile({
          variables:{
            _id : selectedAudit.defaultView,
            ...newTileResult
          }
        }).then(x => {          
          setTileResult(newTileResult.tileResult)
          projectsRefetch()
        })
      } else if(type == "first") {
        const newTileResult = { tileResult:{
            dcof: activeTestCondition,
            first: bot.toString(),
            second: second.toString(),
            pass: messyAverage,
            exist: true
        }}    
        updateViewTile({
          variables:{ 
            _id : selectedAudit.defaultView,
            ...newTileResult
          }
        }).then(x => {
          setTileResult(newTileResult.tileResult)
          projectsRefetch()
        })
      }else if(type == "second") {
        const newTileResult = { tileResult:{ 
          dcof: tileResult.dcof,
          first: tileResult.first,
          second: tileResult.second,
          firstAfter: bot.toString(),
          secondAfter: second.toString(),
          pass: tileResult.pass,
          passAfter: messyAverage,
          exist: true
      }}       
        updateViewTile({
          variables:{ 
            _id : selectedAudit.defaultView,
            ...newTileResult
          }
        }).then(x => {
          setTileResult(newTileResult.tileResult)
          projectsRefetch()
          dispatch(ACTION_LOADING_SPINNER_RESET())
        })
      }
    }
 
    useEffect(() => {   
      if(areas && locations) {  
        if(areas.length && locations.length) {
        let someResults = areas.some(x => x.results) 
        let allResults = areas.every(x => x.results)
          let allResultsComplete = areas.every(x => {
            let uniqeResults = x?.results?.filter((v, i, a) => a.map(x => x['locationId']).indexOf(v['locationId']) === i) 
            return uniqeResults?.length == locations?.filter(y => y.area == x.id)?.length
          })
          
          setWhichRun(allResultsComplete ? "done" : allResults ? "all" : someResults ? "some" : "none")
          
        
        } else {
          setWhichRun("none")
        }
    }
    },[areas, locations])  
    
    useEffect(() => {
      if(tileResult) {
        if(openSurface) {
       
                if(!tileResult?.first) {

                  setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                    serial: "N/A",
                    wetValue: "N/A",
                    dryValue: "N/A"
                  })
                  setAddAreaDropDown(false)
                  setTileModal("first")
                 } else if (tileResult?.firstAfter) {

                  setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                    serial: "N/A", 
                    wetValue: "N/A",
                    dryValue: "N/A"
                  })
                  setAddAreaDropDown(false)
                  setTileModal("second")
                 } 
        }
      }
    },[tileResult])

    const runTestForLocation = (location) => {
      setOneLocation(location.id)
      dispatch(ACTION_SELECTED_AUDIT(selectedAudit.id))
      //dispatch(ACTION_SELECTED_TESTS([]))
      setRunTestModal(true)}


    let [wizard, setWizard] = useState(null)

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [firstTimeOnFly, setFirstTimeOnFly] = useState(null)

    useEffect(() => { 
      if(selectedTests && selectedAudit) {
        if(selectedTests[selectedAudit.defaultView]) {
          if(selectedTests[selectedAudit.defaultView].length) {
            if(selectedTests[selectedAudit.defaultView][0]?.results?.length){
            setFirstTimeOnFly(validateDate(selectedTests[selectedAudit.defaultView][0]?.results[0]?.date))
            }
          } 
          // else {
          //   setFirstTimeOnFly(null)
          // }
        } else {
          setFirstTimeOnFly(null)
        }
      }
    },[selectedTests, selectedAudit])

    useEffect(() => {
      if(location) {  
          if(location?.state?.clientId) {
            dispatch(ACTION_LOADING_SPINNER_ACTIVE())
            let exactClient = auditClientsOptions.find(x => x._id == location.state.clientId)
            let newClient = {}
            newClient.label = exactClient?.companyName
            newClient.value = exactClient?._id
            setAuditClient(newClient)
            if(location?.state?.siteId) {
              setTempSite(location.state.siteId)
            }
          } else {
            if(location?.state?.siteId) {
              let exactSite = auditSitesOptions.find(x=> x._id == location.state.siteId)
              let newSite = {}
              newSite.label = exactSite?.name
              newSite.value = exactSite?._id
              setAuditSite(newSite)
            }
          }
          location?.state?.siteId && setAddAuditModal("Audit")        
      } 
      return () => window.history.replaceState({}, document.title)
    },[location])

    useEffect(() => {
      if(areas) {
        setPrintedAreas(areas.filter(x => x.checked))
      }
    },[areas])

    useEffect(() => {
      if(printMode && selectRef) {
          selectRef?.current.scrollIntoView({behavior: "smooth"})
        
      }
    },[printMode, selectRef])
    useEffect(() => {
      if(location?.state && projectData) {   
        if(location.state.fixAudit) {
          dispatch(ACTION_LOADING_SPINNER_ACTIVE())
          let fixedAudit = fixAuditFunc(location.state.html)
          let structuredImages = location.state.structuredImages
          replaceBrokenImages(fixedAudit, structuredImages, uploadImage, uploadPDF)
          
        } 
          if(location?.state?.backAudit) { 
            setAddLocationLoad(true)
            subScreen(projectData?.find(x => x.id == location.state.backAudit ), true, false, true, location?.state?.backAudit, location?.state?.isView)
          }      
      } 
      return () => window.history.replaceState({}, document.title)
    },[location, projectData])

 
    if(loadingForBroken) {
      return <Spinner isOpen={loadingForBroken} />
    }
    return (
        <div   
        style = {{
            paddingBottom:"1rem",
            justifyContent : "flex-start",
            flexDirection: "column",
          }} className="adminContent projectsAdmins">


{auditFixer && <div 
  className="modal task-modal nModaln" 
  style={{
    width: "90%",
    height: "90%",
    zIndex: 99999999,
    padding: 0,
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0, 0, 0, 0.7)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"
  }}
>
  <div 
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "white",
      zIndex: 99999998,
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    }}
  >
<ExtractImages 
correctAudit={correctAudit}
audit={selectedAudit}
setAuditFixer={setAuditFixer}
auditFixer={auditFixer}
subScreen={subScreen}
/>
</div>
</div>}

<Spinner isOpen={ 
  allViewsLoading ||
  projectsLoading || 
  addLocationLoad ||
  allAreasLoading ||
  auditHistoryLoading ||
  allAreasLoadingToLoad ||
  allLocationsLoading
  
  } />

            <ImagesLaterModal
            open={imagesLaterModal}
            setOpen={setImagesLaterModal}
            updateAreas={updateAreas}
            />
            
            <BeforeGenerateModal
              open={beforeGenerateModal}
              setOpen={setBeforeGenerateModal}
              generate={generateReportDone}
              auditClientsOptions={auditClientsOptions}
              user={user}
              clientsRefetch={clientsRefetch}
              auditAssignOptions={auditAssignOptions}
              usersRefetch={usersRefetch}
            />
            <WrongValidateModal  
            open={validateError}
            setOpen={setValidateError}
            resetActiveTest={resetActiveTest}
            onContinue={() => null}
            />

            <WrongDataModal  
              open={wrongDataModal}
              setOpen={setWrongDataModal} 
              dataOnFly={dataOnFly}
              currentTest={currentTest}
              setCurrentTest={setCurrentTest}
              setPullingDataActive={setPullingDataActive}
              resetActiveTest={resetActiveTest}
              fetchPdf={fetchPdf}
              areas={areas} 
              selectedAudit={selectedAudit}
            />

          <SuggestModal
          auditDate={firstTimeOnFly == "reset" ? null : firstTimeOnFly ? firstTimeOnFly : selectedAudit?.firstTime}
        open={suggestModal}
        setOpen={setSuggestModal}
        confirmNewAuditViewWithLocation={resetAuditBeforeSave}        
        rejectFile={rejectFile}
        resetActiveTest={resetActiveTest}
        areaLocation={areaLocation}
        setAreaLocation={setAreaLocation}
      />


      <AddViewConfirmModal
        open={addViewConfirmModal}
        setOpen={setAddViewConfirmModal}
        addViewFunc={addViewFunc}
        withTest={withTest}
        setWithTest={setWithTest}
        rejectFile={rejectFile}
        resetActiveTest={resetActiveTest}
      />
      

      <AuditHistoryModal
        selectedAudit={selectedAudit}
        open={auditHistoryModal}
        setOpen={setAuditHistoryModal}
        auditHistory={views}
        allViewsLoading={allViewsLoading}
        deleteViewLoading={deleteViewLoading}
        loadViewFunc={loadViewFunc}
        deleteViewFunc={deleteViewFunc}
        allViewsRefetch={allViewsRefetch}
        
      />
                      {returnToHomeScreen()}

      {wizard && (
        <div onClick={() => setWizard(false)} style={{ height:"100vh", width:"100vw", position:"fixed", display:"flex", top:0, bottom:0, right:0, leftL:0, justifyContent:"center", alignItems:"center", zIndex:999999999, background:"#ffffffa8"}}>
        <div onClick={(e) => {
          e.stopPropagation()
        }} style={{height:"90%", width:"70%", borderRadius:"8px", fontSize:"4vmin",padding:"1.5rem 3rem", background:"#5C92BF", overflowY:"auto", display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column", color:"white"}}>
          <div style={{width:"80%", height:"90%", display:"flex", overflowX:"auto", justifyContent:"center", alignItems:"center"}}>{wizard?.info}</div>
          {wizard.button && (
          <div style={{display:"flex", justifyContent:"space-between"}}> 
        <div
          className="auth-button addUser secondaryBlue"
          onClick={() => {
            wizard.click()
            setWizard(null)
          }}
          >
            {wizard.button}
            </div>
            {wizard.extraButton && (
              <div
              className="auth-button addUser secondaryBlue"
                onClick={() => {
                  wizard.extraClick()
                  setWizard(null)
                }}
                >
                {wizard.extraButton}
                </div>
                )}
            </div>
          )}
          </div>
          <div style={{height:"50%"}} onClick={() => setWizard(false)} />
        </div>
      )}

              <LoadAreaModal 
              setAddAreaDropDown={setAddAreaDropDown}
                areas={areas}
                editArea={editArea}
                selectedAudit={selectedAudit}
                loadAreaModal={loadAreaModal}
                setLoadAreaModal={setLoadAreaModal}
                allAreasToLoad={allAreasToLoad}
                draftsAreasToLoad={draftsAreasToLoad} 
                selectedAreaToLoad={selectedAreaToLoad}
                setSelectedAreaToLoad={setSelectedAreaToLoad}
                loadAreaFunc={loadAreaFunc}
            />

            <PrintModal 
            setAddAreaDropDown={setAddAreaDropDown}
            selectedAudit={selectedAudit}
            printModal={printModal}
            setPrintModal={setPrintModal}
            allAreasToPrint={printedAreas}
            printFunc={printFunc}
            />
            <ConditionModal 
              open={conditionModal} 
              setOpen={setConditionModal}
              selectCondition={selectCondition}
            />

            <CommentsModal 
              setAddAreaDropDown={setAddAreaDropDown}

              open={commentsModal}
              setOpen={setCommentsModal}
              comments={comments}
              areasComments={areasComments}
              addComment={addComment}
            />

            <TileModal 
              setAddAreaDropDown={setAddAreaDropDown}
              open={tileModal}
              setOpen={setTileModal}
              tileResult={tileResult}
              updateTile={updateTile}
              audit={selectedAudit}
              whichRun={whichRun}
              surface={surface}
              setSurface={setSurface}
              botRun={botRun}
              setBotRun={setBotRun}
              secondRun={secondRun}
              setSecondRun={setSecondRun}
              messyAverage={messyAverage}
              setMessyAverage={setMessyAverage}
              activeTestCondition={activeTestCondition}
              setActiveTestCondition={setActiveTestCondition}
              onlyTestCondition={onlyTestCondition}
              setOnlyTestCondition={setOnlyTestCondition}


            />
            
        {editAuditModal && (
          <AddAuditModal 
          addAuditModal={editAuditModal}
          setAddAuditModal={setEditAuditModal}
          auditClientsOptions={auditClientsOptions}
          auditClient={auditClient}
          setAuditClient={setAuditClient}
          auditSitesOptions={auditSitesOptions}
          auditSite={auditSite}
          setAuditSite={setAuditSite}
          auditAssignOptions={auditAssignOptions}
          auditAssignTo={auditAssignTo}
          setAuditAssignTo={setAuditAssignTo}
          addAuditFunc={addAuditFunc}
          updateAuditFunc={updateAuditFunc}

      />  
        )}

          {!selectedAudit ? projectData ? projectData.length > 0 ? (
          <>
          <AuditHeader 
            // add Audit model
            addAuditModal={addAuditModal}
            setAddAuditModal={setAddAuditModal}
            auditClientsOptions={auditClientsOptions}
            auditClient={auditClient}
            setAuditClient={setAuditClient}
            auditSitesOptions={auditSitesOptions}
            auditSite={auditSite}
            setAuditSite={setAuditSite}
            auditAssignOptions={auditAssignOptions}
            auditAssignTo={auditAssignTo}
            setAuditAssignTo={setAuditAssignTo}
            addAuditFunc={addAuditFunc}
            updateAuditFunc={updateAuditFunc}
            allAdminsfilter={allAdminsfilter}
            setAllAdmins={setAllAdmins}
            allCompaniesFilter={allCompaniesFilter}
            setAllCompanies={setAllCompanies}
            allSitesFilter={allSitesFilter}
            setAllSites={setAllSites}
            setCompanyFilter={setCompanyFilter}
            companyFilter={companyFilter}
            setSiteFilter={setSiteFilter} 
            siteFilter={siteFilter}
            setCreatedByFilter={setCreatedByFilter}
            createdByFilter ={createdByFilter}
          />
          <div style={{justifyContent:"flex-end", width:"100%", marginBottom:"1rem"}} className="userActions">
          {projectData && projectData.length > 0 && ( <div className="rightActions">
          <div className={`tableHeaderAdd viewContainer ${activeView == "grid" && "viewActiveContainer"}`}>
          <FontAwesomeIcon onClick={() => setActiveView("grid")} style={{fontSize: activeView == "grid" ? "1.7rem" : "1.3rem", cursor:"pointer"}} icon={faThLarge} />
          </div>
          <div className={`tableHeaderAdd viewContainer ${activeView == "list" && "viewActiveContainer"}`}>
          <FontAwesomeIcon onClick={() => setActiveView("list")} style={{fontSize: activeView == "list" ? "1.7rem" : "1.3rem", cursor:"pointer"}}  icon={faTh} />
          </div>
          </div> 
          )}

            </div>

{<><div className="reportsContainer" style={{width:"100%", height:"100%", overflowY:"auto", paddingBottom:"50px"}}>
{activeView == "list" ? (
              <AuditsTable  
                accountData={projectData.sort((a,b) => b.status - a.status)} 
                accountColumns={accountColumns} 
                subScreen={subScreen}
                accountTabs={null} 
                conditionalRowStyles={conditionalRowStyles}
                accountType="Audit"
              />
            ) : (
              <div className="auditsCardContainer">
                
                {projectData &&  projectData.sort((a,b) => b.status - a.status).map((audit, i) => (
                  <div key={i} className="auditCardContainer">
                  <AuditCard 
                  auditFixer={auditFixer} 
                  setAuditFixer={setAuditFixer} 
                  user={user} editAudit={editAudit} updateProjectStatusFunc={updateProjectStatusFunc} audit={audit} subScreen={subScreen}/>
                  </div>
                ))}
              </div>
            )}
            </div>
            {projectData?.length ? projectData?.find(x=> x.count)?.count ? <div className="boxShadowClassName" style={{position:"absolute", bottom:20, right:20, left:"auto", background:"white", display:"flex", borderRadius:"50px", justifyContent:"center", alignItems:"center", padding:"10px",}}>
                
            <Pagination
            className="pagination-bar" 
            currentPage={currentPageAudits}
            totalCount={projectData?.length ? projectData?.find(x=> x.count)?.count || 0 : 0}
            pageSize={20}
            onPageChange={page => setCurrentPageAudits(page)}
            />
              
            </div> : null: <RenderSitesLoader small="s" />} </>}    

          
          </>
          ) : (
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <AddAuditModal 
                addAuditModal={addAuditModal}
                setAddAuditModal={setAddAuditModal}
                auditClientsOptions={auditClientsOptions}
                auditClient={auditClient}
                setAuditClient={setAuditClient}
                auditSitesOptions={auditSitesOptions}
                auditSite={auditSite}
                setAuditSite={setAuditSite}
                auditAssignOptions={auditAssignOptions}
                auditAssignTo={auditAssignTo}
                setAuditAssignTo={setAuditAssignTo}
                addAuditFunc={addAuditFunc}
                updateAuditFunc={updateAuditFunc}
          />
            <div className="tableHeaderAdd">
          <div 
          style={{
            color: "rgb(255, 255, 255)",
            pointerEvents:  "auto",
            background: "#5C92BF",
            
            marginRight: "1rem",
            fontSize:"14",
            fontWeight:"bold" 
        }} 
        className="auth-button addUser floatButton"
        onClick={() => setAddAuditModal("Audit")}
        >
              Add New Audit <img style={{ marginLeft:".5rem"}}  src={add} />
          </div> 
          </div>  
          </div>
          ) : ( <DataSpinner small="l" />) : (
            <div className="adminDetailsContainer" style={{padding:"0 5px"}}>

            <AccountHeader printType={"Area"} printModal={printModal} setPrintModal={setPrintModal} printMode={printMode} setPrintMode={setPrintMode} printedAreas={printedAreas} setPrintedAreas={setPrintedAreas} openHistoryModal={openHistoryModal} addNewView={addNewView} viewsLength={views?.length} deleteType={"Audit"} user={user} editUser={editAudit} updateUser={updateProjectStatusFunc} selectedAccount={selectedAudit} type="audit" />
            
            { areas ? areas.length > 0 ? <div className="userBottom">
            
            {extraDataModal && <FileUploaderModal
            resetActiveTest={resetActiveTest}
            singleRuns={singleRuns}
            setSingleRuns={setSingleRuns}
              matchedData={matchedData}
              setMatchedData={setMatchedData}
              pdfImages={pdfImages}
              setPdfImages={setPdfImages}
              newPdfImages={newPdfImages}
              setNewPdfImages={setNewPdfImages}
              exactData={exactData}
              setExactData={setExactData}
              addNewTest={addNewTest}
              setAddNewTest={setAddNewTest}
              addExtraData={addExtraData}
              extraDataArr={selectedAudit.extra}
              areas={areas}
              mode="extra"
              active={extraDataModal}
              setActive={setExtraDataModal}
              oneLocation={oneLocation}
              // fullCategory={fullCategory}
              // setFullCategory={setFullCategory}
              firstTimeOnFly={firstTimeOnFly}
              selectedAudit={selectedAudit}
              cleanCondition={cleanCondition}
              setCleanCondition={setCleanCondition}
              // contextArea={contextArea}
              contextCategory={contextCategory}
              // contextLocation={contextLocation}
              isDisconnected={isDisconnected}
              connectToDeviceAndSubscribeToUpdates={connectToDeviceAndSubscribeToUpdates}
              getPdfFromBot={getPdfFromBot}
              pullingDataActive={pullingDataActive}
              setMyState={setMyState}
              setFinalData={setFinalData}
              setCurrentTest={setMatchedTest}
              setPullingDataActive={setPullingDataActive}
              pdfFile={pdfFile}
              setPdfFile={setPdfFile}
              matchedTest={matchedTest}
              convertuploadedPdf={convertuploadedPdf}
              file={file}
              fileError={fileError}
              setFile={setFile}
              setFileError={setFileError}
              pdfFileNotFound={pdfFileNotFound}
              setPdfFileNotFound={setPdfFileNotFound}
            />}
            <RunTestModal
            warningMessage={warningMessage}
            setWarningMessage={setWarningMessage}
              singleRuns={singleRuns}
              setSingleRuns={setSingleRuns}
              matchedData={matchedData}
              setMatchedData={setMatchedData}
              pdfImages={pdfImages}
              setPdfImages={setPdfImages}
              newPdfImages={newPdfImages}
              setNewPdfImages={setNewPdfImages}
              exactData={exactData}
              setExactData={setExactData}
              addNewTest={addNewTest}
              setAddNewTest={setAddNewTest}
              mode={mode}
              setMode={setMode}
              addExtraData={addExtraData}
              warningModal={warningModal}
              setWarningModal={setWarningModal}
              areasToUpdate={areasToUpdate}
              setAreasToUpdate={setAreasToUpdate}
              haveData={haveData}
              setHaveData={setHaveData}
              firstTimeOnFly={firstTimeOnFly}
              setFirstTimeOnFly={setFirstTimeOnFly}
              setAddAreaDropDown={setAddAreaDropDown}
              pullButtonActive={pullButtonActive}
              setPullButtonActive={setPullButtonActive}
              startTestModal={startTestModal}
              setStartTestModal={setStartTestModal}
              resetActiveTest={resetActiveTest}
              pdfDone={pdfDone}
              setPdfDone={setPdfDone}
              contextCategory={contextCategory}
              setContextCategory={setContextCategory}
              temporaryTest={temporaryTest}
              setTemporaryTest={setTemporaryTest}
              uploaderModal={uploaderModal}
              setUploaderModal={setUploaderModal}
              selectedAudit={selectedAudit}
              cleanCondition={cleanCondition}
              setCleanCondition={setCleanCondition}
              oneLocation={oneLocation}
              setOneLocation={setOneLocation}
              active={runTestModal}
              setActive={setRunTestModal}
              areas={areas}
              setAreas={setAreas}
              locations={locations}
              selectedTests={selectedTests}
              testType={testType}
              setTestType={setTestType}
              currentTest={currentTest}
              setCurrentTest={setCurrentTest}
              supportsBluetooth={supportsBluetooth}
              isDisconnected={isDisconnected}
              setIsDisconnected={setIsDisconnected}
              connectToDeviceAndSubscribeToUpdates={connectToDeviceAndSubscribeToUpdates}
              device={device}
              setDevice={setDevice}
              pullData={pullData}
              pullingDataActive={pullingDataActive}
              setPullingDataActive={setPullingDataActive}
              generateReport={generateReport}
              char={char}
              charNotification={charNotification}
              charLoading={charLoading}
              setCharLoading={setCharLoading}
              getPdfFromBot={getPdfFromBot}
              setMyState={setMyState}
              setMatchedTest={setMatchedTest}
              setFinalData={setFinalData}
              pdfFile={pdfFile}
              setPdfFile={setPdfFile}
              matchedTest={matchedTest}
              convertuploadedPdf={convertuploadedPdf}
              file={file}
              fileError={fileError}
              setFile={setFile}
              setFileError={setFileError}
              pdfFileNotFound={pdfFileNotFound}
              setPdfFileNotFound={setPdfFileNotFound}
              conditionModal={conditionModal}
              setConditionModal={setConditionModal}
            />
            <AddAreaModal
                areaId={areaId}
                addLocationToDeleted={addLocationToDeleted}
                imagesLater={imagesLater}
                setImagesLater={setImagesLater}
                haveData={haveData}
                setHaveData={setHaveData}
                setAddAreaDropDown={setAddAreaDropDown}
                history={historyArr}
                setHistory={setHistoryArr}
                photo={photo}
                setPhoto={setPhoto}
                areaNumber={areaNumber}
                setAreaNumber={setAreaNumber}
                uploadFile={uploadFile}
                loadAreaFunc={loadAreaFunc}
                areaNameError={areaNameError}
                setAreaNameError={setAreaNameError}
                areas={areas}
                updateAreaImages={updateAreaImages}
                draftAreaFunc={draftAreaFunc}
                images={images}
                setImages={setImages}
                setNewDiagramImage={setNewDiagramImage}
                areaLines={areaLines}
                setAreaLines={setAreaLines}
                areaDiagram={areaDiagram}
                setTestingCondition={setTestingCondition}
                testingCondition={testingCondition}
                setTestingCategory={setTestingCategory}
                testingCategory={testingCategory}
                addAreaModal={addAreaModal}
                setLoadAreaModal={setLoadAreaModal}
                setAddAreaModal={setAddAreaModal}
                areaName={areaName}
                setAreaName={setAreaName}
                setFloorType={setFloorType}
                floorType={floorType}
                setFloorTypeDescription={setFloorTypeDescription}
                floorTypeDescription={floorTypeDescription}
                setTextureType={setTextureType}
                TextureType={TextureType}
                setTextureTypeDescription={setTextureTypeDescription}
                TextureTypeDescription={TextureTypeDescription}
                setFloorFinish={setFloorFinish}
                floorFinish={floorFinish}
                setFinishCondition={setFinishCondition}
                finishCondition={finishCondition}
                setFloorFinishDescription={setFloorFinishDescription}
                floorFinishDescription={floorFinishDescription}
                setslopeSurface={setslopeSurface}
                slopeSurface={slopeSurface}
                setcleaningChemicals={setcleaningChemicals}
                cleaningChemicals={cleaningChemicals}
                setSlsWaterConcentration={setSlsWaterConcentration}
                slsWaterConcentration={slsWaterConcentration}
                setAverageDcof={setAverageDcof}
                averageDcof={averageDcof}
                setAreaNotes={setAreaNotes}
                areaNotes={areaNotes}
                areaModalPage={areaModalPage}
                setAreaModalPage={setAreaModalPage}
                lines={lines}
                setLines={setLines}
                // area images
                takeAPhotoSelected={takeAPhotoSelected}
                handleImageUploaded={handleImageUploaded}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                // area add function
                addAreaFunc={addAreaFunc}
                // testing diagram
                testingDiagram={testingDiagram}
                setTestingDiagram={setTestingDiagram}
                setDiagramImage={setDiagramImage}
                diagramImage={diagramImage}
                updateAreaFunc={updateAreaFunc} 
                selectedArea={selectedArea}
                setSelectedArea={setSelectedArea}
            />
            <div className="auditAreasContainer" style={{paddingBottom:"5rem", }}>

            {!printMode ? <div style={{ marginBottom:"1rem"}} className="userActions">

              <div>


              <div  
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          border: "1px solid #5C92BF",
                          color:"#5C92BF",
                          background:"white",
                          
                          marginBottom:"10px",
                          padding: "0 25px",
                      }}
                      className="auth-button addUser hoverTransparent"
                      onClick={() => goToReports(selectedAudit?.defaultView, selectedAudit.auditStatus)}
                      >
                           Audit Reports
                        </div>
                        
              <ButtonDropdown    
                        isOpen={auditViewDropDown}
                        toggle={() => setAuditViewDropDown(!auditViewDropDown)}
                        >
                            <DropdownToggle
                              style={{fontWeight:"600", borderRadius:"5px"}}
                              className="auth-button addUser userActionsButtons"
                              > 
                                Audit Views
                            </DropdownToggle>
                            <DropdownMenu className="minDropdown">
                              <DropdownItem><div className="tableHeaderAdd">
                        <div  
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          border: "1px solid #5C92BF",
                          color:"#5C92BF",
                          background:"white",
                          
                          margin:"0 5px",
                          padding: "0 25px",
                      }}
                      className="auth-button addUser hoverTransparent"
                      onClick={() => openHistoryModal()}
                      >
                           Audit History
                        </div>
                        </div></DropdownItem>
                              <DropdownItem><div id={`UncontrolledTooltipExample_Status_AddNewView`}  className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          margin:"0 5px",
                          padding: "0 25px",
                      }} 
                      className="auth-button addUser" 
                      onClick={() => addNewView()}
                      >
                        Duplicate audit   
 <UncontrolledTooltip 
          target={`UncontrolledTooltipExample_Status_AddNewView`}
        >
          By clicking this option you will create a copy of this audit including areas and locations
        </UncontrolledTooltip>
                        </div>
                        </div></DropdownItem>
                        </DropdownMenu> 
                        </ButtonDropdown>
                        <div style={{display:"flex",alignItems:"center", marginTop:"10px", display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                        {true ? firstTimeOnFly ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span>  {moment(validateDate(firstTimeOnFly)).format("MM/DD/YYYY")} </div> : selectedAudit.firstTime ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span>  {moment(validateDate(selectedAudit.firstTime)).format("MM/DD/YYYY")} </div> : null : null}
                        {/* {selectedAudit.viewCreatedOn && <div className='squareContent' style={{fontSize:"1vmax", margin:"10px 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>View Created on </span> {moment.unix(selectedAudit.viewCreatedOn/1000).format("MM/DD/YYYY")}  </div>} */}
                        </div>
                        </div> 
              {
                // <div className="leftActions" style={{display:"flex"}}>
              <>
              <ButtonDropdown 
                        isOpen={addAreaDropDown}
                        toggle={() => setAddAreaDropDown(!addAreaDropDown)}
                        onClick={() => {
                          if(addAreaDropDown && buttonDisabled) {
                            setButtonDisabled(false)
                            setAddAreaDropDown(false)
                          }
                        }}
                        disabled={buttonDisabled}
                        >
                            <DropdownToggle
                              style={{fontWeight:"600", border:"2px solid rgb(61, 127, 194)", borderRadius:"5px", background:"transparent"}}> 
                                <FontAwesomeIcon
                                    icon={faBars}
                                    style={{color:"rgb(61, 127, 194)"}}
                                  />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem toggle={false}> 
                              <div className="tableHeaderAdd">
                        <div  
                        style={{
                          color: "rgb(255, 255, 255)", 
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          margin:"0 5px",
                          padding: "0 25px",
                          fontSize:"14",
                          fontWeight:"bold"
                      }} 
                      className={`auth-button addUser userActionsButtons ${!allAreasToLoad?.length && "disable-button"}`} onClick={() => {
              setAddAreaDropDown(false)
                        setLoadAreaModal("Area")}}>Load Area</div></div>
                              </DropdownItem>
                              <DropdownItem toggle={false}>
                               <div className="tableHeaderAdd">
                        <div 
                        style={{ 
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          margin:"0 5px",
                          padding: "0 25px",
                          fontSize:"14",
                          fontWeight:"bold"
                      }} 
                      className="auth-button addUser userActionsButtons" onClick={() => {
                        setAddAreaDropDown(false)
                        setPhoto(null)
                        setHaveData(false) 
                        setAddAreaModal("Area")
                        }}>Create New Area</div></div>
                              </DropdownItem>
                              { tileResult ? tileResult.exist && !tileResult.firstAfter ? <DropdownItem toggle={false}><div className="tableHeaderAdd">
              <div  
                style={{
                  color: "rgb(255, 255, 255)",
                  pointerEvents:  "auto",
                  background: "#5C92BF", 
                  
                  margin:"0 5px",
                          padding: "0 25px",
                  fontSize:"14", 
                  fontWeight:"bold" ,
                  cursor:"pointer"
                }} 
                className={`auth-button addUser userActionsButtons ${selectedAudit?.assignee?.some(x => x?._id == user?.user?._id) && areas?.length > 0  ? "activeButtonToAction" : "disable-button" }`}
              onClick={() => {
                if(selectedAudit?.assignee?.some(x => x?._id == user?.user?._id) && areas?.length > 0 ) {
                if(tileResult?.exist) {
                if(whichRun == "none" && !tileResult?.first) {
                  setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                    serial: "N/A",
                    wetValue: "N/A",
                    dryValue: "N/A"
                  })
                  setAddAreaDropDown(false)
                  setTileModal("first")
                 } else if (whichRun == "done" && !tileResult?.firstAfter) {
                  setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                    serial: "N/A", 
                    wetValue: "N/A",
                    dryValue: "N/A"
                  })
                  setAddAreaDropDown(false)
                  setTileModal("second")
                 } else {
                  if(!tileResult?.first) {
                    setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                      serial: "N/A",
                      wetValue: "N/A",
                      dryValue: "N/A"
                    })
                    setAddAreaDropDown(false)
                    setTileModal("first")
                  } else {
                  setWizard({
                    info: "You're Validated!. It's Time To Run A Test",
                    button: "Run Test",
                    click: () => {
                       dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView] || [], ...areas?.map(x => x?.results?.map(y => y).filter((x, a) => !selectedTests[selectedAudit?.defaultView]?.some( y => y.locationId == x.locationId && y.areaCondition == x.areaCondition)))?.flat(1)?.filter(y => y)]}))
                      dispatch(ACTION_SELECTED_AUDIT(selectedAudit.id))
                      //connectToDeviceAndSubscribeToUpdates()
                      setAddAreaDropDown(false)
                      setRunTestModal(true) 
                    }
                  }) 
                } 
                 }} else {
                   dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView] || [], ...areas?.map(x => x?.results?.map(y => y).filter((x, a) => !selectedTests[selectedAudit?.defaultView]?.some( y => y.locationId == x.locationId && y.areaCondition == x.areaCondition)))?.flat(1)?.filter(y => y)]}))
                  dispatch(ACTION_SELECTED_AUDIT(selectedAudit.id))
                  //connectToDeviceAndSubscribeToUpdates()
                  setAddAreaDropDown(false)
                  setRunTestModal(true)  
                 } 
              } else {
                
              }
            } 
            
            }
            > 
                  Testfoot Validation
              </div>
    </div></DropdownItem>: null : null}
    <DropdownItem toggle={false}>
    <div className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          margin:"0 5px",
                          padding: "0 25px",
                                                    fontSize:"14",
                          fontWeight:"bold"
                      }} 
                      className="auth-button addUser userActionsButtons"
                      onClick={() => {
                        setAddAreaDropDown(false)
                        setCommentsModal(true)}
                      }
                      >
                            Comments
                        </div>
              </div>
    </DropdownItem>
    <DropdownItem toggle={false}>
    <div className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#6DB240",
                          
                          margin:"0 5px",
                          padding: "0 25px",
                          fontSize:"14",
                          fontWeight:"bold", 
                          cursor:"pointer"
                      }} 
                className={`auth-button addUser hoverGreen userActionsButtons ${selectedAudit?.assignee?.some(x => x?._id == user?.user?._id) && areas?.length > 0 && locations?.length > 0  ? "activeButtonToAction" : "disable-button" }`}
                  onClick={() => {
                    if(selectedAudit?.assignee?.some(x => x?._id == user?.user?._id) && areas?.length > 0 && locations?.length > 0) {
                      if(tileResult?.exist) {
                        if(whichRun == "none" && !tileResult?.first) {
                          // setWizard({
                          //   info: "Oops... You Didn't Perform the Pre-test Testfoot Validation Procedure!",
                          //   button: "Validate Now",
                          //   click: () => {
                              setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                                serial: "N/A",
                                wetValue: "N/A",
                                dryValue: "N/A"
                              }) 
                              setAddAreaDropDown(false)
                              setTileModal("first") 
                            // }
                          // })
                            // setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                            //   serial: "N/A",
                            //   wetValue: "N/A",
                            //   dryValue: "N/A"
                            // })
                            // setTileModal("first")
                           } else if (whichRun == "done" && !tileResult?.firstAfter) {
                            setWizard({
                              info: "It seems that all the loations in this audit have been tested, we suggest that you complete the validation procedures unless you want to make some changes to the results of the locations you can click continue",
                              button: "Post Validation procedures",
                              extraButton: "Continue",
                              click: () => {
                                setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                                  serial: "N/A",
                                  wetValue: "N/A",
                                  dryValue: "N/A"
                                })
                                setAddAreaDropDown(false)
                                setTileModal("second")
                              },
                              extraClick: () => {
                                 dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView] || [], ...areas?.map(x => x?.results?.map(y => y).filter((x, a) => !selectedTests[selectedAudit?.defaultView]?.some( y => y.locationId == x.locationId && y.areaCondition == x.areaCondition)))?.flat(1)?.filter(y => y)]}))
                                dispatch(ACTION_SELECTED_AUDIT(selectedAudit.id))
                                //connectToDeviceAndSubscribeToUpdates()
                                setAddAreaDropDown(false)
                                setRunTestModal(true)  
                              }
                            })
 
                            // setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                            //   serial: "N/A",
                            //   wetValue: "N/A",
                            //   dryValue: "N/A"
                            // }) 
                            // setTileModal("second")
                           } else {
                            if(!tileResult?.first) {
                              // setWizard({
                              //   info: "Oops... You Didn't Perform the Pre-test Testfoot Validation Procedure!",
                              //   button: "Validate Now",
                              //   click: () => {
                                  setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                                    serial: "N/A",
                                    wetValue: "N/A",
                                    dryValue: "N/A"
                                  })
                                  setAddAreaDropDown(false)
                                  setTileModal("first") 
                              //   }
                              // })
                            } else {
                           dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView] || [], ...areas?.map(x => x?.results?.map(y => y).filter((x, a) => !selectedTests[selectedAudit?.defaultView]?.some( y => y.locationId == x.locationId && y.areaCondition == x.areaCondition)))?.flat(1)?.filter(y => y)]}))
                            dispatch(ACTION_SELECTED_AUDIT(selectedAudit.id))
                            //connectToDeviceAndSubscribeToUpdates()
                            setAddAreaDropDown(false)
                            setAreasToUpdate([])
                            setAreas(areas.map(x => {
                            x.areaNumber = x.oldAreaNumber || x.areaNumber
                            x.testingCategory = x.oldTestingCategory || x.testingCategory
                            x.category = x.oldCategory || x.category
                            x.oldAreaNumber = null
                            x.oldTestingCategory = null
                            x.oldCategory = null
                            return x
                            }))
                            setRunTestModal(true)  
                            } 
                           }} else {
                           dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView] || [], ...areas?.map(x => x?.results?.map(y => y).filter((x, a) => !selectedTests[selectedAudit?.defaultView]?.some( y => y.locationId == x.locationId && y.areaCondition == x.areaCondition)))?.flat(1)?.filter(y => y)]}))
                            dispatch(ACTION_SELECTED_AUDIT(selectedAudit.id))
                            //connectToDeviceAndSubscribeToUpdates() 
                            setAddAreaDropDown(false)
                            setAreasToUpdate([])
                            setAreas(areas.map(x => {
                            x.areaNumber = x.oldAreaNumber || x.areaNumber
                            x.testingCategory = x.oldTestingCategory || x.testingCategory
                            x.category = x.oldCategory || x.category
                            x.oldAreaNumber = null
                            x.oldTestingCategory = null
                            x.oldCategory = null
                            return x
                          }))
                            setRunTestModal(true)  
                           }
                      } else { 
                        if(locations.length == 0) {
                          setWizard({
                          info: "It seems that no area contains any test location, we suggest that at least one place be added in the test area",
                        })
                        }
                      }
                    
                    }}
                      >  
                            Run test 
                        </div> 
              </div>
    </DropdownItem>
    {user && (
                  <>
                  {areas?.map(x => x?.results?.map(y => y))?.flat(1)?.filter(y => y) ?  (
                                  <> 
              <DropdownItem toggle={false}><div className="tableHeaderAdd"> 
              <div 
              style={{
                color: "rgb(255, 255, 255)",
                pointerEvents:  "auto",
                background: "#5C92BF", 
                
                margin:"0 5px",
                          padding: "0 25px",
                fontSize:"14",
                fontWeight:"bold",
                opacity: !whichRun || whichRun == "none" ? .4 : 1,
                cursor: !whichRun || whichRun == "none" ? "auto" : "pointer"

              }} 
              className="auth-button addUser userActionsButtons"
              onClick={() => {
                if(tileResult?.exist) {
                  if (whichRun && whichRun !== "none" ) {
                   
                      if(!tileResult?.first) {
                        // setWizard({
                        //   info: "Oops... You Didn't Perform the Pre-test Testfoot Validation Procedure!",
                        //   button: "Validate Now",
                        //   click: () => {
                            setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                              serial: "N/A",
                              wetValue: "N/A",
                              dryValue: "N/A"
                            })
                            setTileModal("first") 
                        //   }
                        // })
                      } else if(!tileResult?.firstAfter) {
                        // setWizard({
                        //   info: "Oops... You Didn't Perform the Post-test Testfoot Validation Procedure!",
                        //   button: "Validate Now",
                        //   click: () => {
                            setSurface(user?.user?.surface ? JSON.parse(user?.user?.surface) : {
                              serial: "N/A",
                              wetValue: "N/A",
                              dryValue: "N/A"
                            })
                            setTileModal("second") 
                        //   }
                        // }) 
                      } else {
                          let areaNullImages = areas.filter(x => !x.images?.length && x.results?.length)
                          if(areaNullImages?.length) {
                            setImagesLaterModal(areas)
                          } else {
                            generateReport("yes", areas?.map(x => x?.results?.map(y => y))?.flat(1)?.filter(y => y), null,areas?.map(x => x?.extra?.map(y => y))?.flat(1)?.filter(y => y) )
                          }
                      }                                     
                   }} else {
                   if (whichRun && whichRun !== "none" ) {
                      let areaNullImages = areas.filter(x => !x.images?.length && x.results?.length)
                      if(areaNullImages?.length) {
                        setImagesLaterModal(areas)
                      } else {
                        generateReport("yes", areas?.map(x => x?.results?.map(y => y))?.flat(1)?.filter(y => y), null,areas?.map(x => x?.extra?.map(y => y))?.flat(1)?.filter(y => y) )
                      }
                        }
                        }
                        }
                        }
                        >
                  Generate report
              </div>
              </div>
              </DropdownItem>
              <DropdownItem toggle={false}><div className="tableHeaderAdd"> 
              <div 
              style={{
                color: "rgb(255, 255, 255)",
                pointerEvents:  "auto",
                background: "#5C92BF", 
                margin:"0 5px",
                padding: "0 25px",
                fontSize:"14",
                fontWeight:"bold"
              }} 
              className="auth-button addUser userActionsButtons"
              onClick={() => {
                setMatchedTest({extra: true, pending: true, new: true})
                setExtraDataModal("Extra Data")}}>
                 Extra data
              </div>
              </div>
              </DropdownItem>
              </>
              ) : ( 
              <DropdownItem> <DataSpinner small="s" /></DropdownItem>
              )}
                  </>
                )}
                <DropdownItem onClick={() => {
                  setButtonDisabled(true)
                }} toggle={false}>
                  <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div>Fixed</div>
                <label style={{cursor:"pointer"}} 
                 className="toggle-control">
                    <span className={`${buttonDisabled ? "checked" : "notChecked"}`}></span>
                    <span className="control"></span> 
                </label>
                </div> 
                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
               
              
    
    </>
}               
</div> : <div style={{ marginBottom:"1rem"}} className="userActions">
              <div>
              <div style={{display:"flex",alignItems:"center", paddingLeft:"24px", marginTop:"10px"}}>
              <label class="plus-minus bounce">
                          <input onClick={(e) => {
                                        e.stopPropagation()
                                      }} onChange={(e) => {
                                        setAreas(areas.map((ar, i, a) => {
                                          ar.checked = a.length == printedAreas.length ? false : true
                                          ar.expanded = false                                   
                                          return ar
                                        }))
                                      }} checked={printedAreas.length == areas.length} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                          
                      </label>
                      <div ref={selectRef} onClick={(e) => {
                                        e.stopPropagation()
                                        setAreas(areas.map((ar, i, a) => {
                                          ar.checked = a.length == printedAreas.length ? false : true
                                          ar.expanded = false                                   
                                          return ar
                                        }))
                                      }}  className="userSquareHeader" style={{display:"inline-block", cursor:"pointer"}}>Select all</div>
              </div>
              </div> 
               </div>
               }
              {/* </div> */}
              {areas.filter(area => area.view == selectedAudit.defaultView).map((area, i, a) => (
                <div key={i} ref={scrollToArea == area.id ? bottomRef : null} className={`${area.expanded ? "auditExpanded auditReports": "auditReports"}`} style={{padding:"5px 4px"}}>                  
                <div style={{display:"flex", justifyContent:"space-between", cursor:"pointer", padding:"9px 20px", borderRadius:"8px"}}
                onClick={() => {
                  setScrollToArea(area.id)
                  area.expanded = !area.expanded 
                  setAreas(areas.map(ar => ar.id == area.id ? area : ar))
                }}
                className="userSquareHeader hover" >
                    <div style={{
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center" 
                    }}
                    className="areaBar" 
                    >
                      {printMode && <label class="plus-minus bounce">
                          <input onClick={(e) => {
                                        e.stopPropagation()
                                      }} onChange={(e) => {
                                        area.checked = !area.checked 
                                        setAreas(areas.map(ar => {
                                          ar.expanded = false
                                          area.expanded = false                                        
                                          if(ar.id == area.id) {
                                            return area
                                          } else {
                                            return ar
                                          } 
                                        }))
                                      }} checked={area.checked} type="checkbox"/>
                          <svg viewBox="0 0 21 21">
                              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                          </svg>
                      </label>}
                    <span>{area.name} (Area #{area.areaNumber})</span>
                    <img src={edit} style={{margin:"0 1rem", cursor:"pointer",
                color:"#5C92BF"}} onClick={() => {
                  // setScrollToArea(area)
                  editArea(area)
                  }} />
                    {area.pass !== null && area.results?.length ? <span style={{width:"auto", margin:"0 1rem", backgroundColor: area.pass == "pass" ? "#6DB240" : "#EF1111"}} className="audit-status" >{area.pass}</span> : null}
                    </div> 
                    <FontAwesomeIcon className={`${area.expanded ? "collapseArrow collapseActive" : "collapseArrow"}`} icon={faChevronDown}/> 
                </div>
                <div className={`${area.expanded ? "areaContainerOn" : "areaContainerOff"}`}>
                  <AuditArea locationToDelete={locationToDelete} setLocationToDelete={setLocationToDelete} addLocationToDeleted={addLocationToDeleted} auditHistoryLoading={auditHistoryLoading} auditHistory={auditHistory?.filter((x) => x.area == area.id)?.filter((x,i) => i < 10)} setAddAreaDropDown={setAddAreaDropDown} photo={photo} setPhoto={setPhoto} selectedAudit={selectedAudit} allLocationsRefetch={allLocationsRefetch} runTestForLocation={runTestForLocation} allAreasRefetchToLoad={allAreasRefetchToLoad} allAreasCalledToLoad={allAreasCalledToLoad} editAreaImages={editAreaImages} setAddAreaModal={setAddAreaModal} allAreasRefetch={allAreasRefetch} lines={lines} setLines={setLines} areaNumber={area.areaNumber} defaultDcofHtml={defaultDcofHtml} defaultDcofCss={defaultDcofCss} templateVariables={templateVariables} user={user} defaultDCOF={defaultDCOF} editArea={editArea} locationsRefetch={allLocationsRefetch} area={area} locations={locations ? locations.length > 0 ? locations.filter(location => location.area == area.id) : [] : null } />
                </div>
            </div>
              ))}
            </div>

            </div> : (
            <div style={{width:"100%", height:"70%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <AddAreaModal
            areaId={areaId}
            addLocationToDeleted={addLocationToDeleted}
            imagesLater={imagesLater}
            setImagesLater={setImagesLater}
            setHaveData={setHaveData}
            setAddAreaDropDown={setAddAreaDropDown}
            history={historyArr}
            setHistory={setHistoryArr}
            photo={photo}
            setPhoto={setPhoto}
            areaNumber={areaNumber}
            setAreaNumber={setAreaNumber}
            uploadFile={uploadFile}
            areaNameError={areaNameError}
            setAreaNameError={setAreaNameError}
            areas={areas}
            updateAreaImages={updateAreaImages}
            draftAreaFunc={draftAreaFunc}
            setFloorFinishCondition={setFloorFinishCondition}
            floorFinishCondition={floorFinishCondition}
            images={images}
            setImages={setImages}
            setNewDiagramImage={setNewDiagramImage}
            areaLines={areaLines}
            setAreaLines={setAreaLines}
            areaDiagram={areaDiagram}
            setTestingCondition={setTestingCondition}
            testingCondition={testingCondition}
            setTestingCategory={setTestingCategory}
            testingCategory={testingCategory}
            addAreaModal={addAreaModal}
            setLoadAreaModal={setLoadAreaModal}
            setAddAreaModal={setAddAreaModal}
            areaName={areaName}
            setAreaName={setAreaName}
            areaModalPage={areaModalPage}
            setAreaModalPage={setAreaModalPage}
            setFloorType={setFloorType}
            floorType={floorType}
            setFloorTypeDescription={setFloorTypeDescription}
            floorTypeDescription={floorTypeDescription}
            setTextureType={setTextureType}
            TextureType={TextureType}
            setTextureTypeDescription={setTextureTypeDescription}
            TextureTypeDescription={TextureTypeDescription}
            setFloorFinish={setFloorFinish}
            floorFinish={floorFinish}
            setFinishCondition={setFinishCondition}
            finishCondition={finishCondition}
            setFloorFinishDescription={setFloorFinishDescription}
            floorFinishDescription={floorFinishDescription}
            setslopeSurface={setslopeSurface}
            slopeSurface={slopeSurface}
            setcleaningChemicals={setcleaningChemicals}
            cleaningChemicals={cleaningChemicals}
            setSlsWaterConcentration={setSlsWaterConcentration}
            slsWaterConcentration={slsWaterConcentration}
            setAverageDcof={setAverageDcof}
            averageDcof={averageDcof}
            setAreaNotes={setAreaNotes}
            areaNotes={areaNotes}
            // area add func
            addAreaFunc={addAreaFunc}
            // area images 
            lines={lines}
            setLines={setLines}
            takeAPhotoSelected={takeAPhotoSelected}
            handleImageUploaded={handleImageUploaded}
            changeTakeAPhotoSelected={changeTakeAPhotoSelected}
            webcamURI={webcamURI}
            changeWebcamURI={changeWebcamURI}
            imageUploaded={imageUploaded}
            changeImageUploaded={changeImageUploaded}
            imagePreviewAvailable={imagePreviewAvailable}
            changeImagePreviewAvailable={changeImagePreviewAvailable}
            addProfilePhotoClicked={addProfilePhotoClicked}
            setAddProfileClicked={setAddProfileClicked}
            handleDeletedPreviewImage={handleDeletedPreviewImage}
            // testing diagram
            testingDiagram={testingDiagram}
            setTestingDiagram={setTestingDiagram}
            setDiagramImage={setDiagramImage}
            diagramImage={diagramImage}
            updateAreaFunc={updateAreaFunc}
            selectedArea={selectedArea}
            setSelectedArea={setSelectedArea}
            loadAreaFunc={loadAreaFunc}
            />
            <ButtonDropdown
                        isOpen={addAreaDropDown}
                        toggle={() => setAddAreaDropDown(!addAreaDropDown)}
                        >
                            <DropdownToggle
                              className="auth-button addUser userActionsButtons"
                              style={{fontWeight:"600"}}
                            caret> 
                              Add New Area
                            </DropdownToggle>
                            <DropdownMenu 
                              className="minDropdown"
                            > 
                              {allAreasToLoad?.length ? <DropdownItem onClick={() => setLoadAreaModal("Area")}>
                                Load Area
                              </DropdownItem> : null}
                              <DropdownItem onClick={() => {
                                setAddAreaDropDown(false)
                                setPhoto(null)
                                setHaveData(false) 
                                setAddAreaModal("Area")
                                }} >
                                Create New Area
                              </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
          </div>) : <DataSpinner small="l" /> }
            </div>
          )}
        </div>
    )
}

export default AdminProjects