const BACK_ROUTE = "BACK_ROUTE";

const ACTION_BACK_ROUTE = (payload) => {
  return {
    type: BACK_ROUTE,
    payload
  };
};

export default ACTION_BACK_ROUTE;
