import React, { useEffect, useState } from "react" 
import { css } from "@emotion/css";
import ClipLoader from "react-spinners/ClipLoader";



export const DataSpinner = (props) => {
    
        const override = css`
    display: block;
    position: absolute;
    left: 25%;
    right: 25%;
  `;

  // useEffect(() => {
  //   window.onbeforeunload = function() {
  //     return "Data will be lost if you leave the page, are you sure?";
  //   };
  //   return () => {
  //     window.onbeforeunload = function() {
  //       return
  //     };
  //   }
  //   },[])

        return (
          <div
            className={`clip_loader_spinner_container ${props.all ? "allScreen" : "notAll"}`}
            style={{ display: "flex", position: props.over ? "absolute" : "static" , background: props.over ? "white":"transparent", bottom:0, top:0 , height:"auto" }} 
          > 
             
            <ClipLoader
              size={props.small == "s" || props.small == "sm" ? 20 :100}
              css={override}
              color={props.color ? props.color :props.small == "s" ? "white" : "rgb(44, 44, 52)"}
              loading={true}
            />
          </div>
        );
}