import { gql } from "apollo-boost";

const updateUserStatusMutation = gql`
  mutation(
      $_id: ID
      $status: Boolean
      $letter: Boolean
      $wmg: Boolean
      $franchiseStatus: Boolean
      ) {
    updateUserStatus(
    _id: $_id
    status: $status
    letter: $letter
    wmg: $wmg
    franchiseStatus: $franchiseStatus
    ) {
      _id
      status
      letter
      wmg
      franchiseStatus
    }
  }
`;

export default updateUserStatusMutation;
