import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShowMessageModal = (props) => {
    const {
        open,
        setOpen,
        setShowPopup
        } = props

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0", 
            right: "0",
            bottom: "0", 
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
         <div className="modal password-modal">
          <FontAwesomeIcon
            className="modal_x"
            icon={faTimes}
            onClick={() => {
              setOpen(false)
              setShowPopup(true)
            }
            }
          />
          <h1 className="header" style={{width:"100%", fontSize:"24px", marginBottom:"70px", color:"#EF1111"}}>{"Rejection Reason"}</h1>          
          <div style={{width:"100%", textTransform:"none" , color:"#EF1111", fontWeight:"bolder", fontSize:"18px", display:"flex", width:"100%", justifyContent:"center", alignItems:"center", color: "#505463"}}>{open}</div>
        </div>
      </Modal>
  )}

  export default ShowMessageModal
       