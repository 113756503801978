import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import infoI from "../../../../assests/icons/info.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from "reactstrap"
import {useDispatch} from "react-redux";
import ACTION_INFO_ACTIVE from "../../../../actions/InfoActive/ACTION_INFO_ACTIVE";
import { useHistory } from 'react-router-dom';

 
const TileModal = ( props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [averageRuns, setAverageRuns] = useState("")
    const {
        setAddAreaDropDown,
        open,
        setOpen,
        tileResult,
        audit,
        updateTile,
        whichRun,
        botRun,
        setBotRun,
        secondRun, 
        setSecondRun,
        surface,
        setSurface,
        messyAverage,
        setMessyAverage,
        activeTestCondition, 
        setActiveTestCondition,
        onlyTestCondition, 
        setOnlyTestCondition
        } = props

        const runKeyTyping = (e) => {
          if (
            (e.keyCode >= 8 && e.keyCode < 32) ||
            (e.keyCode >= 37 && e.keyCode <= 40) ||
            (e.keyCode >= 96 && e.keyCode <= 105) ||
            (e.keyCode >= 48 && e.keyCode <= 57) ||
            (e.keyCode == 17) || 
            (e.keyCode == 86) ||
            (e.keyCode == 190) ||
            (e.keyCode == 46)

          ) {
            return e.keyCode;
          } else {
           e && e.preventDefault();
          }
        };

        let prefix = "0."

        const botRunTyping = (e) => {
          let currentTyping = e.currentTarget.value;
          if(currentTyping.length <= 4) {
            if(currentTyping == 0) {
              currentTyping = prefix
              setBotRun(currentTyping)
            }
            if(currentTyping.length <= 1) {
                currentTyping = prefix + e.currentTarget.value
                setBotRun(currentTyping) 
            } else {
              setBotRun(currentTyping)
            } 
          }
        };

        const secondRunTyping = (e) => {
          let currentTyping = e.currentTarget.value; 
          if(currentTyping.length <= 4) {
            if(currentTyping == 0) {
              currentTyping = prefix
              setSecondRun(currentTyping)
            }
            if(currentTyping.length <= 1) {
                currentTyping = prefix + e.currentTarget.value
                setSecondRun(currentTyping) 
            } else {
              setSecondRun(currentTyping)
            } 
              
          }      
        };

        const validateDcof = () => {
          let actualValue = activeTestCondition == "dry" ? surface.dryValue : surface.wetValue
          let messyConst = activeTestCondition == "dry" ? 0.04 : 0.03
          let messyAverage = Number(averageRuns) <=  Number(actualValue) + Number(messyConst) && Number(averageRuns) >= Number(actualValue) - Number(messyConst)
          setMessyAverage(messyAverage)
        }

        const [haveTile, setHaveTile] = useState(true)

        useEffect(() => {
          if(botRun && secondRun) { 
            if(botRun == "0." || secondRun == "0.") {
              setAverageRuns(null)
              } else {
            setAverageRuns((Number(botRun)  + Number(secondRun) ) / 2)
              }

          }
        },[botRun, secondRun])

        useEffect(() => {
          if(averageRuns && activeTestCondition) {
            setMessyAverage(true)
            validateDcof()
          }
        },[averageRuns, activeTestCondition])
        const [dontHaveTile, setDontHaveTile] = useState(false)
        useEffect(() => {
          if(surface) {
            if(surface?.serial == "N/A") {
              setDontHaveTile(true)
              setHaveTile(false)
            }
          }
        },[surface])

        useEffect(() => {
          if(tileResult && open == "second") {
            setActiveTestCondition(tileResult.dcof)
            setOnlyTestCondition(tileResult.dcof)
          } 
        },[tileResult, open])
        
        const [nextClicked, setNextClicked] = useState(false)


  return(
<Modal
        isOpen={open ? true : false}  
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal task-modal" style={{height:"90%", width:"44vw", justifyContent:"space-between"}}>
              <FontAwesomeIcon 
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setNextClicked(false)
                  setAddAreaDropDown(true)
                  setOpen(null)
                  setSurface(null)
                  setBotRun("")
                  setSecondRun("")
                  setOnlyTestCondition("")
                  setAverageRuns(null)
                  setHaveTile(true)
                  setMessyAverage(true) 
                }}
              /> 
               {nextClicked || open == "second" ? (
                <> <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>{open == "first" ? (
  <div><span>Pre</span>-test Testfoot Validation Procedure</div>
): open == "second" ? (
  <div><span>Post</span>-test Testfoot Validation Procedure</div>
) : "Validation Procedure"}</h1>
<div style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}} className="form-group-container task-form"> 
  <div style={{fontWeight:"bold", fontSize:"12px"}}>
  TCNA Validation Procedure as defined in ANSI A326.3-2021: <br/>
  Make one 8 in. (203.2 mm) measurement then rotate the testing device 180<span>&#176;</span> and make a second 8 in. (203.2 mm) measurement on the ANSI A326.3 validation surface.
  <br/> <span style={{color:"gray"}}>*average calculated automatically</span>
  </div>
  {
    open == "first" ? (
      <>
     <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <div className={`haveTile ${haveTile ? "haveTileActive" : !dontHaveTile ? "haveTileNotOpacity" : "haveTileActive"}`} style={{position:"relative", border : !messyAverage && "1px solid red"}} onClick={() => setHaveTile(true)}>
          {dontHaveTile && <div style={{backgroundColor:"rgba(0, 0, 0, 0.5)" , position:"absolute", right:0, left:0, top:0, bottom:0, height:"100%", display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column", padding:"30px 0", zIndex:2}}> 
          <div className={`dontHaveTile ${!haveTile && "haveTileNotActive"}`} style={{height:"fit-content", padding:"30px 20px", color:"black", border:"2px solid black", background:"white", margin:"0px auto", width:"80%", display:"flex", flexDirection:"column"}} onClick={() => {
          setHaveTile(false)
          setBotRun("")
          setSecondRun("")
        }}>
          <div style={{marginBottom:"20px"}}>You currently do not have an ANSI A326.3 Testfoot Validation Surface</div>
          <div>If you want to  add a Validation Surface now</div>
          <div>Please click the button below</div>

        </div>

        <div
    
    className="auth-button addUser confirmDelete"
    style={{margin:0, marginBottom:"10px", padding: "5px 20px", width:"auto"}}
    onClick={() => {                 
      history.push({
        pathname:`/admin/users`,
        state: {
            surface: true,
            backAudit: audit.id
        }
    })
    }} 
    >
   Add Validation Surface Info
    </div> 

           </div>}
        {!messyAverage && <div style={{margin: "10px 0", fontWeight:"bold", color:"#EF1111"}}>Validation procedures failed
       <img id={`UncontrolledTooltipExample_statusFailed_1`} width={15} style={{cursor:"pointer",
    color:"#5C92BF", margin:"0 10px"}} src={infoI} onClick={() => dispatch(ACTION_INFO_ACTIVE(<div style={{height:"100%", overflowY:"auto"}}><div style={{margin:"0 0 30px 0", fontSize:"3.5vmin"}}>If the average is within ±{activeTestCondition == "dry" ? "0.04" : "0.03"} of the value stated for {activeTestCondition} DCOF testing on the ANSI A326.3
    validation surface, proceed with testing
    If the value falls above or below that range, repeat Sections 1-4</div>
    <div style={{fontSize:"3vmin"}}><div style={{margin:"10px 0"}}>1 - Attach new sand paper to the testfoot reconditioning tool before testing begins.</div>
    <div style={{margin:"10px 0"}}>2 - Insert SBR testfoot into the testfoot reconditioning tool.</div>
    <div style={{margin:"10px 0"}}>3 - Recondition the testfoot, periodically removing the testfoot to view the rubber surface.
    Brush sand paper and testfoot with a dry paint or chip brush to remove excess material.</div>
    <div style={{margin:"10px 0"}}>4 - Re-clean the ANSI A326.3 validation surface.</div>
    <div style={{margin:"10px 0"}}>5 - Proceed to Re-test Validation Surface.</div></div></div>))} /><UncontrolledTooltip
placement="top"
target={`UncontrolledTooltipExample_statusFailed_1`}
>
Need Help!
</UncontrolledTooltip>
        </div>}
        <div className="form-row" style={{width:"100%", zIndex: haveTile ? "1" : "-1000"}}>
        <div style={{margin: "10px 0", fontWeight:"bold"}}>I do have an ANSI A326.3 Testfoot Validation Surface</div>
        </div>
        {surface && <div className="form-row" style={{width:"100%", border:"1px solid #e0e0e0" , borderRadius:"5px", justifyContent:"space-between", alignItems:"flex-start", zIndex: haveTile ? "1" : "-1000"}}>
        <div className="form-collection" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div className="form-label" style={{width:"fit-content" ,fontSize:"12px"}}>ANSI validation Surface Serial</div>
          <span style={{color:"gray"}}>{surface.serial}</span>
        </div>
        {surface.wetValue && surface.wetValue !== "0." ? <div className="form-collection" onClick={() => setActiveTestCondition("wet")} style={{borderRadius:"5px", border: activeTestCondition == "wet" ? !messyAverage ? "2px solid red" : "2px solid green" : "0px",alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div className="form-label" style={{width:"fit-content"}}>Wet Value</div>
        <span style={{color:"gray"}}>{surface.wetValue}</span>
        </div> : null}
        {surface.dryValue && surface.dryValue !== "0." ? <div className="form-collection" onClick={() => setActiveTestCondition("dry")} style={{borderRadius:"5px", border: activeTestCondition == "dry" ? !messyAverage ? "2px solid red" : "2px solid green" : "0px",alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div className="form-label" style={{width:"fit-content"}}>Dry Value</div>
        <span style={{color:"gray"}}>{surface.dryValue}</span>
        </div> : null }
        </div>}
        <div className="form-row" style={{width:"100%", justifyContent:"flex-start", alignItems:"flex-start", zIndex: haveTile ? "1" : "-1000"}}>
        </div>
          <div className="form-row" style={{width:"100%", zIndex: haveTile ? "1" : "-1000"}}>
          <div className="form-collection">
            <div className="form-label"><span>first run {activeTestCondition} condition <span style={{color:"red"}}> *</span></span></div>
            <input
              placeholder='First Run 0.00'
              onBlur={(e) => e.target.value && setBotRun(Number(e.target.value))}
              disabled={!haveTile}
              onKeyDown={runKeyTyping}
              onChange={botRunTyping}
              value={botRun}
              step=".11"
              min="0"
              max="0"
              type="number"
              className="form-input"
            />
          </div>
          <div className="form-collection">
            <div className="form-label"><span>second run {activeTestCondition} condition<span style={{color:"red"}}> *</span></span> </div>
            <input
            placeholder='Second run 0.00'
              onBlur={(e) => e.target.value && setSecondRun(Number(e.target.value))}
              disabled={!haveTile}
              onKeyDown={runKeyTyping}
              onChange={secondRunTyping}
              value={secondRun}
              step=".11"
              min="0"
              max="0"
              type="number"
              className="form-input"
            />
          </div>
          </div> 

          <div className="form-row" style={{width:"100%", zIndex: haveTile ? "1" : "-100"}}>
          <div className="form-collection">
            <div className="form-label">Average DCOF</div>
            <input
            placeholder='Average DCOF calculation'
              // onBlur={(e) => setAverage(e.target.value)}
              // disabled={true}
              // onKeyDown={runKeyTyping}
              onChange={() => null}
              value={averageRuns || ""}
              type="text"
              className="form-input"
            />
          </div>
          <div className='form-collection'>

          </div>
          </div>

        </div>
     </div>
     <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
     <div  
    style={{margin:".5rem"}}              
        className={`auth-button addUser confirmDelete`}
        onClick={() => {
         setNextClicked(false)
        }}
    >
    back
    </div>
    <div                
        className={`auth-button addUser confirmDelete ${haveTile ? !averageRuns ? "disable-button" : " " : !haveTile && ""}`}
        onClick={() => {
          haveTile ? averageRuns ? updateTile("first",botRun,secondRun) : null : updateTile("notExist")
          setHaveTile(true)
        }}
    >

    Save

    </div>
</div> 
     </>
    ) : open == "second" ? (
      <>
      <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <div  className={`haveTile ${haveTile ? "haveTileActive" : "haveTileNotOpacity"}`} style={{border : !messyAverage && "1px solid red"}}>
        {!messyAverage && <div style={{margin: "10px 0", fontWeight:"bold", color:"#EF1111"}}>Validation procedures failed
        <img id={`UncontrolledTooltipExample_statusFailed_2`} width={15} style={{cursor:"pointer",
    color:"#5C92BF", margin:"0 10px"}} src={infoI} onClick={() => dispatch(ACTION_INFO_ACTIVE(<div style={{border:"2px solid red"}}>If the average is within ±{activeTestCondition == "dry" ? "0.04" : "0.03"} of the value stated for {activeTestCondition} DCOF testing on the ANSI A326.3
    validation surface, proceed with testing
    If the value falls above or below that range, repeat Sections 1-4
    1 - Attach new sand paper to the testfoot reconditioning tool before testing begins
    2 - Insert SBR testfoot into the testfoot reconditioning tool,
    3 - Recondition the testfoot, periodically removing the testfoot to view the rubber surface.
    Brush sand paper and testfoot with a dry paint or chip brush to remove excess material.
    4 - Re-clean the ANSI A326.3 validation surface
    5 - Proceed to Re-test Validation Surface</div>))} /><UncontrolledTooltip
placement="top"
target={`UncontrolledTooltipExample_statusFailed_2`}
>
Need Help! 
</UncontrolledTooltip>
        </div>}
        {surface && <div className="form-row" style={{width:"100%", border:"1px solid #e0e0e0" , borderRadius:"5px", justifyContent:"space-between", alignItems:"flex-start", zIndex: haveTile ? "1" : "-1000"}}>
        <div className="form-collection" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div className="form-label" style={{width:"fit-content"}}>ANSI Serial</div>
          <span style={{color:"gray"}}>{surface.serial}</span>
        </div>
        {surface.wetValue && surface.wetValue !== "0." ? onlyTestCondition ? onlyTestCondition !== "wet" ? null :<div className="form-collection" onClick={() => setActiveTestCondition("wet")} style={{ borderRadius:"5px", border: activeTestCondition == "wet" ? !messyAverage ? "2px solid red" : "2px solid green" : "0px",alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div className="form-label" style={{width:"fit-content"}}>Wet Value</div>
        <span style={{color:"gray"}}>{surface.wetValue}</span>
        </div>: <div className="form-collection" onClick={() => setActiveTestCondition("wet")} style={{ borderRadius:"5px", border: activeTestCondition == "wet" ? !messyAverage ? "2px solid red" : "2px solid green" : "0px",alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div className="form-label" style={{width:"fit-content"}}>Wet Value</div>
        <span style={{color:"gray"}}>{surface.wetValue}</span>
        </div> : null}
        {surface.dryValue && surface.dryValue !== "0."? onlyTestCondition ? onlyTestCondition !== "dry" ? null :<div className="form-collection" onClick={() => setActiveTestCondition("dry")} style={{borderRadius:"5px", border: activeTestCondition == "dry" ? !messyAverage ? "2px solid red" : "2px solid green" : "0px", alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div className="form-label" style={{width:"fit-content"}}>Dry Value</div>
        <span style={{color:"gray"}}>{surface.dryValue}</span>
        </div> :<div className="form-collection" onClick={() => setActiveTestCondition("dry")} style={{borderRadius:"5px", border: activeTestCondition == "dry" ? !messyAverage ? "2px solid red" : "2px solid green" : "0px", alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div className="form-label" style={{width:"fit-content"}}>Dry Value</div>
        <span style={{color:"gray"}}>{surface.dryValue}</span>
        </div> : null}
        </div>} 
          <div className="form-row" style={{width:"100%", zIndex: haveTile ? "1" : "-1000"}}>
          <div className="form-collection">
            <div className="form-label"><span>first run {activeTestCondition} condition<span style={{color:"red"}}> *</span></span> </div>
            <input
            placeholder='First Run'
              onBlur={(e) => setBotRun(Number(e.target.value))}
              disabled={!haveTile}
              onKeyDown={runKeyTyping}
              onChange={botRunTyping}
              value={botRun}
              step=".11"
              min="0"
              max="0"
              type="number"
              className="form-input"
            />
          </div>
          <div className="form-collection">
            <div className="form-label"><span>second run {activeTestCondition} condition<span style={{color:"red"}}> *</span></span></div>
            <input
            placeholder='Second run'
              onBlur={(e) => setSecondRun(Number(e.target.value))}
              disabled={!haveTile}
              onKeyDown={runKeyTyping}
              onChange={secondRunTyping}
              value={secondRun}
              step=".11"
              min="0"
              max="0"
              type="number"
              className="form-input"
            />
          </div>
          </div> 

          <div className="form-row" style={{width:"100%", zIndex: haveTile ? "1" : "-100"}}>
          <div className="form-collection">
            <div className="form-label">Average DCOF</div>
            <input
            placeholder='Average DCOF calculation'
              // onBlur={(e) => setAverage(e.target.value)}
              // disabled={true}
              // onKeyDown={runKeyTyping}
              onChange={() => null}
              value={averageRuns || ""}
              type="text"
              className="form-input"
            />
          </div>
          <div className='form-collection'>

          </div>
          </div>

        </div>
     </div>
               <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

               <div  
    style={{margin:".5rem"}}              
        className={`auth-button addUser confirmDelete`}
        onClick={() => {
         setNextClicked(false)
        }}
    >
    back
    </div>
    <div  
    style={{margin:".5rem"}}              
        className={`auth-button addUser confirmDelete ${haveTile ? !averageRuns ? "disable-button" : "" : !haveTile && ""}`}
        onClick={() => {
          haveTile ? averageRuns ? updateTile("second",botRun,secondRun) : null : updateTile("notExist")
          setHaveTile(true)
          setOnlyTestCondition("")
        }}
    >

    Save

    </div>
</div>
     </>
    ) : null
  }
</div>
</>
               ):(
                <div style={{height:"100%"}}>
                  
                  <h1 className="header" style={{width:"100%",height:"10%", display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                  Testfoot Validation Procedure
                  </h1>
                  <div style={{height:"80%"}}>
                    <div style={{height:"50%", display:"flex", justifyContent:"center", alignItems:"flex-start", flexDirection:"column"}}>
                  <div style={{fontWeight:"bold", marginTop:"20px", fontSize:"18px"}}>
                  This tile is used in conjunction with ANSI A326.3, test method for dynamic coefficient of friction
                  (DCOF) for hard surface flooring materials.
                  </div>
                  <div style={{fontWeight:"bold", marginTop:"20px", fontSize:"14px", color:"#5C92BF"}}>
                  - Click "Validate Now" if you have the ANSI A326.3 Validation Surface and performed a
                    "Pre-Audit" testfoot validation.
                  </div> 
                  <div style={{fontWeight:"bold", marginTop:"20px", fontSize:"14px", color:"#5C92BF"}}>
                  - Click "I do not have Validation Surface" if you have not yet purchased the ANSI A326.3
                    Validation Surface or were unable to perform a "Pre-Audit" testfoot validation.
                  </div>
                  </div>

                  <div style={{height:"50%", overflow:"hidden", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{ display:"flex", width:"100%", border:"1px solid gray", borderRadius:"8px", height:"100%", justifyContent:"center", alignItems:"center"}}>
                      <img style={{ width:"60%"}} src='https://step-us-east-prod.s3.amazonaws.com/tile.jpeg-1681302979054.png' />
                    </div>
                    </div>
                  </div>

                  <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

                  <div className={`dontHaveTile`} style={{height:"32px"}}  onClick={() => {
                    updateTile("notExist")
                    setHaveTile(true)
                    setOnlyTestCondition("") 
                    setHaveTile(false) 
                    setBotRun("")
                    setSecondRun("")
                  }}>
                    <div>I don't have an ANSI A326.3 Testfoot Validation Surface</div>
                  </div>
                  <div  
                  style={{margin:".5rem", height:"32px", marginBottom:"0px"}}              
                  className={`auth-button addUser confirmDelete`}
                  onClick={() => {
                    setNextClicked(true)
                  }}
                  >
                  Validate Now

                  </div>
                  </div>

                </div>
               )}
           </div>
      </Modal>
  )}

  export default TileModal
       