import React, { useState } from "react"
import {Palette} from "./Palette"
import {Canvas} from "./Canvas"
import {PropertiesPanel} from "./PropertiesPanel"
import useImage from "use-image";
import areaRugImg from "../../../../../assests/layoutkeys/areaRug.svg"
import arrowOnImg from "../../../../../assests/layoutkeys/arrow-on.svg"
import barrierImg from "../../../../../assests/layoutkeys/barrier.svg"
import changeFloorTypeImg from "../../../../../assests/layoutkeys/changeFloortype.svg"
import columnImg from "../../../../../assests/layoutkeys/column.svg"
import dcofTestSampleImg from "../../../../../assests/layoutkeys/dcofTestSample.svg"
import deskImg from "../../../../../assests/layoutkeys/desk.svg"
import doubleDoorImg from "../../../../../assests/layoutkeys/doubleDoor.svg"
import elavatorImg from "../../../../../assests/layoutkeys/elevator.svg"
import halfCircleImg from "../../../../../assests/layoutkeys/half-circle.svg"
import otherImg from "../../../../../assests/layoutkeys/other.svg"
import rampImg from "../../../../../assests/layoutkeys/ramp.svg"
import revolvingDoorImg from "../../../../../assests/layoutkeys/revolvingDoor.svg"
import staircaseEscalatorImg from "../../../../../assests/layoutkeys/staircaseEscalator.svg"
import swingDoorImg from "../../../../../assests/layoutkeys/swingDoor.svg"
import walkoffMatImg from "../../../../../assests/layoutkeys/walkoffMat.svg"
import wallImg from "../../../../../assests/layoutkeys/wall.svg"
import waterFeatureImg from "../../../../../assests/layoutkeys/waterFeature.svg"
import { useShapes } from "./state";

const Planner = ({fromArea,setLoadingShape, loadingCanvas, addLocationToDeleted, shapeToDelete, selectShapeToDelete, history, locationId, setHistory, savingDiagram,photo, editArea, loadMode, setSavingDiagram, setNewDiagramImage, testingDiagram, areaLines, lines ,setLines, diagramImage, setDiagramImage, areaShapes, locationMode}) => {

  let vectors = [
    {
      title:"area rug",
      img: areaRugImg,
    },
    {
      title:"Enter",
      img: arrowOnImg,
    },
    {
      title:"barrier",
      img: barrierImg,
    },
    {
      title:"Floor",
      img: changeFloorTypeImg,
    },
    {
      title:"column",
      img: columnImg,
    },
    {
      title:"Location",
      img: dcofTestSampleImg,
    },
    {
      title:"desk",
      img: deskImg,
    },
    {
      title:"double",
      img: doubleDoorImg,
    },
    {
      title:"elavator",
      img: elavatorImg,
    },
    {
      title:"half circle",
      img: halfCircleImg,
    },
    {
      title:"other",
      img: otherImg,
    },
    {
      title:"ramp",
      img: rampImg,
    },
    {
      title:"revolving",
      img: revolvingDoorImg,
    },
    {
      title:"stairs",
      img: staircaseEscalatorImg,
    },
    {
      title:"swing",
      img: swingDoorImg,
    },
    {
      title:"walk off",
      img: walkoffMatImg,
    },
    {
      title:"wall",
      img: wallImg,
    },
    {
      title:"water",
      img: waterFeatureImg,
    },
    
  ]


  return(
    <div className="plannerWrapper">
      <Canvas
      loadingCanvas={loadingCanvas}
      addLocationToDeleted={addLocationToDeleted}
      setLoadingShape={setLoadingShape}
        locationId={locationId}
        photo={photo}
        loadMode={loadMode}
        editArea={true}
        shapeToDelete={shapeToDelete}
        selectShapeToDelete={selectShapeToDelete}
        vectors={vectors} 
        testingDiagram={testingDiagram}
        lines={lines}      
        locationMode={locationMode} 
        areaShapes={areaShapes}
        areaLines={areaLines}
        setLines={setLines}
        diagramImage={diagramImage}
        setDiagramImage={setDiagramImage}
        setNewDiagramImage={setNewDiagramImage}
        savingDiagram={savingDiagram}
        setSavingDiagram={setSavingDiagram}
        history={history}
        setHistory={setHistory}
        fromArea={fromArea}
      />  
      {!locationId && <Palette 
      vectors={vectors}
      shapeToDelete={shapeToDelete}
      selectShapeToDelete={selectShapeToDelete}
      locationMode={locationMode} />}
    </div>
) 
}

export default Planner