import { faDownload, faFileAlt, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import "./reportItem.scss"
import edit from "../../../../assests/icons/edit.svg"
import reportBlue from "../../../../assests/icons/reportBlue.svg"
import download from "../../../../assests/icons/download.svg"


const ReportItem = (props) => {
    const {
        report,
        even
    } = props
    return(
        <div className={`reportItem ${report.type == "free" && "evenItem"}`}
            
        >
                <div className="itemLeft">
                  
                  <div className="reportIcons reportLeftItem">
                      <img style={{marginRight:"1rem", cursor:"pointer",
                color:"#5C92BF"}} src={edit} />
                      <img style={{marginRight:"1rem", cursor:"pointer"}} src={reportBlue} />
                  </div>
                  <div className="itemDetails">
                  <div className="reportDate reportLeftItem">
                      <span className="reportTime dateItem">{report.date}</span>
                      <span className="dateItem">{report.date}</span>
                  </div>
                  <div className="reportLocation">{report.location}</div>
                  <div className="reportLocation" style={{fontWeight:"normal", marginLeft:"1rem", fontSize:"12px"}}>{report.type}</div>
                  </div>

                </div>
                <div className="itemRight">
                  <div className="reportStatus reportLeftItem">
                    {report.status}
                  </div>
                  <img style={{marginRight:"1rem", cursor:"pointer"}} src={download} />

                </div>

        </div>
    )
}

export default ReportItem