import React, { useCallback, useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_SELECTED_TESTS from '../../../../actions/SelectedTests/ACTION_SELECTED_TESTS';
import moment from 'moment';
// import TextLoop from "react-text-loop";


const StartTestModal = (props) => {
    const [testProgress, setTestProgress] = useState(50)
    const [timeLeft, setTImeLeft] = useState(90000)
    const texts = [
        "step 1",
        "step 2", 
        "step 3",
        "step 4",
        "step 5",
        "step 6",
        "step 7",
        "step 8",
        "step 9",
        "step 10",
        "step 11",

      ];
    const [index, setIndex] = React.useState(0);
    const {
        open,
        setOpen,
        resetActiveTest,
        startFunc,
        pullButtonActive,
        setPullButtonActive,
        pullData,
        pullingDataActive
        } = props


  return( 
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"999999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal task-modal bottomModal" style={{position:"fixed", justifyContent:"flex-start", bottom:"0", right:"auto", left:"auto", width:"50vw", overflow:"auto"}}>
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setIndex(0)
                  resetActiveTest()
                  setOpen(false)
                }}
              />
          <h1 className="header" style={{width:"100%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"24px" , color:"#B9B8BC"}}>Live BOT-3000E Test In Progress</h1>
            
            {/* <div style={{height:"50%", width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <TextLoop
                springConfig={{ stiffness: 70, damping: 31 }}
                adjustingSpeed={500}
            >
                {texts.map(x => (
                    <span key={x} style={{color:"#B9B8BC", fontSize:"7vmin"}}>{x}</span>
                ))} 
            </TextLoop>
            </div> */}
            {/* {(<div style={{zIndex:"1999", display:"flex", flexDirection:"column", justifyContent: "space-between", width:"100%"}}>

                <div
                
                className="auth-button addUser confirmDelete deleteButton"
                style={{margin:0, marginBottom:"10px", cursor:"pointer"}}
                onClick={() => {

                }} 
                >
                Continue
                </div> 

                <div 
                
                    className="auth-button addUser confirmDelete"
                    style={{margin:0, marginBottom:"10px", cursor:"pointer"}}
                    onClick={() => {
                    setIndex(0)
                      resetActiveTest()                   
                    }}
                >
                discard
                </div>

            </div>)} */}
            {true ? <div style={{position:"fixed", bottom:0, left:0, right:0, display:"flex", flexDirection:"column", alignItems:"center"}}>
            {pullButtonActive && !pullingDataActive ? <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center", padding:"20px 20px"}}>
                <div style={{color:"white"}}>Press continue once you generate the report on the BOT-3000E</div>
                <div 
                
                    className="auth-button addUser confirmDelete"
                    style={{margin:0, marginBottom:"10px", cursor:"pointer"}}
                    onClick={() => {
                    pullData()                  
                    }}
                >
                  Continue
                </div>  
              </div> : pullingDataActive ?
              <>
                         <h1 className="countTest" style={{fontSize:"8.5em", color:"white"}}>{pullingDataActive < 1800 ? (pullingDataActive/1800*100).toFixed(2) : (1800/1800*100).toFixed(2)}%</h1>
              <div style={{width:"100%", display:"flex", alignItems:"flex-start"}}>
              <div className="progress-barTest" style={{ height:"10px", position:"relative", borderTopRightRadius:"8px", borderBottomRightRadius:"8px",width: pullingDataActive && `${(pullingDataActive/1800*100).toFixed(2)}%`}}>
              {/* <h5 className="countTest" style={{fontSize:"1em", position:"absolute", top:-20, right:0, color:"white"}}>{ pullingDataActive < 10 ?`${(pullingDataActive)}%` :`${moment.utc(timeLeft).format('mm:ss')} Left`} </h5> */}
              </div>
            </div>
            </> : null
}
            </div> : null}
        </div>
      </Modal>
  )}

  export default StartTestModal
       