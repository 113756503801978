import { gql } from "apollo-boost";



gql`
  type ClientType {
    _id: ID
    companyName: String
  }
`;
gql`
  type UserType {
    _id: ID
    name: String
  }
`;
gql`
  type SiteType {
    _id: ID
    name: String
  }
`;

gql`
  type TileResultType {
    first: String
    firstAfter: String
    Second: String
    SecondTile: String
    exist: Boolean
    result: String
  }
`;
gql`
  input ClientInput {
    client: ClientType
  }
`;

gql`
input UserInput {
  user: UserType
}
`;

gql`
  input SiteInput {
    site: SiteType
  }
`;
gql`
  input TileInput {
    tileResult: TileResultType
  }
`;


    

const updateProjectMutation = gql`
  mutation(
    $_id: ID

    $site: [SiteInput],

    $client: [ClientInput],

    $assignee: [UserInput],
    $creator: [UserInput],

    $comment: [String]
$tileResult: TileInput,

  ) {
    updateProject(
    _id:$_id
    creator:$creator

    site:$site

    client:$client

    assignee:$assignee

    comment: $comment

tileResult: $tileResult
    ) {
    _id
creator {
      _id
      name
      adminType
    }
    site {
        _id
        name
        }
    client {
      _id
      companyName
    }
    assignee {
      _id
      name
    }
    comment
 tileResult {
      first
      firstAfter
      second
      secondAfter
      exist
      result
    }
    notification {
      _id
      new
      message
      users
      createdAt
    }

    }
  }
`;

export default updateProjectMutation;
