import { gql } from "apollo-boost";

const updateUserMutation = gql`
  mutation(
    $_id: ID
    $name: String,
    $email: String,
    $phoneNumber: String,
    $status: Boolean,    
    $active: Boolean,
    $role: String
    $adminType: String
    $image: String
    $certificate: String,
    $certificateExpiry: String,
    $companyName: String,
    $franchiseStatus: Boolean,
    $franchiseTerritory: [String],
    $licenseNumber: String,    
    $owner: ID,  
    $userRole: String  
    $companyAddress: String,
    $surface: String
    $reset: Boolean
    $zip: String


  ) {
    updateUser(
    _id: $_id
    zip: $zip
    name: $name
    email: $email
    phoneNumber: $phoneNumber
    status: $status
    active: $active
    role: $role
    adminType: $adminType
    image: $image
    certificate: $certificate
    certificateExpiry: $certificateExpiry
    companyName: $companyName
    franchiseStatus: $franchiseStatus
    franchiseTerritory: $franchiseTerritory
    licenseNumber: $licenseNumber
    owner: $owner
    userRole: $userRole
        companyAddress: $companyAddress
        surface: $surface
        reset: $reset

    ) {
      _id
      name
      email
      phoneNumber
      status
      active
      role
      image
      zip
      certificate
      adminType
      companyName
      franchiseStatus
      franchiseTerritory
      licenseNumber
      owner
      userRole
      companyAddress
      certificateExpiry
      surface
      notifications {
        _id
        new
        message
        users
        createdAt
      }
    }
  }
`;

export default updateUserMutation;
