import { gql } from "apollo-boost";

const updateSiteStatusMutation = gql`
  mutation(
      $_id: ID
      $status: Boolean
      ) {
    updateSiteStatus(
    _id: $_id
    status: $status
    ) {
      _id
      status
    }
  }
`;

export default updateSiteStatusMutation;
