import { gql } from "apollo-boost";

const allAuditHistoryQuery = gql`
   query(
    $view: ID
  ) { 
    allAuditHistory (
      view: $view
    ) {
    _id
    user 
    update
    view
    area
    createdAt
    }
  }
`;

export default allAuditHistoryQuery;