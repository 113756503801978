import { gql } from "apollo-boost";

const addUserMutation = gql`
  mutation(
    $name: String,
    $email: String,
    $phoneNumber: String,
    $status: Boolean,    
    $active: Boolean,
    $role: String
    $adminType: String
    $image: String
    $certificate: String,
    $companyName: String,
    $companyAddress: String,
    $franchiseStatus: Boolean,
    $franchiseTerritory: [String],
    $licenseNumber: String,    
    $owner: ID,  
    $client: ID,  
    $userRole: String  
    $zip: String  

  ) {
    addUser(
    name: $name
    zip: $zip
    client: $client
    email: $email
    phoneNumber: $phoneNumber
    status: $status
    active: $active
    role: $role
    adminType: $adminType
    image: $image
    certificate: $certificate
    companyName: $companyName
    companyAddress: $companyAddress
    franchiseStatus: $franchiseStatus
    franchiseTerritory: $franchiseTerritory
    licenseNumber: $licenseNumber
    owner: $owner
    userRole: $userRole
    ) {
      _id
      name
      zip
      email
      client
      phoneNumber
      status
      active
      role
      image
      certificate
      adminType
      companyName
      companyAddress
      franchiseStatus
      franchiseTerritory
      licenseNumber
      owner
      userRole
    }
  }
`;

export default addUserMutation;
