import React, { useEffect, useState } from "react"
import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import AdminSideBar from "./adminSideBar"
import AdminSignin from "./adminSignin"
import Admins from "./admins"
import AdminUsers from "./adminUsers"
import AdminClients from "./adminClients"
import AdminProjects from "./adminProjects"
import AdminReports from "./adminReports"
import AdminDashboard from "./adminDashboard"
import Header from "../header"
import blank from "../../assests/images/blank.png"
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from "react-redux"
import AdminLicenses from "./adminLicenses"
import ReportTemplate from "../resuable/reportTemplate"
import TemplateViewer from "../resuable/reportTemplate/viewer"
import ReportTemplates from "../resuable/reportTemplate/templates"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faArrowCircleRight, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons"
import ACTION_ACTIVE_SIDE_BAR from "../../actions/ActiveSideBar/ACTION_ACTIVE_SIDE_BAR"
import { DataSpinner } from "../resuable/spinner/dataSpinner"
import moment from "moment"
import Logo from "../../assests/images/logo.png"
import ACTION_LOADING_SPINNER_RESET from "../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET"
import { UncontrolledTooltip } from "reactstrap"
import AuditCard from "./adminProjects/auditCard" 
import archive from "../../assests/icons/archive.svg"
import ConfirmDeleteModal from "../resuable/modals/confirmDeleteModal"
import ACTION_SELECTED_TESTS from "../../actions/SelectedTests/ACTION_SELECTED_TESTS"
import ACTION_INFO_ACTIVE from "../../actions/InfoActive/ACTION_INFO_ACTIVE"
import ACTION_LOADING_SPINNER_ACTIVE from "../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import { useLocation } from "react-router-dom"
import ChangePasswordModal from "../resuable/modals/changePasswordModal"
import { useMutation } from "@apollo/client"
import resetPasswordMutation from "../../graphql/mutations/user/resetPasswordMutation"
import DoneModal from "../resuable/modals/doneModal" 
import ReportTemplatePage1 from "../ReportTemplate/Pages/Page1/ReportTemplatePage1"
import io from "socket.io-client"
import updateUserMutation from "../../graphql/mutations/user/updateUserMutation"
import { faClipboard } from "@fortawesome/free-regular-svg-icons"
// import TextLoop from "react-text-loop"
import logo from "../../assests/images/logo.png"
import AdminInsights from "./adminInsights"
import InsightsViewer from "../resuable/insightsViewer/viewer"
import AdminPendulum from "./adminPendulum"

const ENDPOINT = "https://stepreporting.com"
var socket, selectedUser; 
  
           
const AdminRouter = (props) => { 

    const location = useLocation();  
    const dispatch = useDispatch() 
    const userAuthenticated = useSelector((state) => state.userAuthenticated.user_authenticated);
    const selectedTests = useSelector((state) => state.selectedTests.selectedTests)

    const {
      activeView,
setActiveView,
        currentPageReports,
        setCurrentPageReports,
        currentPageAudits,
        setCurrentPageAudits,
        path ,
        handleLogout,
        user,
        activeType,
        setActiveType,
        subOwner,
        setSubOwner,
        userRefetch,
        admins,
        users,
        clients,
        accountsRefetch,
        clientsRefetch,
        projects,   
        projectsRefetch,
        projectsLoading,
        projectsCalled,
        licenses,
        licensesRefetch,
        templates,
        templatesRefetch,
        defaultDCOF,
        reports,
        reportsCalled,
        reportsRefetch,
        reportLoading,
        sites,
        allSitesRefetchDashboard
    } = props

    
    const [socketConnected, setSocketConnected] = useState(false)
    const [messages, setMessages] = useState([])
    const [temporaryMessage, setTemporaryMessage] = useState(null)
    const [addLocationLoad, setAddLocationLoad] = useState(false)

    useEffect(() => { 
      if(user) {
        if(user?.user?.notifications?.length) {
          let newNotifications = [...user.user.notifications]
          setTemporaryMessage([...newNotifications.filter(x => x.new)]) 
          setMessages(newNotifications.sort((a,b) => b.createdAt - a.createdAt))
        }
        socket = process.env.REACT_APP_SOCKET_URL_CLIENT ? io(process.env.REACT_APP_SOCKET_URL_CLIENT, {
          transports: ['polling'],
          }) : io() 
       if(user?.user?.notification) { 
        socket?.emit("new message", user?.user?.notification)
       }
      socket?.emit("setup", user?.user) 
      return () => {
        socket?.disconnect(true)
      }
      }  
    },[user])

    useEffect(() => {
      socket?.on("message recieved", (newMessage) => {
        setTemporaryMessage(newMessage)
        // // setMessages([...newMessage.filter(x => !messages.some( y=> x._id == y._id)), ...messages])
        !messages.some(x => x._id == newMessage._id) ? setMessages([newMessage, ...messages]) : null
     })
    }, [socket])

    useEffect(() => {
      if(temporaryMessage) {
        let notificationTimeout = setTimeout(() => {
          setTemporaryMessage(null)
        }, 10000);
        return () => { 
          clearTimeout(notificationTimeout)
        }
      }
    },[temporaryMessage])
    
const [ changePasswordModal, setChangePasswordModal] = useState(false)
const [ doneModal, setDoneModal] = useState(null)
const [ oldPassword, setOldPassword] = useState("")
const [ passwordError, setPasswordError] = useState("") 
const [
  resetPassword,
] = useMutation(resetPasswordMutation)
 

const [
  resetNotificaitons,
] = useMutation(updateUserMutation)
 

const confirmNewPassword = useSelector(
  (state) => state.confirmNewPassword.confirm_new_password
);

const changePassword = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setChangePasswordModal(false)
  resetPassword({
    variables:{
      email: user?.user?.email,
      password: oldPassword,
      newPassword: confirmNewPassword
    }
  }).then(res => {
    if(res) {
      setDoneModal("Your Password Changed Successfully")
      setPasswordError(false)
      setOldPassword("")
    }
    dispatch(ACTION_LOADING_SPINNER_RESET())
  }).catch(err => {
    if(err) {
      setChangePasswordModal(true)
      setPasswordError(err.message)
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }
  })
}


    const [selectedAudit, setSelectedAudit] = useState(null)
    const infoActive = useSelector(
      (state) => state.infoActive.infoActive
    );
    const activeSideBar = useSelector((state) => state.activeSideBar.active);
    const [logoutMenu, setLogoutMenu] = useState(null)
    const [notificationMenu, setNotificationMenu] = useState(null)
    const history = useHistory()

    const [auditAssignOptions, setAuditAssignOptions] = useState(null)
    
    useEffect(() => {
      if(users?.length > 0 && user) {
        console.log(users, 101010)
        if(user?.user?.role == "admin") {
          setAuditAssignOptions(users)
        } else if (user?.user?.role !== "client") {
          setAuditAssignOptions(users.filter(x => x._id == user?.user?._id || x.role == "sub-user" ))
        } else {
// client here
        }
 
      } 
    },[users, user])

    // useEffect(() => { 
    //   if(!userAuthenticated) {
    //     history.push("/admin")
    //   }
    // },[userAuthenticated])
    const [selectedUser , setSelectedUser] = useState(null)
    const [onFlyAudit, setOnFlyAudit] = useState(null)
    const [projectData, setProjectData] = useState(null)
    const [projectsFilter, setProjectsFilter] = useState(null)

    const [allCompaniesFilter, setAllCompanies] = useState([])
    const [allSitesFilter, setAllSites] = useState([])
    const [allAdminsfilter, setAllAdmins] = useState([])

    useEffect(() => {
      if(projectsFilter) {
        setAddLocationLoad(true)
        setProjectData(
          projectsFilter.map((project) => {
            const mappingProject = {
              id: project._id,
              count: project.count,
              views: project.views,
              status: project.status,
              client: project.client,
              site: project.site,
              assignee: project.assignee && project.assignee,
              name: project.client.companyName,
              address: project.site.name,
              auditStatus: project.state,
              createdBy: project.creator.name,
              createdByRole: project.creator.role,
              createdById: project.creator._id,
              createdByType: project.creator.adminType,
              assignToRole: project.assignee[0]?.role,
              createdOn: moment.unix(project.viewCreatedOn/1000).format("MM/DD/YYYY"),
              assignTo: project.assignee[0]?.name,
              assignToId: project.assignee[0]?._id,
              areas: project.areas,
              extra: project?.extra ? JSON.parse(project?.extra) : [],
              locations: project.locations,
              photo: Logo,
              state: project.state,
              comments: project.comment,
              tileResult: project.tileResult,
              firstTime: project.firstTime,
              defaultView: project.defaultView,
              hidden: project.hidden,
              viewCreatedOn: project.viewCreatedOn
          }
          return mappingProject
        }).filter(x => {
          if(user?.user?.role == "admin") {
            return true
       } else if(user?.user?.role !== "client") {
            if(!x.hidden) {
              return true
            } else {
              return false
            } 
} else {
// client here
        }        })  
        )
        dispatch(ACTION_LOADING_SPINNER_RESET())
  
       } else if(projects) {
        setAddLocationLoad(true)
        setProjectData(
          projects.map((project, i) => {
            const mappingProject = {
                id: project._id,
                status: project.status,
              count: project.count,
              views: project.views,
                client: project.client,
                site: project.site,
                assignee: project.assignee && project.assignee,
                name: project.client.companyName,   
                address: project.site.name,
                auditStatus: project.state,
                createdBy: project.creator.name, 
                createdByRole: project.creator.role,
                createdById: project.creator._id,
                createdByType: project.creator.adminType,
                assignToRole: project.assignee[0]?.role,
                createdOn: moment.unix(project.viewCreatedOn/1000).format("MM/DD/YYYY"),
                assignTo: project.assignee[0]?.name,
                assignToId: project.assignee[0]?._id,
                areas: project.areas,
                locations: project.locations,
                photo: Logo,
                state: project.state,
                extra: project?.extra ? JSON.parse(project?.extra) : [],
                comments: project.comment,
                tileResult: project.tileResult,
                firstTime: project.firstTime,
                defaultView: project.defaultView,
                hidden: project.hidden,
                viewCreatedOn: project.viewCreatedOn
            }
            return mappingProject
        }).filter(x => {
          if(user?.user?.role == "admin") {
            return true
} else if(user?.user?.role !== "client") {
              if(!x.hidden) {
              return true
            } else {
              return false
            }
} else {
// client here
        }
        })
        )
        setAllAdmins(
          projects.map(project => (
            project.creator.name
          ))
        )
        setAllCompanies( projects.map(project => (
          project.client.companyName
        )))
        setAllSites(
          projects.map(project => (
            project.site.name 
          ))
        )
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
    },[projectsFilter, projects])

    const [deleteTestModal, setDeleteTestModal] = useState(null)
    const [deleteType, setDeleteType] = useState(null)

    const deleteTestFunc = (key) => {
      dispatch(ACTION_SELECTED_TESTS({[key]: null}))
    } 

    const [areas, setAreas] = useState(null)
    const [resultsOnFly, setResultsOnFly] = useState(null)

    useEffect(() => {
      if(areas) {
        setResultsOnFly(areas?.map(x => x?.results).flat(1)?.filter(y => y))
      } 
    },[areas])
    const[logoutModal, setLogoutModal] = useState(false)


    useEffect(() => {
      if(notificationMenu && messages?.length && user?.user) {
        if(messages.some(x => x.new)) {
           resetNotificaitons({
          variables:{
            _id: user?.user?._id,
            reset: true
          }
        }).then(res => {
          if(res?.data?.updateUser?.notifications) {
            let newNotifications = [...res?.data?.updateUser?.notifications]

            setMessages(newNotifications?.sort((a,b) => b.createdAt - a.createdAt))
          }
        })   
      }
        }
       
    },[notificationMenu, messages, user])

    
const [adminVisitor, setAdminVisitor] = useState(false)
const [copied, setCopied] = useState(null)
const copyLicense = async (text) => {
  try {
    await  navigator.clipboard.writeText(text) 
    setCopied("true")
  } catch {
    setCopied("false")
  }
}



if(adminVisitor && userAuthenticated) { 
  return (
    <div className="signinPageContainer"> 
                    <img src={logo} className="logoScreen" style={{position:"fixed", top:"20px", left:"20px"}} />

    <div className="notAdminVisitor signinPageContainer singinPageContent">
      <div className="notAdminChild">
      <div className="white heading" onClick={(e) => {
        if(e.detail == 5) {
          setAdminVisitor(false)
        }
      }}>
        <div style={{height:"3vh"}}></div>
        Production has been <br/>moved to  <br/><span className="stepWording">stepreporting.com</span>. <br/> Please click the link <br/>below to be rerouted.<br/>Thank you!

        <div style={{display:"flex", height:"30%", justifyContent:"center", alignItems:"flex-end"}}>
            <div
              className="auth-button addUser bigButton"
              onClick={() => {window.location.href = "https://stepreporting.com"}}
              >
              Reroute Now
            </div>
        </div>
      </div>
      {/* <h1 className="black heading">stepreporting.com</h1> */}
      {/* <div className="white heading">Click below to go to the new domain</div> */}
     
      </div>
    </div>
    </div>
  )
}

const [proceed, setProceed] = useState(false)


if(user) {
  let licenseNumber = JSON.parse(user?.user?.licenseNumber)
  let role = user?.user?.role
  let license = licenseNumber?.length ? licenseNumber[0] : null
  if(license && role == "user") {
    let expiredDate = license?.endDate
    let ifExpired = expiredDate ? moment(expiredDate).isBefore(moment()) : null
    if(ifExpired && !proceed) {
      return (
      <div className="signinPageContainer">
                    <img src={logo} className="logoScreen" style={{position:"fixed", top:"20px", left:"20px"}} />
      <div className="notAdminVisitor signinPageContainer singinPageContent">
        <div className="notAdminChild">
        <div className="white heading">
          <div style={{height:"3vh"}}></div>
           Your STEP License 
           <div className="form-row" style={{justifyContent:"flex-end", alignItems:"flex-end"}}>
                  <div 
                  // onClick={() => copyLicense(license?.number)} 
                  style={{border:copied == "true" ? ".5vmin solid #6db240" : copied == "false"  ? ".5vmin solid #EF1111" : ".3vmin solid grey", color:"#6db240" ,cursor:"pointer", padding:"0 2vmin", display:"flex",flexDirection:"column", justifyContent:"flex-end", alignItems:"center"}}>
                      <div>{license?.number}</div>
                      {/* <div style={{fontSize:"1.2vmin", color: copied == "true" ? "#6db240" : copied =="false" ? "#EF1111" : "#5C92BF", display:"flex", alignItems:"center"}}><FontAwesomeIcon style={{fontSize:"1.2vmin", marginRight:".6vmin"}} icon={copied == "true" ? faCheck : copied == "false" ? faTimes : faClipboard} /> {copied == "true" ? "Copied" : copied == "false" ? "failed to copy" : "copy to clipboard"} </div> */}
                  </div>
              </div> 
              has Expired!
              Thank you!
          <div style={{display:"flex", height:"30%", justifyContent:"center", alignItems:"flex-end"}}>
              <div
                className="auth-button addUser bigButton"
                onClick={() => {
                  setProceed(true)
                }}
                >
                Proceed
              </div>
          </div>
        </div>
        </div>
      </div>
      </div>
      )
    }
  }
}

    return (
        <> 
           {/* {temporaryMessage?.length ? <div onClick={() => setTemporaryMessage(null)} style={{height:"12%", width:"100%", display:"flex", alignItems:"center", justifyContent:"center", position:"fixed", top:0, right:0, left:0, bottom:0, zIndex:0}}>

             <TextLoop
                springConfig={{ stiffness: 70, damping: 31 }}
                adjustingSpeed={500}
            >
                {temporaryMessage?.map(x => (
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                          <duv style={{color:"#EF1111"}}>You have got a new notification</duv>

                    <span key={x._id} style={{color:"#B9B8BC", fontSize:"3vmin"}}>
                      {x.message}
                      </span></div>
                ))} 
            </TextLoop> 
            </div> : null} */}

      <ConfirmDeleteModal 
        deleteModal={deleteTestModal} 
        setDeleteModal={setDeleteTestModal}
        deleteType={deleteType}
        setDeleteType={setDeleteType}
        deleteTestFunc={deleteTestFunc}
      />      
      {userAuthenticated && Object.keys(selectedTests).length && Object.values(selectedTests).some(x => x !== null) ? <div className="minimizedContainer">
        <div className="minimizedContent">
            {Object.entries(selectedTests)?.filter(([key, y]) => y !== null).map(([key, x], i) => (
              <div key={i}> 
                <UncontrolledTooltip
                style={{ minWidth:"300px", height:"300px", opacity:1, background:"gray", borderRadius:"8px", border:"1px solid gray", padding:0}}
                placement="top"
                target={`UncontrolledTooltipExample_${key}`}
              >
                <AuditCard hoverCard={true} user={user} editAudit={() => null} updateProjectStatusFunc={() => null} audit={projectData?.find(x => x.defaultView == key)} subScreen={() => {
                history.push("/admin/audits")
                setOnFlyAudit({id: key}) 
              }}/>
              </UncontrolledTooltip> 
               <div id={`UncontrolledTooltipExample_${key}`} onClick={() => {
                history.push("/admin/audits") 
                setOnFlyAudit({id: key})
              }} style={{cursor: projectData ? "pointer" : "auto", position:"relative"}} className={`minimizedTest ${selectedAudit?.defaultView == key ? "activeMiniTest" : ""}`}>
                {x?.filter(l => !l.extra).map((el, i, a) => (
                    el.new && <div key={i} style={{transform: `rotate(${180 - ((180)/a.length*(i + 1))}deg) translateY(30px)`, background: el.passed ? "#6DB240" : "#EF1111", borderRadius:"50%", width:"10px", height:"10px", position:"absolute"}}>
                    </div>
                  ))}
                <div style={{position:"absolute",top:0, right:0}}>                  
                  <img 
                    className="testDelete"
                    onClick={(e) => {
                      e.stopPropagation()
                      setDeleteType("test")
                      setDeleteTestModal(key)
                    }}
                    style={{cursor:"pointer"}}
                    height={"21"}
                    src={archive} 
                  />
                </div>
                {projectData ? `${i + 1}` : <DataSpinner small="s" color="gray" />}</div>
              </div>
            ))}
        </div>  
      </div> : null}
      {userAuthenticated && (  
        <>
        {infoActive ? (
        <div onClick={() => dispatch(ACTION_INFO_ACTIVE(false))} style={{ height:"100vh", width:"100vw", position:"fixed", display:"flex", justifyContent:"center", alignItems:"center", overflowY:"auto", zIndex:999999999, background:"#ffffffa8"}}>
          <div onClick={(e) => {
            e.stopPropagation()
          }} style={{height:"fit-content", maxHeight:"90%", width:"70%", borderRadius:"8px", fontSize:"4vmin",padding:"1.5rem 3rem", background:"#5C92BF", overflowY:"auto", display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column", color:"white"}}>
            <div style={{width:"80%", height:"70%", display:"flex", overflowX:"auto", justifyContent:"center", alignItems:"flex-start"}}>{infoActive}</div>
            <div style={{height:"20%"}}>
              <div
            className="auth-button addUser secondaryBlue"
            onClick={() => {
              dispatch(ACTION_INFO_ACTIVE(false)) 
            }}  
            >
            Close
            </div> 
            </div> 
          </div> 
        </div>
      ) : null}
      <AdminSideBar setSelectedAudit={setSelectedAudit} setOnFlyAudit={setOnFlyAudit} path={path} user={user}/>
      { !activeSideBar && <div className="toggleMenuSideBarContainer">
        <div className="toggleMenuSideBar">
            <FontAwesomeIcon onClick={() => dispatch(ACTION_ACTIVE_SIDE_BAR())} style={{fontSize:"30px", color:"white", marginLeft:".2rem"}} className="toggleMenuSideBarIcon" icon={faArrowCircleRight} />
        </div>
      </div> } 
      </>
      )}

      <div className="adminContainer">
      <DoneModal 
        open={doneModal}
        setOpen={setDoneModal}
        />
        {changePasswordModal && (
          <ChangePasswordModal 
          open={changePasswordModal}
          setOpen={setChangePasswordModal}
          changePassword={changePassword}
          setOldPassword={setOldPassword}
          oldPassword={oldPassword}
          passwordError={passwordError}
setPasswordError={setPasswordError}
          />
        )}
      {logoutMenu && <div onClick={() => setLogoutMenu(false)} className="logoutMenuContainer">
            <div className="logoutMenu">
              <div className="menuHeader">Safe Space Ingenuity</div>
              <div className="menuItems">
                  <div className="menuItem flexItem" onClick={() => {
                dispatch(ACTION_LOADING_SPINNER_ACTIVE())
                history.push({
                    pathname:`/admin/${user?.user?.role == "admin" ? "admins"  : user?.user?.role !== "client" ? "users" : ""}`,
                    state: {
                        license: true
                    }
                })
              }}><span>{user?.user?.name}</span><span><div className="userImage">
                    {user && <img
                    className="adminAvatar"
                    src={user?.user?.image ? user?.user?.image : blank}
                    alt={
                      user?.user?.name[0]
                    }
                  /> }
                    </div></span></div>
                  <div className="horizontalSeparatorMenu"></div>
                  {user?.user?.role !== "admin" && <div onClick={() => {
            history.push({
              pathname: "/bot-3000e",
              state: {
                user: user?.user,
                route: location.pathname
              }
            })
          }} className="menuItem">BOT-3000E</div>}
                  {user?.user?.role !== "admin" && <div className="menuItem" onClick={() => {
                    window.open("https://cdn.shopify.com/s/files/1/0019/0123/2185/files/ANSI_A326.3_2021_February_2022_Locked.pdf?v=1644874339", "_blank")
                  }}>ANSI A326.3 Standard-2021</div>}
                  {user?.user?.role !== "admin" && <div onClick={ () => {
                    history.push({
                      pathname: "/help-center",
                      state: {
                        user: user?.user,
                        route: location.pathname
                      }
                    })
                  }
                  } className="menuItem">Help Center</div>}


{user?.user?.role !== "admin" && <div onClick={ () => {
                    history.push({
                      pathname: "/store",
                      state: {
                        user: user?.user,
                        route: location.pathname,
                        license: user?.user?.licenseNumber ?  JSON.parse(user?.user?.licenseNumber) : null

                      }
                    })
                  }
                  } className="menuItem">STEP Store</div>}


                  <div className="menuItem" onClick={() => setChangePasswordModal(true)} >Change Password</div>
                  {user?.user?.role !== "admin" &&<div className="menuItem" onClick={() => {
                    history.push({
                      pathname: "/contact-us",
                      state: {
                        user: user?.user,
                        route: location.pathname
                      }
                    })
                  }} >Report a Problem</div>}
                  <div className="menuItem" onClick={() => setLogoutModal(true)}>Log Out</div>
              </div> 
            </div>
      </div>}
      {notificationMenu && <div onClick={() => setNotificationMenu(false)} className="logoutMenuContainer">
            <div className="logoutMenu notificaitonM">
              <div className="menuHeader">Notifications</div>
              {messages?.length ? <div className="menuItems">
                  {messages.map((x, i) => (
                    <div key={i} style={{fontSize:"18px", color:"white", margin:"10px 0", padding:"5px 10px", background: x.new ? "#ffffff40" : "transparent"}}>
                      {x.message}
                      <div style={{fontSize:"12px", color:"white"}}>
                        {moment.unix(x.createdAt/1000).format("ddd MM/DD/YYYY HH:mm A")}
                      </div>
                    </div>
                  ))}
                 </div> : <div className="menuItem flexItem">
No New Notifications
</div> }
            </div>
      </div>}
      {userAuthenticated && (
        <>
      <Header reportsRefetch={reportsRefetch} setActiveView={setActiveView} activeView={activeView} messages={messages} notificationMenu={notificationMenu}
setNotificationMenu={setNotificationMenu} logoutModal={logoutModal} setLogoutModal={setLogoutModal} setLogoutMenu={setLogoutMenu} handleLogout={handleLogout} selectedUser={selectedUser} setSelectedUser={setSelectedUser} user={user} />
      </>
      )}
      <Switch>
        <Route
          exact
          path={path}
          render={() => (
            <AdminSignin socket={socket} />
          )}
        />
        <Route
          exact
          path={path + "/dashboard"}
          render={() => (
            <AdminDashboard socket={socket} sites={sites} clients={clients} usersRefetch={accountsRefetch} auditAssignOptions={auditAssignOptions} licenses={licenses} licensesRefetch={licensesRefetch} defaultDCOF={defaultDCOF} reports={reports} audits={projects} users={users} user={user} />
          )}
        />
        <Route
          exact
          path={path + "/admins"}
          render={() => (
            <Admins socket={socket} licensesRefetch={licensesRefetch}  user={user} users={users} adminsRefetch={accountsRefetch} admins={admins} />

          )}
        />
        <Route
          exact
          path={path + "/users"}
          render={() => (
            <AdminUsers socket={socket} currentPageReports={currentPageReports} setCurrentPageReports={setCurrentPageReports} subOwner={subOwner} setSubOwner={setSubOwner} reportLoading={reportLoading} reportsData={reports} reportsRefetch={reportsRefetch} selectedUser={selectedUser} setSelectedUser={setSelectedUser} licensesRefetch={licensesRefetch} auditAssignOptions={auditAssignOptions} usersRefetch={accountsRefetch} users={users} user={user} />
          )}
        />
        <Route
          exact
          path={path + "/clients"}
          render={() => (
            <AdminClients socket={socket} allSitesRefetchDashboard={allSitesRefetchDashboard} user={user} clientsRefetch={clientsRefetch} clients={clients} />
          )}
        />
        <Route
          exact
          path={path + "/audits"}
          render={() => (
            <AdminProjects
            currentPageAudits={currentPageAudits}
            setCurrentPageAudits={setCurrentPageAudits}
            socket={socket}
            usersRefetch={accountsRefetch}
            clientsRefetch={clientsRefetch}
            areas={areas}
            setAreas={setAreas}
            allCompaniesFilter={allCompaniesFilter}
            setAllCompanies={setAllCompanies}
            allSitesFilter={allSitesFilter}
            setAllSites={setAllSites}
            allAdminsfilter={allAdminsfilter}
            setAllAdmins={setAllAdmins}
            projectsFilter={projectsFilter}
            addLocationLoad={addLocationLoad}
setAddLocationLoad={setAddLocationLoad}
 setProjectsFilter={setProjectsFilter} projectData={projectData} setProjectData={setProjectData} onFlyAudit={onFlyAudit} setOnFlyAudit={setOnFlyAudit} selectedAudit={selectedAudit} setSelectedAudit={setSelectedAudit} defaultDCOF={defaultDCOF} auditClientsOptions={clients} auditAssignOptions={auditAssignOptions} user={user} projects={projects} projectsCalled={projectsCalled} projectsLoading={projectsLoading} projectsRefetch={projectsRefetch}  />
          )}
        />


<Route
          exact
          path={path + "/pendulum"}
          render={() => (
            <AdminPendulum
            currentPageAudits={currentPageAudits}
            setCurrentPageAudits={setCurrentPageAudits}
            socket={socket}
            usersRefetch={accountsRefetch}
            clientsRefetch={clientsRefetch}
            areas={areas}
            setAreas={setAreas}
            allCompaniesFilter={allCompaniesFilter}
            setAllCompanies={setAllCompanies}
            allSitesFilter={allSitesFilter}
            setAllSites={setAllSites}
            allAdminsfilter={allAdminsfilter}
            setAllAdmins={setAllAdmins}
            projectsFilter={projectsFilter}
            addLocationLoad={addLocationLoad}
            setAddLocationLoad={setAddLocationLoad}
            setProjectsFilter={setProjectsFilter} 
            projectData={projectData} 
            setProjectData={setProjectData} 
            onFlyAudit={onFlyAudit} 
            setOnFlyAudit={setOnFlyAudit} 
            selectedAudit={selectedAudit} 
            setSelectedAudit={setSelectedAudit}
            defaultDCOF={defaultDCOF}
            auditClientsOptions={clients}
            auditAssignOptions={auditAssignOptions}
            user={user}
            projects={projects}
            projectsCalled={projectsCalled}
            projectsLoading={projectsLoading}
            projectsRefetch={projectsRefetch}
   />
          )}
        />


         <Route
          exact
          path={path + "/insights"}
          render={() => (
            <AdminInsights
            currentPageAudits={currentPageAudits}
            setCurrentPageAudits={setCurrentPageAudits}
            socket={socket}
            usersRefetch={accountsRefetch}
            clientsRefetch={clientsRefetch}
            areas={areas}
            setAreas={setAreas}
            allCompaniesFilter={allCompaniesFilter}
            setAllCompanies={setAllCompanies}
            allSitesFilter={allSitesFilter}
            setAllSites={setAllSites}
            allAdminsfilter={allAdminsfilter}
            setAllAdmins={setAllAdmins}
            projectsFilter={projectsFilter}
            addLocationLoad={addLocationLoad}
            setAddLocationLoad={setAddLocationLoad}
            setProjectsFilter={setProjectsFilter} projectData={projectData} setProjectData={setProjectData} onFlyAudit={onFlyAudit} setOnFlyAudit={setOnFlyAudit} selectedAudit={selectedAudit} setSelectedAudit={setSelectedAudit} defaultDCOF={defaultDCOF} auditClientsOptions={clients} auditAssignOptions={auditAssignOptions} user={user} projects={projects} projectsCalled={projectsCalled} projectsLoading={projectsLoading} projectsRefetch={projectsRefetch}  />
          )}
        />

<Route
          exact
          path={path + "/insights/viewer"}
          render={() => (
            <InsightsViewer />
            
          )}
        />


        <Route
          exact
          path={path + "/licenses"}
          render={() => (
            <AdminLicenses socket={socket} users={users} usersRefetch={accountsRefetch} auditAssignOptions={auditAssignOptions} user={user} licenses={licenses} licensesRefetch={licensesRefetch} />
          )}
        />
        <Route
          exact
          path={path + "/reports"}
          render={() => (
            <AdminReports
            activeView={activeView}
setActiveView={setActiveView}
            socket={socket} activeType={activeType} setActiveType={setActiveType} currentPageReports={currentPageReports} setCurrentPageReports={setCurrentPageReports} reportsCalled={reportsCalled} users={users} reportLoading={reportLoading} defaultDCOF={defaultDCOF} reports={reports} reportsRefetch={reportsRefetch} user={user} />
          )} 
        />
        <Route
          exact
          path={path + "/reports/templates"}
          render={() => (
            <ReportTemplates reportTemplates={templates} templatesRefetch={templatesRefetch} user={user} />
          )}
        /> 
        <Route
          exact
          path={path + "/reports/template"}
          render={() => (
            <ReportTemplate 
            templatesRefetch={templatesRefetch}
            reportsRefetch={reportsRefetch}
            reportLoading={reportLoading}
            user={user}
            />
          )}
        />
        <Route
          exact
          path={path + "/reports/download"}
          render={() => ( 
            <ReportTemplatePage1 user={user}/>
          )}
        /> 
        <Route
          exact
          path={path + "/reports/template/viewer"}
          render={() => (
            <TemplateViewer socket={socket} user={user} usersRefetch={accountsRefetch} userRefetch={userRefetch} licensesRefetch={licensesRefetch} reportsRefetch={reportsRefetch} />
          )}
        />
        {/* If no path matches, redirect to home */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      </div>

      </>
    )
}

export default AdminRouter