import { gql } from "apollo-boost";

const allProjectsQuery = gql`
    query(
    $site: ID
    $client: ID
    $_id: ID
    $limit: Int,
    $skip: Int
  ) { 
    allProjects (
      site: $site
      client: $client
      _id: $_id
      limit: $limit
      skip: $skip
    ) {
    _id
    details
    hidden
    count
    extra
    site {
      _id
      name
      address
    client
    contactPersonName,
    contactPersonEmail,
    contactPersonPhoneNumber
    status
    notes
    zip
    }
    client {
      _id
      companyName
      companyAddress
      contactPersonName
      contactPersonEmail
      contactPersonPhoneNumber
      status
      zip
    }
    status
    dateOfAudit
    dateOfTestingDiagram
    creatorOfTestingDiagram
    auditTrailOfTestingDiagram
    auditTrailOnChanges
    firstTime
    viewCreatedOn
    defaultView
    createdAt
    state
    areas
    views
    locations
    creator {
      _id
      name
      role
      adminType
    }
    assignee {
      _id
      name
      phoneNumber
      companyName
      role
      letter
      certificate
      certificateExpiry
    }
    tileResult {
      first
      firstAfter
      second
      secondAfter
      exist
      result
      pass
      passAfter
      dcof
    }
    comment
    }
  }
  
`;

export default allProjectsQuery;
