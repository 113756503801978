import { gql } from "apollo-boost";

const updateProjectStatusMutation = gql`
  mutation(
      $_id: ID
      $status: Boolean
      $hidden: Boolean
      ) {
    updateProjectStatus(
    _id: $_id
    status: $status
    hidden: $hidden
    ) {
      _id
      status
      hidden
    }
  }
`;

export default updateProjectStatusMutation;
