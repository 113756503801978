const INFO_ACTIVE = "INFO_ACTIVE";

const infoActiveReducer = (
  state = { infoActive: false },
  action
) => {
  switch (action.type) {
    case INFO_ACTIVE:
      return { ...state, infoActive: action.payload };
    default:
      return { ...state };
  }
};

export default infoActiveReducer;
