import { faTrashAlt, faPen, faEnvelope, faPhoneAlt, faPrint, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LZString from "lz-string"
import React, { useEffect, useState } from "react"
import ConfirmDeleteModal from "../modals/confirmDeleteModal"
import "./accountHeader.scss"
import blank from "../../../assests/images/blank.png"
import edit from "../../../assests/icons/edit.svg"
import phone from "../../../assests/icons/phone.svg"
import workBag from "../../../assests/icons/workBag.png"
import email from "../../../assests/icons/email.svg"
import archive from "../../../assests/icons/archive.svg"
import { DataSpinner } from "../spinner/dataSpinner"
import { UncontrolledTooltip } from "reactstrap"
 import JSZip from "jszip"
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"

import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET"

import { useDispatch } from "react-redux"

import { saveAs } from 'file-saver';

import { convertArrayToCSV } from'convert-array-to-csv';

import AWS from 'aws-sdk'


  const S3_BUCKET ='step-us-east-prod';
  const REGION ='us-east-1';
  
  AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS
  })
  
  const myBucket = new AWS.S3({ 
      params: { Bucket: S3_BUCKET},
      region: REGION,
  }) 
const AccountHeader = (props) => {

      const dispatch = useDispatch()
  
    const { 
      printMode,
      setPrintMode,
      printedAreas,
      setPrintedAreas,
        selectedAccount,
        type,
        updateUser,
        deleteUserFunc,
        deleteClientFunc,
        updateClient,
        resetUserPassword,
        editUser,
        user,
        deleteType,
        full,
        viewsLength,
        openHistoryModal, 
        addNewView,
        printModal,
        setPrintModal,
        buttonFunction
    } = props

    const [toggleChecked, setToggleChecked] = useState(true)
    const [deleteModal, setDeleteModal] = useState(null)

    let getPdfs = async (pdfUrls) => {
      let allPdfs = await Promise.all(pdfUrls.map(async (x,i, a) => {
        let newX = x
        let url = x.url
        const params = {
          Bucket: S3_BUCKET,  
          Key: url.slice(url.indexOf("amazonaws.com/") + 14 , url.length)
        }
        await new Promise((resolve, reject) => {
        myBucket.getObject(params) 
        .on("complete", async(evt) => {
          if(evt?.data?.Body) {
            //defaultDCOFBefore.html = Buffer.from(evt.data.Body).toString('utf8')
            let pdfArr = evt.data.Body
            let blobPdf = new Blob([pdfArr], {type: 'application/pdf'})
            if(blobPdf) {
             newX.pdf = blobPdf
             resolve()
            }
          }   
        }) 
        .send((err) => {
          if (err) console.log(err) 
      })})
      return newX
      }))
      return allPdfs
      
    }
    const downloadData = async (results) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())

      var zip = new JSZip();

      let allResults = results.map(x => x.results)?.flat()
      let allLines = allResults.map(x => x.results)?.flat()
      let allUrls = allResults.map(x => {
        return {
          url: x.pdfUrl,
          name: x.results[0].fileName
        }
      })
      let allPdfs = await getPdfs(allUrls)

      const csvFromArrayOfArrays = convertArrayToCSV(allLines, {
        header:[],        
        separator: ','
      });
      const csvBlob = new Blob([csvFromArrayOfArrays], {type: "text/plain;charset=utf-8"});

      zip.file("data.log", csvBlob)
      allPdfs.map(x => {
      zip.file(x.name, x.pdf, {base64:true})
      })
      zip.generateAsync({type:"blob"})
      .then(function(content) {
          saveAs(content, `${selectedAccount.name || "data"}.zip`);
          dispatch(ACTION_LOADING_SPINNER_RESET())
      });
    }


    return (
        <div className="detailsContent">
                {deleteType !== "Audit" && <ConfirmDeleteModal
                deleteType={deleteType}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                userType={type}
                deleteClientFunc={deleteClientFunc}
                deleteUserFunc={deleteUserFunc}
                />}
                <div className="contentLeft" style={{alignItems: type == "audit" ? "center" : "flex-start"}}>
                {!printMode && <div className={`contentAvatar ${selectedAccount && "selectedAccount"}`}>
                <img
                    className="adminAvatar"
                    src={selectedAccount.photo ? selectedAccount.photo : selectedAccount.image ?  selectedAccount.image : blank}
                    alt={
                      selectedAccount.name ? selectedAccount.name[0] : ""
                    }
                /> 
                </div>}
                {type == "client" ? (
                    <>
                    <div className="detailsAvatar detailsClients">
                        <div>
                            <div className="detailsName">
                            <div>{selectedAccount.name}</div>
                            </div>
                            <div className="detailsEmail">
                            <div>{selectedAccount.address}</div>

                            </div>

                        </div>

                    </div>
                    <div className="detailsAvatar detailsClients detailsSmaller">
                    <div className="detailsName">
                        <div>{selectedAccount.contact}</div>
                    </div> 
                    <div className="detailsEmail">
                    <div style={{display:"flex"}}><img  style={{marginRight:".5rem"}} src={email}/>{selectedAccount.email}</div>
                    </div>
                    <div className="detailsNumber">
                    <div style={{display:"flex"}}> <img  style={{marginRight:".5rem"}} src={phone}/> {selectedAccount.phone ? selectedAccount.phone : "not provided"} </div>
                    <div style={{display:"flex"}}> <img  style={{marginRight:".5rem"}} width={15} src={workBag}/> {selectedAccount.phoneWork ? selectedAccount.phoneWork : "not provided"} </div>
                    </div>
                  </div>
                  </>
                ) : type == "audit" ? !printMode ? (
                    <div style={{display:"flex"}} className="detailsAvatar">
                    <div className="detailsName auditField">
                    <div>{selectedAccount.name}</div>
                    <div style={{fontSize:"12px", fontWeight:"normal"}}>{selectedAccount.address}</div>
                    </div>
                    
                    <div className="detailsEmail auditField">
                        <div className="detailHeader">created by</div>
                        <div className="detailContent">{selectedAccount.createdBy} <span style={{fontWeight:"normal"}}>({selectedAccount.createdByRole})</span></div>
                    </div>
                    <div className="detailsNumber auditField">
                    <div className="detailHeader">created on</div>
                        <div className="detailContent">{selectedAccount.createdOn}</div>
                    </div>
                   { selectedAccount.assignTo && <div className="detailsNumber auditField">
                     <div className="detailHeader">assigned to</div>
                        <div className="detailContent">{selectedAccount.assignTo} <span  style={{fontWeight:"normal"}}>{selectedAccount?.assignToId == user?.user?._id ? `(you)` : `(${selectedAccount?.assignToRole})`} {selectedAccount.assignee?.length > 1 ? <div onClick={(e) => {
                        e.stopPropagation()
                        props.printType == "Area" ?  editUser(selectedAccount, "assign") : null
                    }} style={{color:"#5C92BF", cursor:"pointer", display:"inline-block"}}> {selectedAccount.assignee.length - 1} more users</div> : (
                      <div onClick={(e) => {
                        e.stopPropagation()
                        props.printType == "Area" ?  editUser(selectedAccount, "assign") : null
                    }} style={{color:"#5C92BF", cursor:"pointer", display:"inline-block"}}> <img height={20} src={edit}/></div>
                    )}</span></div>
                    </div> }
                  </div>
                ) : (
                  <div style={{fontWeight:"500", fontSize:"22px"}}>
                    {printedAreas?.length ? `${printedAreas.length} ${props.printType}${printedAreas.length > 1 ?"s":""} Selected` : `Select ${props.printType}s`}
                    {props.printType == "View" ? printedAreas.length &&  printedAreas.length < 2 ? <div style={{fontSize:"12px",color:"#EF1111"}}>2 minimum views are required</div> : null: null}
                  </div>
                ) : (
                <>
                <div className="detailsAvatar">
                  <div className="detailsName">
                      <div>{selectedAccount.name}</div>
                  </div>
                  
                  <div className="detailsEmail">
                    <div style={{display:"flex"}}><img  style={{marginRight:".5rem"}} src={email}/>{selectedAccount.email}</div>
                  </div>
                  <div className="detailsN umber">
                    <div style={{display:"flex"}}> <img  style={{marginRight:".5rem"}} src={phone}/> {selectedAccount.phone ? selectedAccount.phone : "not provided"} </div>
                  </div>
                </div>
                 {type == "user" ? (
                   user ? (
                     user?.user?.role !== "user" && (
                    <div className="detailsAvatar">
                    <div className="userType">
                    <div>{selectedAccount.userFranchisee ? "Franchisee" : "Non-Franchisee"}</div>
                    </div>
                    <div className="userType" style={{background:"#6DB240"}}>
                    <div style={{textTransform:"capitalize"}}>{selectedAccount?.role}</div>
                    </div>
                    </div>
                     )  
                   ) : null
                   ) : null}
                </>
                )}                 
                
                </div>
                <div className="contentRight">
                    {type == "user" ? (
                      user?.user?.role == "admin" && (
                        <div className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          marginRight: "1rem",
                          fontSize:"14",
                          fontWeight:"bold",
                          backgroundColor:"transparent",
                          color:"#EF1111",
                      }} 
                      className="auth-button addUser deleteButton"
                      onClick={() => resetUserPassword(selectedAccount)}
                      >
                            Reset Password
                        </div>
                        </div>
                        )
                    ) : user?.user?.role == "admin" && ( selectedAccount?.type == "WMG Admin" || selectedAccount?.type == "Step Admin")  ? (
                       false && <div
                       onClick={() => updateUser(selectedAccount, true)}
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          width:"auto",
                          paddingLeft:"20px",
                          paddingRight:"20px",
                          marginLeft: "1rem",
                          fontSize:"14",
                          fontWeight:"bold",
                          padding:"0 2px",
                          margin:"0 10px"
                      }} 
                      className="auth-button addUser userActionsButtons"
                      >
                             {!selectedAccount.wmg ? "Add to" : "Remove from"} WMG Management
                        </div>
                    ) : null}
                  <div style={{display:"flex", flexDirection:"column", justifyContent:type !== "audit" ? "center" : "flex-start"}}>
                      <div style={{width:"100%", display:"flex", alignItems:"center"}}>
                        {type == "audit" && !printMode && props.printType == "Area" && ( 
                            <>
                          <div style={{marginRight:"1rem", display:"flex", alignItems:"center"}} className="detailHeader">{viewsLength || <DataSpinner small="sm" />} <span style={{marginLeft:".5rem", display:"inline-block"}}>View</span></div>
                          <div style={{marginRight:"1rem", backgroundColor: selectedAccount.auditStatus == "Scheduled" ? "#D89E42" : selectedAccount.auditStatus == "In progress" ? "#D4D243" : selectedAccount.auditStatus == "Finished - Approved" ? "#6DB240" : selectedAccount.auditStatus == "Finished - Rejected" ? "#EF1111" : selectedAccount.auditStatus == "Pending Approval" ? "#5C92BF" : "", width:"fit-content", }} className="audit-status">{selectedAccount.auditStatus}</div>
                            </>
                        )}
                          {type !== "audit" && <img style={{marginRight:"1rem", cursor:"pointer",
                          color:"#5C92BF"}} onClick={() => editUser(selectedAccount)} src={edit} /> }
                          {type == "audit" && !printMode ? props.printType == "Area" ?  <FontAwesomeIcon onClick={() => setPrintMode(true)} icon={faPrint} style={{ fontSize: "130%",marginRight:"1rem", cursor:"pointer", color:"#5C92BF", cursor:"pointer"}} /> : 
                           <div className="tableHeaderAdd">
                           <div
                       id="UncontrolledTooltipExample_print_111"

                           onClick={() => {
                            setPrintMode(true)
                           }}
                           style={{
                             color: "rgb(255, 255, 255)",
                             pointerEvents:  "auto",
                             background: "#6DB240",
                             
                             margin:"0 30px",
                             padding: "0 25px",
                             fontSize:"14",
                             fontWeight:"bold",
                             cursor:"pointer"
                         }} 
                         className={`auth-button addUser hoverGreen userActionsButtons ${ true ? "activeButtonToAction" : "disable-button" }`}>  
                              {"Insights"}
                           </div> 
                 </div> : null}
                          {printMode && (
                            <>
                            <div className="tableHeaderAdd">
                        <div
                        onClick={() => {
                          props.printType == "Area" ? printedAreas?.length && printedAreas.some(x => x.results?.length) ? downloadData(printedAreas.filter(x => x.results?.length)) : null : null
                        }} 
                        id="UncontrolledTooltipExample_print_112"
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          margin:"0 5px",
                          padding: "0 15px",
                          fontSize:"14",
                          fontWeight:"bold"
                      }} 
                      className={`auth-button addUser userActionsButtons ${ props.printType == "Area" ? printedAreas?.length && printedAreas.some(x => x.results?.length) ? "activeButtonToAction" :  "disable-button" : printedAreas?.length > 1 ?  "activeButtonToAction" : "disable-button"}`}>
                            {props.printType == "Area" ? "Export Data" : "Generate Report"}
                        </div>
                        {/* <div data-for={"main"}><UncontrolledTooltip
                          placement="top"
                          target={`UncontrolledTooltipExample_print_112`}
                          >
                            <span style={{color:"red"}}>{printedAreas.length && printedAreas.every(x => !x.results?.length) ? (
                              `No Data found within selected Area${printedAreas?.length > 1 ? "s" : ""}`
                            ) : ""}</span>
                            <br/>
                          Use this button to export audit data into a data.log file to review audit
                          pdf‘s, data, and site information
                          </UncontrolledTooltip></div> */}
                                        </div>
                          
                            <div className="tableHeaderAdd">
                            <div
                        id="UncontrolledTooltipExample_print_111"

                            onClick={() => {
                              props.printType == "Area" ?  printedAreas?.length ? setPrintModal(true) : null : printedAreas?.length > 1 ? buttonFunction() : null
                            }}
                            style={{
                              color: "rgb(255, 255, 255)",
                              pointerEvents:  "auto",
                              background: "#6DB240",
                              
                              margin:"0 30px",
                              padding: "0 25px",
                              fontSize:"14",
                              fontWeight:"bold",
                              cursor:"pointer"
                          }} 
                          className={`auth-button addUser hoverGreen userActionsButtons ${ printedAreas?.length > 1 ? "activeButtonToAction" : "disable-button" }`}>  
                               {props.printType == "Area" ?  "Print" : "View Insights"}
                            </div> 
                            {/* <div data-for={"main"}><UncontrolledTooltip
                          placement="top"
                          target={`UncontrolledTooltipExample_print_111`}
                          >
                          Use this button to print a pdf of area diagrams and audit information prior to collecting data
                          </UncontrolledTooltip></div> */}
                  </div>  </>
                          )}
                          {printMode && (
                            <FontAwesomeIcon
                            className="modal_x"
                            style={{position:"static", zIndex:0}}
                            icon={faTimes}
                            onClick={() => {
                              setPrintMode(false)  
                            }}
                          />
                          )}
                          {type !== "audit" ? !user ? <img  style={{marginRight:"1rem", cursor:"pointer", color:"#EF1111"}} onClick={() => setDeleteModal(selectedAccount)} src={archive} /> : !printMode && user && user?.user?._id !== selectedAccount.id ? (
                            <img  style={{marginRight:"1rem", cursor:"pointer"}} onClick={() => setDeleteModal(selectedAccount)} src={archive} />   
                          ) : null : null}
                          {!printMode && props.printType == "Area" && <label style={{cursor:"pointer"}} onClick={() => {
                              type == "client" ? updateClient(selectedAccount) : updateUser(selectedAccount)
                          }}
                          className="toggle-control">
                              <span className={`${selectedAccount.status ? "checked" : "notChecked"}`}></span>
                              <span className="control"></span>
                          </label>}
                      </div>
                    
                  </div>
                </div>
              </div>
    )
}

export default AccountHeader