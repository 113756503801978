import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SaveDraftModal = (props) => {
    const {
        areaId,
        draftModal,
        setDraftModal,
        setAreaModal,
        draftAreaFunc,
        noHandleClick,
        setAddAreaDropDown
        } = props

 
  return(
<Modal
        isOpen={draftModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal">
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setAddAreaDropDown(true)
                  setDraftModal(null)}
                }
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Close area modal</h1>
          <h1 className="header" style={{width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>{areaId ? "Do you want update this draft" : "Do you want to draft this area"} </h1>
            
            <div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>

                <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {                 
                noHandleClick()
                setDraftModal(null)
                }}
                >
                No - Cancel
                </div> 

                <div
                
                    className="auth-button addUser confirmDelete"
                    onClick={() => {
                        draftAreaFunc(areaId)
                        setAddAreaDropDown(true)
                        setDraftModal(null)
                    
                    }}
                >

                {areaId ? "Yes - Update!" : "Save as draft"}

                </div>

            </div>
        </div>
      </Modal>
  )}

  export default SaveDraftModal
       