import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import "./index.scss"
import { useDispatch } from "react-redux"
import { DataSpinner } from "../resuable/spinner/dataSpinner"
import { useLocation } from "react-router-dom"
import Faq from 'react-faq-component';

const HelpCenter = (props) => {
  const config = {
    animate: true,
    expandIcon: "+",
    collapseIcon: "-",
};

const location = useLocation();
const history = useHistory()
   
    const [user, setUser] = useState(null)
    const [route, setRoute] = useState(null)
    useEffect(() => {
      if(location) {
        if(location.state) {
          if(location?.state?.user) {
          setUser(location.state.user)
        }
        if(location?.state?.route) {
          setRoute(location.state.route)
        }
        } else [
          history.push("/admin")
        ]
      } 
      return () => window.history.replaceState({}, document.title)
    },[location])

    const dataLeft = [{
      title: "GENERAL",
      rows: [
        {
          title: "What does a wet DCOF test value of less than 0.42 indicate?",
          content: "If a walkway surface cannot meet the 0.42 threshold for wet testing, the test can be repeated in the dry mode. If the surface meets the 0.42 threshold in the dry mode that walkway must be kept dry to be considered slip resistant. If the walkway is exposed to involuntary wetness, a friction increasing treatment should be considered."
        },
        {
          title: "What should I do if the slip resistance value is below 0.42?",
          content: "If enhanced cleaning does not raise the DCOF test results to 0.42 or higher, call Walkway Management Group for a consultation for professional remediation treatments."
        },{
          title: "Is it necessary to perform a BOT-3000E system verification each time you go to a new test site?",
          content: "Yes. Regan Scientific Instruments strongly recommends device verification at each site to ensure proper tribometer results. A simple verification procedure is included in your BOT-3000E kit and takes only seconds to complete."
        }]
    },
    {
      title: "INDUSTRY STANDARDS",
      rows: [
        {
          title: "How do I decide where to test?",
          content: <div>ANSI A 326.3 calls for a series of tests in a specific location defined by its exposure to slip and fall hazards. A total of 12 data points is required to complete an ANSI A326.3 result. The BOT-3000E is programmed to take you through this test method step by step. <a style={{
            color:"#0d6efd",
            textDecoration: "underline"
          }} 
          target={"_blank"}
          href="https://www.tcnatile.com/images/pdfs/ANSI_A326.3_1-18.pdf">CLICK HERE</a></div>
        },
        {
          title: "Is it necessary to recondition the SBR Slider between each test sample?",
          content: "Yes, sensor preparation is critical to accurate results. A contaminated sensor will invalidate the test. A326.3 defines specific sensor preparation steps and your BOT-3000E kit will include all of the tools you need."
        },
        {
          title: "What is the difference between Dynamic Coeffi-cient of Friction (DCOF) and Static Coefficient of Friction (SCOF)?",
          content: "SCOF is the force required to start an object moving: DCOF is the force required to keep an object moving. Most major research shows that DCOF is the better indicator of slip resistance."
        }
        ,
        {
          title: "What is the difference between ANSI A137.1 and ANSI A326.3?",
          content: "ANSI A137.1 is a manufacturer's standard for ceramic tiles. ANSI A326.3 is a laboratory and field test method (dry or wet) for the DCOF of a pedestrian surface. ANSI A137.1 references the A326.3 method in its section on slip resistance."
        },
        {
          title: "What is the ANSI A326.3 sanding process?",
          content: <a 
          target={"_blank"}
          style={{
            color:"#0d6efd",
            textDecoration: "underline"
          }} href="https://vimeo.com/277666572">CLICK HERE</a>
        }]
    }]

    const dataRight = [{
                      title: "BOT-3000E",
                      rows: [
                        {
                          title: "BOT-3000E Tribometer Operating Manual",
                          content: "You can find the BOT-3000E Tribometer operating manual here."
                        },
                        {
                          title: "Which slip tests can the BOT-3000E perform?",
                          content: "The BOT-3000E is programmed to conduct either wet or dry A326.3 tests and create reports as defined in that standard. In the manual mode the BOT-3000E can be used for any other chosen method including SCOF and DCOF."
                        },
                        {
                          title: "How often must the BOT-3000E be calibrated? Who does the calibration?",
                          content: "The NIST traceable calibration for the BOT-3000E is required once every 12 months and must be completed at the manufacturer's facility."
                        },
                        {
                          title: "When do I change the travel distance on the BOT-3000E?",
                          content: "The BOT-3000E can test any distance between 4-36 inches. ANSI A326.3 calls for a test distance of 10 inches, well within the capability of the BOT-3000E."
                        },
                        {
                          title: "Does the BOT-3000E perform Dry testing?",
                          content: "Yes the BOT-3000E can test the same surface either dry or wet."
                        },
                        {
                          title: "Can I take the BOT-3000E on a commercial airline?",
                          content: "Yes the BOT-3000E can be taken on an airplane, but must be checked due to its size."
                        },
                        {
                          title: "What do the slip test results indicate?",
                          content: "The BOT-3000E slip test results are expressed on a linear scale of 0.01-1.00. This is a relative indicator of the slip resistance of the surface. The ANSI A326.3 standard has identified a DCOF measurement value of 0.42 as the threshold for adequate slip resistance for pedestrian walkways."
                      }]
                      },
                      {
                        title: "STEP APPLICATION",
                        rows: [
                          {
                            title: "Can I see a demo of the STEP application before purchasing it?",
                            content: "Yes, we can offer a demo of our STEP application or we can send you an overview video of the application Give us call to set-up a demo."
                          },
                          {
                            title: "How do I get access to the STEP application?",
                            content: "You can visit our online store to purchase the application license. The application is available for download on Google Play and the App Store."
                          }]
                      },
                      {
                        title: "UNT WALKWAY SAFETY CERTIFICATION COURSE",
                        rows: [
                          {
                            title: "How do I reset my account password?",
                            content: "Request a password reset by emailing the CLEAR help desk at clearhelp@unt.edu or call 940-565-2324."
                          }]
                      }]

    if(!user) {
      return <DataSpinner small="l" />
    }
    
    return (
        <div className="contactUs" style={{background:"white"}}>
            <div className="headerTitle" style={{color:"#1467b3"}}>
            <div style={{cursor:"pointer", display:"flex", alignItems:"center", width:"fit-content"}} onClick= { () => {                      
                    history.push(`${route || "/admin"}`)    
                    }}> 
                    <FontAwesomeIcon  
                        icon={faArrowLeft}
                        style={{marginRight: "1rem", fontSize:"1.8rem"}}
                    />
                    <span>{route ? "Back" : "Home"}</span>   
            </div> 
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div style={{width:"1100px"}}>
            <iframe width={"100%"} height={"500px"} src="https://www.youtube.com/embed/Txp07g45LRI" title="Introduction to Step App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </div>

            <div 
            className="contactUsContent"
            style={{display:"flex", justifyContent:"center", marginBottom:"200px"}}
            > 
              <div className="contactUsLeft" style={{width:"fit-content", height:"fit-content"}}>
                
              {dataLeft.map((x, i) => (
                <div style={{marginBottom:"90px", maxWidth:"500px"}} key={i}><Faq config={config} styles={{
                    titleTextColor: "#1467b3",
                    rowTitleColor: "#000"
                }}  data={x}/></div>
              ))}

              </div>

              <div className="contactUsRight" style={{width:"fit-content"}}>
              {dataRight.map((x, i) => (
                <div style={{marginBottom:"90px", maxWidth:"500px"}} key={i}><Faq  config={config} styles={{
                  titleTextColor: "#1467b3",
                  rowTitleColor: "#000",
              }} data={x}/></div>
              ))}
              </div> 
            </div>
        </div>
    )    
}

export default HelpCenter