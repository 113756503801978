import React, { useState } from 'react'
import Modal from "react-modal"
import "../../../../assests/styles/Modal.scss"
import {DataSpinner} from "../../spinner/dataSpinner"
import { FileUploader } from "react-drag-drop-files";
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'
import archive from "../../../../assests/icons/archive.svg"
import ConfirmDeleteModal from '../confirmDeleteModal'
import useAwsCredentials from '../../../hooks/useAws';


const UploadPhotoModal = (props) => {

  const { credentials, loading, error, fetchCredentials } = useAwsCredentials();  // Use the custom hook to fetch credentials


    const {
        active,
        setActive,
        photo,
        setPhoto,  
    } = props

    const [progress, setProgress] = useState(0)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(null) 
    const addPhotoToDiagram = () => {
      setActive(null)
    }
    const uploadFile = async (base64) => {
      const myBucket = await fetchCredentials()

      if(myBucket) {
      const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');

      // Getting the file type, ie: jpeg, png or gif
      const type = base64.split(';')[0].split('/')[1];
      const fileName = `${'imageUploadForAudit'}${global.Date.now()}.${type}`
      const params = {
        ACL: 'public-read',
          Body: base64Data,
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: fileName,
          ContentEncoding: 'base64', // required
          ContentType: `image/${type}` // required. Notice the back ticks
      };
      myBucket.putObject(params)
          .on('httpUploadProgress', (evt) => {
              setProgress(Math.round((evt.loaded / evt.total) * 100))
          })
          .on("complete", (evt) => {
            let file = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`
            setPhoto(null)
            setPhoto(file)
            setProgress(0)
          })
          .send((err) => {
              if (err) console.log(err)
          })
  } else {
    alert("Loading.")
    
  }
  }
    
const convertFiletoDataUrl = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.normalize());
  reader.onerror = error => reject(error);
});

let pdfImagesArr = []
const convertPdfPageToImage = (pdf, pageNumber, fileName, i) => new Promise(resolve => {
    pdf.getPage(pageNumber).then((page) => {
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d',{ willReadFrequently: true });
        const renderContext = { canvasContext: ctx, viewport: viewport };

            canvas.height = viewport.height;
            canvas.width = viewport.width;
            page.render(renderContext).promise.then(async () => {
            const dataUrl = canvas.toDataURL();
            uploadFile(dataUrl)             
        })
    });
})

const convertPdfFilesToImages = (files) => new Promise(resolve => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
  const worker = new pdfjsLib.PDFWorker();

  const promises = Array.from(Array(files.length), (_, index) => new Promise(resolve => {
      const file = files[index];

      if (file?.type === 'octet/stream') {
          convertFiletoDataUrl(file).then(dataUrl =>
              pdfjsLib.getDocument({ url: dataUrl, worker }).promise.then(pdf => {
                  const promises = Array.from(Array(pdf.numPages), (_, index, a) => new Promise(resolve => {
                    if(true) {
                      const pageNumber = index + 1;
                      index == 0 && convertPdfPageToImage(pdf, pageNumber, file.name, index)
                    } else {
                      setProgress(0)
                    }
                      
                  }));
                  Promise.all(promises)
              })
          );
      } else {
          resolve(file);
      }
  }).then(() => console.log("allDoneee")));

  Promise.all(promises).then(files => resolve(files.flat()));
});
    const convertuploadedPdf = async (file) => {
      
       setProgress(1)
      if(file.type.includes("image")) {
        convertFiletoDataUrl(file).then(dataUrl => {
             uploadFile(dataUrl)
        } 
        )
      } else if(file.type.includes("pdf")) {
        let blobBase = new Blob([file], {
          type: 'octet/stream'
        })
        convertPdfFilesToImages([blobBase])  
      }   
    } 


  return(
<Modal
        isOpen={active ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center", 
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { active && <div className="modal task-modal" style={{width:"30%", height: "fit-content", minHeight:"100%", justifyContent:"space-between"}}>
               
        {confirmDeleteModal ? (
          <ConfirmDeleteModal 
          deleteType={"Diagram"}
          deleteModal={confirmDeleteModal}
          setDeleteModal={setConfirmDeleteModal}
          userType={"Admin"}
          deleteUserFunc={() => {
            setPhoto(null)
            setActive(null)
          }}
          />
        ) : null}

          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>{active.includes("Edit") || active.includes("Assign") ? active : `Add ${active}`}</h1>
          <div className="form-group-container task-form" style={{height:"100%", margin:"50px 0", justifyContent:"center", overflowY:"auto"}}>           
          
          <div className="form-row" style={{marginBottom:"10px"}}> 

          { !photo && !progress ? <div className='pdfUpload' style={{opacity: 1, border:"0", height:"300px", width:"100%"}}>
          {!photo ? <div style={{width:"100%", height:"100%", border: "2px dashed gray", borderRadius:"8px", opacity:  1}}>
            {<FileUploader
              multiple={false}
              handleChange={(file) => convertuploadedPdf(file)}
              name="file"
              types={["PDF", "PNG", "JPG", "JPEG"]}  
            />}
        </div>  : (
          (
            <div style={{width:"100%", height:"100%", border: "2px dashed gray", borderRadius:"8px", opacity:  1, display:"flex", justifyContent:"center", alignItems:"center", fontSize:18, padding:"0 15px", fontWeight:"bold", textTransform:"capitalize", flexDirection:"column"}}> <div>Please upload a PDF containing your UNT Certificate</div> <div style={{color:"#89CFF0",cursor:"pointer"}} onClick={() => setPdfError(null)}> try again</div></div>
          )
        )}
            </div> : !progress ? (
              <div className='pdfUpload' id="imagesContainer"  style={{opacity: 1, overflow:"hidden", display:"flex", justifyContent:"center", alignItems:"center", border: "2px solid gray", height:"300px", width:"100%"}}>
                {/* <div style={{border:"1px solid gray", position:"relative", borderRadius:"8px", overflow:"hidden", margin:"10px"}}> */}
                <div style={{position:"relative"}} className={`image-item`}>
                <img 
                height={"250px"}
                width={"100%"}
                style={{objectFit:"contain"}}
                src={photo}
                className="domImgCert"
                />
                <div onClick={() => setConfirmDeleteModal("Diagram")} className="image-item__btn-wrapper"> 
                      <div >
                        <img src={archive} height={20} />
                      </div> 
                  </div>
                </div>
              </div>
            ) : ( 
              <div className='pdfUpload' style={{opacity: 1, overflow:"hidden", border: "2px solid gray", height:"300px", width:"100%"}}>
              <DataSpinner />
              </div>  
            )}

            {/* <AvatarModal
            progress={progress}
            setProgress={setProgress}
            images={untImage}
            setImages={setUntImage}                
            /> */}
            </div>
          </div>
          <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {                  
                   
                    setActive(null)
                    // setPhoto(null)
                }}
            >

            Cancel

            </div>
            <div
            style={{margin:".5rem"}}
              className={`${progress && progress !== 100 ? "auth-button addUser disable-button": photo ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={ progress && progress !== 100 ? null : photo ?  active.includes("Edit") ? null  : addPhotoToDiagram : null }
            >
              
              {progress && progress !== 100 ? <DataSpinner small="s" /> :active.includes("Edit") ? "Update" : "Add"}

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default UploadPhotoModal
       