import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import "./index.scss"
import Dropdown from "react-dropdown";
import ACTION_LOADING_SPINNER_ACTIVE from "../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import sendEmailMutation from "../../graphql/mutations/contactUs/sendEmailMutation"
import { useMutation } from "@apollo/client"
import { useDispatch } from "react-redux"
import { DataSpinner } from "../resuable/spinner/dataSpinner"
import { useLocation } from "react-router-dom"
import youtube from "../../assests/icons/youtube.png"
import facebook from "../../assests/icons/facebook.png"
import instagram from "../../assests/icons/instagram.png"
import linkedin from "../../assests/icons/linkedin.png"
const ContactUs = (props) => {
  
const location = useLocation();

const [userFirstName,setUserFirstName] = useState("")


const [userLastName,setUserLastName] = useState("")
  

const [userEmail,setUserEmail] = useState("")


const [purpose,setPurpose] = useState("")


const [floorType, setFloorType] = useState("")


const [message,setMessage] = useState("")

const [ 
  sendEmail,
  { data: sendEmailData },
] = useMutation(sendEmailMutation);

const dispatch = useDispatch()

const sendEmailFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  sendEmail({
    variables:{
      firstName: user?.name || userFirstName || "a Client" ,
      lastName: userLastName || "",
      email: user?.email || userEmail,
      purpose,
      floorType,
      message,
    }
  }).then((res) => {
    setUserFirstName("")
    setUserLastName("")
    setUserEmail("")
    setPurpose("")
    setFloorType("")
    setMessage("")
    history.push(`${route || "/admin"}`)    
  })
}
    const history = useHistory()
    const [user, setUser] = useState(null)
    const [route, setRoute] = useState(null)
    
    useEffect(() => {
      if(location) {
        if(location.state) {
          if(location?.state?.user) {
          setUser(location.state.user)
        }
        if(location?.state?.route) {
          setRoute(location.state.route)
        }
        } else [
          history.push("/admin")
        ]
      } 
      return () => window.history.replaceState({}, document.title)
    },[location])

    const [emailError, setEmailError] = useState(false)

    let validateEmail = (input) => {

      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
     
      if (input.match(validRegex)) {
        setEmailError(false)
      } else { 
        setEmailError(true)
      } 
    
    }

    if(!user) {
      return <DataSpinner small="l" />
    }
    
    return (
        <div className="contactUs">
            <div className="headerTitle" style={{color:"#93CBEE"}}>
            <div style={{cursor:"pointer", display:"flex", alignItems:"center", width:"fit-content"}} onClick= { () => {                      
                    history.push(`${route || "/admin"}`)    
                    }}> 
                    <FontAwesomeIcon  
                        icon={faArrowLeft}
                        style={{marginRight: "1rem", fontSize:"1.8rem"}}
                    />
                    <span>{route ? "Back" : "Home"}</span>
            </div> 
            </div>
            <div
            className="contactUsContent"
            >
            <div className="contactUsLeft">
                <div className="leftHeader">Contact us for Slip Resistance Testing</div> 
                <div className="leftSubHeader" style={{marginBottom:".5rem"}}>
                B.Vassallo@SafeSpaceIngenuity.com
                </div>
                <div className="leftSubHeader" style={{marginBottom:"2rem"}}>
                516-672-4451
                </div> 
                <div className="leftSubHeader" style={{marginBottom:"2rem"}}>
                    {/* 51, Second Street, Lynbrook, New York, 11563, USA */}
                </div>
                <div className="leftIcons">
                    <div className="innerIcon fb" onClick={() => window.open("https://www.facebook.com/safespaceingenuityinc/", "_blank")}>
                      <img src={facebook} width="20" />
                    </div>
                    <div className="innerIcon ins" onClick={() => window.open("https://www.instagram.com/safespaceingenuity/", "_blank")}>
                      <img src={instagram} width="20" />
                    </div>
                    <div className="innerIcon lin" onClick={() => window.open("https://www.linkedin.com/company/2940520/admin/", "_blank")}>
                      <img src={linkedin} width="20" />
                    </div>
                    <div className="innerIcon yout" onClick={() => window.open("https://www.youtube.com/@safespaceingenuity2700", "_blank")}>
                      <img src={youtube} width="20" />
                    </div>
                </div>
            </div>
            {/* <div className='verticalSeparator' style={{borderRight:"1px solid white"}}/> */}
            <div className="contactUsRight">
                
            {user == "home" ? <><div className="form-row" style={{margin:"0 2rem 2rem 2rem"}}>
            <div className="form-collection">
              <div className="form-label grayLabel"><span>First Name<span style={{color:"red"}}> *</span></span></div>
              <input
                onChange={(e) => setUserFirstName(e.target.value)}
                value={userFirstName}
                type="text"
                placeholder={`Your First Name`}
                className="form-input whiteInput"
              />
            </div>    
            <div className="form-collection">
              <div className="form-label grayLabel"><span>Last Name<span style={{color:"red"}}> *</span></span></div>
              <input
                onChange={(e) => setUserLastName(e.target.value)}
                value={userLastName}
                type="text"
                placeholder={`Your Last Name`}
                className="form-input whiteInput"
              />
            </div>
            </div>
            <div className="form-row" style={{margin:"2rem"}}>
            <div className="form-collection">
              <div className="form-label grayLabel"><span>Email<span style={{color:"red"}}> *</span></span>
              {emailError && (
                <div className="auth-error" style={{textTransform:"none"}}>
                  Please enter a valid email address.
                </div>
              )}
              </div>
              <input
                onChange={(e) => {
                  
                  setUserEmail(e.target.value.toLowerCase())

                }}
                onBlur={() => validateEmail(userEmail)}
                value={userEmail}
                type="text"
                placeholder={`Your Email`}
                className="form-input whiteInput"
              />
            </div>
            </div></> : null}  
            <div className="form-row" style={{margin:"2rem"}}>
            <div className="form-collection">
            <div className="form-label grayLabel"><span>Purpose of Inquiry</span></div>
                <Dropdown
                  options={[
                    "Slip Resistance Testing (Field Testing)",
                    "Slip Resistance Testing (Lab Testing)",
                    "Traction-Enhancing Treatments",
                    "Step Application",
                    "AIA CEU Course",
                    "Support",
                    "Other",
                  ]}
                  onChange={(choice) =>
                    setPurpose(choice.value)
                  }
                  controlClassName="form-dropdown whiteInput" 
                  value={purpose}
                />
                </div>
            <div className="form-collection">
            <div className="form-label grayLabel"><span>Floor Type</span></div>
                <Dropdown
                  options={[
                    "Ceramic", "Concrete", "Granite", "Limestone", "Linoleum", "Luxury Vinyl Tile (LVT)", "Marble", "Mosaic", "Porcelain", "Quarry", "Rubber", "Sandstone", "Slate", "Terrazzo", "Terracotta", "Travertine", "Vinyl Composite Tile (VCT)", "Wood", "Other"
                  ]}
                  onChange={(choice) =>
                    setFloorType(choice.value)
                  }
                  controlClassName="form-dropdown whiteInput" 
                  value={floorType}
                />
                </div>
            </div>
            <div className="form-row" style={{margin:"2rem"}}>
            <div className="form-collection textarea" style={{width:"100%"}}>
            <div className="form-label grayLabel"><span>Message<span style={{color:"red"}}> *</span></span></div>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                type="text"
                placeholder="Your Message"
                style={{padding: "10px"}}
                className="form-input whiteInput textarea-input"
              />
          </div>
          </div>
          <div className="form-row" style={{margin:"2rem"}}>
            <div className="form-collection" style={{display:"flex", justifyContent:"flex-end"}}> 
                              <div
            className={`${(user.name || (userFirstName && userLastName)) && (user.email || (userEmail && !emailError)) && message ? "auth-button addUser" : "auth-button addUser disable-button"}`}
            onClick={() => {
              (user.name || (userFirstName && userLastName)) && (user.email || (userEmail && !emailError)) && message ? sendEmailFunc() : null
              
            }}  
            >
            Send
            </div>
            </div> 
            </div>
            </div>
            </div>
        </div>
    )    
}

export default ContactUs