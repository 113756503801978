import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_SELECTED_TESTS from '../../../../actions/SelectedTests/ACTION_SELECTED_TESTS';

const WrongValidateModal = (props) => {
    const {
        open,
        setOpen,
        onContinue,
        resetActiveTest
        } = props

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"9999999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal" style={{height:"fit-content"}}>
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  resetActiveTest()
                }}
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Validation failed</h1>
          <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"16px"}}>
          {open?.length && open.map((x, i) => (
            <span key={i} style={{margin:"15px 0"}}>
              {x}
            </span>
          )) }
          </div>
            
            {(<div style={{display:"flex", flexDirection:"column-reverse", justifyContent: "space-between", width:"100%"}}>

                <div
                
                className="auth-button addUser confirmDelete deleteButton"
                style={{margin:0, marginBottom:"10px"}}
                onClick={() => {
                  resetActiveTest() 
                }} 
                >
                Cancel
                </div> 

                {/* <div 
                
                    className="auth-button addUser confirmDelete"
                    style={{margin:0, marginBottom:"10px"}}
                    onClick={() => {
                      onContinue()                  
                    }}
                >

                Continue

                </div> */}

            </div>)}
        </div>
      </Modal>
  )}

  export default WrongValidateModal
       