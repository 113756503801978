import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LZString from "lz-string"
const ShowImageModal = (props) => {
    const {
        openImageModal,
        setOpenImageModal
        } = props


  return(
<Modal
        isOpen={openImageModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal  task-modal" style={{height:"100vh", width:"fit-content"}}>
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => setOpenImageModal(null)}
              />            
            <div style={{display:"flex" , justifyContent: "space-between", height:"100%"}}>
                <img height={"100%"} src={openImageModal} />
            </div>
        </div>
      </Modal>
  )}

  export default ShowImageModal
       