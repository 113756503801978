import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import html2canvas from "html2canvas"
import { DataSpinner } from '../../spinner/dataSpinner';

const ConfirmDeleteModal = (props) => {
    const {
        deleteModal,
        setDeleteModal,
        deleteType,
        userType,
        deleteClientFunc,
        deleteUserFunc,
        deleteViewFunc,
        deleteTestFunc,
        deleteLocationFunc,
        setLocationModal,
        setPhoto,
        loadingCanvas,
setLoadingCanvas,
        } = props

  return(
<Modal
        isOpen={deleteModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal">
               {loadingCanvas ? <DataSpinner over /> : null}

              <FontAwesomeIcon
                className="modal_x" 
                icon={faTimes}
                onClick={() => {
                  if(deleteType == "location") {
                     setLocationModal(null)
                     setPhoto(null)
                  }
                  setDeleteModal(null) 
                }}
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px", justifyContent:"flex-start"}}>Delete <span style={{textTransform:"capitalize"}}>&nbsp;{deleteType}</span></h1>
          <h1 className="header" style={{width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>Are you sure you want to delete {!deleteType?.includes("location - ") ? "this" : ""} {deleteType}?</h1>
          {deleteType == "view" && <div className='label-statement'>Audit return back to it's original data once you proceed</div>}
            
            <div style={{display:"flex" , justifyContent: "space-between", width:"100%"}}>

                <div
                className="auth-button addUser confirmDelete"
                onClick={() => {
                  if(deleteType == "location") {
                    setLocationModal(null)
                    setPhoto(null)
                 }
                 setDeleteModal(null)
                }}
                >
                Cancel
                </div> 

                <div
                
                    className="auth-button addUser confirmDelete deleteButton"
                    onClick={userType == "client" ? () => {
                        deleteClientFunc(deleteModal)
                        setDeleteModal(null)   
                    }: deleteType == "view" ? () => { 
                      deleteViewFunc(deleteModal)
                      setDeleteModal(null)
                    } : deleteType == "test" ? () => {
                      deleteTestFunc(deleteModal) 
                      setDeleteModal(null) 
                    } : deleteType == "location" ? () => {
                      setLoadingCanvas(true)

                      html2canvas(document.getElementById("stageImage"), {useCORS:true,}).then(canvas => {
                        let dataURL = canvas.toDataURL()
                        deleteLocationFunc(dataURL)
                        setLoadingCanvas(false)   

                    })

                      
                      setDeleteModal(null)
                  } : deleteType == "shape" || deleteType?.includes("location - ") ? () => {
                    deleteUserFunc(deleteModal) 
                    setDeleteModal(null)
                  } : () => {
                        deleteUserFunc(deleteModal)
                        setDeleteModal(null)
                    }}
                >

                Delete

                </div>

            </div>
        </div>
      </Modal>
  )}

  export default ConfirmDeleteModal
       