import { gql } from "apollo-boost";

const allLocationsQuery = gql`
  
    query(
    $view: ID
  ) { 
    allLocations (
      view: $view
    ) {
    _id   
    area
    view
    pass
    readings
    condition
    report
    coordinates
    location
    label
    }
  }
  
`;

export default allLocationsQuery;
