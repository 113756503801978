import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const SuggestModal = (props) => {
    const {
        open,
        setOpen,
        confirmNewAuditViewWithLocation,
        rejectFile,
        resetActiveTest,
        auditDate,
        areaLocation,
setAreaLocation,
        } = props

        let validateDate = (date) => {
          if(date) {
          let rightDate = date.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g)
          if(rightDate?.length) {
            return rightDate[0]
          } else {
            return null
          }
        } else {
          return null
        }
        }
 
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw", 
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal password-modal" style={{height:"fit-content", width: "40vw"}}>
              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  open == "manual" ? rejectFile() : resetActiveTest()
                  setOpen(null)
                }}
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Audit Data Outside of Time Criteria</h1>

          {auditDate && <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span> {moment(validateDate(auditDate)).format("MM/DD/YYYY")} </div>}
          <div className="label-statement" style={{display:"flex", flexDirection:"column", width:"100%", textTransform:"none" , fontWeight:"bolder", fontSize:"17px"}}>
          <span style={{margin:"15px 0", textTransform:"none"}}>The data that you trying to upload exceeds the 48-hour time availability for testing; It cannot be included in the same report.</span>

          <span style={{margin:"15px 0", textTransform:"none"}}>Press "Create New Audit" if you want to place this data {areaLocation ? `(${areaLocation})` : null} into a new Audit with the same information (Client, Site, etc.) as this existing audit.</span>            

                <div
                className="auth-button addUser confirmDelete"
                style={{margin:0, marginBottom:"10px"}}
                onClick={() => {                 
                    setOpen(null)
                    confirmNewAuditViewWithLocation(open == "manual" ? "manual" : "live")
                }} 
                >
                Create New Audit
                </div> 

          <span style={{margin:"15px 0", textTransform:"none"}}>Press "Re-upload" to upload slip testing data wihtin the same time criteria</span>  

      <div
      
      className="auth-button addUser confirmDelete deleteButton"
      style={{margin:0, marginBottom:"10px"}}
      onClick={() => {                 
        open == "manual" ? rejectFile() : resetActiveTest()
        setOpen(null)
      }} 
      >
      Re-Upload 
      </div> 
      </div>
       </div>
      </Modal>
  )}

  export default SuggestModal
       