import React, { useCallback, useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
// import CanvasDraw from 'react-canvas-draw';
import Dropdown from "react-dropdown";
import { DataSpinner } from '../../spinner/dataSpinner';
import bluetooth from "../../../../assests/icons/bluetooth.png"
import ACTION_SELECTED_TESTS from "../../../../actions/SelectedTests/ACTION_SELECTED_TESTS";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  Item,
  Separator,
  Submenu, 
  useContextMenu
} from "react-contexify";
  
import "react-contexify/dist/ReactContexify.css"; 
import FileUploaderModal from './fileUploader';
import { UncontrolledTooltip } from 'reactstrap'; 
import infoI from "../../../../assests/icons/info.svg"
import { useRef } from 'react';
import StartTestModal from '../startTestModal';
import moment from 'moment';
import WarningModal from '../warningModal';
import edit from "../../../../assests/icons/edit.svg"
import UpdateAreaOnFlyModal from "../updateAreaOnFlyModal"
import updateAreaMutation from '../../../../graphql/mutations/area/updateAreaMutation';
import { useMutation } from '@apollo/client';
import Spinner from '../../spinner';

const RunTestModal = (props) => {
  const [
    updateArea,
    { data: updateAreaData },
  ] = useMutation(updateAreaMutation)
    const {
      pendulum,
      singleRuns,
setSingleRuns,
      matchedData,
setMatchedData,
      pdfImages,
setPdfImages,
newPdfImages,
setNewPdfImages,
exactData,
setExactData,
warningMessage, 
setWarningMessage,
      addNewTest,
      setAddNewTest,
        mode,
        setMode,  
      addExtraData,
      setWarningModal,
      warningModal,
        areasToUpdate,
        setAreasToUpdate,
        haveData,
        setHaveData,
        setAddAreaDropDown,
        selectedAudit,
        resetActiveTest,
        oneLocation,
        setOneLocation,
        active,
        setActive,
        areas,
        setAreas,
        locations,
        testType,
        setTestType,
        currentTest,
        setCurrentTest,
        supportsBluetooth,
        isDisconnected,
        setIsDisconnected,
        connectToDeviceAndSubscribeToUpdates,
        device,
        setDevice,
        pullData,
        pullingDataActive,
        setPullingDataActive,
        setPullingDataActve,
        generateReport,
        charLoading,
        char,
        getPdfFromBot,
        setMyState,
        firstTimeOnFly,
        setMatchedTest, 
        setFinalData,
        pdfFile,
        setPdfFile,
        matchedTest,
        convertuploadedPdf,
        file,
        fileError,
        setFile,
        setFileError,
        pdfFileNotFound,
        setFirstTimeOnFly,
        setPdfFileNotFound,
        conditionModal,
        setConditionModal,
        cleanCondition,
        setCleanCondition,
        uploaderModal,
        setUploaderModal,
        temporaryTest,
        setTemporaryTest,
        contextCategory,
        setContextCategory,
        pdfDone,
        setPdfDone,
        pullButtonActive,
        setPullButtonActive,
        startTestModal,
        setStartTestModal,
      } = props

      let validateDate = (date) => {
        if(date) {
        let rightDate = date.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g)
        if(rightDate?.length) {
          return rightDate[0]
        } else {
          return null
        }
      } else {
        return null
      }
      }

    const [fullCategory, setFullCategory] = useState(null)
    const [firstTime, setFirstTime] = useState(null)

    const selectedTests = useSelector((state) => state.selectedTests.selectedTests)

    const dispatch = useDispatch()
    
    useEffect(() => {
      if(active && isDisconnected) {
        if(currentTest) {
          resetActiveTest(true)
        }
      }
    },[isDisconnected, active])

    const [ resultsActive, setResultsActive ] = useState(false)
    const getConst = (coName, con) => {
      let co = !con ? coName?.slice(0, coName?.indexOf(":")) : coName
      if(co == "Interior, Dry") {
        return 0.42
      } else if(co == "Interior, Wet") {
        return 0.42
      } else if(co == "Interior, Wet Plus") {
        return 0.50
      } else if(co == "Exterior, Wet") {
        return 0.55
      } else if(co == "Oils/Greases") {
        return 0.55
      }
    }
    const CustomizedDot = (props) => {
      const { cx, cy, stroke, payload, value, area } = props;   
      if (value >= getConst(area?.testingCategory) && payload.sample1 >= getConst(area?.testingCategory) && payload.sample2 >= getConst(area?.testingCategory) && payload.sample3 >= getConst(area?.testingCategory)) {
        return ( 
          <g>
          <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="#6DB240" viewBox="0 0 1024 1024">
            <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-7" />
          </svg>
          <text x={cx} y={cy+ 25} dy={-4} fill={"black"} fontSize={14} textAnchor="middle">
            {payload.name}
          </text>
          </g>
        );
      }
    
      return (
        <g>
        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="#EF1111" viewBox="0 0 1024 1024">
          <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.35" />          
      </svg>
      <text x={cx} y={cy+ 25} dy={-4} fill={"black"} fontSize={14} textAnchor="middle">
            {payload.name}
          </text>
      </g>
        );
    };

    const CustomTooltip = ({ active, payload, label, area }) => {
      if (active && payload && payload.length) {
        let dia = payload[0].payload
        return (
          <div className={`tooltipContainer  ${
            dia.areaCondition == "Prevailing/wet" ? "pwBorder" :
            dia.areaCondition == "Prevailing/dry" ? "pdBorder" :
            dia.areaCondition == "Clean/wet" ? "cwBorder" :
            dia.areaCondition == "Clean/dry" ? "cdBorder" : null 
         }`}>
          <div className={`areaConditionToolTip ${
             dia.areaCondition == "Prevailing/wet" ? "pwText" :
             dia.areaCondition == "Prevailing/dry" ? "pdText" :
             dia.areaCondition == "Clean/wet" ? "cwText" :
             dia.areaCondition == "Clean/dry" ? "cdText" : null 
          }`}>{dia.areaCondition}</div>
          <div className="custom-tooltip">
            <p className="intro" style={{color: dia.sample1 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240"}}>{`Sample 1 : ${dia.sample1}`}</p>
            <p className="intro" style={{color: dia.sample2 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240"}}>{`Sample 2 : ${dia.sample2}`}</p>
            <p className="intro" style={{color: dia.sample3 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240"}}>{`Sample 3 : ${dia.sample3}`}</p>
          </div>
          </div>
        );
      }
    
      return null;
    }

    
    const [contextLocation, setContextLocation] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [contextArea, setContextArea] = useState(null)
    const [contextFullArea, setContextFullArea] = useState(null)
    const [resultTest, setResultTest] = useState(null)
    const MENU_ID_REPLACE = "menu-id-replace";
    const MENU_ID_RESET = "menu-id-reset";
    const MENU_ID_RESULT = "menu-id-result";

  // const { show: showReplace } = useContextMenu({
  //   id: MENU_ID_REPLACE
  // });

  // const { show: showReset } = useContextMenu({
  //   id: MENU_ID_RESET
  // });

  const { show: showResults } = useContextMenu({
    id: MENU_ID_RESULT
  });

  const handleReplace = (pendulum) => {

    setUploaderModal(!pendulum ? "Location Data" : "pendulum")
  }

  useEffect(() => {
    window.onbeforeunload = selectedTests[selectedAudit?.defaultView]?.find(x => x.temporary) ? function(){
      return "Are you sure you want to close the window?";
  } : null
  },[selectedTests])

  let handleResetFunc = (f, o ,t) => {
    let fly
    let areaNumberOnFly
    let testingCategoryOnFly
    let reset

    !f ? fly = contextFullArea : fly = f
    !o ? areaNumberOnFly = fly.areaNumber : areaNumberOnFly = o
    !t ? testingCategoryOnFly = fly.testingCategory : testingCategoryOnFly = t
    !t && !o ? reset = true : reset = false

    updateAreaOnFly(fly, areaNumberOnFly, testingCategoryOnFly, reset)
    handleReset(fly, reset)
  }
  const handleReset = (fly, one) => {
 
  let tests = [...selectedTests[selectedAudit?.defaultView]].filter(x=>x.areaId == fly.id)

  let newTests= []
  
  if(fly) {
    newTests = tests.map(x => {
      
      let testin = fly?.testingCategory.slice(0, fly?.testingCategory.indexOf(":"))
      let constant = testin == "Interior, Dry" ? 
      0.42
    : testin == "Interior, Wet" ? 
      0.42
    : testin == "Interior, Wet Plus" ? 
      0.50
    : testin == "Exterior, Wet" ? 
      0.55
    : testin == "Oils/Greases" ? 
      0.55 : null

      x.passed =  x.results.every(x => Number(x.result) >= constant)
      x.areaCondition = testin.includes("dry") ? x.areaCondition.replace("wet", "dry") : x.areaCondition.replace("dry", "wet")
      return x
    })
  }

    let dates = !pendulum ? [...selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationId !== selectedLocation?.locationId && x.areaCode !== contextArea)]?.map(x => x.results ? x.results.length ? x.results[0]?.date : null : null) : null
    if(dates?.length && tests.filter(x => x.areaId !== fly.id).length > 1) {
    let momentsDates = dates.map(x => moment(x))
    let minDate = moment.min(momentsDates)
      setFirstTimeOnFly(minDate.format("MM/DD/YYYY"))
    } else {
      setFirstTimeOnFly("reset")
    }
   one ? 
   dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationId !== selectedLocation?.locationId && x.areaCode !== contextArea)]}))  :
   fly.lastCategory !== fly.category || fly.lastAreaNumber !== fly.areaNumber ? 
   dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView]?.filter(x => x.areaId !== fly.id)]})) : 
   dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView]?.filter(x => x.areaId !== fly.id), ...newTests]}))
  } 

  const updateAreaOnFly = (area, number, category, reset) => {
    let newArea = area  
    if(newArea.oldAreaNumber == number && newArea.oldTestingCategory == category) {
      newArea.areaNumber = newArea.oldAreaNumber
      newArea.testingCategory = newArea.oldTestingCategory
      newArea.category = newArea.oldCategory
      newArea.oldAreaNumber = null
      newArea.oldTestingCategory = null
      newArea.oldCategory = null

    } else {
    if(!reset) {
    newArea.oldAreaNumber = !newArea.oldAreaNumber ? area.areaNumber : newArea.oldAreaNumber
    newArea.oldTestingCategory = !newArea.oldTestingCategory ? area.testingCategory : newArea.oldTestingCategory
    newArea.oldCategory = !newArea.oldCategory ? area.category : newArea.oldCategory

    newArea.lastAreaNumber = area.areaNumber || newArea.oldAreaNumber
    newArea.lastTestingCategory = area.testingCategory || newArea.oldTestingCategory
    newArea.lastCategory = area.category || newArea.oldCategory

    newArea.areaNumber = number || area.areaNumber
    newArea.testingCategory = category || area.testingCategory
    newArea.category = newArea?.testingCategory.includes("Interior, Dry") ? "dry" :
    area.testingCategory.includes("Interior, Wet Plus") ? "wet" :
    area.testingCategory.includes("Interior, Wet") ? "wet" :
    area.testingCategory.includes("Exterior, Wet") ? "wet" :
    area.testingCategory.includes("Oils/Greases") ? "wet" : "none" || area.category

  } else {
    newArea.reseted = true
  }
  }
  if((newArea.oldCategory && newArea.oldCategory !== newArea.category) || (newArea.oldAreaNumber && newArea.oldAreaNumber !== newArea.areaNumber)) {
    newArea.reseted = true
  }
  setAreas(areas.map(t => t.id === newArea.id ? newArea : t))
  areasToUpdate.find(x => x.id == newArea.id) ? setAreasToUpdate([...areasToUpdate.map(t => !t.oldTestingCategory && !t.reseted ? null : t.id === newArea.id ? newArea : t).filter(x => x)]) : setAreasToUpdate([...areasToUpdate, newArea])
  }

  const displayLocationsResults = (e, id, category, resultCategory, currentLocation, area) => {
    // alert("hello")
    
    setResultTest(null)
    let testsExist = selectedTests[selectedAudit?.defaultView]?.some(x => x.locationId == id)

    currentLocation && setContextLocation(currentLocation.locationCode)
    currentLocation && setSelectedLocation(currentLocation)
    currentLocation && setContextArea(currentLocation.areaCode)
    area && setContextFullArea(area)
    category && setContextCategory(category)
    resultCategory && setFullCategory(resultCategory)
    currentLocation && setMatchedTest(currentLocation)
    // selectedTests[selectedAudit?.defaultView]?.some(x => x.locationCode == currentLocation?.locationCode && x.areaCode == currentLocation?.areaCode) 

    //&& x.areaCondition?.toLowerCase().includes(cleanCondition)
      if(testsExist) {
        // alert("whola")
        let tests = selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationId == id)
          if(tests.length == 2) {
            let activeTest1 = tests[0]
            let activeTest2 = tests[1]
            if(activeTest1?.results?.length && activeTest2?.results?.length ) {

            let newTest1 = {}
                 newTest1.name = activeTest1.locationCode
                 newTest1.mistake = activeTest1.mistake
                 newTest1.date = activeTest1.results[0]?.date
                 newTest1.result = activeTest1.result
                 newTest1.areaCondition = activeTest1.areaCondition
                 newTest1.resultCategory = resultCategory
                 newTest1.sample1 = activeTest1?.results[2]?.result
                 newTest1.sample2 = activeTest1?.results[1]?.result
                 newTest1.sample3 = activeTest1?.results[0]?.result  
              
            let newTest2 = {}
            newTest2.name = activeTest2.locationCode
            newTest2.mistake = activeTest2.mistake
            newTest2.date = activeTest2.results[0]?.date
            newTest2.result = activeTest2.result
            newTest2.areaCondition = activeTest2.areaCondition
            newTest2.resultCategory = resultCategory
            newTest2.sample1 = activeTest2?.results[2]?.result
            newTest2.sample2 = activeTest2?.results[1]?.result
            newTest2.sample3 = activeTest2?.results[0]?.result  
        
            setResultTest([newTest1, newTest2])
            showResults(e, {
              position: {
                x: e.pageX -100,
                y: e.pageY -150,
              },
            })
            setResultsActive(true)
          }
          } else if(tests.length == 1) {
            // alert("alore")
            let activeTest = tests[0]
            if(activeTest?.results?.length) {            
            let newTest = {}
                 newTest.name = activeTest?.locationCode
                 newTest.date = activeTest.results[0]?.date
                 newTest.mistake = activeTest.mistake
                 newTest.result = activeTest?.result
                 newTest.areaCondition = activeTest?.areaCondition
                 newTest.resultCategory = resultCategory
                 newTest.sample1 = activeTest?.results[2]?.result
                 newTest.sample2 = activeTest?.results[1]?.result
                 newTest.sample3 = activeTest?.results[0]?.result
              
            setResultTest([newTest])
            showResults(e, {
              position: {
                x: e.pageX -100,
                y: e.pageY -150,
              },
            })
            setResultsActive(true)
          }
          }
      } else {
        showResults(e, {
          position: {
            x: e.pageX -100,
            y: e.pageY -150,
          },
        })
        setResultsActive(true)
      }
  }
  // const displayMenu = (e, category, currentLocation) => {
  //   currentLocation && setContextLocation(currentLocation.locationCode)
  //   currentLocation && setContextArea(currentLocation.areaCode)
  //   category && setContextCategory(category)
  //   currentLocation && setMatchedTest(currentLocation)
  //   currentLocation ? selectedTests[selectedAudit?.defaultView]?.some(x => x.locationCode == currentLocation?.locationCode && x.areaCode == currentLocation?.areaCode) ? 
  //   showReset(e, {
  //     position: {
  //       x: e.pageX,
  //       y: e.pageY,
  //     },
  //   }) : showReplace(e, {
  //     position: {
  //       x: e.pageX,
  //       y: e.pageY,
  //     },
  //   }) : null
  // }


  const lastItemRef = useRef()
  const firstItemRef = useRef()
  const allRowItems = useRef()
  let scrollToLastItem = (ref) => {
    ref?.current.scrollIntoView({behavior: "smooth"})
  }
  const objectsEqual = (o1, o2) => 
    typeof o1 === 'object' && Object.keys(o1).length > 0 
        ? Object.keys(o1).length === Object.keys(o2).length 
            && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
        
  const arraysEqual = (a1, a2) => 
  a1?.length === a2?.length && a1?.every((o, idx) => objectsEqual(o, a2[idx]))

  useEffect(() => {
    if(active) {
      setAddAreaDropDown(false)
    }
  },[active])

  const [updateAreaOnFlyModal, setUpdateAreaOnFlyModal] = useState(null)
  const [testingCategoryForExtraData, setTestingCategoryForExtraData] = useState(null)

  const dismissChanges = () => {
    setAreasToUpdate([])
    let newAreas = areas.map(x => {
      x.areaNumber = x.oldAreaNumber || x.areaNumber
      x.testingCategory = x.oldTestingCategory || x.testingCategory
      x.category = x.oldCategory || x.category
      x.oldAreaNumber = null
      x.oldTestingCategory = null
      x.oldCategory = null
      return x
    })
    setAreas(newAreas)
    dispatch(ACTION_SELECTED_TESTS({[selectedAudit?.defaultView] : [...selectedTests[selectedAudit?.defaultView] || [], ...newAreas?.map(x => x?.results?.map(y => y).filter((x, a) => !selectedTests[selectedAudit?.defaultView]?.some( y => y.locationId == x.locationId && y.areaCondition == x.areaCondition)))?.flat(1)?.filter(y => y)]}))
    setWarningMessage(null)
  }
  const updateAreasOnFlyFunc = () => {
    generateReport("no", [...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)])
  }

  const [loadingImage, setLoadingImage] = useState(false)
  const [tests, setTests] = useState(null)

  useEffect(() => {
    if(selectedTests) {
      setTests(selectedTests[selectedAudit?.defaultView])
    }

  },[selectedTests])
return(
<Modal
        isOpen={active}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
      {warningMessage && (
            <WarningModal 
            warning={warningMessage?.unsaved ? "Unsaved Updates" : ""}
            unsaved={warningMessage?.unsaved}
            open={warningMessage}
            setOpen={setWarningMessage}
            button={handleResetFunc}
            done={updateAreasOnFlyFunc}
            dismissChanges={dismissChanges}
            />
          )}
          {updateAreaOnFlyModal && (
            <UpdateAreaOnFlyModal
            allTests={selectedTests[selectedAudit?.defaultView]}
            areas={areas}
            haveData={haveData}
            setTestingCategoryForExtraData={setTestingCategoryForExtraData}
            setHaveData={setHaveData}
            open={updateAreaOnFlyModal}
            areaNumber={updateAreaOnFlyModal?.areaNumber}
            testingCategory={updateAreaOnFlyModal?.testingCategory}
            setOpen={setUpdateAreaOnFlyModal}
            handleReset={handleResetFunc}
            updateAreaOnFly={updateAreaOnFly}
            />   
          )}

          <FileUploaderModal
              pendulum={pendulum}
              resetActiveTest={resetActiveTest}
              singleRuns={singleRuns}
              setSingleRuns={setSingleRuns}
              matchedData={matchedData}
              setMatchedData={setMatchedData}
              pdfImages={pdfImages}
              setPdfImages={setPdfImages}
              newPdfImages={newPdfImages}
              setNewPdfImages={setNewPdfImages}
              exactData={exactData}
              setExactData={setExactData}
              setAddNewTest={setAddNewTest}
              addNewTest={addNewTest}
              addExtraData={addExtraData}
              mode={mode}
              setMode={setMode}
              warningModal={warningModal}
              setWarningModal={setWarningModal}
              setFirstTimeOnFly={setFirstTimeOnFly}
              oneLocation={oneLocation}
              fullCategory={fullCategory}
              setFullCategory={setFullCategory}
              firstTimeOnFly={firstTimeOnFly}
              selectedAudit={selectedAudit}
              cleanCondition={cleanCondition}
              setCleanCondition={setCleanCondition}
              active={uploaderModal}
              setActive={setUploaderModal}
              contextArea={contextArea}
              contextCategory={contextCategory}
              contextLocation={contextLocation}
              isDisconnected={isDisconnected}
              connectToDeviceAndSubscribeToUpdates={connectToDeviceAndSubscribeToUpdates}
              getPdfFromBot={getPdfFromBot}
              pullingDataActive={pullingDataActive}
              setMyState={setMyState}
              setFinalData={setFinalData}
              setCurrentTest={setMatchedTest}
              setPullingDataActive={setPullingDataActive}
              pdfFile={pdfFile}
              setPdfFile={setPdfFile}
              matchedTest={matchedTest}
              convertuploadedPdf={convertuploadedPdf}
              file={file}
              fileError={fileError}
              setFile={setFile}
              setFileError={setFileError}
              pdfFileNotFound={pdfFileNotFound}
              setPdfFileNotFound={setPdfFileNotFound}
        />
        
      <StartTestModal
        open={startTestModal}
        setOpen={setStartTestModal}
        resetActiveTest={resetActiveTest} 
        startFunc={() => null}
        pullButtonActive={pullButtonActive}
        setPullButtonActive={setPullButtonActive}
        pullingDataActive={pullingDataActive}
        pullData={supportsBluetooth && !isDisconnected ? pullData : () => {
          setCurrentTest(null)
          connectToDeviceAndSubscribeToUpdates()
        }}
        
      />    
              {active && (
               <div className="modal task-modal" style={{height:"100vh", width:"98vw", overflow:"auto"}}>
                       {loadingImage ? <DataSpinner over={true} isOpen={loadingImage} /> : null}

          <h1 className="header testingDiagramImage" style={{width:"100%", position:"fixed", top:0, left:0, right:0, height:"10%", color: currentTest && "#D89E42", marginTop:"0px"}} >
            <span className='marginBottomOnToggle'>{oneLocation ? "Add Extra Data" : currentTest ? (    
             <> </>
          ) : "Select Locations"}</span> 
                        {/* {selectedAudit.firstTime && <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span> {moment(validateDate(selectedAudit.firstTime)).format("MM/DD/YYYY")} </div>} */}
                        {selectedTests ? selectedTests[selectedAudit?.defaultView]?.length ?firstTimeOnFly ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span>  {moment(validateDate(firstTimeOnFly)).format("MM/DD/YYYY")} </div> : selectedAudit.firstTime ? <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span>  {moment(validateDate(selectedAudit.firstTime)).format("MM/DD/YYYY")} </div> : null : null : null}
          </h1>
            <Menu id={MENU_ID_REPLACE}>
            <Item onClick={handleReplace}>add existing measurements</Item>
          </Menu>

          <Menu id={MENU_ID_RESET}>
            <Item onClick={handleReplace} >add additional test</Item>
            {/* <Item onClick={handleReset} style={{color:"#EF1111"}}>reset</Item> */}
          </Menu>
           
          {!pullingDataActive ? <> <div className="form-group-container task-form testingDiagramForm"style={{height:"100%", margin:"50px 0", justifyContent:"center", padding:"50px 0", alignItems:"center", overflowY:"auto", display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          { <Menu className='dropdown-menu' style={{position:"absolute", minHeight:"200px", display:"flex", justifyContent:"center", flexDirection:"column", width:"200px"}} onHidden={() => setResultsActive(false)} id={MENU_ID_RESULT}>
        <Item style={{background:"#F2F6FB", cursor:"auto"}}>
          <div>A{contextArea} L{contextLocation}</div>
        </Item>
       {resultTest ? <Item disabled={true} className='resultsContainerItem' style={{opacity:1}}>
        <div ref={allRowItems} style={{cursor:"auto", display:"flex", width:"200px", justifyContent:"center", height:"100%"}}>
                                   <div style={{margin: "0 5px"}} onClick={() => {
                                    scrollToLastItem(firstItemRef)} }
                                  ref={firstItemRef} className={`tooltipContainer  ${
                                   resultTest[0].areaCondition == "Prevailing/wet" ? "pwBorder" :
                                   resultTest[0].areaCondition == "Prevailing/dry" ? "pdBorder" :
                                   resultTest[0].areaCondition == "Clean/wet" ? "cwBorder" :
                                   resultTest[0].areaCondition == "Clean/dry" ? "cdBorder" : null 
                               }`}>
                                 <div className={`areaConditionToolTip ${
                                   resultTest[0].areaCondition == "Prevailing/wet" ? "pwText" :
                                   resultTest[0].areaCondition == "Prevailing/dry" ? "pdText" :
                                   resultTest[0].areaCondition == "Clean/wet" ? "cwText" : 
                                   resultTest[0].areaCondition == "Clean/dry" ? "cdText" : null 
                                 }`}>
                                 <div style={{fontSize:"1rem"}}>
                                  {resultTest[0].mistake ? 
                                  <div style={{color:"#EF1111",width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  A{resultTest[0].mistake?.area} L{resultTest[0].mistake?.location} 
                                  </div>
                                  : null}
                                  {resultTest[0].areaCondition.slice(0, resultTest[0].areaCondition.indexOf("/")+1) + resultTest[0].areaCondition[resultTest[0].areaCondition.indexOf("/")+1]?.toUpperCase() + resultTest[0].areaCondition.slice(resultTest[0].areaCondition.indexOf("/") +2)} </div>
                                 {resultTest[0].date && <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block" ,fontSize:"1rem", fontWeight:"bold"}}></span> {moment(resultTest[0].date).format("MM/DD/YYYY")} </div>}
                                 </div> 
                                 <div className="custom-tooltip">
                                   <p className="intro" style={{color: resultTest[0].sample1 < getConst(resultTest[0]?.resultCategory, true) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 1 : ${resultTest[0].sample1}`}</p>
                                   <p className="intro" style={{color: resultTest[0].sample2 < getConst(resultTest[0]?.resultCategory, true) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 2 : ${resultTest[0].sample2}`}</p>
                                   <p className="intro" style={{color: resultTest[0].sample3 < getConst(resultTest[0]?.resultCategory, true) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 3 : ${resultTest[0].sample3}`}</p>
                                 </div>
                                 </div>
                                 {resultTest[1] && <div onClick={() => {
                                  scrollToLastItem(lastItemRef)} } style={{margin: "0 5px"}}>
                                   <div ref={lastItemRef} className={`tooltipContainer  ${
                                   resultTest[1].areaCondition == "Prevailing/wet" ? "pwBorder" :
                                   resultTest[1].areaCondition == "Prevailing/dry" ? "pdBorder" :
                                   resultTest[1].areaCondition == "Clean/wet" ? "cwBorder" :
                                   resultTest[1].areaCondition == "Clean/dry" ? "cdBorder" : null 
                               }`}>
                                 <div className={`areaConditionToolTip ${
                                   resultTest[1].areaCondition == "Prevailing/wet" ? "pwText" :
                                   resultTest[1].areaCondition == "Prevailing/dry" ? "pdText" :
                                   resultTest[1].areaCondition == "Clean/wet" ? "cwText" :
                                   resultTest[1].areaCondition == "Clean/dry" ? "cdText" : null 
                                 }`}>
                                 <div style={{fontSize:"1rem"}}>
                                  
                                 {resultTest[1].mistake ? 
                                  <div style={{color:"#EF1111",width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  A{resultTest[1].mistake?.area} L{resultTest[1].mistake?.location} 
                                  </div>
                                  : null}

                                  {resultTest[1].areaCondition.slice(0, resultTest[1].areaCondition.indexOf("/")+1) + resultTest[1].areaCondition[resultTest[1].areaCondition.indexOf("/")+1]?.toUpperCase() + resultTest[1].areaCondition.slice(resultTest[1].areaCondition.indexOf("/") +2)}</div>
                                 {resultTest[1].date && <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block", fontSize:"1rem", fontWeight:"bold"}}></span> {moment(resultTest[1].date).format("MM/DD/YYYY")} </div>}

                                 </div>
                                 <div className="custom-tooltip">
                                   <p className="intro" style={{color: resultTest[1].sample1 < getConst(resultTest[1]?.resultCategory, true) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 1 : ${resultTest[1].sample1}`}</p>
                                   <p className="intro" style={{color: resultTest[1].sample2 < getConst(resultTest[1]?.resultCategory, true) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 2 : ${resultTest[1].sample2}`}</p>
                                   <p className="intro" style={{color: resultTest[1].sample3 < getConst(resultTest[1]?.resultCategory, true) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 3 : ${resultTest[1].sample3}`}</p>
                                 </div>
                                 </div>
                                   </div>
                                   } 
                                   </div> 
                                   </Item> : <Item style={{cursor:"auto"}}>
                                    <div>No results found</div>
                                   </Item> }
                                     <Item id={`UncontrolledTooltipExample_111`} style={{width:"100%",display:"flex", justifyContent:"center", alignItems:"center"}}>
                                     <div className="tableHeaderAdd">
                     <div  
                     style={{
                       color: "rgb(255, 255, 255)", 
                       pointerEvents:  "auto",
                       background: "#5C92BF",
                       
                       margin:"0 5px",
                       justifyContent:"space-between",
                       display:"flex",
                       fontSize:"14",
                       fontWeight:"bold"
                   }} 
                   onClick={() => handleReplace(pendulum)}
                   
                    className="auth-button addUser userActionsButtons">
                    upload data <div data-for={"main"}><UncontrolledTooltip
                    placement="top"
                    target={`UncontrolledTooltipExample_111`}
                    >
                    Click "Upload Data" if you have already used the BOT-3000E and download data via USB
                    </UncontrolledTooltip></div>
                    </div></div>
                    </Item>
                    {!pendulum ? <Item id={`UncontrolledTooltipExample_112`} style={{width:"100%",display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="tableHeaderAdd">
                    <div  
                    style={{
                    color: "rgb(255, 255, 255)", 
                    pointerEvents:  "auto",
                    background: "#6DB240",
                    
                    margin:"0 5px",
                    justifyContent:"space-between",
                    display:"flex",
                    fontSize:"14",
                    fontWeight:"bold"
                    }} 
                    onClick={!isDisconnected ?
                    //onClick={() => true ?
                    () => {
                      setTemporaryTest(selectedLocation) 

                    } : () => {
                      setTemporaryTest(selectedLocation) 
                      connectToDeviceAndSubscribeToUpdates()
                    }
                    }
                     
                     
                   className="auth-button addUser userActionsButtons hoverGreen">
                   BOT-3000E Test <div data-for={"main"}><UncontrolledTooltip
 placement="top"
 target={`UncontrolledTooltipExample_112`}
>
Click "BOT-3000E Test" if you want to connect to the BOT-3000E via Bluetooth
</UncontrolledTooltip></div>
                   </div></div>
                    </Item> : null}

                                     {resultTest && <Item id={`UncontrolledTooltipExample_113`}  style={{width:"100%",display:"flex", justifyContent:"center", alignItems:"center"}}>
                                     <div className="tableHeaderAdd">
                     <div  
                     style={{
                       color: "rgb(255, 255, 255)", 
                       pointerEvents:  "auto",
                       background: "#EF1111",
                       margin:"0 5px",
                       justifyContent:"space-between",
                       display:"flex",
                       fontSize:"14",
                       fontWeight:"bold"
                   }
                   } 
                   onClick={() => setWarningMessage({unsaved:false , open: <div>Are you sure you want to reset the data already uploaded for this area?</div>})}
                   
                   className="auth-button addUser userActionsButtons deleteButton">
                   <div>Reset Data</div>  <div data-for={"main"}><UncontrolledTooltip
 placement="top"
 target={`UncontrolledTooltipExample_113`}
>
Click "Reset Data" if you want to reset the data for this Area
</UncontrolledTooltip></div> 
                   </div></div>
                                     </Item>}

                                 </Menu>}
            {areas.filter((x) => {
              if(oneLocation) {
                return x.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).length > 0 && x.locationsCoordinates.some(x =>  x[Object.keys(x)[0]].id == oneLocation)
              } else {
                return x.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location"))?.length > 0
              }
            }).map((area, index) => (
              <div key={index} style={{display:"flex", flexDirection:"column", margin:"60px 10px"}}>
                <div key={index} className={`typeContent ${area.condition ? 
                      area.condition == "Prevailing/wet" ? "pwBorder" :
                      area.condition == "Prevailing/dry" ? "pdBorder" :
                      area.condition == "Clean/wet" ? "cwBorder" :
                      area.condition == "Clean/dry" ? "cdBorder" : null : null
                      }`} style={{margin:"1rem 1rem", position:"relative"}}> 
                                        {area.condition && <div style={{position:"absolute",zIndex:10, top:-1, padding:"0 1rem", right:-1, borderRadius:"5px", cursor:"pointer"}} onClick={() => setConditionModal(area)} className={`${area.condition == "Prevailing/wet" ? "pw activeCard" :
                      area.condition == "Prevailing/dry" ? "pd activeCard" :
                      area.condition == "Clean/wet" ? "cw activeCard" :
                      area.condition == "Clean/dry" ? "cd activeCard" : ""}`}>{area.condition}</div>}
                <div className="typeContent canvasDrawViewer" style={{position:"relative"}}>
                {/* <span style={{zIndex:"9999999", color:area.resultsOn ? "#5C92BF" : "#6DB240", cursor:"pointer", position:"absolute", bottom:"10px", left:"10px"}} onClick={() => {
                  let newArea = area
                 selectedTests[selectedAudit?.defaultView]On ?selectedTests[selectedAudit?.defaultView]On = false :selectedTests[selectedAudit?.defaultView]On = true
                  setReload(!reload)
                }}>{area.resultsOn ? "Test" : "Results"}</span> */}
                {area.resultsOn ? (
                                       <LineChart width={350} height={350} data={selectedTests[selectedAudit?.defaultView]?.filter(testX => testX.areaId == area.id ) ? [...selectedTests[selectedAudit?.defaultView]?.filter(testX => {
                                        if(cleanCondition) {
                                          if(testX.areaId == area.id && testX.areaCondition?.toLowerCase().includes(cleanCondition) ) {
                                            return true
                                          } else  {
                                            return false
                                          }
                                        }  {
                                         if( testX.areaId == area.id) {
                                          return true
                                         } else {
                                          return false
                                         }
                                        }
                                       } ).map(chart => {
                                        if(chart?.results?.length) {
                                         let newChart = {}
                                            newChart.name = chart.locationCode
                                            newChart.result = chart.result
                                            newChart.areaCondition = chart.areaCondition
                                            newChart.sample1 = chart?.results[2]?.result
                                            newChart.sample2 = chart?.results[1]?.result
                                            newChart.sample3 = chart?.results[0]?.result  
                                         return newChart
                                        }
                                       })]: []}
                                       margin={{ top: 50, right: 30, left: -10, bottom: 5 }}>
                                       <CartesianGrid strokeDasharray="3 3" />
                                       <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                                       <YAxis ticks={[0.20, 0.42, 0.60]}  />
                                       <Tooltip                                        
                                         content={<CustomTooltip area={area} />}
                                       />
                                       <Legend />
                                       <ReferenceLine y={0.42} label="Max" stroke="#EF1111" />
                                       <Line type="monotone" dataKey="result" stroke="#8884d8" dot={<CustomizedDot area={area} />} />
                                     </LineChart> 
                                    ) : (
                                    <>

                                    <img loading="lazy" width={"350px"} height={"350px"} src={area.diagramImage}/>

                                    {area.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).filter(x => {
                                      if(oneLocation) {
                                        return x[Object.keys(x)].id == oneLocation
                                      } else {
                                        return x
                                      }
                                    }).map((shape, i) => {
                                    return( 
                                      <div 
                                         key={i}  
                                         onClick={
                                          (e) => {
                                            let resultCategory =testingCategoryForExtraData ? testingCategoryForExtraData.slice(0, area.testingCategory.indexOf(":")): area.testingCategory.slice(0, area.testingCategory.indexOf(":"))
                                            displayLocationsResults(e, shape[Object.keys(shape)].id, area.category, resultCategory, {pendulum: pendulum, extra: oneLocation ? true : false, pending: true, new: true, resultCategory: resultCategory , areaName: area.name, temporary: (area.oldAreaNumber && area.oldAreaNumber !== area.areaNumber) || (area.oldCategory && area.oldCategory !== area.category) ? true : false, areaCode: area.areaNumber, areaCondition:"pending", areaId: area.id, locationCode: Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length), locationId: shape[Object.keys(shape)].id }, area)

                                        }
                                        }
                                      className={`not-user-selectable locationTest ${
                                        currentTest &&
                                        currentTest.locationCode &&
                                        currentTest.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) &&
                                      currentTest.areaId == area.id ? 
                                        "currentTest activeLocationTest" : 
                                        temporaryTest && 
                                        temporaryTest.locationCode && 
                                        temporaryTest.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && 
                                      temporaryTest.areaId == area.id ?
                                        "activeLocationTest" :  
                                        //selectedTests.some(x => x.locationCode && x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && 
                                       selectedTests[selectedAudit?.defaultView]?.some(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id )
                                        ? selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id ).length == 2
                                        ?  ((selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id )?.some(x=>x.passed) &&selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id )?.some(x => !x.passed))) 
                                        ? "differentLocations clickable" 
                                        : (selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id )?.every(x => !x.passed))
                                        ? "clickable" : 
                                        (selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id )?.every(x=> x.passed)) ?
                                        "passLocation clickable" : "notAny" :
                                        selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id )[0].pendulum ? 
                                        "pendulumLocation clickable"
                                        :selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id )?.every(x=>!x.passed)
                                        ? "faildLocation clickable" 
                                        :selectedTests[selectedAudit?.defaultView]?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id )?.every(x=>x.passed)
                                        ? "passLocation clickable"
                                        : "" : "" 
                                      }`} 
                                      style={{position:"absolute", border:"0px solid black", width:`${shape[Object.keys(shape)].radius * 2.5 +5}px`, borderRadius:"50%", height:`${shape[Object.keys(shape)].radius * 2.5 +5}px`,  top:shape[Object.keys(shape)].y - shape[Object.keys(shape)].radius -3, left:shape[Object.keys(shape)].x - shape[Object.keys(shape)].radius -3}}>
                                        {selectedTests[selectedAudit?.defaultView]
                                        ?.filter(x => x.locationCode == Object.keys(shape)[0]
                                        .substring(11, Object.keys(shape)[0].length) 
                                        && x.areaId == area.id )
                                        .length == 2 
                                        ? selectedTests[selectedAudit?.defaultView]
                                        ?.filter(x => x.locationCode == Object.keys(shape)[0]
                                        .substring(11, Object.keys(shape)[0].length) 
                                        && x.areaId == area.id )
                                        ?.every(x=>!x.passed) 
                                        || selectedTests[selectedAudit?.defaultView]
                                        ?.filter(x => x.locationCode == Object.keys(shape)[0]
                                        .substring(11, Object.keys(shape)[0].length) 
                                        && x.areaId == area.id )
                                        ?.every(x=>x.passed) 
                                        ? <div style={{justifyContent:"center", display:"flex", alignItems:"center", color:"white", fontWeight:"bold"}}>2</div> : null : null}
                                        <span className='locationTestId' style={{ textShadow: "#000000 1px 0px 2px", right:"-27px" ,position:"absolute", fontWeight:"bolder", 
                                        color: selectedTests[selectedAudit?.defaultView]
                                        ?.filter(x => x.locationCode == Object.keys(shape)[0]
                                        .substring(11, Object.keys(shape)[0].length) 
                                        && x.areaId == area.id )[0]
                                        ?.new ? "#D89E42" :
                                        selectedTests[selectedAudit?.defaultView]
                                        ?.filter(x => x.locationCode == Object.keys(shape)[0]
                                        .substring(11, Object.keys(shape)[0].length) 
                                        && x.areaId == area.id )[0] 
                                        ? selectedTests[selectedAudit?.defaultView]
                                        ?.filter(x => x.locationCode == Object.keys(shape)[0]
                                        .substring(11, Object.keys(shape)[0].length) 
                                        && x.areaId == area.id )[0]
                                        ?.passed ? "#6DB240" 
                                       : "#EF1111" 
                                        : "#5C92BF"}}
                                        >
                                      {Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length)}</span>
                                    </div>

                                    )})}
                                    </>)}

                                    </div>
                
                   
                <div style={{
                      display:"flex",
                      flexDirection:"column",
                      justifyContent:"space-between",
                      justifyContent:"flex-end",
                      position:"absolute",
                      top: "-110px",
                      padding:"0 10px",
                      opacity:"1",
                      width:"100%",
                      height:"100px",
                    }}
                    className={`areaBar ${selectedTests[selectedAudit?.defaultView]?.filter(o => o.areaId == area.id).length > 0 && "areaBarActive"}`}>
                    <span style={{ marginBottom:"10px"}}>{area?.name} (Area #{area?.areaNumber}) </span>    
                    {!oneLocation && <div>{testingCategoryForExtraData ? testingCategoryForExtraData.slice(0, area?.testingCategory.indexOf(":")) :area?.testingCategory.slice(0, area?.testingCategory.indexOf(":"))}
                    <div onClick={(e) => {
                            true ? area.results?.length ? setHaveData(true) : null : null
                            true ? setUpdateAreaOnFlyModal(area) : setUpdateAreaOnFlyModal("location")
                        }} style={{color:"#5C92BF", cursor:"pointer", display:"inline-block", marginLeft:"10px"}}> <img src={edit} height={"20"}/></div>
                    </div>}                
                    </div> 
            </div>
            </div>
            ))}
          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                
                className="auth-button addUser confirmDelete"
                style={{color:"#5C92BF", border:"1px solid #5C92BF", background:"white"}}
                onClick={() => {
                  if((areasToUpdate.length && ( selectedTests[selectedAudit.defaultView].length || firstTimeOnFly == "reset")) ) {
                    setWarningMessage({unsaved: true ,open: <div>You still have unsaved updates at {areasToUpdate.length} area{areasToUpdate.length == 1 ? "" :"s"}
                    {<div style={{display:"flex", flexDirection:"column",color:"#505463", marginTop:"12px", fontSize:"14px"}}>{areasToUpdate.map(x => (
                      <>
                      {`${x.name} #${x.areaNumber} :`}
                      {x.reseted ? <div style={{marginLeft:"20px", marginTop:"2px", color:"#848387"}}>- The Area data has been reset.</div> : null}
                      {x.oldTestingCategory ? <div style={{marginLeft:"20px", marginTop:"2px", color:"#848387"}}>{`${x.oldTestingCategory !== x.testingCategory ? `- Testing Category changed from ${x.oldTestingCategory?.slice(0, x.oldTestingCategory?.indexOf(":"))} to ${x.testingCategory?.slice(0, x.testingCategory?.indexOf(":"))}` : ""}`}</div> : null}
                      {x.oldAreaNumber ? <div style={{marginLeft:"20px", marginTop:"2px", color:"#848387"}}>{`${x.oldAreaNumber !== x.areaNumber ? `- Area # changed from #${x.oldAreaNumber} to #${x.areaNumber}` : ""}`}</div> : null}
                      </>
                    ))}</div>}
                    <div style={{marginTop:"40px", fontSize:"14px", color:"#5C92BF"}}>Click "Confirm" to save the updates</div>
                    <div style={{marginTop:"10px", fontSize:"14px", color:"#5C92BF"}}>Click "Dismiss" to revert all the changes</div>

                    </div>})
                  } else {            
                  setAddAreaDropDown(true)
                  let areaResults = areas?.map(x => x?.results).flat(1)?.filter(y => y)
                  let ifEqual = arraysEqual(areaResults, selectedTests[selectedAudit.defaultView])
                  if(ifEqual || oneLocation) {
                    dispatch(ACTION_SELECTED_TESTS({[selectedAudit.defaultView]: null}))
                    setTestingCategoryForExtraData(null)
                  }
                  setActive(false)
                  setTestType("DCOF")
                  setOneLocation(false)
                  setCurrentTest(null)
                  if(device) {
                  device.gatt.disconnect()
                  setDevice(null)
                  }
                  setIsDisconnected(true)
                }}
              }
            >

              Minimize
            </div>
            {!isDisconnected && !charLoading ? (  
            // {true? (  
            !currentTest ? 
            selectedTests[selectedAudit?.defaultView]?.length > 0 && selectedTests[selectedAudit?.defaultView]?.filter(test => test.pending).length == 0 && !temporaryTest ? (
                <><div             
                id={`UncontrolledTooltipExample_newData_1112`}
                style={{margin:".5rem"}}
                className={`${true ? "auth-button addUser" : "auth-button addUser disable-button"}`}
                onClick={true ? () => {
                  console.log([...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)], "111112")
                  //  generateReport("no", [...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)])
                  } : null}
                >
                Save
                </div> 
                <UncontrolledTooltip
                placement="top"
                target={`UncontrolledTooltipExample_newData_1112`}
                >
                Click save to save uploaded data
                </UncontrolledTooltip>
                </>     
            ) : isDisconnected ? 
            //  ) : false ? 
            selectedTests[selectedAudit?.defaultView]?.length > 0 && selectedTests[selectedAudit?.defaultView]?.filter(test => test.pending).length == 0 && !temporaryTest ? (
            <><div
              id={`UncontrolledTooltipExample_newData_1113`}
              style={{margin:".5rem"}}
              // className={`${selectedTests[selectedAudit?.defaultView].find(x => x.new) ? "auth-button addUser" : "auth-button addUser disable-button"}`}
              className={`${true ? "auth-button addUser" : "auth-button addUser disable-button"}`}
              onClick={true ? () => {
                console.log([...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)], "111113")
                // generateReport("no", [...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)])
                }
              : null}
              >
              Save
              <UncontrolledTooltip
                placement="top"
                target={`UncontrolledTooltipExample_newData_1113`}
                >
                Click save to save uploaded data
                </UncontrolledTooltip>
              </div> 
              
                  </>     
          ) :
            (
              <><div
              id={`UncontrolledTooltipExample_newData_11131`}
              style={{margin:".5rem"}}
              // className={`${selectedTests[selectedAudit?.defaultView].find(x => x.new) ? "auth-button addUser" : "auth-button addUser disable-button"}`}
              className={`${areasToUpdate?.length ? "auth-button addUser" : "auth-button addUser disable-button"}`}

              onClick={areasToUpdate?.length ? () => {
                console.log([...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)], "111114")
                //  generateReport("no", [...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)])
              }
                  : null}
              >
                Save
                <UncontrolledTooltip
 placement="top"
 target={`UncontrolledTooltipExample_newData_11131`}
>
{areasToUpdate?.length ? "click to save reseted areas" : "Add test to save"}
</UncontrolledTooltip>
              </div> 
  </>     
            ) : <div
            style={{margin:".5rem"}}
              className={`${temporaryTest ? supportsBluetooth && !isDisconnected ? "auth-button addUser": "auth-button addUser disable-button" : "auth-button addUser disable-button"}`}
              // className={`${temporaryTest ? supportsBluetooth && !false ? "auth-button addUser": "auth-button addUser disable-button" : "auth-button addUser disable-button"}`}
              onClick={
                temporaryTest ? supportsBluetooth && !isDisconnected ? () => {
                  // true ? true ? () => {
                  setCurrentTest(temporaryTest)
                  setTemporaryTest(null)
                  setStartTestModal(true)
                } : connectToDeviceAndSubscribeToUpdates : null
              } 
            >
              {"Start"}
            </div> : char ? <div
            style={{margin:".5rem"}}
              className="auth-button addUser"
              onClick={supportsBluetooth && !isDisconnected ? pullData : () => {
                setCurrentTest(null)
                connectToDeviceAndSubscribeToUpdates()
              }}
            >
              Pull {currentTest.areaCode} - {currentTest.locationCode}
            </div> : <div
                className="auth-button addUser disable-button"
                >
              <DataSpinner small="s" /> </div>) :  selectedTests[selectedAudit?.defaultView]?.length > 0 && selectedTests[selectedAudit?.defaultView]?.filter(test => test.pending).length == 0 && selectedTests[selectedAudit?.defaultView]?.filter(test => test.new).length && !temporaryTest ? (
              <><div
              style={{margin:".5rem"}}
              id={`UncontrolledTooltipExample_newData_111`}
              className={`${true ? "auth-button addUser" : "auth-button addUser disable-button"}`}
              onClick={true ? () => {
                console.log([...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)], "111115")
                 generateReport("no", [...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)])
              }
                  : null}
              >
              Save
              </div>   
              <UncontrolledTooltip
              placement="top"
              target={`UncontrolledTooltipExample_newData_111`}
              >
              Click save to save uploaded data
              </UncontrolledTooltip>
                </>           
          ) : !charLoading ? ( <><div
            id={`UncontrolledTooltipExample_newData_11132`}
            style={{margin:".5rem"}}
            // className={`${selectedTests[selectedAudit?.defaultView].find(x => x.new) ? "auth-button addUser" : "auth-button addUser disable-button"}`}
            className={`${areasToUpdate?.length ? "auth-button addUser" : "auth-button addUser disable-button"}`}

            onClick={areasToUpdate?.length ? () => {
              console.log([...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)], "111116")
              // generateReport("no", [...selectedTests[selectedAudit.defaultView], ...areas.filter(x => x?.extra?.length)?.map(x=>x?.extra).flat(1)?.filter(y => y)])
            }
                : null}
            >
              Save
              <UncontrolledTooltip
              placement="top"
              target={`UncontrolledTooltipExample_newData_11132`}
              >
              {areasToUpdate?.length ? "click to save reseted areas" : "Add test to save"}
              </UncontrolledTooltip>
            </div> 
           
</>     ) :  <div
                className="auth-button addUser disable-button"
                >
              <DataSpinner small="s" /> </div>}
          </div>
          </> : (
            <div style={{margin:"2rem 0"}}>
            <div className="progress-bar" style={{height:"10px", borderTopRightRadius:"8px", borderBottomRightRadius:"8px", width: pullingDataActive && `${(pullingDataActive/1800*100).toFixed(2)}%`}}></div>
            <h1 className="count">{pullingDataActive < 1800 ? (pullingDataActive/1800*100).toFixed(2) : (1800/1800*100).toFixed(2)}%</h1>
            </div>
          )}
          
        </div>)}
      </Modal>
  )}

  export default RunTestModal
       