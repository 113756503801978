import { gql } from "apollo-boost";

const resetPasswordMutation = gql`
  mutation(
    $email: String
    $password: String
    $newPassword: String
    ) {
    resetPassword(
      email: $email
      password: $password
      newPassword: $newPassword
      ) {
      email
    }
  }
`;

export default resetPasswordMutation;
