import { gql } from "apollo-boost";

const deleteSiteMutation = gql`
  mutation($_id: ID) {
    deleteSite(_id: $_id) {
      _id
    }
  }
`;

export default deleteSiteMutation;
