const REPORT_TEMPLATE_PAGE_1 = "REPORT_TEMPLATE_PAGE_1";

const reportTemplateLastPageOpenedReducer = (
  state = { report_template_active_page: "page1" },
  action
) => {
  switch (action.type) {
    case REPORT_TEMPLATE_PAGE_1:
      return { ...state, report_template_active_page: "page1" };
    default:
      return { ...state };
  }
};

export default reportTemplateLastPageOpenedReducer;
