const CONFIRM_NEW_PASSWORD_NOT_INVALID =
  "CONFIRM_NEW_PASSWORD_NOT_INVALID";

const ACTION_CONFIRM_NEW_PASSWORD_NOT_INVALID = () => {
  return {
    type: CONFIRM_NEW_PASSWORD_NOT_INVALID,
  };
};

export default ACTION_CONFIRM_NEW_PASSWORD_NOT_INVALID;
