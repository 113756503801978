import React, { useRef, useEffect, useCallback } from "react";
import { Circle as KonvaCircle, Transformer, Text, Tag, Label, Circle, Image, Group } from "react-konva";
import archive from "../../../../../assests/icons/archive.svg"
import { LIMITS } from "./constants";
import { selectShape, transformCircleShape, moveShape, deleteShape } from "./state";
import useImage from "use-image"


const boundBoxCallbackForCircle = (oldBox, newBox) => {
  if (
    newBox.width < LIMITS.CIRCLE.MIN ||
    newBox.height < LIMITS.CIRCLE.MIN ||
    newBox.width > LIMITS.CIRCLE.MAX ||
    newBox.height > LIMITS.CIRCLE.MAX
  ) {
    return oldBox;
  }
  return newBox;
}; 

export function Location({fromArea, setHandleDeleteModal, loadingCanvas, passed, addLocationToDeleted, locationId, isNew, id, isSelected, loadMode, locationMode, selectShapeToDelete, shapeToDelete , type, ...shapeProps}) {
  const shapeRef = useRef();
  const transformerRef = useRef();
  useEffect(() => {
    if (isSelected) {
      transformerRef?.current?.nodes([shapeRef?.current]);
      transformerRef?.current?.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  const handleSelect = useCallback(
    (event) => {
      event.cancelBubble = true;
      selectShape(id);
    },
    [id, locationMode]
  );

  const handleDrag = useCallback(
    (event) => {
      moveShape(id, event);
    },
    [id]
  );

  const handleTransform = useCallback(
    (event) => {
      transformCircleShape(shapeRef.current, id, event);
    },
    [id]
  );

  const handleDelete = (event) => {
    setHandleDeleteModal({id, event, isNew})
    }

  const [trash] = useImage(archive, 'Anonymous');
 
  return (
    <>
    {isNew ? (
    <>
      <KonvaCircle
        onMouseDown={handleSelect}
        onTouchStart={handleSelect}
        onDblClick={handleSelect}
        onTap={handleSelect}
        onDragStart={handleSelect}      
        ref={shapeRef}
        {...shapeProps}
        draggable={true}
        onDragEnd={handleDrag}
        onTransformEnd={handleTransform}
        />
      {isSelected && passed == null && !loadingCanvas ? (
        <Transformer
          anchorSize={5}
          borderDash={[6, 2]}
          ref={transformerRef}
          rotateEnabled={false}
          enabledAnchors={[]}
          boundBoxFunc={boundBoxCallbackForCircle}
        >
          {trash && <Image  
            width={15}
            height={15}
            image={trash}
            // ref={deleteButton}
            onClick={handleDelete}
            x={shapeRef?.current?.width()}
            y={-10}
          />
          }
        </Transformer>
      ) : null}
    </> ) : (
      <>
           {true ? (
            <>
            <Group 
            x={shapeProps.x-5} y={shapeProps.y -5} 
            onMouseDown={(locationMode && !locationId && !fromArea) || loadMode ? handleSelect : null}
            onTouchStart={(locationMode && !locationId && !fromArea) || loadMode ? handleSelect : null}
            onDblClick={(locationMode && !locationId && !fromArea) || loadMode ? handleSelect : null}
            onTap={(locationMode && !locationId && !fromArea) || loadMode ? handleSelect : null}  
            onDragStart={(locationMode && !locationId && !fromArea) || loadMode ? handleSelect : null}
            onDragEnd={(locationMode && !locationId && !fromArea) || loadMode ? handleDrag : null}
            ref={shapeRef}
            {...shapeProps}
            fill= {shapeProps?._id ? "red" : shapeProps.fill}
            stroke={shapeProps?._id ? "red" : shapeProps.stroke}
            draggable={(locationMode && !locationId && !fromArea) || loadMode ? true : false}
            onTransformEnd={(locationMode && !locationId && !fromArea) ? handleTransform : null}

             >
             <KonvaCircle
             {...shapeProps}
             x={3}
             y={3}
            //  fill= {!loadingCanvas ? passed !== null ? passed == true ? "#6DB240" : passed == false ? "#EF1111" : shapeProps.fill : shapeProps.fill : shapeProps.fill}
            fill=""
            stroke=""
            fillPriority="linear-gradient" // 'color', 'pattern', 'linear-gradient', 'radial-gradient'
						/* linear-gradient */
						fillLinearGradientStartPoint={{ x: 1, y: 0}}
						fillLinearGradientEndPoint={{ x: 0, y: 0 }}
						fillLinearGradientColorStops={[
							0,
							passed?.length ? passed[0] : shapeProps.fill,
							1,
							passed?.length ? passed[1] ? passed[1] : passed[0] : shapeProps.fill,
						]}
            //  stroke={!loadingCanvas ? passed !== null ? passed == true ? "#6DB240" : passed == false ? "#EF1111" : shapeProps.stroke : shapeProps.stroke : shapeProps.stroke}
           /> 
             {passed?.length == "2" ? <Text fill="white" text="2" /> : <Text fill="white" text="" />}
          </Group>
        {isSelected && passed == null && !loadingCanvas ? <Transformer
          anchorSize={5}
          borderDash={[6, 2]}
          ref={transformerRef}
          rotateEnabled={false}
          enabledAnchors={[]}
          boundBoxFunc={boundBoxCallbackForCircle}
        >
          {trash ? <Image
            width={15}
            height={15}
            image={trash}
            // ref={deleteButton}
            onClick={handleDelete}
            x={shapeRef?.current?.width() + 10}
            y={-15}
          /> : null
          }
        </Transformer> : null}
           </>
           ) : (
            <>
          
          </>
           )}

          </>
    )}
     
     
    </>
  );
}
