const MENU_CLICKED = "MENU_CLICKED";
const MENU_CLICKED_RESET = "MENU_CLICKED_RESET";

const menuClickedReducer = (state = { menu_clicked: null }, action) => {
  switch (action.type) {
    case MENU_CLICKED:
      return { ...state, menu_clicked: action.row };
    case MENU_CLICKED_RESET:
      return { ...state, menu_clicked: null };
    default:
      return { ...state };
  }
};

export default menuClickedReducer;