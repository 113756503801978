import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";
import "./avatarModal.scss"
import archive from "../../../../../assests/icons/archive.svg"
import add from "../../../../../assests/icons/plus.png"
import { DataSpinner } from "../../../spinner/dataSpinner";
import { UncontrolledTooltip } from "reactstrap";
import useAwsCredentials from "../../../../hooks/useAws";


function AvatarModal({images, setImages, progress, setProgress, imagesLater, setImagesLater, pendulum}) {
  const [selectedFile, setSelectedFile] = useState(null);

  const { credentials, loading, error, fetchCredentials } = useAwsCredentials();  // Use the custom hook to fetch credentials


  const uploadFiles = async (files) => {

    let myBucket = await fetchCredentials()
    if (myBucket) {
    let filteredFiles = files.filter(x => !x.url)
    filteredFiles.map(async x => {
      const base64Data = new Buffer.from(x.data_url.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      const type = x.data_url.split(';')[0].split('/')[1];
    const fileName = `${x.file.name.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "")}-${global.Date.now()}.png`
        const params = {
          ACL: 'public-read',
          Body: base64Data,
          Key: fileName,
          ContentEncoding: 'base64', // required
          ContentType: `image/${type}` // required. Notice the back ticks
        };
      myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .on("complete", (evt) => {
          x.url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`
          setImages(files.map(y => x?.file?.name == y?.file?.name ? x : y))
        })
        .send((err) => {
            if (err) console.log(err)  
        })
      })
    } else {
      alert("Loading.")
      
    }
  }
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    uploadFiles(imageList)
    setImages(imageList)
  };

  const move = (from, to, arr) => {
    const newArr = [...arr];

    const item = newArr.splice(from, 1)[0];
    newArr.splice(to, 0, item);

    return newArr;
}
  const replaceImage = (index) => {
    let newImages = [...images]
    let newImagesArr = move(index, 0, newImages)
    setImages(newImagesArr)
  }
  
  // if(loading) { 
  //   return <DataSpinner />
  // }
 

  return (
    <div>
      <ImageUploading
        multiple 
        value={images}
        onChange={onChange} 
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "jpeg", "png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper" style={{height:"100%"}}>
            <div
              style={{ cursor:"pointer", color: isDragging ?"#EF1111": null, border:"1px solid #e0e0e0", padding:"7px", borderRadius:"4px", minHeight:"200px", display:"flex", justifyContent:"center", alignItems:"center" }}
              onClick={ () => {
                if(imagesLater) {
                  setImagesLater(null)
                  onImageUpload()
                } else {
                  onImageUpload()
                }
              }}
              {...dragProps}
            >
              {images?.length == 0 && <span style={{color:"rgb(182 182 182)", fontWeight:"bold"}}>Click to Upload or Drop Photo of Area here</span>}
           
            {/* <div onClick={(e) => {  
              e.stopPropagation() 
              onImageRemoveAll()}}>Remove all images</div> */}
            {imageList?.length > 0 && <div style={{ width:"100%", maxHeight:"70vh", overflow:"auto", display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                {imageList?.map((image, index) => (
                  <div style={{position:"relative"}} key={index} className={`image-item ${index == 0 && image.url ? "primaryPhoto" :index == 1 && image.url && pendulum ? "zoomedPhoto" : ""}`}>
                    {index == 0 && image.url && <div style={{position:"absolute", background:"rgb(251 244 244 / 80%)", color:"#101010", textTransform:"capitalize", width:"100%", height:'fit-content', display:"flex", alignItems:"center", justifyContent:"center", top:-1}}>Main photo</div>}
                    {index == 1 && image.url && pendulum && <div style={{position:"absolute", background:"rgb(251 244 244 / 80%)", color:"#101010", textTransform:"capitalize", width:"100%", height:'fit-content', display:"flex", alignItems:"center", justifyContent:"center", top:-1}}>Floor zoomed</div>}
                    <div style={{margin:"5px"}}>
                      {image.url ? <> <img  id={index == 0 ? "UncontrolledTooltipExample_401" : index == 1 && pendulum ? "UncontrolledTooltipExampleZoomed_401" : undefined} onClick={(e) =>{
                        e.stopPropagation()
                        !images.some(x => x.url == "replace") ? replaceImage(index) : onImageUpdate(index)
                      }}
                         src={image?.url == "replace" ? add : image.url} alt="" height={"100"} width={"100"} style={{objectFit:"cover"}} />
                        {index == 0 ? <UncontrolledTooltip
                    placement="top"
                    target="UncontrolledTooltipExample_401"
                    >
                    One main Photo per Area
                    </UncontrolledTooltip> : null}

                    {index == 1 && pendulum ? <UncontrolledTooltip
                    placement="top"
                    target="UncontrolledTooltipExampleZoomed_401"
                    >
                    One floor zommed Photo per Area
                    </UncontrolledTooltip> : null}

                    <div onClick={(e) => {
                        e.stopPropagation()
                        onImageRemove(index)}} className="image-item__btn-wrapper"> 
                      <div >
                        <img src={archive} height={20} />
                        </div> 
                    </div></> : <DataSpinner />}
                    </div>
                  </div> 
                ))} 
                {!images.some(x => x.url == "replace") && <div  style={{ padding:"5px", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}} className="image-item">
                    <img id="UncontrolledTooltipExample_402" onClick={(e) => {
                      e.stopPropagation()
                      onImageUpload()
                      }} src={add} alt="" height={"100"} width={"100"} style={{objectFit:"cover"}} />
                      <div style={{fontWeight:"bold"}}>Add Photo</div>
                      <UncontrolledTooltip
                    placement="top"
                    target="UncontrolledTooltipExample_402"
                    >
                    Additional Photo will be added to "Audit Photos"
                    </UncontrolledTooltip>
                </div>}
            </div>}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  ); 
}
 export default AvatarModal
