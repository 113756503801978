import { gql } from "apollo-boost";

const addLocationMutation = gql`
  mutation(
    $area: ID,
    $view: ID,
    $pass: Boolean,
    $readings: String,
    $condition: String,
    $report: String,
    $location: String,
    $coordinates: String,
    $label: String,

  ) {
    addLocation(
    area: $area,
    view: $view,
    pass: $pass,
    readings: $readings,
    condition: $condition,
    report: $report
    location: $location
    coordinates: $coordinates
    label: $label

    ) {
    _id
    area
    view
    pass
    label
    readings
    condition
    report
    location
    coordinates
  }
  }
`;

export default addLocationMutation;
