import React, { useState, useEffect } from "react";



function Bluetooth() {
 
  // {supportsBluetooth && isDisconnected &&
  //   <button onClick={connectToDeviceAndSubscribeToUpdates}>Connect to a Bluetooth device</button>
  // }
  // {!isDisconnected && (
  //           <button onClick={recieveData}>recieve data</button>
  // )}
  // {!supportsBluetooth &&
  //   <p>This browser doesn't support the Web Bluetooth API</p>
  // }

  return (
    <div className="App">
    
    
    </div>
  );
}

export default Bluetooth;
