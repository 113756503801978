import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";

import ACTION_LOGIN_EMAIL from "../../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL";
import ACTION_REGISTERED_CLIENT_FOUND_RESET from "../../../../actions/user/Login/RegisteredClientFound/ACTION_REGISTERED_CLIENT_FOUND_RESET";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";

const LoginEmail = ({disabled}) => {
  const dispatch = useDispatch();

  // Email States
  const loginEmail = useSelector(state => state.loginEmail.login_email);
  const loginEmailInvalid = useSelector(
    state => state.loginEmailInvalid.login_email_invalid
  );
  const loginTempoInvalid = useSelector(
    state => state.loginTempoInvalid.login_tempo_invalid
  );
  const emailTyping = e => {
    dispatch(ACTION_LOGIN_EMAIL(e.currentTarget.value.trim().toLowerCase()));
    dispatch(ACTION_REGISTERED_CLIENT_FOUND_RESET());
    dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
    dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
  };

  return (
    <FormGroup>
      <Label for="loginEmail">
        <div className="auth-label">Email</div>
      </Label>
      <Input
      disabled={disabled}
        type="email"
        name="loginEmail"
        defaultValue={loginEmail}
        maxLength={128}
        placeholder="Email address"
        className="auth-input"
        onChange={emailTyping}
        invalid={loginEmail === "" ? false : loginEmailInvalid ? true : false}
      />
      {loginEmailInvalid ? (
        <div className="auth-error" style={{textTransform:"none"}}>
            No registered client found with this email.
        </div> 
      ) : loginTempoInvalid ? (
        <div className="auth-error" style={{textTransform:"none"}}>
            Your account is temporarily disabled by the admin.
        </div> 
      ) : null}
    </FormGroup>
  );
};

export default LoginEmail;
