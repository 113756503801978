const INFO_ACTIVE = "INFO_ACTIVE";

const ACTION_INFO_ACTIVE = (payload) => {
  return {
    type: INFO_ACTIVE,
    payload
  };
};

export default ACTION_INFO_ACTIVE;
