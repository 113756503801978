import { faEllipsisV, faEnvelope, faInfoCircle, faPhoneAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { AccountTable as AdminsTable } from "../../resuable/accountTable";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET"
import ACTION_BACK_ROUTE from "../../../actions/route/ACTION_BACK_ROUTE"
import ACTION_MENU_CLICKED from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED"
import ACTION_MENU_CLICKED_RESET from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED_RESET"
import blank from "../../../assests/images/blank.png"
import archive from "../../../assests/icons/archive.svg"
import DataTable from "react-data-table-component";
import download from "../../../assests/icons/download.svg"

import { DataSpinner as RenderSitesLoader } from "../../resuable/spinner/dataSpinner";

import { useDispatch, useSelector } from "react-redux";

import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import addUserMutation from "../../../graphql/mutations/user/addUserMutation"
import updateUserStatusMutation from "../../../graphql/mutations/user/updateUserStatusMutation"
import updateUserMutation from "../../../graphql/mutations/user/updateUserMutation"

import infoI from "../../../assests/icons/info.svg"


import imageCompression from "browser-image-compression";
import LZString from "lz-string";
import Cookies, { set } from "js-cookie";
import { useLocation } from "react-router-dom";

import {
useHistory
} from "react-router-dom";

import "./admins.scss"
import AccountHeader from "../../resuable/accountHeader";
import ACTION_BACK_ROUTE_RESET from "../../../actions/route/ACTION_BACK_ROUTE_RESET";
import deleteUserMutation from "../../../graphql/mutations/user/deleteUserMutation";
import { Redirect } from "react-router-dom";
import {AddAccountModal as AddAdminModal} from "../../resuable/modals/addAccountModal";
import ConfirmDeleteModal from "../../resuable/modals/confirmDeleteModal";
import ACTION_INFO_ACTIVE from "../../../actions/InfoActive/ACTION_INFO_ACTIVE";
import { UncontrolledTooltip } from "reactstrap";
import allReportsQuery from "../../../graphql/queries/report/allReportsQuery";
import AWS, { BackupGateway } from 'aws-sdk'
import updateReportMutation from "../../../graphql/mutations/report/updateReportMutation";
import Pagination from "../../resuable/pagination/Pagination";
import AddReportsModal from "../../resuable/modals/addReportsModal";
import updateLicenseMutation from "../../../graphql/mutations/license/updateLicenseMutation";


const Admins = (props) => {
const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';

const history = useHistory ()

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
}) 

const [
  updateReport,
  { data: updateReportData, loading:updateReportLoading },
] = useMutation(updateReportMutation)


    const resubmitReport = (_id) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        updateReport({
            variables:{
                _id,
                status:"pending",
                note:"resubmitted"
            }
        }).then(() => {
            allReportsRefetch()
        })
    }

  const location = useLocation()
  const [currentPageReportsUser, setCurrentPageReportsUser] = useState(1);

  let PageSize = 30;

  const [
    allReportsByUser,
    {
      data: allReportsData,
      refetch: allReportsRefetch,
      called: reportsCalled,
      loading:allReportsLoading
    },
  ] = useLazyQuery(allReportsQuery, {
    fetchPolicy: "no-cache",
  });

    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const {
        admins,
        adminsRefetch,
        user,
        users,
        licensesRefetch
    } = props

    const backRoute = useSelector((state) => state.backRoute.backRoute);
    const menu_clicked = useSelector((state) => state.menuClicked.menu_clicked);


    const [toggleChecked, setToggleChecked] = useState(true)
    const [accountData, setAccountData] = useState(null)
    const [optionMenu, setOptionMenu] = useState(null)
    
    const downloadInnerHtml = (pdf, id) => {
      const {template, css} = pdf
      let newHtml
      let newCss
        const paramsCss = {
          Bucket: S3_BUCKET,
          Key: css,
        }
        myBucket.getObject(paramsCss)
        .on("complete", (evtCss) => {
          if(evtCss?.data?.Body) {
            if(Buffer.from(evtCss?.data?.Body)) {
              newCss = Buffer.from(evtCss?.data?.Body).toString('utf8')
            }
            
          } 
          const params = {
            Bucket: S3_BUCKET,
            Key: template,
          }
          myBucket.getObject(params)
          .on("complete", (evt) => {
            if(evt.data.Body) { 
              updateReport({variables:{
                  _id: id,
                  newUpdate: false
              }}).then(x => {
                allReportsRefetch()
              }) 
              newHtml = Buffer.from(evt.data.Body).toString('utf8')
              let stringHtml = `<div><style>${newCss}</style>${newHtml}</div>`
              history.push({
                  pathname:"/admin/reports/download",
                  state:{
                      page: stringHtml
                  }
              })
              //  let mimeType = "text/html"
    
              // let finalHtml = finalPdf
              // let link = document.createElement('a'); 
              // mimeType = mimeType || 'text/plain';
              // link.setAttribute('download', "report.html");
              // link.setAttribute('href', 'data:' + mimeType  +  ';charset=utf-8,' + encodeURIComponent(finalHtml));
              // link.click();
            }
          })
          .send((err) => {
              if (err) console.log(err)
          }) 
        })
        .send((err) => {
            if (err) console.log(err)
        })       
    }

    const reportColumns = [      
  
      {
        name: "download",
        cell: (row) => <img 
        onClick={() => row.status == "approved" &&  downloadInnerHtml(row, row.id)} style={{cursor:"pointer", opacity: row.status == "pending" || row.status == "rejected" ? ".2" : "1"}} 
        src={download} />,
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        width:"100px",
        style:{
            fontSize:"16px"
        }
    },
    {
      name: 'Updated By',
      filter:["none"],
      selector: row => row.updatedBy, 
      
  },
    {
              name: 'date of audit',
              filter:["none"],
              selector: row => row.dateOfAudit,
              
          },
          {
              name: 'generation date',
              filter:["none"],
              selector: row => row.generationDate,
  
          },
          {
            name: 'Generated by',
            filter:["none"],
            selector: row => row.userName,
            
        },
          {
            name: 'client',
            filter:["none"],
            selector: row => row.client,
  
        },
        {
            name: 'site',
            filter:["none"],
            selector: row => row.site,
  
        },
          {
              name: 'type',
              filter:["none"],
              selector: row => row.type, 
  
          },
          {
              name: 'notes',
              filter:["none"],
              cell: (row) => <div title={row.notes} className="preW">{row.notes}</div>,
            width:"300px"
  
          },
            {
                name: 'status',
                cell: (row) => <div className="audit-status" style={{backgroundColor: row.status == "pending" ? "#D4D243" : row.status == "rejected" ? "#EF1111" : row.status == "approved" ? "#6DB240" : "#ffffff"}}>
                <span>{row.status}</span></div>,
                ignoreRowClick: true,
                allowOverflow: false,
                button: false, 
            }, 
          //   {
          //     name: "resubmit",
          //     cell: (row) => <div className={`auth-button addUser ${row.status == "pending" || row.status == "approved" ? "disable-button" : ""}`} onClick={() => {
          //             row.status == "rejected" && row.user == user?.user?._id && resubmitReport(row.id)
          //     }} style={{width:"fit-content", height:"fit-content", padding:".5rem .7rem"}}>Re-submit</div>,
          //     ignoreRowClick: true,
          //     allowOverflow: false,
          //     button: true,
          //     width:"100px",
          //     style:{
          //         fontSize:"16px"
          //     }
          // },
    ];
    const accountTabs = [
        {
            name: "Step Admins",
            count: 20,
        },
        {
            name: "WMG Admins",
            count:12
        }
    ]
    const dispatch = useDispatch()

    const subScreen = (row) => {
      dispatch(ACTION_BACK_ROUTE({backRoute: "admins", routeName: "Admin Details"}))
      setSelectedAdmin(row)
    }
    const [deleteModal, setDeleteModal] = useState(null)

    
    useEffect(() => {
      if(!backRoute) {
        setSelectedAdmin(null)
      }
    },[backRoute])
    const menuToggle = (row) => {
     menu_clicked ? dispatch(ACTION_MENU_CLICKED_RESET()) : dispatch(ACTION_MENU_CLICKED(row))
    }
    const accountColumns = [
        {
            name: "Delete",
            cell: (row) => !user ? <img  style={{marginRight:"1rem", cursor:"pointer", color:"#EF1111"}} onClick={() => setDeleteModal(row)} src={archive} /> : user && user?.user?._id !== row.id ? (
              <img  style={{marginRight:"1rem", cursor:"pointer"}} onClick={() => setDeleteModal(row)} src={archive} />   
            ) : null,
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
            
            style:{
                fontSize:"19px"
            }
        },
        {
            name: 'status',
            cell: (row) => (
                <label style={{cursor:"pointer"}} onClick={() => {
                  updateUserStatusFunc(row)
                }}
                 className="toggle-control">
                    <span className={`${row.status ? "checked" : "notChecked"}`}></span>
                    <span className="control"></span>
                </label>
                ),
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
            style:{
                fontSize:"19px"
            }
            
        },
        {
            name: 'image',
            cell: row => (
              <img
                    className="adminAvatar"
                    src={row.image ? row.image : blank}
                    alt={
                      row.name[0]
                    }
              />
            ), 
            width: "50px"

        },
        {
            name: 'name',
            selector: row => row.name,
            style: {
                fontSize:"13px",
                fontWeight:"bold",
                textTransform:"capitalize"
            }
        },
        {
          name: 'type',
          selector: row => row.type,
          style: {
              fontSize:"13px",
              textTransform:"capitalize"
          }
      },
        {
            name: 'email',
            selector: row => row.email,
        }
    ];

    // add admin props

    const [
        addUser,
        { data: addUserData },
      ] = useMutation(addUserMutation)
  
      
    const [invitationModal, setInvitationModal] = useState(null)
    const [editUserModal, setEditUserModal] = useState(null)
    const [adminFirstName, setAdminFirstName] = useState("")
    const [adminLastName, setAdminLastName] = useState("")
    const [adminEmail, setAdminEmail] = useState("")
    const [oldEmail, setOldEmail] = useState("")
    const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const[userPhoneNumber, setUserPhoneNumber] = useState("")
    const [adminType, setAdminType] = useState("WMG Admin")

    // sub screen admin

    const [selectedAdmin , setSelectedAdmin] = useState(null)

      const [emailError, setEmailError] = useState("")
      useEffect(() => {
        if(location && accountData) {    
          if(location?.state?.license) {
            let newUser = accountData.find((x) => x.id == user?.user?._id)
            subScreen(newUser)
            dispatch(ACTION_LOADING_SPINNER_RESET())
          }
        } 
        return () => window.history.replaceState({}, document.title)
      },[location, accountData])

    const [images, setImages] = useState([])
    const inviteAdmin = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setInvitationModal(null)
      addUser({
        variables:{
          name: adminFirstName + " " + adminLastName,
          email: adminEmail,
          phoneNumber: userPhoneNumber,
          image: images?.map(x => x.url)[0],
          adminType: adminType,
          role:"admin"
        }
      })    
      .catch((err) => {
      if(err) {
        setInvitationModal("Admin")
        dispatch(ACTION_LOADING_SPINNER_RESET())
        setEmailError(err.message)

      } else {
        setInvitationModal(null)
      }
      })
      .then((res) => {
        if(res) {
        changeImageUploaded("");
        setAddProfileClicked(false)
        changeImagePreviewAvailable(false);
        changeTakeAPhotoSelected(false);
        changeWebcamURI("");
        setAdminFirstName("")
        setAdminLastName("")
        setAdminEmail("")
        setImages([])
        setUserPhoneNumber("")      
        adminsRefetch()
        }
      })
    }


    // admin add profile picture

    
    const [takeAPhotoSelected, changeTakeAPhotoSelected] = useState(false);
    const [webcamURI, changeWebcamURI] = useState("");
    const [imageUploaded, changeImageUploaded] = useState("");
    const [imagePreviewAvailable, changeImagePreviewAvailable] = useState(false);
    const [addProfilePhotoClicked, setAddProfileClicked] = useState(false)

    
    const handleDeletedPreviewImage = () => {
        const deleteImageClass = document.getElementsByClassName("deleteImage");
        const uploadPictureClass = document.getElementsByClassName("uploadPicture");
    
        if (deleteImageClass) {
          if (deleteImageClass[0]) {
            deleteImageClass[0].style.display = "none";
          }
        }
        if (uploadPictureClass) {
          if (uploadPictureClass[0]) {
            uploadPictureClass[0].style.display = "none";
          }
        }
      };
    
  const handleImageUploaded = async (picture) => {
    // dispatch(ACTION_IMAGE_LOADING());
    if (picture[0] || typeof picture === "string") {
      const reader = new FileReader();
      changeImagePreviewAvailable(true);
      try {
        let compressedImage;

        if (typeof picture === "object") {
          compressedImage = await imageCompression(picture[0], {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 300,
          });
        } else if (typeof picture === "string") {
          await fetch(picture)
            .then((res) => {
              return res.blob();
            })
            .then(async (blob) => {
              compressedImage = await imageCompression(blob, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 300,
              });
            });
        }

        reader.readAsDataURL(compressedImage);

        reader.onloadend = async () => {
          const base64data = reader.result;

          const compressedBase64data = LZString.compressToEncodedURIComponent(
            base64data
          );
        //   dispatch(ACTION_IMAGE_LOADING_RESET());
          changeImageUploaded(compressedBase64data);
        };
      } catch (error) {
        // dispatch(ACTION_IMAGE_LOADING_RESET());
        console.log(error);
      }
    } else {
    //   dispatch(ACTION_IMAGE_LOADING_RESET());
      changeImageUploaded("");
      changeImagePreviewAvailable(false);
      handleDeletedPreviewImage();
    }
  };

  // toggle admin status

  
  const [
    updateUserStatus,
    { data: updateUserStatusData },
  ] = useMutation(updateUserStatusMutation)


  const updateUserStatusFunc = (row, wmg) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    if(wmg) {
      const newRow = row
      newRow.wmg = !newRow.wmg
      setAccountData(accountData.map(t => t.id === newRow.id ? newRow : t))
      updateUserStatus({
        variables:{
          _id: newRow.id,
          wmg: newRow.status
        }
      }).then(() => {
        dispatch(ACTION_LOADING_SPINNER_RESET())
        adminsRefetch()
      }) 
    } else {
       const newRow = row
      newRow.status = !newRow.status
      setAccountData(accountData.map(t => t.id === newRow.id ? newRow : t))
      updateUserStatus({
        variables:{
          _id: newRow.id,
          status: newRow.status
        }
      }).then(() => {
        dispatch(ACTION_LOADING_SPINNER_RESET())
        adminsRefetch()
      }) 
    }
  
  }

  
  // delete admin

  const [ 
    deleteUser,
    { data: deleteUserData },
  ] = useMutation(deleteUserMutation)

  

  const deleteUserFunc = (row) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())

      deleteUser({
        variables:{
          _id: row.id,
        }
      }).then(() => {
        setSelectedAdmin(null)
        dispatch(ACTION_BACK_ROUTE_RESET())
        adminsRefetch()
      })
  }
    

  
  // edit user func.

  const editUser = (admin) => {
    setAdminEmail(admin.email)
    setOldEmail(admin.email)
    setAdminFirstName(admin.name.split(" ")[0])
    setAdminLastName(admin.name.split(" ")[1])
    setAdminType(admin.type)
    setUserPhoneNumber(admin.phone)
    admin?.image && setImages([{url: admin?.image}])
    setEditUserModal("Edit Admin")
    
}

// update user func.

const [
  updateUser,
  { data: updateUserData },
] = useMutation(updateUserMutation)

const updateUserFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setEditUserModal(null)
  const newAdmin = selectedAdmin
  newAdmin.name = adminFirstName + " " + adminLastName,
  newAdmin.email = adminEmail,
  newAdmin.phone = userPhoneNumber,
  newAdmin.type = adminType,
  newAdmin.image = images?.map(x => x.url)[0],


  setSelectedAdmin(newAdmin)
  setAccountData(accountData.map(t => t.id === newAdmin.id ? newAdmin : t))

  updateUser({
    variables:{
      _id: selectedAdmin.id,
      name: adminFirstName + " " + adminLastName,
      email: adminEmail == oldEmail ? null : adminEmail,
      phoneNumber: userPhoneNumber,
      adminType: adminType,
      image: images?.map(x => x.url)[0],

    }
  })
  .catch((err) => {
    if(err) {
      setEditUserModal("Edit Admin")
      dispatch(ACTION_LOADING_SPINNER_RESET())
      setEmailError(err.message)

    } else {
      setEditUserModal(null)
    }
    })
  .then((res) => {
    if(res) {
    changeImageUploaded(""); 
    setAddProfileClicked(false)
    changeImagePreviewAvailable(false);
    changeTakeAPhotoSelected(false);
    changeWebcamURI("");
    setAdminFirstName("")
    setAdminLastName("")
    setAdminEmail("")
    setOldEmail("")
    setImages([])
    setUserPhoneNumber("")
    dispatch(ACTION_LOADING_SPINNER_RESET())    
    adminsRefetch()
    }
  })

}



    // admin search 
    const [searchFilter, setSearchFilter] = useState("")
    const [adminsFilter, setAdminsFilter] = useState(null)
    useEffect(() => {
      if (admins) {
        if (admins.length > 0) {
          if (searchFilter) {
            setAdminsFilter(
            [...admins].filter((x) => {
                return (
                  new RegExp(searchFilter, "gi").test(x.adminType) ||
                  new RegExp(searchFilter, "gi").test(x.name) ||
                  new RegExp(searchFilter, "gi").test(x.email) ||
                  new RegExp(searchFilter, "gi").test(x.phoneNumber)
                );
              })
            )
          } else {
            setAdminsFilter(null)
          }
        }
      }
    }, [searchFilter, admins]);

    
    useEffect(() => {
      if(adminsFilter) {
        setAccountData(
          adminsFilter.map((admin) => {
            const mappingUser = {
                id: admin._id,
                image: admin.image,
                status: admin.status,
                wmg: admin.wmg,
                type: admin.adminType,
                name: admin.name,
                email: admin.email,
                phone: admin.phoneNumber,
                license: admin.licenseNumber ? JSON.parse(admin.licenseNumber) : null,

            }
            return mappingUser
        })  
        )
        dispatch(ACTION_LOADING_SPINNER_RESET())
      } else if (admins) {
        setAccountData(
          admins.map((admin) => {
            const mappingUser = {
                id: admin._id,
                image: admin.image,
                status: admin.status,
                wmg: admin.wmg,
                type: admin.adminType,
                name: admin.name,
                email: admin.email,
                phone: admin.phoneNumber,
                license: admin.licenseNumber ? JSON.parse(admin.licenseNumber) : null,
            }
            return mappingUser
        })  
        )
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
    },[adminsFilter, admins])


const [reports, setReports] = useState(null)

useMemo(() => {
  if(allReportsData) {
      setReports(
        allReportsData?.allReports?.map((report) => {
                let activeUser = users.find(x => x._id == report.user)
          const mappingUser = {
              id: report._id,
              dateOfAudit: report.dateOfAudit,
              updatedBy: report.updatedBy,
              generationDate: report.dateOfReportGeneration,
              type: report.type ? report.type : "Free",
              rejectionReason: report.rejectionReason,
              notes: report.note ? report.note : "No notes",
              template: report.html,
              css: report.css,
              status: report.status,
              user: report.user,  
              userName: activeUser?.name,               
              client: report?.client?.companyName,
              site: report?.site?.name,
              count: report.count
          }
          return mappingUser
      })  
      )
      dispatch(ACTION_LOADING_SPINNER_RESET())
  }
},[allReportsData])
  
useEffect(() => {
  if(selectedAdmin) {
    
    const limit = PageSize;
    const skip = (currentPageReportsUser - 1) * limit;
      allReportsByUser({
        variables:{
          wmg: selectedAdmin.id,
          skip,
          limit 
        } 
      })
  }
},[selectedAdmin, currentPageReportsUser])

const [licenseModal, setLicenseModal] = useState(null)
const [freeReports, setFreeReports] = useState("")
const [licenseUser, setLicenseUser] = useState("")
const [licenseNotes, setLicenseNotes] = useState("")
const [expirationDate, setExpirationDate] = useState(null);
const [selectedLicense, setSelectedLicense] = useState(null)
const [reportsModal, setReportsModal] = useState(null)
const [purchasedReports, setPurchasedReports] = useState("")
const [reportsBundle, setReportsBundle] = useState("")


const [
  updateLicense,
  { data: updateLicenseData },
] = useMutation(updateLicenseMutation)


const addReportsFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setReportsModal(null)  
  updateLicense({
      variables:{
          _id: selectedLicense._id,
          purchasedReports: reportsBundle,
          expired:false
      }
  }).then((res) => {
    let newSelectedUser = selectedAdmin
      newSelectedUser.license = [res.data.updateLicense]
      setSelectedAdmin(newSelectedUser)
      setAccountData(accountData.map(t => t.id === newSelectedUser.id ? newSelectedUser : t))
      setSelectedLicense(null)
      setPurchasedReports("")
      setReportsBundle("")
      setFreeReports("")
      setExpirationDate("")
      adminsRefetch()
      licensesRefetch()
  })

}

const editReportsBundle = () => {
  if(selectedAdmin) {
    if(user?.user?.role == "admin") {
      setSelectedLicense(selectedAdmin.license[0])
      setFreeReports(selectedAdmin.license[0]?.freeReports || 0)
      setPurchasedReports(selectedAdmin.license[0].purchasedReports || 0)
      selectedAdmin.license[0]?.endDate && setExpirationDate(new Date(selectedAdmin.license[0].endDate))
      setReportsModal("Reports")
  }
}
}
    const TableData = React.memo(props => {
      let {
        selectedUser,
        user,
        reportColumns,
        reports
      } = props
      return(
        <DataTable
                    columns={reportColumns}
                    data={reports || []}
                    progressPending={props.reportLoading}
                    progressComponent={RenderSitesLoader({small:"l"})}
    
        />
      )
    })

    return (
        <div
        style = {{
          justifyContent : backRoute ? "flex-start" : "center",
          flexDirection: backRoute ? "column" : "row"
        }}            
         className="adminContent">
<AddReportsModal 
        user={user}
         reportsModal={reportsModal}
         setReportsModal={setReportsModal}
         freeReports={freeReports}
         setFreeReports={setFreeReports}
         purchasedReports={purchasedReports}
         setPurchasedReports={setPurchasedReports}
         expirationDate={expirationDate}
         setExpirationDate={setExpirationDate}
         reportsBundle={reportsBundle}
         setReportsBundle={setReportsBundle}
         setSelectedLicense={setSelectedLicense}
         selectedLicense={selectedLicense}
         addReportsFunc={addReportsFunc}
        />

<ConfirmDeleteModal
                deleteType={"Account"}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                userType={"Admin"}
                deleteUserFunc={deleteUserFunc}
                />

          {returnToHomeScreen()}
          {editUserModal && (
            <AddAdminModal
                images={images}
                setImages={setImages}
                emailError={emailError}
                setEmailError={setEmailError}
                invitationModal={editUserModal}
                setInvitationModal={setEditUserModal}
                setUserFirstName={setAdminFirstName}
                userFirstName={adminFirstName}
                setUserLastName={setAdminLastName}
                userLastName={adminLastName}
                setUserEmail={setAdminEmail}
                userEmail={adminEmail}
                inviteUser={inviteAdmin}
                emailAlreadyRegistered={emailAlreadyRegistered}
                invalidEmail={invalidEmail}
                setEmailAlreadyRegistered={setEmailAlreadyRegistered}
                setInvalidEmail={setInvalidEmail}
                setUserPhoneNumber={setUserPhoneNumber}
                userPhoneNumber={userPhoneNumber}
                adminType={adminType}
                setAdminType={setAdminType}
                // avatar props
                takeAPhotoSelected={takeAPhotoSelected}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                handleImageUploaded={handleImageUploaded}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
                updateUserFunc={updateUserFunc}

            />
          )}
          {!selectedAdmin ? (
            <AdminsTable 
            images={images}
                    setImages={setImages}
            emailError={emailError}
          setEmailError={setEmailError}
            accountData={accountData} 
            accountColumns={accountColumns} 
            accountTabs={accountTabs} 
            accountType="Admin"
            // search admins
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            // add modal
            invitationModal={invitationModal}
            setInvitationModal={setInvitationModal}
            setUserFirstName={setAdminFirstName}
            userFirstName={adminFirstName}
            setUserLastName={setAdminLastName}
            userLastName={adminLastName}
            setUserEmail={setAdminEmail}
            userEmail={adminEmail}
            inviteUser={inviteAdmin}
            emailAlreadyRegistered={emailAlreadyRegistered}
            invalidEmail={invalidEmail}
            setEmailAlreadyRegistered={setEmailAlreadyRegistered}
            setInvalidEmail={setInvalidEmail}
            userPhoneNumber={userPhoneNumber}
            setUserPhoneNumber={setUserPhoneNumber}
            adminType={adminType}
            setAdminType={setAdminType}
            // photo props
            takeAPhotoSelected={takeAPhotoSelected}
            changeTakeAPhotoSelected={changeTakeAPhotoSelected}
            webcamURI={webcamURI}
            changeWebcamURI={changeWebcamURI}
            imageUploaded={imageUploaded}
            changeImageUploaded={changeImageUploaded}
            imagePreviewAvailable={imagePreviewAvailable}
            changeImagePreviewAvailable={changeImagePreviewAvailable}
            handleDeletedPreviewImage={handleDeletedPreviewImage}
            handleImageUploaded={handleImageUploaded}
            addProfilePhotoClicked={addProfilePhotoClicked}
            setAddProfileClicked={setAddProfileClicked}
            subScreen={subScreen}

            />
          ) : (
            <div className="adminDetailsContainer">
              <AccountHeader deleteType={"Admin"} user={user} editUser={editUser} deleteUserFunc={deleteUserFunc} updateUser={updateUserStatusFunc} selectedAccount={selectedAdmin} />
              {selectedAdmin?.type?.includes("WMG") && user?.user?.id == selectedAdmin?._id ?<div className="userReports" style={{ position:"relative", height:"80%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
            <div className="userSquareHeader" style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", flexWrap:"wrap"}}><span>Generated Reports
            {<div style={{display:"inline-block"}}><img id="UncontrolledTooltipExample" width={15} style={{cursor:"pointer",
                color:"#5C92BF", margin:"0 10px"}} src={infoI} onClick={() => dispatch(ACTION_INFO_ACTIVE("Generated reports is the number of reports your company has generated to date. These reports have been reviewed by the step team and accepted (rejected reports are not included in this count). If there is more than one user from the same company (main user and sub-user), the dashboard will record and display the total number of reports generated for your company."))} /><UncontrolledTooltip
                placement="top"
                target="UncontrolledTooltipExample"
              >
                Need Help!
              </UncontrolledTooltip></div>}
              <div style={{ display:"flex"}}>
            <div style={{ margin:"0 1rem 0 0", alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                  <div style={{color:"#5C92BF", fontSize:"12px"}}>Reports Remaining</div>
                </div>
                <div style={{ margin:"0 1rem 0 0", alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                  <div style={{color:"grey", fontSize:"12px"}}>Purchased Reports</div>
                  <div style={{color:"#2F3133", fontSize:"12px", fontWeight:"bold", marginLeft:"7px"}}>{selectedAdmin?.license[0]?.purchasedReports || 0} Report{selectedAdmin?.license[0]?.purchasedReports ? Number(selectedAdmin?.license[0]?.purchasedReports) > 1 ? "s" : "":""}</div>
                </div>
                <div style={{ alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <div className="tableHeaderAdd">
                        <div  
                        style={{
                          color: "rgb(255, 255, 255)", 
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          fontSize:"14",
                          fontWeight:"bold"
                      }} 
                      className="auth-button addUser floatAddReport"
                      onClick={editReportsBundle}
                      >
                            <span>Add New Reports</span>
                        </div>
                        </div>
                </div>
            </div>
            </span>
            <div style={{ display:"flex"}}>
            <div style={{ margin:"0 1rem 0 0", alignItems:"center", display:"flex", flexDirection:"row"}}>
                  {/* <div style={{color:"#5C92BF", fontSize:"12px"}}>Reports Remaining</div> */}
                </div>
            </div></div>
            {<><div className="reportsContainer" style={{width:"100%", height:"100%", overflowY:"auto", paddingBottom:"50px"}}>
                <TableData 
                  selectedUser={selectedAdmin}
                  user={user}
                  reportColumns={reportColumns}
                  reports={reports}
                  reportLoading={allReportsLoading} 
                />
            </div>
            {reports?.length ? <div className="boxShadowClassName" style={{position:"absolute", bottom:20, right:20, left:"auto", background:"white", display:"flex", borderRadius:"50px", justifyContent:"center", alignItems:"center", padding:"10px",}}>
                <Pagination
                className="pagination-bar" 
                currentPage={currentPageReportsUser}
                totalCount={reports?.length ? reports[reports.length - 1]?.count : 0}
                pageSize={30}
                onPageChange={page => setCurrentPageReportsUser(page)}
            /></div> : <RenderSitesLoader small="s" />} </>}        
            </div>: null}
            </div>
          ) }
            
        </div>

    )
}

export default Admins