import { gql } from "apollo-boost";

const deleteLocationMutation = gql`
  mutation($_id: ID) {
    deleteLocation(_id: $_id) {
      _id
    }
  }
`;

export default deleteLocationMutation;
 