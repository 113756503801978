import React, { useRef, useCallback, useState, useEffect } from "react";
import { Layer, Stage, Line, Label, Tag, Text, Image, Transformer } from "react-konva";
import useImage from "use-image"
import archive from "../../../../../assests/icons/archive.svg"
import {
  useShapes,
  clearSelection,
  createCircle,
  createVector,
  createLocation,
  createLine,
  saveDiagram,
  reset,
  deleteShape,
} from "./state";
import { DRAG_DATA_KEY, SHAPE_TYPES } from "./constants";
import { Shape } from "./Shape";
import produce from "immer";
import {DataSpinner} from "../../../spinner/dataSpinner"
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faUndo } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas"
import ConfirmDeleteModal from "../../confirmDeleteModal";
import useAwsCredentials from "../../../../hooks/useAws";

const handleDragOver = (event) => event.preventDefault();


export function Canvas({setLoadingShape, loadingCanvas, addLocationToDeleted, fromArea, locationId, editArea, photo, history, setHistory, loadMode, shapeToDelete, selectShapeToDelete, setNewDiagramImage, areaLines, lines, setLines, diagramImage, setDiagramImage, areaShapes, locationMode}) {


  const { credentials, loading, error, fetchCredentials } = useAwsCredentials();  // Use the custom hook to fetch credentials

  const [trash] = useImage(archive, 'Anonymous');
  const mobilePayload = useSelector((state) => state.mobileDrag.payload);
  const [isSelected, setIsSelected] = useState(false)
  const isDrawing = React.useRef(false);
  const [tool, setTool] = React.useState('pen');

  const handleMouseDown = (e) => {
    if(!isSelected) {
       isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, [pos.x, pos.y]]);
    }
   
  };

  
  const handleMouseMove = (e) => {
    // no drawing - skipping
    if(!isSelected) {
      if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine =  [lastLine[0], lastLine[1], point.x, point.y]

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
    }
    
  };

  const handleMouseUp = () => {
    setActiveLine(null)
    if(!isSelected) {
      isDrawing.current = false;
      createLine(lines)
    }
  };

  const shapes = useShapes((state) => Object.entries(state.shapes));
  
  const stageRef = useRef();
  const stageImage = useRef();
  const undoFunc = () => {
    setActiveLine(null)
    let line = lines.find((x,i,a) => i == a.length - 1)
    let newLines = [...lines.filter((x,i,a) => i !== a.length - 1 )]
    setState((state) => { 
      state.lines = newLines
    }) 
    setLines([...newLines])
    let newHistory = [...history, line]
    setHistory(newHistory)
  }
  const redoFunc = () => {
    setActiveLine(null)
    let line = history.find((x,i,a) => i == a.length - 1)
    let newHistory = [...history.filter((x,i,a) => i !== a.length - 1 )]
    setHistory(newHistory)

    let newLines = [...lines, line]
    setState((state) => { 
      state.lines = newLines
    }) 
    setLines([...newLines])
  }
  const handleDrop = useCallback((event) => {
    setActiveLine(null)
    if(mobilePayload) {
    const draggedData = mobilePayload;
    if (draggedData) {

      const { offsetX, offsetY, type, clientHeight, clientWidth } = draggedData

      stageRef.current.setPointersPositions(event);

      const coords = stageRef.current.getPointerPosition();

      if (type === SHAPE_TYPES.LOCATION) {
        createLocation({ 
          x: coords.x,
          y: coords.y,
        });
      } else { 
        // Vector x, y is at the top,left corner
        createVector({
          type: type,
          x: coords.x,
          y: coords.y, 
        });
      } 
    }
    } else {
      const draggedData = event.nativeEvent.dataTransfer.getData(DRAG_DATA_KEY);
  
      if (draggedData) {
        const { offsetX, offsetY, type, clientHeight, clientWidth } = JSON.parse(
          draggedData
        );
  
        stageRef.current.setPointersPositions(event);
  
        const coords = stageRef.current.getPointerPosition();
  
        if (type === SHAPE_TYPES.LOCATION) {
          createLocation({
            x: coords.x - (offsetX - clientWidth / 2),
            y: coords.y - (offsetY - clientHeight / 2),
          });
        } else { 
          // Vector x, y is at the top,left corner
          createVector({
            type: type,
            x: coords.x - offsetX,
            y: coords.y - offsetY, 
          });
        } 
      }
    }
    
  }, [mobilePayload]);

  const isSelectedFunc = (v) => {
    setIsSelected(v)
  }

  const setState = (fn) => useShapes.set(produce(fn));

  useEffect(() => {
    if(areaShapes && Object.keys(areaShapes)?.length) {
      // setState((state) => {
      //   state.shapes = areaShapes
      // })
     let newAreaShapes = Object.entries(areaShapes)
     newAreaShapes.map(([key,shape]) => {
      setState((state) => { 
        state.shapes[key] = shape
      })
     })
    } else {
      setState((state) => { 
        state.shapes = {}
      })
    }
  },[areaShapes])

  useEffect(() => {
    if(areaLines?.length || lines?.length) {
      setActiveLine(null)
      setState((state) => { 
        state.lines = lines?.length ? lines : areaLines
      }) 
      lines?.length ? setLines([...lines]) : setLines ? setLines([...areaLines]) : null
      
    } else {
      setState((state) => { 
        state.lines = []
      }) 
      setLines && setLines([])
    }
  },[areaLines])




  const [ activeColor, setActiveColor] = useState("#000000")
  const [img] = useImage(photo, 'Anonymous');

  const selected = useShapes((state) => state.selected);

  const [activeLine, setActiveLine] = useState(null)

  const shapeRef = useRef();
  const transformerRef = useRef();
  useEffect(() => {
    if (isSelected) {
      setActiveLine(null)
      transformerRef?.current?.nodes([shapeRef?.current]);
      transformerRef?.current?.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  const boundBoxCallbackForCircle = (oldBox, newBox) => {
    if (
      newBox.width < LIMITS.CIRCLE.MIN ||
      newBox.height < LIMITS.CIRCLE.MIN ||
      newBox.width > LIMITS.CIRCLE.MAX ||
      newBox.height > LIMITS.CIRCLE.MAX
    ) {
      return oldBox;
    }
    return newBox;
  }; 

  const deleteLine = (deleted) => {

    let line = lines.find((x,i,a) => x == deleted)
    let newLines = [...lines.filter((x,i,a) =>  x !== deleted)]
    setState((state) => { 
      state.lines = newLines
    }) 
    setLines([...newLines])
    let newHistory = [...history, line]
    setHistory(newHistory)
    setActiveLine(null)

  }

  const [newPhoto, setNewPhoto] = useState(null)
  const [handleDeleteModal, setHandleDeleteModal] = useState(null)

  const deleteShapeFunc = ({id, event, isNew}) => {
    if(!isNew && id.includes("location")) {
        addLocationToDeleted(id)
        deleteShape(id, event);
      } else {
          deleteShape(id, event);
      } 
  }
  useEffect(() => {
    if (!photo) {
      setNewPhoto("no");
      return;
    }

    let isMounted = true; // Prevent setting state if component unmounts

    const fetchData = async () => {
      try {
        const myBucket = await fetchCredentials();
        if (!myBucket || !isMounted) return;
        const key = photo.slice(photo.indexOf("amazonaws.com/") + 14);
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: key,
        };

        myBucket
          .getObject(params)
          .on("complete", (evt) => {
            if (!isMounted) return;

            if (evt?.data?.Body) {
              const blob = new Blob([evt.data.Body], { type: "image/png" });
              const reader = new FileReader();

              reader.onloadend = function () {
                if (isMounted) {
                  setNewPhoto(reader.result);
                }
              };

              reader.readAsDataURL(blob);
            }
          })
          .send((err) => {
            if (err) console.error("S3 Fetch Error:", err);
          });
      } catch (err) {
        console.error("Error fetching credentials or data:", err);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [photo]); // Re-run effect when `photo` changes

useEffect(() => {   
  return () => {
    setNewPhoto(null)
  } 
},[])

if(!newPhoto) { 
  return <DataSpinner />
}


  return (
    <main className="canvas" style={{position:"relative", touchAction:"none"}} onDrop={handleDrop} onDragOver={handleDragOver}>
      {handleDeleteModal ? (
        <ConfirmDeleteModal
          deleteType={handleDeleteModal?.id?.includes("location") ? handleDeleteModal.id : "shape"}
          deleteModal={handleDeleteModal}
          setDeleteModal={setHandleDeleteModal}
          userType={"Admin"}
          deleteUserFunc={deleteShapeFunc}
        />
      ) : null}
    {activeLine && (
      <div onClick={() => deleteLine(activeLine)} style={{cursor:"pointer", position:"absolute", zIndex:"9999", left:activeLine[0]+10, top:activeLine[1] + 10}}>
        <img 
          src={archive}
        />
      </div>
    ) }

  {/* {selected && (
    <div style={{width:"100%", position:"absolute", bottom:0, margin:0, display:"flex", justifyContent:"center", alignItems:"center", zIndex:99999}}     
    >  
          <div
          onDrop={() => alert(selected)} 
          style={{width:"50px", height:"50px", borderRadius:"50%"}}
          className="auth-button addUser confirmDelete deleteButton"
      >  
      </div>
      </div>
        )}     */}
      {!locationMode && <><div style={{padding:"5px 10px", zIndex:"2", top:0, left:0 ,position:"absolute", opacity: lines?.length ? 1 : .3}}>
      <FontAwesomeIcon style={{cursor: lines?.length ? "pointer" : "auto"}} onClick={ lines?.length ? undoFunc : null} icon={faUndo} />
      </div>
      {/* <div style={{position:"absolute", top:0, width:"100%", height:"35px", padding:"5px 30px",zIndex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
        <div onClick={() => setActiveColor("#000000")} style={{paddingBottom: activeColor == "#000000" ? "10px" : "0px" }} className="colorCC"><div className="colorC" style={{backgroundColor: "#000000"}}></div></div>
        <div onClick={() => setActiveColor("#6DB240")} style={{paddingBottom: activeColor == "#6DB240" ? "10px" : "0px" }} className="colorCC"><div className="colorC" style={{backgroundColor: "#6DB240"}}></div></div>
        <div onClick={() => setActiveColor("#EF1111")} style={{paddingBottom: activeColor == "#EF1111" ? "10px" : "0px" }} className="colorCC"><div className="colorC" style={{backgroundColor: "#EF1111"}}></div></div>
        <div onClick={() => setActiveColor("#505463")} style={{paddingBottom: activeColor == "#505463" ? "10px" : "0px" }} className="colorCC"><div className="colorC" style={{backgroundColor: "#505463"}}></div></div>
        <div onClick={() => setActiveColor("#B9B8BC")} style={{paddingBottom: activeColor == "#B9B8BC" ? "10px" : "0px" }} className="colorCC"><div className="colorC" style={{backgroundColor: "#B9B8BC"}}></div></div>
        <div onClick={() => setActiveColor("#D89E42")} style={{paddingBottom: activeColor == "#D89E42" ? "10px" : "0px" }} className="colorCC"><div className="colorC" style={{backgroundColor: "#D89E42"}}></div></div>
      </div> */}
      <div style={{padding:"5px 10px", zIndex:"2", top:0, right:0, position:"absolute", opacity: history?.length ? 1 : .3}}>
        <FontAwesomeIcon style={{cursor: history?.length ? "pointer" : "auto"}} onClick={ history?.length ? redoFunc : null} icon={faRedo} />
      </div></>}
      <div id="stageImage" style={{position:"relative", width:"100%", height:"100%"}}>
        {newPhoto && newPhoto !== "no" ? <div style={{ height:"350px", width:"350px", position:"absolute"}}><div style={{margin:"auto auto", height:"350px", width:"350px",display:"flex", justifyContent:"center"}}>
        <div style={{width:"100%", height:"100%", zIndex:-100,opacity:0.8, objectFit:"contain", backgroundImage:`url(${newPhoto})`, backgroundSize:"contain", backgroundRepeat:"no-repeat", backgroundPosition:"center"}} />
          </div></div> : null }
      <Stage
        ref={stageRef}
        width={350}
        height={350} 
        onClick={clearSelection}
        onMouseDown={!locationMode && handleMouseDown}
        onMousemove={!locationMode && handleMouseMove}
        onMouseup={!locationMode && handleMouseUp}
        onTouchStart={!locationMode && handleMouseDown}
        onTouchMove={!locationMode && handleMouseMove}
        onTouchEnd={!locationMode && handleMouseUp}
      >
        <Layer>
        {/* { img && <Image image={img} height={350} width={100} opacity={1} /> }  */}
        {/* <Image image={img}
        width="50p"
        height="50" 
        /> */}
        {lines?.length || history?.length ? lines.map((line, i) => (
            <Line 
              onDblClick={() => {
                setActiveLine(line)
              }}
              key={i}
              ref={shapeRef}
              points={line}
              stroke={activeLine ? JSON.stringify(activeLine) ==  JSON.stringify(line) ? "#EF1111" : "#000000" : "#000000"}
              strokeWidth={5}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation={
                line.tool === 'eraser' ? 'destination-out' : 'source-over'
              }
            />
          )) : (
            areaLines?.map((line, i) => (
              <Line
              onDblClick={() => {
                setActiveLine(line)
              }}
                key={i}
                points={line}
                              stroke={activeLine ? JSON.stringify(activeLine) ==  JSON.stringify(line) ? "#EF1111" : "#000000" : "#000000"}

                strokeWidth={5}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  line.tool === 'eraser' ? 'destination-out' : 'source-over'
                }
              />
            )
          ))}
         {shapes ? shapes.sort((a,b) => a[1].type == "location" ? 1 : -1).map(([key, shape], i) => (
            <Shape key={i} setHandleDeleteModal={setHandleDeleteModal} loadingCanvas={loadingCanvas} passed={shape.passed} addLocationToDeleted={addLocationToDeleted} fromArea={fromArea} locationId={locationId} shapeToDelete={shapeToDelete} loadMode={loadMode} selectShapeToDelete={selectShapeToDelete} isSelectedFunc={isSelectedFunc} locationMode={locationMode} isNew={shape.new} shape={{ ...shape, id: key }} />
         )) : <DataSpinner />}
        </Layer>
      </Stage>
      </div>
      {shapes && shapes.filter(([key, shape]) => key.includes("location")).map(([key, shape], i) => (
      <span key={i} style={{position:"absolute", top:shape.y + shape.radius, left:shape.x, fontWeight:"bold" }}>{key.substring(11, key.length)}</span>
))}
    </main>
  );
}
