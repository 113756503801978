export const territories = [
    {label: "New York-Newark-Jersey City, NY-NJ-PA MSA", value: "New York-Newark-Jersey City, NY-NJ-PA MSA"},
    {label: "Los Angeles-Long Beach-Anaheim, CA MSA", value: "Los Angeles-Long Beach-Anaheim, CA MSA"},
    {label: "Chicago-Naperville-Elgin, IL-IN-WI MSA", value: "Chicago-Naperville-Elgin, IL-IN-WI MSA"},
    {label: "Dallas-Fort Worth-Arlington, TX MSA", value: "Dallas-Fort Worth-Arlington, TX MSA"},
    {label: "Houston-The Woodlands-Sugar Land, TX MSA", value: "Houston-The Woodlands-Sugar Land, TX MSA"},
    {label: "Washington-Arlington-Alexandria, DC-VA-MD-WV MSA", value: "Washington-Arlington-Alexandria, DC-VA-MD-WV MSA"},
    {label: "Miami-Fort Lauderdale-West Palm Beach, FL MSA", value: "Miami-Fort Lauderdale-West Palm Beach, FL MSA"},
    {label: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD MSA", value: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD MSA"},
    {label: "Atlanta-Sandy Springs-Roswell, GA MSA", value: "Atlanta-Sandy Springs-Roswell, GA MSA"},
    {label: "Boston-Cambridge-Newton, MA-NH MSA", value: "Boston-Cambridge-Newton, MA-NH MSA"},
    {label: "Phoenix-Mesa-Scottsdale, AZ MSA", value: "Phoenix-Mesa-Scottsdale, AZ MSA"},
    {label: "San Francisco-Oakland-Hayward, CA MSA", value: "San Francisco-Oakland-Hayward, CA MSA"},
    {label: "Riverside-San Bernardino-Ontario, CA MSA", value: "Riverside-San Bernardino-Ontario, CA MSA"},
    {label: "Detroit-Warren-Dearborn, MI MSA", value: "Detroit-Warren-Dearborn, MI MSA"},
    {label: "Seattle-Tacoma-Bellevue, WA MSA", value: "Seattle-Tacoma-Bellevue, WA MSA"},
    {label: "Minneapolis-St. Paul-Bloomington, MN-WI MSA", value: "Minneapolis-St. Paul-Bloomington, MN-WI MSA"},
    {label: "San Diego-Carlsbad, CA MSA", value: "San Diego-Carlsbad, CA MSA"},
    {label: "Tampa-St. Petersburg-Clearwater, FL MSA", value: "Tampa-St. Petersburg-Clearwater, FL MSA"},
    {label: "Denver-Aurora-Lakewood, CO MSA", value: "Denver-Aurora-Lakewood, CO MSA"},
    {label: "St. Louis, MO-IL MSA", value: "St. Louis, MO-IL MSA"},
    {label: "Baltimore-Columbia-Towson, MD MSA", value: "Baltimore-Columbia-Towson, MD MSA"},
    {label: "Orlando-Kissimmee-Sanford, FL MSA", value: "Orlando-Kissimmee-Sanford, FL MSA"},
    {label: "Charlotte-Concord-Gastonia, NC-SC MSA", value: "Charlotte-Concord-Gastonia, NC-SC MSA"},
    {label: "San Antonio-New Braunfels, TX MSA", value: "San Antonio-New Braunfels, TX MSA"},
    {label: "Portland-Vancouver-Hillsboro, OR-WA MSA", value: "Portland-Vancouver-Hillsboro, OR-WA MSA"},
    {label: "Sacramento�Roseville�Arden-Arcade, CA MSA", value: "Sacramento�Roseville�Arden-Arcade, CA MSA"},
    {label: "Pittsburgh, PA MSA", value: "Pittsburgh, PA MSA"},
    {label: "Las Vegas-Henderson-Paradise, NV MSA", value: "Las Vegas-Henderson-Paradise, NV MSA"},
    {label: "Cincinnati, OH-KY-IN MSA", value: "Cincinnati, OH-KY-IN MSA"},
    {label: "Austin-Round Rock, TX MSA", value: "Austin-Round Rock, TX MSA"},
    {label: "Kansas City, MO-KS MSA", value: "Kansas City, MO-KS MSA"},
    {label: "Columbus, OH MSA", value: "Columbus, OH MSA"},
    {label: "Cleveland-Elyria, OH MSA", value: "Cleveland-Elyria, OH MSA"},
    {label: "Indianapolis-Carmel-Anderson, IN MSA", value: "Indianapolis-Carmel-Anderson, IN MSA"},
    {label: "San Jose-Sunnyvale-Santa Clara, CA MSA", value: "San Jose-Sunnyvale-Santa Clara, CA MSA"},
    {label: "Nashville-Davidson�Murfreesboro�Franklin, TN MSA", value: "Nashville-Davidson�Murfreesboro�Franklin, TN MSA"},
    {label: "Virginia Beach-Norfolk-Newport News, VA-NC MSA", value: "Virginia Beach-Norfolk-Newport News, VA-NC MSA"},
    {label: "Providence-Warwick, RI-MA MSA", value: "Providence-Warwick, RI-MA MSA"},
    {label: "Milwaukee-Waukesha-West Allis, WI MSA", value: "Milwaukee-Waukesha-West Allis, WI MSA"},
    {label: "Jacksonville, FL MSA", value: "Jacksonville, FL MSA"},
    {label: "Oklahoma City, OK MSA", value: "Oklahoma City, OK MSA"},
    {label: "Raleigh, NC MSA", value: "Raleigh, NC MSA"},
    {label: "Memphis, TN-MS-AR MSA", value: "Memphis, TN-MS-AR MSA"},
    {label: "Richmond, VA MSA", value: "Richmond, VA MSA"},
    {label: "Louisville�Jefferson County, KY-IN MSA", value: "Louisville�Jefferson County, KY-IN MSA"},
    {label: "New Orleans-Metairie, LA MSA", value: "New Orleans-Metairie, LA MSA"},
    {label: "Salt Lake City, UT MSA", value: "Salt Lake City, UT MSA"},
    {label: "Hartford-West Hartford-East Hartford, CT MSA", value: "Hartford-West Hartford-East Hartford, CT MSA"},
    {label: "Birmingham-Hoover, AL MSA", value: "Birmingham-Hoover, AL MSA"},
    {label: "Buffalo-Cheektowaga-Niagara Falls, NY MSA", value: "Buffalo-Cheektowaga-Niagara Falls, NY MSA"},
    {label: "Rochester, NY MSA", value: "Rochester, NY MSA"},
    {label: "Grand Rapids-Wyoming, MI MSA", value: "Grand Rapids-Wyoming, MI MSA"},
    {label: "Tucson, AZ MSA", value: "Tucson, AZ MSA"},
    {label: "Fresno, CA MSA", value: "Fresno, CA MSA"},
    {label: "Tulsa, OK MSA", value: "Tulsa, OK MSA"},
    {label: "Urban Honolulu, HI MSA", value: "Urban Honolulu, HI MSA"},
    {label: "Worcester, MA-CT MSA", value: "Worcester, MA-CT MSA"},
    {label: "Bridgeport-Stamford-Norwalk, CT MSA", value: "Bridgeport-Stamford-Norwalk, CT MSA"},
    {label: "Omaha-Council Bluffs, NE-IA MSA", value: "Omaha-Council Bluffs, NE-IA MSA"},
    {label: "Albuquerque, NM MSA", value: "Albuquerque, NM MSA"},
    {label: "Greenville-Anderson-Mauldin, SC MSA", value: "Greenville-Anderson-Mauldin, SC MSA"},
    {label: "Bakersfield, CA MSA", value: "Bakersfield, CA MSA"},
    {label: "Knoxville, TN MSA", value: "Knoxville, TN MSA"},
    {label: "Albany-Schenectady-Troy, NY MSA", value: "Albany-Schenectady-Troy, NY MSA"},
    {label: "McAllen-Edinburg-Mission, TX MSA", value: "McAllen-Edinburg-Mission, TX MSA"},
    {label: "New Haven-Milford, CT MSA", value: "New Haven-Milford, CT MSA"},
    {label: "Oxnard-Thousand Oaks-Ventura, CA MSA", value: "Oxnard-Thousand Oaks-Ventura, CA MSA"},
    {label: "El Paso, TX MSA", value: "El Paso, TX MSA"},
    {label: "Allentown-Bethlehem-Easton, PA-NJ MSA", value: "Allentown-Bethlehem-Easton, PA-NJ MSA"},
    {label: "Columbia, SC MSA", value: "Columbia, SC MSA"},
    {label: "Baton Rouge, LA MSA", value: "Baton Rouge, LA MSA"},
    {label: "North Port-Sarasota-Bradenton, FL MSA", value: "North Port-Sarasota-Bradenton, FL MSA"},
    {label: "Dayton, OH MSA", value: "Dayton, OH MSA"},
    {label: "Charleston-North Charleston, SC MSA", value: "Charleston-North Charleston, SC MSA"},
    {label: "Greensboro-High Point, NC MSA", value: "Greensboro-High Point, NC MSA"},
    {label: "Cape Coral-Fort Myers, FL MSA", value: "Cape Coral-Fort Myers, FL MSA"},
    {label: "Stockton-Lodi, CA MSA", value: "Stockton-Lodi, CA MSA"},
    {label: "Little Rock-North Little Rock-Conway, AR MSA", value: "Little Rock-North Little Rock-Conway, AR MSA"},
    {label: "Colorado Springs, CO MSA", value: "Colorado Springs, CO MSA"},
    {label: "Boise City, ID MSA", value: "Boise City, ID MSA"},
    {label: "Lakeland-Winter Haven, FL MSA", value: "Lakeland-Winter Haven, FL MSA"},
    {label: "Akron, OH MSA", value: "Akron, OH MSA"},
    {label: "Ogden-Clearfield, UT MSA", value: "Ogden-Clearfield, UT MSA"},
    {label: "Winston-Salem, NC MSA", value: "Winston-Salem, NC MSA"},
    {label: "Madison, WI MSA", value: "Madison, WI MSA"},
    {label: "Deltona-Daytona Beach-Ormond Beach, FL MSA", value: "Deltona-Daytona Beach-Ormond Beach, FL MSA"},
    {label: "Des Moines-West Des Moines, IA MSA", value: "Des Moines-West Des Moines, IA MSA"},
    {label: "Syracuse, NY MSA", value: "Syracuse, NY MSA"},
    {label: "Wichita, KS MSA", value: "Wichita, KS MSA"},
    {label: "Provo-Orem, UT MSA", value: "Provo-Orem, UT MSA"},
    {label: "Springfield, MA MSA", value: "Springfield, MA MSA"},
    {label: "Augusta-Richmond County, GA-SC MSA", value: "Augusta-Richmond County, GA-SC MSA"},
    {label: "Toledo, OH MSA", value: "Toledo, OH MSA"},
    {label: "Palm Bay-Melbourne-Titusville, FL MSA", value: "Palm Bay-Melbourne-Titusville, FL MSA"},
    {label: "Jackson, MS MSA", value: "Jackson, MS MSA"},
    {label: "Durham-Chapel Hill, NC MSA", value: "Durham-Chapel Hill, NC MSA"},
    {label: "Harrisburg-Carlisle, PA MSA", value: "Harrisburg-Carlisle, PA MSA"},
    {label: "Spokane-Spokane Valley, WA MSA", value: "Spokane-Spokane Valley, WA MSA"},
    {label: "Chattanooga, TN-GA MSA", value: "Chattanooga, TN-GA MSA"},
    {label: "Scranton�Wilkes-Barre�Hazleton, PA MSA", value: "Scranton�Wilkes-Barre�Hazleton, PA MSA"},
    {label: "Modesto, CA MSA", value: "Modesto, CA MSA"},
    {label: "Fayetteville-Springdale-Rogers, AR-MO MSA", value: "Fayetteville-Springdale-Rogers, AR-MO MSA"},
    {label: "Lancaster, PA MSA", value: "Lancaster, PA MSA"},
    {label: "Youngstown-Warren-Boardman, OH-PA MSA", value: "Youngstown-Warren-Boardman, OH-PA MSA"},
    {label: "Portland-South Portland, ME MSA", value: "Portland-South Portland, ME MSA"},
    {label: "Lexington-Fayette, KY MSA", value: "Lexington-Fayette, KY MSA"},
    {label: "Santa Rosa, CA MSA", value: "Santa Rosa, CA MSA"},
    {label: "Pensacola-Ferry Pass-Brent, FL MSA", value: "Pensacola-Ferry Pass-Brent, FL MSA"},
    {label: "Lafayette, LA MSA", value: "Lafayette, LA MSA"},
    {label: "Port St. Lucie, FL MSA", value: "Port St. Lucie, FL MSA"},
    {label: "Lansing-East Lansing, MI MSA", value: "Lansing-East Lansing, MI MSA"},
    {label: "Myrtle Beach-Conway-North Myrtle Beach, SC-NC MSA", value: "Myrtle Beach-Conway-North Myrtle Beach, SC-NC MSA"},
    {label: "Reno, NV MSA", value: "Reno, NV MSA"},
    {label: "Springfield, MO MSA", value: "Springfield, MO MSA"},
    {label: "Visalia-Porterville, CA MSA", value: "Visalia-Porterville, CA MSA"},
    {label: "Huntsville, AL MSA", value: "Huntsville, AL MSA"},
    {label: "Asheville, NC MSA", value: "Asheville, NC MSA"},
    {label: "Corpus Christi, TX MSA", value: "Corpus Christi, TX MSA"},
    {label: "Killeen-Temple, TX MSA", value: "Killeen-Temple, TX MSA"},
    {label: "York-Hanover, PA MSA", value: "York-Hanover, PA MSA"},
    {label: "Vallejo-Fairfield, CA MSA", value: "Vallejo-Fairfield, CA MSA"},
    {label: "Santa Maria-Santa Barbara, CA MSA", value: "Santa Maria-Santa Barbara, CA MSA"},
    {label: "Fort Wayne, IN MSA", value: "Fort Wayne, IN MSA"},
    {label: "Shreveport-Bossier City, LA MSA", value: "Shreveport-Bossier City, LA MSA"},
    {label: "Salinas, CA MSA", value: "Salinas, CA MSA"},
    {label: "Salem, OR MSA", value: "Salem, OR MSA"},
    {label: "Brownsville-Harlingen, TX MSA", value: "Brownsville-Harlingen, TX MSA"},
    {label: "Reading, PA MSA", value: "Reading, PA MSA"},
    {label: "Manchester-Nashua, NH MSA", value: "Manchester-Nashua, NH MSA"},
    {label: "Mobile, AL MSA", value: "Mobile, AL MSA"},
    {label: "Salisbury, MD-DE MSA", value: "Salisbury, MD-DE MSA"},
    {label: "Beaumont-Port Arthur, TX MSA", value: "Beaumont-Port Arthur, TX MSA"},
    {label: "Flint, MI MSA", value: "Flint, MI MSA"},
    {label: "Anchorage, AK MSA", value: "Anchorage, AK MSA"},
    {label: "Canton-Massillon, OH MSA", value: "Canton-Massillon, OH MSA"},
    {label: "Gulfport-Biloxi-Pascagoula, MS MSA", value: "Gulfport-Biloxi-Pascagoula, MS MSA"},
    {label: "Savannah, GA MSA", value: "Savannah, GA MSA"},
    {label: "Fayetteville, NC MSA", value: "Fayetteville, NC MSA"},
    {label: "Tallahassee, FL MSA", value: "Tallahassee, FL MSA"},
    {label: "Davenport-Moline-Rock Island, IA-IL MSA", value: "Davenport-Moline-Rock Island, IA-IL MSA"},
    {label: "Eugene, OR MSA", value: "Eugene, OR MSA"},
    {label: "Naples-Immokalee-Marco Island, FL MSA", value: "Naples-Immokalee-Marco Island, FL MSA"},
    {label: "Montgomery, AL MSA", value: "Montgomery, AL MSA"},
    {label: "Ann Arbor, MI MSA", value: "Ann Arbor, MI MSA"},
    {label: "Trenton, NJ MSA", value: "Trenton, NJ MSA"},
    {label: "Hickory-Lenoir-Morganton, NC MSA", value: "Hickory-Lenoir-Morganton, NC MSA"},
    {label: "Peoria, IL MSA", value: "Peoria, IL MSA"},
    {label: "Ocala, FL MSA", value: "Ocala, FL MSA"},
    {label: "Huntington-Ashland, WV-KY-OH MSA", value: "Huntington-Ashland, WV-KY-OH MSA"},
    {label: "Fort Collins, CO MSA", value: "Fort Collins, CO MSA"},
    {label: "Spartanburg, SC MSA", value: "Spartanburg, SC MSA"},
    {label: "Kalamazoo-Portage, MI MSA", value: "Kalamazoo-Portage, MI MSA"},
    {label: "Rockford, IL MSA", value: "Rockford, IL MSA"},
    {label: "Lincoln, NE MSA", value: "Lincoln, NE MSA"},
    {label: "Boulder, CO MSA", value: "Boulder, CO MSA"},
    {label: "South Bend-Mishawaka, IN-MI MSA", value: "South Bend-Mishawaka, IN-MI MSA"},
    {label: "Green Bay, WI MSA", value: "Green Bay, WI MSA"},
    {label: "Lubbock, TX MSA", value: "Lubbock, TX MSA"},
    {label: "Evansville, IN-KY MSA", value: "Evansville, IN-KY MSA"},
    {label: "Greeley, CO MSA", value: "Greeley, CO MSA"},
    {label: "Roanoke, VA MSA", value: "Roanoke, VA MSA"},
    {label: "Kingsport-Bristol-Bristol, TN-VA MSA", value: "Kingsport-Bristol-Bristol, TN-VA MSA"},
    {label: "Columbus, GA-AL MSA", value: "Columbus, GA-AL MSA"},
    {label: "Kennewick-Richland, WA MSA", value: "Kennewick-Richland, WA MSA"},
    {label: "Wilmington, NC MSA", value: "Wilmington, NC MSA"},
    {label: "Clarksville, TN-KY MSA", value: "Clarksville, TN-KY MSA"},
    {label: "Utica-Rome, NY MSA", value: "Utica-Rome, NY MSA"},
    {label: "Gainesville, FL MSA", value: "Gainesville, FL MSA"},
    {label: "Olympia-Tumwater, WA MSA", value: "Olympia-Tumwater, WA MSA"},
    {label: "San Luis Obispo-Paso Robles-Arroyo Grande, CA MSA", value: "San Luis Obispo-Paso Robles-Arroyo Grande, CA MSA"},
    {label: "Fort Smith, AR-OK MSA", value: "Fort Smith, AR-OK MSA"},
    {label: "Duluth, MN-WI MSA", value: "Duluth, MN-WI MSA"},
    {label: "Crestview-Fort Walton Beach-Destin, FL MSA", value: "Crestview-Fort Walton Beach-Destin, FL MSA"},
    {label: "Laredo, TX MSA", value: "Laredo, TX MSA"},
    {label: "Merced, CA MSA", value: "Merced, CA MSA"},
    {label: "Santa Cruz-Watsonville, CA MSA", value: "Santa Cruz-Watsonville, CA MSA"},
    {label: "Cedar Rapids, IA MSA", value: "Cedar Rapids, IA MSA"},
    {label: "Erie, PA MSA", value: "Erie, PA MSA"},
    {label: "Waco, TX MSA", value: "Waco, TX MSA"},
    {label: "Bremerton-Silverdale, WA MSA", value: "Bremerton-Silverdale, WA MSA"},
    {label: "Hagerstown-Martinsburg, MD-WV MSA", value: "Hagerstown-Martinsburg, MD-WV MSA"},
    {label: "Norwich-New London, CT MSA", value: "Norwich-New London, CT MSA"},
    {label: "Amarillo, TX MSA", value: "Amarillo, TX MSA"},
    {label: "Sioux Falls, SD MSA", value: "Sioux Falls, SD MSA"},
    {label: "Atlantic City-Hammonton, NJ MSA", value: "Atlantic City-Hammonton, NJ MSA"},
    {label: "Lynchburg, VA MSA", value: "Lynchburg, VA MSA"},
    {label: "College Station-Bryan, TX MSA", value: "College Station-Bryan, TX MSA"},
    {label: "Yakima, WA MSA", value: "Yakima, WA MSA"},
    {label: "Fargo, ND-MN MSA", value: "Fargo, ND-MN MSA"},
    {label: "Tuscaloosa, AL MSA", value: "Tuscaloosa, AL MSA"},
    {label: "Binghamton, NY MSA", value: "Binghamton, NY MSA"},
    {label: "Champaign-Urbana, IL MSA", value: "Champaign-Urbana, IL MSA"},
    {label: "Appleton, WI MSA", value: "Appleton, WI MSA"},
    {label: "Charlottesville, VA MSA", value: "Charlottesville, VA MSA"},
    {label: "Topeka, KS MSA", value: "Topeka, KS MSA"},
    {label: "Prescott, AZ MSA", value: "Prescott, AZ MSA"},
    {label: "Chico, CA MSA", value: "Chico, CA MSA"},
    {label: "Tyler, TX MSA", value: "Tyler, TX MSA"},
    {label: "Macon-Bibb County, GA MSA", value: "Macon-Bibb County, GA MSA"},
    {label: "Bellingham, WA MSA", value: "Bellingham, WA MSA"},
    {label: "Lafayette-West Lafayette, IN MSA", value: "Lafayette-West Lafayette, IN MSA"},
    {label: "Burlington-South Burlington, VT MSA", value: "Burlington-South Burlington, VT MSA"},
    {label: "Rochester, MN MSA", value: "Rochester, MN MSA"},
    {label: "Medford, OR MSA", value: "Medford, OR MSA"},
    {label: "Longview, TX MSA", value: "Longview, TX MSA"},
    {label: "Daphne-Fairhope-Foley, AL MSA", value: "Daphne-Fairhope-Foley, AL MSA"},
    {label: "Hilton Head Island-Bluffton-Beaufort, SC MSA", value: "Hilton Head Island-Bluffton-Beaufort, SC MSA"},
    {label: "Las Cruces, NM MSA", value: "Las Cruces, NM MSA"},
    {label: "Barnstable Town, MA MSA", value: "Barnstable Town, MA MSA"},
    {label: "Yuma, AZ MSA", value: "Yuma, AZ MSA"},
    {label: "Athens-Clarke County, GA MSA", value: "Athens-Clarke County, GA MSA"},
    {label: "Charleston, WV MSA", value: "Charleston, WV MSA"},
    {label: "Lake Charles, LA MSA", value: "Lake Charles, LA MSA"},
    {label: "Lake Havasu City-Kingman, AZ MSA", value: "Lake Havasu City-Kingman, AZ MSA"},
    {label: "Houma-Thibodaux, LA MSA", value: "Houma-Thibodaux, LA MSA"},
    {label: "Springfield, IL MSA", value: "Springfield, IL MSA"},
    {label: "Elkhart-Goshen, IN MSA", value: "Elkhart-Goshen, IN MSA"},
    {label: "Florence, SC MSA", value: "Florence, SC MSA"},
    {label: "Johnson City, TN MSA", value: "Johnson City, TN MSA"},
    {label: "Gainesville, GA MSA", value: "Gainesville, GA MSA"},
    {label: "Panama City, FL MSA", value: "Panama City, FL MSA"},
    {label: "St. Cloud, MN MSA", value: "St. Cloud, MN MSA"},
    {label: "Jacksonville, NC MSA", value: "Jacksonville, NC MSA"},
    {label: "Racine, WI MSA", value: "Racine, WI MSA"},
    {label: "Warner Robins, GA MSA", value: "Warner Robins, GA MSA"},
    {label: "Bend-Redmond, OR MSA", value: "Bend-Redmond, OR MSA"},
    {label: "Saginaw, MI MSA", value: "Saginaw, MI MSA"},
    {label: "Bloomington, IL MSA", value: "Bloomington, IL MSA"},
    {label: "Punta Gorda, FL MSA", value: "Punta Gorda, FL MSA"},
    {label: "Blacksburg-Christiansburg-Radford, VA MSA", value: "Blacksburg-Christiansburg-Radford, VA MSA"},
    {label: "El Centro, CA MSA", value: "El Centro, CA MSA"},
    {label: "Redding, CA MSA", value: "Redding, CA MSA"},
    {label: "Columbia, MO MSA", value: "Columbia, MO MSA"},
    {label: "Greenville, NC MSA", value: "Greenville, NC MSA"},
    {label: "Joplin, MO MSA", value: "Joplin, MO MSA"},
    {label: "Kingston, NY MSA", value: "Kingston, NY MSA"},
    {label: "Dover, DE MSA", value: "Dover, DE MSA"},
    {label: "Midland, TX MSA", value: "Midland, TX MSA"},
    {label: "Bowling Green, KY MSA", value: "Bowling Green, KY MSA"},
    {label: "Monroe, LA MSA", value: "Monroe, LA MSA"},
    {label: "Yuba City, CA MSA", value: "Yuba City, CA MSA"},
    {label: "Muskegon, MI MSA", value: "Muskegon, MI MSA"},
    {label: "Iowa City, IA MSA", value: "Iowa City, IA MSA"},
    {label: "St. George, UT MSA", value: "St. George, UT MSA"},
    {label: "Billings, MT MSA", value: "Billings, MT MSA"},
    {label: "Abilene, TX MSA", value: "Abilene, TX MSA"},
    {label: "Oshkosh-Neenah, WI MSA", value: "Oshkosh-Neenah, WI MSA"},
    {label: "Terre Haute, IN MSA", value: "Terre Haute, IN MSA"},
    {label: "Waterloo-Cedar Falls, IA MSA", value: "Waterloo-Cedar Falls, IA MSA"},
    {label: "East Stroudsburg, PA MSA", value: "East Stroudsburg, PA MSA"},
    {label: "Sioux City, IA-NE-SD MSA", value: "Sioux City, IA-NE-SD MSA"},
    {label: "Eau Claire, WI MSA", value: "Eau Claire, WI MSA"},
    {label: "Bloomington, IN MSA", value: "Bloomington, IN MSA"},
    {label: "Pueblo, CO MSA", value: "Pueblo, CO MSA"},
    {label: "Kahului-Wailuku-Lahaina, HI MSA", value: "Kahului-Wailuku-Lahaina, HI MSA"},
    {label: "Burlington, NC MSA", value: "Burlington, NC MSA"},
    {label: "Auburn-Opelika, AL MSA", value: "Auburn-Opelika, AL MSA"},
    {label: "Janesville-Beloit, WI MSA", value: "Janesville-Beloit, WI MSA"},
    {label: "State College, PA MSA", value: "State College, PA MSA"},
    {label: "Odessa, TX MSA", value: "Odessa, TX MSA"},
    {label: "Coeur d'Alene, ID MSA", value: "Coeur d'Alene, ID MSA"},
    {label: "Jackson, MI MSA", value: "Jackson, MI MSA"},
    {label: "Madera, CA MSA", value: "Madera, CA MSA"},
    {label: "Sebastian-Vero Beach, FL MSA", value: "Sebastian-Vero Beach, FL MSA"},
    {label: "Chambersburg-Waynesboro, PA MSA", value: "Chambersburg-Waynesboro, PA MSA"},
    {label: "Niles-Benton Harbor, MI MSA", value: "Niles-Benton Harbor, MI MSA"},
    {label: "Elizabethtown-Fort Knox, KY MSA", value: "Elizabethtown-Fort Knox, KY MSA"},
    {label: "Grand Junction, CO MSA", value: "Grand Junction, CO MSA"},
    {label: "Alexandria, LA MSA", value: "Alexandria, LA MSA"},
    {label: "Albany, GA MSA", value: "Albany, GA MSA"},
    {label: "Decatur, AL MSA", value: "Decatur, AL MSA"},
    {label: "Jefferson City, MO MSA", value: "Jefferson City, MO MSA"},
    {label: "Hanford-Corcoran, CA MSA", value: "Hanford-Corcoran, CA MSA"},
    {label: "Wichita Falls, TX MSA", value: "Wichita Falls, TX MSA"},
    {label: "Bangor, ME MSA", value: "Bangor, ME MSA"},
    {label: "Vineland-Bridgeton, NJ MSA", value: "Vineland-Bridgeton, NJ MSA"},
    {label: "Monroe, MI MSA", value: "Monroe, MI MSA"},
    {label: "Texarkana, TX-AR MSA", value: "Texarkana, TX-AR MSA"},
    {label: "Santa Fe, NM MSA", value: "Santa Fe, NM MSA"},
    {label: "Hattiesburg, MS MSA", value: "Hattiesburg, MS MSA"},
    {label: "Idaho Falls, ID MSA", value: "Idaho Falls, ID MSA"},
    {label: "Rapid City, SD MSA", value: "Rapid City, SD MSA"},
    {label: "Dothan, AL MSA", value: "Dothan, AL MSA"},
    {label: "Homosassa Springs, FL MSA", value: "Homosassa Springs, FL MSA"},
    {label: "Florence-Muscle Shoals, AL MSA", value: "Florence-Muscle Shoals, AL MSA"},
    {label: "Valdosta, GA MSA", value: "Valdosta, GA MSA"},
    {label: "Rocky Mount, NC MSA", value: "Rocky Mount, NC MSA"},
    {label: "Dalton, GA MSA", value: "Dalton, GA MSA"},
    {label: "Flagstaff, AZ MSA", value: "Flagstaff, AZ MSA"},
    {label: "Lebanon, PA MSA", value: "Lebanon, PA MSA"},
    {label: "Logan, UT-ID MSA", value: "Logan, UT-ID MSA"},
    {label: "Morgantown, WV MSA", value: "Morgantown, WV MSA"},
    {label: "Wheeling, WV-OH MSA", value: "Wheeling, WV-OH MSA"},
    {label: "Winchester, VA-WV MSA", value: "Winchester, VA-WV MSA"},
    {label: "Napa, CA MSA", value: "Napa, CA MSA"},
    {label: "La Crosse-Onalaska, WI-MN MSA", value: "La Crosse-Onalaska, WI-MN MSA"},
    {label: "Wausau, WI MSA", value: "Wausau, WI MSA"},
    {label: "Harrisonburg, VA MSA", value: "Harrisonburg, VA MSA"},
    {label: "Springfield, OH MSA", value: "Springfield, OH MSA"},
    {label: "Battle Creek, MI MSA", value: "Battle Creek, MI MSA"},
    {label: "Sherman-Denison, TX MSA", value: "Sherman-Denison, TX MSA"},
    {label: "Hammond, LA MSA", value: "Hammond, LA MSA"},
    {label: "Bismarck, ND MSA", value: "Bismarck, ND MSA"},
    {label: "Jonesboro, AR MSA", value: "Jonesboro, AR MSA"},
    {label: "Johnstown, PA MSA", value: "Johnstown, PA MSA"},
    {label: "Jackson, TN MSA", value: "Jackson, TN MSA"},
    {label: "The Villages, FL MSA", value: "The Villages, FL MSA"},
    {label: "Mount Vernon-Anacortes, WA MSA", value: "Mount Vernon-Anacortes, WA MSA"},
    {label: "Albany, OR MSA", value: "Albany, OR MSA"},
    {label: "Sierra Vista-Douglas, AZ MSA", value: "Sierra Vista-Douglas, AZ MSA"},
    {label: "St. Joseph, MO-KS MSA", value: "St. Joseph, MO-KS MSA"},
    {label: "Pittsfield, MA MSA", value: "Pittsfield, MA MSA"},
    {label: "Lawton, OK MSA", value: "Lawton, OK MSA"},
    {label: "Glens Falls, NY MSA", value: "Glens Falls, NY MSA"},
    {label: "New Bern, NC MSA", value: "New Bern, NC MSA"},
    {label: "Farmington, NM MSA", value: "Farmington, NM MSA"},
  ]