import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./addAccountModal.scss"
import AvatarModal from './avatarModal';
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import LZString from "lz-string";
import {faPen} from "@fortawesome/free-solid-svg-icons"
import { DataSpinner } from '../../spinner/dataSpinner';
import Select from 'react-select'

export const AddAccountModal = (props) => {
  const [progress , setProgress] = useState(0);

    const {
      setNewAuditor,
      usersOnFly,
        images,
        setImages,
        emailError,
        setEmailError,
        setLicenseNumber,
        licenseNumber,
        currentUser,
        invitationModal,
        setInvitationModal,
        setUserFirstName,
        userFirstName, 
        setUserLastName,
        userLastName,
        setUserEmail,
        userEmail,
        inviteUser,
        emailAlreadyRegistered,
        invalidEmail,
        setEmailAlreadyRegistered,
        setUserPhoneNumber,
        userPhoneNumber,
        adminType,
        setAdminType,
        selectedUser,
        // AvatarModal props
        takeAPhotoSelected,
        changeTakeAPhotoSelected,
        webcamURI,
        changeWebcamURI,
        imageUploaded,
        changeImageUploaded,
        imagePreviewAvailable,
        changeImagePreviewAvailable,
        handleDeletedPreviewImage,
        handleImageUploaded,
        addProfilePhotoClicked,
        setAddProfileClicked,
        setUserFranchisee,
        userFranchisee,
        setUserRole,
        userRole,
        setUserCompanyName,
        setUserCompanyAddress,
        userCompanyAddress,
        setUserCompanyCity,
        userCompanyCity,
        setUserCompanyState,
        userCompanyState,
        setUserCompanyCountry,
        userCompanyCountry,
        userCompanyName,
        updateUserFunc,
        setZip,
        zip
    } = props

    const [zipError, setZipError] = useState(true)

    const isUSAZipCode = (str) => {
      return /^\d{5}(-\d{4})?$/.test(str);
    }

    const isCanadaZipCode = (str) => {
    return  /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/.test(str);
    }
    
    const validateInput = (e) => {
      if(userCompanyCountryTyping == "United States") {
        if (isUSAZipCode(e.target.value)) {
        setZipError(false)
      } else {
       setZipError(true)
      }  
      } else if(userCompanyCountryTyping == "Canada") {
        if (isCanadaZipCode(e.target.value)) {
          setZipError(false)
        } else {
         setZipError(true)
        } 
      } else {
        setZipError(false)
      }
      
    }
    

    const formatNumber = (num) => {
      let newNum = num.split("")
      let country = userCompanyCountry || userCompanyCountryTyping
      if(country == "United States") {
         return newNum.length == 1 ? `(${newNum[0]}` : newNum.length == 2 ? `(${newNum[0]}${newNum[1]}` : newNum.length == 3 ? `(${newNum[0]}${newNum[1]}${newNum[2]}` : newNum.length == 4 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}` : newNum.length == 5 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}` : newNum.length == 6 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]}` : newNum.length == 7 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}` : newNum.length == 8 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}${newNum[7]}` : newNum.length == 9 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${newNum[6]}${newNum[7]}${newNum[8]}` : newNum.length >= 10 ? `(${newNum[0]}${newNum[1]}${newNum[2]}) ${newNum[3]}${newNum[4]}${newNum[5]} - ${[...newNum.filter((y, i) => i>5)]?.join("")}` : "" 
      } else {
        return num
      }
    
    } 
    const [temporaryState, setTemporaryState] = useState("")
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        position:"aboslute",
        top:-110
      }),
    
      control: (provided, { selectProps: { width }}) => ({
        ...provided,
        margin:"10px 0"
      }),
    
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      }
    }
    const phoneNumberTyping = (e) => {
      let currentTyping = e.currentTarget.value; 
      
      let string = currentTyping.replace(/\D/g, "")
      currentTyping = formatNumber(string)
      setUserPhoneNumberTyping(currentTyping)
    };

    const [userFirstNameTyping, setUserFirstNameTyping] = useState("")
    const [userLastNameTyping, setUserLastNameTyping] = useState("")
    const [userEmailTyping, setUserEmailTyping] = useState("")
    const [userPhoneNumberTyping, setUserPhoneNumberTyping] = useState("")
    const [userCompanyNameTyping, setUserCompanyNameTyping] = useState("")
    const [userCompanyAddressTyping, setUserCompanyAddressTyping] = useState("")
    const [userCompanyCityTyping, setUserCompanyCityTyping] = useState("")
    const [userCompanyStateTyping, setUserCompanyStateTyping] = useState("")
    const [userCompanyCountryTyping, setUserCompanyCountryTyping] = useState("")
    
useEffect(() => {
  if(userFirstName) {
    setUserFirstNameTyping(userFirstName)
  } else {
    setUserFirstNameTyping("")
  }
},[userFirstName])

useEffect(() => {
  if(userCompanyState) {
     setUserCompanyStateTyping(userCompanyState)
  } else {
    setUserCompanyStateTyping("")
  }
},[userCompanyState]) 

useEffect(() => {
  if(userCompanyCountry) {
    if(
    userCompanyCountry !== "United States" &&
    userCompanyCountry !== "Canada" &&
    userCompanyCountry !== "Mexico") {
    setTemporaryState("Other")
    }
     setUserCompanyCountryTyping(userCompanyCountry)
  } else {
    setUserCompanyCountryTyping("")
  }
},[userCompanyCountry]) 

useEffect(() => {
  if(userLastName) {
    setUserLastNameTyping(userLastName)
  } else {
    setUserLastNameTyping("")
  }
},[userLastName])
useEffect(() => {
  if(userEmail) {
    setUserEmailTyping(userEmail)
  } else {
    setUserEmailTyping("")
  }
},[userEmail])
useEffect(() => {
  if(userPhoneNumber) {
    setUserPhoneNumberTyping(userPhoneNumber)
  } else {
    setUserPhoneNumberTyping("")
  }
},[userPhoneNumber])
useEffect(() => {
  if(userCompanyName) {
    setUserCompanyNameTyping(userCompanyName)
  } else {
    setUserCompanyNameTyping("")
  }
},[userCompanyName])
useEffect(() => {
  if(userCompanyAddress) {
    setUserCompanyAddressTyping(userCompanyAddress)
  } else {
    setUserCompanyAddressTyping("")
  }
},[userCompanyAddress])
useEffect(() => {
  if(userCompanyCity) {
    setUserCompanyCityTyping(userCompanyCity)
  } else {
    setUserCompanyCityTyping("")
  }
},[userCompanyCity])

useEffect(() => {
  if(invitationModal && invitationModal.includes("Edit")) {
    setZipError(false)
  }
},[invitationModal])

  return(
<Modal
        isOpen={invitationModal ? true : false}
        style={{
          overlay: {          
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {          
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               {invitationModal && <div className="modal task-modal" style={{height: invitationModal.includes("User") ? "fit-content" : "fit-content" , width: invitationModal.includes("User") ?"50vw" : "50vh"}}>
               
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>{invitationModal.includes("Edit") ? invitationModal : `Add ${invitationModal}`} </h1>
          <div className="form-group-container task-form" style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}}>
          <div className="form-row" style={{marginBottom:"10px"}}> 
            <AvatarModal
              progress={progress}
              setProgress={setProgress}
            images={images}
            setImages={setImages}
                takeAPhotoSelected={takeAPhotoSelected}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                handleImageUploaded={handleImageUploaded}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
            />
            </div>
            {licenseNumber && <div className="form-row">
            <div className="form-collection">
              <div className="form-label">License number</div>
              <input
                // onChange={(e) => setUserFirstName(e.target.value)}
                value={licenseNumber}
                disabled
                type="text"
                className="form-input Dropdown-disabled"
              />
            </div>
            </div>}
          <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>First Name<span style={{color:"red"}}> *</span></span></div>
              <input
                onChange={(e) => setUserFirstNameTyping(e.target.value)}
                onBlur={() => setUserFirstName(userFirstNameTyping)}
                value={userFirstNameTyping}
                type="text"
                placeholder={`${invitationModal} First Name`}
                className="form-input"
              />
            </div>
            </div>
            <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Last Name<span style={{color:"red"}}> *</span></span></div>
              <input
                onChange={(e) => setUserLastNameTyping(e.target.value)}
                onBlur={() => setUserLastName(userLastNameTyping)}
                value={userLastNameTyping}
                type="text"
                placeholder={`${invitationModal} Last Name`}
                className="form-input"
              />
            </div>
            </div>
           { invitationModal !== "Edit Sub-User" ?  <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Country<span style={{color:"red"}}> *</span></span></div>
              <Select 
              options={[
                {label:"United States", value:"United States"},
                {label:"Canada", value:"Canada"},
                {label:"Mexico", value:"Mexico"},
                {label:"Other", value:"Other"},
              ]}
              isSearchable={true}
              styles={customStyles}
              value={temporaryState ? {label: temporaryState , valye: temporaryState} : userCompanyCountry ? {label: userCompanyCountry, value: userCompanyCountry} : userCompanyCountryTyping ? {label: userCompanyCountryTyping, value: userCompanyCountryTyping} : ""}
              onChange={(choice) =>{
                setZip && setZip("")
                setUserPhoneNumberTyping("")
                setUserPhoneNumber("")
                setZipError(false)
                if(choice.value == "Other") { 
                  setUserCompanyCountryTyping("")
                  setUserCompanyCountry && setUserCompanyCountry("")
                  setTemporaryState("Other") 
                  setUserCompanyStateTyping("")
                  setUserCompanyState && setUserCompanyState("")
               } else {
                setUserCompanyCountryTyping(choice.value)
                setTemporaryState("")
                setUserCompanyStateTyping("")
                setUserCompanyState && setUserCompanyState("")
                setUserCompanyCountry && setUserCompanyCountry(choice.value)
               }
              }
              }
              placeholder={"User Contact Country"}
              />  
          </div>
            
        </div> : null}
            <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Email<span style={{color:"red"}}> *</span></span>
              {emailError ? (
        <div className="auth-error" style={{textTransform:"none"}}>
          {emailError}
        </div>
      ) : invalidEmail ? (
        <div className="auth-error" style={{textTransform:"none"}}>
          Please enter a valid email address.
        </div>
      ) : null }
              </div>
              <input
                onChange={(e) => {
                  
                  setUserEmailTyping(e.target.value.toLowerCase())
                  setEmailError && setEmailError("")

                }}
                onBlur={() => setUserEmail(userEmailTyping)}
                value={userEmailTyping}
                type="text"
                placeholder={`${invitationModal} Email`}
                className="form-input"
              />
            </div>
            {<div className="form-collection">
              <div className="form-label"><span>Phone Number<span style={{color:"red"}}> *</span></span></div>
              <input
                onBlur={() => setUserPhoneNumber(userPhoneNumberTyping)}
                onChange={phoneNumberTyping}
                value={userPhoneNumberTyping}
                maxLength={ userCompanyCountry == "United States" || userCompanyCountryTyping == "United States" ? "16" : "30"}
                type="text"
                placeholder={`${invitationModal} Phone Number`}
                className="form-input"
              />
            </div>}
            </div>
{invitationModal.includes("User") ? (
  <>
  {currentUser && currentUser == "admin" ? !selectedUser && !invitationModal.includes("Edit Sub-User") ? (
    <div className="form-row">
            
            <div className="form-collection">
            <div className="form-label"><span>User Type<span style={{color:"red"}}> *</span></span></div>
            <Dropdown
                  options={["user", "sub-user"]}
                  disabled
                  onChange={(choice) =>
                    setUserRole(choice.value)
                  }
                  controlClassName="form-dropdown" 
                  value={userRole}
                />
            </div>
                <div className="form-collection">
                <div className="form-label"><span>User Role<span style={{color:"red"}}> *</span></span></div>
  
                <Dropdown
                  options={["Franchisee", "non-franchisee"]}
                  onChange={(choice) =>
                    setUserFranchisee(choice.value)
                  }
                  controlClassName="form-dropdown" 
                  value={userFranchisee}
                />
            </div> 
            
    </div> 
  ) : null : null}
  {!selectedUser && !invitationModal.includes("Edit Sub-User") ? (
    <>
    <div className="form-row">
            
            <div className="form-collection">
            <div className="form-label"><span>Company name<span style={{color:"red"}}> *</span></span></div>
              <input
                onChange={(e) => setUserCompanyNameTyping(e.target.value)}
                onBlur={() => setUserCompanyName(userCompanyNameTyping)}
                value={userCompanyNameTyping}
                type="text"
                placeholder={`${invitationModal} Company name`}
                className="form-input"
              /> 
            </div>
            
        </div> 


        {temporaryState && <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Other Country<span style={{color:"red"}}> *</span></span></div>
              <input
              placeholder='User Contact Country'
              onChange={(e) => setUserCompanyCountryTyping(e.target.value)}
              onBlur={() => setUserCompanyCountry(userCompanyCountryTyping)}
              value={userCompanyCountryTyping}
              type="text"
              className="form-input"
            />  
          </div>
            
        </div> }

        <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>State</span></div>
               {!temporaryState ? <Select
              isDisabled={!userCompanyCountry}
              options={userCompanyCountry == "United States" ? [
                {label: "Alabama" , value: "Alabama"},
                {label: "Alaska" , value: "Alaska"},
                {label: "Arizona" , value: "Arizona"},
                {label: "Arkansas" , value: "Arkansas"},
                {label: "California" , value: "California"},
                {label: "Colorado" , value: "Colorado"},
                {label: "Connecticut" , value: "Connecticut"},
                {label: "Delaware" , value: "Delaware"},
{label: "District of Columbia", value: "District of Columbia"},
                {label: "Florida" , value: "Florida"},
                {label: "Georgia" , value: "Georgia"},
                {label: "Hawaii" , value: "Hawaii"},
                {label: "Idaho" , value: "Idaho"},
                {label: "Illinois" , value: "Illinois"},
                {label: "Indiana" , value: "Indiana"},
                {label: "Iowa" , value: "Iowa"},
                {label: "Kansas" , value: "Kansas"},
                {label: "Kentucky" , value: "Kentucky"},
                {label: "Louisiana" , value: "Louisiana"},
                {label: "Maine" , value: "Maine"},
                {label: "Maryland" , value: "Maryland"},
                {label: "Massachusetts" , value: "Massachusetts"},
                {label: "Michigan" , value: "Michigan"},
                {label: "Minnesota" , value: "Minnesota"},
                {label: "Mississippi" , value: "Mississippi"},
                {label: "Missouri" , value: "Missouri"},
                {label: "Montana" , value: "Montana"},
                {label: "Nebraska" , value: "Nebraska"},
                {label: "Nevada" , value: "Nevada"},
                {label: "New Hampshire" , value: "New Hampshire"},
                {label: "New Jersey" , value: "New Jersey"},
                {label: "New Mexico" , value: "New Mexico"},
                {label: "New York" , value: "New York"},
                {label: "North Carolina" , value: "North Carolina"},
                {label: "North Dakota" , value: "North Dakota"},
                {label: "Ohio" , value: "Ohio"},
                {label: "Oklahoma" , value: "Oklahoma"},
                {label: "Oregon" , value: "Oregon"},
                {label: "Pennsylvania" , value: "Pennsylvania"},
                {label: "Rhode Island" , value: "Rhode Island"},
                {label: "South Carolina" , value: "South Carolina"},
                {label: "South Dakota" , value: "South Dakota"},
                {label: "Tennessee" , value: "Tennessee"},
                {label: "Texas" , value: "Texas"},
                {label: "Utah" , value: "Utah"},
                {label: "Vermont" , value: "Vermont"},
                {label: "Virginia" , value: "Virginia"},
                {label: "Washington" , value: "Washington"},
                {label: "West Virginia" , value: "West Virginia"},
                {label: "Wisconsin" , value: "Wisconsin"},
                {label: "Wyoming" , value: "Wyoming"},
              ].sort((a, b) => a.label.localeCompare(b.label)) : userCompanyCountry == "Mexico" ? [
                {label : "Aguascalientes", value: "Aguascalientes"},
                {label : "Baja California", value: "Baja California"},
                {label : "Baja California Sur", value: "Baja California Sur"},
                {label : "Campeche", value: "Campeche"},
                {label : "Coahuila", value: "Coahuila"},
                {label : "Colima", value: "Colima"},
                {label : "Chiapas", value: "Chiapas"},
                {label : "Chihuahua", value: "Chihuahua"},
                {label : "Durango", value: "Durango"},
                {label : "Mexico City", value: "Mexico City"},
                {label : "Guanajuato", value: "Guanajuato"},
                {label : "Guerrero", value: "Guerrero"},
                {label : "Hidalgo", value: "Hidalgo"},
                {label : "Jalisco", value: "Jalisco"},
                {label : "Michoacan", value: "Michoacan"},
                {label : "Morelos", value: "Morelos"},
                {label : "Nayarit", value: "Nayarit"},
                {label : "Nuevo León", value: "Nuevo León"},
                {label : "Oaxaca", value: "Oaxaca"},
                {label : "Puebla", value: "Puebla"},
                {label : "Queretaro", value: "Queretaro"},
                {label : "Quintana Roo", value: "Quintana Roo"},
                {label : "San Luis Potosi", value: "San Luis Potosi"},
                {label : "Sinaloa", value: "Sinaloa"},
                {label : "Sonora", value: "Sonora"},
                {label : "Tabasco", value: "Tabasco"},
                {label : "Tamaulipas", value: "Tamaulipas"},
                {label : "Tlaxcala", value: "Tlaxcala"},
                {label : "Veracruz", value: "Veracruz"},
                {label : "Yucatán", value: "Yucatán"},
                {label : "Zacatecas", value: "Zacatecas"},
            ].sort((a, b) => a.label.localeCompare(b.label)) : userCompanyCountry == "Canada" ? [
              {label: "Alberta" , value: "Alberta"},
              {label: "British Columbia" , value: "British Columbia"},
              {label: "Manitoba" , value: "Manitoba"},
              {label: "New Brunswick" , value: "New Brunswick"},
              {label: "Newfoundland and Labrador" , value: "Newfoundland and Labrador"},
              {label: "Northwest Territories" , value: "Northwest Territories"},
              {label: "Nova Scotia" , value: "Nova Scotia"},
              {label: "Nunavut" , value: "Nunavut"},
              {label: "Ontario" , value: "Ontario"},
              {label: "Prince Edward Island" , value: "Prince Edward Island"},
              {label: "Quebec" , value: "Quebec"},
              {label: "Saskatchewan" , value: "Saskatchewan"},
              {label: "Yukon" , value: "Yukon"},
          ].sort((a, b) => a.label.localeCompare(b.label)) : []}
              isSearchable={true}
              styles={customStyles}
              value={userCompanyState ? {label: userCompanyState, value: userCompanyState} : ""}
              onChange={(choice) =>{
               setUserCompanyState(choice.value) }
              
              }
              placeholder={"User Contact State"}
              /> :  <input
              placeholder='User Contact State'
              onChange={(e) => {
                setUserCompanyStateTyping(e.target.value)}}
              onBlur={() => setUserCompanyState(userCompanyStateTyping)}
              value={userCompanyStateTyping}
              type="text"
              className="form-input"
            /> }
          </div>
            
        </div> 
        <div className="form-row">
          <div className="form-collection">
              <div className="form-label"><span>City</span></div>
              <input
                placeholder='User Contact City'
                onChange={(e) => setUserCompanyCityTyping(e.target.value)}
                onBlur={() => setUserCompanyCity(userCompanyCityTyping)}
                value={userCompanyCityTyping}
                type="text"
                className="form-input"
              />
            </div>
            </div>
        <div className="form-row">
            
            <div className="form-collection">
            <div className="form-label"><span>Address</span></div>
              <input
                placeholder='User Contact Address'
                onKeyDown={(e) => {
                  if(e.keyCode !== 188) {
                    return e.keyCode
                  } else {
                    e && e.preventDefault()
                  }
                }}
                onChange={(e) => setUserCompanyAddressTyping(e.target.value)}
                onBlur={() => setUserCompanyAddress(userCompanyAddressTyping)}
                value={userCompanyAddressTyping}
                type="text"
                className="form-input"
              />
          </div>
          </div>

          <div className="form-row">
              <div className="form-collection">
               <div className="form-label"><span>Zip code</span>
               {zipError && zip?.length ? (
                  <div className="auth-error" style={{textTransform:"none"}}>
                    Pleae enter a valid ZIP code
                  </div>
                ) : null }
               </div>
              <input
                name="zipCodeClient"
                placeholder='Zip Code'
                onKeyUp={(e) => validateInput(e)}
                onChange={(e) => setZip(e.target.value)}
                value={zip}
                type= { userCompanyCountryTyping == "Canada" ? "text" : "number" }
                maxLength={ userCompanyCountryTyping == "Canada" ? "7" : "6" }
                minLength={ userCompanyCountryTyping == "Canada" ? "7" : "6" }
                className="form-input"
              />
          </div>
          </div>

          {/* <div className="form-row">
            <div className="form-collection">
              <div className="form-label"><span>Country<span style={{color:"red"}}> *</span></span></div>
              <Select 
              options={[
                {label:"United States", value:"Unisted States"},
                {label:"Canada", value:"Canada"},
                {label:"Mexico", value:"Mexico"},
              ].sort((a, b) => a.label.localeCompare(b.label))}
              isSearchable={true}
              styles={customStyles}
              value={{label: state, value: state}}
              onChange={(choice) =>{
              setUserCompanyState(choice.value)
              }
              }
              placeholder={"User Contact State"}
              />  
          </div>
            
        </div>  */}
         

            
        </>
  ) : null}
  
</>
) : invitationModal.includes("Admin") ? ( 
  <div className="form-row">
            
                <div className="form-collection">
                <div className="form-label"><span>Admin type<span style={{color:"red"}}> *</span></span></div>
  
                  <Dropdown
                  options={["WMG Admin", "Step Admin"]}
                  onChange={(choice) =>
                    setAdminType(choice.value)
                  }
                  controlClassName="form-dropdown" 
                  menuClassName='underMenuAdmin'
                  value={adminType}
                />
            </div> 
            
    </div>
) : null}
          
             
          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {
                setNewAuditor && setNewAuditor(usersOnFly)
                  setInvitationModal(null)
                  setEmailError && setEmailError("")
                  setZip && setZip("")
                  setZipError && setZipError(false)
                  setImages([])
                  setLicenseNumber && setLicenseNumber("")
                  setUserFirstName && setUserFirstName("")
                  setUserLastName && setUserLastName("")
                  setUserEmail && setUserEmail("")
                  setUserPhoneNumber && setUserPhoneNumber("")
                  setAdminType && setAdminType("")
                  setUserFranchisee && setUserFranchisee("Franchisee")
                  setUserRole && setUserRole("user")
                  setUserCompanyName && setUserCompanyName("")
                  setUserCompanyAddress && setUserCompanyAddress("")
                  setUserCompanyCity && setUserCompanyCity("")
                  setUserCompanyState && setUserCompanyState("")
                  setLicenseNumber && setLicenseNumber("")
                  setTemporaryState("")
                }}
            >

            Cancel

            </div>
            <div
              className={`${
                progress && progress !== 100 ? 
                "auth-button addUser disable-button" :
                invitationModal.includes("Sub-User") ? 
                userFirstName && userLastName && userEmail && userPhoneNumber 
                ? "auth-button addUser" : 
                "auth-button addUser disable-button" : 
                invitationModal.includes("Admin") && userFirstName && userPhoneNumber && userLastName  && userEmail && !emailAlreadyRegistered  ? 
                "auth-button addUser" : 
                invitationModal.includes("User") && userFirstName && userLastName  && userEmail && userCompanyCountry && userPhoneNumber && userCompanyName && !emailAlreadyRegistered ? 
                zip ? 
                !zipError ? 
                "auth-button addUser" : 
                "auth-button addUser disable-button": 
                "auth-button addUser": 
                "auth-button addUser disable-button"
              }`}
              onClick={ 
                progress && progress !== 100 ? 
                null : 
                invitationModal.includes("Sub-User") ? userFirstName && userLastName && userEmail && userPhoneNumber ? 
                invitationModal.includes("Edit") ? 
                updateUserFunc : 
                () => inviteUser() : 
                null : 
                invitationModal.includes("Admin") && userFirstName && userPhoneNumber && userLastName  && userEmail 
                ? invitationModal.includes("Edit") ? 
                updateUserFunc : 
                inviteUser : 
                invitationModal.includes("User") && userFirstName && userLastName  && userEmail && userCompanyCountry && userPhoneNumber && userCompanyName&& !emailAlreadyRegistered ? 
                zip ? 
                !zipError 
                ? invitationModal.includes("Edit") ? () => {
                setTemporaryState("")
                updateUserFunc()
              } : () => {
                setTemporaryState("")
                inviteUser()
              } : null : invitationModal.includes("Edit") ? () => {
                setTemporaryState("")
                updateUserFunc()
              } : () => {
                setTemporaryState("")
                inviteUser()
              } : null}
              style={{margin:"0.5rem"}}
            >
              {progress && progress !== 100 ? <DataSpinner small="s" /> : invitationModal.includes("Edit") ? "Update" : "Save"}
            </div>
          </div>
        </div>}
      </Modal>
  )}       