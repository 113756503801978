import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    length 
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount, 
    pageSize
  });

  if(length !== null && length == 0) {
    return (<ul
    className={classnames('pagination-container', { [className]: className })}
  >
  <li
      className={classnames('pagination-item', {
        disabled: currentPage === 1
      })}
      onClick={() => onPageChange(1)}
    >
      <div className="arrow left" />
    </li>
  <li
  className={classnames('pagination-item')}
  onClick={() => onPageChange(1)}
>
  1
</li> </ul>
)}

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1); 
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange?.length ? paginationRange.map((pageNumber, i, a) => {
        if (pageNumber === DOTS) {
          return <li key={`dots${i}`} className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
          key={i}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      }) : null}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
