import { useState, useEffect } from "react";
import Cookies from "js-cookie";


const REGION ='us-east-1';
const useAwsCredentials = () => {
  const [credentials, setCredentials] = useState(null); 
  const [myBucket, setMyBucket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    const fetchCredentials = async () => {
      try {
        // Get the token from cookies
        const token = Cookies.get("access-token");

        if (!token) {
          throw new Error("No access token found in cookies");
        }

        const response = await fetch("/api/ecs-credentials", {
          method: "GET", 
          headers: {
            "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        const data = await response.json();

        if (!data.success) {
          throw new Error("Failed to fetch AWS credentials");
        }

        const myBucketAWS = new AWS.S3({
          params: { Bucket: process.env.REACT_APP_S3_BUCKET},
          region: REGION,
          credentials: {
            accessKeyId: data.credentials.AccessKeyId,
            secretAccessKey: data.credentials.SecretAccessKey,
            sessionToken: data.credentials.SessionToken, // Required for temporary credentials
        }})
  

        setCredentials(data.credentials);
        setMyBucket(myBucketAWS);
        setLoading(false);
        return myBucketAWS
      } catch (err) {
        setError(err.message || "An error occurred while fetching credentials");
        setLoading(false); 
      }
    };


  return { myBucket, credentials, loading, error, fetchCredentials };
};

export default useAwsCredentials;
  