import { gql } from "apollo-boost";

const updateUserPasswordMutation = gql`
  mutation($password: String) {
    updateUserPassword(password: $password) {
      password
      email
      notification {
        _id
        new
        message
        users
        createdAt
      }
    }
  }
`;

export default updateUserPasswordMutation;
