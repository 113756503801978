import React, { useEffect, useState, useMemo, Suspense } from "react";


import ReactDOM from "react-dom";
import { ApolloProvider} from "@apollo/react-hooks";
import { createStore, applyMiddleware } from "redux";
import { Provider, useDispatch, useSelector} from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension"; 
import moment from "moment"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect, 
  useLocation, 
  useHistory
} from "react-router-dom";
 import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import RootReducer from "./RootReducer";
import apolloClient from "./apolloClient";
import Modal from "react-modal";
import { AliveScope } from "react-activation";

import "./style.scss"
import Home from "./components/Home";
import AdminRouter from "./components/admin/AdminRouter";
// import io from "socket?.io-client"

import ACTION_USER_AUTHENTICATED from "./actions/user/Authenticated/ACTION_USER_AUTHENTICATED";
import ACTION_USER_NOT_AUTHENTICATED from "./actions/user/Authenticated/ACTION_USER_NOT_AUTHENTICATED";
import ACTION_DUMMY_TOKEN from "./actions/user/Login/DummyToken/ACTION_DUMMY_TOKEN";
import ACTION_DUMMY_TOKEN_RESET from "./actions/user/Login/DummyToken/ACTION_DUMMY_TOKEN_RESET";
import ACTION_TEMPORARY_DUMMY_TOKEN from "./actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN";
import ACTION_TEMPORARY_DUMMY_TOKEN_RESET from "./actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN_RESET";
// loading spinner

import ACTION_LOADING_SPINNER_ACTIVE from "./actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "./actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";

import getUserQuery from "./graphql/queries/user/getUserQuery"

import Cookies, { set } from "js-cookie";
import jwt_decode from 'jwt-decode'
import allUsersQuery from "./graphql/queries/user/allUsersQuery";
import allClientsQuery from "./graphql/queries/client/allClientsQuery"
import allProjectsQuery from "./graphql/queries/project/allProjectsQuery"
import allTemplatesQuery from "./graphql/queries/template/allTemplatesQuery"



import Spinner from "./components/resuable/spinner"

import Bluetooth from "./components/services/bluetooth"
import allReportsQuery from "./graphql/queries/report/allReportsQuery";
import allLicensesQuery from "./graphql/queries/license/allLicensesQuery";
import allSitesQuery from "./graphql/queries/site/allSitesQuery"
import allAreasQuery from "./graphql/queries/area/allAreasQuery";
import ACTION_INFO_ACTIVE from "./actions/InfoActive/ACTION_INFO_ACTIVE";
import updateUserInvalidateTokensMutation from "./graphql/mutations/user/updateUserInvalidateTokensMutation";
import AWS, { Endpoint } from 'aws-sdk'
import ACTION_SELECTED_TESTS from "./actions/SelectedTests/ACTION_SELECTED_TESTS";
import ACTION_SELECTED_TESTS_RESET from "./actions/SelectedTests/ACTION_SELECTED_TESTS_RESET";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ContactUs from "./components/contactUs";
import HelpCenter from "./components/helpCenter";
import Bot from "./components/bot";

import { Buffer } from 'buffer';
import { Store } from "./components/store";
import useAwsCredentials from "./components/hooks/useAws";
import { DataSpinner } from "./components/resuable/spinner/dataSpinner";
import ExtractImages from "./fixer";

// @ts-ignore
window.Buffer = Buffer;

// Sentry.init({
//   dsn: "https://b3cbbd54cf5447feaa0d1b516375388b@o1398969.ingest.sentry.io/6726089",
//   integrations: [new BrowserTracing()],
//   allowUrls:["https://stepreporting.com/"],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


const middleware = [thunk];

const store = createStore(
  RootReducer,
  process.env.REACT_APP_NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware))
); 


const App = () => {


  const { credentials, loading, error, fetchCredentials } = useAwsCredentials();  // Use the custom hook to fetch credentials

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []); 

  const history = useHistory()
  const dispatch = useDispatch();
  const location = useLocation();
  const userAuthenticated = useSelector(  
    (state) => state.userAuthenticated.user_authenticated
  );

  const selectedTests = useSelector((state) => state.selectedTests.selectedTests)

  const dummyToken = useSelector((state) => state.dummyToken.dummy_token);
  const temporaryDummyToken = useSelector((state) => state.temporaryDummyToken.temporary_dummy_token);

  useEffect(() => {
    let currentDummyToken;
    let temporaryUserDummyToken;
    const checkCookies = () => {
      if (
        currentDummyToken !== Cookies.get("dummy-token") ||
        temporaryUserDummyToken !== Cookies.get("temporary-dummy-token")
      ) {
        currentDummyToken = Cookies.get("dummy-token");
        temporaryUserDummyToken = Cookies.get("temporary-dummy-token");
 
        if (currentDummyToken) {
          if (!dummyToken) {
            dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET());
            dispatch(ACTION_DUMMY_TOKEN(jwt_decode(currentDummyToken)));
          }
          if (!userAuthenticated) {
             dispatch(ACTION_USER_AUTHENTICATED());
          }
        } else if (temporaryUserDummyToken) {
          if (temporaryUserDummyToken) {
            if (!temporaryDummyToken) {
              dispatch(
                ACTION_TEMPORARY_DUMMY_TOKEN(
                  jwt_decode(temporaryUserDummyToken)
                )
              );
            }
            if (userAuthenticated) {
              dispatch(ACTION_USER_NOT_AUTHENTICATED());
            }
          } else {
            if (temporaryDummyToken) {
              dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET());
            }

            if (userAuthenticated) {
              dispatch(ACTION_USER_NOT_AUTHENTICATED());
            }
          }
        }
      }
    };

    setInterval(checkCookies, 100);
  }, [
    dispatch,
    location.pathname,
    userAuthenticated,
    dummyToken,
    temporaryDummyToken
  ]);
 
    
  const [
    updateUserInvalidateTokens,
    { data: userLogoutData },
  ] = useMutation(updateUserInvalidateTokensMutation);

  const [loggingOut, changeLoggingOut] = useState(false);  

    const {
    data: getUserData,
    error: getUserError,
    loading: getUserLoading,
    refetch: userDataRefetch,
    subscribeToMore: userSubscribeToMore,
  } = useQuery(getUserQuery, {
    variables: {
      _id: temporaryDummyToken
        ? temporaryDummyToken.id
        : dummyToken
        ? dummyToken.id
        : null,
    },
  });

  const handleLogout = () => {
    dispatch(ACTION_SELECTED_TESTS_RESET())
    localStorage.setItem("selectedTests", JSON.stringify({}))
    updateUserInvalidateTokens();
    changeLoggingOut(true);
};

useEffect(() => {
  if (userLogoutData) {
    if (loggingOut) {
      if (userAuthenticated) {
        dispatch(ACTION_USER_NOT_AUTHENTICATED());
      }
      if (dummyToken) {
        dispatch(ACTION_DUMMY_TOKEN_RESET());
      }
      changeLoggingOut(false);
    }
  }
}, [userLogoutData, dispatch, userAuthenticated, loggingOut, dummyToken]);


  const [
    allUsers,
    {
      data: allAccountsData,
      refetch: allAccountsRefetch
    },
  ] = useLazyQuery(allUsersQuery, {
    fetchPolicy: "no-cache",
  });

  const [
    getOwner,
    {
      data: ownerData,
      refetch: ownerRefetch
    },
  ] = useLazyQuery(getUserQuery, {
    fetchPolicy: "no-cache",
  });

  const [
    allClients,
    {
      data: allClientsData,
      refetch: allClientsRefetch
    },
  ] = useLazyQuery(allClientsQuery, {
    fetchPolicy: "no-cache",
  });
  
  const [
    allProjects,
    {
      data: allProjectsData,
      refetch: allProjectsRefetch,
      called: allProjectsCalled,
      loading: allProjectsLoading
    },
  ] = useLazyQuery(allProjectsQuery, {
    fetchPolicy: "no-cache",
  });
  const [
    allLicenses,
    {
      data: allLicensesData,
      refetch: allLicensesRefetch
    },
  ] = useLazyQuery(allLicensesQuery, {
    fetchPolicy: "no-cache",
  });
  const [
    allTemplates,
    {
      data: allTemplatesData,
      refetch: allTemplatesRefetch
    },
  ] = useLazyQuery(allTemplatesQuery, {
    fetchPolicy: "no-cache",
  });
  
  const [
    allReports,
    {
      data: allReportsData,
      refetch: allReportsRefetch,
      called: reportsCalled,
      loading:allReportsLoading
    },
  ] = useLazyQuery(allReportsQuery, {
    fetchPolicy: "no-cache",
  });

    const [
      allSites,
      {
        data: allSitesData,
        refetch: allSitesRefetch
      },
    ] = useLazyQuery(allSitesQuery, {
      fetchPolicy: "no-cache",
    });

    const [
      allAreas,
      {
        data: allAreasData,
        refetch: allAreasRefetch
      },
    ] = useLazyQuery(allAreasQuery, {
      fetchPolicy: "no-cache",
    });

  const [admins, setAdmins] = useState([])
  const [users, setUsers] = useState([])
  const [subOwner, setSubOwner] = useState(null)
  const [clients, setClients] = useState([])
  const [projects, setProjects] = useState(null)
  const [licenses, setLicenses] = useState(null)
  const [sites, setSites] = useState(null)

  const [currentPageReports, setCurrentPageReports] = useState(1);
  const [activeView, setActiveView] = useState(null);
  const [currentPageAudits, setCurrentPageAudits] = useState(1);
  const [activeType, setActiveType] = useState("pending");
  const [reports, setReports] = useState(null)

  const [templates, setTemplates] = useState(null)
  const [defaultDCOF, setDefaultDCOF] = useState(null)
  const [defaultPendulum, setDefaultPendulum] = useState(null)
  const [defaultDCOFBefore, setDefaultDCOFBefore] = useState(null)
  const [defaultPendulumBefore, setDefaultPendulumBefore] = useState(null)
  
  let PageSize = 30;
  let PageSizeAudits = 20;

  // useEffect(() => {
  //   if(activeType) {
  //     setCurrentPageReports(1)
  //   }
  // },[activeType])

 useMemo(() => {
    if(userAuthenticated&&getUserData) {
      const limit = PageSize; 
      const skip = (currentPageReports - 1) * limit; 


      if(getUserData.user.role == "admin") {
        if(getUserData.user.adminType == "WMG Admin") {
          allReports({
            variables:{
              view: activeView,
              wmg: getUserData.user._id,
              skip,
              limit,
            } 
          })
        } else {
      allReports({
        variables:{
          view:activeView,
          skip,
          limit,
          status: activeType
        }
      })} 
      dispatch(ACTION_LOADING_SPINNER_RESET())
      } else if(getUserData.user.role == "user") {
        allReports({
          variables:{
            view: activeView,
            user: getUserData.user._id,
            skip,
            limit,
            status: activeType
          } 
        })
  } else {
    allReports({
      variables:{
        view: activeView,
        user: getUserData.user._id,
        role: "client",
        skip,
        limit,
        status: activeType
      } 
    })
  }

}},[
    dispatch,
    userAuthenticated,
    getUserData,
    currentPageReports,
    activeType
  ])


  useMemo(() => {
    if(userAuthenticated&&getUserData) {
      const limit = PageSizeAudits;
      const skip = (currentPageAudits - 1) * limit;

      if(getUserData.user.role == "admin") {
        if(getUserData.user.adminType == "WMG Admin") {
          allProjects({
            variables:{
              _id: getUserData.user._id,
              skip,
              limit,
            } 
          })
        } else {
          allProjects({
            variables:{
              skip,
              limit,
            }
          })  
        }
      dispatch(ACTION_LOADING_SPINNER_RESET())
      } else if(getUserData.user.role !== "client") {
        allProjects({
          variables:{
            _id: getUserData.user._id,
            skip,
            limit,
          } 
        })
  } else {
    allProjects({
      variables:{
        client: getUserData.user.client,
        skip,
        limit,
      } 
    })
  }

}},[
    dispatch,
    userAuthenticated,
    getUserData,
    currentPageAudits
  ])


  useEffect(() => {
    if(userAuthenticated&&getUserData) {
      if(getUserData.user.role == "admin") {
      allUsers()
      allClients() 
      // allProjects()
      allTemplates()
      allLicenses()
      dispatch(ACTION_LOADING_SPINNER_RESET())
      } else if(getUserData.user.role !== "client") {
        if(getUserData.user.role == "sub-user") {
          getOwner({
            variables: {
              _id: getUserData?.user?.owner
            }
          })
        }
        allSites({
          variables:{
            user: getUserData?.user?._id
          }
        })
       
        allTemplates()
        allUsers({
          variables:{
            _id: getUserData.user._id
          }
        })
        allClients({
          variables:{
            user: getUserData?.user?._id
          }
        })
        dispatch(ACTION_LOADING_SPINNER_RESET())
      } else {

        allSites({
          variables:{
            client: getUserData?.user?.client
          } 
        })
        
        allClients({
          variables: {
            _id: getUserData?.user?.client || getUserData?.user?._id
          }
        }) 
      }
      
    }
  },[
    dispatch,
    userAuthenticated,
    getUserData
  ]) 

  useEffect(() => {
    if(allAccountsData) {
      setAdmins(allAccountsData.allUsers?.filter((account) => {
        if(getUserData?.user?.adminType == "WMG Admin") {
          return getUserData?.user?._id ==  account._id
        } else {
          return account.role == "admin"
        }
      }))
      setUsers(allAccountsData.allUsers?.filter((account) => account.role == "user" || account.role == "sub-user"))
    }
  },[allAccountsData]) 


  useEffect(() => {
    if(allClientsData) {
      setClients(allClientsData.allClients)
    }
  },[allClientsData])

  useEffect(() => {
    if(ownerData) {
      setSubOwner(ownerData.user)
    } else {
      setSubOwner(null)
    }
  },[ownerData])

  useEffect(() => {
    if(allSitesData) {
      setSites(allSitesData.allSites)
    }
  },[allSitesData])

  useEffect(() => {
    if(allProjectsData) {
      setProjects(allProjectsData.allProjects?.sort((a, b) => Number(b.createdAt) - Number(a.createdAt)))
    }
  },[allProjectsData])

  useEffect(() => { 
    if(allLicensesData) {
      setLicenses(allLicensesData.allLicenses)
    }
  },[allLicensesData])

  useEffect(() => {
    if(allReportsData) {
      setReports(allReportsData.allReports || [])
    } else {
      setReports([])
    }
  },[allReportsData]) 

  useEffect(() => {
    if(allTemplatesData) {
      setTemplates(allTemplatesData.allTemplates)
      setDefaultDCOFBefore(allTemplatesData.allTemplates.find(x => (
        x.type == "dcof" && x.default == true
      )))
      setDefaultPendulumBefore(allTemplatesData.allTemplates.find(x => (
        x.type == "pendulum" && x.default == true
      )))
    }
  },[allTemplatesData])
  useEffect(() => {
    let isMounted = true; // Prevent setting state if component unmounts

    const fetchData = async () => {
      try {
        if (!defaultDCOFBefore || error) return;
 
        const myBucket = await fetchCredentials();

        const paramsHtml = {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: defaultDCOFBefore.html,
          ResponseCacheControl: "no-cache",
        };

        myBucket
          .getObject(paramsHtml)
          .on("complete", (evt) => {
            if (!isMounted) return;

            const updatedDCOF = { ...defaultDCOFBefore };

            if (evt?.data?.Body) {
              updatedDCOF.html = Buffer.from(evt.data.Body).toString("utf8");
            }

            const paramsCss = {
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: defaultDCOFBefore.css,
              ResponseCacheControl: "no-cache",
            };

            myBucket
              .getObject(paramsCss)
              .on("complete", (evtCss) => {
                if (!isMounted) return;

                if (evtCss?.data?.Body) {
                  updatedDCOF.css = Buffer.from(evtCss.data.Body).toString("utf8");
                }

                setDefaultDCOF(updatedDCOF);
              })
              .send((err) => {
                if (err) console.error("CSS Fetch Error:", err);
              });
          })
          .send((err) => {
            if (err) console.error("HTML Fetch Error:", err);
          });
      } catch (err) {
        console.error("Error fetching credentials or data:", err);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [defaultDCOFBefore, error]); // Added necessary dependencies


  useEffect(() => {
    let isMounted = true; // Prevent setting state if component unmounts

    const fetchData = async () => {
      try {
        if (!defaultPendulumBefore || error) return;

        const myBucket = await fetchCredentials();

        const paramsHtml = {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: defaultPendulumBefore.html,
          ResponseCacheControl: "no-cache",
        };

        myBucket
          .getObject(paramsHtml)
          .on("complete", (evt) => {
            if (!isMounted) return;

            const updatedPendulum = { ...defaultPendulumBefore };

            if (evt?.data?.Body) {
              updatedPendulum.html = Buffer.from(evt.data.Body).toString("utf8");
            }

            const paramsCss = {
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: defaultPendulumBefore.css,
              ResponseCacheControl: "no-cache",
            };

            myBucket
              .getObject(paramsCss)
              .on("complete", (evtCss) => {
                if (!isMounted) return;

                if (evtCss?.data?.Body) {
                  updatedPendulum.css = Buffer.from(evtCss.data.Body).toString("utf8");
                }

                setDefaultPendulum(updatedPendulum);
              })
              .send((err) => {
                if (err) console.error("CSS Fetch Error:", err);
              });
          })
          .send((err) => {
            if (err) console.error("HTML Fetch Error:", err);
          });
      } catch (err) {
        console.error("Error fetching credentials or data:", err);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [defaultPendulumBefore, error]); // Added necessary dependencies


  useEffect(() => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    if(!Cookies.get("dummy-token")) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }
    let tests = JSON.parse(localStorage.getItem("selectedTests"))
    if(tests) {
      dispatch(ACTION_SELECTED_TESTS({all: tests}))
    }
  },[])
 
  const objectMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  )

  useEffect(() => {
    if(selectedTests) {

      let newTests = objectMap(selectedTests, x => x?.filter(y => !y?.temporary && !y.extra))
      localStorage.setItem("selectedTests", JSON.stringify(newTests))
    }
  },[selectedTests])

  // if(loading) {
  //   return <DataSpinner />
  // }

  return ( 


       <div className="app">      
 
      <Switch> 
        <Route
        exact path="/fixer"
        render={() => (
          <ExtractImages />

        )}
        />

        <Route  
        exact path="/" 
          render={() => (
            <Redirect to="/admin" />
          )}
        /> 

            <Route  
                path="/admin"
                render={() => (
                <AdminRouter
                  currentPageReports={currentPageReports}
                  setCurrentPageReports={setCurrentPageReports}
                  activeView={activeView}
                  setActiveView={setActiveView}
                  currentPageAudits={currentPageAudits}
                  setCurrentPageAudits={setCurrentPageAudits}
                  activeType={activeType}
                  setActiveType={setActiveType}
                  handleLogout={handleLogout}
                  path="/admin" 
                  user={getUserData}
                  subOwner={subOwner}
                  setSubOwner={setSubOwner}
                  admins={admins}
                  users={users}
                  accountsRefetch={allAccountsRefetch}
                  clients={clients}
                  clientsRefetch={allClientsRefetch}
                  projects={projects}
                  reports={reports}
                  reportsRefetch={allReportsRefetch}
                  reportsCalles={reportsCalled}
                  reportLoading={allReportsLoading}
                  projectsLoading={allProjectsLoading}
                  projectsRefetch={allProjectsRefetch}
                  projectsCalled={allProjectsCalled}
                  templates={templates}
                  templatesRefetch={allTemplatesRefetch}
                  defaultDCOF={defaultDCOF}
                  defaultPendulum={defaultPendulum}
                  licenses={licenses}
                  licensesRefetch={allLicensesRefetch}
                  sites={sites}
                  allSitesRefetchDashboard={allSitesRefetch}
                  userRefetch={userDataRefetch}
                />
              )} 
            />

        <Route
            path="/contact-us"
              render={() => (
                <ContactUs />
              )}
            />

<Route
            path="/help-center"
              render={() => (
                <HelpCenter />
              )}
            />

<Route
            path="/store"
              render={() => (
                <Store />
              )}
            />


            <Route
            path="/bot-3000e"
              render={() => (
                <Bot />
              )}
            />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
        </div>
  );
};

const rootElement = document.getElementById("root");
Modal.setAppElement(rootElement);

ReactDOM.render(
  <Suspense fallback={<p>Loading credentials...</p>}>

  <Router>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <AliveScope>
        
          <App />
          
        </AliveScope>
      </ApolloProvider>
    </Provider>
  </Router>
  </Suspense>
,
  rootElement
);





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

