import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./templateHistoryModal.scss"
import moment from 'moment';
import archive from "../../../../assests/icons/archive.svg"
import ConfirmDeleteModal from '../confirmDeleteModal';
import {DataSpinner} from "../../spinner/dataSpinner"
import ACTION_LOADING_SPINNER_RESET from '../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import updateViewutation from '../../../../graphql/mutations/view/updateViewMutation';
import edit from "../../../../assests/icons/edit.svg"

import add from "../../../../assests/icons/addBlack.svg"


const AuditHistoryModal = (props) => {
 

  const [
    updateView,
    { data: updateViewData, loading: updateViewLoading }, 
  ] = useMutation(updateViewutation)


    const dispatch = useDispatch()
    const { 
        open,
        setOpen,
        auditHistory,
        loadViewFunc,
        deleteViewFunc,
        allViewsLoading,
deleteViewLoading,
selectedAudit,
allViewsRefetch

    } = props

    let validateDate = (date) => {
      if(date) {
      let rightDate = date.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g)
      if(rightDate?.length) {
        return rightDate[0]
      } else {
        return null
      }
    } else {
      return null
    }
    }

    const [selectedHistory, setSelectedHistory] = useState(null)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(null)

    useEffect(() => {
      if(auditHistory) {
        setSelectedHistory(auditHistory.find(x => x.default == true))
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
    },[auditHistory])

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
             
              
              {open ? allViewsLoading || updateViewLoading || deleteViewLoading  ? (
                  <DataSpinner small="l"/>
              ) : (
               <div className="modal task-modal" style={{width:"30vw", height:"100%", padding:"0"}}>
                <ConfirmDeleteModal
                deleteType={"view"}
                deleteModal={confirmDeleteModal}
                setDeleteModal={setConfirmDeleteModal}
                deleteViewFunc={deleteViewFunc}
                />
              {/* <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setOpen(null)
                  setSelectedHistory(null)
                }}
              /> */}
          {/* <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>select view</h1> */}
          <div style={{background:"#B9B8BC", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",   width:"100%", height:"200px", borderBottomLeftRadius:"8px", borderBottomRightRadius:"8px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
            <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", margin:"10px", textAlign:"center", fontWeight:"700", fontSize:"28px"}}>
              {selectedAudit?.name}
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", margin:"10px", fontWeight:"600", textAlign:"center", fontSize:"24px"}}>
              {selectedAudit?.address}
            </div>
          </div>
          <div className="header" style={{margin:"20px 0 0 0", width:"100%", textTransform:"none", fontSize:"24px"}}>
          Select the Audit View you want to access
          </div>
          <div className="form-group-container task-form" style={{height:"100%", flexDirection:"row", margin:"10px 0", justifyContent:"flex-start", overflowY:"auto"}}>
              <div className='modalSideBar' style={{width:"100%", border:0}}>

                  {
                      auditHistory ? (
                          auditHistory.sort((a,b) => b.default ? 1 : -1).map((history, i) => (
                            <div key={i} className="versionCardContainer" style={{margin:"20px 0", display:"flex", flexDirection:"column"}}>
                                                            <div onClick={() => {

                                                                let label = prompt(`${history.label ? "Edit Label" : "Add new label"}`, `${history.label ? history.label : ""}`);

                                                                if (label != null) {

                                                                  updateView({
                                                                    variables:{
                                                                      _id: history._id,
                                                                      label: label,
                                                                    }
                                                                  }).then(x => {
                                                                    allViewsRefetch()
                                                                  })
                                                                  
                                                                }

                                                            }}   className={`versionCard templatecontainer`} style={{position:"relative", width:"100%", color:"#808080", padding:"5px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", borderRadius:"10px"}}>
                                                              
                                                              {history.label ? history.label : "Add Unique Label"}

<img style={{ position:"absolute", right:5, top:5, width:".8vw", cursor:"pointer",
                color:"#5C92BF"}} src={history.label ? edit : add} />
                                                              </div>

                            <div style={{flexDirection:"row", justifyContent:"space-between"}} className={`versionCard templatecontainer ${ selectedHistory && selectedHistory._id == history._id && "selectedHistory"}`} onClick={() => !history.default ? loadViewFunc(history._id) : setOpen(false)}>
                              <div>
                                <div className='userSquareHeader'><span style={{fontSize:"12px",color:"#6DB240", display:"inline-block"}}>Created on</span>  {moment.unix(history.createdAt/1000).format("MM/DD/YYYY hh:mm a")?.toUpperCase()}</div>
                                {history.firstTime && <div className='squareContent'><span style={{color:"#6DB240", display:"inline-block"}}>Audit date </span> {moment(validateDate(history.firstTime)).format("MM/DD/YYYY")} </div>}
                                <div className='squareContent'>areas: {history.areas || 0}</div>
                                <div className='squareContent'>locations: {history.locations || 0}</div>
                                <div className='squareContent'>reports generated: {history.reports || 0}</div>
                                {history.default && <div className="squareContent" style={{color:"#6DB240"}}> recently used </div>}
                                </div>
                                {!history.default && <div>
                            <img  style={{cursor:"pointer", color:"#EF1111"}} onClick={(e) => {
                              e.stopPropagation()
                              setConfirmDeleteModal(history._id)
                            }} src={archive} />
                              </div>}
                            </div>
                            </div>
                          ))
                      ) : (
                        <DataSpinner small="l" />
                      )
                  } 
          </div>
          {/* {
                                selectedHistory && (
                                    <div className='historyViewerContainer'>
                                        {parse(`${selectedHistory.html} <style>${selectedHistory.css}</style>`)}
                                    </div>
                                    
                                )
                            } */}
          </div>
          {/* <div>
            <div
              className={`${selectedHistory && !selectedHistory.default ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={selectedHistory && !selectedHistory.default ? () => loadViewFunc(selectedHistory._id) : null }
            >
              Load
            </div>
          </div> */}
        </div>) : null}
      </Modal>
  )}

  export default AuditHistoryModal
       