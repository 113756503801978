import { gql } from "apollo-boost";

const updateTemplateMutation = gql`
  mutation(
    $_id: ID
    $html: String,
    $css: String,
    $thumbnail: String,
    $by: String,
  ) {
    updateTemplate(
    _id:$_id
    html:$html,
    css:$css,
    thumbnail:$thumbnail,
    by:$by,
    
    ) {
    _id
    type
    name
    default
    html
    css  
    thumbnail
    by
    }
  }
`;

export default updateTemplateMutation;
