import { gql } from "apollo-boost";

const addLicenseMutation = gql`
  mutation(
    $user:ID,
    $pass:Boolean,
    $endDate:String,
    $dateIssued:String,
    $freeReports:String,
    $users:String,
    $status:String,
    $notes:String,
    $createdBy: String
  ) {
    addLicense(
    user:$user
    createdBy:$createdBy
    pass:$pass
    endDate:$endDate
    dateIssued:$dateIssued
    freeReports:$freeReports
    users:$users
    status:$status
    notes:$notes
    ) {
    _id
    user
    createdBy
    pass
    endDate
    dateIssued
    freeReports
    users
    status,
    notes,
    number
  }
  }
`;

export default addLicenseMutation;
