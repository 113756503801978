import ResizableBox from "./Resize";
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import AuditArea from "../../../admin/adminProjects/auditArea";

export default function BarComponent({data, statistics, activeType}) {

 
  
  
  // const [statistics, setStatistics] = useState(null)

  // useEffect(() => {
  //   if(data) {
  //     setStatistics(calculateStatistics(data.map(x), sampleKeys))
  //   }
  // },[data])

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      let hoverNode = payload?.length ? payload[0] : null
      let selectedPayload = payload?.length ? payload[0]?.payload : null
      let typeName = hoverNode?.name

      return (
        <div style={{ zoom:"50%",width:"500px", display:"flex"}}>
        <div style={{width:"100%", height:"fit-content"}}>
      
          {selectedPayload ? <div className="tinnyContainer">     
          <div style={{width:"100%", background:selectedPayload.pass ? "#6DB240" : "#EF1111", display:"flex", justifyContent:"center", alignItems:"center"}} className="dashboardCard todayNumber todayNumberContainer forInsightssmall">

          {selectedPayload.area ? <AuditArea locationId={selectedPayload.locationId} hoverMode={true} area={selectedPayload.area} /> : null }
          </div>
          <div style={{display:"flex", width:"100%"}}>    
          
          <div style={{width:"100%"}} className="dashboardCard todayNumber todayNumberContainer forInsightssmall">
                          <div className="dashboardCardHeader">
                          {typeName}
                          </div>
                          <div className="todayNumber" style={{fontSize:"60px"}}>
                          {selectedPayload[typeName]}
                          </div>
                          </div>   
                          <div style={{width:"100%"}} className="dashboardCard todayNumber todayNumberContainer forInsightssmall">
                          <div className="dashboardCardHeader">
                          Test
                          </div>
                          <div className="todayNumber" style={{fontSize:"50px", height:"100%", display:"flex", justifyContent:"center"}}>
                           {selectedPayload.label}
                          </div>
                          </div>
                         
                          </div>
                          <div className="dashboardCard todayNumber todayNumberContainer forInsightssmall">
                          <div  className="dashboardCardHeader">
                          Max DCOF
                          </div> 
                          <div className="todayNumber" style={{fontSize:"60px"}}>
                          {selectedPayload.max}
                          </div>
                          </div>  
                          <div className="dashboardCard todayNumber todayNumberContainer forInsightssmall">
                          <div  className="dashboardCardHeader">
                          Min DCOF
                          </div> 
                          <div className="todayNumber" style={{fontSize:"60px"}}>
                          {selectedPayload.min}

                          </div>
                          </div>  
                          <div className="dashboardCard todayNumber todayNumberContainer forInsightssmall">
                          <div  className="dashboardCardHeader">
                          Range 
                          </div> 
                          <div className="todayNumber" style={{fontSize:"60px"}}>
                          {selectedPayload.range}
                          </div>
                          </div>  
                          <div style={{width:"100%"}} className="dashboardCard todayNumber todayNumberContainer forInsightssmall">
                          <div className="dashboardCardHeader">
                          Average DCOF
                          </div>
                          <div className="todayNumber" style={{fontSize:"60px"}}>
                          {selectedPayload.average}
                          </div>
                          </div>                
            </div> : null} 
        </div>
      </div>
      );
    }
  
    return null;
  };

  
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
      return (
      <g>
        <circle cx={x-0 + width / 2} cy={y+20 - radius} r={radius} fill="white" />
        <text x={x-0 + width / 2} y={y+20 - radius} fontSize={"8px"} fill="#000000" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  };



const renderQuarterTick = (tickProps) => {
  const { x, y, payload } = tickProps;
  const { value, offset } = payload;
  const date = new Date(value);
  const month = date.getMonth();
  const quarterNo = Math.floor(month / 3) + 1;
  const isMidMonth = month % 3 === 1;

  if (month % 3 === 1) {
    return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
  }

  const isLast = month === 11;

  if (month % 3 === 0 || isLast) {
    const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

    return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
  }
  return null;
};


  return (
    <>
      <div className="dashboardCard" style={{ margin:"20px 0px 0 0", width:"100%",height:"700px",}}>
      <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={800}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="label" />             
            

              <XAxis
                dataKey="date"
                axisLine={false}
                height={20}
                xAxisId="quarter"
              />


             {activeType == "samples" ?  <YAxis ticks={[0.20, 0.42, 0.60]} /> : null}
              <Tooltip shared={false} content={<CustomTooltip />} />
              {/* <Legend /> */}
              
              { activeType == "samples" ? (
                <>
                <Bar dataKey="Sample 1" fill="#8884d8" >

                              <LabelList dataKey="Sample 1" content={renderCustomizedLabel} />

                </Bar>

             

<Bar dataKey="Sample 2" fill="#82ca9d" >

              <LabelList dataKey="Sample 2" content={renderCustomizedLabel} />

</Bar>


<Bar dataKey="Sample 3" fill="#e2cc9d" >

              <LabelList dataKey="Sample 3" content={renderCustomizedLabel} />

</Bar>
                </>
              ) : (
<>

              <Bar className="barCell barCell1" dataKey="Sample 1 Run 1" stackId="a" fill="#8884d8">
                <LabelList dataKey={"Sample 1 Run 1"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell2" dataKey="Sample 1 Run 2" stackId="a" fill="#82ca9d">
                <LabelList dataKey={"Sample 1 Run 2"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell3" dataKey="Sample 1 Run 3" stackId="a" fill="#e2cc9d">
                <LabelList dataKey={"Sample 1 Run 3"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell4" dataKey="Sample 1 Run 4" stackId="a" fill="#88c4d8">
                <LabelList dataKey={"Sample 1 Run 4"} content={renderCustomizedLabel} />
              </Bar>

              <Bar className="barCell barCell5" dataKey="Sample 2 Run 1" stackId="b" fill="#8884d8">
                <LabelList dataKey={"Sample 2 Run 1"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell6" dataKey="Sample 2 Run 2" stackId="b" fill="#82ca9d">
                <LabelList dataKey={"Sample 2 Run 2"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell7" dataKey="Sample 2 Run 3" stackId="b" fill="#e2cc9d">
                <LabelList dataKey={"Sample 2 Run 3"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell8" dataKey="Sample 2 Run 4" stackId="b" fill="#88c4d8">
                <LabelList dataKey={"Sample 2 Run 4"} content={renderCustomizedLabel} />
              </Bar>



              <Bar className="barCell barCell9" dataKey="Sample 3 Run 1" stackId="c" fill="#8884d8">
                <LabelList dataKey={"Sample 3 Run 1"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell10" dataKey="Sample 3 Run 2" stackId="c" fill="#82ca9d">
                <LabelList dataKey={"Sample 3 Run 2"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell11" dataKey="Sample 3 Run 3" stackId="c" fill="#e2cc9d">
                <LabelList dataKey={"Sample 3 Run 3"} content={renderCustomizedLabel} />
              </Bar>
              <Bar className="barCell barCell12" dataKey="Sample 3 Run 4" stackId="c" fill="#88c4d8">
                <LabelList dataKey={"Sample 3 Run 4"} content={renderCustomizedLabel} />
              </Bar>
</>
              )
                
                

            }

              {/* <Bar dataKey="Sample 3" fill="#c2dc9d" /> */}
            </BarChart>
          </ResponsiveContainer>
      </div>
    </>
  );
}
