import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataSpinner } from '../../spinner/dataSpinner';
import MiniAvatarModal from './miniAvatarModal';

const ImagesLaterModal = (props) => {
    const {
        open,
        setOpen,
        updateAreas
        } = props

        const [laterAreas, setLaterAreas] = useState(null)
        const [progress, setProgress] = useState(0)
 
        const uploadImageToArea = (id , images) => {
            setLaterAreas([...laterAreas.map(x => x.id == id ? {...x, images: images} : x )])
        }
        useEffect(()=>{
            if(open) {
                setLaterAreas(open.filter(x => !x.images?.length))
            }
        },[open])

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
        <div className="modal task-modal" style={{height:"fit-content", width:"94vw"}}>          
        <FontAwesomeIcon
          className="modal_x"
          icon={faTimes}
          onClick={() => {   
            setLaterAreas(null)   
            setOpen(null)         
          }}
        />  
    <h1 className="header" style={{width:"100%"}}><span style={{opacity:".9", cursor:"pointer"}}></span> <span>Add Areas Photos</span> </h1>        
    <div style={{alignItems:"center"}} className="form-group-container task-form">
          <div className="form-row">
          <div className="form-collection textarea" style={{width:"100%"}}>
            {laterAreas?.map((x, i) => (
                <div key={i} style={{display:"flex", border:"2px solid red", margin:"30px 0", alignItems:"center", border:"1px solid #e0e0e0", borderRadius:"4px", padding:"7px", padding:"7px", justifyContent:"space-between"}}>
                <div style={{fontWeight:"bold"}}>{x.name} - {x.areaNumber}{x?.results?.length ? <span style={{color:"red"}}> *</span> : null }</div>
                <MiniAvatarModal
                i={i}
                _id={x.id}
                progress={progress}
                setProgress={setProgress}
                images={x.images}
                setImages={uploadImageToArea}
            />
            </div>
            ))}
            
      </div>
      </div>
      </div>
      <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
      <div
            
              className={
                 progress && progress !== 100 ? "auth-button addUser disable-button":laterAreas?.filter(x=> x?.results?.length)?.every(x => x.images?.length ) ?
                    "auth-button addUser" :
                    "auth-button addUser disable-button"
                    

              }
              style={{minHeight:"30px"}}
              onClick={ () => {
                progress && progress !== 100 ? null : laterAreas?.filter(x=> x?.results?.length)?.every(x => x.images?.length ) ? updateAreas(laterAreas) : null  
                

              }}
            >
              {progress && progress !== 100 ? <DataSpinner small="s" /> :"Save"}
            </div>
            </div>
      </div>
      </Modal>
  )}

  export default ImagesLaterModal
       