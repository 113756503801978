import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons';
import { useLocation } from "react-router-dom";

import { FormGroup, Label, Input } from "reactstrap";
import TableHeader from "./tableHeader";
import TableTabs from "./tableTabs"
import TableContent from "./tableContent";
import "./accountTable.scss"

export const AccountTable = (props) => {
    const {
        setShowPopup,
        siteContactPersonPhoneNumber,
        setSiteContactPersonPhoneNumber,
        selectedClient,
        userCompanyCity,
        setUserCompanyCity,
        userCompanyState,
        setUserCompanyState,
        userCompanyCountry,
        setUserCompanyCountry,
        images,
        setImages,
        emailError,
          setEmailError,
        subUser,
        setLicenseNumber,
        licenseNumber,
        currentUser,
        subScreen,
        accountType, 
        accountTabs,
        setAccountTabs,
        accountColumns,
        accountData,
        invitationModal,
        setInvitationModal,
        setUserFirstName,
        updateSiteFunc,
        userFirstName,
        setUserLastName,
        userLastName,
        setUserEmail,
        userEmail,
        inviteUser,
        emailAlreadyRegistered,
        invalidEmail,
        setEmailAlreadyRegistered,
        setInvalidEmail,
        setCompanyName,
        companyName,
        setCompanyEmail,
        companyEmail,
        setCompanyPhoneNumber,
        companyPhoneNumber,
        setCompanyWorkPhoneNumber,
        companyWorkPhoneNumber,
        setCompanyAddress,
        companyAddress,
        city,
        setCity,
        country,
        setCountry,
        state,
        setState,
        adminType,
        setAdminType,
        clientModal,
        setClientModal,
        setContactPersonName,
        contactPersonName,
        setUserPhoneNumber,
        userPhoneNumber,
        takeAPhotoSelected,
        changeTakeAPhotoSelected,
        webcamURI,
        changeWebcamURI,
        imageUploaded,
        changeImageUploaded,
        imagePreviewAvailable,
        changeImagePreviewAvailable,
        handleDeletedPreviewImage,
        handleImageUploaded,
        handleConfirmPhotoSubmit,
        addProfilePhotoClicked,
        setAddProfileClicked,
        setUserFranchisee,
        userFranchisee,
        setUserRole,
        userRole,
        setUserCompanyName,
        userCompanyName,
        siteModal,
        setSiteModal,
        setSiteName,
        siteName,
        setSiteEmail,
        siteEmail,
        addSiteFunc,
        setSiteAddress,
        siteAddress,
        siteContactPersonName,
        setSiteContactPersonName,
        updateUserFunc,
        searchFilter,
        setSearchFilter,
        notTabs,
        notTemplate,
        notSearch,
        user,
        loading,
        setLicenseModal,
        conditionalRowStyles,
        defaultSortFieldId,
        setContactPersonFirstName,
        setContactPersonLastName,
        contactPersonFirstName,
        contactPersonLastName,
        setUserCompanyAddress,
        userCompanyAddress,
        selectedUser,
        zip,
setZip,
    } = props

    const location = useLocation()


    return (
        <div className="accountContainer" style={{height: accountType == "Site" ? "auto" : "100%"}}>
            <div className={`tableContainer ${!accountTabs && "tabsFalse"} ${accountType == "Report" && "reportsRadius"}`} style={{padding: notTabs ? "0" : ".7rem"}}>
            {accountType == "Site" && <div className="tableSitesHeader">Sites</div>}
                {accountType !== "Audit" && !notSearch && (<TableHeader
                user={user}
                zip={zip}
                setZip={setZip}
                updateSiteFunc={updateSiteFunc}
                selectedClient={selectedClient}
                userCompanyCity={userCompanyCity}
                setUserCompanyCity={setUserCompanyCity}
                userCompanyState={userCompanyState}
                setUserCompanyState={setUserCompanyState}
                userCompanyCountry={userCompanyCountry}
                setUserCompanyCountry={setUserCompanyCountry}
                images={images}
                setImages={setImages}
                emailError={emailError}
                setEmailError={setEmailError}
                subUser={subUser}
                setLicenseNumber={setLicenseNumber}
                licenseNumber={licenseNumber}
                selectedUser={selectedUser}
                setContactPersonFirstName={setContactPersonFirstName}
                setContactPersonLastName={setContactPersonLastName}
                contactPersonFirstName={contactPersonFirstName}
                contactPersonLastName={contactPersonLastName}
                currentUser={currentUser}
                setSearchFilter={setSearchFilter}
                searchFilter={searchFilter}
                accountType={accountType}
                invitationModal={invitationModal}
                setInvitationModal={setInvitationModal}
                setUserFirstName={setUserFirstName}
                userFirstName={userFirstName}
                setUserLastName={setUserLastName}
                userLastName={userLastName}
                setUserEmail={setUserEmail}
                userEmail={userEmail}
                inviteUser={inviteUser}
                emailAlreadyRegistered={emailAlreadyRegistered}
                invalidEmail={invalidEmail}
                setEmailAlreadyRegistered={setEmailAlreadyRegistered}
                setInvalidEmail={setInvalidEmail}
                setCompanyName={setCompanyName}
                companyName={companyName}
                setCompanyEmail={setCompanyEmail}
                companyEmail={companyEmail}
                setCompanyPhoneNumber={setCompanyPhoneNumber}
                companyPhoneNumber={companyPhoneNumber}
                setCompanyWorkPhoneNumber={setCompanyWorkPhoneNumber}
                companyWorkPhoneNumber={companyWorkPhoneNumber}
                setCompanyAddress={setCompanyAddress}
                companyAddress={companyAddress}
                city={city}
                setCity={setCity}
                country={country}
                setCountry={setCountry}
                state={state}
                setState={setState}
                adminType={adminType}
                setAdminType={setAdminType}
                clientModal={clientModal}
                setClientModal={setClientModal}
                setContactPersonName={setContactPersonName}
                contactPersonName={contactPersonName}
                setUserPhoneNumber={setUserPhoneNumber}
                userPhoneNumber={userPhoneNumber}
                takeAPhotoSelected={takeAPhotoSelected}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                handleImageUploaded={handleImageUploaded}
                handleConfirmPhotoSubmit={handleConfirmPhotoSubmit}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
                setUserFranchisee={setUserFranchisee}
                userFranchisee={userFranchisee}
                setUserRole={setUserRole}
                userRole={userRole}
                setUserCompanyName={setUserCompanyName}
                userCompanyName={userCompanyName}
                siteModal={siteModal}
                setSiteModal={setSiteModal}
                setSiteName={setSiteName}
                siteName={siteName}
                setSiteEmail={setSiteEmail}
                siteEmail={siteEmail}
                addSiteFunc={addSiteFunc}
                setSiteAddress={setSiteAddress}
                siteAddress={siteAddress}
                siteContactPersonName={siteContactPersonName}
                setSiteContactPersonName={setSiteContactPersonName}
                siteContactPersonPhoneNumber={siteContactPersonPhoneNumber}
                setSiteContactPersonPhoneNumber={setSiteContactPersonPhoneNumber}
                updateUserFunc={updateUserFunc}
                setLicenseModal={setLicenseModal}
                setUserCompanyAddress={setUserCompanyAddress}
                userCompanyAddress={userCompanyAddress}
                />)}
                <TableContent setShowPopup={setShowPopup}  defaultSortFieldId={defaultSortFieldId} conditionalRowStyles={conditionalRowStyles} loading={loading} subScreen={subScreen} accountData={accountData} accountColumns={accountColumns} />

            </div>
           
        </div>
    )
}

