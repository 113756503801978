import { gql } from "apollo-boost";

const updateClientStatusMutation = gql`
  mutation(
      $_id: ID
      $status: Boolean
      ) {
    updateClientStatus(
    _id: $_id
    status: $status
    ) {
      _id
      status
    }
  }
`;

export default updateClientStatusMutation;
