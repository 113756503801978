import { gql } from "apollo-boost";

const updateTemplateStatusMutation = gql`
  mutation(
      $_id: ID
      $type: String
      ) {
    updateTemplateStatus(
    _id: $_id
    type: $type
    ) {
      _id
      type
    }
  }
`;

export default updateTemplateStatusMutation;
