import React, { useEffect, useRef, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import Dropdown from "react-dropdown";
import { DataSpinner } from '../../spinner/dataSpinner';

import infoI from "../../../../assests/icons/info.svg"

import { useDispatch } from 'react-redux';
import ACTION_INFO_ACTIVE from '../../../../actions/InfoActive/ACTION_INFO_ACTIVE';
import { UncontrolledTooltip } from 'reactstrap';
import WarningModal from '../warningModal';

const AddAreaModal = (props) => {
  const dispatch = useDispatch()


  const canvasRef = useRef() 
    const {
        allTests,
        open,
        setOpen,
        handleReset,
        areaNumber,
        haveData,
        setHaveData,
        testingCategory,
        areas,
        updateAreaOnFly,
        setTestingCategoryForExtraData
    } = props
const [areaNumberOnFly, setAreaNumberOnFly] = useState("")
const [testingCategoryOnFly, setTestingCategoryOnFly] = useState("")
const [areaNumberError, setAreaNumberError] = useState("")
const [warningMessage, setWarningMessage] = useState(null)

const isExistAreaNumber = (str) => {
  return areas.some(x => x.areaNumber == str) && areaNumber !== str;
}


const validateAreaNumberInput = (e) => {
  if (isExistAreaNumber(e.value)) {
    setAreaNumberError("Area number already exist")
  } else {
    setAreaNumberError("")
    areaNumber !== e.value ? setAreaNumberOnFly(e.value) : setAreaNumberOnFly(null)
  }  
}

const handleResetFunc = () => {
  // updateAreaOnFly(open, areaNumberOnFly, testingCategoryOnFly )
  handleReset(open, areaNumberOnFly, testingCategoryOnFly)
  setWarningMessage(null)
  setOpen(null)
}
useEffect(() => {
  setTestingCategoryForExtraData(null)
},[])
return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center",  
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"18px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >          
              {open ? (
               <div className="modal task-modal" style={{height:"50vh", width:"44vw", justifyContent:"space-between", overflow:"visible"}}>
                  <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {   
                  setTestingCategoryOnFly(null)
                  setAreaNumberOnFly(null)               
                 setOpen(null)
                }}
              />  
              {warningMessage && (
            <WarningModal
            warning={"Delete Data"} 
            open={warningMessage}
            setOpen={setWarningMessage}
            button={handleResetFunc}
            />
          )}
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}><span>
          {open == "location" ?"Extra data testing category" : "Update Area" }         
          {open!=="location"?<div data-for={"main"} style={{display:"inline-block"}}><img id="UncontrolledTooltipExample_77q" onClick={() => dispatch(ACTION_INFO_ACTIVE("Determine the area(s) to be tested based on the test objective. An area is a contiguous space with hard surface flooring of the same or similar type. Before testing, you will enter a unique area identification code (AID) for that specific Area which corresponds with that particular test area (for example, the lobby is Area 001 (A001) but the bathroom is Area 002 (A002)). If you have already completed a slip test using the BOT-3000E select the AID (001, 002…) that matches the Area # that you selected previously on the BOT-3000E."))} width={15} style={{cursor:"pointer",
          color:"#5C92BF", margin:"0 10px"}} src={infoI} />
          <UncontrolledTooltip
            placement="top"
            target="UncontrolledTooltipExample_77q"
          >
    Need Help!
  </UncontrolledTooltip>
              </div>:null}
          </span> </h1>        
          <div style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto", overflow:"visible"}} className="form-group-container task-form">
          { true ? 
          <>
          { true ? ( 
          <>
          {open!=="location"?<div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
              <div className="form-label" style={{justifyContent:"space-between", display:"flex", color:"black", textTransform: areaNumberError? "none" : "capitalize"}}><div>Area Identification (AID)</div>
              <div style={{display:"flex", color:"#EF1111"}}>{areaNumberError && <div style={{color: areaNumberError ? "#EF1111" : "black" }}>{areaNumberError}</div>}<div data-for={"main"}><img id="UncontrolledTooltipExample_3" onClick={() => dispatch(ACTION_INFO_ACTIVE("AID codes or “areas” are used to help identify the physical area that you are testing within buildings, facilities, or work sites, etc. These “areas” are the specific “room(s)” where the slip testing will take place within a site. A single audit cannot have repeat AID’s, so the user must change the AID on the BOT-3000E when they move between areas or “rooms”. For example, if you test 3 “areas” you must have 3 different AID codes (001, 002, 003)."))} width={15} style={{cursor:"pointer",
              color:"#5C92BF", margin:"0 10px"}} src={infoI} /><UncontrolledTooltip
                placement="top"
                target="UncontrolledTooltipExample_3"
              >
                Need Help!
              </UncontrolledTooltip></div></div>
              </div>
              <Dropdown 
              options={["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012", "013", "014", "015", "016", "017", "018", "019", "020", "021", "022", "023", "024", "025", "026", "027", "028", "029", "030", "031", "032", "033", "034", "035", "036", "037", "038", "039", "040", "041", "042", "043", "044", "045", "046", "047", "048", "049", "050", "051", "052", "053", "054", "055", "056", "057", "058", "059", "060", "061", "062", "063", "064", "065", "066", "067", "068", "069", "070", "071", "072", "073", "074", "075", "076", "077", "078", "079", "080", "081", "082", "083", "084", "085", "086", "087", "088", "089", "090", "091", "092", "093", "094", "095", "096", "097", "098", "099", "100"]}
                            controlClassName="form-dropdown" 
                  value={areaNumberOnFly || areaNumber}
                  onChange={(choice) =>
                   validateAreaNumberInput(choice)
                  }
              placeholder={"Choose AID from 001 to 100"}
              />
            </div> 
            </div>:null}    
          <div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
              <div className="form-label"> 
                <span>Product Use Category<span style={{color:"red"}}> *</span></span>
                <img id="UncontrolledTooltipExample_5"
                onClick={() => dispatch(ACTION_INFO_ACTIVE(<div style={{display:"flex", justifyContent:"flex-start", flexDirection:"column", height:"100%", overflowY:"auto"}}>
                    <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Interior, Dry: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a dry DCOF value of 0.42 or greater</span><br/>
                    Possible Areas in this category, may include but are not
                    limited to: indoor shopping malls (this does not include the
                    food courts); hotel lobbies (except if there are water sources
                    such as fountains); office buildings (this does not include
                    kitchens or restrooms); showrooms; home interiors (this does
                    not include rooms with water sources); and other level areas
                    where hard surface flooring materials will be kept dry and
                    contaminant free when walked upon and proper safety
                    procedures will be followed when cleaning the hard surface
                    flooring materials. Walk-off mats may be necessary for use in
                    entrance areas in this category, due to the possibility that water
                    or other contaminants, which would otherwise not be present,
                    may occasionally and consistently be transported onto the
                    flooring surface. This Area shall be kept dry, level, and free of
                    contaminants when walked upon. Hard surface flooring
                    materials not intended to be walked upon when wet shall have
                    a
                    dry DCOF value at or above 0.42* when using an SBR testfoot (per Section 10.0, ANSI
                    A326.3-2021).</div></div>
                    
                    <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Interior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.42 or greater</span><br/>
                    Possible Areas in this category, may include but are not
                    limited to: entry foyers, public restrooms (without showers),
                    grocery stores, 'front of the house' in fine and casual dining
                    restaurants with a closed kitchen, home interiors including
                    bathrooms and kitchens, and other areas where hard surface
                    flooring materials may be walked upon when wet if level clean,
                    maintained, and free of standing water or other contaminants.
                    Hard surface flooring materials in this category shall have a wet
                    DCOF value at or above 0.42* when using an SBR testfoot and 0.05% Sodium-Lauryl Sulfate
                     (SLS) solution (per ANSI A326.3-2021 Standard).</div></div>
                    
                   <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Interior, Wet Plus: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.50 or greater</span><br/>
                    Possible Areas in this category, may include but are not
                    limited to: public showers, interior pool decks, locker rooms,
                    covered exterior areas, steam rooms, 'front of house' in
                    restaurants with an open kitchen, and 'front of house' in quick
                    service, fast-casual, and self-service restaurants, food areas in
                    gas stations, and other similar areas where the hard surface
                    flooring materials may be walked upon when wet if clean
                    maintained, and free of standing water or other contaminants. Hard surface flooring materials in this category may require frequent maintenance to keep clean, and free of standing water or other contaminants.</div></div>
                    
                   <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Exterior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater</span><br/>
                    Possible Areas in this category, may include but are
                    not limited to: level outdoor living spaces including pool
                    decks, walkways, patios, and sidewalks, where such
                    floors may be waiked upon when wet (excluding ice or
                    snow) if level, clean, maintained, and free of standing
                    water or other contaminants. Hard surface flooring
                    materials in this category may require frequent
                    maintenance to keep clean.</div></div>
                    
                   <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Oils/Greases: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater </span><br/>
                    Possible Areas in this category, may include but are not
                    llmited to: level areas regularly exposed to automotive fluids,
                    'back of the house' fast food or family styie restaurants, food
                    preparation areas with grills or deep-fry equipment, and any
                    area where oil, grease and/or fats may be present so long as
                    such floors are level, regularly cleaned, maintained, and free of
                    standing water and contaminant build-up, Hard surface flooring materials in this category may require frequent maintenance to keep clean.</div></div>
                </div>) )}
                width={15} style={{cursor:"pointer",
                 color:"#5C92BF", margin:"0 10px"}} src={infoI}/>
                 <UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltipExample_5"
                >
                  Need Help! 
                </UncontrolledTooltip>
                </div>
              <Dropdown
                  options={[
                    { value :"Interior, Dry: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a dry DCOF value of 0.42 or greater", label:<div style={{display:"flex"}}>Interior, Dry: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a dry DCOF value of 0.42 or greater</div>},
                    { value :"Interior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.42 or greater", label:<div style={{display:"flex"}}>Interior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.42 or greater</div>},
                    { value :"Interior, Wet Plus: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.50 or greater", label:<div style={{display:"flex"}}>Interior, Wet Plus: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.50 or greater</div>},
                    { value :"Exterior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater", label:<div style={{display:"flex"}}>Exterior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater</div>},
                    { value :"Oils/Greases: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater ", label:<div style={{display:"flex"}}>Oils/Greases: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater </div>},
               ]}
                  onChange={(choice) => { 
                    testingCategory !== choice.value ? setTestingCategoryOnFly(choice.value) : setTestingCategoryOnFly(null)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="Product Use Category"
                  value={testingCategoryOnFly || testingCategory}
                />

            </div>
            </div>
            </>
            ) : null
          }
            </> : (
                <DataSpinner small="l" /> 
            ) }
          </div>
            <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
            <div
            
              className={ (areaNumberOnFly == areaNumber && testingCategoryOnFly == testingCategory) || (!areaNumberOnFly && !testingCategoryOnFly) || areaNumberError ?
                    "auth-button addUser disable-button" :
                    "auth-button addUser"
              }
              style={{margin:".5rem"}}
              onClick={()=> {
                if(open!=="location") {
                  if((areaNumberOnFly == areaNumber && testingCategoryOnFly == testingCategory) || (!areaNumberOnFly && !testingCategoryOnFly) || areaNumberError) {
                   return null
                  } else if(haveData && allTests?.find(x => x.areaId == open.id)) {
                    testingCategoryOnFly?.includes(open.category) && (open.areaNumber == areaNumberOnFly || !areaNumberOnFly)? handleResetFunc() : setWarningMessage(<div>To change the Area # or Product Use Category, all testing data inside of this area ({open.name}) must be deleted.<br/><div style={{marginTop:"40px", fontSize:"14px", color:"#5C92BF"}}>- Press "Confirm" to delete testing data from ({open.name})</div></div>)
                } else {
                  handleResetFunc()
                }
                } else { 
                  setTestingCategoryForExtraData(testingCategoryOnFly)
                  setOpen(null)
                }
                
              }} 
            >
            {open == "location" ? "Continue" : "Update"}            
            </div>

          </div>
        </div>) : null}
      </Modal>
  )}

  export default AddAreaModal
       