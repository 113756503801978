import { gql } from "apollo-boost";

const allViewsQuery = gql`
  
    query(
    $project: ID
  ) { 
    allViews (
      project: $project
    ) {
    _id
    project
    default
    firstTime
    areas
    locations
    passed
    failed
    reports
    createdAt
    allReports
    label
    tileResult {
      first
      firstAfter
      second
      secondAfter
      exist
      result
      pass
      passAfter
      dcof
    }
    }
  }
  
`;

export default allViewsQuery;
