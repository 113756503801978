import React, { useEffect, useState } from "react"
import "./auditArea.scss"
import { DataSpinner, DataSpinner as RenderLocaionsLoader } from "../../../resuable/spinner/dataSpinner"
import { faChevronDown, faClosedCaptioning, faFileAlt, faPen } from "@fortawesome/free-solid-svg-icons"
import DataTable from "react-data-table-component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AddLocationModal from "../../../resuable/modals/addLocationModal"
import VariablesModal from "../../../resuable/modals/variablesModal"
import addLocationMutation from "../../../../graphql/mutations/location/addLocationMutation"
import ACTION_LOADING_SPINNER_ACTIVE from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import ACTION_LOADING_SPINNER_RESET from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET"
import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux"
import LZString from "lz-string"
// import CanvasDraw from "react-canvas-draw" 
import randomColor from "randomcolor"
import edit from "../../../../assests/icons/edit.svg"
import archive from "../../../../assests/icons/archive.svg"
import download from "../../../../assests/icons/download.svg"
import { saveAs } from 'file-saver';
import { reset, useShapes } from "../../../resuable/modals/addAreaModal/diagramWrapper/state"
import updateAreaMutation from "../../../../graphql/mutations/area/updateAreaMutation"
import deleteLocationMutation from "../../../../graphql/mutations/location/deleteLocationMutation"
import { convertArrayToCSV } from'convert-array-to-csv';
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import plus from "../../../../assests/icons/plus.png"
import AWS from 'aws-sdk'
import { UncontrolledTooltip } from "reactstrap"
import updateLocationMutation from "../../../../graphql/mutations/location/updateLocationMutation"
import moment from "moment"

const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
}) 

const AuditArea = ({setAddAreaDropDown,hoverMode,locationId, addLocationToDeleted, locationToDelete, setLocationToDelete, auditHistoryLoading, auditHistory, photo, setPhoto, selectedAudit, allLocationsRefetch, lines, runTestForLocation, allAreasRefetchToLoad, allAreasCalledToLoad, editAreaImages, setLines, area, locations, allAreasRefetch, locationsRefetch, defaultDCOF, templateVariables, defaultDcofHtml, defaultDcofCss, user, areaNumber, setAddAreaModal}) => {
    const [
        addLocation,
        { data: addLocationData },
      ] = useMutation(addLocationMutation)

      const [
        updateLocation,
        { data: updateLocationData },
      ] = useMutation(updateLocationMutation)

      
      const [
        deleteLocation, 
        { data: deleteLocationData },
      ] = useMutation(deleteLocationMutation)

    const deleteLocationFunc = (id, newD) => {
      let imageD = newDiagramImage || newD
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setLocationModal(null)
      let newTestingDiagram = useShapes.get()
      let finalDiagram = {}
      finalDiagram.shapes = newTestingDiagram.shapes
      finalDiagram.lines = newTestingDiagram.lines
      let newAreaName = area.name
      const base64Data = new Buffer.from(imageD.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      const fileName = `${newAreaName.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "")}-${selectedAudit.id}-${global.Date.now()}.png`
      // Getting the file type, ie: jpeg, png or gif
      const type = imageD.split(';')[0].split('/')[1];
    
      const params = { 
        ACL: 'public-read', 
          Body: base64Data,
          Bucket: S3_BUCKET,
          Key: fileName,
          ContentEncoding: 'base64', // required
          ContentType: `image/${type}` // required. Notice the back ticks
      };
  
      myBucket.putObject(params)
      .on("complete", (evt) => {
      let newUrl = `https://step-us-east-prod.s3.amazonaws.com/${fileName}`
      updateArea({
        variables:{
        _id: area.id,
        user: user?.user?._id,
        diagramImage: newUrl,
        testingDiagram: JSON.stringify(finalDiagram)
        }
        
      }).then(() => { 
        allAreasRefetch()  
        allAreasCalledToLoad && allAreasRefetchToLoad()        
        deleteLocation({
        variables: {
            _id: id
        }
        }).then(() => {
            allLocationsRefetch() 
            useShapes.set({
              selected: null,
              shapes: {},
              lines: []
            })
            setLines([])
            setPhoto(null)
            setNewDiagramImage(null)
    })
  })

  }).send((err) => {
    if (err) {
    console.log(err)
    }
}) 
}
    const dispatch = useDispatch()
    const [locationsData, setLocationsData] = useState([])   
    const conditionalRowStyles= [
      {
        when: row => row,
        style: {
          cursor:"auto"
        },
      },
    ];
    const [locationModalPage, setLocationModalPage] = useState(0)
    const [locationModalLabel, setLocationModalLabel] = useState("")
    const [variablesModal, setVariablesModal] = useState(null)
    const [currentRow, setCurrentRow] = useState("")
    const [rowCase, setRowCase] = useState("")
    const [shapeToDelete, selectShapeToDelete] = useState("")
    const downloadData = (results) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      results.map((x,i) => {
        let pdf = x.pdfUrl
        let testsData = x.results
        const csvFromArrayOfArrays = convertArrayToCSV(testsData, {
          header:[],        
          separator: ','
        });
        const csvBlob = new Blob([csvFromArrayOfArrays], {type: "text/plain;charset=utf-8"});

        let pdfFilename = x.results[0].fileName
        const params = {
          Bucket: S3_BUCKET,  
          Key: pdf.slice(pdf.indexOf("amazonaws.com/") + 14 , pdf.length)
        }
         
        myBucket.getObject(params) 
        .on("complete", (evt) => {
          if(evt?.data?.Body) {
            let pdfArr = evt.data.Body
            let blobPdf = new Blob([pdfArr], {type: 'application/pdf'})
            saveAs(blobPdf, pdfFilename)
            saveAs(csvBlob, "DATA.LOG")
            dispatch(ACTION_LOADING_SPINNER_RESET())
          }   
        }) 
        .send((err) => {
          if (err) console.log(err) 
      }) 
      }) 
    }
    const locationsColumns = [
        // {
        //     name: "Add Extra Data",
        //     cell: (row) => <><img onClick={() => true ? runTestForLocation(row) : null} height={50} width={50} style={{marginRight:".5rem", cursor:"pointer",
        //     color:"#5C92BF"}} src={plus} />
        //     {area.extra?.filter(x => x.locationId == row.id)?.length ? area.extra?.filter(x => x.locationId == row.id).map((y,i) => (
        //     <div id={`UncontrolledTooltipExample_Status${i}${row.id}`} key={i} onClick={(e) => displayLocationsResults(e, [y])} className="audit-status" style={{cursor:"pointer" ,backgroundColor: "#B9B8BC", margin:"0 5px"}}>
        //     <span>Extra</span>
        //     <UncontrolledTooltip 
        //     target={`UncontrolledTooltipExample_Status${i}${row.id}`}>
        //       Click to view data
        //     </UncontrolledTooltip>
        //     </div>
        //     )):null}
        //     </>
        //     ,
        //     ignoreRowClick: true,
        //     allowOverflow: false,
        //     style:{
        //         fontSize:"19px",
        //         width:"10px",
        //     } 
        // }, 
        // {area.pass !== null && <span style={{width:"auto", padding:"0px 7px", margin:"0 1rem", backgroundColor: area.pass == "pass" ? "#6DB240" : "#EF1111"}} className="audit-status" >{area.pass}</span>}
   
        {
            name: "Download data",
            cell: (row) =>
            <img
            style={{
              opacity: area?.results?.length ? area.results?.find(x => (x.locationId == row.id || (x.areaId == row.area && x.locationCode == Object.keys(row.coordinates)[0]))) ? 1 : .4 : .4,
              cursor: area?.results?.length ? area.results?.find(x => (x.locationId == row.id || (x.areaId == row.area && x.locationCode == Object.keys(row.coordinates)[0]))) ? "pointer" : "auto" : "auto"
            }} 
            onClick={() => area.results?.some(x => (x.locationId == row.id || (x.areaId == row.area && x.locationCode == Object.keys(row.coordinates)[0]))) && downloadData(area.results?.filter(x => (x.locationId == row.id || (x.areaId == row.area && x.locationCode == Object.keys(row.coordinates)[0]))))}
            src={download} 
            />,

        },
        {
            name: 'location',
            cell: row => <div style={{textTransform:"capitalize"}}>{row.coordinates ? Object.keys(row.coordinates)?.length ? Object.keys(row.coordinates)[0] : null : null }</div>,

        },
        { 
          name: 'status',
          cell: (row) => area.results?.filter(x => x.locationId == row.id)?.length ? area.results?.filter(x => x.locationId == row.id).map((y,i) => (
            <div id={`UncontrolledTooltipExample_Status${i}${row.id}`} key={i} onClick={(e) => displayLocationsResults(e, [y])} className="audit-status" style={{cursor:"pointer" ,backgroundColor: y.passed ? "#6DB240" : "#EF1111", margin:"0 5px"}}>
          <span>{ y.passed ? "Passed" : "Failed"}</span>
          <UncontrolledTooltip 
          target={`UncontrolledTooltipExample_Status${i}${row.id}`}
          >
            Click to view data
          </UncontrolledTooltip>
          </div>
          )) : <div className="audit-status" style={{backgroundColor:"#D4D243" ,margin:"0 5px", cursor:"auto"}}>
          <span>Pending</span></div>
          ,   
          ignoreRowClick: true,
          allowOverflow: false,
          button: false,
      },   
      {
        name:"Delete",
        cell: (row) => <><img id={`UncontrolledTooltipExample_Archive${row.id}`} style={{cursor:  !area.results?.some(x => x.locationId == row.id) ? "pointer" : "auto", opacity: !area.results?.some(x => x.locationId == row.id) ? 1 : .4}} onClick={() => {
          // setSelectedSite(row)
          //deleteLocation(row.id)
          if(!area.results?.some(x => x.locationId == row.id)) {
          setAreaDiagram(area.diagramImage)
          setPhoto(area.diagramPhoto)
          let testingDiagram = JSON.parse(area.testingDiagram)
          let selectedLocation = area.locationsCoordinates.find(x => Object.entries(x).some(([key, x]) => x?.id == row?.id))
          let beforeTesting = Object.entries(testingDiagram?.shapes).map(([key, x]) => {
            if(key == Object.keys(selectedLocation)[0]) {
              x._id = selectedLocation[key]?.id
              selectShapeToDelete(key)
              return [key,x] 
            } else {
              return [key,x]
            }
          })
          let newShapes = Object.fromEntries(beforeTesting)
          testingDiagram.shapes = newShapes
          setAreaLines(testingDiagram)
          setLocationModal(row.id)
          setLocationModalLabel(() => randomColor())
          // setDeleteSite(true)
        }
        }} src={archive} />
        {area.results?.some(x => x.locationId == row.id) && <UncontrolledTooltip  
          target={`UncontrolledTooltipExample_Archive${row.id}`}
        >
          You can't delete tested location
        </UncontrolledTooltip>}
        </>,
        ignoreRowClick: true, 
        allowOverflow: false,
        button: true,
        style:{
            fontSize:"19px",
        }
    },
        // {
        //     name: 'condition',
        //     selector: row => row.condition,

        // },         
    ];
    
    // fetch location data

    useEffect(() => {
        if(locations) {
            setLocationsData(
                locations.map((location) => {
                  const mappingLocation = {
                      id: location.id,
                      area: location.area,
                      location: location.location,
                      readings: location.readings,
                      samples: location.samples,
                      condition: location.condition,
                      coordinates: location.coordinates ? JSON.parse(location.coordinates) ? JSON.parse(location.coordinates) : null : null,
                      status: location.pass,
                      label: location.label ? location.label : "#444"
                  }                                 
                  
                  return mappingLocation
              }).sort((a,b) => Number(Object.keys(a.coordinates)[0].slice(-3)) - Number(Object.keys(b.coordinates)[0].slice(-3))  ) 
            )
        }
    },[locations])

    // add locaiton modal

        const [locationModal, setLocationModal] = useState(null)
        const [locationCondition, setLocationCondition] = useState("")
        const [locationReadings, setLocationReadings] = useState("")
        const [locationDetails, setLocationDetails] = useState("")
        const [areaDiagram, setAreaDiagram] = useState(null)
        const [areaLines, setAreaLines] = useState(null)
        const [newDiagramImage, setNewDiagramImage] = useState(null)
        const [locationCoordinates, setLocationCoordinates] = useState(null)
               
        const [
          updateArea, 
          { data: updateAreaData },
        ] = useMutation(updateAreaMutation)
    
        const refetchArea = () => {
          setLocationToDelete([])
          setLocationReadings("")
          setLocationDetails("")
          setLocationCondition("")
          setLocationCoordinates(null)
          setLocationModalPage(0)
          setLocationModalLabel("")
          locationsRefetch()
          allAreasRefetch()
          allAreasCalledToLoad && allAreasRefetchToLoad() 
          reset()
          setPhoto(null)
          setNewDiagramImage(null)
        }
        const addLocationFunc = (newD) => {
          let imageD = newDiagramImage || newD
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setLocationModal(null)
        let allLocations = useShapes.get()
        let allLocationsArr = Object.entries(allLocations.shapes)
        let newLocation = allLocationsArr.filter(([key, shape]) => key.includes("location") && shape.new)
        let updatedLocation = allLocationsArr.filter(([key, shape]) => key.includes("location") && shape.update)
        let finalLocation = Object.fromEntries(newLocation)
        let finalUpdatedLocation = Object.fromEntries(updatedLocation)
        finalLocation[Object.keys(finalLocation)[0]] = {...finalLocation[Object.keys(finalLocation)[0]], new: false}
        finalUpdatedLocation[Object.keys(finalUpdatedLocation)[0]] = {...finalUpdatedLocation[Object.keys(finalUpdatedLocation)[0]], update: false}
        let finalDiagram = {}
        finalDiagram.shapes = allLocations.shapes
        finalDiagram.lines = allLocations.lines
        Object.keys(finalDiagram.shapes).map(x => {
           if(finalDiagram.shapes[x].new == true) {
             let newLocation  = {...finalDiagram.shapes[x], new: false}
            finalDiagram.shapes = {...finalDiagram.shapes, [x]: newLocation}
           } else {
            let oldLocation  = {...finalDiagram.shapes[x], update: false}
            finalDiagram.shapes = {...finalDiagram.shapes, [x]: oldLocation}
           }
        })

        if(finalLocation) { 


          const base64Data = new Buffer.from(imageD.replace(/^data:image\/\w+;base64,/, ""), 'base64');

          // Getting the file type, ie: jpeg, png or gif
          const type = imageD.split(';')[0].split('/')[1];
           const fileName = `${area.name?.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "") }-${selectedAudit.id}-${global.Date.now()}.png`
        
          const params = {
            ACL: 'public-read',
              Body: base64Data,
              Bucket: S3_BUCKET,
              Key: fileName,
              ContentEncoding: 'base64', // required
              ContentType: `image/${type}` // required. Notice the back ticks
          };
      
          myBucket.putObject(params)
              .on("complete", (evt) => {

                let newUrl = `https://step-us-east-prod.s3.amazonaws.com/${fileName}`

                updateArea({
                  variables:{
                  _id: area.id,
                  user: user?.user?._id,
                  diagramImage: newUrl, 
                  testingDiagram: JSON.stringify(finalDiagram)
                  }
                  
                }).then(async () => {
                  let locationsD = await Promise.all(locationToDelete.map( async (y, i, a) => {
                    let location = locationsData.find(x => Object.keys(x.coordinates)[0] == y && x.area == area.id)
                    if(location) {
                       await deleteLocation({
                      variables:{
                        _id: location.id,
                      }
                    })
                  
                  }}))

                  let locationsU = await Promise.all(updatedLocation.map(async ([key, x], i) => {
                    let location = locationsData.find(x => Object.keys(x.coordinates)[0] == key)
                    if(location) {
                        await updateLocation({
                      variables:{
                        _id: location.id,
                        coordinates: JSON.stringify({[key]: {...finalUpdatedLocation[key], id: location.id, new : false, update: false}})
                      }
                    })
                    }
                  }))   
                  let locationsN = await Promise.all(newLocation.map(async ([key, x], i) => {     
                    await addLocation({
                      variables: {
                          view: area.view,
                          area: area.id,
                          location: locationDetails,
                          readings: locationReadings,
                          condition: locationCondition,
                          coordinates: JSON.stringify({[key]: {...finalLocation[key], new : false}}),
                          label: locationModalLabel
                      }
                  })}))

                  refetchArea()                  
                })

              })
              .send((err) => {
                  if (err) console.log(err)
              }) 
                       
        }
          
        } 
        const updateLocationFunc = (locaiton) => {
            console.log(location)
        }
        
      const [ more, setMore ] = useState(false)
   
      const [resultsActive, setResultsActive] = useState(false)
   
      useEffect(() => {
        if(area && area.results && area.results.length > 0) {
          let chartResults = area.results.map(result => {
            if(result?.results?.length) {
            let chart = {}
            chart.name = result.locationCode
            chart.result= result.result
            chart.areaCondition = result.areaCondition
           chart.sample1 = result?.results[2]?.result
           chart.sample2 = result?.results[1]?.result
           chart.sample3 = result?.results[0]?.result  
            return chart
            }
          })

          setChartData(chartResults)
        }
      },[area])

      const [chartData, setChartData] = useState(null)

      const getConst = (coName, con) => {
        let co = !con ? coName.slice(0, coName.indexOf(":")) : coName
        if(co == "Interior, Dry") {
          return 0.42
        } else if(co == "Interior, Wet") { 
          return 0.42
        } else if(co == "Interior, Wet Plus") {
          return 0.50
        } else if(co == "Exterior, Wet") {
          return 0.55
        } else if(co == "Oils/Greases") {
          return 0.55
        }
      }

      const CustomizedDot = (props) => {
        const { cx, cy, stroke, payload, value } = props;     
        if (value >= getConst(area?.testingCategory) && payload.sample1 >= getConst(area?.testingCategory) && payload.sample2 >= getConst(area?.testingCategory) && payload.sample3 >= getConst(area?.testingCategory)) {
          return (
            <g>
            <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="#6DB240" viewBox="0 0 1024 1024">
              <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-7" />
            </svg>
            <text x={cx} y={cy+ 25} dy={-4} fill={"black"} fontSize={14} textAnchor="middle">
              {payload.name}
            </text>
            </g>
          );
        }
      
        return (
          <g>
          <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="#EF1111" viewBox="0 0 1024 1024">
            <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.35" />          
        </svg>
        <text x={cx} y={cy+ 25} dy={-4} fill={"black"} fontSize={14} textAnchor="middle">
              {payload.name}
            </text>
        </g>
          );
      };
      

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          let dia = payload[0].payload
          return (
            <div className={`tooltipContainer  ${
              dia.areaCondition == "Prevailing/wet" ? "pwBorder" :
              dia.areaCondition == "Prevailing/dry" ? "pdBorder" :
              dia.areaCondition == "Clean/wet" ? "cwBorder" :
              dia.areaCondition == "Clean/dry" ? "cdBorder" : null 
           }`}>
            <div className={`areaConditionToolTip ${
               dia.areaCondition == "Prevailing/wet" ? "pwText" :
               dia.areaCondition == "Prevailing/dry" ? "pdText" :
               dia.areaCondition == "Clean/wet" ? "cwText" :
               dia.areaCondition == "Clean/dry" ? "cdText" : null 
            }`}>{ dia?.areaCondition?.slice(0, dia?.areaCondition?.indexOf("/")+1) + dia?.areaCondition[dia?.areaCondition?.indexOf("/")+1]?.toUpperCase() + dia?.areaCondition?.slice(dia?.areaCondition?.indexOf("/") +2)}</div>
            <div className="custom-tooltip">
              <p className="intro" style={{color: dia.sample1 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240"}}>{`Sample 1 : ${dia.sample1}`}</p>
              <p className="intro" style={{color: dia.sample2 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240"}}>{`Sample 2 : ${dia.sample2}`}</p>
              <p className="intro" style={{color: dia.sample3 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240"}}>{`Sample 3 : ${dia.sample3}`}</p>
            </div>
            </div> 
          );
        }
      
        return null;
      }
      
      const [resultTest, setResultTest] = useState(null)
      const [showResult, setShowResult] = useState(null)

      const MENU_ID_RESULT = "menu-id-result";
  
      const { show: showResults } = useContextMenu({
        id: MENU_ID_RESULT
      });

      const displayLocationsResults = (e, tests) => {
        if(tests.length == 2) {
          let activeTest1 = tests[0] 
          let activeTest2 = tests[1]
          if(activeTest1?.results?.length && activeTest2?.results?.length ) {
          let newTest1 = {}
               newTest1.name = activeTest1?.locationCode
               newTest1.result = activeTest1?.result
            newTest1.mistake = activeTest1.mistake
               newTest1.extra = activeTest1?.extra
               newTest1.date = activeTest1.results[0]?.date
               newTest1.areaCondition = activeTest1.areaCondition 
               newTest1.sample1 = activeTest1?.results[2]?.result
               newTest1.sample2 = activeTest1?.results[1]?.result
               newTest1.sample3 = activeTest1?.results[0]?.result  
            
          let newTest2 = {}
          newTest2.name = activeTest2?.locationCode
          newTest2.result = activeTest2?.result 
          newTest2.mistake = activeTest2.mistake
          newTest2.extra = activeTest2?.extra
          newTest2.date = activeTest2.results[0]?.date
          newTest2.areaCondition = activeTest2.areaCondition
          newTest2.sample1 = activeTest2?.results[2]?.result
          newTest2.sample2 = activeTest2?.results[1]?.result
          newTest2.sample3 = activeTest2?.results[0]?.result  
          setShowResult(e)
          setResultTest([newTest1, newTest2])
          // showResults(e, {
          //   position: {
          //     x: e.pageX,
          //     y: e.pageY,
          //   },
          // })
        }
        } else if(tests.length == 1) {
          let activeTest = tests[0]
          if(activeTest?.results?.length) {
            let newTest = {}
               newTest.extra = activeTest?.extra
               newTest.mistake = activeTest.mistake
               newTest.name = activeTest?.locationCode
               newTest.result = activeTest?.result                 
               newTest.date = activeTest.results[0]?.date
               newTest.areaCondition = activeTest?.areaCondition
               newTest.sample1 = activeTest?.results[2]?.result
               newTest.sample2 = activeTest?.results[1]?.result
               newTest.sample3 = activeTest?.results[0]?.result  
               setShowResult(e)
               setResultTest([newTest])
          }
          
        }
    }

    useEffect(() => {
      if(resultTest && showResult) {
        showResults(showResult, {
          position: {
            x: showResult.pageX,
            y: showResult.pageY,
          },
        })
      }
    },[resultTest, showResult])

    if(hoverMode) {
      return (
        <div className="typeContent" style={{position:"relative", width:"350px", height:"350px"}}>

        <> <img width={"350px"} height={"350px"} src={`${area.diagramImage}`}/>
        {area.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).map((shape, i) => {
        return(
          <div key={i}>
          <div 
          onClick={ area.results?.some(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id ) ? (e) => displayLocationsResults(e, area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )) : null}
          className={`${
            area.results?.some(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )
                  ? area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id ).length == 2
                  ?  ((area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.some(x=>x.passed) && area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.some(x => !x.passed))) 
                  ? "differentLocations clickable" 
                  : (area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x => !x.passed))
                  ? "faildLocation clickable" : 
                  (area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=> x.passed)) ?
                  "passLocation clickable" : "notAny"
                  : area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=>!x.passed)
                  ? "faildLocation clickable" 
                  : area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=>x.passed)
                  ? "passLocation clickable"
                  : "" : ""                                   
          }`} 
          style={{background:shape[Object.keys(shape)[0]]?.id !== locationId ? "white":"", position:"absolute", width:`${shape[Object.keys(shape)].radius * 2.5 +5}px`, borderRadius:"50%", height:`${shape[Object.keys(shape)].radius * 2.5+5}px`,  top:shape[Object.keys(shape)].y - shape[Object.keys(shape)].radius - 3, left:shape[Object.keys(shape)].x - shape[Object.keys(shape)].radius - 3}}>
            {
              area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id ).length == 2 ? area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=>!x.passed) || area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=>x.passed) ? <div style={{justifyContent:"center", display:"flex", alignItems:"center", color:"white", fontWeight:"bold"}}>2</div> : null : null
            }
        </div>
        {shape[Object.keys(shape)[0]]?.id == locationId ? <span className='locationTestId' style={{ top:shape[Object.keys(shape)].y + shape[Object.keys(shape)].radius +5, left:shape[Object.keys(shape)].x, position:"absolute", zIndex:"99999", fontWeight:"bold", color:"black", textShadow:"1px 1px 2px #fff", fontSize:"50px"}}>{Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length)}</span> : null}
        </div>
        )})} 
         {<Menu style={{display:"flex"}} onHidden={() => {
          setResultsActive(false)
          setResultTest(null)
          setShowResult(null)
         }} id={MENU_ID_RESULT}>
          {resultTest && resultTest[0] && <Item style={{cursor:"auto"}}>
          <div className={`tooltipContainer  ${
          resultTest[0].areaCondition == "Prevailing/wet" ? "pwBorder" :
          resultTest[0].areaCondition == "Prevailing/dry" ? "pdBorder" :
          resultTest[0].areaCondition == "Clean/wet" ? "cwBorder" :
          resultTest[0].areaCondition == "Clean/dry" ? "cdBorder" : null 
      }`}>
        <div className={`areaConditionToolTip ${
          resultTest[0].areaCondition == "Prevailing/wet" ? "pwText" :
          resultTest[0].areaCondition == "Prevailing/dry" ? "pdText" :
          resultTest[0].areaCondition == "Clean/wet" ? "cwText" : 
          resultTest[0].areaCondition == "Clean/dry" ? "cdText" : null  
        }`}>
        <div style={{fontSize:"1rem"}}>
        {resultTest[0].mistake ? 
      <div style={{color:"#EF1111",width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
      A{resultTest[0].mistake?.area} L{resultTest[0].mistake?.location} 
      </div>
      : null}
          {resultTest[0].areaCondition.slice(0, resultTest[0].areaCondition.indexOf("/")+1) + resultTest[0].areaCondition[resultTest[0].areaCondition.indexOf("/")+1]?.toUpperCase() + resultTest[0].areaCondition.slice(resultTest[0].areaCondition.indexOf("/")+2)}</div>
        {resultTest[0].date && <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"gray", display:"inline-block", fontSize:"1rem", fontWeight:"bold"}}></span> {moment(resultTest[0].date).format("MM/DD/YYYY")} </div>}
        </div> 
        <div className="custom-tooltip">
          <p className="intro" style={{color: resultTest[0]?.extra ? "#B9B8BC" : resultTest[0].sample1 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 1 : ${resultTest[0].sample1}`}</p>
          <p className="intro" style={{color: resultTest[0]?.extra ? "#B9B8BC" : resultTest[0].sample2 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 2 : ${resultTest[0].sample2}`}</p>
          <p className="intro" style={{color: resultTest[0]?.extra ? "#B9B8BC" : resultTest[0].sample3 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 3 : ${resultTest[0].sample3}`}</p>
        </div> 
        </div>
          </Item>}
          {resultTest && resultTest[1] && <Item style={{cursor:"auto"}}>
          <div className={`tooltipContainer  ${
          resultTest[1].areaCondition == "Prevailing/wet" ? "pwBorder" :
          resultTest[1].areaCondition == "Prevailing/dry" ? "pdBorder" :
          resultTest[1].areaCondition == "Clean/wet" ? "cwBorder" :
          resultTest[1].areaCondition == "Clean/dry" ? "cdBorder" : null 
      }`}>
        <div className={`areaConditionToolTip ${
          resultTest[1].areaCondition == "Prevailing/wet" ? "pwText" :
          resultTest[1].areaCondition == "Prevailing/dry" ? "pdText" :
          resultTest[1].areaCondition == "Clean/wet" ? "cwText" :
          resultTest[1].areaCondition == "Clean/dry" ? "cdText" : null 
        }`}>
        <div style={{fontSize:"1rem"}}>
        {resultTest[1].mistake ? 
      <div style={{color:"#EF1111",width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
      A{resultTest[1].mistake?.area} L{resultTest[1].mistake?.location} 
      </div>
      : null}
          {resultTest[1].areaCondition.slice(0, resultTest[1].areaCondition.indexOf("/")+1) + resultTest[1].areaCondition[resultTest[1].areaCondition.indexOf("/")+1]?.toUpperCase() + resultTest[1].areaCondition.slice(resultTest[1].areaCondition.indexOf("/") +2)}</div>
        {resultTest[1].date && <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block", fontSize:"1rem", fontWeight:"bold"}}></span> {moment(resultTest[1].date).format("MM/DD/YYYY")} </div>}

        </div>
        <div className="custom-tooltip">
          <p className="intro" style={{color: resultTest[1]?.extra ? "#B9B8BC" : resultTest[1].sample1 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 1 : ${resultTest[1].sample1}`}</p>
          <p className="intro" style={{color: resultTest[1]?.extra ? "#B9B8BC" : resultTest[1].sample2 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 2 : ${resultTest[1].sample2}`}</p>
          <p className="intro" style={{color: resultTest[1]?.extra ? "#B9B8BC" : resultTest[1].sample3 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 3 : ${resultTest[1].sample3}`}</p>
        </div>
        </div>
          </Item> }
        </Menu>}
        
        </>
        </div>)
    }
    return(
    <div  className="reportsContainer areaContainer">
        <VariablesModal 
            variablesModal={variablesModal}
            setVariablesModal={setVariablesModal}
            defaultDCOF={defaultDCOF}
        /> 
        
        <AddLocationModal
        addLocationToDeleted={addLocationToDeleted}
        photo={photo}
        setPhoto={setPhoto}
        deleteLocationFunc={deleteLocationFunc}
        shapeToDelete={shapeToDelete}
        selectShapeToDelete={selectShapeToDelete}
        setNewDiagramImage={setNewDiagramImage}
        locationModal={locationModal}
        setLocationModal={setLocationModal}
        locationCondition={locationCondition}
        setLocationCondition={setLocationCondition}
        locationReadings={locationReadings}
        setLocationReadings={setLocationReadings}
        locationDetails={locationDetails}
        setLocationDetails={setLocationDetails}
        addLocationFunc={addLocationFunc}
        updateLocationFunc={updateLocationFunc}
        setLocationModalPage={setLocationModalPage}
        locationModalPage={locationModalPage}
        areaLines={areaLines}
        setAreaLines={setAreaLines}
        areaDiagram={areaDiagram}
        lines={lines}
        setLines={setLines}
        setAreaDiagram={setAreaDiagram}
        locationCoordinates={locationCoordinates}
        setLocationCoordinates={setLocationCoordinates}
        locationModalLabel={locationModalLabel}
        setLocationModalLabel={setLocationModalLabel}
        />
            <div style={{marginBottom:"2.5rem"}} className="areaUpperContainer responsiveArea">
              <div className="areaCard testingDiagramCard" style={{height:"100%", width:"fit-content"}}>
                            <div className="areaCardType">
                                <div className="typeHeader testingDiagramHeader" style={{justifyContent:"space-between", display:"flex"}}><span>testing diagram</span> 
                                {/* {area.results && (
                                <span style={{color:!resultsActive ? "#6DB240" : "blue", cursor:"pointer"}} onClick={() => setResultsActive(!resultsActive)}>{resultsActive ? "Diagram" : "results"}</span>
                                )} */}
                                </div>
                                <div className="typeContent" style={{position:"relative", width:"350px", height:"350px"}}>
                                    {resultsActive ? (
                                      chartData ? 
                                       <LineChart width={350} height={350} data={chartData}
                                       margin={{ top: 30, right: 30, left: -10, bottom: 5 }}>
                                       <CartesianGrid strokeDasharray="3 3" />
                                       <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                                       <YAxis ticks={[0.20, 0.42, 0.60]}  />
                                       <Tooltip                                        
                                         content={<CustomTooltip />}
                                       />
                                       <Legend />
                                       <ReferenceLine y={0.42} label="Max" stroke="#EF1111" />
                                       <Line type="monotone" dataKey="result" stroke="#8884d8" dot={<CustomizedDot />} />
                                     </LineChart> : <DataSpinner />
                                    ) : (
                                    <> <img width={"350px"} height={"350px"} src={`${area.diagramImage}`}/>
                                    {area.locationsCoordinates.filter(x => Object.keys(x)[0].includes("location")).map((shape, i) => {
                                    return(
                                      <div key={i}>
                                      <div 
                                      onClick={ area.results?.some(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id ) ? (e) => displayLocationsResults(e, area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )) : null}
                                      className={`${
                                        area.results?.some(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )
                                              ? area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id ).length == 2
                                              ?  ((area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.some(x=>x.passed) && area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.some(x => !x.passed))) 
                                              ? "differentLocations clickable" 
                                              : (area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x => !x.passed))
                                              ? "faildLocation clickable" : 
                                              (area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=> x.passed)) ?
                                              "passLocation clickable" : "notAny"
                                              : area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=>!x.passed)
                                              ? "faildLocation clickable" 
                                              : area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=>x.passed)
                                              ? "passLocation clickable"
                                              : "" : ""                                   
                                      }`} 
                                      style={{ position:"absolute", width:`${shape[Object.keys(shape)].radius * 2.5 +5}px`, borderRadius:"50%", height:`${shape[Object.keys(shape)].radius * 2.5+5}px`,  top:shape[Object.keys(shape)].y - shape[Object.keys(shape)].radius - 3, left:shape[Object.keys(shape)].x - shape[Object.keys(shape)].radius - 3}}>
                                        {
                                          area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id ).length == 2 ? area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=>!x.passed) || area.results?.filter(x => x.locationCode == Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length) && x.areaId == area.id && x.locationId ==  shape[Object.keys(shape)[0]]?.id )?.every(x=>x.passed) ? <div style={{justifyContent:"center", display:"flex", alignItems:"center", color:"white", fontWeight:"bold"}}>2</div> : null : null
                                        }
                                    </div>
                                    <span className='locationTestId' style={{ top:shape[Object.keys(shape)].y + shape[Object.keys(shape)].radius +5, left:shape[Object.keys(shape)].x, position:"absolute", fontWeight:"bold", color:"black", textShadow:"1px 1px 2px #fff"}}>{Object.keys(shape)[0].substring(11, Object.keys(shape)[0].length)}</span>
                                    </div>
                                    )})} 
                                     {<Menu style={{display:"flex"}} onHidden={() => {
                                      setResultsActive(false)
                                      setResultTest(null)
                                      setShowResult(null)
                                     }} id={MENU_ID_RESULT}>
                                      {resultTest && resultTest[0] && <Item style={{cursor:"auto"}}>
                                      <div className={`tooltipContainer  ${
                                      resultTest[0].areaCondition == "Prevailing/wet" ? "pwBorder" :
                                      resultTest[0].areaCondition == "Prevailing/dry" ? "pdBorder" :
                                      resultTest[0].areaCondition == "Clean/wet" ? "cwBorder" :
                                      resultTest[0].areaCondition == "Clean/dry" ? "cdBorder" : null 
                                  }`}>
                                    <div className={`areaConditionToolTip ${
                                      resultTest[0].areaCondition == "Prevailing/wet" ? "pwText" :
                                      resultTest[0].areaCondition == "Prevailing/dry" ? "pdText" :
                                      resultTest[0].areaCondition == "Clean/wet" ? "cwText" : 
                                      resultTest[0].areaCondition == "Clean/dry" ? "cdText" : null  
                                    }`}>
                                    <div style={{fontSize:"1rem"}}>
                                    {resultTest[0].mistake ? 
                                  <div style={{color:"#EF1111",width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  A{resultTest[0].mistake?.area} L{resultTest[0].mistake?.location} 
                                  </div>
                                  : null}
                                      {resultTest[0].areaCondition.slice(0, resultTest[0].areaCondition.indexOf("/")+1) + resultTest[0].areaCondition[resultTest[0].areaCondition.indexOf("/")+1]?.toUpperCase() + resultTest[0].areaCondition.slice(resultTest[0].areaCondition.indexOf("/")+2)}</div>
                                    {resultTest[0].date && <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"gray", display:"inline-block", fontSize:"1rem", fontWeight:"bold"}}></span> {moment(resultTest[0].date).format("MM/DD/YYYY")} </div>}
                                    </div> 
                                    <div className="custom-tooltip">
                                      <p className="intro" style={{color: resultTest[0]?.extra ? "#B9B8BC" : resultTest[0].sample1 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 1 : ${resultTest[0].sample1}`}</p>
                                      <p className="intro" style={{color: resultTest[0]?.extra ? "#B9B8BC" : resultTest[0].sample2 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 2 : ${resultTest[0].sample2}`}</p>
                                      <p className="intro" style={{color: resultTest[0]?.extra ? "#B9B8BC" : resultTest[0].sample3 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240", fontSize:"1rem",fontWeight:"bold"}}>{`Sample 3 : ${resultTest[0].sample3}`}</p>
                                    </div> 
                                    </div>
                                      </Item>}
                                      {resultTest && resultTest[1] && <Item style={{cursor:"auto"}}>
                                      <div className={`tooltipContainer  ${
                                      resultTest[1].areaCondition == "Prevailing/wet" ? "pwBorder" :
                                      resultTest[1].areaCondition == "Prevailing/dry" ? "pdBorder" :
                                      resultTest[1].areaCondition == "Clean/wet" ? "cwBorder" :
                                      resultTest[1].areaCondition == "Clean/dry" ? "cdBorder" : null 
                                  }`}>
                                    <div className={`areaConditionToolTip ${
                                      resultTest[1].areaCondition == "Prevailing/wet" ? "pwText" :
                                      resultTest[1].areaCondition == "Prevailing/dry" ? "pdText" :
                                      resultTest[1].areaCondition == "Clean/wet" ? "cwText" :
                                      resultTest[1].areaCondition == "Clean/dry" ? "cdText" : null 
                                    }`}>
                                    <div style={{fontSize:"1rem"}}>
                                    {resultTest[1].mistake ? 
                                  <div style={{color:"#EF1111",width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  A{resultTest[1].mistake?.area} L{resultTest[1].mistake?.location} 
                                  </div>
                                  : null}
                                      {resultTest[1].areaCondition.slice(0, resultTest[1].areaCondition.indexOf("/")+1) + resultTest[1].areaCondition[resultTest[1].areaCondition.indexOf("/")+1]?.toUpperCase() + resultTest[1].areaCondition.slice(resultTest[1].areaCondition.indexOf("/") +2)}</div>
                                    {resultTest[1].date && <div className='squareContent' style={{fontSize:"1vmax", margin:"0 5px"}}><span style={{color:"#6DB240", display:"inline-block", fontSize:"1rem", fontWeight:"bold"}}></span> {moment(resultTest[1].date).format("MM/DD/YYYY")} </div>}

                                    </div>
                                    <div className="custom-tooltip">
                                      <p className="intro" style={{color: resultTest[1]?.extra ? "#B9B8BC" : resultTest[1].sample1 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 1 : ${resultTest[1].sample1}`}</p>
                                      <p className="intro" style={{color: resultTest[1]?.extra ? "#B9B8BC" : resultTest[1].sample2 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 2 : ${resultTest[1].sample2}`}</p>
                                      <p className="intro" style={{color: resultTest[1]?.extra ? "#B9B8BC" : resultTest[1].sample3 < getConst(area?.testingCategory) ? "#EF1111" : "#6DB240",fontSize:"1rem",fontWeight:"bold"}}>{`Sample 3 : ${resultTest[1].sample3}`}</p>
                                    </div>
                                    </div>
                                      </Item> }
                                    </Menu>}
                                    
                                    </>)}
                                </div> 
                            </div>
                            <div className="diagramDetails">
                                <div className="diagramDetailsContainer"> 
                            <div className="areaCardType">
                                <div className="typeHeader">date</div>
                                <div className="typeContent">{area.createdAt ? area.createdAt : "unknown"}</div>
                            </div>
                            <div className="areaCardType">
                                <div className="typeHeader">created by</div>
                                <div className="typeContent">{area.createdBy ? area.createdBy : "unknown"}</div>
                            </div>
                            </div>
                            </div>
                    </div>
                    <div className="rightCards" style={{height:"100%"}}>                      
                    <div className="rightLower">
                        <div className="areaUpperContainer">
                            <div className="areaCard" style={{justifyContent:"space-between"}}>
                                <div className="areaCardType">
                                    <div className="typeHeader">Floor Type</div>
                                    <div className="typeContent">{area.floorType}</div>
                                </div>
                                {/* <div className="areaCardType">
                                    <div className="typeHeader">Testing Condition</div>
                                    <div className="typeContent">{area.testingCondition ? area.testingCondition : "any"}</div>
                                </div> */}
                                <div className="areaCardType">
                                    <div className="typeHeader">Testing Category</div>
                                    <div className="typeContent">{area.testingCategory ? area.testingCategory : "any"}</div>
                                </div>
                                { area.floorTypeDescription && <div className="areaCardType">
                                    <div className="typeHeader">Floor Type Description</div>
                                    <div className="typeContent">{area.floorTypeDescription}</div>
                            </div> }
                    <div className="areaCardType">
                                <div className="typeHeader">floor finish</div>
                                <div className="typeContent">{area.floorFinish}</div>
                            </div>
                           { area.floorFinishDescription &&  <div className="areaCardType">
                                <div className="typeHeader">floor finish Description</div>
                                <div className="typeContent">{area.floorFinishDescription}</div>
                            </div> }
                            {area?.floorTexture && <div className="areaCardType">
                        <div className="typeHeader">Floor Texture</div>
                        <div className="typeContent">{area.floorTexture}</div>
                    </div>}
                            <div className="areaCardType">
                        <div className="typeHeader">Floor Condition</div>
                        <div className="typeContent">{area.conditionofFloorFinish ? area.conditionofFloorFinish : "N/A"}</div>
                    </div>
                    <div className="areaCardType">
                        <div className="typeHeader">Slope Surface</div> 
                        <div className="typeContent">{area.slopeofFloorsSurface}</div>
                    </div>
                        </div> 
                        <div className="areaCard" style={{overflow:"auto", maxHeight:"558px"}}>
                        <div className="areaCardType areaNotes">
                        <div className="typeHeader">notes</div>
                        <div className="typeContent">{area.auditorsNotes ? area.auditorsNotes : "No notes"}</div>
                    </div>
                    <div className="areaCardType areaImages">
                        <div className="typeHeader">photos</div>
                        {<div className="typeContent">{area.images && area.images.length > 0 ? area.images.map((image, i) => (
                            <div  key={i} style={{margin:"5px", position:"relative"}} className={`${i == 0 ? "primaryPhoto" : "nonPri"}`}>
                            {i == 0 && <div style={{position:"absolute", background:"rgb(251 244 244 / 80%)", color:"gray", textTransform:"capitalize", width:"100%", height:'fit-content', display:"flex", alignItems:"center", justifyContent:"center", top:-1}}>area photo</div>}
                            <img
                            className="areaImage"
                            src={image}
                            alt="areaImage"
                          />
                          </div>
                        )) : null}
                        <div style={{margin:"5px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <img
                            className="areaImage"
                            src={plus}
                            alt="areaImage"
                            onClick={() => editAreaImages(area)}
                            style={{cursor:"pointer"}}
                          />
                          </div>
                        </div>}
                    </div>
                        </div>
                        </div>

                    </div>
                {!auditHistoryLoading ? auditHistory?.length ? <div className="areaMiddleContainer" style={{margin:"0"}}>
                    <div className={`${more ? "auditExpanded auditReports": "auditReports"}`} style={{padding:"16px 5px"}}>
                <div style={{display:"flex", justifyContent:"space-between", cursor:"pointer", padding:"5px 20px", borderRadius:"8px"}}
                onClick={() => {
                  setMore(!more)                       
                }}  className="userSquareHeader hover">
                    <div style={{
                      display:"flex",
                      justifyContent:"space-between",
                      alignItems:"center",                      
                      width:"97%"
                    }}
                    className="areaBar"
                    >
                    <span className="typeHeader">Audit changes</span>
                    <span style={{ width:"20px", height:"20px", borderRadius:"50%", display:"flex", justifyContent:"center", alignItems:"center", color:"white", background:"#EF1111"}}>{auditHistory.filter(x => x.user)?.length}</span>
                    </div> 
                    <FontAwesomeIcon className={`${more ? "collapseArrow collapseActive" : "collapseArrow"}`} onClick={() => {
                        setMore(!more)                       
                      }}  icon={faChevronDown}/> 
                </div>
                <div className={`${more ? "areaContainerOn" : "areaContainerOff"}`}>
                    <div className="rightUpper" style={{flexDirection:"column"}}>
                            {auditHistory.filter(x => x.user).map((x, i) => (
                              <div key={i} style={{justifyContent:"flex-start", flexDirection:"row", width:"99%", height:"fit-content"}} className="areaCard">
                                <div className="areaCardType">
                                    <div className="typeContent"><span style={{fontWeight:"bold"}}>{`${x.user == user?.user?.name ? "You" : x.user}`}&nbsp;</span> had {`changed the Area ${x.update}` || "an Update"} {x.createdAt ? `at ${moment.unix(x.createdAt/1000).format("MM/DD/YYYY HH:mm:ss")}` : "in a while" }</div>
                                </div>                  
                              </div>
                            ))} 
                            {/* <div style={{justifyContent:"flex-start"}} className="areaCard">
                                    <div className="userSquareHeader">audit trail on changes</div>
                                    <div className="areaCardType">
                                        <div className="typeHeader">title</div>
                                        <div className="typeContent">text</div>
                                    </div>                            

                            </div> */}
                        </div>
                </div>
            </div>
                    </div> : null : <DataSpinner /> }                                 
                    </div>
                    </div>
        <div className="areaLocationsContainer">
        <div style={{width:"100%"}} className="headerTitle">locations</div>
        <div style={{width:"100%"}} className="locationsTable">
        <DataTable
                columns={user ? user?.user?.role == "user" ? locationsColumns : locationsColumns.filter(x => x.name !== "Previous test data") :null}
                data={locationsData ? locationsData : []}
                progressPending={locations == null}
                progressComponent={RenderLocaionsLoader({small: "l"})}
                conditionalRowStyles={conditionalRowStyles}
        />
        </div>
        <div style={{width:"100%"}} className="locationsAddButton">
        <div style={{width:"150px"}} className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          marginRight: "1rem",
                          fontSize:"14",
                          fontWeight:"bold",
                      }} 
                      className="auth-button addUser userActionsButtons"
                      onClick={() => {
                          setAreaDiagram(area.diagramImage)
                          setPhoto(area.diagramPhoto || null)
                          // let testingDiagram = JSON.parse(area.testingDiagram)


        let testingDiagram = area.testingDiagram ? JSON.parse(area.testingDiagram) : {}
        let shapes = testingDiagram.shapes ? Object.entries(testingDiagram.shapes) : null
        let coor = area.locationsCoordinates ? JSON.stringify(area.locationsCoordinates) : null
        let newShapes = coor && shapes ? shapes.filter(([key, x]) => coor.includes(key) || !key.includes("location")) : null
        let lastShapes = newShapes?.map(([key, x, a]) => {
          let selectedLocation = area.results?.filter(y => key.includes(y.locationCode)) 
          x.passed = selectedLocation?.length ? [...selectedLocation?.map(x => x.passed ? "#6DB240" : "#EF1111")] : null
          return [key, x]
        })
        let convertedShapes = lastShapes ? Object.fromEntries(lastShapes) : null
        let newTestingDiagram = convertedShapes ? {...testingDiagram, shapes: convertedShapes} : testingDiagram
        setAreaLines(newTestingDiagram)
                          setLocationModal("Locations")
                          setLocationModalLabel(() => randomColor())
                        }}
                      >
                            Edit Locations
                        </div>
              </div>
        </div>

        </div>
        </div>

    )
}

export default AuditArea