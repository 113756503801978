import { faInfoCircle, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef, useState } from "react" 
import info from "../../../../assests/icons/info.svg"
import InfoItem from "../../../resuable/info"
import edit from "../../../../assests/icons/edit.svg"
import { DataSpinner } from "../../../resuable/spinner/dataSpinner"
import { useHistory } from "react-router-dom"

const AuditCard = ({auditFixer, setAuditFixer, audit, subScreen, updateProjectStatusFunc, editAudit, user, hoverCard}) => {

    const history = useHistory()

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupRef, setShowPopupRef] = useState(false);
    const popupRefAudit = useRef(null);
    const fixRef = useRef(null);
    
    const handleMouseEnter = () => {
        if(audit.auditStatus == "Finished - Approved" || audit.auditStatus == "Finished - Rejected" || audit.auditStatus == "Pending Approval" ) {
            setShowPopup(true);
            setShowPopupRef(false)
        }

    };
    
    const handleMouseMove = (e) => {
        if(audit.auditStatus == "Finished - Approved" || audit.auditStatus == "Finished - Rejected" || audit.auditStatus == "Pending Approval" ) {
            if (popupRefAudit.current) {
                popupRefAudit.current.style.left = e.pageX +10+ 'px';
                popupRefAudit.current.style.top = e.pageY +10+ 'px';
                }
        }
       
      };
    
    const handleMouseLeave = () => {
        if(audit.auditStatus == "Finished - Approved" || audit.auditStatus == "Finished - Rejected" || audit.auditStatus == "Pending Approval" ) {
            setShowPopup(false);
            setShowPopupRef(true)
        }
    };




    const handleMouseEnterRef = () => {
        setShowPopupRef(true)

    };
    
    const handleMouseMoveRef = (e) => {
            if (fixRef.current) {
                fixRef.current.style.left = e.pageX +10+ 'px';
                fixRef.current.style.top = e.pageY +10+ 'px';
                }
       
      };
    
    const handleMouseLeaveRef = () => {
            setShowPopupRef(false);
    };


    const fixReport = (e) => {
        e.stopPropagation()

        setAuditFixer(audit)
        // subScreen(audit, null, null, null, null, null, null, true)

    }

    const goToReports = (e,id, state) => {

        e.stopPropagation()
    
        if(audit.auditStatus == "Finished - Approved" || audit.auditStatus == "Finished - Rejected" || audit.auditStatus == "Pending Approval" ) {
        history.push({
          pathname:"/admin/reports",
          state:{
            view:{id, state},
          }
        })
       
    }
      }


    return (
        <>

        {showPopup ? (
            <div className="popupReport" ref={popupRefAudit}>
            View report
            </div>
        ) : null}


        {/* { showPopupRef ? (
            <div className="popupReport" ref={fixRef}>
                double click to fix it
            </div>
        ) : null} */}

        {audit ? <div 
            

        style={{opacity:audit.status ? 1 : .4, position:"relative", padding:"0px", paddingTop:"30px"}}  
        onClick={() => hoverCard ? subScreen() : subScreen(audit)} 
        className="auditCard"
        >
           {user?.user?.role == "admin" ?  <div onClick={fixReport} className="fixaudit" style={{border:"2px solid green", borderRadius:"10px", padding:"0px 5px"}}>Fix Audit</div> : null}

            <div style={{height:"270px", paddingBottom:"40px", width:"100%", display:"flex", justifyContent:"space-between", flexDirection:"column", alignItems:"flex-start"}}>
            <div className="auditCardHeader" style={{padding: "0px 16px", height:"fit-content"}}>
                <div className="cardHeaderInfo">
                    <div className="carClientInfo">
                        <div className="clientInfoName" style={{textAlign:"left"}}><span style={{color:"grey"}}>Client </span>{audit.name}</div>
                        <div className="clientInfoAddress" style={{textAlign:"left"}}><span style={{color:"grey"}}>Site </span>{audit.address}</div>
                    </div>
                    {
                    <div className="audit-status" style={{right: "auto", background:"transparent", position:"absolute", top:10, left:0}}>
                    <div className="auditCountNumber" style={{fontWeight:"900"}}>
                        {audit.views} {audit?.views > 1 ? "Views" : "View"}
                    </div>
                    </div>
                    }

                    {audit.auditStatus ? <div
                    onMouseEnter={handleMouseEnter}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    className="audit-status cardStatus" 
                    onClick={(e) => goToReports(e,audit.defaultView, audit.auditStatus)}
                    style={{ margin:0, borderRadius:"8px", left: "auto", backgroundColor: audit.auditStatus == "Scheduled" ? "#D89E42" : audit.auditStatus == "In progress" ? "#D4D243" : audit.auditStatus == "Finished - Approved" ? "#6DB240" : audit.auditStatus == "Finished - Rejected" ? "#EF1111" : audit.auditStatus == "Pending Approval" ? "#5C92BF" : "", width:"fit-content",  position:"absolute", top:0, right:0}}>
                        <span>{audit.auditStatus}</span>
                    </div> : null}

                    <div></div>
                </div>                
            </div>
            <div className="auditCardContent" style={{padding: "10px 16px"}}>
                <div className="auditInformation">
                    <div className="typeHeaderAudit" style={{textAlign:"left"}}>created by</div>
                    <div className="typeContentAudit" style={{textAlign:"left"}}>{audit.createdBy} <span style={{fontWeight:"normal"}}>({audit.createdByRole})</span></div>
                </div>
                {/* <div className="auditInformation">
                    <div className="typeHeaderAudit">created on</div>
                    <div className="typeContentAudit">{audit.createdOn}</div>
                </div> */}
                {audit.assignTo ? <div className="auditInformation">
                    <div className="typeHeaderAudit" style={{textAlign:"left"}}>assigned to</div>
                    <div className="typeContentAudit" style={{textAlign:"left"}}>{audit.assignTo} <span style={{fontWeight:"normal"}}>{audit?.assignToId == user?.user?._id ? `(you)` : `(user)`} {audit.assignee?.length > 1 ? <div onClick={(e) => {
                        e.stopPropagation()
                        !hoverCard && editAudit(audit, "assign")
                    }} style={{color:"#5C92BF", cursor:"pointer"}}> {audit.assignee.length - 1} more users</div> : (
                       !hoverCard && <div onClick={(e) => {
                            e.stopPropagation()
                            editAudit(audit, "assign")
                        }} style={{color:"#5C92BF", cursor:"pointer", display:"inline-block"}}> <img src={edit} height={"20"}/></div>
                    ) }</span></div>
                    
                </div> : (
                    <div className="auditInformation hiddenButtonToHover">
                    {!hoverCard && <div className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          marginRight: "1rem",
                          fontSize:"14",
                          fontWeight:"bold",
                          width:"50%"
                      }} 
                      className="auth-button addUser"
                      onClick={(e) => {
                          e.stopPropagation()
                        editAudit(audit, "assign")
                      }}
                      >
                            <span>Assign</span>
                        </div>
                        </div>}
                </div>
                )}

            </div>
            </div>
            <div className="auditCardFooter" style={{padding: "10px 16px", position:"absolute", bottom:0}}>
                <div className="auditCount" style={{width: hoverCard && "100%", justifyContent:"space-between"}}>
                    <div className="auditCountNumber">
                        {audit.areas} Area
                    </div>
                    <div className="auditCountNumber">
                        {audit.locations} Locations
                    </div>
                    <div className="auditCountNumber">{audit.createdOn}</div>
                </div>
                {!hoverCard && <div className="auditStatus">
                <label style={{cursor:"pointer"}} onClick={(e) => {
                    e.stopPropagation()
                    updateProjectStatusFunc(audit)
                }} className="toggle-control">
                    <span className={`${audit.status ? "checked" : "notChecked"}`}></span>
                    <span className="control"></span>
                </label>
                </div>}
            </div>
        </div> : <div style={{opacity:audit?.status ? 1 : .4, position:"relative", padding:"0px"}}  onClick={() => subScreen()} className="auditCard">
            <DataSpinner small="s" color="gray" />
        </div> }       
        </>
    )
} 

export default AuditCard