import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../reportTemplate.scss";
import parse from 'html-react-parser';
import ReactToPrint from 'react-to-print';
import logo from "../../../../assests/images/logo.png"
const ReportTemplatePage1 = ({user}) => {
const location = useLocation()
const history = useHistory()
const [page, setPage] = useState(null)
const response = useRef()

useEffect(() => {
  if(location) {
    if(location?.state?.page) {
      setPage(location.state.page)
    } else {
      history.push("/admin/reports")
    }
  }  
  return () => {
    setPage(null)
    window.history.replaceState({}, document.title)
  }
},[location])

useEffect(() => {
  if(!user) {
    history.push("/admin")
  }
},[user])
 
  return (
    <>
    <div className="adminContent" style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
    <div ref={response} className="printetReportContainer" style={{width:"100%"}}>
    {/* <img alt={""} src={logo} className={"watermark"}/> */}
            <table className="report-container">
                <thead className="report-header">
                <tr>
                    <th className="report-header-cell">
                        <div className="header-info">
                            
                        </div>
                    </th>
                </tr>
                </thead>
                <tfoot className="report-footer">
                <tr>
                    <td className="report-footer-cell">
                        <div className="footer-info">
                            <div className={"page-footer"}>
                            
                            </div>
                        </div>
                    </td>
                </tr>
                </tfoot>
                <tbody className="report-content">
                <tr>
                    <td className="report-content-cell">
                        <div className="main">
    {parse(`${page}`)}
    </div>
                    </td>
                </tr>
                </tbody>
            </table>
           
          
    </div>    
    </div> 
    <div
            className="next_page_button"
          >
            <ReactToPrint
              content={() => response?.current}
              trigger={() => (
                <div className="auth-button addUser secondaryBlue">Download</div>
          )}
        />
      </div>
    </>
  );
};

export default ReportTemplatePage1;
