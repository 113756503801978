import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExtractImages = ({correctAudit,selectedAudit, setAuditFixer, auditFixer, subScreen, audit}) => {
  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
  }, []);
 

  const [structuredImages, setStructuredImages] = useState({});
  const lengthedObject =  Object.keys(structuredImages)?.length

  const firstKey = Object.keys(structuredImages)[0]; // Get the first key
  const newObject = { ...structuredImages[firstKey] };

  async function extractHeaderFromPdf(pdfDocument, maxPages = 2) {
    let headerText = [];

    for (let pageNum = 1; pageNum <= Math.min(maxPages, pdfDocument.numPages); pageNum++) {
      const page = await pdfDocument.getPage(pageNum);
      const textContent = await page.getTextContent();
      const text = textContent.items.map((item) => item.str).join("\n");
      headerText.push(text);
    }

    return headerText.join("\n"); // Return combined header text
  }

  function parseHeaderVariables(headerText) {
    return {
      // **Extract Site Name (Next Line After "Post Audit Evaluation")**
      siteName: headerText.match(/Post Audit Evaluation\s*of\s*\n?(.+)/i)?.[1]?.trim() || "Unknown",
  
      // **Extract Site Street Address (First Line After Site Name)**
      siteStreetAddress: headerText.match(/Post Audit Evaluation\s*of\s*\n?.+\n(.+)/i)?.[1]?.trim() || "Unknown",
  
      // **Extract City, State, ZIP**
      siteCity: headerText.match(/\n([A-Za-z\s]+),\s*([A-Za-z\s]+)\s*(\d{5})/i)?.[1]?.trim() || "Unknown",
      siteState: headerText.match(/\n([A-Za-z\s]+),\s*([A-Za-z\s]+)\s*(\d{5})/i)?.[2]?.trim() || "Unknown",
      siteZipCode: headerText.match(/\n([A-Za-z\s]+),\s*([A-Za-z\s]+)\s*(\d{5})/i)?.[3]?.trim() || "Unknown",
  
      // **Extract Auditor Name(s)**
      auditorNames: headerText.match(/Auditor\(s\):\s*(.+)/i)?.[1]?.trim() || "Unknown",
  
      // **Extract Audit Date(s)**
      auditDates: headerText.match(/Date\(s\) of Audit:\s*(.+)/i)?.[1]?.trim() || "Unknown",
  
      // **Extract Client Company**
      clientCompany: headerText.match(/Audit Report Prepared For:\s*(.+)/i)?.[1]?.trim() || "Unknown",
  
      // **Extract Client Contact Name**
      clientContactName: headerText.match(/Re:\s*Audit Report -\s*(.+)/i)?.[1]?.trim() || "Unknown",
    };
  }
  

  async function extractTablesFromPdf(pdfDocument, startPage, endPage) {
    let extractedTables = [];

    for (let pageNum = startPage; pageNum <= endPage; pageNum++) {
      const page = await pdfDocument.getPage(pageNum);
      const textContent = await page.getTextContent();

      let lines = [];
      let currentLineY = null;
      let currentLine = [];

      for (let item of textContent.items) {
        let y = Math.round(item.transform[5]);

        if (currentLineY === null) {
          currentLineY = y;
        }

        if (Math.abs(y - currentLineY) > 5) {
          lines.push(currentLine.join(" "));
          currentLine = [];
          currentLineY = y;
        }

        currentLine.push(item.str);
      }

      if (currentLine.length > 0) {
        lines.push(currentLine.join(" "));
      }

      let tables = [];
      let currentTable = [];

      for (let line of lines) {
        if (line.includes("Date") && line.includes("Time") && line.includes("Area") && line.includes("Location")) {
          if (currentTable.length > 0) {
            tables.push(currentTable);
          }
          currentTable = [line];
        } else {
          currentTable.push(line);
        }
      }

      if (currentTable.length > 0) {
        tables.push(currentTable);
      }

      extractedTables.push(...tables);
    }

    return extractedTables;
  }

  function parseTableData(textTables) {
    let structuredData = {};
    let flatLines = textTables.flat();

    for (let line of flatLines) {
      const areaMatch = line.match(/(\d{3})\s{3,}(\d{3})/);

      if (areaMatch) {
        let currentArea = `${areaMatch[1]}`;
        let currentLocation = `${areaMatch[2]}`;

        if (!structuredData[currentArea]) {
          structuredData[currentArea] = {};
        }
        if (!structuredData[currentArea][currentLocation]) {
          structuredData[currentArea][currentLocation] = [];
        }
      }
    }

    return structuredData;
  }

  async function extractAppendixCImages(pdfDocument, appendixCStart, pdfEnd) {
    let appendixCImages = [];
  
    for (let pageNum = appendixCStart; pageNum <= pdfEnd; pageNum++) {
      const page = await pdfDocument.getPage(pageNum);
      const operatorList = await page.getOperatorList();
  
      for (let i = 0; i < operatorList.fnArray.length; i++) {
        if (operatorList.fnArray[i] === pdfjsLib.OPS.paintImageXObject) {
          const imgName = operatorList.argsArray[i][0];
          const img = await page.objs.get(imgName);
  
          if (img) {
            // Create a temporary canvas for the original image
            const tempCanvas = document.createElement("canvas");
            const tempCtx = tempCanvas.getContext("2d");
  
            tempCanvas.width = img.width;
            tempCanvas.height = img.height;
  
            const imgDataRGBA = new Uint8ClampedArray(img.width * img.height * 4);
            for (let j = 0, k = 0; j < img.data.length; j += 3, k += 4) {
              imgDataRGBA[k] = img.data[j];
              imgDataRGBA[k + 1] = img.data[j + 1];
              imgDataRGBA[k + 2] = img.data[j + 2];
              imgDataRGBA[k + 3] = 255;
            }
  
            const tempImageData = new ImageData(imgDataRGBA, img.width, img.height);
            tempCtx.putImageData(tempImageData, 0, 0);
  
            // Create a main canvas for the resized image
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
  
            const newWidth = Math.floor(img.width * 0.18);
            const newHeight = Math.floor(img.height * 0.18);
  
            canvas.width = newWidth;
            canvas.height = newHeight;
  
            // Resize the image onto the main canvas
            ctx.drawImage(tempCanvas, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
  
            const imgBase64 = canvas.toDataURL("image/png");
            appendixCImages.push(imgBase64);
          }
        }
      }
    }
    return appendixCImages;
  }

  


  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;
  
    let allFilesData = {};
  
    for (const file of files) {
      const fileName = file.name;
      const reader = new FileReader();
  
      reader.readAsArrayBuffer(file);
      await new Promise((resolve) => {
        reader.onload = async () => {
          const pdfData = new Uint8Array(reader.result);
          const pdfDocument = await pdfjsLib.getDocument({ data: pdfData }).promise;
  
          let tableStart = null;
          let appendixBStart = null;
          let appendixCStart = null; // Renamed for clarity
          let appendixCEnd = null; // Renamed for clarity
          let pdfEnd = pdfDocument.numPages; // Last page of the document
  
          for (let pageNum = 10; pageNum <= pdfDocument.numPages; pageNum++) {
            const page = await pdfDocument.getPage(pageNum);
            const textContent = await page.getTextContent();
            const pageText = textContent.items.map((item) => item.str).join(" ");
  
            if (pageText.includes("Appendix A")) {
              tableStart = pageNum;
            }
            if (pageText.includes("Appendix B")) {
              appendixBStart = pageNum;
            }
            if (pageText.includes("Appendix C")) {
              appendixCStart = pageNum;
            }
            if(pageText.includes("Appendix D")) {
              appendixCEnd = pageNum - 1
            }
          }
  
          if (!tableStart || !appendixBStart || !appendixCStart) {
            console.error(`Could not find Appendix A, B, or C in ${fileName}`);
            resolve();
            return;
          }
  
          let appendixAText = await extractTablesFromPdf(pdfDocument, tableStart, appendixBStart);
          let headerText = await extractHeaderFromPdf(pdfDocument);
          let headerVariables = parseHeaderVariables(headerText);
  
          let structuredData = parseTableData(appendixAText);
  
          let locationOrder = Object.entries(structuredData)
            .flatMap(([area, locations]) => Object.entries(locations).map(([location]) => ({ area, location })));
  
          let imageIndex = 0;
  
          for (let pageNum = appendixBStart; pageNum < appendixCStart; pageNum++) {
            const page = await pdfDocument.getPage(pageNum);
            const operatorList = await page.getOperatorList();
  
            for (let i = 0; i < operatorList.fnArray.length; i++) {
              if (operatorList.fnArray[i] === pdfjsLib.OPS.paintImageXObject) {
                const imgName = operatorList.argsArray[i][0];
                const img = await page.objs.get(imgName);
  
                if (img) {
                  const canvas = document.createElement("canvas");
                  const ctx = canvas.getContext("2d");
  
                  canvas.width = img.width;
                  canvas.height = img.height;
  
                  const imgDataRGBA = new Uint8ClampedArray(img.width * img.height * 4);
                  for (let j = 0, k = 0; j < img.data.length; j += 3, k += 4) {
                    imgDataRGBA[k] = img.data[j];
                    imgDataRGBA[k + 1] = img.data[j + 1];
                    imgDataRGBA[k + 2] = img.data[j + 2];
                    imgDataRGBA[k + 3] = 255;
                  }
  
                  const imageData = new ImageData(imgDataRGBA, img.width, img.height);
                  ctx.putImageData(imageData, 0, 0);
  
                  const imgBase64 = canvas.toDataURL("image/png");
  
                  if (imageIndex < locationOrder.length * 3) {
                    let { area, location } = locationOrder[Math.floor(imageIndex / 3)];
  
                    if (!structuredData[area][location]) {
                      structuredData[area][location] = [];
                    }
  
                    if (structuredData[area][location].length < 3) {
                      structuredData[area][location].push(imgBase64);
                      imageIndex++;
                    }
                  }
                }
              }
            }
          }
  
          // Extract images from Appendix C
          let appendixCImages = await extractAppendixCImages(pdfDocument, appendixCStart, appendixCEnd);
  
          allFilesData[fileName] = {
            structuredData,
            headerVariables,
            images: appendixCImages,
          };
  
          resolve();
        };
      });
    }
  
    setStructuredImages(allFilesData);
  };
  

  return (
    <div  style={{ height: "100%", width: "100%", padding:"30px", display: "flex", justifyContent: lengthedObject ? "flex-start" : "center", alignItems: "center", flexDirection: "column", overflow: "auto" }}>


                   <FontAwesomeIcon
                      className="modal_x"
                      icon={faTimes}
                      onClick={() => {
                        setAuditFixer(false)}}
                    /> 

      <h2>Extract Images from PDFs</h2>

      <input type="file" onChange={handleFileUpload} accept="application/pdf" multiple />

      <div style={{ marginTop: "20px" }}>
        {Object.entries(structuredImages).map(([fileName, fileData], fileIndex) => (
          <div key={fileIndex} style={{ marginBottom: "40px", borderBottom: "2px solid #000", paddingBottom: "20px" }}>
            <h2>{fileName}</h2>

            <div style={{ marginBottom: "30px", padding: "10px", borderBottom: "2px solid #000" }}>
                <h2>Audit Report</h2>
                <p><strong>Site Name:</strong> {fileData.headerVariables.siteName}</p>
                <p><strong>Address:</strong> {fileData.headerVariables.siteStreetAddress}, {fileData.headerVariables.siteCity}, {fileData.headerVariables.siteState} {fileData.headerVariables.siteZipCode}</p>
                <p><strong>Auditor(s):</strong> {fileData.headerVariables.auditorNames}</p>
                <p><strong>Audit Date(s):</strong> {fileData.headerVariables.auditDates}</p>
                <p><strong>Client Company:</strong> {fileData.headerVariables.clientCompany}</p>
                <p><strong>Client Contact:</strong> {fileData.headerVariables.clientContactName}</p>
            </div>

            {Object.entries(fileData.structuredData).map(([areaName, locations], areaIndex) => (
              <div key={areaIndex} style={{ marginBottom: "30px" }}>
                <h3>{areaName}</h3>

                {Object.entries(locations).map(([locationName, imageList], locIndex) => (
                  <div key={locIndex} style={{ marginBottom: "20px" }}>
                    <h4>{locationName}</h4>
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                      {imageList.map((img, imgIndex) =>
                        img ? <img key={imgIndex} src={img} style={{ width: "200px", height: "auto" }} /> : <div key={imgIndex} style={{ width: "200px", height: "150px", background: "#ccc" }} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
            
          </div>
        ))}
      </div>


      <div style={{position:"fixed", width:"100%", height:"33px", padding:"10px", bottom:0, right:0, display:"flex", alignItems:"flex-end", justifyContent:"flex-end", width:"100%", marginTop:".8rem"}}>

      <div
            style={{margin:".5rem"}}
              className={`${ lengthedObject ? "auth-button addUser" : "auth-button addUser disable-button"}`}
              onClick={ lengthedObject ? () => subScreen(auditFixer, null, null, null, null, null, null, true, newObject) : null }
            >
              
          Submit
            </div>
            
            </div>
    </div>
  );
};

export default ExtractImages;
