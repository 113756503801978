import React, { useEffect } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from "react-dropdown";
import { DataSpinner } from '../../spinner/dataSpinner';

const AddTemplateModal = (props) => {
    const {
        addTemplateModal,
        setAddTemplateModal,
        templateName,
        setTemplateName,
        addTemplateFunc,
        updateTemplateFunc
    } = props

  
    
  return(
<Modal
        isOpen={addTemplateModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
       
              
              {addTemplateModal && (
               <div className="modal task-modal">
                <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setAddTemplateModal(null)
                  setTemplateName("")
                }}
              />

          <h1 className="header" style={{width:"100%"}}>{addTemplateModal.includes("Edit") ? addTemplateModal : `Add ${addTemplateModal}`}</h1>
          <div style={{alignItems:"center", height:"100%"}} className="form-group-container task-form" >

            <div className="form-collection">
              <div className="form-label"><span>Template name<span style={{color:"red"}}> *</span></span></div>

              <input
                onChange={(e) => setTemplateName(e.target.value)}
                value={templateName}
                type="text"
                placeholder="Template name"
                className="form-input"
              />
            </div>
            </div>
          <div>
            <div
            
              className={`${templateName? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={addTemplateModal.includes("Edit") ? updateTemplateFunc : addTemplateFunc }
            >
              {addTemplateModal.includes("Edit") ? "Update" : "Add"}
            </div>
          </div>
        </div>)}
      </Modal>
  )}

  export default AddTemplateModal
       