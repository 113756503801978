export const SHAPE_TYPES = {
  RECT: "rect",
  CIRCLE: "circle",
  LOCATION: "location"
};

export const DEFAULTS = {
  VECTOR: {
    WIDTH: 50,
    HEIGHT: 50,
    ROTATION: 0,
  },
  CIRCLE: {
    STROKE: "#000000",
    FILL: "#ffffff",
    RADIUS: 20,
  },
  LOCATION: {
    STROKE: "#5C92BF",
    FILL: "#5C92BF",
    RADIUS: 10,
  },
};

export const LIMITS = {
  VECTOR: {
    MAX: 1000,
    MIN: 10,
  },
  CIRCLE: {
    MAX: 500,
    MIN: 5,
  },
  LOCATION: {
    MAX: 500,
    MIN: 5,
  },
};

export const DRAG_DATA_KEY = "__drag_data_payload__";
