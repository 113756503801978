import { faChevronDown, faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons';
import { useLocation } from "react-router-dom";
import "./tableTabs.scss"
import { useHistory, } from "react-router-dom";
const TableTabs = (props) => {
    const history = useHistory()
    const {
        accountType,
        accountTabs,
        setAccountTabs
    } = props

    const location = useLocation()

    const tabClick = (id) => {
        setAccountTabs(accountTabs.map((tab) => {
            if(tab.id == id) {
                tab.active = true
                return tab
            } else {
                tab.active = false
                return tab
            }
        }))
            
    }
    
    return (
        <div className="tableTabs">
            <div className="tabsContainer">
                {accountTabs && accountTabs.map(tab => (
                    <div key={tab.name} className={`${tab.active ? "activeTab accountTabs" : "accountTabs"}`}>
                        {tab.name && <div onClick={() => tabClick(tab.id)} className="tabName tabElement">{tab.name}</div>}
                        {tab.count && <div className="tabCount tabElement">{tab.count}</div>}
                    </div>
                ))}
            </div>
            <div className="tableHeaderAdd">
                    <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents: "auto",
                          background: "#5C92BF",
                          
                          fontSize:"14",
                          fontWeight:"bold",
                          height:"32px"
                      }} 
                      className="auth-button addUser floatButton"
                      onClick={() => history.push("/admin/reports/templates")}
                      >
                            <span>templates</span>
                            <FontAwesomeIcon style={{marginLeft: ".5rem"}} icon={faColumns} />
                    </div>
              </div>
        </div>
    )
}

export default TableTabs