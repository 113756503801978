import React from "react"
import InfoItem from "../../../resuable/info"
import "./userInformationCard.scss"
import infoI from "../../../../assests/icons/info.svg"
import ReactTooltip from 'react-tooltip';
import ACTION_INFO_ACTIVE from "../../../../actions/InfoActive/ACTION_INFO_ACTIVE.js";
import { useDispatch } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
const UserInformationCard = (props) => {

    const {
        header,
        middleHeader,
        middle,
        bottomHeader,
        bottom,
        button, 
        buttonFunc,  
        buttonTwo,
        buttonTwoFunc,
        info,
        subHeader,
        lowerHeader,
        lower,
        i
    } = props

    const dispatch = useDispatch()
     
    return(
        <div className="userSquare" style={{marginRight:"10px", marginLeft:"10px" ,height:"fit-content"}}>
              { header && <div className="userSquareHeader" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>{header} {subHeader && <span style={{fontSize:"12px", color:"gray", textTransform:"lowercase"}}>{subHeader}</span>} {info && <div data-for={"main"} data-tip={info}><img id={`UncontrolledTooltipExample_11${i}`} width={15} style={{cursor:"pointer",
                color:"#5C92BF", margin:"0 10px"}} src={infoI} onClick={() => dispatch(ACTION_INFO_ACTIVE(info))} /><UncontrolledTooltip
    placement="top"
    target={`UncontrolledTooltipExample_11${i}`}
  >
    Need Help!
  </UncontrolledTooltip></div>}
                </div>}
              {middleHeader && middle && <div className="userSquareMiddle">
                    <span className="squareHeader">{middleHeader}</span>
                    <span className="squareContent">{middle}</span>
                </div>} 

                
                {bottomHeader && bottom && <div className="userSquareBottom">
                    <span className="squareHeader">{bottomHeader}</span>
                    <span className="squareContent">{bottom}</span>
                </div> }

                {lowerHeader && lower && <div className="userSquareBottom">
                    <span className="squareHeader">{lowerHeader}</span>
                    <span className="squareContent">{lower}</span>
                </div> }
                {button && <div className="userUpdateLicense">
                <div
                style={{
                    width:"100%",
                    marginTop:"20px"
                }} className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          fontSize:"14",
                          fontWeight:"bold",
                          marginTop:"2rem"
                      }} 
                      className="auth-button addUser userActionsButtons"
                      onClick={buttonFunc? buttonFunc:null}
                      >
                            {button}
                        </div>
                        </div>
                    </div>}
                    {buttonTwo && <div className="userUpdateLicense">
                <div
                style={{
                    width:"100%",
                    marginTop:"20px"
                }} className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: buttonTwo.includes("Upload") ? "#6DB240" : "#5C92BF",
                          
                          fontSize:"14",
                          fontWeight:"bold",
                          marginTop:"2rem"
                      }} 
                      className={`auth-button addUser userActionsButtons ${buttonTwo.includes("Upload") && "hoverGreen"}`}
                      onClick={buttonTwoFunc? buttonTwoFunc:null}
                      >
                            {buttonTwo}
                        </div>
                        </div>
                    </div>}
              </div>
    )
}

export default UserInformationCard