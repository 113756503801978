import { gql } from "apollo-boost";

const updateViewutation = gql`
  mutation(
      $_id: ID
      $project: ID
      $extra: String
      $label: String
      $del: Boolean
      ) {
      updateView(
      _id: $_id
      project: $project
      extra: $extra
      del: $del
      label: $label
    ) {
      _id  
      project
      default
      firstTime
      areas
      locations
      passed
      extra
      del
      failed
      reports
      createdAt
      label
    }
  }
`;

export default updateViewutation;
