import React, { useEffect, useRef, useState } from "react";
import "./adminReports.scss"
import Cookies, { set } from "js-cookie";
import { Redirect, useHistory } from "react-router-dom";
import {useLocation} from "react-router-dom"
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccountTable as ReportsTable }from "../../resuable/accountTable";
import { faArchive, faCheck, faChevronDown, faDownload, faFileAlt, faTimesCircle, faUndo } from "@fortawesome/free-solid-svg-icons";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import reportBlue from "../../../assests/icons/reportBlue.svg"
import download from "../../../assests/icons/download.svg"
import yes from "../../../assests/icons/yes.svg"
import no from "../../../assests/icons/no.svg"
import { useMutation } from "@apollo/client";
import updateReportMutation from "../../../graphql/mutations/report/updateReportMutation";
import {DataSpinner} from "../../resuable/spinner/dataSpinner"
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import AddReportsModal from "../../resuable/modals/addReportsModal"; 
import AWS from 'aws-sdk'
import RejectionModal from "../../resuable/modals/rejectionModal";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import QuestionnaireModal from "../../resuable/modals/questionnaireModal";
import Pagination from "../../resuable/pagination/Pagination";
import TableTabs from "../../resuable/accountTable/tableTabs";
import moment from "moment"
import { UncontrolledTooltip } from "reactstrap";
import ConfirmModal from "../../resuable/modals/confirmModal";
import ShowMessageModal from "../../resuable/modals/showMessageModal";
const AdminReports = ({currentPageReports, socket, setCurrentPageReports, activeView, setActiveView, activeType, setActiveType, user, users, reports, reportsRefetch, reportsCalled, defaultDCOF, reportLoading}) => {
    
const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
}) 
    let history = useHistory()
    let location = useLocation()
    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const [
        updateReport,
        { data: updateReportData, loading:updateReportLoading },
      ] = useMutation(updateReportMutation)
  
    const dispatch = useDispatch()
    const [reportsData, setReportsData] = useState(null)
    const [activeTab, setActiveTab] = useState(null)
    const [license, setLicense] = useState(null)
    const [reportsTabs, setReportsTabs] = useState([
        {
            name: "Pending",
            id:"pending",
            active: true
        },
        {
            name: "Approved",
            id:"approved",
            active: false
        },
        {
            name: "Rejected",
            id:"rejected",
            active: false
        }
    ]) 

    const [showMessageModal, setShowMessageModal] = useState(null)

    const reportClicked = (row) => {

       if(activeType !== "all") { if(row.status == "approved") {
            if(!row.wmg) {
                setShowPopup(false);
                downloadInnerHtml(row, row.id)
            }
        } else if (row.status == "rejected") {
            setShowPopup(false);
            setShowMessageModal(row.notes)
        } else if (row.archive) {
            setShowPopup(false);
            setConfirmModal("Release this report")
            setHandleIt({id:row.id, status:row.status, archive: "release"})
        }}
    }

    const [handleIt, setHandleIt] = useState(null)
    const [confirmModal, setConfirmModal] = useState(null)
    const handleItFunc = () => {
        if(handleIt.archive) {
            archiveReportFunc(handleIt)
            setHandleIt(null)
        } else if(handleIt.status == "approved") { 
            updateReportFunc(handleIt)
            setHandleIt(null)
        } else if (handleIt.status == "rejected") {
            setRejectionModal(handleIt)
            setHandleIt(null)

        } 
    }

    const archiveReportFunc = ({id, status, archive}) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())

       updateReport({
        variables:{
            _id: id,
            archive: archive == "archive" ? true : false
        }
       }).then(res => {
        
        setActiveType(archive == "archive" ? "archive" : status)
       })
    }
    const reportsColumns = [
        {
            name: "download",
            filter:["archive"],
            cell: (row) => <div id={`UncontrolledTooltipExample_notification_${row.id}`}>
            {row.newUpdate ? <>
            <div  className="newupdate"></div>
            <UncontrolledTooltip 
          target={`UncontrolledTooltipExample_notification_${row.id}`}
        > 
         New Update Available
        </UncontrolledTooltip>
            </> : null}
            <img 
            onClick={() => downloadInnerHtml(row, row.id)} style={{cursor:"pointer", opacity: false? ".2" : "1"}} 
            src={download} /> </div>,
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
            width:"100px",
            style:{
                fontSize:"16px"
            }
        },
        {
            name: "report",
            filter:["archive"],
            cell: (row) => <img style={{cursor:"pointer"}} src={reportBlue} onClick={() => {
                history.push({ 
                    pathname: `/admin/reports/template`,
                    state: {
                    type: "edit",
                    reportId: row.id,
                    _id: row.id,
                    html: row.template,
                    css: row.css,
                    }
                })
            }}/>,  
            ignoreRowClick: true,
            allowOverflow: false,
            button: true, 
            style:{
                fontSize:"16px"
            }
        },
        {
            name: "Archive",
            filter:["archive"],
            cell: (row) => !row.archive ? <div style={{width:"100%", display:"flex", justifyContent:"center"}}><FontAwesomeIcon 
            // onClick={() => archiveReportFunc(row, true)} 
            onClick={() => {
                setConfirmModal("Archive this report")
                setHandleIt({id:row.id, status:row.status, archive: "archive"})
            }} 
            style={{color:"#EF1111", fontSize:"20px"}} icon={faArchive}/></div> : null, 
            ignoreRowClick: true,
            allowOverflow: false,
            width:"120px",
            style:{ 
                fontSize:"16px"
            }
        },
        {
            name: "Release",
            filter:["rejected", "pending", "all", "approved"],
            cell: (row) => row.archive ? <div style={{width:"100%", display:"flex", justifyContent:"center"}}><FontAwesomeIcon 
            // onClick={() => archiveReportFunc(row, false)} 
            onClick={() => {
                setConfirmModal("Release this report")
                setHandleIt({id:row.id, status:row.status, archive: "release"})
            }} 
            style={{color:"#6DB240", fontSize:"20px"}} icon={faUndo}/></div> : null, 
            ignoreRowClick: true,
            allowOverflow: false,
            width:"120px",
            style:{ 
                fontSize:"16px"
            }
        },
        {
            name: "Questionnaire",
            filter:["archive"],
            cell: (row) => row.questionnaire ? <div style={{width:"100%", display:"flex", justifyContent:"center"}}><FontAwesomeIcon onClick={() => setQuestionnaireModal(row.questionnaire)} style={{color:"#5C92BF", fontSize:"20px"}} icon={faQuestionCircle}/></div> : null, 
            ignoreRowClick: true,
            allowOverflow: false,
            width:"80px",
            style:{ 
                fontSize:"16px"
            }
        },
        {
            name: "approve",
            filter: ["approved", "archive"],
            cell: (row) => row.status == "pending" || row.status == "rejected" ? <img style={{cursor:"pointer"}} src={yes} onClick={() => {
                setConfirmModal("approve this report")
                setHandleIt({id:row.id, status:"approved"})
            }} />: null,
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
            width: "60px",
            style:{
                fontSize:"16px"
            }
        },
        {
            name: "reject",
            filter: ["rejected", "archive"],
            cell: (row) => row.status == "pending" || row.status == "approved" ? <img style={{cursor:"pointer"}} src={no} onClick={() => {
                setConfirmModal("reject this report")
                setHandleIt({id:row.id, status:"rejected"})
            }} /> : null,
            ignoreRowClick: true,
            allowOverflow: false,
            button: true, 
            style:{
                fontSize:"16px"
            }
        },
        {
            name: 'Approved By',
            filter:["rejected", "pending", "all", "archive"],
            selector: row => row.updatedBy, 
            
        },
        ,
        {
            name: 'Rejected By',
            filter:["approved", "pending", "all", "archive"],
            selector: row => row.updatedBy, 
            
        },
        {
            name: 'date of audit',
            filter:["none"],
            selector: row => row.dateOfAudit, 
            
        },
        {
                    name: 'generation date',
                    filter:["none"],
                    selector: row => row.generationDate,

        },
        {
            name: 'last update',
            filter:["none"],
            selector: row => row.lastUpdate,

        },
        {
            name: 'client',
            filter:["none"],
            selector: row => row.client,

        },
        {
            name: 'site',
            filter:["none"],
            selector: row => row.site,

        },
        {
            name: 'Generated by',
            filter:["none"],
            selector: row => row.userName,
            
        },{ 
            name: 'User Company',
            filter:["none"],
            selector: row => row.userCompany,
            
        },
        
        {
            name: 'type',
            filter:["none"],
            selector: row => row.type,

        },
        {
            name: 'notes',
            filter:["none"],
            cell: (row) => <div title={row.notes} className="preW">{row.notes}</div>,
            width:"300px"

        },
        {
            name: 'status',
            filter:["none"],
            cell: (row) => <div className="audit-status" style={{backgroundColor: row.status == "pending" ? "#D4D243" : row.status == "rejected" ? "#EF1111" : row.status == "approved" ? "#6DB240" : "#ffffff"}}>
            <span>{row.status}</span></div>,
            ignoreRowClick: true,
            allowOverflow: false,
            button: false,
        },
    ];

    const downloadInnerHtml = (pdf, id) => {
        const {template, css} = pdf
        let newHtml
        let newCss
          const paramsCss = {
            Bucket: S3_BUCKET,
            Key: css,
          }
          myBucket.getObject(paramsCss)
          .on("complete", (evtCss) => {
            if(evtCss?.data?.Body) {
              if(Buffer.from(evtCss?.data?.Body)) {
                newCss = Buffer.from(evtCss?.data?.Body).toString('utf8')
              }
              
            } 
            const params = {
              Bucket: S3_BUCKET,
              Key: template,
            }
            myBucket.getObject(params)
            .on("complete", (evt) => {
              if(evt.data.Body) { 
                updateReport({variables:{
                    _id: id,
                    newUpdate: false
                }}).then(x => {
                    reportsRefetch()
                })
                newHtml = Buffer.from(evt.data.Body).toString('utf8')
                let stringHtml = `<div><style>${newCss}</style>${newHtml}</div>`
                history.push({
                    pathname:"/admin/reports/download",
                    state:{
                        page: stringHtml
                    }
                })
                //  let mimeType = "text/html"

                // let finalHtml = finalPdf
                // let link = document.createElement('a'); 
                // mimeType = mimeType || 'text/plain';
                // link.setAttribute('download', "report.html");
                // link.setAttribute('href', 'data:' + mimeType  +  ';charset=utf-8,' + encodeURIComponent(finalHtml));
                // link.click();
              }
            })
            .send((err) => {
                if (err) console.log(err)
            }) 
          })
          .send((err) => {
              if (err) console.log(err)
          })       
    }

    const resubmitReport = (_id) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        updateReport({
            variables:{
                _id,
                status:"pending",
                note:"resubmitted"
            }
        }).then(() => {
            reportsRefetch()
        })
    }
    
    const reportsUserColumns = [
       {
            name: "download",
            cell: (row) => <div id={`UncontrolledTooltipExample_notification_${row.id}`}>
            {row.newUpdate ? <>
            <div  className="newupdate"></div>
            <UncontrolledTooltip 
          target={`UncontrolledTooltipExample_notification_${row.id}`}
        > 
         New Update Available
        </UncontrolledTooltip>
            </> : null} 
            <img 
            onClick={() => row.status == "approved" && !row.wmg ? downloadInnerHtml(row, row.id) : null} style={{opacity: row.status == "pending" || row.status == "rejected" || row.wmg ? ".2" : "1"}} 
            src={download} /> </div>,
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
            width:"100px",
            style:{
                fontSize:"16px"
            }
        },
        {
            name: 'date of audit',
            filter:["none"],
            selector: row => row.dateOfAudit,
            
        },
        {
            name: 'generation date',
            filter:["none"],
            selector: row => row.generationDate,

        },
        {
            name: 'last update',
            filter:["none"],
            selector: row => row.lastUpdate,

        },
        {
            name: 'client',
            filter:["none"],
            selector: row => row.client,

        },
        {
            name: 'site',
            filter:["none"],
            selector: row => row.site,

        },
        {
            name: 'type',
            filter:["none"],
            selector: row => row.type,

        },
        {
            name: 'notes',
            filter:["none"],
            cell: (row) => <div title={row.notes} className="preW">{row.notes}</div>,
            width:"300px"

        },
        {
            name: 'status',
            cell: (row) => <div className="audit-status" style={{backgroundColor: row.status == "pending" ? "#D4D243" : row.status == "rejected" ? "#EF1111" : row.status == "approved" ? "#6DB240" : "#ffffff"}}>
            <span>{row.status}</span></div>,
            ignoreRowClick: true,
            allowOverflow: false,
            button: false,
        },
        // {
        //     name: "resubmit",
        //     cell: (row) => <div className={`auth-button addUser ${row.status == "pending" || row.status == "approved" ? "disable-button" : ""}`} onClick={() => {
        //             row.status == "rejected" && resubmitReport(row.id)
        //     }} style={{width:"fit-content", height:"fit-content", padding:".5rem .7rem"}}>Re-submit</div>,
        //     ignoreRowClick: true,
        //     allowOverflow: false,
        //     button: true,
        //     width:"100px",
        //     style:{
        //         fontSize:"16px"
        //     }
        // },
    ]; 
  
    useEffect(() => {

        if(reports && users) {
            setReportsData(
              reports?.length ? reports.map((report) => { 
                let activeUser = users.find(x => x._id == report.user)
                const mappingUser = {
                    id: report._id,
                    dateOfAudit: report.dateOfAudit,
                    newUpdate: report.newUpdate,
                    updatedBy: report.updatedBy,
                    archive: report.archive,
                    generationDate: report.dateOfReportGeneration,
                    //lastUpdate: report.updatedAt ? Number(moment().format("mm")) - Number(moment.unix(report.updatedAt/1000).format("mm")) < 0 ? "More than hour"  : `${Number(moment.unix(report.updatedAt/1000).format("mm"))} mintues ago` : null,
                    lastUpdate: report.updatedAt ? moment.unix(report.updatedAt/1000).format("MM/DD/YYYY"): null,
                    type: report.type ? report.type : "Free",
                    rejectionReason: report.rejectionReason,
                    notes: report.note ? report.note : "No notes",
                    template: report.html,
                    css: report.css,
                    status: report.status,
                    userName: activeUser?.name,
                    userCompany: activeUser?.companyName,
                    client: report?.client?.companyName,
                    site: report?.site?.name,
                    questionnaire:report.questionnaire,
                    count: report.count,
                    wmg: report.wmg
                } 
                return mappingUser
            }) : []
            )
            dispatch(ACTION_LOADING_SPINNER_RESET())
        } else {
            setReportsData([])
        }
    },[reports, users])

    const [ archivedLoading, setArchiveLoading] = useState(false)
    const [ all, setAll] = useState(false)
    useEffect(() => {
        if (location.state && location.state.view) {
            setArchiveLoading(true)
            setReportsData(null)
            let row = location.state?.view?.state
            let all = location.state?.view?.all
            let type = all ? "all" : row == "Finished - Approved" ? "approved" : row == "Finished - Rejected" ? "rejected" : row == "Pending Approval" ? "pending" : null
            type ? setActiveType(type) : null
            setAll(true)
            setActiveView(location.state?.view?.id)
        } else {
                reportsRefetch({
                    view: null
                }).then(x=>{
                    setArchiveLoading(false)
                }) 
        }
        return () => {
            setActiveView(null)
            setAll(null)
            setActiveType("pending")
            setArchiveLoading(false)  
            window.history.replaceState({}, document.title)
        }
    },[location.state])


    useEffect(() => {
        if(activeView) {
        setArchiveLoading(true)
        reportsRefetch({
            view: activeView
        }).then(x=>{
            setArchiveLoading(false) 
        })
     }
 
    },[activeView])


    const [rejectionModal ,setRejectionModal] = useState(null)
    const [reason ,setReason] = useState("")
    const [questionnaireModal, setQuestionnaireModal] = useState(null)
    const updateReportFunc = (report) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setRejectionModal(null)
        updateReport({
            variables:{
                _id: report.id,
                status: report.status,
                note: reason,
                updatedBy: user.user.name
            }
        }).then((res) => { 
            if(res?.data?.updateReport?.notification) {
                socket?.emit("new message", res?.data?.updateReport?.notification)
               }
            setReason("")
            reportsRefetch().then(() => {
                dispatch(ACTION_LOADING_SPINNER_RESET())
            })
        })
    }
    // if(!defaultDCOF) {
    //     return (
    //         <DataSpinner small="l" />
    //     )
    // }
    
    useEffect(() => {
        if(user) {
            if(user?.user?.licenseNumber) {
                setLicense(JSON.parse(user?.user?.licenseNumber))
            }
        }
    },[user])

    const [reportsModal, setReportsModal] = useState(null)

    const editReportsBundle = () => {

        history.push({
            pathname: "/store",
            state: {
              user: user?.user,
              route: location.pathname,
              license: user?.user?.licenseNumber ?  JSON.parse(user?.user?.licenseNumber) : null
    
            }
          })

            // setReportsModal(license[0].licenseType)    
      }


      useEffect(() => {
        reportsCalled && reportsRefetch()
      })

      const [reportTypes, setReportTypes] = useState([
        {type: "all"},
        {type: "pending"},
        {type: "approved"},
        {type: "rejected"}
    ])


    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);
    
    const handleMouseEnter = () => {
    //   setShowPopup(true);

    };
    
    const handleMouseMove = (e) => {
        if (popupRef.current) {
          popupRef.current.style.left = e.pageX + 'px';
          popupRef.current.style.top = e.pageY + 'px';
        }
      };
    
    const handleMouseLeave = () => {
    setShowPopup(false);
    };
  
    
    const showPopupFunc = (row) => {
        if(row && activeType !== "all") {
            if(row.status == "pending" && !row.archive) {
                showPopup ? setShowPopup(false) : null 
            } else if(user?.user?.role == "admin" || row.archive) {
                !showPopup ? setShowPopup(true) : null
            } else if(user?.user?.role !== "client") {
                if(row.status == "approved") {
                    if(!row.wmg) {
                        !showPopup ? setShowPopup(true) : null
                    } else {
                        showPopup ? setShowPopup(false) : null
                    }
                } else {
                    !showPopup ? setShowPopup(true) : null
                }
            }
        }
    }
    
    const conditionalRowStyles= [
        {
            when: row => {
              if(row.status == "pending" && !row.archive) {
                  return true
              } else {
                  if(user?.user?.role == "admin" || row.archive) {
                      return false
                  } else if(user?.user?.role !== "client") {
                      if(row.status == "approved") {
                          if(row.wmg) {
                              return true
                          } else {
                              return false
                          }
                      } else {
                          return false
                      }
                  }  
              }
            },
            
          style: {
            opacity:1,
            background:"white",
            cursor:"auto",
          }
          },
      ];

    
    return (
        <div className="adminContent" style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>     
         <ConfirmModal 
            open={confirmModal}
            setOpen={setConfirmModal}
            handleIt={handleItFunc}
            setHandleIt={setHandleIt}
         />
         <QuestionnaireModal 
            open={questionnaireModal}
            setOpen={setQuestionnaireModal}
         />

        <ShowMessageModal 
            open={showMessageModal}
            setOpen={setShowMessageModal}
            setShowPopup={setShowPopup}
         />

         <RejectionModal 
            rejectionModal={rejectionModal}
            setRejectionModal={setRejectionModal}
            updateReportFunc={updateReportFunc}
            reason={reason}
            setReason={setReason}
            />   
                        {returnToHomeScreen()}
                       { user?.user?.role !== "admin" && user?.user?.role !== "client" && license && <div style={{width:"100%", background:"white", margin:"20px 0", borderRadius:"8px", padding:"5px 10px"}}>
                       <div className="userSquareHeader" style={{display:"flex", flexDirection:"row", justifyContent:"space-between  "}}><span>Reports Remaining</span>
            <div style={{ display:"flex"}}>
                <div style={{ margin:"0 1rem 0 0", alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                  <div style={{color:"grey", fontSize:"12px"}}>Free Reports</div>
                  <div style={{color:"#2F3133", fontSize:"12px", fontWeight:"bold", marginLeft:"7px"}}> {license[0]?.freeReports || 0} Report{license[0]?.freeReports ? Number(license[0]?.freeReports) > 1 ? "s" : "" : ""}</div>
                </div>
                <div style={{ margin:"0 1rem 0 0", alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                  <div style={{color:"grey", fontSize:"12px"}}>Purchased Reports</div>
                  <div style={{color:"#2F3133", fontSize:"12px", fontWeight:"bold", marginLeft:"7px"}}> {license[0]?.purchasedReports || 0} Report{license[0]?.purchasedReports ? Number(license[0]?.purchasedReports) > 1 ? "s" : "":""}</div>
                </div>
                <div style={{ alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <div className="tableHeaderAdd">
                        <div  
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          fontSize:"14",
                          fontWeight:"bold"
                      }} 
                      className="auth-button addUser floatAddUser  floatButton"
                      onClick={editReportsBundle}
                      >
                            <span>Add New Reports</span>
                        </div> 
                        </div>
                </div> 
            </div></div>
                       </div>}
 
                       <AddReportsModal  
                            user={user}
                            reportsModal={reportsModal}
                            setReportsModal={setReportsModal}
                         />
            {user?.user?.role == "admin" && user?.user?.adminType !== "WMG Admin" && <TableTabs />} 
 
 
                <div className={`${activeType ? "auditExpanded auditReports": "auditReports"}`}>
                <div 
                // onClick={() => {
                //     type.expanded = !type.expanded
                //     setReportTypes(reportTypes.map(ar => ar.type == activeType ? type : ar))
                // }} 
                style={{ display:"flex", justifyContent:"space-between"}} className="userSquareHeader">
                    
                    { user?.user?.adminType !== "WMG Admin" ? <div style={{
                      display:"flex",
                      justifyContent:"space-between", 
                      alignItems:"center",  
                      minWidth:"200px"                     
                    }}>
                        {reportTypes.filter(x => all ? true : x?.type !== "all" ).map((x, i) => (
                        <span key={i} onClick={() => setActiveType(x.type)} style={{cursor:"pointer",textTransform:"capitalize", margin:"0 10px", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"8px", background:activeType == x.type ? "#5C92BF" : "transparent", color:activeType == x.type ? "white" : "#5C92BF"}}>{x.type}</span>
                        ))}
                    </div> : null  }
                    <div style={{
                      display:"flex",
                      justifyContent:"space-between", 
                      alignItems:"center",
                    }}>
                    <div style={{marginLeft:"10px", fontSize:"12px", color:reportsData?.length > 2 ? "#a1a1a1" : "#EF1111" }}>
                         {reportLoading ? <DataSpinner small="sm"/> : `${reportsData?.length} report` }
                    </div>
               { user?.user?.role == "admin" ? <> <div className="verticalSeparator" style={{height:"30px"}}></div>
                    <div style={{marginLeft:"10px", fontSize:"12px", cursor:"pointer"}}>
                    <div style={{width:"100%", display:"flex", justifyContent:"center"}}><FontAwesomeIcon onClick={() => setActiveType("archive")} style={{color:activeType == "archive" ?"white" : "#EF1111", fontSize:"20px", background:activeType == "archive" ? "#5C92BF" : "transparent", padding:"10px"}} icon={faArchive}/></div>
                    </div> </> : null}
                    </div> 
                    
                </div>
                <div className={`${activeType ? "areaContainerOn" : "areaContainerOff"}`}>
                <div className="templatesContainer" onMouseEnter={activeType !== "pending" ? handleMouseEnter : null} onMouseMove={activeType !== "pending" ? handleMouseMove : null} onMouseLeave={activeType !== "pending"? handleMouseLeave : null}>
                    <ReportsTable
                        conditionalRowStyles={conditionalRowStyles}
                        setShowPopup={showPopupFunc}
                        accountColumns={user ? user?.user?.role == "admin" ? reportsColumns.filter(column => column.filter && !column.filter.includes(activeType)) : user?.user?.role !== "client" ?  reportsUserColumns : [] : []}
                        accountData={reportsData}
                        accountTabs={null}
                        subScreen={(row) => reportClicked(row)}
                        setAccountTabs={setReportsTabs}
                        accountType="Report"
                        user={user ? user?.user?.role == "admin" ? null : user?.user?.role == "client" ? "client" : "user" : null}
                        loading={reportLoading || archivedLoading}
                        notSearch={true}
                    />
                        {showPopup && reportsData?.length && user?.user?.role == "admin" && user?.user?.adminType !== "WMG Admin" ? (
                            <div className="popupReport" ref={popupRef}>
                            {activeType == "approved" ? "Click to download" : activeType == "rejected" ? "Click to view rejection reason" : activeType == "archive" ? "Click to release" : "" }
                            </div>
                        ) : null}
                </div>
                </div>
                </div>
              
            {!activeView ? reportsData ? <div className="boxShadowClassName" style={{position:"fixed", bottom:20, background:"white", display:"flex", borderRadius:"50px", justifyContent:"center", alignItems:"center", padding:"10px",right:20, left:"auto"}}><Pagination
                
                className="pagination-bar" 
                currentPage={currentPageReports}
                length={reportsData && !reportLoading ? reportsData?.length : null}
                totalCount={reportsData?.length ? reportsData[reportsData.length - 1]?.count : 0}
                pageSize={30}
                onPageChange={page => setCurrentPageReports(page)} 

            />

            </div> : <DataSpinner small="s" /> : null}

        </div>
    )
}

export default AdminReports