import React, { useState, useEffect, useMemo } from "react";
import { AccountTable as UsersTable } from "../../resuable/accountTable";
import { faEllipsisV, faEnvelope, faInfoCircle, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./adminUsers.scss"
import { useLazyQuery, useMutation } from "@apollo/client";
import addUserMutation from "../../../graphql/mutations/user/addUserMutation";
import { useDispatch, useSelector } from "react-redux";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import blank from "../../../assests/images/blank.png"
import download from "../../../assests/icons/download.svg"
import reportBlue from "../../../assests/icons/reportBlue.svg"
import imageCompression from "browser-image-compression";
import LZString from "lz-string"; 
import ACTION_BACK_ROUTE from "../../../actions/route/ACTION_BACK_ROUTE";
import AccountHeader from "../../resuable/accountHeader";
import UserInformationCard from "./userInformationCard";
import ReportItem from "../../resuable/user/reportItem";
import updateUserStatusMutation from "../../../graphql/mutations/user/updateUserStatusMutation";
import deleteUserMutation from "../../../graphql/mutations/user/deleteUserMutation";
import resetPasswordMutation from "../../../graphql/mutations/user/resetPasswordMutation";
import { useHistory } from "react-router-dom";
import ACTION_BACK_ROUTE_RESET from "../../../actions/route/ACTION_BACK_ROUTE_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import updateUserMutation from "../../../graphql/mutations/user/updateUserMutation";
import {AddAccountModal as AddUserModal} from "../../resuable/modals/addAccountModal";

import ACTION_MENU_CLICKED from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED"
import ACTION_MENU_CLICKED_RESET from "../../../actions/OptionsMenu/ACTION_MENU_CLICKED_RESET"
import DataTable from "react-data-table-component";
import moment from "moment";
import addLicenseMutation from "../../../graphql/mutations/license/addLicenseMutation";
import updateLicenseMutation from "../../../graphql/mutations/license/updateLicenseMutation";
import AddLicenseModal from "../../resuable/modals/addLicenseModal";
import { useLocation } from "react-router-dom";
import AddUntModal from "../../resuable/modals/addUntModal";
import { saveAs } from 'file-saver';
import AddReportsModal from "../../resuable/modals/addReportsModal";
import AddTerritoryModal from "../../resuable/modals/addTerritoryModal";
import { territories } from "./territories";
import ShowImageModal from "../../resuable/modals/showImageModal";
import UpdateSurfaceModal from "../../resuable/modals/updateSurfaceModal";
import updateReportMutation from "../../../graphql/mutations/report/updateReportMutation";

import infoI from "../../../assests/icons/info.svg"

import ACTION_INFO_ACTIVE from "../../../actions/InfoActive/ACTION_INFO_ACTIVE.js";
import { UncontrolledTooltip } from "reactstrap";
import Pagination from "../../resuable/pagination/Pagination";
import { DataSpinner as RenderSitesLoader } from "../../resuable/spinner/dataSpinner";
import allReportsQuery from "../../../graphql/queries/report/allReportsQuery";
import PendulumCalibrationModal from "../../resuable/modals/pendulumCalibrationModal/index.js";
import useAwsCredentials from "../../hooks/useAws.js";


const AdminUsers = (props) => { 

  const { credentials, loading, error, fetchCredentials } = useAwsCredentials();  // Use the custom hook to fetch credentials


  const [currentPageReportsUser, setCurrentPageReportsUser] = useState(1);

  let PageSize = 30;

  const [
    allReportsByUser,
    {
      data: allReportsData,
      refetch: allReportsRefetch,
      called: reportsCalled,
      loading:allReportsLoading
    },
  ] = useLazyQuery(allReportsQuery, {
    fetchPolicy: "no-cache",
  });

  

  const convertBase64ToFile = (base64String, fileName) => {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
       uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
}
  const downloadBase64Data = (base64String, fileName) => {
    let file = convertBase64ToFile(base64String, fileName);
    saveAs(file, fileName);
}

  const location = useLocation()
    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const {
      socket,
        users,
        usersRefetch,
        user,
        auditAssignOptions,
        licensesRefetch,
        selectedUser,
        setSelectedUser,
        reportLoading,
        reportsData,
        reportsRefetch,
        subOwner,
        setSubOwner,
        currentPageReports,
        setCurrentPageReports,
    } = props
    
    const backRoute = useSelector((state) => state.backRoute.backRoute);
    const menu_clicked = useSelector((state) => state.menuClicked.menu_clicked);
    
    const history = useHistory()
    const dispatch = useDispatch()

    const [accountData, setAccountData] = useState(null)

    const [toggleChecked, setToggleChecked] = useState(false)

    const subScreen = (row) => {
      setUsersFilter(null)
      setReports(null)
      setCurrentPageReportsUser(1)
      dispatch(ACTION_BACK_ROUTE({backRoute: `users`, routeName: "User Details", subUser: selectedUser}))
      setSelectedUser(row)
    } 

    useEffect(() => {
      if(selectedUser) {
        
        const limit = PageSize;
        const skip = (currentPageReportsUser - 1) * limit;
          allReportsByUser({
            variables:{
              user: selectedUser.id,
              skip,
              limit 
            } 
          })
      }
    },[selectedUser, currentPageReportsUser])

        const menuToggle = (row) => {
         menu_clicked ? dispatch(ACTION_MENU_CLICKED_RESET()) : dispatch(ACTION_MENU_CLICKED(row))
        }

        const formatNumber = (num) => {
          return num
        }
    const accountColumns = [
        {
            name: 'image',
            cell: row => (
              <img
                    onClick={() => subScreen(row)}
                    className="adminAvatar"
                    src={row.image ? row.image : blank}
                    alt={
                      row.name[0]
                    }
                  />
            ),
            button:true,

            
        },
        {
            name: 'name',
            cell : row => (
              row.id == user?.user?._id ? (
                <div onClick={() => subScreen(row)}>{row.name} <span style={{color: "#6DB240"}}>(you)</span></div>
              ) : (
                <div onClick={() => subScreen(row)}>{row.name}</div>
              )
            ),
            style: {
                fontSize:"13px",
                fontWeight:"bold",
                textTransform:"capitalize"
            }
        },
        {
            name: 'company name',
            selector: row => row.company,

        },
        {
          name: 'owner',
          selector: row => row.ownerName,

      },
        {
            name: 'email',
            cell: row => (
              <div onClick={() => subScreen(row)}>{row.email}</div>
            ),
        },
        {
            name: 'phone',
            cell: row => (
              <div onClick={() => subScreen(row)}> {row.phone ? formatNumber(row.phone) : "not provided"} </div>
            ),

        },
        {
            name: 'License',
            cell: row => <div onClick={() => subScreen(row)}>
              {row.license[0]?.number} 
            </div>,

        }, 
        {
          name: 'status',
          cell: (row) => (
              <label style={{cursor:"pointer"}} onClick={() => updateUserStatusFunc(row)} className="toggle-control">
                  <span className={`${row.status ? "checked" : "notChecked"}`}></span>
                  <span className="control"></span>
              </label>
              ),
          ignoreRowClick: true,
          allowOverflow: false, 
          button:true,
          style:{ 
              fontSize:"19px"
          }
          
      },
      {
        name: 'Letter',
        cell: (row) => (
            <label style={{cursor:"pointer"}} onClick={() => updateUserStatusFunc(row, "letter")} className="toggle-control">
                <span className={`${row.letter ? "checked" : "notChecked"}`}></span>
                <span className="control"></span>
            </label>
            ),
        ignoreRowClick: true,
        allowOverflow: false, 
        button:true,
        style:{ 
            fontSize:"19px"
        }
        
    },
    ];
    

    // user search 

    const [searchFilter, setSearchFilter] = useState("")
    const [usersFilter, setUsersFilter] = useState(null)
    useEffect(() => {
      if (users) {
        if (users.length > 0) {
          if (searchFilter) {
            setUsersFilter(
            [...users].filter((x) => {
                return (
                  new RegExp(searchFilter, "gi").test(x.name) ||
                  new RegExp(searchFilter, "gi").test(x.userRole) ||
                  new RegExp(searchFilter, "gi").test(x.companyName) ||
                  new RegExp(searchFilter, "gi").test(x.email) ||
                  new RegExp(searchFilter, "gi").test(x.phoneNumber)
                );
              })
            )
          } else {
            setUsersFilter(null)
          }
        }
      }
    }, [searchFilter, users]);

    
    
    const [
        addUser,
        { data: addUserData },
      ] = useMutation(addUserMutation)
  
      
    const [invitationModal, setInvitationModal] = useState(null)
    const [userFirstName, setUserFirstName] = useState("")
    const [userLastName, setUserLastName] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [oldEmail, setOldEmail] = useState("")
    const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [userPhoneNumber, setUserPhoneNumber] = useState("")
    const [userFranchisee, setUserFranchisee] = useState("Franchisee")
    const [userRole, setUserRole] = useState("user")
    const [userCompanyName, setUserCompanyName] = useState("")
    const [userCompanyAddress, setUserCompanyAddress] = useState("")

const [userCompanyCity ,setUserCompanyCity] = useState("")
const [zip ,setZip] = useState("")
const [userCompanyState ,setUserCompanyState] = useState("")
const [userCompanyCountry ,setUserCompanyCountry] = useState("")



    
    const [emailError, setEmailError] = useState("")

    const inviteUser = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setInvitationModal(null)
      addUser({
        variables:{
          name: userFirstName + " " + userLastName, 
          email: userEmail, 
          phoneNumber: userPhoneNumber,
          image: images?.map(x => x.url)[0],
          franchiseStatus: selectedUser? selectedUser.userFranchisee : userFranchisee == "Franchisee" ? true : false,
          companyName: selectedUser? selectedUser.company : userCompanyName,
          companyAddress: selectedUser? selectedUser.companyAddress : `${userCompanyAddress}, ${userCompanyCity}, ${userCompanyState}, ${userCompanyCountry}`,
          zip: zip,
          role: selectedUser ? "sub-user" : user?.user?.role == "admin" ? userRole : "sub-user",
          licenseNumber: licenseNumber? licenseNumber: selectedUser?.license[0]?._id,
          owner:  selectedUser ? selectedUser.id : user?.user?.name
        }
      })
      .catch((err) => {
        if(err) {
          setInvitationModal("User")
          dispatch(ACTION_LOADING_SPINNER_RESET())
          setEmailError(err.message)
  
        } else {
          setInvitationModal(null)
        }
      })
      .then((res) => {
        if(res)
        {
        changeImageUploaded("");
        setAddProfileClicked(false)
        changeImagePreviewAvailable(false);
        changeTakeAPhotoSelected(false);
        changeWebcamURI("");
        setUserFirstName("")
        setUserLastName("")
        setUserEmail("")
        setUserPhoneNumber("")   
        setUserFranchisee("Franchisee")
        setUserRole("user")
        setUserCompanyName("")
        setUserCompanyAddress("")
        setUserCompanyState && setUserCompanyState("")
        setUserCompanyCountry && setUserCompanyCountry("")
        setUserCompanyCity("")  
        licensesRefetch()
        setImages([])
        usersRefetch()
        } 
      })
    }

    useEffect(() => {
      if(!backRoute) {
        setSelectedUser(null)
      }
    },[backRoute])
    useEffect(() => {
        if(usersFilter) {
          setAccountData(
            usersFilter.map((user) => {
              const mappingUser = {
                  id: user._id,
                  image: user.image?.replace("#", "").replace("_", "").replace("/", ""),
                  zip: user.zip,
                  userFranchisee: user.franchiseStatus,
                  status: user.status,
                  letter: user.letter,
                  email: user.email,
                  active: user.active,
                  role: user.role,
                  userType: user.franchiseStatus ? "Franchisee" : "Non-Franchisee",
                  company: user.companyName,
                  companyAddress: user.companyAddress,
                  expiration:  user.expiration,
                  reports: user.reports,
                  name: user.name, 
                  owner: user.owner,
                  ownerName: subOwner?.name,       
                  phone: user.phoneNumber,
                  license: user.licenseNumber ? JSON.parse(user.licenseNumber) : null,
                  franchiseTerritory: user.franchiseTerritory,
                  certificate: user.certificate,
                  certificateExpiry: user.certificateExpiry,
                  surface: user.surface ? JSON.parse(user.surface) : {
                  serial: "",
                  wetValue: "",
                  dryValue: ""
                  },
                  pendulum: user.pendulum ? JSON.parse(user.pendulum) : {
                    serial: "",
                    calibration: "",
                    manufacturer: "",
                    performedBy: "",
                    dueDate: "",
                    }
              }
              return mappingUser
          })  
          )
          dispatch(ACTION_LOADING_SPINNER_RESET()) 
        } else if (users) {
            setAccountData(
              users.map((user) => {
                const mappingUser = {
                    id: user._id,
                  zip: user.zip,
                  image: user.image?.replace("#", "").replace("_", "").replace("/", ""),
                    owner: user.owner,
                    userFranchisee: user.franchiseStatus,
                    status: user.status,
                  letter: user.letter,
                    email: user.email,
                    active: user.active,
                    role: user.role,
                    userType: user.franchiseStatus ? "Franchisee" : "Non-Franchisee",
                    company: user.companyName,
                    companyAddress: user.companyAddress,
                    expiration:  user.expiration,
                    reports: user.reports,
                    name: user.name,       
                    phone: user.phoneNumber,
                    license: user.licenseNumber ? JSON.parse(user.licenseNumber) : null,
                    franchiseTerritory: user.franchiseTerritory,
                    certificate: user.certificate,
                    certificateExpiry: user.certificateExpiry,
                    surface: user.surface ? JSON.parse(user.surface) : {
                    serial: "",
                    wetValue: "",
                    dryValue: ""
                    },
                    pendulum: user.pendulum ? JSON.parse(user.pendulum) : {
                      serial: "",
                      calibration: "",
                      manufacturer: "",
                      performedBy: "",
                      dueDate: "",
                      }

                }
                return mappingUser
            })  
            )
            dispatch(ACTION_LOADING_SPINNER_RESET())
        }
    },[users, usersFilter, subOwner])
    


    
    // admin add profile picture

    
    const [takeAPhotoSelected, changeTakeAPhotoSelected] = useState(false);
    const [webcamURI, changeWebcamURI] = useState("");
    const [imageUploaded, changeImageUploaded] = useState("");
    const [imagePreviewAvailable, changeImagePreviewAvailable] = useState(false);
    const [addProfilePhotoClicked, setAddProfileClicked] = useState(false)

    
    const handleDeletedPreviewImage = () => {
        const deleteImageClass = document.getElementsByClassName("deleteImage");
        const uploadPictureClass = document.getElementsByClassName("uploadPicture");
    
        if (deleteImageClass) {
          if (deleteImageClass[0]) {
            deleteImageClass[0].style.display = "none";
          }
        }
        if (uploadPictureClass) {
          if (uploadPictureClass[0]) {
            uploadPictureClass[0].style.display = "none";
          }
        }
      };
    
  const handleImageUploaded = async (picture) => {
    // dispatch(ACTION_IMAGE_LOADING());
    if (picture[0] || typeof picture === "string") {
      const reader = new FileReader();
      changeImagePreviewAvailable(true);
      try {
        let compressedImage;

        if (typeof picture === "object") {
          compressedImage = await imageCompression(picture[0], {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 300,
          });
        } else if (typeof picture === "string") {
          await fetch(picture)
            .then((res) => {
              return res.blob();
            })
            .then(async (blob) => {
              compressedImage = await imageCompression(blob, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 300,
              });
            });
        }

        reader.readAsDataURL(compressedImage);

        reader.onloadend = async () => {
          const base64data = reader.result;

          const compressedBase64data = LZString.compressToEncodedURIComponent(
            base64data
          );
        //   dispatch(ACTION_IMAGE_LOADING_RESET());
          changeImageUploaded(compressedBase64data);
        };
      } catch (error) {
        // dispatch(ACTION_IMAGE_LOADING_RESET());
        console.log(error);
      }
    } else {
    //   dispatch(ACTION_IMAGE_LOADING_RESET());
      changeImageUploaded("");
      changeImagePreviewAvailable(false);
      handleDeletedPreviewImage();
    }
  };

  const [reports, setReports] = useState(null)

useMemo(() => {
  if(allReportsData) {
      setReports(
        allReportsData?.allReports?.map((report) => {
          const mappingUser = {
              id: report._id,
              dateOfAudit: report.dateOfAudit,
              generationDate: report.dateOfReportGeneration,
              type: report.type ? report.type : "Free",
              rejectionReason: report.rejectionReason,
              notes: report.note ? report.note : "No notes",
              template: report.html,
              css: report.css,
              status: report.status,
              user: report.user,                 
              client: report?.client?.companyName,
              site: report?.site?.name,
              count: report.count,
              wmg: report.wmg

          }
          return mappingUser
      })  
      )
      dispatch(ACTION_LOADING_SPINNER_RESET())
  }
},[allReportsData])
  
  // toggle admin status

  
  const [
    updateUserStatus,
    { data: updateUserStatusData },
  ] = useMutation(updateUserStatusMutation)

  

  const updateUserStatusFunc = (row, type) => {
    if(type && type == "franchisee") {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      const newRow = row
      newRow.userFranchisee = !newRow.userFranchisee
      setSelectedUser(newRow)
      setAccountData(accountData.map(t => t.id === newRow.id ? newRow : t))
      updateUserStatus({
        variables:{
          _id: newRow.id,
          franchiseStatus: newRow.userFranchisee
        }
      }).then(() => {
        dispatch(ACTION_LOADING_SPINNER_RESET())
        usersRefetch()
      })
    } else if(type && type == "letter") {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      const newRow = row
      newRow.letter = !newRow.letter
      setAccountData(accountData.map(t => t.id === newRow.id ? newRow : t))
        updateUserStatus({
          variables:{
            _id: newRow.id,
            letter: newRow.letter
          }
        }).then(() => {
          dispatch(ACTION_LOADING_SPINNER_RESET())
          usersRefetch()
        })

    } else {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    const newRow = row
    newRow.status = !newRow.status
    setAccountData(accountData.map(t => t.id === newRow.id ? newRow : t))
      updateUserStatus({
        variables:{
          _id: newRow.id,
          status: newRow.status
        }
      }).then(() => {
        dispatch(ACTION_LOADING_SPINNER_RESET())
        usersRefetch()
      })
    }
  }
    

  // delete user


  const [
    deleteUser,
    { data: deleteUserData },
  ] = useMutation(deleteUserMutation)

  

  const deleteUserFunc = (row) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())

      deleteUser({
        variables:{
          _id: row.id,
        }
      }).then(() => {
        setSelectedUser(null)
        dispatch(ACTION_BACK_ROUTE_RESET())
        usersRefetch()
      })
  }
    

  // reset user password

  const [
    resetPassword,
    { data: resetPasswordData },
  ] = useMutation(resetPasswordMutation)

  const resetUserPassword = (row) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    const newRow = row
    newRow.active = false
    setSelectedUser(newRow)
    setAccountData(accountData.map(t => t.id === newRow.id ? newRow : t))
    resetPassword({
      variables:{
        email: row.email
      }
    }).then(() => {
      dispatch(ACTION_LOADING_SPINNER_RESET())
    })
  }


  

  // edit user func.
  const [editUserModal, setEditUserModal] = useState(null)

  const editUser = (user) => {

    
    setUserEmail(user.email)
    setOldEmail(user.email)
    setUserFirstName(user.name.split(" ")[0])
    setUserLastName(user.name.split(" ")[1])
    setUserPhoneNumber(user.phone)
    setUserRole(user.role)
    setZip(user.zip)
    setUserCompanyName(user.company)
    setUserCompanyAddress(user.companyAddress.split(', ')[0])
    setUserCompanyCity(user.companyAddress.split(', ')[1])
    setUserCompanyState(user.companyAddress.split(', ')[2])
    setUserCompanyCountry(user.companyAddress.split(', ')[3])
    setUserFranchisee(user.userFranchisee ? "Franchisee" : "Non-Franchisee" )
    user?.image && setImages([{url: user?.image}])

    user.role == "sub-user" ? setEditUserModal("Edit Sub-User") : setEditUserModal("Edit User")
    
}
 
const [
  updateUser,
  { data: updateUserData },
] = useMutation(updateUserMutation)

const updateUserFunc = () => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setEditUserModal(null)

      const newUser = selectedUser
      newUser.name= userFirstName + " " + userLastName,
      newUser.email= userEmail,
      newUser.phone= userPhoneNumber,
      newUser.userFranchisee= userFranchisee == "Franchisee" ? true : false,
      newUser.company= userCompanyName,
      newUser.companyAddress= `${userCompanyAddress}, ${userCompanyCity}, ${userCompanyState}, ${userCompanyCountry}`,
      newUser.role= userRole
      newUser.image = images?.map(x => x.url)[0],
      newUser.zip = zip
      newUser.userType = newUser.franchiseStatus ? "Franchisee" : "Non-Franchisee"

  setSelectedUser(newUser)
  setAccountData(accountData.map(t => t.id === newUser.id ? newUser : t))

  updateUser({
    variables:{
      _id: selectedUser.id,
      name: userFirstName + " " + userLastName,
      email: userEmail == oldEmail ? null : userEmail,
      phoneNumber: userPhoneNumber,
      image: images?.map(x => x.url)[0],
      franchiseStatus: userFranchisee == "Franchisee" ? true : false,
      companyName: userCompanyName,
      zip: zip,
      companyAddress: `${userCompanyAddress}, ${userCompanyCity}, ${userCompanyState}, ${userCompanyCountry}`,
      userRole: userRole,
    }
  }).catch((err) => {
    if(err) {
     
      userRole == "sub-user" ? setEditUserModal("Edit Sub-User") : setEditUserModal("Edit User")
      dispatch(ACTION_LOADING_SPINNER_RESET())
      setEmailError(err.message)

    } else {
    setEditUserModal(null)
    }
  })
  .then((res) => {
    if(res) {
    changeImageUploaded("");
    setAddProfileClicked(false)
    changeImagePreviewAvailable(false);
    changeTakeAPhotoSelected(false);
    changeWebcamURI("");
    setUserFirstName("")
    setUserLastName("")
    setOldEmail("")
    setUserEmail("")
    setUserPhoneNumber("")   
    setUserRole("user")
    setUserFranchisee("Franchisee")
    setUserCompanyName("")
    dispatch(ACTION_LOADING_SPINNER_RESET())
    setImages([])
    usersRefetch()
    }
  })

}

// const downloadInnerHtml = (pdf) => {
//   const {template, css} = pdf
//   let newHtml
//   let newCss
//     const paramsCss = {
//       Bucket: process.env.REACT_APP_S3_BUCKET,
//       Key: css,
//     }
//     myBucket.getObject(paramsCss)
//     .on("complete", (evtCss) => {
//       if(evtCss?.data?.Body) {
//         if(Buffer.from(evtCss?.data?.Body)) {
//           newCss = Buffer.from(evtCss?.data?.Body).toString('utf8')
        
//         }
        
//       } 
//       const params = {
//         Bucket: process.env.REACT_APP_S3_BUCKET,
//         Key: template,
//       }

//       myBucket.getObject(params)
//       .on("complete", (evt) => {
//         if(evt.data.Body) { 
//           newHtml = Buffer.from(evt.data.Body).toString('utf8')
//           let finalPdf = `<html> <head><meta charset="utf-8" /><style>${newCss}</style></head><body>${newHtml}</body></html>`
//            let mimeType = "text/html"

//           let finalHtml = finalPdf
//           let link = document.createElement('a');
//           mimeType = mimeType || 'text/plain';
      
//           link.setAttribute('download', "report.html");
//           link.setAttribute('href', 'data:' + mimeType  +  ';charset=utf-8,' + encodeURIComponent(finalHtml));
//           link.click(); 
//         }
//       })
//       .send((err) => {
//           if (err) console.log(err)
//       }) 
//     })
//     .send((err) => {
//         if (err) console.log(err)
//     })       
// }

const downloadInnerHtml = async (pdf, id) => {

  let myBucket = await fetchCredentials()

  
  if(myBucket) {
  const {template, css} = pdf
  let newHtml
  let newCss
    const paramsCss = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: css,
    }
    myBucket.getObject(paramsCss)
    .on("complete", (evtCss) => {
      if(evtCss?.data?.Body) {
        if(Buffer.from(evtCss?.data?.Body)) {
          newCss = Buffer.from(evtCss?.data?.Body).toString('utf8')
        }
        
      } 
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: template,
      }
      myBucket.getObject(params)
      .on("complete", (evt) => {
        if(evt.data.Body) { 
          updateReport({variables:{
              _id: id,
              newUpdate: false
          }}).then(x => {
              reportsRefetch()
          })
          newHtml = Buffer.from(evt.data.Body).toString('utf8')
          let stringHtml = `<div><style>${newCss}</style>${newHtml}</div>`
          history.push({
              pathname:"/admin/reports/download",
              state:{
                  page: stringHtml
              }
          })
          //  let mimeType = "text/html"

          // let finalHtml = finalPdf
          // let link = document.createElement('a'); 
          // mimeType = mimeType || 'text/plain';
          // link.setAttribute('download', "report.html");
          // link.setAttribute('href', 'data:' + mimeType  +  ';charset=utf-8,' + encodeURIComponent(finalHtml));
          // link.click();
        }
      })
      .send((err) => {
          if (err) console.log(err)
      }) 
    })
    .send((err) => {
        if (err) console.log(err)
    })       
} else {
  alert("Loading.")
  
}
}


const [
  updateReport,
  { data: updateReportData, loading:updateReportLoading },
] = useMutation(updateReportMutation)


    const resubmitReport = (_id) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        updateReport({
            variables:{
                _id,
                status:"pending",
                note:"resubmitted"
            }
        }).then(() => {
            reportsRefetch()
        })
    }
const reportColumns = [      
  
  {
    name: "download",
    cell: (row) => <img 
    onClick={() => row.status == "approved" && !row.wmg ? downloadInnerHtml(row, row.id) : null} style={{cursor:"pointer", opacity: row.status == "pending" || row.status == "rejected" || row.wmg ? ".2" : "1"}} 
    src={download} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    width:"100px",
    style:{
        fontSize:"16px"
    }
},{
            name: 'date of audit',
            filter:["none"],
            selector: row => row.dateOfAudit,
            
        },
        {
            name: 'generation date',
            filter:["none"],
            selector: row => row.generationDate,

        },
        {
          name: 'client',
          filter:["none"],
          selector: row => row.client,

      },
      {
          name: 'site',
          filter:["none"],
          selector: row => row.site,

      },
        {
            name: 'type',
            filter:["none"],
            selector: row => row.type,

        },
        {
            name: 'notes',
            filter:["none"],
            cell: (row) => <div title={row.notes} className="preW">{row.notes}</div>,
            width:"300px"

        },
        {
            name: 'status',
            cell: (row) => <div className="audit-status" style={{backgroundColor: row.status == "pending" ? "#D4D243" : row.status == "rejected" ? "#EF1111" : row.status == "approved" ? "#6DB240" : "#ffffff"}}>
            <span>{row.status}</span></div>,
            ignoreRowClick: true,
            allowOverflow: false,
            button: false,
        },
      //   {
      //     name: "resubmit",
      //     cell: (row) => <div className={`auth-button addUser ${row.status == "pending" || row.status == "approved" ? "disable-button" : ""}`} onClick={() => {
      //             row.status == "rejected" && selectedUser.id == user?.user?._id && resubmitReport(row.id)
      //     }} style={{width:"fit-content", height:"fit-content", padding:".5rem .7rem"}}>Re-submit</div>,
      //     ignoreRowClick: true,
      //     allowOverflow: false,
      //     button: true,
      //     width:"100px",
      //     style:{
      //         fontSize:"16px"
      //     }
      // },
];


// update license

   
const [licenseModal, setLicenseModal] = useState(null)
const [freeReports, setFreeReports] = useState("")
const [licenseUser, setLicenseUser] = useState("")
const [licenseNotes, setLicenseNotes] = useState("")
const [expirationDate, setExpirationDate] = useState(null);
const [selectedLicense, setSelectedLicense] = useState(null)
const assignLicenseFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setLicenseModal(null)  
  updateLicense({
      variables:{
          _id: selectedLicense.id,
          user: licenseUser?.value
      }
  }).then(() => {
      setSelectedLicense(null)
      setLicenseUser("") 
      usersRefetch()
      licensesRefetch()
  
  })
  
}
const assignLicense = (row) => {
        setSelectedLicense(row)
        setLicenseModal("Assign License")
    }
const [
  addLicense,
  { data: addLicenseData },
] = useMutation(addLicenseMutation)

const [
  updateLicense,
  { data: updateLicenseData },
] = useMutation(updateLicenseMutation)

const addLicenseFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setLicenseModal(null)
  addLicense({
    variables:{
       user: licenseUser,
       createdBy: user?.user?.name,
       freeReports,
       endDate: expirationDate,
       notes: licenseNotes
    }
  })
  .then((res) => {
      let newSelectedUser = selectedUser
      newSelectedUser.license = [res.data.addLicense]
      setSelectedUser(newSelectedUser)
      setAccountData(accountData.map(t => t.id === newSelectedUser.id ? newSelectedUser : t))
    setFreeReports("")
    setExpirationDate("")
    setLicenseNotes("")
    usersRefetch()
    licensesRefetch()
  })
  

}

const [images, setImages] = useState([])

const updateLicenseFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setLicenseModal(null)  
  updateLicense({
      variables:{
          _id: selectedLicense._id,
          freeReports: freeReports,
          endDate: expirationDate,
          notes: licenseNotes,
          expired:false
      }
  }).then((res) => {
   

    let newSelectedUser = selectedUser
      newSelectedUser.license = [res.data.updateLicense]
      setSelectedUser(newSelectedUser)
      setAccountData(accountData.map(t => t.id === newSelectedUser.id ? newSelectedUser : t))
      setSelectedLicense(null)
      setLicenseUser("") 
      usersRefetch()
      licensesRefetch()
  })

  }

  const [licenseNumber, setLicenseNumber] = useState("")

  useEffect(() => {
    if(location) {
      if(location?.state?.licenseNumber) {
        setLicenseNumber(location.state.licenseNumber)
        setInvitationModal(`User`)
      }
    } 
    return () => window.history.replaceState({}, document.title)
  },[location])

  const [backAudit, setBackAudit] = useState(null)
  useEffect(() => {
    if(location && accountData) {    
      if(location?.state?.license) {
        let newUser = accountData.find((x) => x.id == user?.user?._id)
        subScreen(newUser)
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
      if(location?.state?.surface) {
        let newUser = accountData.find((x) => x.id == user?.user?._id)
        subScreen(newUser)
        setSurfaceModal("Update Surface")
        setBackAudit(location.state.backAudit)
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
    } 
    return () => window.history.replaceState({}, document.title)
  },[location, accountData])
    


  const [ viewUntModal, setViewUntModal] = useState(null)
const [ untModal, setUntModal] = useState(null)
const [ untImage, setUntImage] = useState([])
const [ completionDate, setCompletionDate] = useState("")

const addUntFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setUntModal(null)
  const newUser = selectedUser
  newUser.certificate = untImage[0].url
  newUser.certificateExpiry = moment(completionDate).add(3,"year")

  setSelectedUser(newUser)
  setAccountData(accountData.map(t => t.id === newUser.id ? newUser : t))
  updateUser({
    variables:{
      _id: selectedUser.id,
      certificate: untImage[0].url,
      certificateExpiry: moment(completionDate).add(3,"year")
    }
  }).then(x => {
    setUntImage("")
    setCompletionDate("")
    dispatch(ACTION_LOADING_SPINNER_RESET())
    usersRefetch()
  })
}

const updateUntFunc = () => {
  console.log("here update")
}


// reports modal

const [reportsModal, setReportsModal] = useState(null)

const [purchasedReports, setPurchasedReports] = useState("")

const [reportsBundle, setReportsBundle] = useState("")

const addReportsFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setReportsModal(null)  
  updateLicense({
      variables:{
          _id: selectedLicense._id,
          purchasedReports: reportsBundle,
          expired:false
      }
  }).then((res) => {
    if(res?.data?.updateLicense?.notification) {
      socket?.emit("new message", res?.data?.updateLicense?.notification)
     }
    let newSelectedUser = selectedUser
      newSelectedUser.license = [res.data.updateLicense]
      setSelectedUser(newSelectedUser)
      setAccountData(accountData.map(t => t.id === newSelectedUser.id ? newSelectedUser : t))
      setSelectedLicense(null)
      setPurchasedReports("")
      setReportsBundle("")
      setFreeReports("")
      setExpirationDate("")
      usersRefetch()
      licensesRefetch()
  })

}


const editReportsBundle = () => {
  if(selectedUser) {
    if(user?.user?.role == "admin") {
      setSelectedLicense(selectedUser.license[0])
      setFreeReports(selectedUser.license[0]?.freeReports || 0)
      setPurchasedReports(selectedUser.license[0].purchasedReports || 0)
      selectedUser.license[0]?.endDate && setExpirationDate(new Date(selectedUser.license[0].endDate))
      setReportsModal("Reports")
    } else if(user?.user?.role !== "client") {

      history.push({
        pathname: "/store",
        state: {
          user: user?.user,
          route: location.pathname,
          license: user?.user?.licenseNumber ?  JSON.parse(user?.user?.licenseNumber) : null

        }
      })

      // setSelectedLicense(selectedUser.license[0])
      // setReportsModal(selectedUser.license[0].licenseType)    
    }
  }
}


const [addTerritoryModal, setAddTerritoryModal] = useState(null)

const [territoryToAdd , setTerritoryToAdd] = useState([])

const addTerritoryFunc = () => { 
  dispatch(ACTION_LOADING_SPINNER_ACTIVE()) 
  setAddTerritoryModal(null)

  const newUser = selectedUser
        newUser.franchiseTerritory = territoryToAdd.map(x => x.value)

  setSelectedUser(newUser)
  setAccountData(accountData.map(t => t.id === newUser.id ? newUser : t))

  updateUser({
    variables:{
      _id: selectedUser.id,
      franchiseTerritory: territoryToAdd.map(x => x.value)
    }
  }).then(() => {
    setTerritoryToAdd([])
    usersRefetch()
  })
}

const updateTerritoryFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setEditUserModal(null)

  const newUser = selectedUser
      newUser.franchiseTerritory = territoryToAdd

  setSelectedUser(newUser)
  setAccountData(accountData.map(t => t.id === newUser.id ? newUser : t))

  updateUser({
    variables:{
      _id: selectedUser.id,
      franchiseTerritory: territoryToAdd
    }
  })
  .then(() => {
    setTerritoryToAdd([])
    usersRefetch()
  })
}


// recalibrate


const [pendulumSerial, setPendulumSerial] = useState("")
const [pendulumCalibrations, setPendulumCalibrations] = useState("")
const [pendulumManufacturer, setPendulumManufacturer] = useState("")
const [pendulumPerformedBy, setPendulumPerformedBy] = useState("")
const [pendulumDueDate, setPendulumDueDate] = useState("")
const [calibrateModal, setCalibrateModal] = useState(false)




const updatePendulumFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setCalibrateModal(false)
  let pendulumCalibration = {
    serial: pendulumSerial,
    calibration: pendulumCalibrations,
    manufacturer: pendulumManufacturer,
    performedBy: pendulumPerformedBy,
    dueDate: pendulumDueDate
  }
  const newUser = selectedUser
    newUser.pendulum = pendulumCalibration

setSelectedUser(newUser)
setAccountData(accountData.map(t => t.id === newUser.id ? newUser : t))

  updateUser({
    variables:{
      _id: selectedUser.id,
      pendulum: JSON.stringify(pendulumCalibration)
    }
  }).then(() => {
    setPendulumSerial("")
    setPendulumCalibrations("")
    setPendulumManufacturer("")
    setPendulumPerformedBy("")
    setPendulumDueDate("")
    usersRefetch()
    backAudit && history.push({
    pathname: "/admin/audits",
    state: {
      backAudit: backAudit
    }
    })
    setBackAudit(null)
})
}



const [surfaceModal, setSurfaceModal] = useState(null)

const [serial, setSerial]= useState("")

const [wetValue,setWetValue] = useState("")

const [dryValue, setDryValue] = useState("")

const updateSurfaceFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setSurfaceModal(null)
    let validationSurface = {
      serial: serial,
      wetValue: wetValue,
      dryValue: dryValue
    }
    const newUser = selectedUser
      newUser.surface = validationSurface

  setSelectedUser(newUser)
  setAccountData(accountData.map(t => t.id === newUser.id ? newUser : t))

    updateUser({
      variables:{
        _id: selectedUser.id,
        surface: JSON.stringify(validationSurface)
      }
    }).then(() => {
    setSerial("")
    setWetValue("")
    setDryValue("")
    usersRefetch()
    backAudit && history.push({
      pathname: "/admin/audits",
      state: {
        backAudit: backAudit
      }
    })
  setBackAudit(null)
  })
}


const [openImageModal, setOpenImageModal] = useState(false)

const TableData = React.memo(props => {
  let {
    selectedUser,
    user,
    reportColumns,
    reports
  } = props
  return(
    <DataTable
                columns={selectedUser?.id == user?.user?._id ? reportColumns : reportColumns.filter(x =>  x.name !== "resubmit")}
                data={reports ? reports.filter(x => x.user == selectedUser?.id) : []}
                progressPending={props.reportLoading}
                progressComponent={RenderSitesLoader({small:"l"})}

    />
  )
})
    return (
        <div
        style = {{
          justifyContent : backRoute ? "flex-start" : "center",
          flexDirection: backRoute ? "column" : "row",
          padding: 0,
          paddingBottom:0
        }}
        className="adminContent noPadding">          
        {returnToHomeScreen()}
        <AddReportsModal 
        user={user}
         reportsModal={reportsModal}
         setReportsModal={setReportsModal}
         freeReports={freeReports}
         setFreeReports={setFreeReports}
         purchasedReports={purchasedReports}
         setPurchasedReports={setPurchasedReports}
         expirationDate={expirationDate}
         setExpirationDate={setExpirationDate}
         reportsBundle={reportsBundle}
         setReportsBundle={setReportsBundle}
         setSelectedLicense={setSelectedLicense}
         selectedLicense={selectedLicense}
         addReportsFunc={addReportsFunc}
        />

        <AddUntModal 
         untModal={untModal}
         setUntModal={setUntModal}
         untImage={untImage}
         setUntImage={setUntImage}
         completionDate={completionDate}
         setCompletionDate={setCompletionDate}
         addUntFunc={addUntFunc}
         updateUntFunc={updateUntFunc}
         user={selectedUser}
        />

      <UpdateSurfaceModal
         active={surfaceModal}
         setActive={setSurfaceModal}
         serial={serial}
         setSerial={setSerial}
         wetValue={wetValue}
         setWetValue={setWetValue}
         dryValue={dryValue}
         setDryValue={setDryValue}
         updateSurfaceFunc={updateSurfaceFunc}
        />


<PendulumCalibrationModal
         active={calibrateModal}
         setActive={setCalibrateModal}
         pendulumSerial={pendulumSerial}
         setPendulumSerial={setPendulumSerial}
         pendulumCalibrations={pendulumCalibrations}
        setPendulumCalibrations={setPendulumCalibrations}
        pendulumManufacturer={pendulumManufacturer}
        setPendulumManufacturer={setPendulumManufacturer}
        pendulumPerformedBy={pendulumPerformedBy}
        setPendulumPerformedBy={setPendulumPerformedBy}
        pendulumDueDate={pendulumDueDate}
        setPendulumDueDate={setPendulumDueDate}
         updatePendulumFunc={updatePendulumFunc}
        />


        {openImageModal && (
          <ShowImageModal 
            openImageModal={openImageModal}
            setOpenImageModal={setOpenImageModal}
          />
        )}
        <AddTerritoryModal 
        addTerritoryModal={addTerritoryModal}
        setAddTerritoryModal={setAddTerritoryModal}
        territoryToAdd={territoryToAdd}
        addTerritoryFunc={addTerritoryFunc}
        setTerritoryToAdd={setTerritoryToAdd}
        territories={territories}
        updateTerritoryFunc={updateTerritoryFunc}
        />
        <AddLicenseModal 
            licenseModal={licenseModal}
            setLicenseModal={setLicenseModal}
            freeReports={freeReports}
            setFreeReports={setFreeReports}
            licenseNotes={licenseNotes}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate }
            setLicenseNotes={setLicenseNotes}
            licenseUser={licenseUser}
            setLicenseUser={setLicenseUser}
            addLicenseFunc={addLicenseFunc}
            updateLicenseFunc={updateLicenseFunc}
            setSelectedLicense={setSelectedLicense}
            assignLicenseFunc={assignLicenseFunc}
            auditAssignOptions={auditAssignOptions}
        />

        {editUserModal && (
          <AddUserModal
          zip={zip}
          setZip={setZip}
          userCompanyCity={userCompanyCity}
          setUserCompanyCity={setUserCompanyCity}
          userCompanyState={userCompanyState}
          setUserCompanyState={setUserCompanyState}
          userCompanyCountry={userCompanyCountry}
          setUserCompanyCountry={setUserCompanyCountry}
          images={images}
          setImages={setImages}
          emailError={emailError}
          setEmailError={setEmailError}
          invitationModal={editUserModal}
          setInvitationModal={setEditUserModal}
          setUserFirstName={setUserFirstName}
          userFirstName={userFirstName}
          setUserLastName={setUserLastName}
          userLastName={userLastName}
          setUserEmail={setUserEmail}
          userEmail={userEmail}
          updateUserFunc={updateUserFunc}
          emailAlreadyRegistered={emailAlreadyRegistered}
          invalidEmail={invalidEmail}
          setEmailAlreadyRegistered={setEmailAlreadyRegistered}
          setInvalidEmail={setInvalidEmail}
          setUserPhoneNumber={setUserPhoneNumber}
          userPhoneNumber={userPhoneNumber}
          // avatar props
          takeAPhotoSelected={takeAPhotoSelected}
          changeTakeAPhotoSelected={changeTakeAPhotoSelected}
          webcamURI={webcamURI}
          changeWebcamURI={changeWebcamURI}
          imageUploaded={imageUploaded}
          changeImageUploaded={changeImageUploaded}
          imagePreviewAvailable={imagePreviewAvailable}
          changeImagePreviewAvailable={changeImagePreviewAvailable}
          handleDeletedPreviewImage={handleDeletedPreviewImage}
          handleImageUploaded={handleImageUploaded}
          addProfilePhotoClicked={addProfilePhotoClicked}
          setAddProfileClicked={setAddProfileClicked}
          setUserFranchisee={setUserFranchisee}
          userFranchisee={userFranchisee}
          setUserRole={setUserRole}
          userRole={userRole}
          setUserCompanyName={setUserCompanyName}
          userCompanyName={userCompanyName}
          setUserCompanyAddress={setUserCompanyAddress}
          userCompanyAddress={userCompanyAddress}
      />
        )}

          {!selectedUser ? <UsersTable
          zip={zip}
          setZip={setZip}
          subScreen={subScreen}
          userCompanyCity={userCompanyCity}
          setUserCompanyCity={setUserCompanyCity}
          userCompanyState={userCompanyState}
          setUserCompanyState={setUserCompanyState}
          userCompanyCountry={userCompanyCountry}
          setUserCompanyCountry={setUserCompanyCountry}
          images={images}
          setImages={setImages}
          emailError={emailError}
          setEmailError={setEmailError}
          setLicenseNumber={setLicenseNumber}
          licenseNumber={licenseNumber}
          currentUser={user?.user?.role}
          accountData={user?.user?.role == "sub-user" ? accountData : accountData?.filter(x => x.role !== "sub-user")} 
          accountColumns={user ? user?.user?.role == "admin" ? accountColumns : user?.user?.role !== "sub-user" ? accountColumns.filter(x => x.name !== "owner" && x.name !== "Letter" ) : accountColumns.filter(a => a.name !== "Letter" && a.name !== "Role" && a.name !== "Franchisee" && a.name !== "license expiration" && a.name!=="reports") : []} 
          accountTabs={null} 
          accountType="User"
          notSearch={!user || (user && user?.user?.role !== "admin")}
          // search user
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          // add user model
          // add modal 
          invitationModal={invitationModal}
          setInvitationModal={setInvitationModal}
          setUserFirstName={setUserFirstName}
          userFirstName={userFirstName}
          setUserLastName={setUserLastName}
          userLastName={userLastName}
          setUserEmail={setUserEmail}
          userEmail={userEmail}
          inviteUser={inviteUser}
          emailAlreadyRegistered={emailAlreadyRegistered}
          invalidEmail={invalidEmail}
          setEmailAlreadyRegistered={setEmailAlreadyRegistered}
          setInvalidEmail={setInvalidEmail}
          userPhoneNumber={userPhoneNumber}
          setUserPhoneNumber={setUserPhoneNumber}
          // photo props
          takeAPhotoSelected={takeAPhotoSelected}
            changeTakeAPhotoSelected={changeTakeAPhotoSelected}
            webcamURI={webcamURI}
            changeWebcamURI={changeWebcamURI}
            imageUploaded={imageUploaded}
            changeImageUploaded={changeImageUploaded}
            imagePreviewAvailable={imagePreviewAvailable}
            changeImagePreviewAvailable={changeImagePreviewAvailable}
            handleDeletedPreviewImage={handleDeletedPreviewImage}
            handleImageUploaded={handleImageUploaded}
            addProfilePhotoClicked={addProfilePhotoClicked}
            setAddProfileClicked={setAddProfileClicked}
            userFranchisee={userFranchisee}
            setUserFranchisee={setUserFranchisee}
            setUserRole={setUserRole}
            userRole={userRole}
            setUserCompanyName={setUserCompanyName}
            userCompanyName={userCompanyName}
            setUserCompanyAddress={setUserCompanyAddress}
            userCompanyAddress={userCompanyAddress}


          /> : (
            <div className="adminDetailsContainer" style={{paddingBottom:"7rem"}}>
            <AccountHeader deleteType={"User"} user={user} editUser={editUser} resetUserPassword={resetUserPassword} deleteUserFunc={deleteUserFunc} updateUser={updateUserStatusFunc} selectedAccount={selectedUser} type="user" />
            <div className="userActions">
              <div className="leftActions">              
              </div>
              <div className="rightActions">
              <div className="tableHeaderAdd">
                        { user && user?.user?.role == "admin" && <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          marginLeft: "1rem",
                          fontSize:"14",
                          fontWeight:"bold",
                          padding:"0 2px"
                      }} 
                      className="auth-button addUser userActionsButtons"
                      onClick={() => updateUserStatusFunc(selectedUser, "franchisee")}
                      >
                            to {selectedUser.userFranchisee ? "Non-Franchisee" : "Franchisee"}
                        </div>}
                        </div>
                        {!selectedUser.active && (
                          user?.user?.role !== "user" &&
                        <div className="tableHeaderAdd">
                        <div 
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          fontSize:"14",
                          marginLeft:"1rem",
                          fontWeight:"bold"
                      }} 
                      className="auth-button addUser userActionsButtons"
                      onClick={() => resetUserPassword(selectedUser)}
                      >
                            Resend Invitation
                        </div>
                        </div>)}
              </div>

            </div> 
            <div className="userBottom">
            <div className="userInfo">
              { user && <UserInformationCard 
              i={1}
              header = "Company"
              middleHeader = "Company Name"
              middle = {selectedUser.company}
              bottomHeader = "Company Address"
              bottom = {`${selectedUser.companyAddress}${selectedUser.zip ? `, ${selectedUser.zip}` : ""}`}

              />}
              {selectedUser.userFranchisee && user?.user?.role !== "sub-user" && selectedUser.franchiseTerritory ? <UserInformationCard 
              header = "Territories"
              i={2}
              info={<div style={{height:"100%", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                Territories are Metropolitan Statistical Areas (MSAs) that are assigned to a franchise to service
              </div>}
              subHeader = {selectedUser.franchiseTerritory?.length == 0 ? "empty" : null}
              middleHeader = {selectedUser.franchiseTerritory[0] ? "Territory 01" : null}
              middle = {selectedUser.franchiseTerritory[0]}
              bottomHeader = {selectedUser.franchiseTerritory[1] ? "Territory 02" : null}
              bottom = {selectedUser.franchiseTerritory[0]}
              button={ user?.user?.role == "admin" ? selectedUser.franchiseTerritory?.length > 0 ? "Edit Territory" : "Add Territory" : null}
              buttonFunc={
                selectedUser.franchiseTerritory?.length > 0 ? () => {
                  setTerritoryToAdd(selectedUser.franchiseTerritory.map(terr => {
                    return {
                      value: terr,
                      label: terr
                    }
                  }))
                  setAddTerritoryModal("Territory")
                } : () => setAddTerritoryModal("Territory")
              }

              /> : (<div></div>)}
              {selectedUser.license && selectedUser.license.length ? <UserInformationCard 
              header = "License"
              i={3}
              info={"Each company is assigned an identifying license number that allows access to the STEP App for 12 months. There are 5 free STEP Reports included with each license purchased or renewal (if not used, these free reports expire when the license expires)"}
              middleHeader = "License Number"
              middle = {selectedUser.license[0]?.number}
              bottomHeader = {selectedUser.license[0].licenseType!=="Pay As You Go" ? "Expiration Date" : ""}
              bottom = {selectedUser.license[0].licenseType!=="Pay As You Go" ? moment(selectedUser.license[0]?.endDate).format("MM/DD/YYYY") : ""}
              button={ user?.user?.role == "admin" ? "update license" : null}
              buttonFunc={() => {
                setSelectedLicense(selectedUser.license[0]) 
                setFreeReports(selectedUser.license[0].freeReports)
                setExpirationDate(new Date(selectedUser.license[0].endDate))
                setLicenseNotes(selectedUser.license[0].notes)
                setLicenseModal("Edit License")
              }}
              /> : null} 
             
              <UserInformationCard 
              i={6}
              header = "Validation Surface"
              middleHeader= {"ANSI Serial"}
              middle={selectedUser.surface?.serial || null}
              bottomHeader={"Wet Value"}
              bottom={selectedUser.surface?.wetValue || null}
              lowerHeader="Dry Value"
              lower={selectedUser.surface?.dryValue || null}
              buttonTwo={user?.user?.role !== "admin" && "Upload Surface Info"}
              buttonTwoFunc={() => {
                if(user?.user?.role !== "admin" ) {
                  let {serial, wetValue, dryValue} = selectedUser.surface
                  setSerial(serial)
                  setWetValue(wetValue)
                  setDryValue(dryValue)
                  setSurfaceModal("Surface")
                }
              }}
              //info={"'The Ansi A326.3 validation surface or the equivalen has been found satisfactory. The sole source of supply of the ANSI A326.3 validation surface known to the committee at this time is Tile Council of North Amerrica, 100 Clemson Research Blvd Anderson, SC 29625. This is not an endorsement of this product nor its supplier.' ANSI A326.3 Standard -2021"}
              info={<div style={{padding:"20px 0"}}><img src="https://i.ibb.co/ynkcXsM/Whats-App-Image-2022-12-02-at-11-42-49-PM.jpg"/></div>}
              />
<UserInformationCard 
              i={6}
              header = "Pendulum Testing Equipment"
              middleHeader= {"Pendulum Serial"}
              middle={selectedUser.pendulum?.serial || null}
              bottomHeader={"Calibration #"}
              bottom={selectedUser.pendulum?.calibration || null}
              lowerHeader="Next Calibration due date"
              lower={selectedUser.pendulum?.dueDate || null}
              buttonTwo={user?.user?.role !== "admin" && "Re-Calibrate"}
              buttonTwoFunc={() => {
                if(user?.user?.role !== "admin" ) {
                  let {serial, calibration ,manufacturer, performedBy, dueDate} = selectedUser.pendulum || {}
                  setPendulumSerial(serial)
                  setPendulumCalibrations(calibration)
                  setPendulumManufacturer(manufacturer)
                  setPendulumPerformedBy(performedBy)
                  setPendulumDueDate(dueDate)
                  setCalibrateModal(true)
                }
              }}
              //info={"'The Ansi A326.3 validation surface or the equivalen has been found satisfactory. The sole source of supply of the ANSI A326.3 validation surface known to the committee at this time is Tile Council of North Amerrica, 100 Clemson Research Blvd Anderson, SC 29625. This is not an endorsement of this product nor its supplier.' ANSI A326.3 Standard -2021"}
              info={<div style={{padding:"20px 0"}}><img src="https://i.ibb.co/ynkcXsM/Whats-App-Image-2022-12-02-at-11-42-49-PM.jpg"/></div>}
              />
              
            
              {
                selectedUser?.certificateExpiry
                && selectedUser?.certificate
                ?
                <UserInformationCard 
                i={4}
              header = "UNT Certificate"
              middleHeader = "valid until"
              middle = {moment(selectedUser.certificateExpiry).format("MM/DD/YYYY")}
              button="view certificate"
              buttonFunc={() => {
                setOpenImageModal(selectedUser.certificate)
              }}
              buttonTwo="Upload certificate"
              buttonTwoFunc={() => {
                setUntModal("UNT certificate") 
              }}
              info={"UNT Walkway Certification Course: Type in the date that appears on your UNT Certificate. UNT Certificates are VALID for 3 years after completion"}
              /> : <UserInformationCard 
              i={5}
              header = "UNT Certificate"
              buttonTwo="Upload certificate"
              buttonTwoFunc={() => {
                setUntModal("UNT certificate")
              }}
              info={"This certificate is awarded by the University of North Texas, College of Engineering to candidates who successfully complete the assessment-based certificate program based on the standard ASTM F2948-13 “Walkway Auditor Qualifications”"}              /> }
            </div>
            {<div style={{width:"100%", height:"100%", overflow:"hidden"}}>
            {selectedUser.role == "user" && <div className="userReports">
            <div className="userSquareHeader">Sub-Users
            {<div style={{display:"inline-block"}}>
              
              <img       id="UncontrolledTooltipExample_1"
 width={15} style={{cursor:"pointer",
                color:"#5C92BF", margin:"0 10px"}} src={infoI} onClick={() => dispatch(ACTION_INFO_ACTIVE("The main user of a STEP account can add new sub-users who work under the main user's license and for the same company as the main user. Adding a sub-user allows them access to your company's STEP account which includes adding sites, adding audits and generating reports."))} />
                
                <UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltipExample_1"
                >
                  Need Help!
                </UncontrolledTooltip>
                              </div>}
            </div>
            <div className="reportsContainer">
            <UsersTable
            zip={zip}
            setZip={setZip}
            subScreen={subScreen}
            emailError={emailError}
            setEmailError={setEmailError}
            subUser={true}
            currentUser={user?.user?.role}
            selectedUser={selectedUser}
            accountData={accountData?.filter(x => x.owner == selectedUser?.id)} 
            accountColumns={user ? user?.user?.role == "admin" ? accountColumns : accountColumns.filter(a => a.name !== "Role" && a.name !== "Letter" && a.name !== "Franchisee" && a.name !== "license expiration" && a.name!=="reports") : []} 
            accountTabs={null} 
            accountType="Sub-User"
            images={images}
            setImages={setImages}
            invitationModal={invitationModal}
            setInvitationModal={setInvitationModal}
            setUserFirstName={setUserFirstName}
            userFirstName={userFirstName}
            setUserLastName={setUserLastName}
            userLastName={userLastName}
            setUserEmail={setUserEmail}
            userEmail={userEmail}
            inviteUser={inviteUser}
            emailAlreadyRegistered={emailAlreadyRegistered}
            invalidEmail={invalidEmail}
            setEmailAlreadyRegistered={setEmailAlreadyRegistered}
            setInvalidEmail={setInvalidEmail}
            userPhoneNumber={userPhoneNumber}
            setUserPhoneNumber={setUserPhoneNumber}
            // photo props
            takeAPhotoSelected={takeAPhotoSelected}
              changeTakeAPhotoSelected={changeTakeAPhotoSelected}
              webcamURI={webcamURI}
              changeWebcamURI={changeWebcamURI}
              imageUploaded={imageUploaded}
              changeImageUploaded={changeImageUploaded}
              imagePreviewAvailable={imagePreviewAvailable}
              changeImagePreviewAvailable={changeImagePreviewAvailable}
              handleDeletedPreviewImage={handleDeletedPreviewImage}
              handleImageUploaded={handleImageUploaded}
              addProfilePhotoClicked={addProfilePhotoClicked}
              setAddProfileClicked={setAddProfileClicked}
              userFranchisee={userFranchisee}
              setUserFranchisee={setUserFranchisee}
              setUserRole={setUserRole}
              userRole={userRole}
              setUserCompanyName={setUserCompanyName}
              userCompanyName={userCompanyName}
              setUserCompanyAddress={setUserCompanyAddress}
              userCompanyAddress={userCompanyAddress}
          />
            </div>
            </div>}
            {<div className="userReports" style={{ position:"relative", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
            <div className="userSquareHeader" style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", flexWrap:"wrap"}}><span>Generated Reports
            {<div style={{display:"inline-block"}}><img id="UncontrolledTooltipExample" width={15} style={{cursor:"pointer",
                color:"#5C92BF", margin:"0 10px"}} src={infoI} onClick={() => dispatch(ACTION_INFO_ACTIVE("Generated reports is the number of reports your company has generated to date. These reports have been reviewed by the step team and accepted (rejected reports are not included in this count). If there is more than one user from the same company (main user and sub-user), the dashboard will record and display the total number of reports generated for your company."))} /><UncontrolledTooltip
    placement="top"
    target="UncontrolledTooltipExample"
  >
    Need Help!
  </UncontrolledTooltip></div>}
            </span>
            <div style={{ display:"flex"}}>
            <div style={{ margin:"0 1rem 0 0", alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                  <div style={{color:"#5C92BF", fontSize:"12px"}}>Reports Remaining</div>
                </div>
                <div style={{ margin:"0 1rem 0 0", alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                  <div style={{color:"grey", fontSize:"12px"}}>Free Reports</div>
                  <div style={{color:"#2F3133", fontSize:"12px", fontWeight:"bold", marginLeft:"7px"}}>{selectedUser?.license[0]?.freeReports || 0} Report{selectedUser?.license[0]?.freeReports ? Number(selectedUser?.license[0]?.freeReports) > 1 ? "s" : "" : ""}</div>
                </div>
                <div style={{ margin:"0 1rem 0 0", alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                  <div style={{color:"grey", fontSize:"12px"}}>Purchased Reports</div>
                  <div style={{color:"#2F3133", fontSize:"12px", fontWeight:"bold", marginLeft:"7px"}}>{selectedUser?.license[0]?.purchasedReports || 0} Report{selectedUser?.license[0]?.purchasedReports ? Number(selectedUser?.license[0]?.purchasedReports) > 1 ? "s" : "":""}</div>
                </div>
                <div style={{ alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <div className="tableHeaderAdd">
                        <div  
                        style={{
                          color: "rgb(255, 255, 255)",
                          pointerEvents:  "auto",
                          background: "#5C92BF",
                          
                          fontSize:"14",
                          fontWeight:"bold"
                      }} 
                      className="auth-button addUser floatAddReport"
                      onClick={editReportsBundle}
                      >
                            <span>Add New Reports</span>
                        </div>
                        </div>
                </div>
            </div></div>
            {<><div className="reportsContainer" style={{width:"100%", paddingBottom:"50px"}}>
                <TableData 
                  selectedUser={selectedUser}
                  user={user}
                  reportColumns={reportColumns}
                  reports={reports}
                  reportLoading={allReportsLoading} 
                />
            </div>
            {reports?.length ? <div className="boxShadowClassName" style={{position:"absolute", bottom:20, right:20, left:"auto", background:"white", display:"flex", borderRadius:"50px", justifyContent:"center", alignItems:"center", padding:"10px",}}>
                <Pagination
                className="pagination-bar" 
                currentPage={currentPageReportsUser}
                totalCount={reports?.length ? reports[reports.length - 1]?.count : 0}
                pageSize={30}
                onPageChange={page => setCurrentPageReportsUser(page)}
            /></div> : <RenderSitesLoader small="s" />} </> }        
            </div>}
            </div>}
            </div>
            </div>
          )}
        </div>
    )
}

export default AdminUsers