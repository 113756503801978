import { gql } from "apollo-boost";

const updateLocationMutation = gql`
  mutation(
    $_id: ID
    $area: ID,
    $view: ID,
    $pass: Boolean,
    $readings: String,
    $condition: String,
    $report: String,
    $location: String,
    $coordinates: String,
    $label: String, 
  ) {
    updateLocation(
    _id: $_id
    area: $area,
    view: $view,
    pass: $pass,
    readings: $readings,
    condition: $condition,
    report: $report
    location: $location
    coordinates: $coordinates
    label: $label
    ) {
    _id
    area
    view
    pass
    label
    readings
    condition
    report
    location
    coordinates
  }
  }
`;

export default updateLocationMutation;
