import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from "react-dropdown";
import AddClientModal from '../addClientModal';
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import addClientMutation from '../../../../graphql/mutations/client/addClientMutation';
import Multiselect from 'multiselect-react-dropdown';
import { DataSpinner } from '../../spinner/dataSpinner';
import { AddAccountModal } from '../addAccountModal';
import ACTION_LOADING_SPINNER_RESET from '../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET';
import addUserMutation from '../../../../graphql/mutations/user/addUserMutation';
import moment from 'moment';

const BeforeGenerateModal = (props) => {
    const {
        open,
        setOpen, 
        generate,
        auditClientsOptions,
        user,
        clientsRefetch,
        auditAssignOptions,
        usersRefetch,
        } = props

        const dispatch = useDispatch()
        const [
          addClient,
          { data: addClientData,
            loading: addClientLoading,
          },
        ] = useMutation(addClientMutation)
    
        const [
          addUser,
          { data: addUserData },
        ] = useMutation(addUserMutation)


        const [questionsPage, setQuestionPageFunc] = React.useState(0);

  const myStateRef = React.useRef(questionsPage);

  const setQuestionPage = data => {
    myStateRef.current = data;
    setQuestionPageFunc(data);
  };

  const listener = async (e) => {
    let i = myStateRef.current
    if(e.key =="Enter") {
    if(i == 0) {
      toggleQuestion(1, true)
      setQuestionPage(1)
    } else if(i == 1) {
      toggleQuestion(2, true) 
      setQuestionPage(2)
    } else if(i == 2) {
      toggleQuestion(3, true) 
      setQuestionPage(3)
    } else if(i == 3) {
      toggleQuestion(4, true) 
      setQuestionPage(4)
    } else if(i == 4) {
      toggleQuestion(5, true) 
      setQuestionPage(5) 
    } else if(i == 5) {
      toggleQuestion(6, true) 
      setQuestionPage(6) 
    } else if(i == 6) {
      toggleQuestion(7, true)  
      setQuestionPage(7) 
    } else if(i == 7) {
      toggleQuestion(8, true) 
    }
  }
  };

        const [vars, setVars] = useState(null)
        const [report, setReport] = useState(null)
        
        const [one_yes, setOne_yes] = useState(null)
        const [two_yes, setTwo_yes] = useState(null)
        const [three_yes, setThree_yes] = useState(null)
        const [four_yes, setFour_yes] = useState(null)
        const [five_yes, setFive_yes] = useState(null)
        const [five_sub_yes, setFive_sub_yes] = useState(null)
        const [six_yes, setSix_yes] = useState(null)
        const [seven_yes, setSeven_yes] = useState(null)
        const [eight_yes, setEight_yes] = useState(null)

        const [newClient, setNewClient] = useState(null)
        const [newClientDiscuss, setNewClientDiscuss] = useState(null)
        const [newClientId, setNewClientId] = useState(null)
        const [newClientDiscussId, setNewClientDiscussId] = useState(null)
        const [newClientContactName, setNewClientContactName] = useState("")
        const [newAuditor, setNewAuditor] = useState(null)
        const [newUserId, setNewUserId] = useState(null)
        const [explainWhy, setExplainWhy] = useState("")
        const [skippedWhy, setSkippedWhy] = useState("")
        
        useEffect(() => {
          if(open) {
            setReport(open.finalReport)            
            setVars(open.variables)            
          }
        },[open])

        useEffect(() => {
          if(vars) {
            let auditors = vars.auditor
            setNewAuditor(auditors.map(user => {
              user.label = user.name
              user.value = user._id
              return user
            }))
          }
        },[vars])
        const onSelectFunc = (selectedList, selectedItem) => {
              if(selectedItem.value == "Add New Auditor") {
              setAddUserModal("Sub-User")
              setUsersOnFly(newAuditor)
              setNewAuditor(null)
              } else {
              setNewAuditor(selectedList)
              }
             }
             
             const onRemoveFunc = (selectedList, removedItem) => {
    
              setNewAuditor(selectedList)
             }
        const toggleQuestion = (i, state) => {
          if(i == 1) {
            if(state == true) {
              setNewClient(null)
            }
            setOne_yes(state)
          } else if(i == 2) {
            if(state == true) {
              setNewAuditor(null)
            }
            setTwo_yes(state)
          } else if(i == 3) {
            setThree_yes(state)
          } else if(i == 4) {
            if(state == true) {
              setExplainWhy("")
            }
            setFour_yes(state)
          } else if(i == 5) {
            if(state == true) {
              setFive_sub_yes(null)
              setNewClientDiscuss(null)
            }
            setFive_yes(state)
          } else if(i == 51) {
            if(state == true) {
              setNewClientDiscuss(null)
            }
            setFive_sub_yes(state)
          } else if(i == 6) {
            setSix_yes(state)
          }
          else if(i == 7) {
            setSeven_yes(state)
          } else if(i == 8) {
            setEight_yes(state)
          }
        }

        const resetQuestions = () => {
            setVars(null)
            setQuestionPage(0)
            setOne_yes(null)
            setTwo_yes(null)
            setThree_yes(null)
            setFour_yes(null)
            setFive_yes(null)
            setFive_sub_yes(null)
            setSix_yes(null)
            setSeven_yes(null)
            setEight_yes(null)
            setNewAuditor(null)
            setNewClient(null)
            setNewClientId(null)
            setNewClientDiscuss(null)
            setNewClientDiscussId(null)
            setExplainWhy("")
            setNewClient(null)
            setNewClientDiscuss(null)
            setSkippedWhy("")
            setOpen(null)
        } 

        let generateFunc = () => {
          // let client = auditClientsOptions.find(x => x._id == newClient?.value)
          // let clientDiscuss = auditClientsOptions.find(x => x._id == newClientDiscuss?.value)
          // let auditors = auditAssignOptions.filter(x => newAuditor?.some(y => y.value == x._id))

          let auditors = newAuditor
          // clients vars 
          let newVars = {...vars}
               
            newVars.ClientZipCodeAddressed = newClient ? newClient.zip : newVars.ClientZipCode
            newVars.ClientCompanyAddressed = newClient ? newClient.companyName : newVars.ClientCompany
            newVars.ClientCompanyStreetAddressAddressed = newClient ? newClient.companyAddress.split(',')[0] : newVars.ClientCompanyStreetAddress
            newVars.ClientCompanyCityAddressed = newClient ? newClient.companyAddress.split(',')[1] : newVars.ClientCompanyCity
            newVars.ClientCompanyStateAddressed = newClient ? newClient.companyAddress.split(',')[2] : newVars.ClientCompanyState
            newVars.ClientContactNameAddressed = newClient ? newClient.contactPersonName : newVars.ClientContactName
          
           

          // auditors vars
          if(auditors?.length) {
            newVars.auditor = auditors
            newVars.AuditorName = auditors?.map((x, i) => ` ${x.name} (${vars?.isWmg ? "WMG" : x.companyName})`).toString()
            newVars.AuditorCompany = auditors?.filter((v,i,a)=>a.findIndex(v2=>(v2.companyName===v.companyName))===i).map((x, i) => i !== 0 ? ` ${vars?.isWmg ? "WMG":x.companyName}` : `${vars?.isWmg ? "WMG":x.companyName}`).toString()  
            newVars.AuditorNameWithPhone = auditors?.map((x, i) => ` ${x.name} (${vars?.isWmg ? "WMG" : x.companyName}) ${vars?.isWmg ? "(940) 464-9103" : x.phoneNumber?.replace(/\s/g, '').replace(")", ") ")}`).toString() 
            newVars.certificate = auditors.filter(x => x.certificate).map(x => {
              return {
                certificate: x.certificate,
                name: x.name,
                company: vars?.isWmg ? "WMG" : x.companyName,
                certificateDate: moment(x.certificateExpiry).subtract(3,"year").format("MM/DD/YYYY")
              }      
            })
          }
          
          let questionnaire = {one_yes:{question:one_yes, answer:newClient?.contactPersonName}, two_yes: {question:two_yes, answer: newAuditor?.map((x) => x.label).toString()}, three_yes: {question:three_yes, answer:null}, four_yes: {question:four_yes, answer:explainWhy}, five_yes: {question:five_yes, answer:null}, six_yes: {question:six_yes, answer:null}, seven_yes:{question:seven_yes, answer:null}, eight_yes: {question:eight_yes, answer:skippedWhy}}
          let finalData = {...open, variables: newVars}
          finalData.variables.questionnaire = JSON.stringify(questionnaire)
          resetQuestions()
          generate(finalData)
        }

        // change client

        const [companyName, setCompanyName] = useState("")
        const [companyEmail, setCompanyEmail] = useState("")
        const [companyPhoneNumber, setCompanyPhoneNumber] = useState("")
        const [companyWorkPhoneNumber, setCompanyWorkPhoneNumber] = useState("")
        const [companyAddress, setCompanyAddress] = useState("")
        const [contactPersonName, setContactPersonName] = useState("")
        const [contactPersonFirstName, setContactPersonFirstName] = useState("")
        const [contactPersonLastName, setContactPersonLastName] = useState("")
        const [clientModal, setClientModal] = useState(null)

        const[city, setCity]  = useState("")
        const [state, setState] = useState("")
        const [zip, setZip] = useState("")
        const [country, setCountry] = useState("")
        const [images, setImages] = useState([])
        
        const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false)
        const [invalidEmail, setInvalidEmail] = useState(false)
        const [emailError, setEmailError] = useState("")

      const inviteClient = (discuss) => {
            setNewClient(null)
            setClientModal(null)
            addClient({
              variables:{
                  companyName: companyName,
                  companyAddress: `${companyAddress}, ${city}, ${state}`,
                  contactPersonName: contactPersonFirstName + " " + contactPersonLastName,
                  contactPersonEmail: companyEmail,
                  contactPersonPhoneNumber : companyPhoneNumber,
                  contactPersonWorkPhoneNumber : companyWorkPhoneNumber,
                  image: images?.map(x => x.url)[0],
                  zip: zip,
                  siteID: vars?.siteObj?.site?._id,
                  user: user?.user?.role == "user" ? user?.user?._id : user?.user?.role == "sub-user" ? user?.user?.owner : null
              }
      })
      .then((res) => {    
        
        clientsRefetch()
        setClientModal(null)
        setCompanyName("")
        setCompanyEmail("")
        setCompanyPhoneNumber("")
        setCompanyWorkPhoneNumber("")
        setCompanyAddress("")
        setCity("")
        setState("")
        setZip && setZip("")
        setContactPersonName("")
        setContactPersonFirstName("")
        setContactPersonLastName("")
        discuss ? setNewClientDiscussId(res?.data?.addClient?._id) : setNewClientId(res?.data?.addClient?._id)     
        
      })
      

    }
    const inviteUser = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setAddUserModal(null)
      let exactUser = user?.user      
      let organizedUser = {}
      organizedUser.id = exactUser._id,
      organizedUser.image = exactUser.image,
      organizedUser.owner = exactUser.owner,
      organizedUser.userFranchisee = exactUser.franchiseStatus,
      organizedUser.status = exactUser.status,
      organizedUser.email = exactUser.email,
      organizedUser.active = exactUser.active,
      organizedUser.role = exactUser.role,
      organizedUser.userType = exactUser.franchiseStatus ? "Franchisee" :"Non-Franchisee",
      organizedUser.company = exactUser.companyName,
      organizedUser.companyAddress = exactUser.companyAddress,
      organizedUser.expiration =  exactUser.expiration,
      organizedUser.reports = exactUser.reports,
      organizedUser.name = exactUser.name,       
      organizedUser.phone = exactUser.phoneNumber,
      organizedUser.license = exactUser.licenseNumber ? JSON.parse(exactUser.licenseNumber) :null,
      organizedUser.franchiseTerritory = exactUser.franchiseTerritory,

      addUser({
        variables:{
          name: userFirstName + " " + userLastName,
          email: userEmail, 
          phoneNumber: userPhoneNumber,
          image: images?.map(x => x.url)[0],
          franchiseStatus: organizedUser ? organizedUser.userFranchisee : userFranchisee == "Franchisee" ? true : false,
          companyName: organizedUser ? organizedUser.company : userCompanyName,
          companyAddress: organizedUser ? organizedUser.companyAddress : `${userCompanyAddress}, ${userCompanyCity}, ${userCompanyState}`,
          role: organizedUser ? "sub-user" : user?.user?.role == "admin" ? userRole : "sub-user",
          licenseNumber: organizedUser?.license[0]?._id,
          owner:  organizedUser ? organizedUser.id : user?.user?.name
        }
      })
      .catch((err) => {
        if(err) {
          setAddUserModal("User")
          dispatch(ACTION_LOADING_SPINNER_RESET())
          setEmailError(err.message)
  
        } else {
          setAddUserModal(null)
        }
      })
      .then((res) => {
        if(res)
        { 
        setNewUserId(res.data.addUser._id)
        setUserFirstName("")
        setUserLastName("")
        setUserEmail("")
        setUserPhoneNumber("")   
        setUserFranchisee("Franchisee")
        setUserRole("user")
        setUserCompanyName("")
        setUserCompanyAddress("")
        setUserCompanyState && setUserCompanyState("")
        setUserCompanyCity("")  
        // licensesRefetch()
        setImages([])
        usersRefetch()
        }
      })
    }

    // useEffect(() => {
    //   if(auditClientsOptions) {
    //     if(newClientId) {          
    //       let clientExist = auditClientsOptions.find(x => x.value == newClientId)
    //       if(clientExist) {
    //           setNewClient(clientExist)
    //           setNewClientId(null)
    //         }
    //     }
    //   }
    // },[auditClientsOptions, newClientId])

    // useEffect(() => {
    //   if(auditClientsOptions) {
    //     if(newClientDiscussId) {          
    //       let clientExist = auditClientsOptions.find(x => x.value == newClientDiscussId)
    //       if(clientExist) {
    //           setNewClient(clientExist)
    //           setNewClientDiscussId(null)
    //         }
    //     }
    //   }
    // },[auditClientsOptions, newClientDiscussId])

    useEffect(() => {
      if(auditAssignOptions) {        
        if(newUserId) {                
          let userExist = auditAssignOptions.find(x => x._id == newUserId)          
          if(userExist) {
              userExist.label = userExist.name
              userExist.value = userExist._id
              setNewAuditor([...usersOnFly, userExist])
              setUsersOnFly(null)
              setNewUserId(null)
            }
        }
      }
    },[auditAssignOptions, newUserId])
 

    // users props

    
    const [addUserModal, setAddUserModal] = useState(null)
    const [usersOnFly, setUsersOnFly] = useState(null)
    const [userFirstName, setUserFirstName] = useState("")
    const [userLastName, setUserLastName] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userPhoneNumber, setUserPhoneNumber] = useState("")
    const [userFranchisee, setUserFranchisee] = useState("Franchisee")
    const [userRole, setUserRole] = useState("user")
    const [userCompanyName, setUserCompanyName] = useState("")
    const [userCompanyAddress, setUserCompanyAddress] = useState("")
    const [userCompanyCity ,setUserCompanyCity] = useState("")
    const [userCompanyState ,setUserCompanyState] = useState("")


    const addClientToReport = () => {
      let newClient = {}
      newClient.companyName = vars?.ClientCompany,
      newClient.companyAddress = `${companyAddress}, ${city}, ${state}`,
      newClient.contactPersonName = contactPersonFirstName + " " + contactPersonLastName,
      newClient.contactPersonEmail = companyEmail,
      newClient.contactPersonPhoneNumber  = companyPhoneNumber,
      newClient.contactPersonWorkPhoneNumber  = companyWorkPhoneNumber,
      newClient.image = images?.map(x => x.url)[0],
      newClient.zip = zip,
      newClient.user = user?.user?.role == "user" ? user?.user?._id : user?.user?.role == "sub-user" ? user?.user?.owner : null
      setNewClient(newClient)
      setCompanyName("")
      setCompanyEmail("")
      setCompanyPhoneNumber("")
      setCompanyWorkPhoneNumber("")
      setCompanyAddress("")
      setCity("")
      setState("")
      setZip && setZip("")
      setContactPersonName("")
      setContactPersonFirstName("")
      setContactPersonLastName("")
      setClientModal(null)
    }

    useEffect(() => {
      if(open) {
        window.addEventListener("keydown", listener)
      }
      return () => {
        window.removeEventListener("keydown", listener)
      }
    },[open])
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
               
               <div className="modal task-modal" style={{minHeight:"60vh", height:"90vh", height:"fit-content", width:"60vw", alignItems:"center", justifyContent:"flex-start"}}>

              <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  resetQuestions(null)
                }}
              />

            <AddAccountModal
              emailError={emailError}
              usersOnFly={usersOnFly}
              setNewAuditor={setNewAuditor}
              currentUser={"user"}
              selectedUser={user}
              invitationModal={addUserModal}
              setInvitationModal={setAddUserModal}
              userCompanyCity={userCompanyCity}
              setUserCompanyCity={setUserCompanyCity}
              userCompanyState={userCompanyState}
              setUserCompanyState={setUserCompanyState}
              images={images}
              setImages={setImages}         
              setUserFirstName={setUserFirstName}
              userFirstName={userFirstName}
              setUserLastName={setUserLastName} 
              userLastName={userLastName}
              setUserEmail={setUserEmail}
              userEmail={userEmail}
              emailAlreadyRegistered={emailAlreadyRegistered}
              invalidEmail={invalidEmail}
              setEmailAlreadyRegistered={setEmailAlreadyRegistered}
              setInvalidEmail={setInvalidEmail}
              setUserPhoneNumber={setUserPhoneNumber}
              userPhoneNumber={userPhoneNumber}
              setUserFranchisee={setUserFranchisee}
              userFranchisee={userFranchisee}
              setUserRole={setUserRole}
              userRole={userRole}
              setUserCompanyName={setUserCompanyName}
              userCompanyName={userCompanyName}
              setUserCompanyAddress={setUserCompanyAddress}
              userCompanyAddress={userCompanyAddress}
              inviteUser={inviteUser}
            />

            <AddClientModal
                country={country}
                setCountry={setCountry}
                addClientToReport={addClientToReport}
                setNewClientDiscuss={setNewClientDiscuss}
                addClientData={addClientData}
                addClientLoading={addClientLoading}
                setNewClient={setNewClient}
                images={images}
                setImages={setImages}
                zip={zip}
                setZip={setZip}
                clientModal={clientModal}
                setClientModal={setClientModal}
                setCompanyName={setCompanyName}
                companyName={companyName}
                setCompanyEmail={setCompanyEmail}
                companyEmail={companyEmail}
                inviteUser={inviteClient}
                emailAlreadyRegistered={emailAlreadyRegistered}
                invalidEmail={invalidEmail}
                setEmailAlreadyRegistered={setEmailAlreadyRegistered}
                setCompanyPhoneNumber={setCompanyPhoneNumber}
                companyPhoneNumber={companyPhoneNumber}
                setCompanyWorkPhoneNumber={setCompanyWorkPhoneNumber}
                companyWorkPhoneNumber={companyWorkPhoneNumber}
                setCompanyAddress={setCompanyAddress}
                companyAddress={companyAddress}
                contactPersonName={contactPersonName}
                setContactPersonName={setContactPersonName}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}                
                setContactPersonFirstName={setContactPersonFirstName}
                setContactPersonLastName={setContactPersonLastName}
                contactPersonFirstName={contactPersonFirstName}
                contactPersonLastName={contactPersonLastName}
                />
          <h1 className="header" style={{width:"100%", fontSize:"24px", display:"flex", justifyContent:"center", alignItems:"center"}}>Step Report Questionnaire</h1>
          <div className="form-group-container task-form" style={{
            margin: "50px 0",
            padding: "0px 150px", height:"100%", overflow:"auto", justifyContent:"flex-start", alignItems:"center", width:"100%", }}>
          {questionsPage == 0 ? <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <div className="form-collection">
              <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>The report will be addressed to {one_yes == false ? <del style={{margin:"0 4px"}}> {vars?.ClientContactName}</del> : <span>{vars?.ClientContactName}</span> } {newClient?.contactPersonName} of {vars?.ClientCompany}</div>
              
              <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
              <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(1, false)} className={`${one_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                    <span className="control">No</span>
              </div>
              <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(1, true)} className={`${one_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}> 
                    <span className="control">Yes</span>
              </div>
              </div>
              </div>
              {one_yes == false && <div className="form-collection">
              <div className="form-label"><span style={{textTransform:"none"}}>Who Should the Report be addressed to?</span></div>
              {<div className="form-collection">
              <div className="form-label" style={{display:"flex", alignItems:"center", paddingRight:"0px"}}><span>Addressed To</span></div>
              {newClient !== null || !addClientLoading ? <Dropdown
                  value={newClient ? {label: newClient?.contactPersonName, value: newClient?.contactPersonName} : ""}
                  options={[{
                    label: <div style={{color:"#6DB240"}}>Add New Name - address</div>,
                    value: "Add New Client"
                  }]}
                  onChange={(choice) =>{
                    if(choice.value == "Add New Client") {
                      setClientModal("Addressed to")
                    }
                    setNewClient(choice) 
                  }
                  } 
                  controlClassName="form-dropdown"
                  menuClassName='underMenuAdmin' 
                />: <DataSpinner small="sm" /> }

            </div>}
              <div>
                {/* <input
                placeholder='Client Contact Name'
                onChange={(e) => setNewClientContactName(e.target.value)}
                value={newClientContactName}
                type="text"
                className="form-input"
              /> */}
              </div>
              </div>
              } 
            </div> : questionsPage == 1 ? (
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>{two_yes == false ? <del style={{margin:"0 4px"}}> {vars?.AuditorName}</del> : vars?.AuditorName} performed the Audit and was the only person(s) to operate the BOT-3000E.</div>
                
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(2, false)} className={`${two_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                      <span className="control">No</span>
                </div> 
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(2, true)} className={`${two_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
                {two_yes == false && <div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>Who performed this audit?</span></div>
                    {newAuditor !== null || !usersOnFly ? <Multiselect
                            options={[...auditAssignOptions.map(user => {
                            user.label = user.name
                            user.value = user._id
                            return user
 
                          }),{label :"Add New Auditor", value:"Add New Auditor"}]} // Options to display in the dropdown
                            selectedValues={newAuditor} // Preselected value to persist in dropdown
                            onSelect={onSelectFunc} // Function will trigger on select event
                            onRemove={onRemoveFunc} // Function will trigger on remove event
                            displayValue="label" // Property name to display in the dropdown options
                            style={{optionContainer : { background:"white", maxHeight:"150px", position:"absolute", width:"100%", top:"-200px"}}}

                    /> : <DataSpinner small="sm" /> }
                {/* <input
                  placeholder='Auditor Name'
                  onChange={(e) => setNewAuditor(e.target.value)}
                  value={newAuditor}
                  type="text"
                  className="form-input"
                /> */}
                </div>} 
              </div>
            ) : questionsPage == 2 ? (
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Did you verify the BOT-3000E onsite?</div>
                
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(3, false)} className={`${three_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(3, true)} className={`${three_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
              </div>
            ) : questionsPage == 3 ? (
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Was 0.05% Sodium Laurel Sulfate (SLS) Solution used when testing was performed under “WET”
                conditions?</div>
                
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(4, false)} className={`${four_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(4, true)} className={`${four_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
                {four_yes == false && <div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>explain why!</span></div>
                <textarea
                    onChange={(e) => setExplainWhy(e.target.value)}
                    value={explainWhy}
                    type="text"
                    placeholder="Explain why!"
                    className="form-input textarea-input"
                  />
                </div>} 
              </div>
            ) : questionsPage == 4 ? (
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Were the procedures for testing according to ANSI A326.3 Standard-2021 under {report?.tests?.map(x => x.areaCondition).filter((item, i, ar) => ar.indexOf(item) === i).map((x, i) => <div key={i} style={{display:"inline-block", margin:"0 5px"}}> {x.replace("wet", "Wet").replace("dry", "Dry")} </div>) || "Testing Conditions used"} discussed with {`${newClient?.contactPersonName || vars?.ClientContactName}`}? </div>
                
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(5, false)} className={`${five_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(5, true)} className={`${five_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
                {/* {five_yes == false && <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Did you discuss the procedures for testing according to ANSI A326.3 Standard-2021 under {report?.tests?.map(x => x.areaCondition).filter((item, i, ar) => ar.indexOf(item) === i) || "Testing Conditions used"} with anybody?</div>
                
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(51, false)} className={`${five_sub_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(51, true)} className={`${five_sub_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>}
                {five_sub_yes == true && <><div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>Choose the client?</span></div>
                <Dropdown
                  options={[...auditClientsOptions.map(client => {
                      client.label = client.companyName
                      client.value = client._id
                      return client
                  }), {
                    label: <div style={{color:"#6DB240"}}>Add New Client</div>,
                    value: "Add New Client"
                  }]}
                  onChange={(choice) =>{
                    if(choice.value == "Add New Client") {
                      setClientModal("Discuss Client")
                    }
                    setNewClientDiscuss(choice) 
                  }
                  } 
                  controlClassName="form-dropdown"
                  menuClassName='underMenuAdmin' 
                  value={newClientDiscuss}
                />
                </div></>}  */}
              </div>
            ) : questionsPage == 5 ? (
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Did 
                {newAuditor?.length ? newAuditor?.map((x,i,a) => (<div style={{display:"inline-block", margin:"0 5px"}} key={x.value}> {` ${x?.label} ${i !== a.length-1 ? "," : " "} `} </div>)) : vars?.AuditorName}
                &nbsp;categorize the Area tested as shown? {report.areas.map((x, i) => (<div style={{fontSize:"22px"}} key={i}>- Area {x.areaNumber} ({x.name}) into the Product Use Category {x.testingCategory.slice(0, x.testingCategory.indexOf(":"))}</div>))}
</div>
                
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(6, false)} className={`${six_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(6, true)} className={`${six_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
              </div>
            ) : questionsPage == 6 ? (
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
              <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>Did 
              {newAuditor?.length ? newAuditor?.map((x,i,a) => (<div style={{display:"inline-block", margin:"0 5px"}} key={x.value}> {` ${x?.label} ${i !== a.length-1 ? "," : " "} `} </div>)) : vars?.AuditorName} use the ANSI A326.3 Validation Surface to perform the Testfoot Validation Procedure?
              </div>
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(7, false)} className={`${seven_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(7, true)} className={`${seven_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div>
                </div>
              </div> 
            ) : questionsPage == 7 ? (
              <div className="form-row" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <div className="form-collection">
                <div className="form-label" style={{textTransform:"none", fontSize:"3vmin", width:"100%", display:"block"}}>{vars?.allLocationsArr?.length ? <div> Are you aware that the following location(s) do not show any new data for this report ? {vars?.allLocationsArr.map((x,i) => <div style={{color:"#EF1111"}} key={i}>Area - {x.area} {x.location}</div>)}</div> : <div>Thanks</div> }</div>
                
                {vars?.allLocationsArr?.length ? <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center", flexDirection:"row"}}>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(8, false)} className={`${eight_yes == false ? "checkedNo notCheckedNo" : "notCheckedNo"}`}>
                      <span className="control">No</span>
                </div>
                <div style={{cursor:"pointer"}} onClick={() => toggleQuestion(8, true)} className={`${eight_yes == true ? "checkedYes notCheckedYes" : "notCheckedYes"}`}>
                      <span className="control">Yes</span>
                </div>
                </div> : null}
                </div>
                {/* {eight_yes == true && <div className="form-collection">
                <div className="form-label"><span style={{textTransform:"none"}}>why you skipped it!</span></div>
                <textarea
                    onChange={(e) => setSkippedWhy(e.target.value)}
                    value={skippedWhy}
                    type="text"
                    placeholder="why you skipped it!"
                    className="form-input textarea-input"
                  />
                </div>}  */}
              </div>
            ) : <div></div>}
            </div>
            {questionsPage !== 7 ? vars?.allLocationsArr?.length ? <div style={{color:""}}>Press Enter to select Yes {questionsPage == 7 ? "" : "and go next"}</div> : <div style={{color:""}}>Press Enter to select Yes {questionsPage == 7 ? "" : "and go next"}</div> : null}
            <div style={{position:"absolute",width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          { questionsPage > 0 ? <div
            style={{margin:".5rem"}}
            className="auth-button addUser"
            onClick={() => {              
              if(questionsPage !== 0) {              
                setQuestionPage(questionsPage - 1)
              }
            }}
          >
            Back
          </div> : <div></div> }

            <div
            
              className={
                    questionsPage == 0 ?
                    one_yes == true ?
                      "auth-button addUser" : one_yes == false && newClient ? "auth-button addUser" :
                    "auth-button addUser disable-button"
                    :
                    questionsPage == 1 ?
                    two_yes == true ?
                      "auth-button addUser" : two_yes == false && newAuditor ? "auth-button addUser" :
                    "auth-button addUser disable-button"
                    :
                    questionsPage == 2 ?
                    three_yes !== null ?
                      "auth-button addUser" :
                    "auth-button addUser disable-button"
                    :
                    questionsPage == 3 ?
                    four_yes !== null ?
                    "auth-button addUser" :
                    "auth-button addUser disable-button"
                    : questionsPage == 4 ? five_yes !== null ? "auth-button addUser" : "auth-button addUser disable-button" :
                    questionsPage == 5 ?
                    six_yes !== null ?
                    "auth-button addUser" :
                    "auth-button addUser disable-button" :
                    questionsPage == 6 ? seven_yes !== null ?
                    "auth-button addUser" : "auth-button addUser disable-button" :
                    questionsPage == 7 ? vars?.allLocationsArr?.length ? eight_yes !== null ? "auth-button addUser" : "auth-button addUser disable-button" : "auth-button addUser" :
                    "auth-button addUser disable-button"
              }
              style={{margin:".5rem"}}
              onClick={
                // () => {
                //   if(questionsPage !== 5) {
                //     setQuestionPage(questionsPage + 1)
                //   }
                // }
                questionsPage == 0 ?
                one_yes == true ?
                  () => setQuestionPage(questionsPage + 1): one_yes == false && newClient ? () => setQuestionPage(questionsPage + 1):
                null
                : 
                questionsPage == 1 ?
                two_yes == true ?
                  () => setQuestionPage(questionsPage + 1) : two_yes == false && newAuditor ? () => setQuestionPage(questionsPage + 1):
                null
                :
                questionsPage == 2 ? three_yes !== null ?
                () => setQuestionPage(questionsPage + 1):
              null
                :
                questionsPage == 3 ? four_yes !== null ?
                () => setQuestionPage(questionsPage + 1):
                null
                : questionsPage == 4 ? five_yes !== null ? () => setQuestionPage(questionsPage + 1) : null :
                questionsPage == 5 ? six_yes !== null ?
                () => setQuestionPage(questionsPage + 1):
              null :
                questionsPage == 6 ? seven_yes !== null ? () => setQuestionPage(questionsPage + 1):
                null :
                questionsPage == 7 ? vars?.allLocationsArr?.length ? eight_yes !== null ? () => generateFunc() : null : () => generateFunc() : 
                null       
              }
            >
              { questionsPage == 7 ?  "Generate" : "Next"}
            </div>

          </div>
          </div>
      </Modal>
  )}

  export default BeforeGenerateModal
       