import React from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ForgetPassword = (props) => {
    const {
        resetPasswordModal,
        setResetPasswordModal,
        setUserEmail,
        userEmail,
        forgetPassword,
        emailError,
        setEmailError
        } = props


  return(
<Modal
        isOpen={resetPasswordModal}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
              
               <div className="modal password-modal">
               <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                  setEmailError("")
                  setResetPasswordModal(false)}}
              />
          <h1 className="header" style={{width:"100%", fontSize:"24px"}}>Forgor your Password?</h1>
          <h1 className="header" style={{width:"100%", fontWeight:"normal", fontSize:"13px"}}>Enter your email address and we will send you a reset password</h1>

            <div className="form-collection">
              <div className="form-label"><span>Email<span style={{color:"red"}}> *</span></span></div>
              <input
                onChange={(e) => {
                  setEmailError("")
                  setUserEmail(e.target.value)

                }}
                value={userEmail}
                type="text"
                placeholder="User Email"
                className="form-input"
              />
             {emailError && (
        <div className="auth-error" style={{textTransform:"none"}}>
          {emailError}
        </div>
      )}
            </div>
            
            <div
              className={`${userEmail ? "auth-button addUser userActionsButtons": "auth-button addUser disable-button"}`}
              onClick={userEmail ? forgetPassword : null}
            >
              Reset
            </div> 
        </div>
      </Modal>
  )}

  export default ForgetPassword
       