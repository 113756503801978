import { gql } from "apollo-boost";

const updateLicenseMutation = gql`
  mutation(
    $_id: ID
    $user:ID,
    $pass:Boolean,
    $endDate:String,
    $expired:Boolean,
    $dateIssued:String,
    $freeReports:String,
    $purchasedReports: String,
    $users:String,
    $status:String,
    $notes:String,
    $createdBy: String
  ) {
    updateLicense(
    _id: $_id
    user:$user
    expired:$expired
    createdBy:$createdBy
    pass:$pass
    endDate:$endDate
    dateIssued:$dateIssued
    freeReports:$freeReports
    purchasedReports: $purchasedReports,
    users:$users
    status:$status
    notes:$notes
    ) {
    _id
    user
    expired
    createdBy
    pass
    endDate
    dateIssued
    freeReports
    notification {
        _id
        new
        message
        users
        createdAt
      }
    purchasedReports
    users
    status,
    notes,
    number
  }
  }
`;

export default updateLicenseMutation;
