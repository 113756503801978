import React,{useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom"
import { DataSpinner } from "../../spinner/dataSpinner";

import { Style } from "react-style-tag";
import parse from 'html-react-parser';
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTh, faThLarge } from "@fortawesome/free-solid-svg-icons";


import AWS from 'aws-sdk'
import ACTION_LOADING_SPINNER_ACTIVE from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import { useDispatch } from "react-redux";
import updateReportMutation from "../../../../graphql/mutations/report/updateReportMutation";
import { v4 as uuidv4 } from 'uuid';

import Bar from "../charts/Bar";
import AuditArea from "../../../admin/adminProjects/auditArea";


const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
}) 
const InsightsViewer = () => {

  const [activeType, setActiveType] = useState("samples")
  let location= useLocation()  
let dispatch = useDispatch()

  useEffect(() => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  },[])

  const[gridData, setGridData] = useState(null)


  const calculateStats = (values) => {
    // Function to calculate average of an array of numbers
    const calculateAverage = (array) =>
      array.reduce((acc, curr) => acc + curr, 0) / array.length;
  
    // Function to calculate minimum of an array of numbers
    const calculateMin = (array) => Math.min(...array);
  
    // Function to calculate maximum of an array of numbers
    const calculateMax = (array) => Math.max(...array);
  
    // Function to calculate range of an array of numbers
    const calculateRange = (array) => Math.max(...array) - Math.min(...array);
  
    // Convert values to numbers
    const numericValues = values.map((value) => parseFloat(value));
  
    // Calculate statistics
    const average = calculateAverage(numericValues);
    const min = calculateMin(numericValues);
    const max = calculateMax(numericValues);
    const range = calculateRange(numericValues);
  
    return {
      min,
      max,
      average,
      range,
    };
  };


const calculateStatistics = (arr, keys) => {
  // Function to convert string numbers to actual numbers
  const convertToNumbers = (value) => parseFloat(value);

  // Calculate average of an array of numbers
  const calculateAverage = (array) =>
    array.reduce((acc, curr) => acc + curr, 0) / array.length;

  // Calculate minimum of an array of numbers
  const calculateMin = (array) => Math.min(...array);

  // Calculate maximum of an array of numbers
  const calculateMax = (array) => Math.max(...array);

  // Calculate range of an array of numbers
  const calculateRange = (array) => Math.max(...array) - Math.min(...array);

  // Extract values for the given keys and convert them to numbers
  const values = arr.map((obj) =>
    keys.map((key) => convertToNumbers(obj[key]))
  );

  // Calculate averages for each set of samples
  const averages = values.map((set) => calculateAverage(set));

  // Calculate overall average of averages
  const overallAverage = calculateAverage(averages);

  // Calculate minimum of each set of samples
  const mins = values.map((set) => calculateMin(set));

  // Calculate minimum of all samples
  const overallMin = calculateMin(mins);

  // Calculate maximum of each set of samples
  const maxs = values.map((set) => calculateMax(set));

  // Calculate maximum of all samples
  const overallMax = calculateMax(maxs);

  // Calculate ranges for each set of samples
  const ranges = values.map((set) => calculateRange(set));

  // Calculate range of all samples
  const overallRange = calculateRange(values.flat());

  return {
    averages,
    overallAverage,
    mins,
    overallMin,
    maxs,
    overallMax,
    ranges,
    overallRange,
  };
};

// Define keys for the samples
const sampleKeys = ["Sample 1", "Sample 2", "Sample 3"];

const padNumber = num => num.toString().padStart(2, '0');



  useEffect(() => {
    let data = location?.state?.data
    if(data) {
      let newData = data.map(x => x.allReports ? JSON.parse(x.allReports)?.filter((x,i, a) => i == a.length -1) : null).filter(x=>x)?.flat()
      let finalData = newData?.map(x=> x.finalReport ? JSON.parse(x.finalReport) : null).filter(x=>x)
      let samples = finalData?.map(y=> y.tests.map(x => x.results.reduce((a, v) => {
        return { ...a,
        label: `A${v.area} L${v.location}`, category: v.condition, 
        [`Sample ${v.testNumber}`]: v.result,
        [`Sample ${v.testNumber} Run 1`]: v.avg1,
        [`Sample ${v.testNumber} Run 2`]: v.avg2,
        [`Sample ${v.testNumber} Run 3`]: v.avg3,
        [`Sample ${v.testNumber} Run 4`]: v.avg4,
        results: x.results,
        area: v.area,
        location: v.location,
        date: v.date,
        area: y.areas.find(z => z.id == x.areaId),
        pass: x.passed,
        locationId: x.locationId
      }}, {})).map(x => {
        const stats = calculateStats([x["Sample 1"],x["Sample 2"],x["Sample 3"]])
        return {
          ...x,
          min: stats.min?.toFixed(2),
          max: stats.max?.toFixed(2),
          average: stats.average?.toFixed(2),
          range: stats.range?.toFixed(2),
        }
      }).map(x=> {
        return {
          ...x, 
          uniqe: `${padNumber(x.area)}${padNumber(x.location)}`
        } 
      }).sort((a,b) => a.uniqe - b.uniqe).flat()).flat()

      console.log(samples, "here")
      setGridData(samples)
    }
  },[location])

  const [statistics, setStatistics] = useState(null)
  useEffect(() => {

    if(gridData?.length) {
      setStatistics(calculateStatistics(gridData, sampleKeys))
    }
  },[gridData])

    return(
        <div className="adminContent" style={{alignItems:"flex-start"}}>
          {!gridData ? <DataSpinner 
          all={true} 
          over={true}
          /> : (
            <div style={{display:"flex", flexDirection:"column", width:"100%" }}>
            <div style={{width:"100%", minHeight:"200px", padding:"10px 0"}}>
            {statistics ? <div className="tinnyContainer">            
                    <div className="dashboardCard todayNumber todayNumberContainer forInsights">
                    <div className="dashboardCardHeader">
                    Total Tests
                    </div>
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                      {gridData?.length}
                    </div>
                    </div>
                    <div className="dashboardCard todayNumber todayNumberContainer forInsights">
                    <div className="dashboardCardHeader">
                    Average DCOF
                    </div>
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {statistics.overallAverage?.toFixed(2)}
                    </div>
                    </div>
                    <div className="dashboardCard todayNumber todayNumberContainer forInsights">
                    <div  className="dashboardCardHeader">
                    Max DCOF
                    </div> 
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {statistics.overallMax?.toFixed(2)}
                    </div>
                    </div>  
                    <div className="dashboardCard todayNumber todayNumberContainer forInsights">
                    <div  className="dashboardCardHeader">
                    Min DCOF
                    </div> 
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {statistics.overallMin?.toFixed(2)}
                    </div>
                    </div>  
                    <div className="dashboardCard todayNumber todayNumberContainer forInsights">
                    <div  className="dashboardCardHeader">
                    Range 
                    </div> 
                    <div className="todayNumber" style={{fontSize:"60px"}}>
                    {statistics.overallRange?.toFixed(2)}
                    </div>
                    </div>                  
            </div> : null}
            </div>

            <div className="rightActions">
          <div style={{width:"120px"}} className={`tableHeaderAdd viewContainer ${activeType == "samples" && "viewActiveContainer"}`}>
          {/* <FontAwesomeIcon onClick={() => setActiveType("samples")} style={{fontSize: activeType == "samples" ? "1.7rem" : "1.3rem", cursor:"pointer"}} icon={faThLarge} /> */}
          <div onClick={() => setActiveType("samples")} style={{fontSize: activeType == "samples" ? "1.7rem" : "1.3rem", cursor:"pointer"}} icon={faThLarge} >Samples</div>
          </div>
          <div style={{width:"120px"}} className={`tableHeaderAdd viewContainer ${activeType == "Runs" && "viewActiveContainer"}`}>
          {/* <FontAwesomeIcon onClick={() => setActiveType("Runs")} style={{fontSize: activeType == "Runs" ? "1.7rem" : "1.3rem", cursor:"pointer"}}  icon={faTh} /> */}
          <div onClick={() => setActiveType("Runs")} style={{fontSize: activeType == "Runs" ? "1.7rem" : "1.3rem", cursor:"pointer"}}  icon={faTh} >Runs</div>

          </div>
          </div> 

            
            <Bar activeType={activeType} statistics={statistics} data={[...gridData]} />
            </div>

          )
          }
            {/* { generateReport ? !defaultDCOF ? <DataSpinner small="l" /> : (
                <div ref={templateContainer} style={{width:"100%", height:"100%"}}>
                <Style>{defaultDCOF.css}</Style>
                {parse(`${defaultDCOF.html}`)}
               </div>
            ) : null} */}
            


        </div>
    ) 
}
 
export default InsightsViewer 